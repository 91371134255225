import React from 'react';
import { connect } from 'react-redux';
import pinterpolate from 'pinterpolate';
import { Redirect } from 'react-router-dom';

import { EMPLOYEE_PROFILE, UPDATE_EMPLOYEE } from 'constants/routes';

import initialFormData from '../initialFormData';

import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';
import { withoutAttrs, withOnlyAttrs } from 'utils/object';

import * as userService from 'services/user';
import * as roleService from 'services/role';
import * as departmentService from 'services/department';
import * as designationService from 'services/designation';
import * as designationAreaService from 'services/designationArea';
import * as engagementStatusService from 'services/engagementStatus';

import { filterAllowedRoles } from 'utils/user';
import Loading from '../../common/loading/Loading';
import EmployeeForm from '../employees/EmployeeForm';

class CreateForm extends React.Component {
  constructor(props) {
    super(props);
    window.document.title = 'Create new employee | Vyaguta';

    this.state = {
      designations: [],
      employees: [],
      departments: [],
      designationAreas: [],
      engagementStatus: [],
      isLoading: true,
      isSubmitting: false,
      formData: { ...initialFormData },
      toPage: null,
      roles: [],
      userId: null
    };
  }

  async componentDidMount() {
    try {
      const [designations, departments, designationAreas, engagementStatus, roles] = await Promise.all([
        this.fetchDesignations(),
        this.fetchDepartments(),
        this.fetchDesignationAreas(),
        this.fetchEngagementStatus(),
        this.fetchRoles()
      ]);

      this.setState({
        departments,
        designations,
        designationAreas,
        engagementStatus,
        roles: filterAllowedRoles(roles, this.props.permissions)
      });
    } catch (error) {
      handleError(error);
    }

    this.setState({ isLoading: false });
  }

  /**
   * Fetchs active employees.
   *
   * @returns {Array}
   */
  fetchActiveEmployees = async () => {
    const response = await userService.fetchAll({
      size: 1000
    });

    const { data } = response;

    return data;
  };
  /**
   * Fetchs departments.
   *
   * @returns {Array}
   */
  fetchDepartments = async () => {
    const response = await departmentService.fetchAll();

    const { data } = response;

    return data.map(item => withOnlyAttrs(item, ['id', 'name']));
  };

  /**
   * Fetchs designations.
   *
   * @returns {Array}
   */
  fetchDesignations = async () => {
    const response = await designationService.fetchAll();

    const { data } = response;

    return data.map(item => withOnlyAttrs(item, ['id', 'name']));
  };

  /**
   * Fetches Areas.
   *
   * @returns {Array}
   */
  fetchDesignationAreas = async () => {
    const response = await designationAreaService.fetchAll();

    const { data } = response;

    return data.map(item => withOnlyAttrs(item, ['id', 'name']));
  };

  /**
   * Fetches Engagement status.
   *
   * @returns {Array}
   */
  fetchEngagementStatus = async () => {
    const response = await engagementStatusService.fetchAll();

    const { data } = response;

    return data.map(item => withOnlyAttrs(item, ['id', 'name']));
  };

  /**
   *
   * @returns {Array} roles data
   */
  fetchRoles = async () => {
    const data = await roleService.fetchAll();

    return data;
  };

  /**
   * Handle create button click.
   */
  handleCreateClick = async formData => {
    this.setState({ isSubmitting: true });

    const createFormData = withoutAttrs(formData, ['employeeImage', 'empId']);
    const { employeeImage } = formData;

    try {
      const response = await userService.create({ ...createFormData, middleName: createFormData.middleName || null });

      const { id: userId } = response.data;
      this.setState({ userId: userId });
    } catch (error) {
      error.response.data.error.message = 'Oops! Something went wrong';
      handleError(error);
    }

    if (this.state.userId && employeeImage) {
      try {
        let imageUploadFormData = new FormData();
        imageUploadFormData.append('employeeImage', employeeImage);

        await userService.upload(this.state.userId, imageUploadFormData);

        this.setState({
          toPage: {
            pageUrl: EMPLOYEE_PROFILE,
            params: {
              id: this.state.userId
            }
          }
        });
      } catch (error) {
        this.setState({
          toPage: {
            pageUrl: UPDATE_EMPLOYEE,
            params: {
              id: this.state.userId
            }
          }
        });

        handleError('Error', { title: 'Error', message: 'Image upload failed! Please reupload the profile image.' });
      } finally {
        toast.success({
          title: 'Success',
          message: 'Employee has been created successfully.'
        });
      }
    }

    this.setState({ isSubmitting: false });
  };

  render() {
    const { isLoading, formData, isSubmitting, toPage } = this.state;

    if (isLoading) {
      return (
        <div className="container loading-container">
          <Loading />
        </div>
      );
    }

    if (toPage) {
      let togo = pinterpolate(toPage.pageUrl, toPage.params);
      return <Redirect to={togo} />;
    }

    return (
      <div>
        <EmployeeForm
          formData={formData}
          isCreateForm={true}
          isSubmitting={isSubmitting}
          handleSubmit={this.handleCreateClick}
          employees={this.state.employees}
          canEdit={key => true}
          departments={this.state.departments}
          designations={this.state.designations}
          designationAreas={this.state.designationAreas}
          engagementStatus={this.state.engagementStatus}
          roles={this.state.roles}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  permissions: state.information.value.permissions
});

export default connect(mapStateToProps)(CreateForm);
