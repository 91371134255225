import React from 'react';
import lodash from 'lodash';
import classnames from 'classnames';

import { getFormattedDate, sortByDate } from 'utils/date';
import { mergeDesignationAndEngagementStatus } from 'utils/user';

import Divider from 'components/common/divider/Divider';

import { HIRED } from 'constants/employeeStatus';
import { DATE_FORMAT_FOR_DISPLAY } from 'constants/appConstants';

const EmploymentHistory = props => {
  const { empStatusHistory = [], designationAreaHistory = [] } = props;

  const sortedEmpStatusHistory = lodash.sortBy(empStatusHistory, 'transitionDate');
  const sortedDesignationAreaHistory = lodash.sortBy(designationAreaHistory, 'transitionDate');

  const mergedData = mergeDesignationAndEngagementStatus(
    sortedEmpStatusHistory,
    sortedDesignationAreaHistory,
    'transitionDate'
  );

  const descendingMergedData = lodash.orderBy(mergedData, 'transitionDate', 'desc');

  let currentDesignationIndex = 0;

  return descendingMergedData.map((data, index, arr) => {
    const isActive = index === currentDesignationIndex;
    const isHired = data?.engagementStatus?.name === HIRED;

    if (getFormattedDate() < data.transitionDate) {
      currentDesignationIndex++;
    }

    const name = isHired ? HIRED : `${data.designation.name}${data.area && `, ${data.area.name}`}`;

    return (
      <div className="position-relative">
        <div
          className={classnames('employee-status-wrapper', {
            'employee-status-wrapper-last': index === mergedData.length - 1,
            'remove-border': index !== mergedData.length - 1 && arr[index + 1].engagementStatus.name === 'Terminated'
          })}
        >
          <div className="employee-status">
            <div
              className={classnames('designation-title font-bold', {
                'current-designation-title': isActive
              })}
            >
              {name}
            </div>

            <div className="emp-history">{getEmpHistoryDate(data.transitionDate, data.endDate)}</div>
            {!isHired && <div className="engagement-status">{data.engagementStatus.name}</div>}
            <div className="engagement-details">
              {data.empStatusDetails &&
                [...sortByDate(data.empStatusDetails, 'date')].reverse().map(item => {
                  return (
                    <p key={item.id}>
                      <span className="engagement-details__category">{`${item.category} : `}</span>
                      <span className="engagement-details__date">{`${getFormattedDate(
                        item.date,
                        DATE_FORMAT_FOR_DISPLAY
                      )}`}</span>
                    </p>
                  );
                })}
            </div>
          </div>

          {index !== mergedData.length - 1 && arr[index + 1].engagementStatus.name === 'Terminated' && (
            <Divider customClassName="employee-status-spaced-border" />
          )}
        </div>
      </div>
    );
  });
};

export const getEmpHistoryDate = (startDate, endDate, dateFormat = DATE_FORMAT_FOR_DISPLAY) => {
  return endDate
    ? [getFormattedDate(startDate, dateFormat), getFormattedDate(endDate, dateFormat)].join(' - ')
    : getFormattedDate(startDate, dateFormat);
};

export default EmploymentHistory;
