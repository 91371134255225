import React from 'react';
import classNames from 'classnames';

const TableHeader = props => {
  const { label, identifier, columnToSortBy, isAscendingOrdered, onClick } = props;
  const highlightColumn = identifier === columnToSortBy;

  return (
    <div
      className={classNames({
        'cursor-pointer': columnToSortBy,
        'color-tertiary-blue-40 lf-table__col--sortable': highlightColumn
      })}
      onClick={onClick}
    >
      {label}
      {highlightColumn ? (
        <span>
          <span className={classNames('triangle', { 'triangle--up': isAscendingOrdered })}></span>
        </span>
      ) : null}
    </div>
  );
};

export default TableHeader;
