import React from 'react';

export function AttendanceSummaryItem(props) {
  const { icon: Icon, size = 16, count, iconColor, isVisible = true } = props;

  if (!Icon || !isVisible) {
    return null;
  }

  return (
    <div className="attendance-summary__item d-flex gapx-4 clear-after">
      <div className="icon d-flex">
        <Icon size={size} color={iconColor} />
      </div>
      <div className="count">{count}</div>
    </div>
  );
}
