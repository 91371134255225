import React from 'react';

import classNames from 'classnames';

const Divider = props => {
  const { customClassName } = props;

  return (
    <hr
      className={classNames('divider', {
        [customClassName]: customClassName
      })}
    />
  );
};

export default Divider;
