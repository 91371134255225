import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import useTitle from 'hooks/useTitle';

import * as routes from 'constants/routes';

import * as eventService from 'services/events';

import history from 'utils/history';
import { handleError } from 'utils/errorHandler';

import EventForm from './EventForm';
import Spinner from 'components/common/Spinner';
import BackButton from 'components/common/button/BackButton';
import Loading from 'components/common/loading/Loading';

const CreateEditEvent = props => {
  const eventId = props.match.params && props.match.params.id;
  const title = eventId ? 'Edit Event' : 'Add Event';
  const setTitle = useTitle();

  useEffect(() => {
    setTitle(eventId ? 'Edit Event' : 'Add Event');
  }, []);

  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);

  const fetchEvent = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await eventService.fetchEventById(eventId);
      data.eventDate = moment(data.eventDate);
      setEvent(data);
    } catch (err) {
      handleError(err);
      history.push(routes.EVENTS);
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    if (!eventId) {
      return;
    }

    fetchEvent();
  }, [eventId, fetchEvent]);

  return (
    <div className="full-scope-card">
      <div className="full-scope-card__header table-header name-wrap">
        <div className="d-flex flex-row">
          <BackButton />
          <div className="d-flex flex-row">
            <div className="table-header">
              <h3 className="text-bold">{title}</h3>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="loading-min-height">
          <Loading />
        </div>
      ) : (
        <EventForm event={event} />
      )}
    </div>
  );
};

export default CreateEditEvent;
