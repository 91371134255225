import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const AccordionItem = props => {
  const [opened, setOpened] = useState(false);

  const {
    content: Content,
    title,
    info,
    value,
    onTitleClick,
    id,
    activeAccordion,
    setActiveAccordion,
    stickyClassName,
    isQuickLink,
    ...rest
  } = props;

  const handleClick = () => {
    if (!opened) {
      setActiveAccordion(id);
      return;
    }

    setOpened(false);
    setActiveAccordion(null);
  };

  useEffect(() => {
    setOpened(activeAccordion === id);
  }, [activeAccordion]);

  return (
    <div
      className={classNames(
        'accordion-item',
        { 'accordion-item--opened': opened },
        { 'accordion-item--sticky-opened custom-scroll-bar': stickyClassName && opened }
      )}
    >
      <div
        className={classNames(`accordion-item__line ${stickyClassName}`, {
          'bg-grey-5': opened,
          'accordion-item__line--quick-link': isQuickLink
        })}
        onClick={handleClick}
      >
        <h4 className={classNames('accordion-item__title', { 'text-grey-80': opened })}>
          <span
            className='accordion-item__label text-bold'
            onClick={event => {
              if (onTitleClick) {
                event.stopPropagation();
                onTitleClick(value || event);
              }
            }}
          >
            {title}
          </span>
          {info ? (
            <>
              <i className='accordion-item__info_separator' />
              <span className='accordion-item__info'> {info}</span>
            </>
          ) : (
            ''
          )}
        </h4>
        <span className={classNames('accordion-item__icon', { 'accordion-item__icon--quick-link': isQuickLink })} />
      </div>
      <div className='accordion-item__inner'>
        <div className='accordion-item__content'>
          <Content {...rest} />
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ data, categoryToOpen, itemToHighlight, stickyClassName = '', isQuickLink, className }) => {
  const [activeAccordion, setActiveAccordion] = useState(categoryToOpen);
  const [highlightedItem, setHighlightedItem] = useState(itemToHighlight);

  useEffect(() => {
    if (categoryToOpen === null) {
      return;
    }

    setActiveAccordion(0);
  }, [categoryToOpen]);

  return (
    <div className={classNames('accordion-container custom-scroll-bar', { [className]: className })}>
      <ul className='accordion-list'>
        {data.map((obj, key) => {
          obj.setActiveAccordion = setActiveAccordion;
          obj.activeAccordion = activeAccordion;
          obj.categoryToOpen = categoryToOpen;
          obj.itemToHighlight = itemToHighlight;
          obj.id = key;
          return (
            <li className='accordion-list__item' key={key}>
              <AccordionItem stickyClassName={stickyClassName} isQuickLink={isQuickLink} {...obj} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Accordion;
