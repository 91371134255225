import React from 'react';
import classNames from 'classnames';

import LeapfrogLogo from 'assets/icon/LeapfrogLogo';

const AttendanceWifiLog = ({ Icon, color, className, logoColor, hasLogo }) => {
  return (
    <div>
      <div className={classNames('profile-work', className)}>
        <Icon color={color} size={14} />
        {hasLogo && (
          <div className="lf-logo">
            <LeapfrogLogo bgColor={logoColor} size={16} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceWifiLog;
