import React from 'react';
import classNames from 'classnames';

const PillStatus = props => {
  const { label, isActive } = props;

  return (
    <div className="pill">
      <span
        className={classNames(
          'pill__bulb',
          { 'pill--status-active': isActive },
          { 'pill--status-inactive': !isActive }
        )}
      />
      <span className="pill__label">{label}</span>
    </div>
  );
};

export default PillStatus;
