import pinterpolate from 'pinterpolate';

import { PAGE_SIZE } from 'constants/appConstants';

import http from 'utils/http';
import config from '../config';

export async function fetchUserGoals(userId, params) {
  const url = `${config.jumpBaseURI}${pinterpolate(config.endpoints.jump.userGoals, { userId })}`;
  const { data } = await http.get(url, { params: { ...params, pageSize: PAGE_SIZE.MAX } });

  return data;
}

/**
 * Fetch user goals summary.
 *
 * @param {nubmer} userId
 * @param {object} params
 * @returns
 */
export async function fetchUserGoalsSummary(userId, params) {
  const url = `${config.jumpBaseURI}${pinterpolate(config.endpoints.jump.userGoalsSummary, { userId })}`;

  const { groupBy = 'type' } = params;

  const { data } = await http.get(url, { params: { ...params, groupBy } });

  return data;
}
