import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email')
});

export const validateEmail = email => {
  const isValidEmail = schema.isValidSync({
    email
  });

  return isValidEmail;
};
