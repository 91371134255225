import { useEffect, useState } from 'react';

const useTitle = (initialTitle = null) => {
  const [title, setTitle] = useState(initialTitle);

  useEffect(() => {
    if (!title) {
      document.title = 'Vyaguta';
      return;
    }

    document.title = `${title} | Vyaguta`;
  }, [title]);

  return setTitle;
};

export default useTitle;
