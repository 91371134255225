import { useMutation, useQuery } from 'react-query';
import { updateLinkLibraryWeights, fetchQuickLinks } from 'services/quickLinks';
import { handleError } from 'utils/errorHandler';

export const useFetchQuickLinks = ({ onSuccess, onError }) => {
  const queryKey = ['quickLinks'];
  const query = useQuery(queryKey, fetchQuickLinks, {
    onSuccess: data => onSuccess(data),
    onError: error => {
      onError && onError(error);
      handleError(error);
    }
  });

  return {
    ...query
  };
};

export const useUpdateLinkLibraryMutation = () => {
  const { mutate, isLoading } = useMutation(updateLinkLibraryWeights, {
    onError: error => handleError(error)
  });

  return {
    mutate,
    isLoading
  };
};
