import React from 'react';
import { withRouter } from 'react-router-dom';

import { parse } from 'utils/queryParam';

import {
  PAGE_SIZE_OPTIONS,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_LENGTH,
  MINIMUM_PAGE_COUNT
} from 'constants/page';

const Pagination = props => {
  const { pageData = {}, pageCount } = props;
  const activePage = pageData.page || DEFAULT_PAGE;
  const queryParam = parse(props.location.search);
  const pageSize = queryParam.size || DEFAULT_PAGE_SIZE;

  const changePage = selectedPage => {
    if (selectedPage === activePage) {
      return;
    }
    props.onPageChange(selectedPage);
  };

  const handlePageSizeChange = event => {
    props.onPageSizeChange(+event.target.value);
    if (+event.target.value > pageData.total && activePage !== DEFAULT_PAGE) {
      props.onPageChange(DEFAULT_PAGE);
      props.onPageSizeChange(+event.target.value);
    }
  };

  return (
    <div className="lf-table__pagination">
      <div className="lf-table__pagination--left">
        <button
          className={`lf-table__pagination-btn${
            activePage === MINIMUM_PAGE_COUNT || pageCount === MINIMUM_PAGE_COUNT
              ? ' lf-table__pagination-btn--active'
              : ''
          }`}
          onClick={() => changePage(MINIMUM_PAGE_COUNT)}
          disabled={activePage === MINIMUM_PAGE_COUNT || pageCount === MINIMUM_PAGE_COUNT}
        >
          First
        </button>
        <button
          className={`lf-table__pagination-btn${
            activePage === MINIMUM_PAGE_COUNT || pageCount === MINIMUM_PAGE_COUNT
              ? ' lf-table__pagination-btn--disabled'
              : ''
          }`}
          onClick={() => changePage(activePage - 1)}
          disabled={activePage === MINIMUM_PAGE_COUNT || pageCount === MINIMUM_PAGE_COUNT}
        >
          Prev
        </button>
        <button
          className={`lf-table__pagination-btn${
            activePage === pageCount || pageCount === MINIMUM_PAGE_COUNT ? ' lf-table__pagination-btn--disabled' : ''
          }`}
          onClick={() => changePage(activePage + 1)}
          disabled={activePage === pageCount || pageCount === MINIMUM_PAGE_COUNT}
        >
          Next
        </button>
        <button
          className={`lf-table__pagination-btn${activePage === pageCount ? ' lf-table__pagination-btn--active' : ''}${
            pageCount <= 1 ? ' lf-table__pagination-btn--disabled' : ''
          }`}
          onClick={() => changePage(pageCount)}
          disabled={activePage === pageCount || pageCount <= 1}
        >
          Last
        </button>
      </div>
      <div className="lf-table__pagination--right">
        <select value={pageSize} onChange={handlePageSizeChange} className="lf-table__pagination-dropdown">
          {PAGE_SIZE_OPTIONS.map(pageSize => (
            <option className="lf-table__pagination-dropdown-option " key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <span className="lf-table__pagination-status">
          {'Page '}
          <strong>
            {activePage} of {pageCount || DEFAULT_PAGE_LENGTH}
          </strong>
        </span>
      </div>
    </div>
  );
};

export default withRouter(Pagination);
