import React from 'react';

import InputWrapper from '../InputWrapper';
import DropDown from 'components/common/dropDown';
import { INITIAL_EMPLOYEE_SELECT_VALUE } from 'constants/appConstants';

const FormDropdown = props => {
  const {
    label = '',
    error,
    isMandatory,
    options,
    name,
    onChange,
    onBlur,
    value,
    isMulti,
    isDisabled,
    onInputChange,
    noOptionsMessage,
    classNamePrefix = 'lf-select',
    className,
    formatOptionLabel,
    isClearable,
    ...rest
  } = props;

  const onDropDownChange = item => {
    if (!onChange) {
      return null;
    }

    let value;

    if (item === null) {
      value = null;
    } else {
      value = isMulti ? item.map(({ value }) => value) : item.value;
    }

    return onChange({
      target: {
        name,
        value
      }
    });
  };

  const onDropDownBlur = () => {
    if (!onBlur) {
      return null;
    }

    return onBlur({
      target: {
        name
      }
    });
  };

  const getValueFromOptions = () => {
    if (!isMulti) {
      return options.find(({ value: optValue }) => optValue === value);
    }

    return options.filter(({ value: optValue }) => value?.includes(optValue));
  };

  return (
    <InputWrapper label={label} error={error} isMandatory={isMandatory}>
      <DropDown
        isBig
        autoResize={true}
        hasError={Boolean(error)}
        isSearchable={true}
        isDisabled={isDisabled}
        options={options}
        onDropDownChange={onDropDownChange}
        onBlur={onDropDownBlur}
        isMulti={isMulti}
        value={value !== INITIAL_EMPLOYEE_SELECT_VALUE && getValueFromOptions()}
        onInputChange={onInputChange}
        noOptionsMessage={noOptionsMessage}
        formatOptionLabel={formatOptionLabel}
        classNamePrefix={classNamePrefix}
        customClassName={className}
        isClearable={isClearable}
        {...rest}
      />
    </InputWrapper>
  );
};

export default FormDropdown;
