import React from 'react';
import Modal from 'react-modal';

// Icon
import { UiInfoCircle } from 'vyaguta-icons/ui';

Modal.setAppElement('#root');

// Constant

const DeleteModal = ({
  isOpen,
  onDelete,
  closeModal,
  title = 'Are you sure',
  deleteMessage = 'You are about to delete the record'
}) => {
  return (
    <Modal isOpen={isOpen} className="lf-modal" contentLabel="Example Modal" overlayClassName="lf-modal__overlay">
      <div className="delete-designation-modal">
        <div className="delete-designation-modal__header">
          <UiInfoCircle className="delete-designation-modal__header__logo" />
          <div>
            <p>{title}</p>
            <p className="delete-designation-modal__header__title">{deleteMessage}</p>
            <div className="delete-designation-modal__header__bottom">
              <button
                className="btn btn--large btn--red mr-12 delete__btn"
                onClick={() => {
                  onDelete();
                  closeModal();
                }}
              >
                Delete
              </button>
              <button className="btn btn--large btn--outlined-grey py-8 px-12" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
