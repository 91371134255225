import React, { useEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { DraggableLinkTableRow } from './DraggableLinkTableRow';

import { useUpdateLinkLibraryMutation } from 'hooks/query/useQuickLinksQuery';

import DndContextComponent from 'components/common/sortableDnD/DndContextComponent';

import { formattedUpdateWeightBody } from 'utils/linkLibrary';

const LinkTable = props => {
  const { links, nodeLevel } = props;
  const [linkListData, setLinkListData] = useState(links);

  useEffect(() => {
    if (!isEqual(linkListData, links)) {
      setLinkListData([...links]);
    }
  }, [JSON.stringify(links)]);

  const { mutate } = useUpdateLinkLibraryMutation();

  const onDragEnd = data => {
    const bodyPayload = formattedUpdateWeightBody(data);

    setLinkListData(data);
    mutate(bodyPayload);
  };

  return (
    <div className={`link-table-wrapper paddingNested-${nodeLevel}`}>
      <DndContextComponent listData={linkListData} onDragEnd={onDragEnd}>
        <table className="link-table">
          <thead>
            <tr>
              <th></th>
              <th>Link Name</th>
              <th>Link Url</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <SortableContext items={linkListData.map(({ id }) => id)} strategy={verticalListSortingStrategy}>
              {linkListData.map(data => (
                <DraggableLinkTableRow key={`${data.id}-${data.name}`} rowData={data} />
              ))}
            </SortableContext>
          </tbody>
        </table>
      </DndContextComponent>
    </div>
  );
};

export default LinkTable;
