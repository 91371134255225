export const LEAVE_QUERY_KEYS = {
  LEAVE_BALANCE: 'leaveBalance',
  WELL_BEING_LEAVE_CREDITS: 'wellBeingLeaveCredits'
};

export const FISCAL_YEAR_QUERY_KEYS = {
  BY_COUNTRY: 'fiscalYearByCountry'
};

export const ATTENDANCE_QUERY_KEYS = {
  SUMMARY: 'summary',
  ROSTER: 'roster'
};

export const USER_QUERY_KEYS = {
  TEAMS: 'userTeams',
  ALLOCATIONS: 'userAllocations'
};
