import classNames from 'classnames';
import { UiEnvelopeEdit } from 'vyaguta-icons/ui';
import React, { useState, useEffect } from 'react';

import config from 'config';
import { INITIAL_OPEN_DELAY } from 'constants/appConstants';

const AskUsAnything = ({ initialOpen }) => {
  const [isInitiallyOpen, setIsInitiallyOpen] = useState(true);

  useEffect(() => {
    if (!initialOpen) {
      return;
    }

    setTimeout(() => {
      setIsInitiallyOpen(false);
    }, INITIAL_OPEN_DELAY);
  }, []);

  return (
    <div className="ask-us-anything">
      <a href={config.askUsAnythingForm} target="_blank" rel="noopener noreferrer">
        <div
          className={classNames('d-flex align-items-center content', {
            'content--show': initialOpen && isInitiallyOpen
          })}
        >
          <span className={classNames('label', { 'label--show': initialOpen && isInitiallyOpen })}>
            Ask Us Anything
          </span>
          <div className="icon d-flex align-items-center">
            <UiEnvelopeEdit size={16} />
          </div>
        </div>
      </a>
    </div>
  );
};

export default AskUsAnything;
