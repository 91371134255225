import { arrayToString, stringToArray } from './string';

/**
 * Search for the  mappedSearchIds and result tree of that mappedSearchIds.
 *
 * @param {Array} tree
 * @param {Array} mappedSearchIds
 * @returns {Array}
 *
 * tree: an array representing the tree structure
 * mappedSearchIds: an object with searchIds as keys, used to check if the current node id matches any of the searchIds
 * Returns an array of nodes that match the searchIds
 *
 * Returns an array of nodes that match the searchIds
 */
export const searchTree = (tree, mappedSearchIds) => {
  // Create an empty array to store the results
  let result = [];

  // Loop through each node in the tree
  for (let node of tree) {
    // If the node id is found in the mappedSearchIds object, push the node to the result array
    if (mappedSearchIds[node.id] && node.type === 'skill') {
      // push the new object to the result array
      result.push(node);
    }

    // Recursively call the searchTree function on the node's subSkills, passing in the same mappedSearchIds object
    let subResult = searchTree(node.subSkills, mappedSearchIds);

    // If the subResult array is not empty, push a new object to the result array with the current node's properties and the subResult array
    if (subResult.length > 0) {
      result.push({
        id: node.id,
        name: node.name,
        description: node.description,
        totalSkills: node.totalSkills,
        subSkills: subResult
      });
    }
  }

  // Return the result array
  return result;
};

// searchTreeWithRating

export const searchTreeWithRating = (tree, mappedSearchIds) => {
  // Create an empty array to store the results
  let result = [];

  // Loop through each node in the tree
  for (let node of tree) {
    // If the node id is found in the mappedSearchIds object, push the node to the result array
    if (mappedSearchIds[node.id] && node.type === 'skill') {
      // create a new object with the properties of the node and add the rating property to it
      const nodeWithRating = {
        ...node,
        rating: mappedSearchIds[node.id].rating,
        skillStatus: mappedSearchIds[node.id].skillStatus
      };

      // push the new object to the result array
      result.push(nodeWithRating);
    }

    // Recursively call the searchTree function on the node's subSkills, passing in the same mappedSearchIds object
    let subResult = searchTreeWithRating(node.subSkills, mappedSearchIds);

    // If the subResult array is not empty, push a new object to the result array with the current node's properties and the subResult array
    if (subResult.length > 0) {
      result.push({
        id: node.id,
        name: node.name,
        description: node.description,
        totalSkills: node.subSkills.length,
        subSkills: subResult
      });
    }
  }

  // Return the result array
  return result;
};

/**
 * Return search skill.
 *
 * @param {Array} userSkills
 * @param {Array} skills
 * @returns {Array}
 *
 */
export const getSearchTreeWithRating = (userSkills, skills) => {
  if (!userSkills || !skills) {
    return [];
  }

  const mappedSearchIds = userSkills.reduce((acc, curr) => {
    acc[curr.id] = { rating: curr.rating, skillStatus: curr.skillStatus };

    return acc;
  }, {});

  return searchTreeWithRating(skills, mappedSearchIds).filter(item => item.subSkills.length);
};

/**
 * Return search skill.
 *
 * @param {Array} selectedSkill
 * @param {Array} skills
 * @returns {Array}
 *
 */
export const getSearchSkillTree = (selectedSkill, skills) => {
  if (!selectedSkill || !skills) {
    return [];
  }

  const mappedSearchIds = [selectedSkill].reduce((acc, curr) => {
    acc[curr.id] = true;

    return acc;
  }, {});

  return searchTree(skills, mappedSearchIds);
};

/**
 * Return skills by replacing with the search skill.
 *
 * @param {Array} skills
 * @param {Array} searchSkillTree
 * @returns {Array}
 *
 */
export const getFilterSkills = (skills, searchSkillTree) => {
  if (!searchSkillTree?.length) {
    return skills;
  }

  const [searchSkill] = searchSkillTree;

  const replaceSearchSkills = skills.map(skill => {
    if (searchSkill.id === skill?.id) {
      return searchSkill;
    }

    return skill;
  });

  return replaceSearchSkills;
};

/**
 * Return param for the fetchResource.
 *
 * @param {Object} param
 * @returns {Object}
 *
 */
export const getPeopleFinderParam = param => {
  const { skill, rating } = param;

  if (!skill) {
    return {};
  }

  const skillArray = stringToArray(skill, ',');
  const ratingArray = stringToArray(rating, ',');

  let ratedSkills = [];
  let skillRatings = [];
  let skillsWithZeroRating = [];

  skillArray.forEach((element, index) => {
    if (ratingArray[index] === '0') {
      skillsWithZeroRating.push(element);
    } else {
      ratedSkills.push(element);

      skillRatings.push(ratingArray[index]);
    }
  });

  const resourceParam = {
    ratedSkills: arrayToString(ratedSkills),
    ratings: arrayToString(skillRatings),
    ...(skillsWithZeroRating.length ? { skills: arrayToString(skillsWithZeroRating) } : {})
  };

  return resourceParam;
};

/**
 * Returns search and remainig arrays of skills based on a query of skill ids.
 *
 * @param {Array} skills - An array of skill objects.
 * @param {Array} skillsQuery - An array of skill ids to search for.
 * @returns {Array} An array containing two arrays: search, and remainig.
 */
export const getSearchAndRemainingSkills = (skills, skillsQuery, searchSkillLimit = 3) => {
  if (!skills) {
    return [[], []];
  }

  const filteredSkillsQuery = skillsQuery?.filter(skill => !!skill && skill !== '');

  if (!filteredSkillsQuery?.length) {
    const skillsCopy = [...skills];

    const firstArray = skillsCopy.splice(0, searchSkillLimit);

    const secondArray = skillsCopy;

    return [firstArray, secondArray];
  }

  const skillMap = {};

  const searchSkills = [];

  const remainingSkills = [];

  const mappedSkillQuery = filteredSkillsQuery.reduce((acc, curr) => {
    acc[curr] = true;

    return acc;
  }, {});

  for (const skill of skills) {
    skillMap[skill.id] = skill;

    if (mappedSkillQuery[skill.id] && searchSkills.length < filteredSkillsQuery?.length) {
      searchSkills.push(skill);
    } else {
      remainingSkills.push(skill);
    }
  }

  return [searchSkills, remainingSkills];
};
