import Tooltip from '../tooltip';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import React, { useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import { getAllocationAppendLabel } from 'utils/user';

import CurrentAllocation from 'components/home/dashboard/components/CurrentAllocation';

const PieChartLabel = ({ value, label, appendLabel }) => {
  const append = appendLabel ? ` ${appendLabel}` : '';

  return (
    <>
      <span className="piechart__text__textStyle">{label}</span>
      <span className="piechart__hrs"> {`(${value}${append})`}</span>
    </>
  );
};

export default function CommonChart({
  chartData = [],
  className = '',
  legendClassName = '',
  appendLabel = '',
  colors = '',
  projectAllocations = '',
  designation = ''
}) {
  const [totalValue, setTotalValue] = useState(0);
  const [hovered, setHovered] = useState(null);
  const wordLimit = 15;
  const emptyData = [
    {
      key: 0,
      label: '',
      value: 100,
      color: '#F6F6F6'
    }
  ];

  function makeTooltipContent(entry) {
    //TODO Improve function implementation.
    const suffix = entry.value === 1 ? appendLabel?.singular : appendLabel?.plural;
    return <div className="piechart__tooltip">{`${entry.label} - ${entry.value} ${suffix || ''}`}</div>;
  }

  useEffect(() => {
    if (chartData.length > 0) {
      chartData.map(item => {
        setTotalValue(totalValue => totalValue + item.value);
      });
    }
  }, []);

  return (
    <div className={`piechart__content align-items-center ${className}`}>
      <div className="piechart__main" data-tip="" data-for="chart">
        <PieChart
          animate={true}
          startAngle={270}
          data={chartData.length === 0 ? emptyData : chartData}
          totalValue={totalValue}
          lineWidth={30}
          onMouseOver={(_, index) => {
            setHovered(index);
          }}
          onMouseOut={() => {
            setHovered(null);
          }}
          labelPosition={0}
          label={({ x, y, dx, dy, dataEntry }) => {
            if (dataEntry.key === 0) {
              return (
                <text x={x} y={y} dx={dx} dy={dy} dominantBaseline="middle" textAnchor="middle">
                  <tspan x="50%" dy="-5" className="piechart__label">
                    {totalValue}
                  </tspan>
                  {appendLabel && (
                    <tspan x="50%" dy="17" className="piechart__hrsLabel">
                      {getAllocationAppendLabel(totalValue)}
                    </tspan>
                  )}
                </text>
              );
            }
          }}
        />
        {chartData.length !== 0 && (
          <ReactTooltip
            id="chart"
            multiline={true}
            getContent={() => (typeof hovered === 'number' ? makeTooltipContent(chartData[hovered]) : null)}
          />
        )}
      </div>
      <div className="piechart__wrapper custom-scroll-bar">
        <div className="piechart__legend">
          <CurrentAllocation allocations={projectAllocations} colorData={colors} designation={designation} />
        </div>
      </div>
    </div>
  );
}
