import * as Yup from 'yup';

export const changeAppraiserRequestSchema = Yup.object({
  allocationId: Yup.number()
    .min(1, 'Please select an allocation')
    .required('Please select an allocation')
    .typeError('Please select an allocation'),

  reason: Yup.string()
    .required('Please provide reason')
    .trim()
    .min(8, 'Reason should be of at least 8 characters')
    .typeError('Please provide reason')
});
