import React, { useMemo } from 'react';
import Select from 'react-select';
import classNames from 'classnames';

import { MdAdd } from 'react-icons/md';
import { FiXCircle } from 'react-icons/fi';

import ScoreTab from 'components/home/CreateUpdateEmployeeForm/components/Scoretab';

import { en } from 'constants/lang/en';

const SkillsSelector = props => {
  const { skillOptions, loading, onAddSkill, onRemoveSkill, skills, ratings, onSkillChange, onRatingChange } = props;

  const filterSkillOptions = useMemo(() => {
    const skillsMap = {};

    skills.forEach(element => {
      skillsMap[element] = true;
    });

    return skillOptions.map(skillOption => {
      return {
        label: skillOption.label,
        options: skillOption.options.filter(option => !skillsMap[option.value])
      };
    });
  }, [skillOptions, skills]);

  return (
    <>
      {skills.map((skill, index) => {
        return (
          <div className="skill-select-node d-flex align-items-end column-gap-3x" key={index}>
            <div className="skill-select">
              <div className="skill-label filter-label font-12">
                Skill {index + 1} {index === 0 && <span className="c-red">*</span>}
              </div>

              <Select
                options={filterSkillOptions}
                isLoading={loading}
                isSearchable={true}
                isClearable={true}
                value={skillOptions.flatMap(item => item.options).find(option => option.value === +skill) || null}
                onChange={option => {
                  const value = option && option.value;
                  onSkillChange(index, value);
                  if (ratings[index] == null && value) {
                    onRatingChange(index, 0);
                  }
                }}
              />
            </div>

            <div className="rating-select">
              <div className="rating-label filter-label font-12">{'Ratings'}</div>

              <ScoreTab
                lightMode={true}
                id={index}
                scoreValue={+ratings[index]}
                handleChange={(name, value) => {
                  onRatingChange(index, value);
                }}
              />
            </div>

            <div
              className={classNames('h-10x d-flex align-items-center invisible', {
                visible: skills.length > 1
              })}
            >
              <button className="clear-btn" type="button" onClick={() => onRemoveSkill(index)}>
                <FiXCircle size={20} />
              </button>
            </div>
          </div>
        );
      })}

      <div className="add-more-skill-filter" onClick={onAddSkill}>
        <MdAdd size={20} />

        <span className="skill-add-label">{en.BUTTON.ADD_ANOTHER_SKILL_FILTER}</span>
      </div>
    </>
  );
};

export default SkillsSelector;
