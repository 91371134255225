import http from '../utils/http';
import { interpolate, unParseQuery } from 'utils/string';
import { SKILL_CATEGORY_URL, SKILL_CATEGORY_ABSOLUTE_URL } from '../constants/endpoints';

export async function fetchAll(params = {}) {
  const data = await http.get(SKILL_CATEGORY_URL + unParseQuery(params));

  return data;
}

export async function fetchById(id) {
  const { data } = await http.get(interpolate(SKILL_CATEGORY_ABSOLUTE_URL, { id }));

  return data;
}

export async function create(skillCategory) {
  return await http.post(SKILL_CATEGORY_URL, {
    body: skillCategory
  });
}

export async function update(skillCategory, id) {
  return await http.put(interpolate(SKILL_CATEGORY_ABSOLUTE_URL, { id }), {
    body: skillCategory
  });
}

export async function remove(id) {
  return await http.delete(interpolate(SKILL_CATEGORY_ABSOLUTE_URL, { id }));
}
