import * as Yup from 'yup';

export const changeTentativeExitSchema = Yup.object({
  informedDate: Yup.date()
    .required('Please select informed date')
    .typeError('Invalid date format'),
  tentativeExitDate: Yup.date()
    .required('Please select tentative exit date')
    .typeError('Invalid date format')
    .min(Yup.ref('informedDate', 0), 'Tentative exit date must be equal to or greater than informed date'),
  reason: Yup.string()
    .required('Please provide reason')
    .trim()
    .min(8, 'Reason should be of at least 8 characters')
    .typeError('Please provide reason')
});
