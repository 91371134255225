// eslint-disable react-hooks/exhaustive-deps
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import Loading from 'components/common/loading/Loading';

import { getSearchSkillTree } from 'utils/skillV2';
import { handleError } from 'utils/errorHandler';

import { fetchGlobalRating } from 'slices/globalRatingSlice';

import * as skillService from 'services/skillService';

import { SkillSet } from './components';
import GlobalRatingModal from './skillV2Component/GlobalRatingModal';
import SearchSkill from './skillV2Component/SearchSkill/SearchSkill';

const Skills = props => {
  const { openGlobalRatingModal, onGlobalRatingModalClose, isCategoryModalOpen, setIsCategoryModalOpen } = props;

  const dispatch = useDispatch();

  const { value: globalRatings, loading: isLoadingGlobalRating } = useSelector(state => state.globalRatings);

  const [skills, setSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSearchSkill, setSelectedSearchSkill] = useState({});
  const [shouldFetchSkills, setShouldFetchSkills] = useState(false);
  const [triggerSearchSkillTree, setTriggerSearchSkillTree] = useState(0);

  const fetchSkills = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await skillService.fetchAll();

      setSkills(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
      setShouldFetchSkills(false);
    }
  }, []);

  const refetchSkills = useCallback(async () => {
    try {
      const { data } = await skillService.fetchAll();

      setSkills(data);
    } catch (error) {
      handleError(error);
    } finally {
      setTriggerSearchSkillTree(value => value + 1);
    }
  }, []);

  useEffect(() => {
    fetchSkills();
  }, [fetchSkills, shouldFetchSkills]);

  const getGlobalRating = useCallback(async () => {
    try {
      await dispatch(fetchGlobalRating());
    } catch (error) {
      handleError(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (globalRatings?.length > 0) {
      return;
    }

    getGlobalRating();
  }, [getGlobalRating, globalRatings]);

  const searchSkillTree = useMemo(() => getSearchSkillTree(selectedSearchSkill, skills), [skills, selectedSearchSkill]);

  return (
    <>
      <div className="full-scope-card">
        <div className="full-scope-card__header table-header name-wrap">
          <div>
            <div className="table-title">Skills</div>
          </div>
          <SearchSkill
            setSelectedSearchSkill={setSelectedSearchSkill}
            selectedSearchSkill={selectedSearchSkill}
            triggerSearchSkillTree={triggerSearchSkillTree}
          />
        </div>
        <div className={classNames({ loading__container: isLoading })}>
          <div className="full-scope-card__content d-flex position-relative primary-skills-card">
            {isLoading ? (
              <Loading className="mt-120" />
            ) : (
              <SkillSet
                skills={skills}
                setSelectedSearchSkill={setSelectedSearchSkill}
                searchSkillTree={searchSkillTree}
                refechSkills={refetchSkills}
                setShouldFetchSkills={setShouldFetchSkills}
                isCategoryModalOpen={isCategoryModalOpen}
                setIsCategoryModalOpen={setIsCategoryModalOpen}
              />
            )}
          </div>
        </div>
      </div>

      <GlobalRatingModal
        globalRatings={globalRatings}
        isLoadingGlobalRating={isLoadingGlobalRating}
        openGlobalRatingModal={openGlobalRatingModal}
        onGlobalRatingModalClose={onGlobalRatingModalClose}
      />
    </>
  );
};

export default Skills;
