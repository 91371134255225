import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  missing: 0,
  pending: 0
};

const selfAttendanceSlice = createSlice({
  name: 'selfAttendance',
  initialState,
  reducers: {
    setUserSelfAttendance: (state, action) => {
      state.missing = action.payload.missing;
      state.pending = action.payload.pending;
    }
  }
});

export default selfAttendanceSlice.reducer;
export const { setUserSelfAttendance } = selfAttendanceSlice.actions;
