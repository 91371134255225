export const SELECT_CATEGORY_VIEW = 'Select a category to view the related skills';
export const SELECT_CATEGORY_ADD = 'Select a category to start rating the skills';
export const REQUEST_NEW_SKILLS = 'Request new skills';

export const ADD_CATEGORY = 'Add category';
export const EDIT_CATEGORY = 'Edit category';
export const ADD_SKILL = 'Add Skill';
export const EDIT_SKILL = 'Edit Skill';
export const EDITING_SKILL_UNDER = 'You are editing skill under:';
export const ADDING_SKILL_UNDER = 'You are adding skill under:';

export const SKILL_INFO_MESSAGES = {
  AGREE_WITH_DIRECT:
    'You are agreeing with the initial rating of :firstName which means after you submit this skill set will be verified.',
  AGREE_WITH_TEAM_MANAGER: 'Agreeing with the rating from your team manager will change the initial rating from you.',
  AGREE_FOR_REMOVAL: 'Agreeing with your team manager will remove this skill from your skill set.',
  REFLAG_DIRECT_SKILL: 'Flagging again will let you re-rate the direct.',
  TALK_TO_TM: 'Talk to your TM if you want your rating changed in this skill set.',
  DELETION_CONFIRMATION:
    'Agreeing with your team manager will delete this skill from your skill set as this is recommended for removal.',
  RECOMMENDATION_REMOVAL_INFO: 'Recommendation for removal will trigger this skill to be removed for :firstName.'
};

export const ACTION_ITEMS = {
  FLAG_AGAIN: 'Flag again',
  AGREE_WITH_RATING: 'Agree with the rating',
  SURE: 'Yes, I am sure',
  GO_BACK: 'No, go back',
  AGREE: 'Agree for removal'
};

export const TALK_TO_TM = 'Talk to your TM';

export const SKILL_STATUS = {
  FLAGGED: 'Flagged',
  APPROVED: 'Approved'
};

export const AGREE = 'Agree';

export const VERIFIED = 'Verified';
export const APPROVE = 'Approve';
export const FLAG = 'Flag';

export const OVERLAY_TIMEOUT = 200;

export const LAST_SKILL_LEVEL = 3;

export const FLAGGED_REASON_LENGTH = 8;

export const SKILL_PILL_ACTION = {
  ADD: 'add',
  REMOVE: 'remove'
};
