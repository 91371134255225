import React from 'react';

const InputWrapperLabel = ({ label, isMandatory }) => {
  return (
    <p className="input-wrapper__label" style={{ color: label === '' ? 'transparent' : '' }}>
      {label} {isMandatory && <span className="input-wrapper__label--mandatory">*</span>}
    </p>
  );
};

export default InputWrapperLabel;
