import React from 'react';
import { getUniqueKey } from 'utils/string';

const Radio = ({ name, value, selected, label, ...rest }) => {
  const radioId = getUniqueKey();

  return (
    <div className="radio-btn">
      <input id={radioId} type="radio" className="radio-input" name={name} value={value} {...rest} />
      <label for={radioId} className="radio-label">
        <div className="radio-label__bulb"></div>
        <div className="radio-label__content">{label || value}</div>
      </label>
    </div>
  );
};

export default Radio;
