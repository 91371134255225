const mixPanelEvents = {
  view: {
    dashboard: 'View Dashboard',
    self: {
      pendingActionsTab: 'View Self Pending Actions Tab',
      worklogPending: 'View Self Worklog Pending',
      attendanceMissed: 'View Self Attendance Missed'
    },
    team: {
      pendingActionsTab: 'View Team Pending Actions Tab',
      worklogPending: 'View Team Worklog Pending',
      worklogSubmitted: 'View Team Worklog Submitted',
      leaveRequestPending: 'View Team Leave Request Pending'
    },
    myKpis: 'View My KPIs',
    askShareForm: 'View Ask & Share Us Anything Form',
    quickLinks: 'View Quick Links',
    leaveIssuerChangeRequest: {
      list: 'View Leave Issuer Change Request List',
      form: 'View Leave Issuer Change Request Form'
    },
    plannedLeaves: 'View Planned Leaves List in My Team in dashboard'
  },
  select: {
    workPlanButton: 'Select Work Plan Button',
    leaveIssuerChangeRequest: {
      button: 'Select Leave Issuer Change Request Button',
      linkFromProfile: 'Select Leave Issuer Change Request Link from Profile',
      list: {
        applyFilterButton: 'Select Leave Issuer Change Request List Apply Filter Button',
        exportCSVButton: 'Select Leave Issuer Change Request List Export CSV Button',
        resetButton: 'Select Leave Issuer Change Request List Reset Button',
        pills: {
          all: 'Select Leave Issuer Change Request List All Pill',
          approved: 'Select Leave Issuer Change Request List Approved Pill',
          rejected: 'Select Leave Issuer Change Request List Rejected Pill',
          pending: 'Select Leave Issuer Change Request List Pending Pill'
        }
      },
      linkFromMyTeamDashboard: 'Select Leave Issuer Change Request Link from My Team in dashboard'
    },
    pulse: 'Click Pulse Button from My Team in dashboard',
    requestedPlannedLeaves: 'Select Requested Planned Leaves List from My Team in dashboard',
    approvedPlannedLeaves: 'Select Approved Planned Leaves List from My Team in dashboard',
    appraiserChangeRequest: {
      linkFromMyTeamDashboard: 'Select Appraiser Change Request from My Team in dashboard',
      trigger: {
        requestForChange: 'Click Request For Change Appraiser button',
        cancel: 'Click Appraiser Change Cancel button'
      }
    }
  },
  switch: {
    from: {
      dashboard: 'Switch from Dashboard'
    }
  },
  change: {
    leaveIssuer: 'Change Leave Issuer'
  },
  approve: {
    leaveIssuerChangeRequest: {
      byPeopleManagement: 'Approve Leave Issuer Change Request by People Management',
      byRecipient: 'Approve Leave Issuer Change Request by Recipient',
      bulkByPeopleManagement: 'Approve Leave Issuer Change Requests Bulk by People Management'
    }
  },
  reject: {
    leaveIssuerChangeRequest: {
      byPeopleManagement: 'Reject Leave Issuer Change Request by People Management'
    }
  },
  send: {
    leaveIssuerChangeRequest: 'Send Leave Issuer Change Request'
  },
  scroll: {
    myTeamDashboard: {
      toScrollUp: 'Scroll towards the Up of My Team in Dashboard',
      toScrollDown: 'Scroll towards the Down of My Team in Dashboard',
      toScrollBottom: 'Scroll to the Bottom of My Team in Dashboard'
    }
  },
  linkLibrary: 'Link library open'
};

export { mixPanelEvents };
