import { useEffect } from 'react';

/**
 * Custom hook that executes a provided function when the browser tab becomes visible or hidden.
 *
 * @param {Function} callback - The function to call when the specified visibility state is detected.
 * @param {string} [targetState='visible'] - The visibility state that triggers the callback. Default is 'visible'.  * @param {string} [targetState='visible'] - The visibility state that triggers the callback. Default is 'visible'. Other possible value is 'hidden'.
 */
const useRefetchOnTabActive = (callback, targetState = 'visible') => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === targetState) {
        callback();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [callback, targetState]);
};

export default useRefetchOnTabActive;
