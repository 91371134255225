import * as Yup from 'yup';

const skillCategorySchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z][\sa-z0-9A-Z\-/]{1,50}$/, 'Invalid Name')
    .required('Please enter the skill category name'),
  description: Yup.string()
    .matches(/^[a-zA-Z][\sa-z0-9A-Z\-/]{1,200}$/, 'Invalid Description')
    .nullable()
});

export default skillCategorySchema;
