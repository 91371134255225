import React from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate, isDateToday } from 'utils/date';
import { EVENT_TYPE, OCCASION_TYPE } from 'constants/dashboard';
import { WEEK_DAY_DISPLAY_FORMAT, MONTH_DISPLAY_FORMAT, DAY_DISPLAY_FORMAT } from 'constants/appConstants';
import classNames from 'classnames';

const Eventitem = props => {
  const { childRef, date, eventTitle, eventType } = props;
  const weekDay = getFormattedDate(date, WEEK_DAY_DISPLAY_FORMAT);
  const day = getFormattedDate(date, DAY_DISPLAY_FORMAT);
  const month = getFormattedDate(date, MONTH_DISPLAY_FORMAT);
  const isToday = isDateToday(date);

  return (
    <div ref={childRef} className="event__item d-flex">
      <div
        className={classNames('event__date', 'text-align--center', {
          'event__date--active': isToday
        })}
      >
        <div className="event__date--day">{weekDay}</div>
        <div className={classNames('event__date--dates', { 'event__date--active': isToday })}>{day}</div>
        <div className="event__date--month">{month}</div>
      </div>
      <div className="event__description">
        <div
          className={classNames('event__title word-break', {
            'event__title--event': OCCASION_TYPE.EVENT === EVENT_TYPE[eventType],
            'event__title--active': isToday
          })}
        >
          {eventTitle}
        </div>
        <div
          className={classNames('event__type', {
            'event__type--active': isToday,
            'event__type--event': OCCASION_TYPE.EVENT === EVENT_TYPE[eventType]
          })}
        >
          {EVENT_TYPE[eventType]}
        </div>
      </div>
    </div>
  );
};

Eventitem.propTypes = {
  date: PropTypes.string,
  eventTitle: PropTypes.string,
  eventType: PropTypes.string,
  isToday: PropTypes.bool,
  childRef: PropTypes.func
};

export default Eventitem;
