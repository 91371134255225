export const ROLE_FLAGS = [
  'isTeamLead',
  'isProjectManager',
  'isAccountManager',
  'isCoach',
  'isPeopleOps',
  'isProxypm',
  'isResourceManager',
  'isHod',
  'isHr',
  'isRaffleEligible',
  'isSupervisor',
  'isTeamManager'
];

export const ROLE_MAP = {
  teamLead: 'Team Lead',
  projectManager: 'Project Manager',
  accountManager: 'Account Manager',
  coach: 'Coach',
  peopleOps: 'People Ops',
  proxypm: 'Proxy PM',
  resourceManager: 'Resource Manager',
  hod: 'HOD',
  hr: 'HR',
  raffleEligible: 'Raffle Eligible',
  supervisor: 'Supervisor',
  teamManager: 'Team Manager'
};

export const ROLE_FLAG_TO_VALUE = {
  isTeamLead: 'Team Lead',
  isProjectManager: 'Project Manager',
  isAccountManager: 'Account Manager',
  isCoach: 'Coach',
  isPeopleOps: 'People Ops',
  isProxypm: 'Proxy PM',
  isResourceManager: 'Resource Manager',
  isHod: 'HOD',
  isHr: 'HR',
  isRaffleEligible: 'Raffle Eligible',
  isSupervisor: 'Supervisor',
  isTeamManager: 'Team Manager'
};

export const USER_ROLES_EMPTY =
  'Oops, we cannot find the person you are looking for.\nPlease add the user from top right if you want to give privileges.';
