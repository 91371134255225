import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Empty } from '@vyaguta/vyaguta-ui';
import { useEffect, useMemo, useState } from 'react';

import Loading from 'components/common/loading/Loading';
import ProjectAllocationList from './ProjectAllocationList';
import AllProjectAllocations from './AllProjectAllocations';

import * as permissions from 'constants/permissions';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/page';

import { pluralize } from 'utils/string';
import { handleError } from 'utils/errorHandler';
import { getFormattedDate, getMonthsAgoDate, isDateInFuture, isDateToday } from 'utils/date';

import { fetchUserAllocations } from 'services/user';

export default function ProjectAllocations(props) {
  const { limit = 3, employeeId, authUserId, employeeName, employee, isSelf } = props;
  const [projectAllocations, setProjectAllocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [areAllocationsHidden, setAreAllocationsHidden] = useState(null);
  const {
    value: { permissions: userPermissions }
  } = useSelector(store => store.information);
  const endDate = getFormattedDate();
  const startDate = getMonthsAgoDate(endDate, 12);
  const [allocationData, setAllocationData] = useState([]);

  const isTeamManager = useMemo(() => {
    return employee.teamManager?.id === authUserId;
  }, [employee.teamManager?.id, authUserId]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const params = {
          startDate,
          endDate,
          userId: employeeId,
          page: DEFAULT_PAGE,
          size: DEFAULT_PAGE_SIZE,
          sortBy: endDate ? 'endDate' : 'potentialEndDate',
          order: 'desc'
        };

        const { data, meta } = await fetchUserAllocations(params);

        setAllocationData(data);

        const { page, pageSize, total } = meta;
        const totalPages = Math.ceil(total / pageSize);
        const fetchAllPromise = [];

        for (let i = page + 1; i <= totalPages; i++) {
          fetchAllPromise.push(
            fetchUserAllocations({
              startDate,
              endDate,
              userId: employeeId,
              page: i,
              size: DEFAULT_PAGE_SIZE,
              sortBy: endDate ? 'endDate' : 'potentialEndDate',
              order: 'desc'
            })
          );
        }

        const remainingAllocationList = await Promise.all(fetchAllPromise);
        const formattedAllocationList = remainingAllocationList.reduce((acc, { data }) => [...acc, ...data], []);

        setAllocationData(prev => [...prev, ...formattedAllocationList]);
      } catch (e) {
        handleError(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [userPermissions, employeeId]);

  useEffect(() => {
    const currentAllocations = allocationData.filter(allocation =>
      allocation.endDate ? isDateToday(allocation.endDate) || isDateInFuture(allocation.endDate) : true
    );

    setProjectAllocations(currentAllocations);
  }, [allocationData]);

  useEffect(() => {
    if (!projectAllocations) {
      return;
    }

    setAreAllocationsHidden(projectAllocations.length > limit);
  }, [projectAllocations, limit]);

  const sortedProjectAllocations = projectAllocations.sort((a, b) => {
    const dateA = a.endDate || a.potentialEndDate;
    const dateB = b.endDate || b.potentialEndDate;

    return new Date(dateB) - new Date(dateA);
  });

  return isLoading ? (
    <div className="full-scope-card emp-info-container mt-25">
      <Loading />
    </div>
  ) : (
    allocationData.length > 0 && (
      <div className="full-scope-card emp-info-container mt-25">
        <div className="font-bold emp-info-title">{pluralize('Allocation', projectAllocations.length)}</div>
        {sortedProjectAllocations.length > 0 ? (
          <>
            <ul className="projects">
              {sortedProjectAllocations.map((projectAllocation, index) => (
                <ProjectAllocationList
                  key={projectAllocation.id}
                  projectAllocation={projectAllocation}
                  className={classNames('project project-current', {
                    'project-hidden': areAllocationsHidden && index >= limit,
                    'project-prior-to-hidden': areAllocationsHidden && index === limit - 1
                  })}
                  isSelf={isSelf}
                  isTeamManager={isTeamManager}
                />
              ))}
            </ul>
            {areAllocationsHidden && projectAllocations.length > limit && (
              <button
                className="project-view-more-button"
                onClick={() => setAreAllocationsHidden(!areAllocationsHidden)}
              >
                View More
              </button>
            )}
          </>
        ) : (
          <Empty message="No current allocation." />
        )}

        {(userPermissions[permissions.EMPLOYEES.PROJECTS.VIEW_HISTORY] || authUserId === employeeId) &&
          (!areAllocationsHidden || !projectAllocations.length) && (
            <AllProjectAllocations
              hasCurrentAllocations={projectAllocations.length > 0}
              endDate={endDate}
              employeeId={employeeId}
              employeeName={employeeName}
              startDate={
                employee.empStatusHistory?.[employee.empStatusHistory.length - 1].transitionDate || employee.joinDate
              }
              isSelf={isSelf}
            />
          )}
      </div>
    )
  );
}
