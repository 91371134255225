export const leaveSource = {
  MANUAL: 'MANUAL',
  TRANSFER: 'TRANSFER',
  ENGAGEMENT_STATUS_TRANSITION: 'ENGAGEMENT_STATUS_TRANSITION',
  AUTO: 'AUTO'
};

export const leaveTypeId = {
  DISCRETIONARY: 7,
  ANNUAL: 8,
  SICK: 9,
  WELL_BEING: 10,
  MENSURATION: 15
};

export const leaveTypeLabel = {
  7: 'Discretionary',
  8: 'Annual',
  9: 'Sick',
  15: 'Mensuration'
};

export const leaveStatus = {
  REQUESTED: 'Requested',
  APPROVED: 'Approved'
};

export const USER_TEAM_COUNT_TYPE = {
  LEAVE_ISSUE: 'leaveIssuee'
};

export const DEFAULT_ISSUE_ALERT_COUNT = 8;

export const LEAVE_ISSUER = 'Leave Issuer';
