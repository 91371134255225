import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, Redirect, Switch } from 'react-router-dom';

import EventList from '../events/EventList';
import { getDefaultPath } from 'utils/path';
import NoticeList from '../notices/NoticeList';
import AuthorizedRoute from '../AuthorizedRoute';
import HolidayList from '../holidays/HolidayList';
import ReminderList from '../reminder/ReminderList';
import CreateEditEvent from '../events/CreateEditEvent';
import CreateEditNotice from '../notices/CreateEditNotice';
import LinkLibraryList from '../linkLibrary/LinkLibraryList';
import CreateEditHoliday from '../holidays/CreateEditHoliday';
import CreateEditReminder from '../reminders/CreateEditReminder';

import * as permissions from 'constants/permissions';
import {
  ADD_BUTTON_TEXT_OPTIONS,
  CREATE_EVENTS_PERMISSION_OPTIONS,
  CREATE_EVENTS_ROUTE_OPTIONS
} from 'constants/appConstants';
import {
  CREATE_EVENT,
  CREATE_HOLIDAY,
  CREATE_NOTICE,
  CREATE_REMINDER,
  HOLIDAYS,
  UPDATE_EVENT,
  UPDATE_HOLIDAY,
  UPDATE_NOTICE,
  UPDATE_REMINDER,
  EVENTS,
  NOTICES,
  REMINDERS,
  LINK_LIBRARY
} from 'constants/routes';

const EventsAndNotices = props => {
  const { setIsNoticeCountLoading } = props;

  const {
    value: { permissions: userPermissions }
  } = useSelector(state => state.information);

  const navBars = [
    {
      label: 'Reminder',
      path: REMINDERS,
      permission: permissions.REMINDERS.VIEW
    },
    {
      label: 'Notice',
      path: NOTICES,
      permission: permissions.NOTICES.VIEW
    },
    {
      label: 'Event',
      path: EVENTS,
      permission: permissions.EVENTS.VIEW
    },
    {
      label: 'Holiday',
      path: HOLIDAYS,
      permission: permissions.HOLIDAYS.VIEW
    },
    {
      label: 'Link Library',
      path: LINK_LIBRARY,
      permission: permissions.LINK_LIBRARY.VIEW
    }
  ];

  const navBarsWithPermissions = useMemo(() => {
    return navBars.map(navBar => {
      return {
        ...navBar,
        hasPermission: userPermissions[navBar.permission]
      };
    });
  }, [navBars, userPermissions]);

  const hasCustomClass = !navBarsWithPermissions.hasPermission;

  const getActionBarNavs = navBars => {
    const pathName = props?.history?.location?.pathname;

    const navigationPath = CREATE_EVENTS_ROUTE_OPTIONS[pathName];
    const hasPermission = userPermissions[CREATE_EVENTS_PERMISSION_OPTIONS[pathName]];
    const btnText = ADD_BUTTON_TEXT_OPTIONS[pathName];

    return (
      <div className="mtpx-30 f-wrap justify-content-between d-flex nav-card">
        <ul className="secondary-nav__wrap">
          {navBars.map(({ label, path, hasPermission }) => {
            if (!hasPermission) {
              return null;
            }

            return (
              <li key={path} className="secondary-nav__node">
                <NavLink
                  className="secondary-nav__link secondary-nav__link--primary clear-after"
                  activeClassName="active"
                  to={path}
                >
                  {label}
                </NavLink>
              </li>
            );
          })}
        </ul>
        {hasPermission && (
          <Link to={navigationPath} className="f-left add-employee-button">
            {btnText}
          </Link>
        )}
      </div>
    );
  };

  return (
    <main>
      <div className="container">
        {getActionBarNavs(navBarsWithPermissions)}
        <Switch>
          <AuthorizedRoute
            exact
            permission={[permissions.REMINDERS.VIEW]}
            path={REMINDERS}
            component={ReminderList}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.REMINDERS.CREATE]}
            path={CREATE_REMINDER}
            component={CreateEditReminder}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.REMINDERS.UPDATE]}
            path={UPDATE_REMINDER}
            component={CreateEditReminder}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />

          <AuthorizedRoute
            exact
            path={EVENTS}
            permission={[permissions.EVENTS.VIEW]}
            component={EventList}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            permission={[permissions.EVENTS.CREATE]}
            path={CREATE_EVENT}
            component={CreateEditEvent}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.EVENTS.UPDATE]}
            path={UPDATE_EVENT}
            component={CreateEditEvent}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />

          <AuthorizedRoute
            exact
            permission={[permissions.NOTICES.VIEW]}
            path={NOTICES}
            component={NoticeList}
            setIsNoticeCountLoading={setIsNoticeCountLoading}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.NOTICES.CREATE]}
            path={CREATE_NOTICE}
            component={CreateEditNotice}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.NOTICES.UPDATE]}
            path={UPDATE_NOTICE}
            component={CreateEditNotice}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />

          <AuthorizedRoute
            exact
            permission={[permissions.HOLIDAYS.VIEW]}
            path={HOLIDAYS}
            component={HolidayList}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.HOLIDAYS.CREATE]}
            path={CREATE_HOLIDAY}
            component={CreateEditHoliday}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.HOLIDAYS.UPDATE]}
            path={UPDATE_HOLIDAY}
            component={CreateEditHoliday}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />
          <AuthorizedRoute
            exact
            permission={[permissions.LINK_LIBRARY.VIEW]}
            path={LINK_LIBRARY}
            component={LinkLibraryList}
            customClassName={hasCustomClass && 'plpx-0 prpx-0'}
          />

          <Redirect to={getDefaultPath(navBarsWithPermissions)} />
        </Switch>
      </div>
    </main>
  );
};

export default EventsAndNotices;
