import classnames from 'classnames';
import { Empty } from '@vyaguta/vyaguta-ui';
import { FiMoreVertical } from 'vyaguta-icons/fi';
import React, { useCallback, useEffect, useState } from 'react';

import UserDetail from './UserDetail';
import Table from 'components/common/table';
import { AddUserModal } from './AddUserModal';
import PopOver from 'components/common/popover';
import VerticalEllipsis from './VerticalEllipsis';
import Pagination from 'components/common/paginator';
import Loading from 'components/common/loading/Loading';
import UserPermissionsFilter from './UserPermissionsFilter';
import TableHeader from 'components/common/table/TableHeader';

import { interpolate } from 'utils/string';
import { getIndexOfSN } from 'utils/table';
import { isObjectEmpty } from 'utils/object';
import { handleError } from 'utils/errorHandler';
import { parse, stringify } from 'utils/queryParam';

import * as userService from 'services/user';

import useTitle from 'hooks/useTitle';

import { EMPLOYEE_PROFILE } from 'constants/routes';
import { USER_ROLES_EMPTY } from 'constants/employeeRoles';
import { USER_FETCH_FIELDS } from 'constants/appConstants';
import { DEFAULT_PAGE_SIZE, MINIMUM_PAGE_COUNT } from 'constants/page';
import { USER_SELECTED_FIELDS, MAKE_SEARCH_BY } from 'constants/fetchTypes';

const UserPermissions = props => {
  const { history, updateFilterState } = props;
  const queryParams = parse(props.location.search);
  const [isFilterApplied, setIsFilterApplied] = useState(!isObjectEmpty(queryParams));
  const [userRoleData, setUserRoleData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isRoleAdded, setIsRoleAdded] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [columnToSortBy, setColumnToSortBy] = useState('name');
  const [isAscendingOrdered, setIsAscendingOrdered] = useState(true);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const defaultSorted = [
    {
      id: 'name',
      desc: false
    }
  ];

  const getColumns = (fetchUserRoles, queryParams, pageData, pageSize) => [
    {
      Header: <TableHeader label="SN." identifier="sn" />,
      accessor: 'sn',
      maxWidth: 56,
      sortable: false,
      filterable: false,
      Cell: props => {
        const index = getIndexOfSN(props, pageSize, pageData);
        return <span className="capital-text employees__table-text">{index < 10 ? `0${index}` : index}</span>;
      }
    },
    {
      Header: () => (
        <TableHeader
          label="Name"
          identifier="name"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => {
            setIsAscendingOrdered(!isAscendingOrdered);
          }}
        />
      ),
      accessor: 'name',
      maxWidth: 325,
      Cell: props => {
        const { empId, firstName, lastName, designation, id, roles } = props.original;

        return <UserDetail detail={{ empId, fullName: `${firstName} ${lastName}`, designation, id, roles }} />;
      }
    },
    {
      Header: <div className="d-flex align-items-center">Role(s)</div>,
      accessor: 'role',
      sortable: false,
      Cell: props => {
        const { roles } = props.original;
        const roleIds = queryParams?.roleIds ? queryParams.roleIds.split(',') : [];

        return (
          <div className="employee__role" id={`user-role-${props.original.id}`}>
            {roles.length > 0
              ? roles.map(role => {
                  const matchId = roleIds?.find(id => id == role.id);
                  return (
                    <span key={role.id} className={classnames('role-table-item', { 'text-bold': matchId })}>
                      {role.name}
                    </span>
                  );
                })
              : '-'}
          </div>
        );
      }
    },
    {
      Header: '',
      width: 80,
      id: 'more',
      sortable: false,
      Cell: props => {
        const { empId, firstName, lastName, designation, id, roles } = props.original;

        return (
          <span
            className="vertical-ellipsis"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <PopOver
              interactive
              theme={'light'}
              trigger="click"
              size={'regular overflow-menu__regular'}
              position="left-start"
              distance={5}
              disableOnScroll={true}
              popperOptions={{
                modifiers: {
                  addZIndex: {
                    enabled: true,
                    order: 810,
                    fn: data => ({
                      ...data,
                      styles: {
                        ...data.styles,
                        zIndex: 10
                      }
                    })
                  }
                }
              }}
              html={
                <VerticalEllipsis
                  selectedUser={{ empId, fullName: `${firstName} ${lastName}`, designation, id, roles }}
                  fetchUserRoles={fetchUserRoles}
                />
              }
              className="vertical-ellipsis__menu dots-menu"
            >
              <FiMoreVertical className="grey--text" size={24} />
            </PopOver>
          </span>
        );
      }
    }
  ];

  const setTitle = useTitle();

  useEffect(() => {
    setTitle('Role Management');
  }, []);

  const updateUrl = useCallback(
    value => {
      const queryParam = stringify('', value, { arrayFormat: 'comma', encode: false });
      history.push({
        pathname: props.location.pathname,
        search: queryParam
      });
    },
    [props.location.pathname]
  );

  const fetchUserRoles = async () => {
    try {
      setIsDataLoading(true);

      const queryParams = parse(props.location.search);
      const params = {
        ...queryParams,
        fields: [USER_FETCH_FIELDS.ROLES, ...USER_SELECTED_FIELDS].join(','),
        searchBy: MAKE_SEARCH_BY.join(',')
      };

      const size = queryParams.size || DEFAULT_PAGE_SIZE;

      setPageSize(size);

      const { data, meta } = await userService.fetchAll(params);

      const { total } = meta;

      setUserRoleData(data);
      setPageData(meta);

      const totalPage = Math.ceil(total / size);

      setPageCount(totalPage);
    } catch (error) {
      handleError(error);
    } finally {
      setIsDataLoading(false);
      setIsRoleAdded(false);
    }
  };

  useEffect(() => {
    fetchUserRoles();
  }, [props.location.search, isRoleAdded]);

  const applyFilter = filter => {
    updateUrl(filter);
  };

  const onPageChange = useCallback(
    selectedPage => {
      updateUrl({ ...parse(props.location.search), page: selectedPage });
    },

    [props.location.search, updateUrl]
  );

  const onPageSizeChange = useCallback(
    selectedSize => {
      setIsFilterApplied(true);
      updateUrl({ ...parse(props.location.search), page: 1, size: selectedSize });
    },

    [props.location.search, updateUrl]
  );

  const handleAddUserClick = state => {
    setShowAddUserModal(state);
  };

  return (
    <>
      <div className="full-scope-card">
        <UserPermissionsFilter
          {...props}
          data={userRoleData}
          applyFilter={applyFilter}
          handleAddUserClick={handleAddUserClick}
          showAddUserModal={showAddUserModal}
          updateFilterState={updateFilterState}
          isFilterApplied={isFilterApplied}
          setIsFilterApplied={setIsFilterApplied}
        />
        <div className={classnames({ loading__container: isDataLoading })}>
          {isDataLoading ? (
            <Loading />
          ) : userRoleData && userRoleData.length ? (
            <>
              <Table
                data={userRoleData}
                defaultSorted={defaultSorted}
                columns={getColumns(fetchUserRoles, queryParams, pageData, pageSize)}
                showPagination={false}
                noDataText={USER_ROLES_EMPTY}
                getTrProps={(state, rowInfo) => {
                  return {
                    className: 'align-items-center d-flex hover-visible-dots',
                    onClick: e => {
                      const url = interpolate(EMPLOYEE_PROFILE, { id: rowInfo.original.id });
                      if (e.ctrlKey || e.metaKey) {
                        window.open(url);
                      } else {
                        history.push(url);
                      }
                    }
                  };
                }}
              />
              <Pagination
                pageData={pageData}
                pageCount={+(pageCount || MINIMUM_PAGE_COUNT)}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
              />
            </>
          ) : (
            <Empty message={USER_ROLES_EMPTY} />
          )}
        </div>
      </div>
      <div>
        {showAddUserModal ? (
          <AddUserModal setShowAddUserModal={handleAddUserClick} setIsRoleAdded={setIsRoleAdded} />
        ) : null}
      </div>
    </>
  );
};

export default UserPermissions;
