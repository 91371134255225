import React from 'react';

// Constant
import LFModal from 'components/common/LFModal';
import { PREV } from 'constants/createEmployeeForm';
import Loading from 'components/common/loading/Loading';

const FormWrapperBottom = props => {
  const {
    currentForm,
    sizeOfStepper,
    onNavigate,
    isLoading,
    isModalShown,
    alertMessage = { text: "You won't be able to revert this!" },
    alertTitle = { text: `Are you sure ?`, type: 'danger' },
    onSubmit
  } = props;

  const showBack = currentForm > 1 && currentForm <= sizeOfStepper;
  const showSave = currentForm === sizeOfStepper;

  return (
    <div className="form-wrapper-bottom">
      {isModalShown && showSave ? (
        <LFModal
          TriggerBtn={({ onClick }) => (
            <button
              onClick={e => {
                e.preventDefault();
                onClick();
              }}
              className="btn btn--primary position-relative"
            >
              {isLoading && <Loading />}
              Save
            </button>
          )}
          renderFooter={true}
          title={alertTitle}
          className="delete-modal"
          message={alertMessage}
          buttons={[
            {
              text: 'Confirm',
              type: 'red',
              className: 'mr-12 delete__btn',
              close: true,
              showLoading: true,
              onClick: onSubmit
            },
            {
              text: 'Cancel',
              type: 'outlined-grey',
              className: 'py-8 px-12',
              close: true
            }
          ]}
        />
      ) : (
        <button className="btn btn--primary position-relative" type="submit" disabled={isLoading}>
          {isLoading && <Loading />}
          {showSave ? 'Save' : 'Next'}
        </button>
      )}

      {showBack && (
        <button
          className="btn btn--outlined-grey mr-16"
          type="button"
          onClick={e => {
            e.preventDefault();
            onNavigate(currentForm, PREV);
          }}
        >
          Back
        </button>
      )}
    </div>
  );
};

export default FormWrapperBottom;
