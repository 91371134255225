import config from 'config';
import isEqual from 'lodash/isEqual';
import pinterpolate from 'pinterpolate';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import {
  Overlay,
  QuickLink,
  SecondaryNav,
  useQuickLink,
  NotificationBar,
  QuickLinksWrapper,
  SearchBar,
  Header as VyagutaHeader,
  Global as VyagutaWrapper
} from '@vyaguta/vyaguta-ui';

import { addClassToChildren } from 'utils/dom';
import { handleError } from 'utils/errorHandler';

import * as authService from 'services/auth';
import { trackEvent } from 'services/mixPanel';

import { NOTICE_BOARD } from 'constants/routes';
import { mixPanelEvents } from 'constants/mixPanel';
import { USER_PROFILE_LIST_URL } from 'constants/endpoints';
import { MAC_SHORTCUT_KEY, ROLE, WIN_SHORTCUT_KEY } from 'constants/appConstants';
import { getHeaderNavs, getSecondaryNav, SUB_CONTRACTOR_HEADER } from 'constants/navigation';

import Notices from 'components/common/notices/Notices';
import AskUsAnything from 'components/common/ask-us-anything/AskUsAnything';

import { useFetchQuickLinks } from 'hooks/query/useQuickLinksQuery';
import useEmployeeDebounceWithLazyLoad from 'hooks/useEmployeeDebounceWithLazyLoad';

const Header = props => {
  const { user: loggedInUser, history, noticeCount, isNoticeCountLoading, userRoles, isUserRoleLoading } = props;
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const {
    value: { reminders, permissions }
  } = useSelector(state => state.information);

  const isSubContractor = userRoles?.includes(ROLE.SUB_CONTRACTOR);

  const headerNavList = useMemo(() => {
    const headerNav = getHeaderNavs(history.location.pathname);

    if (isUserRoleLoading) {
      return [];
    }

    if (isSubContractor) {
      return headerNav.filter(item => SUB_CONTRACTOR_HEADER.includes(item.label));
    }

    return headerNav;
  }, [isUserRoleLoading, userRoles, isSubContractor]);

  const secondaryNavItems = useMemo(
    () =>
      getSecondaryNav(isSubContractor).filter(
        item => !item?.allowFor || item?.allowFor?.some(permission => permissions[permission])
      ),
    [permissions, userRoles]
  );

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);

      await authService.logout();

      authService.redirectToLogin();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  const avatarMenus = [
    {
      type: 'link',
      props: {
        label: 'View Profile',
        href: getUserProfileUrl(loggedInUser)
      }
    },
    {
      type: 'button',
      props: {
        label: isLoggingOut ? 'Logging out' : 'Logout',
        onClick: handleLogout,
        disabled: isLoggingOut
      }
    }
  ];

  const sidebarMenus = [
    {
      type: 'link',
      props: {
        label: 'View Profile',
        href: getUserProfileUrl(loggedInUser)
      },
      position: 'body'
    },
    {
      type: 'button',
      props: {
        label: isLoggingOut ? 'Logging out' : 'Logout',
        onClick: handleLogout,
        disabled: isLoggingOut
      },
      position: 'footer'
    }
  ];

  const user = {
    ...loggedInUser,
    name: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
    image: loggedInUser.avatarUrl
  };

  useEffect(() => {
    const header = document.querySelector('#header');

    if (header) {
      addClassToChildren(header, 'flex-fix', el => window.getComputedStyle(el).display === 'flex');
    }
  }, []);

  const {
    quickLinksButtonRef,
    isQuickLinksOpen,
    setIsQuickLinksOpen,
    handleQuickLinkMenuClick,
    topPosition,
    overlayRef,
    reminderRef,
    headerRef
  } = useQuickLink();

  const [quickLinks, setQuickLinks] = useState([]);

  const [hasErrorInLoadingQuickLinks, setHasErrorInLoadingQuickLinks] = useState(false);

  const { isLoading: isQuickLinksLoading } = useFetchQuickLinks({
    onSuccess: data => {
      if (!isEqual(data, quickLinks)) {
        setQuickLinks(data);
      }
    },
    onError: _error => setHasErrorInLoadingQuickLinks(true)
  });

  useEffect(() => {
    if (isQuickLinksOpen) {
      trackEvent(mixPanelEvents.linkLibrary);
    }
  }, [isQuickLinksOpen]);

  const userDebounceOptions = useEmployeeDebounceWithLazyLoad({ searchUserByFullName: true });

  return (
    <div className="flex-fix" id="header">
      <VyagutaWrapper>
        <div ref={reminderRef}>
          <NotificationBar
            notifications={reminders}
            event={{
              startDate: config.events.startDate,
              endDate: config.events.endDate,
              link: config.events.link,
              title: config.events.title
            }}
          />
        </div>
        <div ref={headerRef}>
          <VyagutaHeader headerNavs={headerNavList} avatarMenus={avatarMenus} user={user} sidebarMenus={sidebarMenus}>
            {isUserRoleLoading && (
              <VyagutaHeader.Left>
                <div className="d-flex align-items-center justify-content-center gap-x-24 mlpx-16">
                  <SkeletonTheme baseColor="#e9edf0" highlightColor="#f0f3f5">
                    {Array(7)
                      .fill(0)
                      .map((item, index) => (
                        <Skeleton key={index} height={18} width={55} borderRadius={10} />
                      ))}
                  </SkeletonTheme>
                </div>
              </VyagutaHeader.Left>
            )}

            <VyagutaHeader.Right>
              <div className="mr-16">
                <div className="header-content">
                  <div className="mr-4x">
                    <SearchBar
                      debounceOptions={userDebounceOptions}
                      userProfileLink={USER_PROFILE_LIST_URL}
                      macShortcutKey={MAC_SHORTCUT_KEY}
                      winShortcutKey={WIN_SHORTCUT_KEY}
                      bodyOpenClassName="lf-overlay-body--hidden"
                      popOverClassName="popover-tippy"
                    />
                  </div>
                  <AskUsAnything />

                  <div ref={quickLinksButtonRef}>
                    <QuickLink isQuickLinksOpen={isQuickLinksOpen} onClick={handleQuickLinkMenuClick} />
                  </div>

                  <Notices
                    active={history.location.pathname === NOTICE_BOARD}
                    noticeCount={noticeCount}
                    isNoticeCountLoading={isNoticeCountLoading}
                  />
                </div>
              </div>
            </VyagutaHeader.Right>
          </VyagutaHeader>
        </div>

        <Overlay
          isOpen={isQuickLinksOpen}
          onClose={() => setIsQuickLinksOpen(false)}
          parentScroll={false}
          topPosition={topPosition}
        >
          <div ref={overlayRef}>
            <QuickLinksWrapper
              linkLibraryData={quickLinks}
              isLoading={isQuickLinksLoading}
              hasErrorInLoadingQuickLinks={hasErrorInLoadingQuickLinks}
            />
          </div>
        </Overlay>

        {props.location.pathname !== '/' && props.location.pathname !== NOTICE_BOARD && (
          <Switch>
            <SecondaryNav navItems={secondaryNavItems} />
          </Switch>
        )}
      </VyagutaWrapper>
    </div>
  );
};

/**
 * Gets users lms profiles url.
 *
 * @param {*} user
 * @returns
 */
const getUserProfileUrl = user => {
  return user && pinterpolate(config.endpoints.lms.userProfile, { id: user.id });
};

export default withRouter(Header);
