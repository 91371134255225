import React from 'react';

import { orderBy } from 'lodash';

import { Avatar } from '@vyaguta/vyaguta-ui';

import { extractFullName, getEmpImageUrl } from './string';

export const mapAndSortSelectOptions = (options, columns) => {
  const sortedOptions = orderBy(options, [option => option[columns].toLowerCase()]);

  const mappedOptions = sortedOptions.map(option => {
    if (option.empId) {
      return {
        label: option.name,
        value: option.id,
        empId: option.empId
      };
    }

    return {
      label: option.name,
      value: option.id
    };
  });

  return mappedOptions;
};

export const getOptionWithAvatar = option => {
  return {
    label: (
      <div className="d-flex flex-row align-items-center employee__details">
        {option.empId && <Avatar image={getEmpImageUrl(option.empId || '')} alt={option.label} size="smaller" />}
        <span className="ml-2x">{option.label}</span>
      </div>
    ),
    value: option.value
  };
};

export const mapOptionWithAvatar = options => {
  return options.map(option => getOptionWithAvatar(option));
};

export const mapUser = users => {
  return users.map(user => ({
    id: user.id,
    name: user.fullname || extractFullName(user, true),
    empId: user.empId
  }));
};

export const mapUsersWithAvatar = users => {
  return mapOptionWithAvatar(mapAndSortSelectOptions(mapUser(users), 'name'));
};
