import React from 'react';
import omit from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
import Creatable from 'react-select/creatable';

const DropDown = props => {
  const {
    isPlain,
    isSmall,
    isMedium,
    isBig,
    isBigSearchBox,
    customClassName,
    defaultValue,
    isLoading,
    value,
    name,
    onDropDownChange,
    onInputChange,
    noOptionsMessage,
    autoResize = false,
    isMulti = false,
    isCreatable = false,
    customStyles,
    isReadOnly,
    hasError = false,
    isDisabled = false,
    formatOptionLabel,
    isClearable,
    placeholder,
    menuIsOpen,
    components
  } = props;
  const selectClass = classnames(
    'lf-select',
    {
      'lf-select--plain': isPlain,
      'lf-select--small': isSmall,
      'lf-select--medium': isMedium,
      'lf-select--big': isBig,
      'lf-select--big-searchbox': isBigSearchBox,
      'lf-select--readonly': isReadOnly
    },
    customClassName
  );

  const handleFormatCreateLabel = inputValue => {
    if (props.formatCreateLabel) return props.formatCreateLabel;

    return inputValue;
  };

  const SelectComponent = isCreatable ? Creatable : Select;

  return (
    <div className="custom-dropdown">
      <SelectComponent
        name={name ? name : ''}
        isLoading={isLoading}
        components={components}
        defaultValue={defaultValue}
        value={value}
        onChange={onDropDownChange}
        classNamePrefix="lf-select"
        menuPortalTarget={document.body}
        styles={{
          singleValue: styles => (autoResize ? omit(styles, ['maxWidth']) : { ...styles }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          menu: base => ({ ...base, marginTop: 2 }),
          option: (styles, { isSelected }) => {
            return {
              ...styles,
              color: isSelected ? '#ffffff' : '#333333'
            };
          },
          control: styles => ({
            ...styles,
            ...(isReadOnly && { backgroundColor: 'transparent' }),
            ...(hasError && { borderColor: '#F44336 !important' })
          }),
          ...customStyles
        }}
        menuPosition={'absolute'}
        menuPlacement={'auto'}
        className={selectClass}
        onInputChange={onInputChange}
        isMulti={isMulti}
        formatCreateLabel={handleFormatCreateLabel}
        isDisabled={isDisabled}
        noOptionsMessage={noOptionsMessage}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        placeholder={placeholder}
        menuIsOpen={menuIsOpen}
        {...props}
      />
    </div>
  );
};

export default DropDown;
