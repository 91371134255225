import * as Yup from 'yup';

import MomentDateSchemaType from './momentdate';

const momentSchema = new MomentDateSchemaType();

const reminderSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .min(8, 'Title should be of at least 8 characters')
    .max(255, 'Title cannot exceed more than 255 characters')
    .required('Please provide a title'),
  redirectUrl: Yup.string()
    .trim()
    .max(255, 'Link cannot exceed more than 255 characters')
    .required('Please provide a link')
    .test('urlValidation', 'Provide a valid link, example: https://www.example.com', function(value) {
      const validatorRegX = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

      return Boolean(value) ? validatorRegX.test(value) : true;
    })
    .nullable(),
  startDate: momentSchema.required('Please input start date'),
  endDate: momentSchema.required('Please input end date')
});

export default reminderSchema;
