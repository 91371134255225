import moment from 'moment';

import { DEFAULT_DATE_FORMAT, MONTH_MAPPING, WEEKEND_DAYS } from 'constants/date';
import { DATE_FORMAT_DEFAULT, DEFAULT_TIMEZONE, FULL_DATE_TIME_FORMAT } from 'constants/appConstants';

/**
 * Formats date according to given format.
 *
 * @param {Date} date
 * @param {String} format
 * @returns {Date}
 */
export const getFormattedDate = (date, format = DATE_FORMAT_DEFAULT) => {
  return moment(date || moment()).format(format);
};

/**
 * Checks if the provided date is in the future.
 *
 * @param {Date|String} date
 * @returns {Boolean}
 */
export function isDateInFuture(date) {
  if (!date) return false;
  return moment().diff(date) < 0;
}

/**
 * Sort on the basics of date
 *
 * @param {Array} array of objects with a 'startDate' property
 * @param {string} key
 * @returns {Array} sorted array on the basis of startDate
 */
export function sortByDate(array, key = 'date') {
  return array.sort((a, b) => moment.utc(b[key]).diff(moment.utc(a[key])));
}

/**
 * Sort on the basics of date in ascending order
 *
 * @param {Array} array of objects with a 'date' property
 * @param {string} key
 * @returns {Array} sorted array on the basis of 'date'
 */
export function sortByAscendingDate(array, key = 'date') {
  return array.sort((a, b) => moment.utc(a[key]).diff(moment.utc(b[key])));
}

/**
 * Get the n months ago date from the given date
 *
 * @param {Date, Number} current date and number of days to go back
 * @returns {Date} date of n months ago
 */
export function getMonthsAgoDate(date, noOfMonths = 1) {
  return getFormattedDate(moment(date).subtract(noOfMonths, 'M'));
}

/**
 * Checks if the date is today or not.
 *
 * @param {String} date
 * @returns {Boolean} :specify valid or not.
 */
export function isDateToday(date) {
  return moment(date).isSame(moment(), 'day');
}

/**
 * Return range of dates. Includes both start date and end date.
 *
 * @param {string} startDate
 * @param {string} endDate
 * @param {string} [format=  DEFAULT_DATE_FORMAT]
 * @returns string[]
 */
export function getDateRange(startDate, endDate, format = DATE_FORMAT_DEFAULT) {
  const start = moment(startDate);
  const end = moment(endDate);

  const dateRange = [];

  while (!start.isAfter(end)) {
    dateRange.push(getFormattedDate(start, format));
    start.add(1, 'day');
  }

  return dateRange;
}

/**
 * Returns current day in number.
 *
 * @returns {Number}
 */
export const getCurrentDay = () => moment().day();

/**
 * Calculates the difference between the start date and end date.
 *
 * @param {Date | String} [startDate = null]
 * @param {Date | String} [endDate = null]
 * @returns {number}
 */

export const getDateDifference = (startDate = null, endDate = null) => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);

  return endDateMoment.diff(startDateMoment);
};

/**
 * Get the n months later date from the given date
 *
 * @param {Date, Number}
 * @returns {Date}
 */
export function getMonthsLaterDate(date, noOfMonths = 1) {
  return getFormattedDate(moment(date).add(noOfMonths, 'M'));
}

/**
 * Get the n weeks later date from the given date
 *
 * @param {Date, Number}
 * @returns {Date}
 */
export function getWeeksLaterDate(date, noOfWeeks = 1) {
  return getFormattedDate(moment(date).add(noOfWeeks, 'weeks'));
}

/**
 * Get the start date and end date of the week.
 * Week starts from Monday.
 * Week ends on Sunday.
 *
 * @param {string} date
 * @returns {array}
 */
export function getCurrentWeekRange(date) {
  const currentDate = moment(date);

  const weekStart = currentDate.clone().startOf('isoWeek');
  const weekEnd = currentDate.clone().endOf('isoWeek');

  return [getFormattedDate(weekStart), getFormattedDate(weekEnd)];
}

/**
 *
 * Checks whether the selected date falls
 * between start date and end date, inclusive
 * in both sides.
 *
 * @param {string} selectedDate
 * @param {string} startDate
 * @param {string} endDate
 * @param {string} inclusivity
 * @returns {boolean}
 */
export const isDateBetweenInclusive = (selectedDate, startDate, endDate, inclusivity = '[]') => {
  return moment(selectedDate).isBetween(startDate, endDate, null, inclusivity);
};

/**
 * Get the start date of the month.
 * @param {string} date
 * @returns {Date}
 */
export function getMonthsFirstDate(date) {
  return getFormattedDate(moment(date).startOf('month'));
}

/**
 * Get the end date of the month.
 *
 * @param {string} date
 * @returns {Date}
 */
export function getMonthsLastDate(date) {
  return getFormattedDate(moment(date).endOf('month'));
}

/**
 * Get the number of days from start date to end date.
 *
 * @param {string} startDate
 * @param {string} endDate
 * @returns {array}
 */
export function getDaysCount(startDate, endDate) {
  return moment(endDate).diff(moment(startDate), 'days') + 1;
}

/**
 *
 * Checks if the date is in the past.
 *
 * @param {string} date
 * @param {string} currentDate
 * @param {string} unit
 * @returns {boolean}
 */
export function isDateInPast(date, currentDate, unit = 'day') {
  return moment(date).isBefore(moment(currentDate), unit);
}

/**
 *
 * Checks if the date is same
 *
 * @param {string} date1
 * @param {string} date2
 * @param {string} unit
 * @returns {boolean}
 */
export function isSameDate(date1, date2, unit = 'day') {
  return moment(date1).isSame(moment(date2), unit);
}

/**
 * Get the months in a calendar.
 *
 * @returns {string []}
 */
export function getCalendarMonths() {
  return moment.months();
}

/**
 * Get list of years for calendar.
 *
 * @param {number} pastYearsCount
 * @param {number} futureYearsCount
 * @returns {number []}
 */
export function getCalendarYears(pastYearsCount = 80, futureYearsCount = 2) {
  const currentYear = new Date().getFullYear();

  const years = [];

  for (let i = futureYearsCount; i >= -pastYearsCount; i--) {
    years.push(currentYear + i);
  }

  return years;
}

/**
 * Get the months in a calendar.
 *
 * @returns {string []}
 */
export function getCalendarMonthsOption() {
  const data = moment.months();

  const months = [];

  for (let i = 0; i < data.length; i++) {
    months.push({
      label: MONTH_MAPPING[i],
      value: i
    });
  }

  return months;
}

/**
 * Get list of years for calendar.
 *
 * @param {number} pastYearsCount
 * @param {number} futureYearsCount
 * @returns {number []}
 */
export function getCalendarYearsOption(pastYearsCount = 80, futureYearsCount = 2) {
  const currentYear = new Date().getFullYear();

  const years = [];

  for (let i = futureYearsCount; i >= -pastYearsCount; i--) {
    years.push({ label: currentYear + i, value: currentYear + i });
  }

  return years;
}

export function getWeek() {
  return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
}

/**
 * Checks if the given time is in the past than other time.
 *
 * @param {string} time
 * @param {string} compareTime
 * @returns {boolean}
 */
export const isTimePast = (time, compareTime) => {
  return moment(time, 'h:mma').isBefore(moment(compareTime, 'h:mma'));
};

/**
 * Get current time in given timezone.
 *
 * @param {string} timezone
 * @param {string} format
 * @param {string} date
 * @returns {string}
 *
 */
export const getTimeAtTimeZone = (timezone = DEFAULT_TIMEZONE, format = FULL_DATE_TIME_FORMAT, date) => {
  return moment(date)
    .utcOffset(timezone)
    .format(format);
};

/**
 * calculates the date before a specified number of days and formats
 * it according to the provided format.
 * @param {number} days
 * @param {string} [format]
 * @returns {string}
 */
export const getDateBeforeNDays = (days, format = DATE_FORMAT_DEFAULT) => {
  return moment()
    .subtract(days, 'days')
    .format(format);
};

/**
 * Subtracts a specified number of days from a given date and formats the result.
 * @param {string | Date} date - The date to subtract days from.
 * @param {number} days - The number of days to subtract.
 * @param {string} [format=DEFAULT_DATE_FORMAT] - The format of the output date.
 * @param {boolean} [inclusive=false] - Whether to include the given date in the calculation.
 * @returns {string} - The formatted date string.
 */
export function subtractDays(date, days, format = DEFAULT_DATE_FORMAT, inclusive = false) {
  const momentInstance = inclusive ? moment(date) : moment(date).add(1, 'day');

  return momentInstance.subtract(days, 'days').format(format);
}

/**
 * Filters an array of objects based on a date range.
 *
 * @param {DataItem[]} data - The array of data objects to be filtered.
 * @param {string} key - The key in the data object that contains the date string.
 * @param {Date} startDate - The start date of the range.
 * @param {Date} endDate - The end date of the range.
 * @returns {DataItem[]} - The filtered array of data objects.
 */
export function filterDataByDateRange({ data, key, startDate, endDate }) {
  return data.filter(item => {
    const itemDate = moment(item[key]);

    return itemDate.isSameOrAfter(startDate) && itemDate.isSameOrBefore(endDate);
  });
}

/**
 * Checks if the given date is a weekend (Saturday or Sunday).
 *
 * @param {string | Date} date - The date to check.
 * @returns {boolean} - Returns true if the date is a weekend, otherwise false.
 */
export function isWeekend(date) {
  const dayOfWeek = moment(date).day();
  return WEEKEND_DAYS.includes(dayOfWeek);
}
