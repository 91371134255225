import 'react-table/react-table.css';

import ReactTable from 'react-table';

import React from 'react';
import classNames from 'classnames';

import Empty from 'components/common/empty/Empty';
import Loading from 'components/common/loading/Loading';

const Table = ({
  style,
  columns,
  loading,
  minRows,
  sortable,
  expanded,
  data = [],
  noDataText,
  getTdProps,
  getTrProps,
  subComponent,
  getTbodyProps,
  defaultSorted,
  showPagination,
  getTheadThProps,
  customClassName,
  noDataClassName
}) => {
  minRows = minRows || data.length;

  const NoDataComponent = props => {
    return loading ? (
      <div className="loading__container"></div>
    ) : (
      <Empty message={noDataText ? noDataText : 'No data to show.'} className={noDataClassName} />
    );
  };

  return (
    <ReactTable
      data={data}
      NoDataComponent={NoDataComponent}
      noDataText={loading ? '' : noDataText}
      className={classNames('lf', { [customClassName]: customClassName })}
      columns={columns}
      loading={loading}
      minRows={minRows}
      pageSize={minRows}
      sortable={sortable}
      getTrProps={getTrProps}
      getTheadThProps={getTheadThProps}
      getTbodyProps={getTbodyProps}
      getTdProps={getTdProps}
      showPagination={showPagination}
      defaultSorted={defaultSorted}
      loadingText={<Loading />}
      style={style}
      expanded={expanded}
      SubComponent={subComponent}
    />
  );
};

Table.defaultProps = {
  sortable: true
};

export default Table;
