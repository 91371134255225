import { useEffect, useState } from 'react';

import { handleError } from 'utils/errorHandler';
import { getEmployeeSelectOptions } from 'utils/user';
import { checkStringLength, isStringEmpty } from 'utils/string';

import { useDebounce } from './useDebounce';
import * as userService from 'services/user';
import { MAKE_SEARCH_BY } from 'constants/fetchTypes';
import { MIN_USER_SEARCH_LENGTH } from 'constants/appConstants';

const useEmployeeDebounceOptions = (employeeId, additionalFetchProps, searchUserByFullName = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [code, debounceFunction] = useDebounce();

  useEffect(() => {
    (async function fetchEmployee() {
      if (!employeeId && checkStringLength(code, MIN_USER_SEARCH_LENGTH)) {
        return;
      }

      try {
        setIsLoading(true);

        const params = {
          q: code,
          ...additionalFetchProps
        };

        if (employeeId && isStringEmpty(code, MIN_USER_SEARCH_LENGTH)) {
          const data = await userService.fetchById(employeeId);

          setOptions([data].map(getEmployeeSelectOptions));

          return;
        }

        if (!searchUserByFullName) {
          params.searchBy = MAKE_SEARCH_BY.join(',');
        }

        const { data } = await userService.fetchAll(params);

        setOptions(data.map(getEmployeeSelectOptions));
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [code, employeeId]);

  return [isLoading, options, code, debounceFunction];
};

export default useEmployeeDebounceOptions;
