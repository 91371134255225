import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import NoticeForm from './NoticeForm';
import Loading from 'components/common/loading/Loading';
import BackButton from 'components/common/button/BackButton';

import useTitle from 'hooks/useTitle';

import * as noticeService from 'services/notices';

import history from 'utils/history';
import { handleError } from 'utils/errorHandler';

import * as routes from 'constants/routes';

const CreateEditNotice = props => {
  const noticeId = props.match.params?.id;

  const title = noticeId ? 'Edit Notice' : 'Add Notice';

  const setTitle = useTitle();

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  const [isLoading, setIsLoading] = useState(false);
  const [notice, setNotice] = useState(null);

  const fetchNotice = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await noticeService.fetchById(noticeId);

      setNotice({
        ...data,
        startDate: moment(data.startDate),
        endDate: moment(data.endDate)
      });
    } catch (error) {
      handleError(error);
      history.push(routes.NOTICES);
    } finally {
      setIsLoading(false);
    }
  }, [noticeId]);

  useEffect(() => {
    if (!noticeId) {
      return;
    }

    fetchNotice();
  }, [noticeId, fetchNotice]);

  return (
    <div className="full-scope-card">
      <div className="full-scope-card__header table-header name-wrap">
        <div className="d-flex flex-row">
          <BackButton />
          <div className="d-flex flex-row">
            <div className="table-header">
              <h3 className="text-bold">{title}</h3>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="loading-min-height">
          <Loading />
        </div>
      ) : (
        <NoticeForm notice={notice} {...props} />
      )}
    </div>
  );
};

export default CreateEditNotice;
