import React from 'react';
import { Avatar } from '@vyaguta/vyaguta-ui';

import { getEmpImageUrl } from 'utils/string';

const UserDetail = props => {
  const { empId, designation, fullName } = props.detail;

  return (
    <div className="d-flex flex-row align-items-center">
      <Avatar
        className="nametag nametag--lg profile-photo leapfroggers-profile"
        image={getEmpImageUrl(empId)}
        alt={fullName}
      />
      <div>
        <div className="employee__fullname">{fullName}</div>
        <div className="employee__designation">{designation ? designation.name : '-'}</div>
      </div>
    </div>
  );
};

export default UserDetail;
