import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { handleError } from '../utils/errorHandler';
import { STATUS, CAN_FETCH } from '../constants/redux';
import * as feedbackService from '../services/feedback';

const initialState = { value: {}, loading: false, status: STATUS.IDLE };

export const fetchGoalFeedbacks = createAsyncThunk('goalFeedbacks/fetchGoalFeedbacks', async ({ goalId, params }) => {
  try {
    const data = await feedbackService.fetchGoalFeedbacks(goalId, params);

    return data;
  } catch (error) {
    handleError(error);
  }

  return initialState.value;
});

export const goalFeedbackSlice = createSlice({
  name: 'goalFeedbacks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGoalFeedbacks.pending, state => {
        if (CAN_FETCH.includes(state.status)) {
          state.loading = true;
          state.status = STATUS.PENDING;
        }

        return state;
      })
      .addCase(fetchGoalFeedbacks.fulfilled, (state, action) => {
        return {
          ...state,
          value:
            action.payload.length > 0 ? { ...state.value, [action.payload[0]?.goal.id]: action.payload } : state.value,
          loading: false,
          status: STATUS.FULFILLED
        };
      })
      .addCase(fetchGoalFeedbacks.rejected, state => {
        state.loading = false;
        state.status = STATUS.REJECTED;
      });
  }
});

export default goalFeedbackSlice.reducer;
