import React, { useMemo } from 'react';
import classNames from 'classnames';

import { UiBuilding, UiHomeAlt } from 'vyaguta-icons/ui';

import Table from 'components/common/table';
import PopOver from 'components/common/popover';

import { WORK_LOCATION } from 'constants/worklog';
import { DAYS_MAPPING, DDDD, DISPLAY_DATE_FORMAT } from 'constants/date';
import { HOLIDAY, MAX_CHARACTERS_MEDIAN_HISTORY_TABLE } from 'constants/dashboard';

import { getFormattedDate } from 'utils/date';
import { convertTimeToAMPMWithDots } from 'utils/time';
import { checkAttendanceUnfrozen } from 'utils/attendance';

import CalendarCross from 'assets/icon/CalendarCross';

export function ClockinHistoryTable(props) {
  const { clockinHistoryData, isLoading, unfreezeRequests } = props;

  const getTableRowsProps = (state, rowInfo) => {
    const day = getFormattedDate(rowInfo?.original?.date, DDDD);

    const styledClassname = classNames({
      'separator-row': day === DAYS_MAPPING.SUNDAY,
      'first-row': rowInfo?.index === 0
    });

    return {
      className: styledClassname.trim()
    };
  };

  const clockinHistoryColumns = useMemo(() => {
    const clockinHistoryColumns = [
      {
        Header: 'Day',
        sortable: false,
        width: 140,
        resizable: false,
        accessor: 'day',
        Cell: props => {
          const { date } = props?.original;

          const day = getFormattedDate(date, DDDD);

          return <span>{day}</span>;
        }
      },
      {
        Header: 'Date',
        sortable: false,
        width: 220,
        resizable: false,
        accessor: 'date',
        Cell: props => {
          const { date, isWeekend, holiday } = props?.original;

          const formattedDate = getFormattedDate(date, DISPLAY_DATE_FORMAT);

          if (isWeekend)
            return (
              <p className="d-flex align-items-center">
                {formattedDate}
                <span className="font-size-10 color-grey-60 mlpx-4">({HOLIDAY})</span>
              </p>
            );

          if (holiday) {
            const { name } = holiday;

            return (
              <p className="d-flex align-items-center">
                {formattedDate}

                {name.length > MAX_CHARACTERS_MEDIAN_HISTORY_TABLE ? (
                  <PopOver html={<p className="font-size-12">{name}</p>}>
                    <span className="font-size-10 color-grey-60 d-flex mlpx-4">
                      (<span className="ellipsis max-wpx-106">{name}</span>)
                    </span>
                  </PopOver>
                ) : (
                  <span className="font-size-10 color-grey-60 mlpx-4">({name})</span>
                )}
              </p>
            );
          }

          return <span>{formattedDate}</span>;
        }
      },
      {
        Header: 'Status',
        sortable: false,
        width: 106,
        resizable: false,
        accessor: 'status',
        Cell: props => {
          const { worklog, leave, holiday, date } = props?.original;

          const isAttendanceUnfrozen = checkAttendanceUnfrozen(date, unfreezeRequests);

          const shouldShowLeave = leave && ((leave.isAutomated && !isAttendanceUnfrozen) || !leave.isAutomated);

          if (shouldShowLeave && !holiday) {
            return (
              <span className="-mlpx-2 hpx-18 wpx-18">
                <CalendarCross color="#ab22ff" size={18} />
              </span>
            );
          }

          if (worklog?.location === WORK_LOCATION.OFFICE) {
            return (
              <span className="-mlpx-1 hpx-18 wpx-18">
                <UiBuilding size={18} color="#102b7b" />
              </span>
            );
          }

          if (worklog?.location === WORK_LOCATION.HOME) {
            return (
              <span className="-mlpx-2 hpx-18 wpx-18">
                <UiHomeAlt size={18} color="#102b7b" />
              </span>
            );
          }

          return '-';
        }
      },
      {
        Header: 'Clock-in time',
        sortable: false,
        width: 110,
        resizable: false,
        accessor: 'clockinTime',
        Cell: props => {
          const { checkinTime } = props?.original;

          if (!checkinTime) {
            return <span>-</span>;
          }

          return <span>{convertTimeToAMPMWithDots(checkinTime)}</span>;
        }
      }
    ];

    return clockinHistoryColumns;
  }, [unfreezeRequests]);

  return (
    <Table
      columns={clockinHistoryColumns}
      showPagination={false}
      sortable={false}
      customClassName="clockin-history-table"
      getTrProps={getTableRowsProps}
      data={clockinHistoryData}
      getTbodyProps={state => {
        return { className: 'custom-scroll-bar' };
      }}
      loading={isLoading}
    />
  );
}
