import React from 'react';
import classNames from 'classnames';
import { Tooltip } from 'react-tippy';

import 'react-tippy/dist/tippy.css';

function TooltipPopOver(props) {
  const { children, className, ...rest } = props;

  return (
    <span>
      <Tooltip {...rest} className={classNames('tooltip', className)} size="small">
        {children}
      </Tooltip>
    </span>
  );
}

export default TooltipPopOver;
