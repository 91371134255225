import React from 'react';
import classNames from 'classnames';

import { chevronRight } from 'assets/images';
import { FiChevronRight } from 'react-icons/fi';

export const SkillBreadCrumb = ({ skills }) => {
  return (
    <div className="skill-breadcrumb">
      <div className="skill-breadcrumb__heading">{skills?.[0].name}</div>
      <div className="skill-breadcrumb__body">
        {skills?.map((skill, index) => {
          const lastItem = index === skills.length - 1;

          if (index === 0) {
            return;
          }

          return (
            <div className="skill-breadcrumb__skill-item" key={skill.id}>
              <div className={classNames({ 'skill-breadcrumb--last-skill-item': lastItem })}>{skill.name}</div>
              {!lastItem && <img src={chevronRight} alt="chevron-right" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};
