import lodash from 'lodash';
import classNames from 'classnames';
import React, { useMemo, useEffect, useState } from 'react';

// Components
import PopOver from 'components/common/popover';
import ImageUploader from './components/ImageUploader';
import InputWrapper from 'components/common/inputWrapper';
import Input from 'components/common/inputWrapper/components/Input';
import AvatarComponent from 'components/common/avatar/AvatarComponent';
import TimeInput from 'components/common/inputWrapper/components/TimeInput';
import FormDropdown from 'components/common/inputWrapper/components/Select';
import SmallInput from 'components/common/inputWrapper/components/SmallInput';
import FormDateSelect from 'components/common/inputWrapper/components/DateSelect';
import FormRadioGroup from 'components/common/inputWrapper/components/RadioGroup';
import DebounceFormDropdown from 'components/common/debounceFormDropdown/DebounceFormDropdown';

import { pluralize } from 'utils/string';
import { isObjectEmpty } from 'utils/object';
import { scrollToElement } from 'utils/scroll';

import { WORKING_SHIFTS_OPTIONS } from 'constants/worklog';
import { WORKING_TYPE_OPTIONS, LIST_TYPE, AVAILABILITY_TIME_RANGES } from 'constants/appConstants';
import { COMPLETE, ERROR, TEMPLATE_FOR_CV, OFFICIAL_INFORMATION_FORM_INPUTS } from 'constants/createEmployeeForm';

const OfficialInformationForm = props => {
  const {
    isCreateForm,
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    departments,
    canEdit,
    roles,
    leaveIssuerDebounceOptions,
    teamManagerDebounceOptions,
    duplicateErrors,
    setDuplicateErrors,
    currentForm,
    shouldForcefullyShowError
  } = props;

  const [availabilityStartTime, setAvailabilityStartTime] = useState(values.availabilityStartTime);
  const [availabilityEndTime, setAvailabilityEndTime] = useState(values.availabilityEndTime);

  useEffect(() => {
    if (values.workingShift) {
      const times = AVAILABILITY_TIME_RANGES[values.workingShift];

      const [startValue, startType] = times.start.split(' ');

      const [endValue, endType] = times.end.split(' ');

      setAvailabilityStartTime({ value: startValue, type: startType });

      setAvailabilityEndTime({ value: endValue, type: endType });
    }
  }, [values.workingShift]);

  const departmentOptions = departments.map(({ id, name }) => ({ label: name, value: id }));

  const rolesOptions = useMemo(() => roles.map(({ id, name }) => ({ value: id, label: name })), [roles]);

  useEffect(() => {
    if (currentForm) {
      const isFormEmpty = OFFICIAL_INFORMATION_FORM_INPUTS.some(key => {
        if (key === 'employeeImage' && lodash.isEmpty(values[key])) {
          return true;
        }

        if (!values[key] || isObjectEmpty(values[key])) {
          return true;
        }
        return false;
      });

      if (!isFormEmpty) {
        currentForm.state = COMPLETE;
        return;
      }
      currentForm.state = ERROR;
    }
  }, [values]);

  return (
    <div className="office-information-form">
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <Input
            name="firstName"
            onBlur={handleBlur}
            value={values.firstName}
            error={(shouldForcefullyShowError || touched.firstName) && errors.firstName}
            onChange={handleChange}
            disabled={!canEdit('firstName')}
            label="First Name"
            isMandatory
            placeholder="Ribby"
            multiline={false}
            type="text"
            customClassName="employee-form__input"
          />
        </div>
        <div className="office-information-form__full-width__space">
          <Input
            name="middleName"
            onBlur={handleBlur}
            value={values.middleName}
            error={(shouldForcefullyShowError || touched.middleName) && errors.middleName}
            onChange={handleChange}
            disabled={!canEdit('middleName')}
            label="Middle Name"
            isMandatory={false}
            placeholder="Good"
            multiline={false}
            type="text"
            customClassName="employee-form__input"
          />
        </div>
        <div className="office-information-form__full-width__space">
          <Input
            name="lastName"
            onBlur={handleBlur}
            value={values.lastName}
            disabled={!canEdit('lastName')}
            error={(shouldForcefullyShowError || touched.lastName) && errors.lastName}
            onChange={handleChange}
            label="Last Name"
            isMandatory
            placeholder="Frog"
            multiline={false}
            type="text"
            customClassName="employee-form__input"
          />
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <PopOver
            interactive
            html={
              <div className="tooltip-info word-break">
                <p className="tooltip-info__title">
                  Employee ID will be generated automatically once the profile is created.
                </p>
              </div>
            }
            visible={isCreateForm}
          >
            <Input
              name="empId"
              onBlur={handleBlur}
              value={values.empId}
              error={duplicateErrors.empId || ((shouldForcefullyShowError || touched.empId) && errors.empId)}
              onChange={value => {
                if (isCreateForm) {
                  return;
                }

                setDuplicateErrors(error => ({ ...error, empId: null }));
                handleChange(value);
              }}
              label="Employee ID"
              isMandatory={!isCreateForm}
              placeholder={!isCreateForm ? '1001' : 'Auto-generated'}
              multiline={false}
              disabled={!canEdit('empId') || isCreateForm}
              type="text"
              customClassName="employee-form__input"
            />
          </PopOver>
        </div>
        <div className="office-information-form__full-width__space">
          <FormDateSelect
            name="joinDate"
            onBlur={handleBlur}
            value={values.joinDate}
            disabled={!canEdit('joinDate')}
            error={(shouldForcefullyShowError || touched.joinDate) && errors.joinDate}
            onChange={handleChange}
            label="Joining Date"
            isMandatory
            customClassName="employee-form__date"
          />
        </div>
        <div className="office-information-form__full-width__space">
          <Input
            name="recruiteeUrl"
            onBlur={handleBlur}
            value={values.recruiteeUrl}
            error={(shouldForcefullyShowError || touched.recruiteeUrl) && errors.recruiteeUrl}
            disabled={!canEdit('recruiteeUrl')}
            onChange={handleChange}
            label="Recruitee URL"
            placeholder="https://www.recruitee.com/1344"
            multiline={false}
            type="text"
            customClassName="employee-form__input"
          />
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <Input
            name="username"
            onBlur={handleBlur}
            value={values.username}
            error={duplicateErrors.username || ((shouldForcefullyShowError || touched.username) && errors.username)}
            onChange={value => {
              setDuplicateErrors(error => ({ ...error, username: null }));
              handleChange(value);
            }}
            disabled={!canEdit('username')}
            label="Employee Email"
            isMandatory
            placeholder="ribby@lftechnology.com"
            multiline={false}
            type="text"
            customClassName="employee-form__input"
          />
        </div>
        <div className="office-information-form__full-width__space">
          <FormDropdown
            name="departmentId"
            onBlur={handleBlur}
            value={values.departmentId}
            isDisabled={!canEdit('departmentId')}
            error={(shouldForcefullyShowError || touched.departmentId) && errors.departmentId}
            onChange={handleChange}
            label="Department"
            isMandatory
            options={departmentOptions}
            className="employee-form__dropdown"
          />
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <DebounceFormDropdown
            name="leaveIssuerId"
            label="Leave Issuer"
            type={LIST_TYPE.EMPLOYEE}
            value={values.leaveIssuerId}
            error={(shouldForcefullyShowError || touched.leaveIssuerId) && errors.leaveIssuerId}
            handleChange={handleChange}
            debounceOptions={leaveIssuerDebounceOptions}
            handleBlur={handleBlur}
            iconComponent={AvatarComponent}
            isDisabled={!isCreateForm}
            isClearable={true}
            isMandatory
            className="employee-form__dropdown"
            customSearchText={!isCreateForm && 'Cannot update leave issuer here'}
          />
        </div>
        <div className="office-information-form__full-width__space">
          <DebounceFormDropdown
            name="teamManagerId"
            label="Team Manager"
            type={LIST_TYPE.EMPLOYEE}
            value={values.teamManagerId}
            debounceOptions={teamManagerDebounceOptions}
            error={(shouldForcefullyShowError || touched.teamManagerId) && errors.teamManagerId}
            handleChange={handleChange}
            handleBlur={handleBlur}
            iconComponent={AvatarComponent}
            isDisabled={!isCreateForm}
            isClearable={true}
            isMandatory={isCreateForm ? true : false}
            className="employee-form__dropdown"
          />
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <FormDropdown
            name="roleIds"
            onBlur={handleBlur}
            value={values.roleIds}
            isDisabled={!canEdit('roleIds')}
            disabled={!isCreateForm}
            onChange={handleChange}
            isMulti
            label="Roles"
            options={rolesOptions}
            className="employee-form__dropdown"
          />
        </div>
        <div className="office-information-form__full-width__space">
          <div className="office-information-form__full-width__space">
            <InputWrapper label="Relevant experience before joining Leapfrog" error="" isMandatory={false}>
              <div className="office-information-form__full-width--small-input">
                <SmallInput
                  onBlur={handleBlur}
                  value={values.pastExperienceYears}
                  error={(shouldForcefullyShowError || touched.pastExperienceYears) && errors.pastExperienceYears}
                  onChange={handleChange}
                  disabled={!canEdit('pastExperienceYears')}
                  placeholder="2"
                  type="text"
                  name="pastExperienceYears"
                  label={pluralize('year', values.pastExperienceYears)}
                  customClassName="employee-form__small-input"
                />
                <SmallInput
                  name="pastExperienceMonths"
                  onBlur={handleBlur}
                  value={values.pastExperienceMonths}
                  disabled={!canEdit('pastExperienceMonths')}
                  error={(shouldForcefullyShowError || touched.pastExperienceMonths) && errors.pastExperienceMonths}
                  onChange={handleChange}
                  placeholder="2"
                  type="text"
                  label={pluralize('month', values.pastExperienceMonths)}
                  customClassName="employee-form__small-input"
                />
              </div>
            </InputWrapper>
          </div>
        </div>
      </div>
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <InputWrapper label="Availability Time" error="" isMandatory>
            <div className="d-flex align-items-center gap-10">
              <TimeInput
                onBlur={handleBlur}
                time={availabilityStartTime}
                error={(shouldForcefullyShowError || touched.availabilityStartTime) && errors.availabilityStartTime}
                onChange={handleChange}
                disabled={!canEdit('availabilityStartTime')}
                type="text"
                name="availabilityStartTime"
              />
              <div>to</div>
              <TimeInput
                name="availabilityEndTime"
                onBlur={handleBlur}
                time={availabilityEndTime}
                error={(shouldForcefullyShowError || touched.availabilityEndTime) && errors.availabilityEndTime}
                onChange={handleChange}
                disabled={!canEdit('availabilityEndTime')}
                type="text"
              />
            </div>
          </InputWrapper>
        </div>

        <div className="office-information-form__full-width__space">
          <FormDropdown
            name="workingShift"
            onBlur={handleBlur}
            value={values.workingShift}
            error={(shouldForcefullyShowError || touched.workingShift) && errors.workingShift}
            onChange={handleChange}
            isDisabled={!canEdit('workingShift')}
            label="Working Shift"
            isMandatory
            options={WORKING_SHIFTS_OPTIONS}
            className="employee-form__dropdown"
          />
        </div>
      </div>

      <div className="office-information-form__full-width__space">
        <FormRadioGroup
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.scheduledType}
          error={(shouldForcefullyShowError || touched.scheduledType) && errors.scheduledType}
          label="Working Type"
          name="scheduledType"
          disabled={!canEdit('scheduledType')}
          radioList={WORKING_TYPE_OPTIONS}
          isMandatory
        />
      </div>

      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <InputWrapper
            label="CV URL"
            error={(shouldForcefullyShowError || touched.cvUrl) && errors.cvUrl}
            isMandatory={false}
          >
            <input
              name="cvUrl"
              onBlur={handleBlur}
              value={values.cvUrl}
              disabled={!canEdit('cvUrl')}
              onChange={handleChange}
              className={classNames('input-wrapper__input', 'employee-form__input', {
                'input-wrapper__input--error': ''
              })}
              type="text"
              placeholder="ribby.frog/cv"
            />
            <a href={TEMPLATE_FOR_CV} target="_blank" rel="noopener noreferrer" className="input-wrapper__extra-detail">
              Template for CV
            </a>
          </InputWrapper>
        </div>
        <div className="office-information-form__full-width__space"></div>
      </div>

      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <InputWrapper
            label="Upload a picture"
            error={
              isCreateForm
                ? (shouldForcefullyShowError || touched.employeeImage) && errors.employeeImage
                : errors.employeeImage
            }
            isMandatory={true}
          >
            <ImageUploader
              isButtonsVisible={canEdit('employeeImage')}
              name="employeeImage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.employeeImage}
              error={
                isCreateForm
                  ? (shouldForcefullyShowError || touched.employeeImage) && errors.employeeImage
                  : errors.employeeImage
              }
            />
          </InputWrapper>
        </div>
        <div className="office-information-form__full-width__space" />
        <div className="office-information-form__full-width__space" />
        {errors.employeeImage && !isCreateForm && scrollToElement('image-upload__error')}
      </div>
    </div>
  );
};

export default OfficialInformationForm;
