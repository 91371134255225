export const LEAVE_ISSUER_CHANGE_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  CLOSED: 'Closed'
};

export const statusLabels = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  ALL: 'All'
};

export const requestPillStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  ALL: 'ALL'
};

export const requestPillStatusColor = {
  PENDING: 'yellow',
  APPROVED: 'green',
  REJECTED: 'red',
  ALL: 'blue'
};
