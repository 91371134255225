import { isFunction } from 'formik';

function dataToOptions(data, valueKey, labelKey) {
  return data.map(row => {
    return {
      value: isFunction(valueKey) ? valueKey(row) : row[valueKey],
      label: isFunction(labelKey) ? labelKey(row) : row[labelKey]
    };
  });
}

/**
 * Fetch filter options.
 *
 * Return a list of options as
 * [ {value: '...', label: '...'}, ... ]
 *
 * The value field is the unique key to separate the options.
 * The label field is displayed in the drop down menu list.
 *
 * @param {Function} fetchService Service to fetch the options.
 * @param {String | Function} valueKey A function or key that maps the data to the options value.
 * @param {String | Function} labelKey A function or key that maps the data to the options label.
 * @param {*} queryParams Any extra query parameters to the fetch service.
 */
export async function fetchOptions(fetchService, valueKey, labelKey, queryParams) {
  const response = await fetchService(queryParams);
  const { data } = response;

  return dataToOptions(data, valueKey, labelKey);
}
