import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import Stepper from '../../stepper';
import ImportSheet from '../../importSheet';
import { NEXT, PREV, COMPLETE, ERROR, getStepperNavigationList } from 'constants/createEmployeeForm';

// Components
import { error } from 'utils/toast';
import { handleError } from 'utils/errorHandler';
import { deepFalsyTransform } from 'utils/object';
import { getDuplicateErrorLabel } from 'utils/string';
import { getFullName, isUpComingStatusTerminated } from 'utils/user';

import * as userService from 'services/user';
import FormWrapperHeader from './FormWrapperHeader';
import FormWrapperBottom from './FormWrapperBottom';
import AddDesignationModal from '../leapfrogHistory/components/AddDesignationModal';
import AddEngagementStatusModal from '../leapfrogHistory/components/AddEngagementStatusModal';

import { EMPLOYEE_STATUSES } from 'constants/employeeStatus';
import { ROLES_IDS, USER_FETCH_FIELDS } from 'constants/appConstants';
import useEmployeeDebounceOptions from 'hooks/useEmployeeDebounceOptions';

const FormWrapper = props => {
  const {
    label,
    initialFormData,
    isCreateForm,
    onSubmit,
    isSubmitting,
    canEdit,
    designations,
    employees,
    departments,
    designationAreas,
    engagementStatus,
    roles,
    empId
  } = props;

  const stepperNavigationList = getStepperNavigationList(isCreateForm);

  useEffect(() => {
    stepperNavigationList.forEach(item => (item.state = ''));
  }, []);

  const [duplicateErrors, setDuplicateErrors] = useState({});
  const [isCheckingDuplicates, setIsCheckingDuplicates] = useState(false);

  const [stepperNavigationItems, setStepperNavigationItems] = useState(stepperNavigationList);

  const [currentForm, setCurrentForm] = useState(stepperNavigationList[0]);

  const [designationModal, setDesignationModal] = useState({ isOpen: false, type: 'Add' });
  const [employeeStatusModal, setEmployeeStatusModal] = useState({ isOpen: false, type: 'Add' });

  const [shouldForcefullyShowError, setShouldForcefullyShowError] = useState(false);

  useEffect(() => {
    if (!isCreateForm) {
      const stepperNavigationList = [...stepperNavigationItems];
      stepperNavigationList.pop();
      setStepperNavigationItems(() => stepperNavigationList);
    }
  }, [isCreateForm]);

  const handleNavigate = (id, action) => {
    if (action === NEXT) {
      id += 1;
    } else if (action === PREV) {
      id -= 1;
    }

    setCurrentForm(stepperNavigationItems.find(item => item.id === id));
  };

  const getDuplicateFields = async values => {
    const { empId, username } = values;

    const duplicateEntries = [];

    try {
      setIsCheckingDuplicates(true);
      if (username !== initialFormData.username) {
        const { data } = await userService.fetchAll({ email: username, empStatus: EMPLOYEE_STATUSES.join(',') });

        if (data.length > 0) {
          duplicateEntries.push({ value: 'username', label: 'Email' });
        }
      }

      if (empId !== initialFormData.empId) {
        const { data } = await userService.fetchAll({ empId, empStatus: EMPLOYEE_STATUSES.join(',') });

        if (data.length > 0) {
          duplicateEntries.push({ value: 'empId', label: 'Employee Id' });
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsCheckingDuplicates(false);
    }

    return duplicateEntries;
  };

  const checkIsValid = () =>
    isCreateForm
      ? stepperNavigationItems.every(({ state }) => state === COMPLETE) &&
        Object.values(duplicateErrors).every(error => !error)
      : stepperNavigationItems.every(({ state }) => state !== ERROR);

  const handleSubmit = async (values, actions) => {
    const cleanedValues = deepFalsyTransform(values);

    const fields = await getDuplicateFields(values);

    if (fields.length > 0) {
      fields.forEach(field => {
        actions.setFieldError(field.value, getDuplicateErrorLabel(field.label));
        setDuplicateErrors(error => ({ ...error, [field.value]: getDuplicateErrorLabel(field.label) }));

        setCurrentForm(stepperNavigationItems[0]);

        handleError('Error', { title: 'Error', message: `${field.label} already exists` });
      });

      return;
    }

    setDuplicateErrors({});

    currentForm.state = COMPLETE;

    actions.setTouched({});
    if (currentForm.id !== stepperNavigationItems.length) {
      handleNavigate(currentForm.id, NEXT);

      return;
    }

    const shouldBeRedirectedForm = stepperNavigationItems.find(({ formSchema }) => {
      return !formSchema.isValidSync(cleanedValues);
    });

    if (shouldBeRedirectedForm) {
      shouldBeRedirectedForm.state = ERROR;
      setCurrentForm(shouldBeRedirectedForm);
      setShouldForcefullyShowError(true);
      actions.validateForm();

      error({ title: 'Error', message: shouldBeRedirectedForm.detail });

      return;
    }

    const employeePayload = userService.getUserPayload(values);

    await onSubmit(employeePayload);
  };

  const ActiveComponent = currentForm.formComponent;
  const validationSchema = currentForm.formSchema;

  const [initialValues, setInitialValues] = useState(initialFormData);

  const leaveIssuerDebounceOptions = useEmployeeDebounceOptions(initialValues.leaveIssuerId);

  const teamManagerDebounceOptions = useEmployeeDebounceOptions(initialValues.teamManagerId, {
    fields: USER_FETCH_FIELDS.ROLES,
    roleIds: ROLES_IDS.TEAM_MANAGER
  });

  const appraiserDebounceOptions = useEmployeeDebounceOptions(initialValues.appraiserId);

  const coAppraiserDebounceOptions = useEmployeeDebounceOptions(initialValues.coAppraiserId);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formikProps => {
          const handleSubmit = e => {
            formikProps.handleSubmit(e);
            currentForm.state = ERROR;
          };

          return (
            <>
              <AddDesignationModal
                isOpen={designationModal.isOpen}
                designations={designations}
                areas={designationAreas}
                buttonLabel={designationModal.type}
                isCreateForm={isCreateForm}
                onClose={() => setDesignationModal({ isOpen: false })}
                editIndex={designationModal.editIndex}
                {...formikProps}
              />
              <AddEngagementStatusModal
                isCreateForm={isCreateForm}
                isOpen={employeeStatusModal.isOpen}
                buttonLabel={employeeStatusModal.type}
                engagementStatus={engagementStatus}
                onClose={editIndex => setEmployeeStatusModal({ isOpen: false, editIndex })}
                editIndex={employeeStatusModal.editIndex}
                initialValues={initialValues}
                empId={empId}
                {...formikProps}
              />

              <div className="container employee-form">
                <div className="d-flex flex-column">
                  <div className="employee-form__stepper">
                    <Stepper
                      isCreateForm={isCreateForm}
                      employeeName={getFullName(initialFormData)}
                      stepperNavigation={stepperNavigationItems}
                      onNavigate={(...args) => {
                        handleNavigate(...args);
                      }}
                      currentForm={currentForm}
                    />
                  </div>

                  {isCreateForm && (
                    <div className="employee-form__import">
                      <ImportSheet {...formikProps} setInitialValues={setInitialValues} />
                    </div>
                  )}
                </div>

                <div className="employee-form__form-wrapper">
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="form-wrapper">
                      <FormWrapperHeader
                        currentForm={currentForm.id}
                        sizeOfStepper={stepperNavigationItems.length}
                        label={currentForm.label}
                      />
                      <ActiveComponent
                        {...formikProps}
                        shouldForcefullyShowError={shouldForcefullyShowError}
                        duplicateErrors={duplicateErrors}
                        setDuplicateErrors={setDuplicateErrors}
                        leaveIssuerDebounceOptions={currentForm.id === 1 && leaveIssuerDebounceOptions}
                        teamManagerDebounceOptions={currentForm.id === 1 && teamManagerDebounceOptions}
                        appraiserDebounceOptions={currentForm.id === 4 && appraiserDebounceOptions}
                        coAppraiserDebounceOptions={currentForm.id === 4 && coAppraiserDebounceOptions}
                        isCreateForm={isCreateForm}
                        canEdit={canEdit}
                        currentForm={currentForm}
                        sizeOfStepper={stepperNavigationItems.length}
                        onNavigate={handleNavigate}
                        departments={currentForm.id === 1 && departments}
                        employees={employees}
                        roles={currentForm.id === 1 && roles}
                        openDesignationModal={(type, editIndex) =>
                          setDesignationModal({ isOpen: true, type, editIndex })
                        }
                        openEmployeeStatusModal={(type, editIndex) =>
                          setEmployeeStatusModal({ isOpen: true, type, editIndex })
                        }
                      />
                      <FormWrapperBottom
                        isModalShown={isUpComingStatusTerminated(formikProps.values.empStatusHistory)}
                        alertMessage={{
                          text: `All the leave credits of ${formikProps.values.firstName} ${formikProps.values.lastName} will be cleared and can't be reverted back if status is updated to terminated. Do you want to continue?`
                        }}
                        onSubmit={formikProps.handleSubmit}
                        onNavigate={handleNavigate}
                        currentForm={currentForm.id}
                        sizeOfStepper={stepperNavigationItems.length}
                        isValid={checkIsValid()}
                        isLoading={isSubmitting || formikProps.isValidating || isCheckingDuplicates}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default FormWrapper;
