import { APPROVED, SUBMITTED, WORK_LOCATION } from 'constants/worklog';

import { convertMinuteToDisplayFormat } from './time';
import { checkAttendanceUnfrozen } from './attendance';

/**
 * Calculates the total duration of worklogs with status SUBMITTED or APPROVED.
 *
 * @param {CalendarData} data[] - The calendar data array.
 * @returns {string} - The total duration formatted as a display string.
 */
export function getWorklogTotalDuration(data) {
  const totalDuration = data.reduce((acc, cur) => {
    if (![SUBMITTED, APPROVED].includes(cur.worklog?.status)) {
      return acc;
    }

    return (acc += cur.worklog?.totalDuration || 0);
  }, 0);

  return convertMinuteToDisplayFormat(totalDuration);
}

/**
 * Generates a summary of work status data.
 *
 * @param {CalendarData} data[] - The calendar data array.
 * @returns {WorkSummary} - An object containing the summary of work status data.
 */
export function getWorkStatusSummaryData(data, unfreezeRequests) {
  return data.reduce(
    (acc, item) => {
      const { worklog, leave, date } = item;

      const isAttendanceUnfrozen = checkAttendanceUnfrozen(date, unfreezeRequests);

      if (leave && ((leave.isAutomated && !isAttendanceUnfrozen) || !leave.isAutomated)) {
        acc.leave++;
        return acc;
      }

      if (worklog && worklog.location === WORK_LOCATION.HOME) {
        acc.wfh++;
        return acc;
      }

      if (worklog && worklog.location === WORK_LOCATION.OFFICE) {
        acc.wfo++;
      }

      return acc;
    },
    { wfh: 0, wfo: 0, leave: 0 }
  );
}
