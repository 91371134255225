import React from 'react';
import { Link } from 'react-router-dom';
import pinterpolate from 'pinterpolate';

const toTextComponent = string => {
  return string && (() => <>{string}</>);
};

const toLinkComponent = (label, url, params = {}, blank) => {
  return (
    label &&
    (() => (
      <a href={pinterpolate(url, params)} className="break-word" target={blank && '_blank'} rel="noopener noreferrer">
        {label}
      </a>
    ))
  );
};

const toRouteComponent = (label, url, params = {}, target) => {
  return (
    label &&
    (() => (
      <Link to={pinterpolate(url, params)} target={target}>
        {label}
      </Link>
    ))
  );
};

export { toTextComponent, toLinkComponent, toRouteComponent };
