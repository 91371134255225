import React, { useMemo } from 'react';
import classNames from 'classnames';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';

import { getFormattedDate, isDateInFuture, isDateToday } from 'utils/date';

import useLazyLoading from 'hooks/useLazyLoading';

import { fetchUserAllocations } from 'services/user';

import { PROJECT_ALLOCATION_DATE_FORMAT } from 'constants/appConstants';

import ProjectAllocationList from './ProjectAllocationList';

const Projects = ({ startDate, endDate, employeeId: userId, isSelf }) => {
  const { loading, dataList: allocationData, lastRef } = useLazyLoading(fetchUserAllocations, [
    { startDate, endDate, userId, sortBy: 'endDateNullFirst', order: 'desc' }
  ]);

  const isLoadingAlone = loading && allocationData.length < 1;

  const sortedProjectAllocations = useMemo(() => {
    if (!allocationData) {
      return [];
    }

    return allocationData.sort((a, b) => {
      const isCurrentProjectA = !a.endDate || isDateToday(a.endDate) || isDateInFuture(a.endDate);
      const isCurrentProjectB = !b.endDate || isDateToday(b.endDate) || isDateInFuture(b.endDate);

      if (isCurrentProjectA && !isCurrentProjectB) {
        return -1;
      } else if (!isCurrentProjectA && isCurrentProjectB) {
        return 1;
      }

      // If both are current projects or both are not, or if neither has an end date,
      // then sort based on date
      const dateA = a.endDate || a.potentialEndDate;
      const dateB = b.endDate || b.potentialEndDate;

      return new Date(dateB) - new Date(dateA);
    });
  }, [allocationData]);

  if (isLoadingAlone) {
    return (
      <div className="mt-120">
        <Loading />
      </div>
    );
  }

  return (
    <ul className="projects project-container custom-scroll-bar overflow-auto">
      {sortedProjectAllocations.map(projectAllocation => (
        <ProjectAllocationList
          childRef={lastRef}
          projectAllocation={projectAllocation}
          key={projectAllocation.id}
          className="pb-24"
          isSelf={isSelf}
        />
      ))}

      {loading && (
        <div className="allocation-lazy-loading">
          <Loading />
        </div>
      )}
    </ul>
  );
};

const AllProjectAllocations = ({ startDate, endDate, employeeId, employeeName, hasCurrentAllocations, isSelf }) => {
  const viewPastAllocationButton = ({ onClick }) => {
    return (
      <button className="project-view-more-button" onClick={onClick}>
        {hasCurrentAllocations ? 'View All Allocations' : 'View Past Allocations'}
      </button>
    );
  };

  return (
    <LFModal
      TriggerBtn={viewPastAllocationButton}
      title={{ text: `Allocation history of ${employeeName}` }}
      subTitle={{
        text: `Allocation till ${getFormattedDate(endDate, PROJECT_ALLOCATION_DATE_FORMAT)}`
      }}
      htmlContent={<Projects startDate={startDate} endDate={endDate} employeeId={employeeId} isSelf={isSelf} />}
      renderFooter={false}
      closeButton={true}
      className={classNames({ 'allocation-history-modal': isSelf & hasCurrentAllocations })}
    />
  );
};

export default AllProjectAllocations;
