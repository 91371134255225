import React from 'react';
import classNames from 'classnames';

import { Avatar } from '@vyaguta/vyaguta-ui';

import { ALPHANUMERIC } from 'constants/regex';
import { PROJECT_LOGO_LETTERS, SINGLE_PROJECT_LOGO_LETTERS } from 'constants/allocation';

import { getInitials } from 'utils/string';

function ProjectLogo(props) {
  const { logoUrl, name, size, className, isProjectSingleLetter, classes } = props;

  const getProjectInitials = (projectName, maxLength) => {
    const initials = getInitials(projectName)
      .substring(0, maxLength)
      .replace(ALPHANUMERIC, '');

    return initials;
  };

  const projectLogoInitialsLength = isProjectSingleLetter ? SINGLE_PROJECT_LOGO_LETTERS : PROJECT_LOGO_LETTERS;

  return (
    <div className={classNames('project-logo', className)}>
      <Avatar image={logoUrl} alt={name ?? ''} className="project-logo__avatar">
        <div className={classNames(`project-logo__substitute project-logo__substitute--${size}`, classes?.substitute)}>
          {name ? getProjectInitials(name, projectLogoInitialsLength) : ''}
        </div>
      </Avatar>
    </div>
  );
}

export default ProjectLogo;
