import React from 'react';

import { Empty as EmptyPage } from '@vyaguta/vyaguta-ui';

const Empty = ({ message, children, className }) => {
  return <EmptyPage message={message} children={children} className={className} />;
};

Empty.defaultProps = {
  message: 'No data to show.'
};

export default Empty;
