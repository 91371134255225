import moment from 'moment';

import { BUFFER_DAYS } from 'constants/date';
import { PERIOD_TYPE } from 'constants/jump';

export const getYearFilters = (period, year) => {
  if (!period || !year) return;
  if (period === PERIOD_TYPE.MID_YEAR) return { startDate: `${year}-01-01`, endDate: `${year}-06-30` };
  if (period === PERIOD_TYPE.YEAR_END) return { startDate: `${year}-07-01`, endDate: `${year}-12-31` };

  return { startDate: `${year}-01-01`, endDate: `${year}-12-31` };
};

export const getDefaultFilters = () => {
  const currentMonth =
    moment()
      .subtract(BUFFER_DAYS, 'days')
      .month() + 1;

  const today = moment();

  const getPeriod = month => {
    if (month > 6) {
      return PERIOD_TYPE.YEAR_END;
    }
    return PERIOD_TYPE.MID_YEAR;
  };

  return {
    year: today.year(),
    period: getPeriod(currentMonth)
  };
};
