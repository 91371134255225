export const PENDING = 'REQUESTED';

export const LEAVE = 'Leave';
export const MISSING = 'Missing';
export const ATTENDANCE_PENDING = 'Attendance Pending';
export const UPDATE_PENDING = 'Update Pending';
export const WFH_APPROVED = 'WFH approved';
export const WFH_PENDING = 'WFH pending';

export const WORKLOG_HOME = 'Worked From Home';
export const WORKLOG_OFFICE = 'Worked From Office';

export const WORKLOG_HOME_PRESENT = 'Work From Home';
export const WORKLOG_OFFICE_PRESENT = 'Work From Office';

export const ON_LEAVE = 'On Leave';
export const LEAVE_WITHOUT_PAY = 'Leave Without Pay';
