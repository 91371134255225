import config from 'config';

export const GOAL_TYPE = {
  GOAL: 'Goal',
  ACHIEVEMENT: 'Achievement'
};

export const PERIOD_TYPE = {
  MID_YEAR: 'mid_year',
  YEAR_END: 'year_end',
  WHOLE_YEAR: 'whole_year'
};

export const periodOptions = [
  { label: 'Mid Year', value: PERIOD_TYPE.MID_YEAR },
  { label: 'Year End', value: PERIOD_TYPE.YEAR_END },
  { label: 'Whole Year', value: PERIOD_TYPE.WHOLE_YEAR }
];

export const urls = {
  FEEDBACK_DETAIL_VIEW: `${config.vyagutaURI}${config.vyaguta.jump}${config.endpoints.jump.app.feedbackDetail}`,
  KPI_VIEW: `${config.vyagutaURI}${config.vyaguta.jump}`
};
