import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import {
  WORK_STATUS,
  N_OF_MEDIAN_TIME,
  INCLUDED_COUNTRIES_FOR_WORKSHIFT,
  EXCLUDED_DESIGNATION_FOR_WORKSHIFT
} from 'constants/dashboard';

import { SELF } from 'constants/fetchTypes';
import { mixPanelEvents } from 'constants/mixPanel';
import { ATTENDANCE_URL } from 'constants/endpoints';
import { APPROVED, SUBMITTED } from 'constants/attendanceStatus';

import * as mixpanel from 'services/mixPanel';
import * as attendanceService from 'services/attendance';

import { isObjectEmpty } from 'utils/object';
import { handleError } from 'utils/errorHandler';
import { convertTimeToAMPM, getMedianTime } from 'utils/time';
import { subtractDays, getFormattedDate, getCurrentWeekRange, filterDataByDateRange } from 'utils/date';

import useCurrentFiscalYear from 'hooks/useCurrentFiscalYear';

import { setUserSelfAttendance } from 'slices/selfAttendanceSlice';

import ClockInTime from '../ClockInTime';
import WorkStatusCount from '../WorkStatusCount';
import { ClockinHistoryModal } from '../ClockinHistory/ClockinHistoryModal';
import { ClockinHistoryButton } from '../ClockinHistory/ClockinHistoryButton';
import { getWorkStatusSummaryData, getWorklogTotalDuration } from 'utils/leave';

export default function AllocationCardFooter() {
  const dispatch = useDispatch();

  const {
    value: { user }
  } = useSelector(state => state.information);

  const shouldShowWorkshift =
    INCLUDED_COUNTRIES_FOR_WORKSHIFT.includes(user?.country) &&
    !EXCLUDED_DESIGNATION_FOR_WORKSHIFT.includes(user?.designation?.name);

  const [totalDuration, setTotalDuration] = useState(0);

  const [medianTime, setMedianTime] = useState(null);
  const [clockinHistory, setClockinHistory] = useState([]);

  const [calendarData, setCalendarData] = useState([]);
  const [isCalendarDataLoading, setIsCalendarDataLoading] = useState(false);

  const [workStatusSummaryData, setWorkStatusSummaryData] = useState({});

  const [isSelfAttendanceLoading, setIsSelfAttendanceLoading] = useState(true);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  const [unfreezeRequests, setUnfreezeRequests] = useState([]);

  const currentFiscalYear = useCurrentFiscalYear();

  const { missing: selfMissing, pending: selfPending } = useSelector(state => state.selfAttendance);

  const { self } = mixPanelEvents.view;

  const isPendingZero = selfPending === 0;
  const isMissingZero = Array.isArray(selfMissing);

  const getCalendarMedianTime = calendarData => {
    const times = calendarData.map(({ checkinTime }) => checkinTime).filter(data => !!data);

    return getMedianTime(times);
  };

  useEffect(() => {
    if (isObjectEmpty(currentFiscalYear)) {
      return;
    }

    const endDate = getFormattedDate();
    const startDate = getFormattedDate(currentFiscalYear.startDate);

    (async () => {
      const filters = {
        fetchType: SELF,
        startDate,
        endDate,
        fields: 'worklog,missing'
      };

      try {
        setIsSelfAttendanceLoading(true);

        const { data: selfData } = await attendanceService.fetchSummary(filters);

        dispatch(setUserSelfAttendance({ missing: selfData.missing || 0, pending: selfData.worklog.pending || 0 }));
      } catch (err) {
        handleError(err);
      } finally {
        setIsSelfAttendanceLoading(false);
      }
    })();
  }, [currentFiscalYear]);

  const fetchCalendarData = async () => {
    if (!user?.id) {
      return;
    }

    const currentDate = getFormattedDate();
    const calendarDataSize = 40;

    const [weekStartDate, weekEndDate] = getCurrentWeekRange(currentDate);
    const medianStartDate = subtractDays(currentDate, N_OF_MEDIAN_TIME);

    const params = {
      fetchType: SELF,
      startDate: medianStartDate,
      endDate: weekEndDate,
      withCheckinTime: true,
      size: calendarDataSize
    };

    try {
      setIsCalendarDataLoading(true);

      const [data, unfreezeRequestsData] = await Promise.all([
        await attendanceService.fetch(params),
        await attendanceService.fetchUnfreezeRequestsByUserId(user.id)
      ]);

      const weeklyData = filterDataByDateRange({ data, key: 'date', startDate: weekStartDate, endDate: weekEndDate });
      const medianData = filterDataByDateRange({ data, key: 'date', startDate: medianStartDate, endDate: currentDate });

      const medianTime = getCalendarMedianTime(medianData);
      const totalDuration = getWorklogTotalDuration(weeklyData);
      const workStatusSummary = getWorkStatusSummaryData(medianData, unfreezeRequestsData);

      const formattedMedianTime = medianTime && convertTimeToAMPM(medianTime);

      setCalendarData(data);
      setUnfreezeRequests(unfreezeRequestsData);
      setClockinHistory(medianData);
      setTotalDuration(totalDuration);
      setMedianTime(formattedMedianTime);
      setWorkStatusSummaryData(workStatusSummary);
    } catch (error) {
      handleError(error);
    } finally {
      setIsCalendarDataLoading(false);
    }
  };

  useEffect(() => {
    fetchCalendarData();
  }, [user?.id]);

  const WORK_STATUS_MAPPING = {
    wfo: WORK_STATUS.WFO,
    wfh: WORK_STATUS.WFH,
    leave: WORK_STATUS.LEAVE
  };

  const isLoading = isCalendarDataLoading || isSelfAttendanceLoading;

  if (isLoading) {
    return (
      <>
        <SkeletonTheme baseColor="#fdfdfd" highlightColor="#f0f3f5">
          <Skeleton className="weekly-record-card-loader wp-100" height={62} />
          {shouldShowWorkshift && <Skeleton className="median-office-time-card-loader wp-100" height={86} />}
        </SkeletonTheme>
      </>
    );
  }

  return (
    <>
      <div className="d-flex actions flex-direction-column">
        <div className="weekly-action d-flex">
          <div className="actions__container">
            <div className="actions__footer justify-content-center">
              <div className="footer__log-content">
                <div className="footer__top">{totalDuration}</div>
                <div>
                  <p className="footer__bottom-text">logged this week</p>
                </div>
              </div>
            </div>
          </div>
          <div className="pending-action">
            <a
              className={classNames(
                'pending-action__item',
                { 'pending-action__item--empty': isPendingZero },
                { 'pending-action__item--pending': !isPendingZero }
              )}
              href={ATTENDANCE_URL}
              target={'_blank'}
              onClick={() => {
                mixpanel.trackEvent(self.worklogPending);
              }}
              rel="noreferrer"
            >
              {selfPending}
              <div className="pending-action__title">Worklog Pending</div>
            </a>
            <a
              className={classNames(
                'pending-action__item',
                { 'pending-action__item--empty': isMissingZero },
                { 'pending-action__item--missed': !isMissingZero }
              )}
              href={ATTENDANCE_URL}
              target="_blank"
              onClick={() => {
                mixpanel.trackEvent(self.attendanceMissed);
              }}
              rel="noreferrer"
            >
              <div>{isMissingZero ? 0 : selfMissing}</div>
              <div className="pending-action__title">Attendance Missed</div>
            </a>
          </div>
        </div>
      </div>

      {shouldShowWorkshift && (
        <div className="actions median-office-time px-16 pbpx-12">
          <ClockinHistoryModal
            TriggerBtn={() => <ClockinHistoryButton setIsHistoryModalOpen={setIsHistoryModalOpen} />}
            isOpen={isHistoryModalOpen}
            isEdit={false}
            onClose={() => setIsHistoryModalOpen(false)}
            clockinHistoryData={clockinHistory}
            isClockinHistoryLoading={isCalendarDataLoading}
            medianClockInTime={medianTime}
            unfreezeRequests={unfreezeRequests}
          />
          <div className="median-office-time__main d-flex justify-content-between align-items-center">
            <ClockInTime medianClockInTime={medianTime} />

            {Object.entries(workStatusSummaryData).map(([status, count], index) => {
              const workStatus = WORK_STATUS_MAPPING[status];
              const isLastChild = index === Object.entries(workStatusSummaryData).length - 1;

              return (
                <WorkStatusCount
                  key={status}
                  status={workStatus}
                  count={count}
                  className={!isLastChild ? 'border-right-grey-15' : ''}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
