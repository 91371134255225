import React, { useMemo } from 'react';

import useTitle from 'hooks/useTitle';
import { useFetchAssetsById } from 'hooks/query/useAssetsQuery';

import Table from 'components/common/table';

import { getFormattedDate } from 'utils/date';

import { DATE_FORMAT_FOR_DISPLAY } from 'constants/appConstants';

const EmployeeAssets = props => {
  const { employeeId: id } = props;

  useTitle('Assets');

  const { data, isLoading } = useFetchAssetsById(id);

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'assetTag'
      },
      {
        Header: 'Name',
        accessor: 'assetName'
      },
      {
        Header: 'Type',
        accessor: 'assetType'
      },
      {
        Header: 'Added Date',
        accessor: 'addedDate',
        Cell: row => <span>{getFormattedDate(row.original.addedDate, DATE_FORMAT_FOR_DISPLAY)}</span>
      }
    ];
  }, []);

  return (
    <main>
      <div className="container">
        <div className="full-scope-card mt-15">
          <div className="full-scope-card__header table-header name-wrap d-flex justify-content-between user-select-none">
            <div>
              <span className="table-header__title">Assets</span>
            </div>
          </div>
          <Table
            data={data}
            columns={columns}
            loading={isLoading}
            showPagination={false}
            minRows={data?.length}
            customClassName="employees-assets-table rt-cursor-default"
          />
        </div>
      </div>
    </main>
  );
};

export default EmployeeAssets;
