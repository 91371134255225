import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { FiExternalLink } from 'react-icons/fi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import React, { useEffect, useMemo, useState } from 'react';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { isObjectEmpty } from 'utils/object';
import { handleError } from 'utils/errorHandler';
import { getFormattedDate, isDateBetweenInclusive } from 'utils/date';
import { interpolate, pluralize, withoutProtocolAndSubDomainURL } from 'utils/string';

import * as routes from 'constants/routes';
import * as permissions from 'constants/permissions';
import {
  DATE_FORMAT_FOR_DISPLAY,
  EVENTS_REFERENCE_PERIOD,
  EVENTS_REFERENCE_PERIOD_PILL_TABS
} from 'constants/appConstants';
import { REMINDER_TITLE_LENGTH, TOOLTIP_LIMIT_LENGTH } from 'constants/string';

import Table from 'components/common/table';
import PopOver from 'components/common/popover';
import PillTab from 'components/common/pillTab';
import EditDelete from 'components/common/editDelete';
import TableHeader from 'components/common/table/TableHeader';

import * as reminderService from 'services/reminder';

import useQuery from 'hooks/useQuery';
import useTitle from 'hooks/useTitle';
import useCurrentFiscalYear from 'hooks/useCurrentFiscalYear';

import { deleteReminders } from 'actions/data/information';

const ReminderList = props => {
  useTitle('Reminders');

  const [query, setQuery] = useQuery(props);

  const dispatch = useDispatch();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [reminderList, setReminderList] = useState([]);
  const [columnToSortBy, setColumnToSortBy] = useState('title');
  const [isAscendingOrdered, setIsAscendingOrdered] = useState(false);

  const { type } = query;

  const {
    value: { permissions: userPermissions }
  } = useSelector(state => state.information);

  const currentFiscalYear = useCurrentFiscalYear();

  const handleEdit = row => {
    history.push(interpolate(routes.UPDATE_REMINDER, { id: row.original.id }));
  };

  const handleDelete = async row => {
    try {
      await reminderService.remove(row.original.id);

      if (
        isDateBetweenInclusive(
          getFormattedDate(),
          getFormattedDate(row.original.startDate),
          getFormattedDate(row.original.endDate)
        )
      ) {
        dispatch(deleteReminders(row.original.id));
      }

      toast.success({
        title: 'Success',
        message: 'Reminder deleted successfully.'
      });

      fetchReminders();
    } catch (error) {
      handleError(error);
    }
  };

  const getLinkCell = () => {
    return {
      Header: <TableHeader label="Link" identifier="redirectUrl" />,
      accessor: 'redirectUrl',
      sortable: false,
      filterable: false,
      maxWidth: 300,
      Cell: props => {
        const isNotFirstRow = props.index !== 0;
        const linkExceedsLimit = props.original.redirectUrl.length > TOOLTIP_LIMIT_LENGTH;
        const top = linkExceedsLimit ? -40 : -20;
        const moreProps = isNotFirstRow ? {} : createPopperOptions({ zIndex: 10, top });
        return (
          <>
            {props.original.redirectUrl !== '-' ? (
              <PopOver
                interactive
                html={
                  <div className="tooltip-info word-break">
                    <p className="tooltip-info__title">{props.original.redirectUrl}</p>
                  </div>
                }
                position="top-start"
                visible={withoutProtocolAndSubDomainURL(props.original.redirectUrl).length}
                {...moreProps}
              >
                <a
                  href={props.original.redirectUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex align-items-center table-link"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <div className="table-link__text ellipsis">
                    {withoutProtocolAndSubDomainURL(props.original.redirectUrl)}
                  </div>
                  <div className="center">
                    <FiExternalLink className="table-link__icon" size={12} />
                  </div>
                </a>
              </PopOver>
            ) : (
              <>{props.original.redirectUrl}</>
            )}
          </>
        );
      }
    };
  };

  const getDeleteCell = () => {
    const canEdit = userPermissions[permissions.REMINDERS.UPDATE];
    const canDelete = userPermissions[permissions.REMINDERS.DELETE];

    const canEditOrDelete = canEdit || canDelete;

    return {
      Header: '',
      width: 6,
      className: 'margin-left-auto cursor-default',
      Cell: row =>
        canEditOrDelete ? (
          <span
            className="verticle-ellipse cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <PopOver
              interactive
              trigger="click"
              theme="light"
              size="regular overflow-menu__regular"
              position="left-start"
              disableOnScroll={true}
              popperOptions={{
                modifiers: {
                  addZIndex: {
                    enabled: true,
                    order: 810,
                    fn: data => ({
                      ...data,
                      styles: {
                        ...data.styles,
                        zIndex: 10
                      }
                    })
                  }
                }
              }}
              html={
                <EditDelete
                  onDelete={() => handleDelete(row)}
                  onEdit={() => handleEdit(row)}
                  canEdit={canEdit}
                  canDelete={canDelete}
                  type="reminder"
                  title={row.original.title}
                />
              }
            >
              <BsThreeDotsVertical size={20} />
            </PopOver>
          </span>
        ) : null
    };
  };

  const fetchReminders = async () => {
    setIsDataLoading(true);

    try {
      const { data } = await reminderService.fetchAll(query);

      const formattedData = data.map(dataItem => {
        const description = dataItem.description || '-';
        const redirectUrl = dataItem.redirectUrl || '-';
        const startDate = getFormattedDate(dataItem.startDate);
        const endDate = getFormattedDate(dataItem.endDate);

        return {
          ...dataItem,
          description,
          redirectUrl,
          startDate,
          endDate
        };
      });

      setReminderList(formattedData);
    } catch (error) {
      handleError(error);
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    if (!type) {
      // if type is not present in query params.
      setQuery({ type: EVENTS_REFERENCE_PERIOD.UPCOMING.toLowerCase() });
    }
  }, [props.location.search, type, currentFiscalYear]);

  useEffect(() => {
    if (isObjectEmpty(currentFiscalYear)) {
      return;
    }

    fetchReminders();
  }, [props.location.search, type, currentFiscalYear]);

  const getRowProps = (state, rowInfo) => {
    return {
      className: 'align-items-center d-flex',
      onClick: e => {
        if (!userPermissions[permissions.REMINDERS.UPDATE]) {
          return;
        }

        const url = interpolate(routes.UPDATE_REMINDER, { id: rowInfo.original.id });

        if (e.ctrlKey || e.metaKey) {
          window.open(url);
        } else {
          history.push(url);
        }
      }
    };
  };

  const createPopperOptions = customStyles => {
    return {
      distance: 5,
      popperOptions: {
        modifiers: {
          flip: {
            enabled: false
          },
          addZIndex: {
            enabled: true,
            order: 812,
            fn: data => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  ...customStyles
                }
              };
            }
          }
        }
      }
    };
  };

  const getColumns = useMemo(
    () => (isAscendingOrdered, setIsAscendingOrdered, columnToSortBy, setColumnToSortBy) => [
      {
        Header: <TableHeader label="SN." identifier="sn" />,
        accessor: 'sn',
        maxWidth: 56,
        sortable: false,
        filterable: false,
        Cell: row => {
          const index = row.viewIndex + 1;
          return <span className="capital-text employees__table-text">{index < 10 ? `0${index}` : index}</span>;
        }
      },
      {
        Header: (
          <TableHeader
            label="Title"
            identifier="title"
            columnToSortBy={columnToSortBy}
            isAscendingOrdered={isAscendingOrdered}
            onClick={() => {
              if (columnToSortBy !== 'title') {
                setIsAscendingOrdered(true);
              } else {
                setIsAscendingOrdered(!isAscendingOrdered);
              }
              setColumnToSortBy('title');
            }}
          />
        ),
        accessor: 'title',
        maxWidth: 700,
        Cell: props => {
          const isNotFirstRow = props.index !== 0;
          const moreProps = isNotFirstRow ? {} : createPopperOptions({ zIndex: 10, top: -40 });
          return (
            <PopOver
              interactive
              html={
                <div className="tooltip-info word-break">
                  <p className="tooltip-info__title">{props.original.title}</p>
                </div>
              }
              position="top-start"
              visible={props.original.title.length > REMINDER_TITLE_LENGTH}
              {...moreProps}
            >
              <div className="ellipsis">{props.original.title}</div>
            </PopOver>
          );
        }
      },
      {
        Header: (
          <TableHeader
            label="Start Date"
            identifier="startDate"
            columnToSortBy={columnToSortBy}
            isAscendingOrdered={isAscendingOrdered}
            onClick={() => {
              if (columnToSortBy !== 'startDate') {
                setIsAscendingOrdered(true);
              } else {
                setIsAscendingOrdered(!isAscendingOrdered);
              }
              setColumnToSortBy('startDate');
            }}
          />
        ),
        accessor: 'startDate',
        maxWidth: 180,
        Cell: props => <span>{getFormattedDate(props.original.startDate, DATE_FORMAT_FOR_DISPLAY)}</span>
      },
      {
        Header: (
          <TableHeader
            label="End Date"
            identifier="endDate"
            columnToSortBy={columnToSortBy}
            isAscendingOrdered={isAscendingOrdered}
            onClick={() => {
              if (columnToSortBy !== 'endDate') {
                setIsAscendingOrdered(true);
              } else {
                setIsAscendingOrdered(!isAscendingOrdered);
              }
              setColumnToSortBy('endDate');
            }}
          />
        ),
        accessor: 'endDate',
        maxWidth: 180,
        Cell: props => <span>{getFormattedDate(props.original.endDate, DATE_FORMAT_FOR_DISPLAY)}</span>
      }
    ],
    []
  );

  const onToggleChange = async newValue => {
    setQuery({ ...newValue });
  };

  return (
    <div className="full-scope-card">
      <div className="full-scope-card__header table-header name-wrap">
        <div>
          <div className="table-title">Reminders</div>
          <div className="table-subtitle">
            Showing {reminderList.length} {pluralize('Result', reminderList.length)}
          </div>
        </div>
        <div className="table-header__right">
          <PillTab
            tabs={EVENTS_REFERENCE_PERIOD_PILL_TABS}
            onTabClick={value => onToggleChange({ type: value })}
            isActive={value => value === query?.type?.toLowerCase()}
          />
        </div>
      </div>
      <div className={classNames('full-scope-card__content')}>
        <Table
          loading={isDataLoading}
          data={reminderList}
          columns={[
            ...getColumns(isAscendingOrdered, setIsAscendingOrdered, columnToSortBy, setColumnToSortBy),
            getLinkCell(),
            getDeleteCell()
          ]}
          showPagination={false}
          noDataText="No reminders to show."
          getTrProps={getRowProps}
        />
      </div>
    </div>
  );
};

export default ReminderList;
