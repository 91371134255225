import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import InputLabel from './InputLabel';

const FormSelect = props => {
  const {
    label,
    isMandatory,
    name,
    error,
    placeholder,
    value,
    options,
    handleChange,
    handleInputChange,
    noOptionsMessage,
    isLoading,
    disabled,
    isClearable,
    uniqueIdentifier,
    iconComponent
  } = props;

  return (
    <div className="form-group">
      {label && InputLabel(label, isMandatory)}
      <Select
        options={options}
        name={name}
        className={classnames('', { 'child-border-red': error })}
        value={
          value &&
          options.find(option =>
            uniqueIdentifier ? option.value[uniqueIdentifier] === value[uniqueIdentifier] : option.value === value
          )
        }
        isDisabled={disabled}
        isClearable={isClearable}
        onChange={selectedOption =>
          handleChange({ target: { name: name, value: selectedOption ? selectedOption.value : null } })
        }
        placeholder={placeholder}
        onInputChange={handleInputChange}
        isLoading={isLoading}
        noOptionsMessage={noOptionsMessage}
        formatOptionLabel={iconComponent}
      />
      {error && <span className="error-block">{error}</span>}
    </div>
  );
};

FormSelect.propTypes = {
  error: PropTypes.any,
  handleChange: PropTypes.any,
  handleInputChange: PropTypes.any,
  noOptionsMessage: PropTypes.any,
  isLoading: PropTypes.bool,
  isMandatory: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired
    })
  ),
  uniqueIdentifier: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any
};

export default FormSelect;
