import { Formik } from 'formik';
import classNames from 'classnames';
import React, { useEffect, useState, useCallback } from 'react';

import { success } from 'utils/toast';
import { addRole } from 'services/user';
import { fetchRoles } from 'services/user';
import CreateEditRole from './CreateEditRole';
import { handleError } from 'utils/errorHandler';
import { addRoleSchema } from 'schemas/addRoleSchema';
import Loading from 'components/common/loading/Loading';
import useEmployeeDebounceOptions from 'hooks/useEmployeeDebounceOptions';

export const AddUserModal = props => {
  const { setShowAddUserModal, setIsRoleAdded } = props;
  const [selectedRolesList, setSelectedRolesList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userFullName, setUserFullName] = useState('');

  const employeeDebounceOptions = useEmployeeDebounceOptions();

  const handleSubmit = async values => {
    try {
      const { userId, roles } = values;
      setIsLoading(true);

      await addRole(userId, roles);

      success({
        title: 'Success',
        message: `You have successfully added ${userFullName} for the role.`
      });
      setIsRoleAdded(true);
      setShowAddUserModal(false);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserRoles = useCallback(async () => {
    try {
      const data = await fetchRoles(selectedUser);

      setSelectedRolesList(data?.roles || []);
    } catch (err) {
      handleError(err);
    }
  }, [selectedUser]);

  const getUserName = (employeeDebounceOptions, options) => {
    const userFullName = employeeDebounceOptions.find(({ value }) => value === options.target.value).label;

    return userFullName;
  };

  useEffect(() => {
    if (!selectedUser) {
      return;
    }

    fetchUserRoles();
  }, [selectedUser, fetchUserRoles]);

  return (
    <div className="ReactModal__Overlay ReactModal__Overlay--after-open lf-modal__overlay animated-modal">
      <div
        className="ReactModal__Content ReactModal__Content--after-open lf-modal"
        tabIndex="-1"
        role="dialog"
        aria-modal="true"
      >
        <div className="lf-modal__content-wrapper">
          <div className="lf-modal__header lf-modal__header--border">
            <div className="lf-modal__close-icon"></div>
            <div className="lf-modal__title lf-modal__title--color">Add User</div>
          </div>
          <div className="lf-modal__body lf-modal__body--border pt-5x">
            <Formik
              initialValues={{ userId: null, roles: null }}
              validationSchema={addRoleSchema}
              onSubmit={handleSubmit}
              render={props => {
                const { handleSubmit, errors, touched } = props;

                return (
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="lf-input-wrap ">
                          <div className="lf-input lf-input--required p-1rem pt-0">
                            <CreateEditRole
                              setSelectedRolesList={setSelectedRolesList}
                              selectedRolesList={selectedRolesList}
                              touched={touched}
                              errors={errors}
                              employeeDebounceOptions={employeeDebounceOptions}
                              onUserBlur={() => props.setFieldTouched('userId')}
                              onUserSelectChange={options => {
                                setSelectedUser(options?.target.value);
                                setUserFullName(getUserName(employeeDebounceOptions[1], options));
                                props.setFieldValue('userId', options?.target.value);
                              }}
                              onRoleBlur={() => props.setFieldTouched('roles')}
                              afterChange={() => {
                                props.setFieldValue('roles', selectedRolesList);
                              }}
                              selectedUser={selectedUser}
                            />

                            {touched.roles && errors.roles && <div className="error-block"> {errors.roles} </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lf-modal__footer pb-0 pl-0">
                      <button
                        className={classNames('btn btn--large btn--primary mr-16 py-8 px-12', {
                          'btn--loading': isLoading
                        })}
                        type="submit"
                        disabled={isLoading}
                      >
                        <span className="btn__label">Save & Close</span>
                        {isLoading && <Loading />}
                      </button>
                      <button
                        className={classNames('btn btn--large btn--outlined-grey py-8 px-12')}
                        onClick={() => setShowAddUserModal(false)}
                        disabled={isLoading}
                      >
                        <span className="btn__label">Cancel</span>
                      </button>
                    </div>
                  </form>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
