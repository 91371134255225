import moment from 'moment';
import { IoMdArrowBack } from 'react-icons/io';
import React, { useCallback, useEffect, useState } from 'react';

import history from 'utils/history';
import { handleError } from 'utils/errorHandler';

import useTitle from 'hooks/useTitle';

import * as routes from 'constants/routes';

import * as reminderService from 'services/reminder';

import ReminderForm from './ReminderForm';
import Loading from 'components/common/loading/Loading';

const CreateEditReminder = props => {
  const reminderId = props.match.params && props.match.params.id;
  const title = reminderId ? 'Edit Reminder' : 'Add Reminder';
  const setTitle = useTitle();

  useEffect(() => {
    setTitle(reminderId ? 'Edit Reminder' : 'Add Reminder');
  }, []);

  const [loading, setLoading] = useState(false);
  const [reminder, setReminder] = useState(null);

  const handleBackClick = () => (history.length > 1 ? history.goBack() : history.push(routes.REMINDERS));

  const refactorReminder = data => {
    data.startDate = moment(data.startDate);
    data.endDate = moment(data.endDate);
  };

  const fetchReminder = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await reminderService.fetchReminder(reminderId);
      refactorReminder(data);
      setReminder(data);
    } catch (err) {
      handleError(err);

      history.push(routes.REMINDERS);
    } finally {
      setLoading(false);
    }
  }, [reminderId]);

  useEffect(() => {
    if (!reminderId) {
      return;
    }

    fetchReminder();
  }, [reminderId, fetchReminder]);

  return (
    <div className="full-scope-card">
      <div className="full-scope-card__header table-header name-wrap">
        <div className="d-flex flex-row">
          <button
            type="button"
            className="btn btn-shrink btn--alternate btn--curved mr-10 d-inline-flex flex-fix"
            onClick={handleBackClick}
          >
            <IoMdArrowBack size="16px" className="mr-5" />
            Back
          </button>
          <div className="d-flex flex-row">
            <div className="table-header">
              <h3>{title}</h3>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="loading-min-height">
          <Loading />
        </div>
      ) : (
        <ReminderForm reminder={reminder} />
      )}
    </div>
  );
};

export default CreateEditReminder;
