import http from 'utils/http';
import interpolate from 'pinterpolate';
import {
  GRANT_ROLE_PERMISSION_URL,
  REVOKE_ROLE_PERMISSION_URL,
  ROLE_PERMISSION_URL,
  ROLE_URL,
  UPDATE_ROLE_URL
} from 'constants/endpoints';

/**
 * Fetch all roles.
 *
 * @returns {Array}
 */
export async function fetchAll() {
  const data = await http.get(ROLE_URL);

  return data;
}

/**
 * Update roles of user.
 *
 * @param {Number} id
 * @param {Object} params
 * @returns {Array}
 */

export async function updateUserRoles(id, params) {
  const url = interpolate(UPDATE_ROLE_URL, { id });

  await http.post(url, {
    body: {
      ...params
    }
  });
}

/**
 * Fetch all roles with permissions.
 *
 * @returns {Array}
 */
export async function fetchRolesPermissions() {
  const data = await http.get(ROLE_PERMISSION_URL);

  return data;
}

/**
 *
 * @param {Number} roleId
 * @param {Number} permissionId
 * @return {Promise<void>}
 */
export async function grantRolesPermissions(roleId, permissionId) {
  return await http.post(interpolate(GRANT_ROLE_PERMISSION_URL, { id: roleId }), { body: { permissionId } });
}

/**
 *
 * @param {Number} roleId
 * @param {Number} permissionId
 * @return {Promise<void>}
 */
export async function revokeRolesPermissions(roleId, permissionId) {
  return await http.delete(
    interpolate(REVOKE_ROLE_PERMISSION_URL, {
      id: roleId,
      permissionId
    })
  );
}
