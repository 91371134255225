import lodash from 'lodash';
import { FiInfo } from 'react-icons/fi';
import React, { useEffect, useState } from 'react';

import MyTeamContent from './MyTeamContent';
import PopOver from 'components/common/popover';
import Loading from 'components/common/loading/Loading';

import { MY_TEAM_FILTERS } from 'constants/myTeam';
import { DEFAULT_PAGE, MAX_PAGE_SIZE } from 'constants/page';

import { handleError } from 'utils/errorHandler';

import * as userService from 'services/user';
import { getWithPossessionSuffix } from 'utils/string';

const MyTeam = props => {
  const { employee, isSelf } = props;

  const { id: employeeId } = employee;
  const [issuerTeam, setIssuerTeam] = useState([]);
  const [managerTeam, setManagerTeam] = useState([]);
  const [appraiserTeam, setAppraiserTeam] = useState([]);
  const [coAppraiserTeam, setCoAppraiserTeam] = useState([]);

  const [isIssuerTeamLoading, setIsIssuerTeamLoading] = useState(true);
  const [isManagerTeamLoading, setIsManagerTeamLoading] = useState(true);
  const [isAppraiserTeamLoading, setIsAppraiserTeamLoading] = useState(true);
  const [isCoAppraiserTeamLoading, setIsCoAppraiserTeamLoading] = useState(true);

  const [activeFilter, setActiveFilter] = useState(MY_TEAM_FILTERS.ISSUER);

  const isLoading = isIssuerTeamLoading || isManagerTeamLoading || isAppraiserTeamLoading || isCoAppraiserTeamLoading;

  const HeaderContent = isSelf ? 'Viewing my team as' : `Viewing ${getWithPossessionSuffix(employee.firstName)} team`;
  const HeaderInfo = isSelf
    ? 'The list of Leapfroggers for whom I am responsible for.'
    : `The list of Leapfroggers for whom ${employee.firstName} is responsible for.`;
  const fetchIssuerTeamMembers = async () => {
    try {
      setIsIssuerTeamLoading(true);

      const params = { page: DEFAULT_PAGE, size: MAX_PAGE_SIZE, relations: 'leaveIssuer' };

      const { meta } = await userService.fetchTeamMembers(employeeId, params);

      const { page, size: pageSize, total } = meta;

      const totalPages = Math.ceil(total / pageSize);

      const fetchAllPromise = [];

      for (let i = page; i <= totalPages; i++) {
        fetchAllPromise.push(
          userService.fetchTeamMembers(employeeId, { page: i, size: MAX_PAGE_SIZE, relations: 'leaveIssuer' })
        );
      }

      const remainingTeam = await Promise.all(fetchAllPromise);

      const formattedTeam = remainingTeam.reduce((acc, { data }) => [...acc, ...data], []);

      const sortedTeam = lodash.orderBy(formattedTeam, [formattedTeam => formattedTeam.user.fullname.toLowerCase()]);

      setIssuerTeam(sortedTeam);
    } catch (error) {
      handleError(error);
    } finally {
      setIsIssuerTeamLoading(false);
    }
  };

  const fetchManagerTeamMembers = async () => {
    try {
      setIsManagerTeamLoading(true);

      const params = { page: DEFAULT_PAGE, size: MAX_PAGE_SIZE, relations: 'teamManager' };

      const { meta } = await userService.fetchTeamMembers(employeeId, params);

      const { page, size: pageSize, total } = meta;

      const totalPages = Math.ceil(total / pageSize);

      const fetchAllPromise = [];

      for (let i = page; i <= totalPages; i++) {
        fetchAllPromise.push(
          userService.fetchTeamMembers(employeeId, { page: i, size: MAX_PAGE_SIZE, relations: 'teamManager' })
        );
      }

      const remainingTeam = await Promise.all(fetchAllPromise);

      const formattedTeam = remainingTeam.reduce((acc, { data }) => [...acc, ...data], []);

      const sortedTeam = lodash.sortBy(formattedTeam, [formattedTeam => formattedTeam.user.fullname.toLowerCase()]);

      setManagerTeam(sortedTeam);
    } catch (error) {
      handleError(error);
    } finally {
      setIsManagerTeamLoading(false);
    }
  };

  const fetchAppraiserTeamMembers = async () => {
    try {
      setIsAppraiserTeamLoading(true);

      const params = { page: DEFAULT_PAGE, size: MAX_PAGE_SIZE, relations: 'appraiser' };

      const { meta } = await userService.fetchTeamMembers(employeeId, params);

      const { page, size: pageSize, total } = meta;

      const totalPages = Math.ceil(total / pageSize);

      const fetchAllPromise = [];

      for (let i = page; i <= totalPages; i++) {
        fetchAllPromise.push(
          userService.fetchTeamMembers(employeeId, { page: i, size: MAX_PAGE_SIZE, relations: 'appraiser' })
        );
      }

      const remainingTeam = await Promise.all(fetchAllPromise);

      const formattedTeam = remainingTeam.reduce((acc, { data }) => [...acc, ...data], []);

      const uniqueTeam = formattedTeam.reduce((acc, curr) => {
        if (acc.findIndex(item => item.user.id === curr.user.id) >= 0) {
          return acc;
        }

        const allocations = curr.currentAllocations.filter(allocation => allocation.appraiserId === employeeId);

        curr.currentAllocations = allocations;

        return [...acc, curr];
      }, []);

      const sortedTeam = lodash.sortBy(uniqueTeam, [uniqueTeam => uniqueTeam.user.fullname.toLowerCase()]);

      setAppraiserTeam(sortedTeam);
    } catch (error) {
      handleError(error);
    } finally {
      setIsAppraiserTeamLoading(false);
    }
  };

  const fetchCoAppraiserTeamMembers = async () => {
    try {
      setIsCoAppraiserTeamLoading(true);

      const params = { page: DEFAULT_PAGE, size: MAX_PAGE_SIZE, relations: 'coAppraiser' };

      const { meta } = await userService.fetchTeamMembers(employeeId, params);

      const { page, size: pageSize, total } = meta;

      const totalPages = Math.ceil(total / pageSize);

      const fetchAllPromise = [];

      for (let i = page; i <= totalPages; i++) {
        fetchAllPromise.push(
          userService.fetchTeamMembers(employeeId, { page: i, size: MAX_PAGE_SIZE, relations: 'coAppraiser' })
        );
      }

      const remainingTeam = await Promise.all(fetchAllPromise);

      const formattedTeam = remainingTeam.reduce((acc, { data }) => [...acc, ...data], []);

      const uniqueTeam = formattedTeam.reduce((acc, curr) => {
        if (acc.findIndex(item => item.user.id === curr.user.id) >= 0) {
          return acc;
        }

        const allocations = curr.currentAllocations.filter(allocation => allocation.coAppraiserId === employeeId);

        curr.currentAllocations = allocations;

        return [...acc, curr];
      }, []);

      const sortedTeam = lodash.sortBy(uniqueTeam, [uniqueTeam => uniqueTeam.user.fullname.toLowerCase()]);

      setCoAppraiserTeam(sortedTeam);
    } catch (error) {
      handleError(error);
    } finally {
      setIsCoAppraiserTeamLoading(false);
    }
  };

  useEffect(() => {
    fetchIssuerTeamMembers();
    fetchManagerTeamMembers();
    fetchAppraiserTeamMembers();
    fetchCoAppraiserTeamMembers();
  }, []);

  if (!issuerTeam.length && !managerTeam.length && !appraiserTeam.length && !coAppraiserTeam.length) {
    return null;
  }

  return (
    <div className="full-scope-card my-team-list mt-0 moml-30">
      <div className="my-team-list__label d-flex">
        <div className="pr-5">{HeaderContent}</div>
        <div className="my-team-list__icon">
          <PopOver
            interactive
            html={
              <div className="tooltip-info">
                <div className="tooltip-info__my-team-help d-flex">
                  <p>{HeaderInfo}</p>
                </div>
              </div>
            }
          >
            <FiInfo size={14} />
          </PopOver>
        </div>
      </div>
      <>
        {isLoading ? (
          <div className="my-team-list__loading">
            <Loading />
          </div>
        ) : (
          <MyTeamContent
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            issuerTeam={issuerTeam}
            managerTeam={managerTeam}
            appraiserTeam={appraiserTeam}
            coAppraiserTeam={coAppraiserTeam}
            isLoading={isLoading}
          />
        )}
      </>
    </div>
  );
};

export default MyTeam;
