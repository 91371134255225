import * as Yup from 'yup';
import MomentDateSchemaType from './momentdate';

import { getRequiredErrorLabel } from 'utils/string';

const momentSchema = new MomentDateSchemaType();

const eventSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(255, 'Name cannot exceed more than 255 characters')
    .required(getRequiredErrorLabel('title')),
  link: Yup.string()
    .trim()
    .test('urlValidation', 'Provide a valid link, example: https://www.example.com', function(value) {
      const validatorRegX = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

      return Boolean(value) ? validatorRegX.test(value) : true;
    })
    .nullable(),
  eventDate: momentSchema.required(getRequiredErrorLabel('event date')),
  description: Yup.string()
    .trim()
    .min(8, 'Description should be of at least 8 characters')
    .max(255, 'Description cannot exceed more than 255 characters')
    .nullable()
});

export default eventSchema;
