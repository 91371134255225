import config from '../config';

import http from 'utils/http';
import { stringify } from 'utils/queryParam';
import { interpolate } from 'utils/string';

import { EMPLOYEE_ROSTER_URL, SUMMARY_URL, UNFREEZE_REQUESTS_URL } from '../constants/endpoints';

/**
 * Fetch Calendar view.
 *
 * @param {Object}    filter
 *
 * @return {Promise}
 */
export async function fetch(filter = {}) {
  const url = stringify(`${config.attendanceBaseURI}${config.endpoints.attendance.api.calendar}`, filter);

  const { data } = await http.get(url);

  return data;
}

export async function fetchSummary(params) {
  return await http.get(SUMMARY_URL, { params });
}

export async function fetchWorkStatusSummary(filter = {}) {
  const url = stringify(`${config.attendanceBaseURI}${config.endpoints.attendance.api.workStatusSummary}`, filter);

  const { data } = await http.get(url);

  return data;
}

/**
 * Fetch unfreeze requests by user id.
 *
 * @param {number} userId
 * @returns Array of unfreeze requests
 */
export async function fetchUnfreezeRequestsByUserId(userId) {
  const url = interpolate(UNFREEZE_REQUESTS_URL, { userId });

  const { data } = await http.get(url);

  return data;
}

/**
 * Fetches the roster data for a specific user based on provided parameters.
 *
 * @async
 * @param {Object} [params={}] - The parameters are serialized and appended to the URL as query strings.
 * @returns {Promise<Object>} - A promise that resolves to the roster data of the specified user.
 *
 */
export async function fetchRosterByUserId(params = {}) {
  const url = stringify(EMPLOYEE_ROSTER_URL, params);

  const { data } = await http.get(url);

  return data;
}
