import React from 'react';

import { RiDraggable } from 'react-icons/ri';
import { FiMoreVertical } from 'react-icons/fi';

import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import { TYPE_ATTRIBUTES } from 'constants/linkLibrary';

import PopOver from 'components/common/popover';

export const DraggableLinkTableRow = ({ rowData }) => {
  const { transform, transition, attributes, setNodeRef, listeners } = useSortable({
    id: rowData.id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td>
        <div {...attributes} {...listeners} className="rst__row-draggableIcon">
          <RiDraggable />
        </div>
      </td>
      <td className="link-table-name">
        <div className={TYPE_ATTRIBUTES[rowData.type].titleClass}>{rowData.name}</div>
      </td>
      <td className="link-table-url">
        <div>
          <a
            href={`${rowData.linkUrl}`}
            target="_blank"
            onClick={event => {
              event.stopPropagation();
            }}
          >
            {rowData.linkUrl}
          </a>
        </div>
      </td>
      <td>
        <span
          className="vertical-ellipsis"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <PopOver
            interactive
            theme="light"
            trigger="click"
            size="small"
            position="left-start"
            distance={5}
            disableOnScroll={true}
            popperOptions={{
              modifiers: {
                addZIndex: {
                  enabled: true,
                  order: 810,
                  fn: data => ({
                    ...data,
                    styles: {
                      ...data.styles,
                      zIndex: 10
                    }
                  })
                }
              }
            }}
            html={TYPE_ATTRIBUTES[rowData.type].html(rowData)}
            className="vertical-ellipsis__menu dots-menu"
          >
            <FiMoreVertical className="grey--text" size={24} />
          </PopOver>
        </span>
      </td>
    </tr>
  );
};
