// to be removed
// import store from 'store';
import store from 'storeRtk';
import Modal from 'react-modal';
import classnames from 'classnames';
import { Provider } from 'react-redux';
import { UiMultiply } from 'vyaguta-icons/ui';
import React, { Component, useEffect, useState } from 'react';

import Loading from '../loading/Loading';

// Make sure to bind modal to your appElement
Modal.setAppElement('#root');

export class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverFlown: false,
      loading: false
    };
  }

  isOverFlown = el => {
    return el.scrollHeight > el.clientHeight;
  };

  setLoading(loading) {
    this.setState({
      loading
    });
  }

  componentDidMount() {
    this.setState({ isOverFlown: this.isOverFlown(this) });
  }

  render() {
    const {
      buttons,
      title,
      message,
      htmlContent,
      modalClose,
      onClose,
      withIcon,
      renderFooter,
      onCancelClick,
      onClick,
      className,
      subTitle,
      headerBorder,
      footerBorder,
      titlePrefixHtml
    } = this.props;
    const { isOverFlown, loading } = this.state;

    return (
      <>
        {withIcon && <div className="lf-modal__status-icon">{withIcon}</div>}
        <div className="lf-modal__content-wrapper" onClick={onClick}>
          <div className={`lf-modal__header ${isOverFlown || headerBorder ? 'lf-modal__header--border' : ''}`}>
            {modalClose && (
              <div className="lf-modal__close-icon">
                <UiMultiply
                  size={20}
                  onClick={() => {
                    modalClose();
                    onClose && onClose();
                  }}
                />
              </div>
            )}

            <div className="lf-modal__title--container">
              <div className="lf-modal__title--inner-container">
                <div
                  className={classnames('lf-modal__title', {
                    [`lf-modal__title--${title.type}`]: title.type
                  })}
                >
                  <div>{title.text}</div>
                </div>
                <div>{titlePrefixHtml}</div>
              </div>

              {subTitle && <div className={'lf-modal__sub-title'}>{subTitle.text}</div>}
            </div>
          </div>

          {message && (
            <div className="lf-modal__body">
              <p className={classnames('lf-modal__info', { [message.type]: message.type })}>{message.text}</p>
            </div>
          )}

          {htmlContent && (
            <div
              className={`lf-modal__body ${isOverFlown ? 'pt-5x' : ''}`}
              ref={ref => {
                this.el = ref;
              }}
            >
              {htmlContent}
            </div>
          )}

          {renderFooter && (
            <div
              className={classnames('lf-modal__footer', { 'lf-modal__footer--border': isOverFlown || footerBorder })}
            >
              {buttons &&
                buttons.map((value, index) => {
                  const btnTheme = value.type === undefined ? 'primary' : value.type;
                  return (
                    <button
                      className={classnames('btn btn--large', `btn--${btnTheme}`, value.className, {
                        'btn--loading': value.showLoading && (this.state.loading || value.isLoading)
                      })}
                      onClick={async () => {
                        this.setLoading(true);
                        if (value.onClick) {
                          await value.onClick();
                        }

                        this.setLoading(false);

                        if (value.close) onCancelClick();
                      }}
                      disabled={value.disabled || loading || value.isLoading}
                      key={index}
                    >
                      <span className="btn__label">{value.text}</span>
                      {value.showLoading && (this.state.loading || value.isLoading) && <Loading />}
                    </button>
                  );
                })}
            </div>
          )}
        </div>
      </>
    );
  }
}

const LFModal = props => {
  const {
    btnType,
    loading,
    btnLabel,
    onClose,
    title,
    TriggerBtn,
    isLoading,
    modalStatusIcon,
    buttons,
    message,
    htmlContent,
    closeButton,
    renderFooter,
    customBtnClass,
    disableBtn,
    stopPropagation = false,
    interactive = false,
    className,
    subTitle,
    headerBorder = false,
    footerBorder = false,
    titlePrefixHtml
  } = props;

  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const showModal = () => {
    setIsOpen(true);
  };

  const closeModal = e => {
    stopPropagation && e && e.stopPropagation();
    e && e.preventDefault();

    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };

  const handleOnClick = e => {
    if (stopPropagation) e.stopPropagation();
    e && e.preventDefault();
  };

  const handleButtonOnClick = e => {
    e.stopPropagation();
    e.preventDefault();

    showModal();
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    props.isOpen && setIsOpen(props.isOpen);

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.isOpen, isOpen]);

  return (
    <>
      {TriggerBtn ? (
        <TriggerBtn onClick={showModal} interactive={interactive} isLoading={isLoading} />
      ) : (
        <button
          className={classnames('btn', { [`btn--${btnType}`]: btnType, [customBtnClass]: customBtnClass })}
          type="button"
          onClick={handleButtonOnClick}
          disabled={disableBtn}
        >
          {btnLabel}
        </button>
      )}
      <Provider store={store}>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          className={classnames('lf-modal', { [className]: className })}
          overlayClassName="lf-modal__overlay"
        >
          <CustomModal
            title={title}
            loading={loading}
            buttons={buttons}
            message={message}
            onClose={onClose}
            onCancelClick={closeModal}
            htmlContent={htmlContent}
            renderFooter={renderFooter}
            onClick={handleOnClick}
            modalClose={closeButton ? closeModal : ''}
            withIcon={modalStatusIcon ? modalStatusIcon : ''}
            className={className}
            subTitle={subTitle}
            headerBorder={headerBorder}
            footerBorder={footerBorder}
            titlePrefixHtml={titlePrefixHtml}
          />
        </Modal>
      </Provider>
    </>
  );
};

export default LFModal;
