import React from 'react';

import classNames from 'classnames';

function Pills(props) {
  const { className, text, fontSize = 8, color, icon, textClassName } = props;

  return (
    <div className={classNames('expired-pills', className)}>
      <span className={textClassName} fontSize={fontSize} color={color}>
        {text}
      </span>
      {icon && icon}
    </div>
  );
}

export default Pills;
