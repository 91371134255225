import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import classnames from 'classnames';

import { GrFormClose } from 'react-icons/gr';

// Binding modal to the appElement
Modal.setAppElement('#root');

const BaseModal = props => {
  const [isOverFlown, setOverFlown] = useState(false);

  const modelRef = useRef(null);

  const checkOverflow = el => el && el.scrollHeight > el.clientHeight;

  useEffect(() => {
    setOverFlown(checkOverflow(modelRef));
  }, []);

  const {
    buttons,
    title,
    message,
    htmlContent,
    modalClose,
    onClose,
    withIcon,
    renderFooter,
    onCancelClick,
    onClick
  } = props;

  return (
    <React.Fragment>
      {withIcon && <div className="lf-modal__status-icon">{withIcon}</div>}
      <div className="lf-modal__content-wrapper" onClick={onClick}>
        <div className={`lf-modal__header ${isOverFlown ? 'lf-modal__header--border' : ''}`}>
          {modalClose && (
            <div className="lf-modal__close-icon">
              <GrFormClose
                size={20}
                onClick={() => {
                  modalClose();
                  onClose();
                }}
              />
            </div>
          )}

          {title.type !== undefined ? (
            <div
              className={classnames('lf-modal__title', {
                [`lf-modal__title--${title.type}`]: title.type
              })}
            >
              {title.text}
            </div>
          ) : (
            <div className="lf-modal__title">{title.text}</div>
          )}
        </div>
        {htmlContent && htmlContent !== undefined && (
          <div className={`lf-modal__body ${isOverFlown ? 'pt-5x' : ''}`} ref={modelRef}>
            {htmlContent}
          </div>
        )}

        {message && message !== undefined && (
          <div className="lf-modal__body">
            <p className={classnames('lf-modal__info', { [message.type]: message.type })}>{message.text}</p>
          </div>
        )}

        {renderFooter && (
          <div className={`lf-modal__footer ${isOverFlown ? 'lf-modal__footer--border' : ''}`}>
            {buttons &&
              buttons.map((value, index) => {
                const btnTheme = value.type === undefined ? 'primary' : value.type;
                return (
                  <button
                    className={classnames('btn btn--large', `btn--${btnTheme}`, value.className)}
                    onClick={async () => {
                      if (value.onClick) {
                        await value.onClick();
                      }

                      if (value.close) onCancelClick();
                    }}
                    disabled={value.disabled}
                    key={index}
                  >
                    {value.text}
                  </button>
                );
              })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const CustomModel = props => {
  const {
    isOpen,
    loading,
    onClose,
    title,
    modalStatusIcon,
    buttons,
    onClick,
    message,
    htmlContent,
    closeButton,
    renderFooter,
    className
  } = props;

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className={`lf-modal ${className}`}
        overlayClassName="lf-modal__overlay"
        bodyOpenClassName="overflow-hidden"
      >
        <BaseModal
          title={title}
          loading={loading}
          buttons={buttons}
          message={message}
          onClose={onClose}
          onCancelClick={onClose}
          htmlContent={htmlContent}
          renderFooter={renderFooter}
          onClick={onClick}
          modalClose={closeButton ? onClose : ''}
          withIcon={modalStatusIcon ? modalStatusIcon : ''}
        />
      </Modal>
    </React.Fragment>
  );
};

export default CustomModel;
