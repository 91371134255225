export const MID_PROBATION = 'Mid Probation';
export const END_PROBATION = 'End Probation';
export const PROBATION_EXTENSION = 'Probation Extension';

export const EMPLOYEE_STATUS_PROBATION_DETAILS = [MID_PROBATION, END_PROBATION, PROBATION_EXTENSION];

export const EMPLOYEE_STATUS_PROBATION_DETAILS_VALUES = [
  { label: MID_PROBATION, value: MID_PROBATION },
  { label: END_PROBATION, value: END_PROBATION },
  { label: PROBATION_EXTENSION, value: PROBATION_EXTENSION }
];
