import moment from 'moment';
import pinterpolate from 'pinterpolate';
import React, { Component } from 'react';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { withOnlyAttrs } from 'utils/object';
import { getFormattedDate } from 'utils/date';
import { extractFullName } from 'utils/string';
import { handleError } from 'utils/errorHandler';
import { generateReactSelectOptionsFromObject } from 'utils/reactSelect';

import * as userService from 'services/user';
import * as designationService from 'services/designation';
import * as designationAreaService from 'services/designationArea';

import { EMPLOYEE_PROFILE } from 'constants/routes';
import { DATE_FORMAT_DEFAULT, REACT_DATEPICKER_DATE_FORMAT } from 'constants/appConstants';

import Loading from '../../common/loading/Loading';
import InputWrapper from 'components/common/inputWrapper';
import { EmploymentHistory } from '../employees/components';
import DatePicker from 'components/common/datepicker/DatePicker';
import ProfileHeader from '../employees/components/ProfileHeader';
import { FormSelect } from '../CreateUpdateEmployeeForm/components';

export class ChangeDesignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: null,
      designations: null,
      selectedArea: null,
      isSubmitting: false,
      loadingEmployee: true,
      designationAreas: null,
      currentDesignation: null,
      loadingDesignations: true,
      id: props.match.params.id,
      selectedDesignation: null,
      transitionDate: getFormattedDate(new Date().toISOString(), DATE_FORMAT_DEFAULT),
      loadingDesignationAreas: true,
      errors: {
        designation: false,
        transitionDate: false
      }
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.fetchEmployeeById();
      this.fetchDesignations();
      this.fetchDesignationAreas();
    }
  }

  fetchEmployeeById = async () => {
    this.setState({ loadingEmployee: true });
    try {
      const data = await userService.fetchById(this.state.id);
      window.document.title = this.getPageTitle(data);

      this.setState({
        employee: {
          ...data,
          designationAreaHistory: data.designationAreaHistory && data.designationAreaHistory.reverse()
        },
        currentDesignation: data.designation,
        selectedArea: data.designation?.area
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({ loadingEmployee: false });
    }
  };

  selectedFieldToState = {
    designation: 'selectedDesignation',
    area: 'selectedArea',
    transitionDate: 'transitionDate'
  };

  handleChange = event => {
    const selectedValue = event && event.target && event.target.value;
    const selectedFieldName = event && event.target && event.target.name;

    this.setState({
      [this.selectedFieldToState[selectedFieldName]]: this.getSelectedOptionValue(selectedFieldName, selectedValue),
      errors: {
        ...this.state.errors,
        [`${selectedFieldName}`]: false
      }
    });
  };

  /**
   * Get option value from list of object
   *
   * @param {String} fieldName
   * @param {String} fieldValue
   */
  getSelectedOptionValue = (fieldName, fieldValue) => {
    if (fieldName === 'designation') {
      return fieldValue && this.state.designations.find(designation => +fieldValue === designation.id);
    } else if (fieldName === 'area') {
      return fieldValue && this.state.designationAreas.find(area => +fieldValue === area.id);
    } else {
      return fieldValue;
    }
  };

  /**
   * Fetchs designations.
   *
   * @returns {Array}
   */
  fetchDesignations = async () => {
    this.setState({
      loadingDesignations: true
    });

    try {
      const response = await designationService.fetchAll();

      const { data } = response;

      const designations = data.map(item => withOnlyAttrs(item, ['id', 'name']));

      this.setState({
        designations
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({
        loadingDesignations: false
      });
    }
  };

  /**
   * Fetches Areas.
   *
   * @returns {Array}
   */
  fetchDesignationAreas = async () => {
    this.setState({
      loadingDesignationAreas: true
    });
    try {
      const response = await designationAreaService.fetchAll();

      const { data } = response;

      const designationAreas = data.map(item => withOnlyAttrs(item, ['id', 'name']));

      this.setState({
        designationAreas
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({
        loadingDesignationAreas: false
      });
    }
  };

  handleSubmit = async () => {
    const { selectedDesignation: designation, selectedArea: area, transitionDate, id } = this.state;
    if (!designation || !transitionDate || !area) {
      return this.setState({
        errors: {
          designation: !designation,
          transitionDate: !transitionDate,
          area: !area
        }
      });
    }

    try {
      this.setState({
        isSubmitting: true
      });

      await userService.updateDesignation({ designation, area, transitionDate }, id);

      toast.success({
        title: 'Success',
        message: 'Designation has been updated successfully.'
      });

      this.redirectToEmployeeProfile();
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  redirectToEmployeeProfile = () => {
    const { id } = this.state;
    history.push(pinterpolate(EMPLOYEE_PROFILE, { id }));
  };

  getPageTitle = employee => {
    const employeeName = employee ? extractFullName(employee) : '';
    const pageTitle = employeeName ? `${employeeName}'s designation` : 'Change Designation';

    return pageTitle;
  };

  render() {
    const {
      errors,
      employee,
      selectedArea,
      designations,
      isSubmitting,
      transitionDate,
      loadingEmployee,
      designationAreas,
      currentDesignation,
      loadingDesignations,
      selectedDesignation,
      loadingDesignationAreas
    } = this.state;

    if (loadingEmployee) {
      return (
        <div className="loading-container container">
          <Loading />
        </div>
      );
    }

    return (
      <>
        <div className="title profile-wrapper">
          <ProfileHeader employee={employee} user={this.props.user} />
        </div>
        <div className="container d-flex fd-row flex-fix name-wrap">
          <div className="full-scope-card momr-20">
            <div className="full-scope-card__header table-header name-wrap">
              <div className="d-flex flex-row">
                <h3>Change Designation</h3>
              </div>
            </div>
            <div className="leaveissuer-form">
              <label className="font-label font-14 label-margin">
                <span className="font-weight-bold dark--text"> Current Designation: </span>
                <span className="span-padding-left">{currentDesignation?.name || 'None'}</span>
              </label>

              <label className="font-label font-14 label-margin">
                <span className="font-weight-bold dark--text"> Current Designation Area: </span>
                <span className="span-padding-left">{currentDesignation?.area?.name || 'None'}</span>
              </label>

              {!loadingDesignations && (
                <FormSelect
                  label="New Designation"
                  name="designation"
                  isMandatory={true}
                  isDisabled={false}
                  handleChange={this.handleChange}
                  value={selectedDesignation ? selectedDesignation.id : null}
                  error={errors && errors.designation && 'Please enter Designation'}
                  options={generateReactSelectOptionsFromObject(designations)}
                />
              )}
              {!loadingDesignationAreas && (
                <FormSelect
                  label="Area"
                  name="area"
                  isMandatory={true}
                  isDisabled={false}
                  handleChange={this.handleChange}
                  value={selectedArea ? selectedArea.id : null}
                  error={errors && errors.area && 'Please enter Area'}
                  options={generateReactSelectOptionsFromObject(designationAreas)}
                />
              )}
              <InputWrapper isMandatory={true} label="Transition Date" error={errors && errors.transitionDate}>
                <DatePicker
                  date={transitionDate ? moment(transitionDate) : null}
                  placeholderText="Pick a Transition Date"
                  dateFormat={REACT_DATEPICKER_DATE_FORMAT}
                  displayFormat="LL"
                  hasError={errors.transitionDate ? 'Please Select a transition date' : null}
                  openDirection="up"
                  onDateChange={selectedDate =>
                    this.handleChange({
                      target: {
                        name: 'transitionDate',
                        value: moment(selectedDate).format('YYYY-MM-DD')
                      }
                    })
                  }
                />
              </InputWrapper>

              <button
                type="button"
                className="btn btn--outlined-grey f-left card-button mr-10"
                onClick={this.redirectToEmployeeProfile}
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn--primary f-right card-button mb-20"
                onClick={this.handleSubmit}
                disabled={loadingDesignationAreas || isSubmitting || loadingDesignations}
              >
                {isSubmitting ? <Loading /> : 'Change'}
              </button>
            </div>
          </div>
          <div className="full-scope-card emp-history-area emp-history-wrapper custom-scroll-bar">
            <div className="font-bold emp-history-title">Employment History</div>
            <EmploymentHistory
              designationAreaHistory={employee.designationAreaHistory}
              empStatusHistory={employee.empStatusHistory}
            />
          </div>
        </div>
      </>
    );
  }
}
