import moment from 'moment';
import { Formik } from 'formik';
import pinterpolate from 'pinterpolate';
import React, { useEffect, useState } from 'react';

import {
  GENDER,
  REACT_DATEPICKER_DATE_FORMAT,
  CURRENT_ENGAGEMENT_STATUS_NONE,
  TERMINATION_CONFIRMATION_TITLE,
  CHANGE_ENGAGEMENT_STATUS_PAGE_TITLE,
  ENGAGEMENT_STATUS_UPDATE_SUCCESS_MESSAGE,
  ENGAGEMENT_STATUS_LEAVE_CREDIT_FAILED_MESSAGE,
  ENGAGEMENT_STATUS_LEAVE_CREDIT_SUCCESS_MESSAGE
} from 'constants/appConstants';
import { EMPLOYEE_PROFILE } from 'constants/routes';
import { DEFAULT_DATE_FORMAT } from 'constants/date';
import { TERMINATED } from 'constants/employeeStatus';
import { ENGAGEMENT_STATUS_IDS } from 'constants/employeeStatus';
import { PERMANENT, PROBATION } from 'constants/engagementStatus';
import { leaveSource, leaveTypeId, leaveTypeLabel } from 'constants/leave';

import history from 'utils/history';
import { success, error } from 'utils/toast';
import { withOnlyAttrs } from 'utils/object';
import { extractFullName } from 'utils/string';
import { handleError } from 'utils/errorHandler';
import { getFormattedDate, getMonthsLaterDate } from 'utils/date';

import * as userService from 'services/user';
import * as leaveService from 'services/leave';
import * as fiscalYear from 'services/fiscalYear';
import * as engagementStatusService from 'services/engagementStatus';

import { calendar } from 'assets/images';
import DropDown from 'components/common/dropDown';
import Loading from 'components/common/loading/Loading';
import InputWrapper from 'components/common/inputWrapper';
import DatePicker from 'components/common/datepicker/DatePicker';
import ProfileHeader from '../employees/components/ProfileHeader';

import changeEngagementStatusSchema from 'schemas/changeEngagementStatusSchema';
import ConfirmationModal from 'components/home/changeDesignation/ConfirmationModal';
import EngagementStatusList from '../employees/components/employeeForm/forms/leapfrogHistory/components/EngagementStatusList';

import EmployeeStatusDetails from './EmployeeStatusDetails';
import TerminationConfirmationModal from './TerminationConfirmationModal';

const ChangeEngagement = ({
  user,
  match: {
    params: { id }
  }
}) => {
  const [title, setTitle] = useState(CHANGE_ENGAGEMENT_STATUS_PAGE_TITLE);
  const [employee, setEmployee] = useState({});
  const [isLoadingEmployee, setIsLoadingEmployee] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingLeaveData, setIsFetchingLeaveData] = useState(false);
  const [isLoadingEngagementStatus, setIsLoadingEngagementStatus] = useState(true);
  const [isFetchingFiscalYear, setIsFetchingFiscalYear] = useState(true);
  const [engagementStatusOptions, setEngagementStatusOptions] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [payload, setPayload] = useState(null);
  const [leave, setLeave] = useState(null);
  const [currentFiscalYear, setCurrentFiscalYear] = useState(null);
  const [openTerminationConfirmationModal, setOpenTerminationConfirmationModal] = useState(false);
  const initialValues = {
    engagementStatusId: null,
    transitionDate: moment(getFormattedDate()),
    endDate: null,
    empStatusDetails: []
  };

  useEffect(() => {
    window.document.title = title;
  }, [title]);

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchEmployeeById(id);
  }, [id]);

  useEffect(() => {
    if (!!employee) {
      fetchFiscalYear({ country: employee.country });
    }
  }, [employee?.country]);

  useEffect(() => {
    fetchEngagementStatus();
  }, []);

  useEffect(() => {
    if (!payload) {
      return;
    }

    if (employee.currentEngagementStatusId === PROBATION && payload.engagementStatusId === PERMANENT) {
      fetchLeaveData();
    } else if (payload.engagementStatusId === ENGAGEMENT_STATUS_IDS.TERMINATED) {
      showTerminationConfirmationModal();
    } else {
      changeEngagementStatus();
    }
  }, [payload]);

  const getPageTitle = employee => {
    const employeeName = employee ? extractFullName(employee) : '';

    return employeeName ? `${employeeName}'s engagement status` : CHANGE_ENGAGEMENT_STATUS_PAGE_TITLE;
  };

  const redirectToEmployeeEdit = () => {
    if (!id) {
      return;
    }

    history.push(pinterpolate(EMPLOYEE_PROFILE, { id }));
  };

  const showTerminationConfirmationModal = () => {
    setOpenTerminationConfirmationModal(true);
  };

  const closeTerminationConfirmationModal = () => {
    setOpenTerminationConfirmationModal(false);
  };

  const changeEngagementStatus = async (successCallback = null) => {
    try {
      //first we will try to close the termination confirmation modal before proceeding.
      closeTerminationConfirmationModal();

      setIsSubmitting(true);

      await userService.updateEngagementStatus(id, payload);

      success({
        title: 'Success',
        message: ENGAGEMENT_STATUS_UPDATE_SUCCESS_MESSAGE
      });

      successCallback && successCallback();

      redirectToEmployeeEdit();
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showConfirmationModalWithLeave = () => {
    setOpenConfirmationModal(true);
  };

  const fetchLeaveData = async () => {
    try {
      setIsFetchingLeaveData(true);

      const { data } = await leaveService.calculateLeave({
        gender: employee.gender,
        currentEngagementStatusId: employee.currentEngagementStatusId,
        nextEngagementStatusId: payload.engagementStatusId,
        transitionDate: payload.transitionDate,
        endDate: payload.endDate,
        userId: employee.id
      });

      setLeave({
        discretionaryLeave: data.discretionaryLeave,
        sickLeave: data.sickLeave,
        mensurationLeave: data.mensurationLeave
      });

      showConfirmationModalWithLeave();
    } catch (error) {
      handleError(error);
    } finally {
      setIsFetchingLeaveData(false);
    }
  };

  const handleSubmit = async ({ engagementStatusId, transitionDate, empStatusDetails, endDate }) => {
    setPayload({
      engagementStatusId,
      transitionDate: transitionDate.startOf('day').format(DEFAULT_DATE_FORMAT),
      ...(endDate && { endDate: endDate.startOf('day').format(DEFAULT_DATE_FORMAT) }),
      ...(empStatusDetails && { empStatusDetails })
    });
  };

  const fetchFiscalYear = async () => {
    try {
      setIsFetchingFiscalYear(true);

      const fiscalYearData = await fiscalYear.fetch({ country: employee.country });

      setCurrentFiscalYear(fiscalYearData.reduce((accu, curr) => (curr.isCurrent ? curr : accu), {}));
    } catch (error) {
      handleError(error);

      // We will redirect the user back, since without fiscal year we won't be able
      // to complete the transition change request with leave update.
      redirectToEmployeeEdit();
    } finally {
      setIsFetchingFiscalYear(false);
    }
  };

  const fetchEmployeeById = async userId => {
    try {
      setIsLoadingEmployee(true);

      const data = await userService.fetchById(userId);

      setTitle(getPageTitle(data));

      const recentEmpStatus = data.empStatusHistory.reduce((acc, cur) => (cur.id > acc.id ? cur : acc));

      setEmployee({
        ...data,
        name: extractFullName(data),
        currentEmpStatusId: recentEmpStatus.id,
        currentEngagementStatusName: recentEmpStatus.engagementStatus.name || CURRENT_ENGAGEMENT_STATUS_NONE,
        currentEngagementStatusId: recentEmpStatus.engagementStatus.id,
        currentTransitionDate: recentEmpStatus.transitionDate,
        currentEndDate: recentEmpStatus.endDate
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingEmployee(false);
    }
  };

  const fetchEngagementStatus = async () => {
    try {
      setIsLoadingEngagementStatus(true);

      const { data } = await engagementStatusService.fetchAll();
      const engagementStatus = data.map(item => withOnlyAttrs(item, ['id', 'name']));

      setEngagementStatusOptions(
        engagementStatus.map(item => ({
          label: item.name,
          value: item
        }))
      );
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingEngagementStatus(false);
    }
  };

  const getLabelOfEngagementStatusFromOptions = engagementStatusId =>
    engagementStatusOptions &&
    engagementStatusOptions.filter(status => status.value.id === engagementStatusId)[0]?.label;

  const updateLeaveCredits = async leaveCreditPayload => {
    try {
      await leaveService.saveCredits(leaveCreditPayload);

      success({
        title: 'Success',
        message: pinterpolate(ENGAGEMENT_STATUS_LEAVE_CREDIT_SUCCESS_MESSAGE, {
          leaveType: leaveTypeLabel[leaveCreditPayload.leaveTypeId]
        })
      });
    } catch (err) {
      error({
        title: 'Error',
        message: pinterpolate(ENGAGEMENT_STATUS_LEAVE_CREDIT_FAILED_MESSAGE, {
          leaveType: leaveTypeLabel[leaveCreditPayload.leaveTypeId]
        })
      });
    }
  };

  const handleConfirmation = async ({ discretionaryLeave, sickLeave, mensurationLeave }) => {
    await changeEngagementStatus(() => {
      discretionaryLeave > 0 &&
        updateLeaveCredits({
          userId: id,
          leaveDays: discretionaryLeave,
          leaveTypeId: leaveTypeId.DISCRETIONARY,
          leaveSource: leaveSource.ENGAGEMENT_STATUS_TRANSITION
        });

      sickLeave > 0 &&
        updateLeaveCredits({
          userId: id,
          leaveDays: sickLeave,
          leaveTypeId: leaveTypeId.SICK,
          leaveSource: leaveSource.ENGAGEMENT_STATUS_TRANSITION
        });

      employee.gender === GENDER.FEMALE &&
        mensurationLeave > 0 &&
        updateLeaveCredits({
          userId: id,
          leaveDays: mensurationLeave,
          leaveTypeId: leaveTypeId.MENSURATION,
          leaveSource: leaveSource.ENGAGEMENT_STATUS_TRANSITION
        });
    });
  };

  const setEndDate = (id, setFieldTouched, setFieldValue, startDate) => {
    if (id === ENGAGEMENT_STATUS_IDS.FIXED_TERM_CONTRACT) {
      setFieldTouched('endDate', true, true);
      setFieldValue('endDate', moment(getMonthsLaterDate(startDate, 6)));
    } else {
      setFieldTouched('endDate', false, true);
      setFieldValue('endDate', null);
    }
  };

  const latestEmpStatus =
    employee?.empStatusHistory?.length && employee.empStatusHistory[employee.empStatusHistory.length - 1];

  if (isLoadingEmployee || isLoadingEngagementStatus || isFetchingFiscalYear) {
    return (
      <div className="container loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <TerminationConfirmationModal
        isOpen={openTerminationConfirmationModal}
        title={{
          text: TERMINATION_CONFIRMATION_TITLE,
          type: `danger`
        }}
        firstName={employee.firstName}
        lastName={employee.lastName}
        employeeId={employee.id}
        onConfirm={changeEngagementStatus}
        onCancel={closeTerminationConfirmationModal}
      />
      <ConfirmationModal
        isSubmitting={isSubmitting}
        displayMensurationLeaveField={employee.gender === GENDER.FEMALE}
        discretionaryLeave={leave?.discretionaryLeave}
        sickLeave={leave?.sickLeave}
        mensurationLeave={leave?.mensurationLeave}
        isOpen={openConfirmationModal}
        currentEngagementStatusLabel={employee.currentEngagementStatusName.toLowerCase()}
        newEngagementStatusLabel={getLabelOfEngagementStatusFromOptions(payload?.engagementStatusId)?.toLowerCase()}
        onConfirm={handleConfirmation}
        onCancel={() => setOpenConfirmationModal(false)}
      />
      <div className="title profile-wrapper">
        <ProfileHeader employee={employee} user={user} />
      </div>
      <div className="container d-flex fd-row flex-fix name-wrap">
        <div className="full-scope-card momr-20">
          <div className="full-scope-card__header table-header name-wrap">
            <div className="d-flex flex-row">
              <h3>{CHANGE_ENGAGEMENT_STATUS_PAGE_TITLE}</h3>
            </div>
          </div>
          <div className="engagement-manager-form">
            <Formik
              initialValues={{ ...initialValues }}
              onSubmit={handleSubmit}
              validationSchema={changeEngagementStatusSchema({
                currentEngagementStatusId: employee.currentEngagementStatusId,
                currentTransitionDate: employee.currentTransitionDate
              })}
            >
              {({ handleSubmit, errors, values, setFieldValue, touched, setFieldTouched }) => (
                <form onSubmit={handleSubmit}>
                  <label className="font-label font-14 label-margin">
                    <span className="font-weight-bold dark--text"> Current Employment Status: </span>
                    <span className="span-padding-left">{employee.currentEngagementStatusName}</span>
                  </label>

                  <InputWrapper
                    label="Employment Status"
                    error={touched.engagementStatusId && errors.engagementStatusId}
                    isMandatory
                  >
                    <DropDown
                      hasError={touched.engagementStatusId && errors.engagementStatusId}
                      isDisabled={isSubmitting || isFetchingLeaveData}
                      isSearchable={true}
                      name="engagementStatusId"
                      onChange={({ value }) => {
                        setFieldTouched('engagementStatusId', true, true);
                        setFieldValue('engagementStatusName', value.name);
                        setFieldValue('empStatusDetails', []);

                        setEndDate(value.id, setFieldTouched, setFieldValue, values.transitionDate);
                        setFieldValue('engagementStatusId', value.id);
                      }}
                      options={engagementStatusOptions}
                      value={engagementStatusOptions.find(({ value }) => value.id === values.engagementStatusId)}
                    />
                  </InputWrapper>
                  <InputWrapper label="Start Date" error={touched.transitionDate && errors.transitionDate} isMandatory>
                    <DatePicker
                      customInputIcon={<img className="form-icon" src={calendar} alt="Calendar" />}
                      date={values.transitionDate}
                      dateFormat={REACT_DATEPICKER_DATE_FORMAT}
                      disabled={isSubmitting || isFetchingLeaveData}
                      displayFormat="LL"
                      hasError={touched.transitionDate && errors.transitionDate}
                      isOutsideRange={date =>
                        date.isBefore(employee.currentTransitionDate) ||
                        date.isAfter(currentFiscalYear.endDate) ||
                        date.isBefore(currentFiscalYear.startDate) ||
                        (latestEmpStatus?.engagementStatus.name === TERMINATED &&
                          date.isAfter(latestEmpStatus?.transitionDate))
                      }
                      onDateChange={selectedDate => {
                        setFieldTouched('transitionDate', true, true);
                        setFieldValue('transitionDate', selectedDate);

                        setEndDate(values.engagementStatusId, setFieldTouched, setFieldValue, selectedDate);
                      }}
                      openDirection="up"
                      placeholderText="Pick a Transition Date"
                      showMonthDropdown={true}
                      showYearDropdown={true}
                    />
                  </InputWrapper>

                  {values.engagementStatusId === ENGAGEMENT_STATUS_IDS.FIXED_TERM_CONTRACT && (
                    <InputWrapper label="End Date" error={touched.endDate && errors.endDate} isMandatory>
                      <DatePicker
                        customInputIcon={<img className="form-icon" src={calendar} alt="Calendar" />}
                        date={values.endDate}
                        dateFormat={REACT_DATEPICKER_DATE_FORMAT}
                        disabled={isSubmitting || isFetchingLeaveData}
                        displayFormat="LL"
                        hasError={touched.endDate && errors.endDate}
                        isOutsideRange={date =>
                          date.isBefore(employee.currentTransitionDate) ||
                          date.isSameOrBefore(values.transitionDate) ||
                          date.isAfter(currentFiscalYear.endDate) ||
                          date.isBefore(currentFiscalYear.startDate)
                        }
                        onDateChange={selectedDate => {
                          setFieldTouched('endDate', true, true);
                          setFieldValue('endDate', selectedDate);
                        }}
                        openDirection="up"
                        placeholderText="Pick a End Date"
                        showMonthDropdown={true}
                        showYearDropdown={true}
                      />
                    </InputWrapper>
                  )}
                  {[ENGAGEMENT_STATUS_IDS.PROBATION, ENGAGEMENT_STATUS_IDS.FIXED_TERM_CONTRACT].includes(
                    values.engagementStatusId
                  ) &&
                    values.transitionDate &&
                    (values.engagementStatusId === ENGAGEMENT_STATUS_IDS.FIXED_TERM_CONTRACT
                      ? values.endDate
                      : !values.endDate) && (
                      <EmployeeStatusDetails
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        employee={employee}
                        currentFiscalYear={currentFiscalYear}
                        touched={touched}
                        errors={errors}
                      />
                    )}
                  <button
                    className="btn btn--outlined-grey f-left card-button mr-10 mb-20"
                    disabled={isSubmitting || isFetchingLeaveData}
                    onClick={redirectToEmployeeEdit}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn--primary f-right card-button"
                    disabled={isSubmitting || isFetchingLeaveData}
                  >
                    <span>Change</span>
                    {(isSubmitting || isFetchingLeaveData) && <Loading />}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div className="full-scope-card emp-history-area emp-history-wrapper engagement-history">
          <div className="font-bold emp-history-title">Employment Status History</div>
          <div className="leapfrog-history-form__designation-list engagement-history-list custom-scroll-bar">
            {[...employee.empStatusHistory].reverse().map((item, index) => {
              return (
                <EngagementStatusList
                  canEdit={false}
                  employeeName={employee.name}
                  isCurrent={employee.currentEmpStatusId === item.id}
                  key={item.id}
                  title={item.engagementStatus.name}
                  transitionDate={item.transitionDate}
                  endDate={item.endDate}
                  empStatusDetails={item.empStatusDetails}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeEngagement;
