import http from 'utils/http';
import { PERMISSION_URL } from 'constants/endpoints';

/**
 * Fetch all permissions.
 *
 * @returns {Array}
 */
export async function fetchAll() {
  const data = await http.get(PERMISSION_URL);

  return data;
}
