import * as Yup from 'yup';

import { getRequiredErrorLabel } from 'utils/string';
import { NOTE_STATUS_TYPES } from 'constants/appConstants';

const noteSchema = Yup.object().shape({
  label: Yup.string()
    .trim()
    .required(getRequiredErrorLabel('title.'))
    .max(255, 'Title cannot exceed more than 255 characters'),
  status: Yup.string()
    .oneOf([NOTE_STATUS_TYPES.ACTIVE, NOTE_STATUS_TYPES.INACTIVE])
    .required(getRequiredErrorLabel('status.')),
  viewers: Yup.array()
    .of(
      Yup.object({
        id: Yup.number().required()
      })
    )
    .required(getRequiredErrorLabel('viewers.'))
    .min(1),
  actionableUsers: Yup.array()
    .of(
      Yup.object({
        id: Yup.number().required()
      })
    )
    .required(getRequiredErrorLabel('actionable peoples.'))
    .min(1),
  description: Yup.string()
    .trim()
    .min(1, 'Description should not be empty.')
    .max(65535)
    .required(getRequiredErrorLabel('description of the note.')),
  actionDate: Yup.date()
    .typeError(getRequiredErrorLabel('action date.'))
    .required(getRequiredErrorLabel('action date.'))
});

export default noteSchema;
