import { UiPen } from 'vyaguta-icons/ui';
import React, { useMemo, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

import AddLink from './AddLink';
import DeletePopup from './DeletePopup';

import LFModal from 'components/common/LFModal/LFModal';

import { deleteQuickLinks } from 'services/quickLinks';

import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';
import { queryClient } from 'index';

const LinkVerticalEllipsis = props => {
  const { selectedLink, setHierarchyData } = props;

  const [isLinkEditModalOpen, setIsLinkEditModalOpen] = useState(false);

  const closeLinkEditModal = () => {
    setIsLinkEditModalOpen(false);
  };

  const EditLinkSubCategory = useMemo(() => {
    return ({ onClick }) => {
      return (
        <li
          className="overflow-menu__list-item overflow-menu__list-item--hoverable d-flex"
          onClick={() => {
            onClick();
            setIsLinkEditModalOpen(true);
          }}
        >
          <span className="icon overflow-menu__icon">
            <UiPen className="grey--text" size={16} />
          </span>
          <span>Edit</span>
        </li>
      );
    };
  }, []);

  const deleteLink = async () => {
    try {
      const data = await deleteQuickLinks(selectedLink.id);

      toast.success({
        title: 'Success',
        message: data.message
      });

      queryClient.invalidateQueries('quickLinks');
      // await setHierarchyData();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="overflow-menu">
      <div className="overflow-menu__block">
        <ul className="overflow-menu__list">
          <AddLink
            isEdit={true}
            isOpen={isLinkEditModalOpen}
            onClose={closeLinkEditModal}
            selectedLink={selectedLink}
            setHierarchyData={setHierarchyData}
            TriggerBtn={EditLinkSubCategory}
          />
          <LFModal
            TriggerBtn={DeletePopup}
            modalStatusIcon={<FiAlertTriangle color="#F44336" size={24} />}
            title={{ text: 'Do you want to delete the link?', type: 'danger' }}
            className="delete-modal"
            htmlContent={
              <div className="d-grid mbpx-4">
                <span className="delete__text">
                  This action cannot be reverted back. The {selectedLink?.name} link will be deleted.
                </span>
              </div>
            }
            renderFooter={true}
            buttons={[
              {
                text: 'Yes, delete',
                type: 'red',
                className: 'mr-12 delete__btn',
                onClick: deleteLink,
                close: true,
                showLoading: true
              },
              {
                text: 'No, go back',
                type: 'outlined-grey',
                className: 'py-8 px-12',
                close: true
              }
            ]}
          />
        </ul>
      </div>
    </div>
  );
};

export default LinkVerticalEllipsis;
