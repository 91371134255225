import React, { useEffect } from 'react';
import moment from 'moment';

import Tooltip from 'components/common/tooltip';
import DropDown from 'components/common/dropDown';
import InputWrapper from 'components/common/inputWrapper';
import DatePicker from 'components/common/datepicker/DatePicker';

import { isObjectEmpty } from 'utils/object';
import { UiTrashAlt } from 'vyaguta-icons/ui';
import { getFormattedDate, getMonthsLaterDate } from 'utils/date';

import { ENGAGEMENT_STATUS_IDS, FIXED_TERM_CONTRACT } from 'constants/employeeStatus';
import { EMPLOYEE_STATUS_PROBATION_DETAILS_VALUES } from 'constants/employeeStatusDetails';

const AddEmployeeStatusDetails = ({
  item,
  setEmpStatusDetails,
  empStatusDetails,
  handleDelete,
  values,
  employee,
  currentFiscalYear,
  type,
  index,
  touched,
  errors
}) => {
  const getUpdatedData = (prev, key, value) => {
    const newData = prev.map(data => (data.keyId === item.keyId ? { ...data, [key]: value } : data));

    return newData;
  };

  const onDropDownChange = data => {
    const { value } = data;
    setEmpStatusDetails(prev => getUpdatedData(prev, 'category', value));
  };

  const onDateChange = value => {
    setEmpStatusDetails(prev => getUpdatedData(prev, 'date', getFormattedDate(value)));
  };

  useEffect(() => {
    if (values.engagementStatusId === ENGAGEMENT_STATUS_IDS.FIXED_TERM_CONTRACT) {
      return;
    }

    const newData = empStatusDetails?.map((empStatusDetailsItem, index) => {
      return {
        ...empStatusDetailsItem,
        date: (index < 2 && getMonthsLaterDate(values.transitionDate, 3 * (index + 1))) || empStatusDetailsItem.date
      };
    });

    setEmpStatusDetails(newData);
  }, [values.transitionDate]);

  const getDefaultValue = item => {
    if (isObjectEmpty(item.category)) {
      return null;
    }

    return {
      label: item.category,
      value: item.category
    };
  };

  return (
    <div className="emp-status-details-form__category">
      <div className="d-flex">
        <InputWrapper
          label="Category"
          className="emp-status-details-form__input emp-status-details-form__input--lg"
          error={touched.empStatusDetails?.[index]?.category && errors.empStatusDetails?.[index]?.category}
          isMandatory
        >
          <DropDown
            defaultValue={getDefaultValue(item)}
            onDropDownChange={onDropDownChange}
            options={EMPLOYEE_STATUS_PROBATION_DETAILS_VALUES}
            isSearchable={true}
            isDisabled={type === FIXED_TERM_CONTRACT}
            className="employee-form__dropdown"
            placeholder={'Select...'}
            hasError={touched.empStatusDetails?.[index]?.category && errors.empStatusDetails?.[index]?.category}
          />
        </InputWrapper>

        <InputWrapper
          label="Date"
          className="employee-form__date emp-status-details-form__input emp-status-details-form__input--lg"
          error={touched.empStatusDetails?.[index]?.date && errors.empStatusDetails?.[index]?.date}
          isMandatory
        >
          <DatePicker
            date={item.date ? moment(item.date) : null}
            onDateChange={onDateChange}
            isOutsideRange={date =>
              date.isBefore(employee.currentTransitionDate) ||
              date.isBefore(currentFiscalYear.startDate) ||
              date.isBefore(values.transitionDate)
            }
            hasError={touched.empStatusDetails?.[index]?.date && errors.empStatusDetails?.[index]?.date}
          />
        </InputWrapper>
        <div className="emp-status-details-form__delete emp-status-details-form__delete" onClick={handleDelete}>
          <Tooltip
            html={
              <div className="tooltip-info">
                <p className="tooltip-info__title">Delete</p>
              </div>
            }
          >
            <div className="icon-container">
              <UiTrashAlt />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="emp-status-details-form__divider"></div>
    </div>
  );
};

export default AddEmployeeStatusDetails;
