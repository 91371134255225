import { FiPlusCircle } from 'react-icons/fi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddEmployeeStatusDetails from './AddEmployeeStatusDetails';

import { getUniqueKey } from 'utils/string';
import { getMonthsLaterDate } from 'utils/date';
import { FIXED_TERM_CONTRACT, PROBATION } from 'constants/employeeStatus';

import { getCheckIn } from '../employees/components/employeeForm/forms/leapfrogHistory/components/EmployeeStatusDetails';

const EmployeeStatusDetails = ({
  values,
  setFieldValue,
  setFieldTouched,
  employee,
  currentFiscalYear,
  touched,
  errors
}) => {
  const getDefaultValue = useCallback(name => {
    if (name === PROBATION) {
      return [
        {
          keyId: getUniqueKey(),
          category: 'Mid Probation',
          date: getMonthsLaterDate(values?.transitionDate, 3)
        },
        {
          keyId: getUniqueKey(),
          category: 'End Probation',
          date: getMonthsLaterDate(values?.transitionDate, 6)
        }
      ];
    }

    if (name === FIXED_TERM_CONTRACT) {
      return [
        {
          keyId: getUniqueKey(),
          category: getCheckIn()
        }
      ];
    }
  }, []);

  const initialEmpStatusDetails = useMemo(() => getDefaultValue(values.engagementStatusName), [
    values.engagementStatusName,
    getDefaultValue
  ]);

  const [empStatusDetails, setEmpStatusDetails] = useState(initialEmpStatusDetails);

  useEffect(() => {
    setEmpStatusDetails(initialEmpStatusDetails);
  }, [initialEmpStatusDetails]);

  const onAdd = () => {
    setEmpStatusDetails(prev => [
      ...prev,
      {
        keyId: getUniqueKey(),
        category: values.engagementStatusName === FIXED_TERM_CONTRACT ? getCheckIn(empStatusDetails) : '',
        date: ''
      }
    ]);
  };

  const handleDelete = id => {
    const filteredArray = empStatusDetails.filter(item => {
      return item.keyId !== id;
    });

    setEmpStatusDetails(filteredArray);
  };

  useEffect(() => {
    setFieldValue(
      'empStatusDetails',
      empStatusDetails?.map(({ id, category, date }) => ({
        id,
        category,
        date
      }))
    );

    setFieldTouched('empStatusDetails', true, true);
  }, [empStatusDetails, setFieldValue]);

  return (
    <div className="emp-status-details-form custom-scroll-bar">
      <div className="emp-status-details-form__heading">Add {values.engagementStatusName} Category</div>
      <div className="emp-status-details-form__body">
        {empStatusDetails?.map((item, index) => (
          <AddEmployeeStatusDetails
            key={item.keyId}
            item={item}
            setEmpStatusDetails={setEmpStatusDetails}
            handleDelete={() => handleDelete(item.keyId)}
            values={values}
            employee={employee}
            currentFiscalYear={currentFiscalYear}
            empStatusDetails={empStatusDetails}
            type={values.engagementStatusName}
            index={index}
            touched={touched}
            errors={errors}
          />
        ))}
      </div>

      <div className="emp-status-details-form__add-button" onClick={() => onAdd()}>
        <FiPlusCircle className="emp-status-details-form__add-button--plus-icon" />
        <p className="emp-status-details-form__button-label">Add Another Category</p>
      </div>
    </div>
  );
};

export default EmployeeStatusDetails;
