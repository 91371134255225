import { useEffect } from 'react';

import { checkStringLength } from 'utils/string';
import { mapUsersWithAvatar } from 'utils/filterOptions';

import { useDebounce } from 'hooks/useDebounce';
import useLazyLoading from 'hooks/useLazyLoading';

import * as userService from 'services/user';

import { DEFAULT_PAGE } from 'constants/page';
import { MAKE_SEARCH_BY } from 'constants/fetchTypes';
import { DEFAULT_PAGE_SIZE, MIN_USER_SEARCH_LENGTH } from 'constants/appConstants';

/**
 * The function `useEmployeeDebounceWithLazyLoad` combines debounce functionality with lazy loading for
 * fetching employee data based on employee ID or search query.
 * @returns The `useEmployeeDebounceWithLazyLoad` function returns an array with the following elements
 * in order:
 * 1. `loading`: a boolean indicating whether data is currently being loaded
 * 2. `dataList`: an array of data fetched based on the provided filters
 * 3. `code`: the current value being debounced
 * 4. `debounceFunction`: a function to debounce the input value
 */
const useEmployeeDebounceWithLazyLoad = ({ employeeId, additionalFetchProps, searchUserByFullName = false }) => {
  const [code, debounceFunction] = useDebounce();

  const getDataFunction = async filters => {
    if (!employeeId && checkStringLength(code, MIN_USER_SEARCH_LENGTH)) {
      return { data: [], meta: { total: 0, page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE } };
    }

    const params = {
      q: code,
      ...filters,
      ...additionalFetchProps
    };

    if (!searchUserByFullName) {
      params.searchBy = MAKE_SEARCH_BY.join(',');
    }

    const { data, meta } = await userService.fetchAll(params);

    const mappedData = mapUsersWithAvatar(data);

    return { data: mappedData, meta };
  };

  const { loading, dataList, lastRef, update, reset } = useLazyLoading(getDataFunction);

  useEffect(() => {
    reset();
  }, [code, employeeId]);

  return [loading, dataList, code, debounceFunction, lastRef];
};

export default useEmployeeDebounceWithLazyLoad;
