import React from 'react';

import CurrentAllocationItem from './CurrentAllocationComponents/CurrentAllocationItem';

import { EMPTY_ALLOCATION_CHART_MESSAGE } from 'constants/dashboard';

export const defaultAllocationHrs = 40;

export default function CurrentAllocation({ allocations = [], designation = null, colorData = [] }) {
  return (
    <div className="allocation__project">
      {allocations.length ? (
        <div className={allocations.length === 1 ? 'allocation__centerItem' : 'allocation__projects custom-scroll-bar'}>
          {allocations.map((item, index) => (
            <CurrentAllocationItem item={item} index={index} colorData={colorData} />
          ))}
        </div>
      ) : (
        <div className="allocation__empty">
          <p>{EMPTY_ALLOCATION_CHART_MESSAGE}</p>
        </div>
      )}
    </div>
  );
}
