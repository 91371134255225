import React, { useState, useEffect } from 'react';

import { isStringEmpty } from 'utils/string';
import { NO_USERS_FOUND_TEXT, TYPE_USER_TEXT } from 'constants/appConstants';
import FormDropdown from 'components/common/inputWrapper/components/Select';

const DebounceFormDropdown = props => {
  const {
    error,
    value,
    name,
    label,
    type,
    handleChange,
    handleBlur,
    isDisabled,
    classNamePrefix,
    isMandatory,
    debounceOptions,
    className,
    iconComponent,
    isClearable,
    removeOptionsCallback,
    customSearchText
  } = props;

  const SEARCH_TEXT = customSearchText || `Search ${type || 'employee'}, hit enter to select`;

  const [isLoading, options, code, debounceFunction] = debounceOptions;

  const [dropdownOptions, setDropdownOptions] = useState(options);

  useEffect(() => {
    let requiredOptions = options;

    if (removeOptionsCallback) {
      requiredOptions = removeOptionsCallback(options);
    }

    setDropdownOptions(requiredOptions);
  }, [options, removeOptionsCallback]);

  return (
    <FormDropdown
      isMandatory={isMandatory}
      name={name}
      onBlur={handleBlur}
      value={value}
      error={error}
      onChange={handleChange}
      options={dropdownOptions}
      label={label}
      placeholder={isLoading ? 'Loading' : SEARCH_TEXT}
      isLoading={isLoading}
      onInputChange={code => {
        if (!isStringEmpty(code)) {
          debounceFunction(code);
        }
      }}
      noOptionsMessage={() => (code.length ? NO_USERS_FOUND_TEXT : TYPE_USER_TEXT)}
      isDisabled={isDisabled}
      classNamePrefix={classNamePrefix}
      className={className}
      formatOptionLabel={iconComponent}
      isClearable={isClearable}
    />
  );
};

export default DebounceFormDropdown;
