import config from 'config';
import interpolate from 'pinterpolate';
import React, { useState } from 'react';

import { Avatar } from '@vyaguta/vyaguta-ui';

import PopOver from 'components/common/popover';
import ChangeAppraiserModal from '../ChangeAppraiserModal';

import { isDateToday } from 'utils/date';
import { getAllocationAppendLabel } from 'utils/user';
import { getEmpImageUrl, startCase } from 'utils/string';

import { EMPLOYEE_PROFILE } from 'constants/routes';
import { BILLING_LEARNING } from 'constants/appConstants';
import { REQUEST_FOR_APPRAISERS_CHANGE } from 'constants/profile';

import AllocationTimePeriod from '../AllocationTimePeriod';

const CurrentAllocationItem = ({ item, colorData, index }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hiddenBtn = () => {
    return <div style={{ display: 'hidden' }}></div>;
  };

  const popperOptions = {
    modifiers: {
      addZIndex: {
        enabled: true,
        order: 810,
        fn: data => ({
          ...data,
          styles: {
            ...data.styles,
            zIndex: 10,
            left: -40
          }
        })
      },
      preventOverflow: {
        enabled: false, // Prevent the tooltip from overflowing the boundaries of the viewport
        boundariesElement: 'viewport' // Specify the element used as a boundary for the tooltip
      }
    }
  };

  const {
    id,
    project,
    area,
    startDate,
    endDate,
    potentialEndDate,
    allocation,
    billingStatus,
    role,
    allocationType,
    appraiser,
    coAppraiser
  } = item;

  const isOffboardedToday = isDateToday(endDate);

  return (
    <div className="allocation__projects__project-current" key={id}>
      <div className="project-brief project-brief--current-allocation ">
        <span className="allocation__dot" style={{ backgroundColor: colorData[index] }} key={index}></span>
        <div className="allocation__text d-grid">
          <h4 className="project-name">
            {project?.name ? (
              <span
                className="cursor-pointer project-name__link"
                onClick={() =>
                  window.open(
                    interpolate(`${config.teamsAppURL}${config.endpoints.teams.app.project}`, {
                      id: project.id
                    })
                  )
                }
              >
                {project.name}
              </span>
            ) : (
              area?.name || startCase(allocationType)
            )}
          </h4>
          <AllocationTimePeriod startDate={startDate} endDate={endDate} potentialEndDate={potentialEndDate} />
        </div>
      </div>

      <div className="project-details project-details--current-allocation">
        {role?.name && <div className="project-info-current-allocation">{role.name}</div>}
        <div className="project-info-current-allocation project-info-current-allocation__allocation">{`${allocation} ${getAllocationAppendLabel(
          allocation
        )}`}</div>
        {billingStatus === BILLING_LEARNING && <div className="project-info-current-allocation">{billingStatus}</div>}
      </div>

      <div className="d-flex appraiser-info">
        <div className="appraiser-info__label">Appraiser(s): </div>
        <PopOver
          interactive
          position="top-start"
          theme="custom-light"
          popperOptions={popperOptions}
          html={
            <div className="appraiser-info-modal">
              <div className="appraiser-info-modal__allocation">{project?.name || area?.name}</div>
              <div className="appraiser-info-modal__title">Appraiser</div>
              <div className="d-flex gap-px-8 mbpx-16">
                <Avatar image={getEmpImageUrl(appraiser.empId)} size="small" alt="appraiser" />
                <div>
                  <a href={interpolate(EMPLOYEE_PROFILE, { id: appraiser.id })}>
                    <span className="appraiser-info-modal__name">{appraiser.fullname}</span>
                  </a>
                  <p className="appraiser-info-modal__designation">{appraiser.designation.name}</p>
                </div>
              </div>

              {coAppraiser && (
                <>
                  <div className="appraiser-info-modal__title">Co-appraiser</div>
                  <div className="d-flex gap-px-8 mbpx-16">
                    <Avatar image={getEmpImageUrl(coAppraiser.empId)} size="small" alt="appraiser" />
                    <div>
                      <a href={interpolate(EMPLOYEE_PROFILE, { id: coAppraiser.id })}>
                        <span className="appraiser-info-modal__name">{coAppraiser.fullname}</span>
                      </a>
                      <p className="appraiser-info-modal__designation">{coAppraiser.designation.name}</p>
                    </div>
                  </div>
                </>
              )}
              {!isOffboardedToday && (
                <div>
                  <button
                    aria-hidden="true"
                    type="button"
                    className="appraiser-change-btn cursor-pointer"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    {REQUEST_FOR_APPRAISERS_CHANGE}
                  </button>
                </div>
              )}
            </div>
          }
        >
          <div className="d-flex">
            <div className="images">
              <Avatar image={getEmpImageUrl(appraiser.empId)} alt="appraiser" size="extraSmall" />
            </div>

            {coAppraiser && (
              <div className="images">
                <Avatar image={getEmpImageUrl(coAppraiser.empId)} alt="coAppraiser" size="extraSmall" />
              </div>
            )}
          </div>
        </PopOver>

        <ChangeAppraiserModal
          TriggerBtn={hiddenBtn}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          filteredAllocation={[{ name: project?.name || area?.name, id }]}
        />
      </div>
    </div>
  );
};

export default CurrentAllocationItem;
