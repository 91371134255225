import http from 'utils/http';
import { unParseQuery } from 'utils/string';

import {
  LEAVES,
  LEAVE_CREDITS,
  WELL_BEING_LEAVE_CREDITS,
  CALCULATE_LEAVE_FOR_ENGAGEMENT_TRANSITION
} from 'constants/endpoints';

export async function calculateLeave(params = {}) {
  const data = await http.get(CALCULATE_LEAVE_FOR_ENGAGEMENT_TRANSITION + unParseQuery(params));

  return data;
}

export async function saveCredits(credits) {
  const { data } = await http.post(LEAVE_CREDITS, { body: credits });

  return data;
}

export async function fetch(params = {}) {
  const data = await http.get(LEAVES + unParseQuery(params));

  return data;
}

export async function fetchWellBeingLeaves(params = {}) {
  const { data } = await http.get(WELL_BEING_LEAVE_CREDITS + unParseQuery(params));

  return data;
}
