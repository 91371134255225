import React from 'react';
import classNames from 'classnames';

function RatingItem({ rating, description, classes }) {
  return (
    <div className="global-rating-item d-flex align-items-center gap-x-16">
      <p className="rating">{rating}</p>

      <p className={classNames('description', classes?.label)}>{description}</p>
    </div>
  );
}

function GlobalRatingList({ globalRatings, classes }) {
  return (
    <div className="global-rating-list">
      {globalRatings.map(({ rating, description }) => {
        return <RatingItem rating={rating} description={description} key={rating} classes={classes} />;
      })}
    </div>
  );
}

export default GlobalRatingList;
