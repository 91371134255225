import pinterpolate from 'pinterpolate';

import http from 'utils/http';
import config from '../config';

import { PAGE_SIZE } from 'constants/appConstants';

export async function fetchUserFeedbacks(userId, params) {
  const url = `${config.jumpBaseURI}${pinterpolate(config.endpoints.jump.userFeedbacks, { userId })}`;
  const { data } = await http.get(url, { params: { ...params, pageSize: PAGE_SIZE.MAX } });

  return data;
}

export async function fetchGoalFeedbacks(goalId, params) {
  const url = `${config.jumpBaseURI}${pinterpolate(config.endpoints.jump.goalFeedbacks, { goalId })}`;
  const { data } = await http.get(url, { params: { ...params, pageSize: PAGE_SIZE.MAX } });

  return data;
}
