import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';

import { interpolate } from 'utils/string';
import { handleError } from 'utils/errorHandler';
import { parse, updateUrl } from 'utils/queryParam';

import {
  NOTE_STATUS_TYPES,
  NOTE_STATUS_FILTER,
  DEFAULT_PAGE_NUMBER,
  EMPTY_NOTE_RESULT_MESSAGE,
  DEFAULT_NOTE_STATUS_FILTER
} from 'constants/appConstants';
import * as routes from 'constants/routes';
import * as permissions from 'constants/permissions';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/page';

import Empty from 'components/common/empty/Empty';
import EmployeeNoteList from './EmployeeNoteList';
import Pagination from 'components/common/paginator';
import Loading from 'components/common/loading/Loading';
import MaterialDropdown from 'components/common/dropDown/materialDropdown';

import * as noteService from 'services/note';

const NoteList = props => {
  const noteUserId = props.match.params.userId;
  const authUserId = props.authUser.id;

  const [notes, setNotes] = useState([]);
  const [pageData, setPageData] = useState({});
  const [isFetchingNotes, setIsFetchingNotes] = useState(false);
  const [selectedPage, setSelectedPage] = useState(DEFAULT_PAGE);
  const [pageCount, setPageCount] = useState(DEFAULT_PAGE_NUMBER);
  const [selectedStatusValue, setSelectedStatusValue] = useState({});
  const [selectedPageSize, setSelectedPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [selectedStatus, setSelectedStatus] = useState(NOTE_STATUS_TYPES.ACTIVE);

  const fetchAllNotes = async () => {
    try {
      setIsFetchingNotes(true);

      const params = { userId: noteUserId, status: selectedStatus, page: selectedPage, size: selectedPageSize };

      const { data, meta } = await noteService.fetchNotes(params);

      const { total } = meta;
      const pageCount = Math.ceil(total / selectedPageSize);

      setPageData(meta);
      setPageCount(pageCount);

      const notes = data.map((datum, index) => {
        return {
          ...datum,
          sn: (selectedPage - 1) * selectedPageSize + (index + 1)
        };
      });

      setNotes(notes);
    } catch (error) {
      handleError(error);
    } finally {
      setIsFetchingNotes(false);
    }
  };

  useEffect(() => {
    fetchAllNotes();

    updateUrl(props, {
      ...parse(props.location.search, {
        decoder: str => decodeURIComponent(str)
      }),
      status: selectedStatus || `${NOTE_STATUS_TYPES.ACTIVE},${NOTE_STATUS_TYPES.INACTIVE}`,
      page: selectedPage,
      size: selectedPageSize
    });
  }, [selectedStatus, selectedPage, selectedPageSize]);

  const onPageChange = useCallback(
    selectedPage => {
      setSelectedPage(selectedPage);
    },
    [setSelectedPage]
  );

  const onPageSizeChange = useCallback(
    selectedSize => {
      setSelectedPageSize(selectedSize);
    },
    [setSelectedPageSize]
  );

  const {
    value: { permissions: userPermissions }
  } = useSelector(state => state.information);
  const handleFilterChange = option => setSelectedStatus(option.status);

  useEffect(() => {
    setSelectedStatusValue(NOTE_STATUS_FILTER.find(option => option.value === selectedStatus));
  }, [selectedStatus]);

  return (
    <main>
      <div className="container">
        <div className="full-scope-card">
          <div className="full-scope-card__header table-header name-wrap d-flex justify-content-between user-select-none">
            <div>
              <span className="table-header__title">Notes</span>
            </div>
            <div className="d-flex">
              <MaterialDropdown
                isSearchable={false}
                options={NOTE_STATUS_FILTER}
                isPlain
                isSmall
                autoResize={true}
                value={selectedStatusValue || DEFAULT_NOTE_STATUS_FILTER}
                onChange={option => {
                  handleFilterChange({ status: option.value });
                }}
                customClassName="pr-40"
                placeholder={'Status'}
              />
              {userPermissions[permissions.NOTES.CREATE] && (
                <Link
                  className="btn btn--primary btn--curved"
                  to={interpolate(routes.CREATE_EMPLOYEE_NOTE, { userId: noteUserId })}
                >
                  Add Note
                </Link>
              )}
            </div>
          </div>
          <div className="full-scope-card__content">
            {isFetchingNotes ? (
              <div className="full-scope-card mt-15">
                <Loading />
              </div>
            ) : notes.length ? (
              <>
                <EmployeeNoteList notes={notes.map(note => ({ note }))} authUserId={authUserId} />
                <Pagination
                  pageData={pageData}
                  pageCount={+pageCount}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                />
              </>
            ) : (
              <div className="mt-15 p-20 empty-container__wrapper">
                <Empty message={EMPTY_NOTE_RESULT_MESSAGE} />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default NoteList;
