import moment from 'moment';
import { Formik } from 'formik';
import classnames from 'classnames';
import React, { useState } from 'react';
import { HORIZONTAL_ORIENTATION } from 'react-dates/constants';
import { FiAlertCircle, FiCalendar } from 'react-icons/fi';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import DatePicker from 'components/common/datepicker/DatePicker';
import DeleteButton from 'components/common/button/DeleteButton';

import eventSchema from 'schemas/events';

import * as eventService from 'services/events';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';

import * as routes from 'constants/routes';
import { DEFAULT_DATE_FORMAT } from 'constants/date';
import { DEFAULT_HISTORY_ENTRIES } from 'constants/appConstants';

const initialValues = {
  name: '',
  description: null,
  eventDate: moment(),
  link: null
};

const EventForm = ({ event }) => {
  const [submitting, setSubmitting] = useState(false);

  const handleCancelClick = () =>
    history.length > DEFAULT_HISTORY_ENTRIES ? history.goBack() : history.push(routes.EVENTS);

  const handleSubmit = async values => {
    setSubmitting(true);

    const eventObj = {
      name: values.name?.trim(),
      description: values.description ? values.description.trim() : null,
      link: values.link ? values.link.trim() : null,
      eventDate: values.eventDate.startOf('day').format(DEFAULT_DATE_FORMAT)
    };

    try {
      if (event) {
        await eventService.updateEvent(event.id, eventObj);
      } else {
        await eventService.createEvent(eventObj);
      }

      toast.success({
        title: 'Success',
        message: event ? 'Event updated successfully.' : 'Event created successfully.'
      });
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
      history.push(routes.EVENTS);
    }
  };

  const handleEventDelete = async () => {
    try {
      await eventService.removeEvent(event.id);
      toast.success({
        title: 'Success',
        message: 'Event deleted successfully.'
      });
    } catch (err) {
      handleError(err);
    } finally {
      history.push(routes.EVENTS);
    }
  };

  return (
    <Formik
      initialValues={event ? { ...event } : { ...initialValues }}
      validationSchema={eventSchema}
      onSubmit={handleSubmit}
      render={({ handleChange, handleSubmit, touched, errors, values, handleBlur, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="event-form">
          <div className="full-scope-card__content">
            <div className="form-wrap">
              <div className="form-wrap__row form-wrap__row--no-margin clearfix">
                <div className="form-wrap__col col-sm-6 px-0">
                  <div className="d-flex flex-direction-responsive">
                    <div className="form-group width--full momr-20">
                      <label className="form-label dark--text">
                        Title <span className="required ml-5">*</span>
                      </label>
                      <input
                        name="name"
                        type="text"
                        value={values.name}
                        placeholder="Provide a suitable title for the event"
                        className={classnames('form-elem form-elem--input-sm', {
                          'form-elem--error': errors.name && touched.name
                        })}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></input>
                      {errors.name && touched.name && <div className="error-block">{errors.name}</div>}
                    </div>
                    <div className="form-group width--full">
                      <label className="form-label dark--text">
                        Date <span className="required ml-5">*</span>
                      </label>
                      <div className="form-elem--icon-pos">
                        <DatePicker
                          id="eventDate"
                          displayFormat="LL"
                          customInputIcon={<FiCalendar size={18} className="color-grey-50" />}
                          date={values.eventDate}
                          onDateChange={eventDate => {
                            eventDate = moment(eventDate);
                            if (!eventDate._isValid) {
                              return;
                            }

                            setFieldValue('eventDate', eventDate);
                          }}
                          numberOfMonths={1}
                          orientation={HORIZONTAL_ORIENTATION}
                          isOutsideRange={undefined}
                        />
                      </div>
                      {errors.eventDate && touched.eventDate && <div className="error-block">{errors.eventDate}</div>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label dark--text">Description</label>
                    <textarea
                      name="description"
                      type="text"
                      value={values.description}
                      placeholder="Provide a suitable description for the event"
                      className={classnames('form-elem form-elem--input-sm', {
                        'form-elem--error': errors.description && touched.description
                      })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    ></textarea>
                    {errors.description && touched.description && (
                      <div className="error-block">{errors.description}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label dark--text">Link</label>
                    <input
                      name="link"
                      type="text"
                      value={values.link}
                      placeholder="Provide a redirect link"
                      className={classnames('form-elem form-elem--input-sm', {
                        'form-elem--error': errors.link && touched.link
                      })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    ></input>
                    {errors.link && touched.link && <div className="error-block">{errors.link}</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="action-bar-footer action-bar-footer--bordered-top px-16">
              <button
                type="submit"
                className={classnames('btn btn--primary btn--curved mr-10', {
                  'btn--loading': submitting
                })}
                disabled={submitting}
              >
                <span className={submitting ? 'invisible' : ''}>Save</span>
                {submitting && <Loading />}
              </button>
              <button
                type="button"
                className={classnames('btn btn--outlined-grey btn--curved mr-10')}
                disabled={submitting}
                onClick={handleCancelClick}
              >
                Cancel
              </button>

              {event && (
                <LFModal
                  TriggerBtn={DeleteButton}
                  modalStatusIcon={<FiAlertCircle color="#F44336" size={24} />}
                  title={{ text: 'Delete this event?', type: 'danger' }}
                  className="delete-modal"
                  message={{ text: "You won't be able to revert this!", type: 'delete__text' }}
                  renderFooter={true}
                  buttons={[
                    {
                      text: 'Confirm',
                      type: 'red',
                      className: 'mr-12 delete__btn',
                      onClick: handleEventDelete,
                      close: true,
                      showLoading: true
                    },
                    {
                      text: 'Cancel',
                      type: 'outlined-grey',
                      className: 'py-8 px-12',
                      close: true
                    }
                  ]}
                />
              )}
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default EventForm;
