import Select from 'react-select';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { FiXCircle } from 'vyaguta-icons/fi';
import React, { useEffect, useState, useCallback } from 'react';

import { ROLES_IDS, TYPE_USER_TEXT, USER_FETCH_FIELDS, NO_USERS_FOUND_TEXT, LIST_TYPE } from 'constants/appConstants';

import Badge from 'components/common/badge';
import * as userService from 'services/user';
import { extractFullName } from 'utils/string';
import { filterAllowedRoles } from 'utils/user';
import { handleError } from 'utils/errorHandler';
import { useFetchRoles } from 'hooks/useFetchRoles';
import { MAKE_SEARCH_BY } from 'constants/fetchTypes';
import AvatarComponent from 'components/common/avatar/AvatarComponent';
import DebounceFormDropdown from 'components/common/debounceFormDropdown/DebounceFormDropdown';

const CreateEditRole = props => {
  const {
    setSelectedRolesList,
    selectedRolesList,
    afterChange,
    handleBlur,
    touched,
    errors,
    isEditable,
    onUserSelectChange,
    onUserBlur,
    selectedUser,
    employeeDebounceOptions
  } = props;

  const {
    value: { permissions }
  } = useSelector(state => state.information);
  const { roles, isLoadingRoles, isError } = useFetchRoles();
  const defaultRoleValue = { label: 'Select Roles', value: {} };
  const [roleList, setRoleList] = useState(roles);
  const [roleOptions, setRoleOptions] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userCode, setUserCode] = useState('');
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  useEffect(() => {
    const selectedRolesIds = selectedRolesList.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: curr.id
      }),
      {}
    );
    const filteredList = roleList.reduce((prevObj, currObj) => {
      if (currObj.id === selectedRolesIds[currObj.id]) {
        return prevObj;
      }

      const newFilteredObj = {
        value: {
          id: currObj.id,
          name: currObj.name
        },
        label: `${currObj.name}`
      };

      prevObj.push(newFilteredObj);

      return prevObj;
    }, []);

    setRoleOptions(filteredList);
  }, [roleList, selectedRolesList]);

  useEffect(() => {
    if (isLoadingRoles) {
      return;
    }

    const filteredRoles = filterAllowedRoles(roles, permissions);

    setRoleList(filteredRoles);
  }, [roles]);

  const fetchUserList = useCallback(async code => {
    try {
      setIsLoadingUsers(true);
      const params = {
        q: code,
        searchBy: MAKE_SEARCH_BY.join(','),
        fields: USER_FETCH_FIELDS.ROLES,
        roleIds: ROLES_IDS.NONE
      };
      const { data } = await userService.fetchAll(params);

      setUserList(data.map(getEmployeeSelectOptions));
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoadingUsers(false);
    }
  }, []);

  useEffect(() => {
    if (!afterChange) {
      return;
    }

    afterChange();
  }, [selectedRolesList]);

  const handleRoleChange = options => {
    options && setSelectedRolesList(state => [...state, options.value]);
  };

  const handleRemoveRoles = options => {
    setSelectedRolesList(roles => roles.filter(role => role.id !== options.id));
  };

  const getEmployeeSelectOptions = employee => ({
    value: employee.id,
    empId: employee.empId,
    label: extractFullName(employee),
    email: employee.email
  });

  const handleNoOptionsMessage = () => (userCode ? NO_USERS_FOUND_TEXT : TYPE_USER_TEXT);

  return (
    <>
      {!isEditable && (
        <div className="lf-input lf-input--required pb-1rem ">
          <label htmlFor="User's name" className="lf-input__label">
            Name
          </label>
          <div className="custom-dropdown w-80 ">
            <DebounceFormDropdown
              name="employees"
              debounceOptions={employeeDebounceOptions}
              type={LIST_TYPE.EMPLOYEE}
              value={selectedUser}
              error={touched.userId && errors.userId}
              handleChange={onUserSelectChange}
              handleBlur={onUserBlur}
              handleNoOptionsMessage={handleNoOptionsMessage}
              callback={fetchUserList}
              options={userList}
              listCode={userCode}
              setListCode={setUserCode}
              loadingList={isLoadingUsers}
              setLoadingList={setIsLoadingUsers}
              className={classnames({ 'is-invalid': touched.userId && errors.userId })}
              iconComponent={AvatarComponent}
            />
          </div>
        </div>
      )}
      <div className="lf-input lf-input--required">
        <label htmlFor="User's name" className="lf-input__label">
          Role
        </label>
        <div className="custom-dropdown w-80">
          <Select
            options={roleOptions || []}
            isLoading={isLoadingRoles}
            isSearchable={true}
            isClearable={false}
            classNamePrefix="lf-select"
            placeholder={defaultRoleValue}
            value={defaultRoleValue}
            maxMenuHeight={120}
            onChange={handleRoleChange}
            onBlur={handleBlur}
            className={touched.roles && errors.roles ? 'is-invalid' : ''}
          />
        </div>

        <div className={classnames('badge-wrap', { 'mt-3x': selectedRolesList.length !== 0 })}>
          {selectedRolesList?.map(role => (
            <Badge
              key={role.id}
              closeIcon={
                <FiXCircle
                  className="grey--text"
                  size={18}
                  onClick={() => {
                    handleRemoveRoles(role);
                  }}
                />
              }
              label={role.name}
              color={'color-grey-60'}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default CreateEditRole;
