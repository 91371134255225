import { Formik } from 'formik';
import classNames from 'classnames';
import { queryClient } from 'index';
import React, { useState } from 'react';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import Input from 'components/common/inputWrapper/components/Input';

import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';

import { addLinkSchema } from 'schemas/addLinkSchema';
import { addQuickLinks, updateQuickLinks } from 'services/quickLinks';

const AddLink = props => {
  const { selectedLink, setHierarchyData, isOpen, onClose, TriggerBtn, isEdit } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    name: isEdit ? selectedLink?.name : '',
    link: isEdit ? selectedLink?.linkUrl : ''
  };

  const handleLink = async values => {
    setIsSubmitting(true);

    try {
      const linkData = {
        title: values.name,
        link: values.link,
        categoryId: isEdit ? selectedLink.categoryId : selectedLink.id
      };

      let data;

      if (isEdit) {
        data = await updateQuickLinks(selectedLink.id, linkData);
      } else {
        data = await addQuickLinks(linkData);
      }

      toast.success({
        title: 'Success',
        message: data.message
      });

      queryClient.invalidateQueries('quickLinks');
      // await setHierarchyData(!isEdit && selectedLink?.id);
    } catch (error) {
      handleError(error);
    } finally {
      onClose();
      setIsSubmitting(false);
    }
  };

  return (
    <LFModal
      TriggerBtn={TriggerBtn}
      isOpen={isOpen}
      onClose={onClose}
      closeButton={true}
      title={{ text: isEdit ? 'Edit link' : 'Add new link', type: 'info' }}
      className="skill-modal"
      htmlContent={
        <Formik initialValues={initialValues} onSubmit={handleLink} validationSchema={addLinkSchema}>
          {({ handleSubmit, handleBlur, handleChange, errors, values, dirty, touched }) => {
            return (
              <form onSubmit={handleSubmit} className="skill-modal__form">
                <div className="skill-modal__form-body">
                  <Input
                    isMandatory
                    label="Link Name"
                    name="name"
                    placeholder="Write the name of the link"
                    className="wp-100 linkLibrary__form-title"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                    disabled={isSubmitting}
                    multiline={false}
                    type="text"
                  />
                  <Input
                    isMandatory
                    label="URL to the link"
                    name="link"
                    placeholder="URL link here"
                    className="wp-100 linkLibrary__form-title"
                    value={values.link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.link && errors.link}
                    disabled={isSubmitting}
                    multiline={false}
                    type="url"
                  />
                </div>
                <div className="skill-modal__footer">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={classNames('btn skill-modal__btn', {
                      'btn--primary': !isSubmitting && dirty,
                      'btn--disabled': isSubmitting || !dirty
                    })}
                    disabled={isSubmitting || !dirty}
                  >
                    <span className={classNames({ invisible: isSubmitting })}>
                      {isEdit ? 'Edit & close' : 'Add & close'}
                    </span>
                    {isSubmitting && <Loading />}
                  </button>

                  <button
                    type="button"
                    className={classNames('btn ml-10 skill-modal__btn', {
                      'btn--outlined-grey': !isSubmitting,
                      'btn--disabled': isSubmitting
                    })}
                    onClick={e => {
                      e.preventDefault();
                      onClose();
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      }
    />
  );
};

export default AddLink;
