import http from 'utils/http';

import config from 'config';

import * as tokenService from 'services/token';
import { LOGIN_URL, REFRESH_TOKEN_URL } from '../constants/endpoints';

/**
 * Logout the user.
 *
 * @param {Object} body
 * @returns
 */
export const logout = (body = {}) => {
  const logoutUrl = `${config.authURI}${config.endpoints.auth.logout}`;

  return http.post(logoutUrl, { body: { ...body, from: window.location.href } });
};

/**
 * Redirect User to login page.
 *
 */
export async function redirectToLogin() {
  tokenService.clear();

  const currentURL = window.location.href;

  window.location.href = `${LOGIN_URL}?loginRedirectUrl=${encodeURIComponent(currentURL)}`;
}

/**
 * Refresh access token.
 *
 * @param {string} refreshToken
 * @returns {Promise<{accessToken, refreshToken}>}
 */
export async function refresh(refreshToken) {
  const clientId = config.authClientId;

  const response = await http.post(REFRESH_TOKEN_URL, {
    body: {
      clientId,
      refreshToken
    },
    accessToken: false
  });

  const accessToken = response.accessToken;

  return accessToken;
}
