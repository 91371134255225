import http from 'utils/http';

import { INFO_URL } from 'constants/endpoints';

/**
 * Fetch the info for the logged in user.
 *
 * @returns {Promise}
 */
export async function fetchInfo() {
  const { data } = await http.get(INFO_URL);

  return data;
}
