import classNames from 'classnames';
import { FiExternalLink } from 'react-icons/fi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import React, { useCallback, useEffect, useState } from 'react';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { useSelector } from 'react-redux';
import { getIndexOfSN } from 'utils/table';
import { getFormattedDate } from 'utils/date';
import { handleError } from 'utils/errorHandler';
import { parse, stringify } from 'utils/queryParam';
import { interpolate, pluralize, withoutProtocolAndSubDomainURL } from 'utils/string';

import * as routes from 'constants/routes';
import * as permissions from 'constants/permissions';
import { DISPLAY_DATE_FORMAT } from 'constants/date';
import { MINIMUM_PAGE_COUNT, MINIMUM_PAGE_ITEM } from 'constants/page';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  EVENTS_REFERENCE_PERIOD,
  EVENTS_REFERENCE_PERIOD_PILL_TABS
} from 'constants/appConstants';
import { EVENT_DESCRIPTION_LENGTH, EVENT_NAME_LENGTH, TOOLTIP_LIMIT_LENGTH } from 'constants/string';

import Table from 'components/common/table';
import PopOver from 'components/common/popover';
import PillTab from 'components/common/pillTab';
import Pagination from 'components/common/paginator';
import EditDelete from 'components/common/editDelete';
import TableHeader from 'components/common/table/TableHeader';

import * as eventService from 'services/events';
import getCurrentReferencePeriod from 'utils/getCurrentReferencePeriod';

const EventList = props => {
  window.document.title = 'Events | Vyaguta';

  const [pageData, setPageData] = useState({});
  const [pageCount, setPageCount] = useState(DEFAULT_PAGE_NUMBER);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const {
    value: { permissions: userPermissions }
  } = useSelector(state => state.information);
  const [eventList, setEventList] = useState([]);
  const [columnToSortBy, setColumnToSortBy] = useState('date');
  const [isAscendingOrdered, setIsAscendingOrdered] = useState(true);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const EVENTS_CURRENT_REFERENCE_PERIOD = getCurrentReferencePeriod(props);
  const [queries, setQueries] = useState({
    referencePeriod: EVENTS_CURRENT_REFERENCE_PERIOD || EVENTS_REFERENCE_PERIOD.UPCOMING.toLowerCase()
  });

  const queryParams = parse(props.location.search, {
    decoder: str => decodeURIComponent(str)
  });

  const { page, size, referencePeriod } = queries;

  const handleEdit = row => {
    history.push(interpolate(routes.UPDATE_EVENT, { id: row.original.id }));
  };

  const handleDelete = async row => {
    try {
      await eventService.removeEvent(row.original.id);
      toast.success({
        title: 'Success',
        message: 'Event deleted successfully.'
      });

      if (eventList.length === MINIMUM_PAGE_ITEM) {
        queryParams.page = DEFAULT_PAGE_NUMBER;
        updateUrl({
          ...parse(props.location.search, {
            decoder: str => decodeURIComponent(str)
          }),
          page: DEFAULT_PAGE_NUMBER
        });
      }

      fetchEvents();
    } catch (err) {
      handleError(err);
    }
  };

  const getLinkCell = () => {
    return {
      Header: <TableHeader label="Link" identifier="link" />,
      accessor: 'link',
      sortable: false,
      filterable: false,
      maxWidth: 200,
      Cell: props => {
        const isNotFirstRow = props.index !== 0;
        const linkExceedsLimit = props.original.link.length > TOOLTIP_LIMIT_LENGTH;
        const top = linkExceedsLimit ? -40 : -20;
        const moreProps = isNotFirstRow ? {} : createPopperOptions({ zIndex: 10, top });
        return (
          <>
            {props.original.link !== '-' ? (
              <PopOver
                interactive
                html={
                  <div className="tooltip-info word-break">
                    <p className="tooltip-info__title">{props.original.link}</p>
                  </div>
                }
                position="top-start"
                visible={withoutProtocolAndSubDomainURL(props.original.link).length}
                {...moreProps}
              >
                <a
                  href={props.original.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex align-items-center table-link"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <div className="table-link__text ellipsis">{withoutProtocolAndSubDomainURL(props.original.link)}</div>
                  <div className="center">
                    <FiExternalLink className="table-link__icon" size={12} />
                  </div>
                </a>
              </PopOver>
            ) : (
              <>{props.original.link}</>
            )}
          </>
        );
      }
    };
  };

  const getDeleteCell = () => {
    const canEdit = userPermissions[permissions.EVENTS.UPDATE];
    const canDelete = userPermissions[permissions.EVENTS.DELETE];

    const canEditOrDelete = canEdit || canDelete;

    return {
      Header: '',
      width: 6,
      className: 'margin-left-auto',
      Cell: row =>
        canEditOrDelete ? (
          <span
            className="verticle-ellipse cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <PopOver
              interactive
              trigger="click"
              theme={'light'}
              size={'regular overflow-menu__regular'}
              position="left-start"
              disableOnScroll={true}
              popperOptions={{
                modifiers: {
                  addZIndex: {
                    enabled: true,
                    order: 810,
                    fn: data => ({
                      ...data,
                      styles: {
                        ...data.styles,
                        zIndex: 10
                      }
                    })
                  }
                }
              }}
              html={
                <EditDelete
                  onDelete={() => handleDelete(row)}
                  onEdit={() => handleEdit(row)}
                  canEdit={canEdit}
                  canDelete={canDelete}
                  type="event"
                  title={row.original.name}
                />
              }
            >
              <BsThreeDotsVertical size={20} />
            </PopOver>
          </span>
        ) : null
    };
  };

  const fetchEvents = async () => {
    setIsDataLoading(true);

    try {
      const size = queryParams.size || DEFAULT_PAGE_SIZE;
      const page = queryParams.page || DEFAULT_PAGE_NUMBER;

      const filters = {
        ...queries,
        page,
        size
      };

      setPageSize(size);

      const { data, meta } = await eventService.fetchEvents(filters);
      const { total } = meta;

      const pageCount = Math.ceil(total / size);

      setPageData(meta);

      const finalData = data.map(data => {
        const date = getFormattedDate(data.date, DISPLAY_DATE_FORMAT);
        const description = data.description ? data.description : '-';
        const link = data.link ? data.link : '-';

        return {
          ...data,
          description,
          date,
          link
        };
      });

      setEventList(finalData);
      setPageCount(pageCount);
    } catch (error) {
      handleError(error);
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [props.location.search, queryParams.page, queryParams.size]);

  const getRowProps = (state, rowInfo) => {
    return {
      className: 'align-items-center d-flex',
      onClick: e => {
        if (!userPermissions[permissions.EVENTS.UPDATE]) {
          return;
        }

        const url = interpolate(routes.UPDATE_EVENT, { id: rowInfo.original.id });

        if (e.ctrlKey || e.metaKey) {
          window.open(url);
        } else {
          history.push(url);
        }
      }
    };
  };

  const createPopperOptions = customStyles => {
    return {
      distance: 5,
      popperOptions: {
        modifiers: {
          flip: {
            enabled: false
          },
          addZIndex: {
            enabled: true,
            order: 812,
            fn: data => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  ...customStyles
                }
              };
            }
          }
        }
      }
    };
  };

  const getColumns = (
    isAscendingOrdered,
    setIsAscendingOrdered,
    columnToSortBy,
    setColumnToSortBy,
    pageData,
    pageSize
  ) => [
    {
      Header: <TableHeader label="SN." identifier="sn" />,
      accessor: 'sn',
      maxWidth: 56,
      sortable: false,
      filterable: false,
      Cell: props => {
        const index = getIndexOfSN(props, pageSize, pageData);
        return <span className="capital-text employees__table-text">{index < 10 ? `0${index}` : index}</span>;
      }
    },
    {
      Header: (
        <TableHeader
          label="Title"
          identifier="name"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => {
            if (columnToSortBy !== 'name') {
              setIsAscendingOrdered(true);
            } else {
              setIsAscendingOrdered(!isAscendingOrdered);
            }
            setColumnToSortBy('name');
          }}
        />
      ),
      accessor: 'name',
      maxWidth: 340,
      Cell: props => {
        const isNotFirstRow = props.index !== 0;
        const moreProps = isNotFirstRow ? {} : createPopperOptions({ zIndex: 10, top: -22 });
        return (
          <>
            {props.original.name.length > EVENT_NAME_LENGTH ? (
              <PopOver
                interactive
                html={
                  <div className="tooltip-info word-break">
                    <p className="tooltip-info__title">{props.original.name}</p>
                  </div>
                }
                position="top-start"
                {...moreProps}
              >
                <div className="ellipsis">{props.original.name}</div>
              </PopOver>
            ) : (
              <div className="ellipsis">{props.original.name}</div>
            )}
          </>
        );
      }
    },
    {
      Header: <TableHeader label="Description" identifier="description" />,
      accessor: 'description',
      sortable: false,
      filterable: false,
      maxWidth: 460,
      Cell: props => {
        const isNotFirstRow = props.index !== 0;
        const moreProps = isNotFirstRow ? {} : createPopperOptions({ zIndex: 10, top: -38 });
        return (
          <>
            {props.original.description.length > EVENT_DESCRIPTION_LENGTH ? (
              <PopOver
                interactive
                html={
                  <div className="tooltip-info word-break">
                    <p className="tooltip-info__title">{props.original.description}</p>
                  </div>
                }
                position="top-start"
                {...moreProps}
              >
                <div className="ellipsis">{props.original.description}</div>
              </PopOver>
            ) : (
              <div className="ellipsis">{props.original.description}</div>
            )}
          </>
        );
      }
    },
    {
      Header: (
        <TableHeader
          label="Date"
          identifier="date"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => {
            if (columnToSortBy !== 'date') {
              setIsAscendingOrdered(true);
            } else {
              setIsAscendingOrdered(!isAscendingOrdered);
            }
            setColumnToSortBy('date');
          }}
        />
      ),
      accessor: 'date',
      maxWidth: 120
    }
  ];

  const updateUrl = useCallback(
    value => {
      const queryParam = stringify('', value, { arrayFormat: 'comma', encode: false });
      history.push({
        pathname: props.location.pathname,
        search: queryParam
      });
    },
    [props.location.pathname]
  );

  useEffect(() => {
    updateUrl({
      ...parse(props.location.search, {
        decoder: str => decodeURIComponent(str)
      }),
      ...queries
    });
  }, [referencePeriod, page, size, props.location.search, updateUrl]);

  const onPageChange = selectedPage => {
    setQueries(value => ({ ...value, page: selectedPage }));
  };

  const onPageSizeChange = selectedSize => {
    setQueries(queries => ({ ...queries, page: DEFAULT_PAGE_NUMBER, size: selectedSize }));
  };

  const handleApplyFilter = filters => {
    setQueries({ ...filters, page: DEFAULT_PAGE_NUMBER, size: DEFAULT_PAGE_SIZE });
  };

  return (
    <div className="full-scope-card">
      <div className="full-scope-card__header table-header name-wrap">
        <div>
          <div className="table-title">Events</div>
          <div className="table-subtitle">
            Showing {eventList.length} {pluralize('Result', eventList.length)}
          </div>
        </div>
        <div className="table-header__right">
          <PillTab
            tabs={EVENTS_REFERENCE_PERIOD_PILL_TABS}
            onTabClick={value => handleApplyFilter({ referencePeriod: value })}
            isActive={value => value === queries.referencePeriod.toLowerCase()}
          />
        </div>
      </div>
      <div className={classNames('full-scope-card__content')}>
        <>
          <Table
            loading={isDataLoading}
            data={eventList}
            columns={[
              ...getColumns(
                isAscendingOrdered,
                setIsAscendingOrdered,
                columnToSortBy,
                setColumnToSortBy,
                pageData,
                pageSize
              ),
              getLinkCell(),
              getDeleteCell()
            ]}
            showPagination={false}
            noDataText="No events to show."
            getTrProps={getRowProps}
          />
          <Pagination
            pageData={pageData}
            pageCount={+(pageCount || MINIMUM_PAGE_COUNT)}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </>
      </div>
    </div>
  );
};

export default EventList;
