import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { BiPencil, BiTrashAlt } from 'react-icons/bi';

import LFModal from 'components/common/LFModal';

const DeletePopUp = ({ onClick, type }) => {
  return (
    <li onClick={onClick} className="overflow-menu__item color-tertiary-red-40">
      <div className="icon-container d-flex">
        <BiTrashAlt size={18} />
        <span className="overflow-menu__text">Delete {type} </span>
      </div>
    </li>
  );
};

function EditDelete(props) {
  const { canEdit, canDelete, onEdit, onDelete, type = '', title } = props;

  return (
    <ul className="overflow-menu">
      {canEdit && (
        <li onClick={onEdit} className="overflow-menu__item">
          <div className="icon-container d-flex">
            <BiPencil size={18} />
            <span className="overflow-menu__text">Edit {type}</span>
          </div>
        </li>
      )}
      {canDelete && (
        <LFModal
          TriggerBtn={({ onClick }) => (
            <DeletePopUp
              onClick={e => {
                e.preventDefault();
                onClick();
              }}
              type={type}
            />
          )}
          modalStatusIcon={<FiAlertTriangle color="#F44336" size={24} />}
          title={{ text: `Delete ${type}?`, type: 'danger' }}
          className="delete-modal"
          htmlContent={
            <div className="d-grid">
              <span className="delete__text">The following {type} will be deleted:</span>
              <span className="delete__title">{title}</span>
            </div>
          }
          renderFooter={true}
          buttons={[
            {
              text: 'Yes, delete',
              type: 'red',
              className: 'mr-12 delete__btn',
              onClick: () => onDelete(),
              close: true,
              showLoading: true
            },
            {
              text: 'Nevermind',
              type: 'outlined-grey',
              className: 'py-8 px-12',
              close: true
            }
          ]}
        />
      )}
    </ul>
  );
}

export default EditDelete;
