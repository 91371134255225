import React from 'react';
import classNames from 'classnames';

import config from 'config';
import { urls } from 'constants/jump';

import * as mixpanel from 'services/mixPanel';
import { mixPanelEvents } from 'constants/mixPanel';

export default function DashboardCard({ title = '', className, children }) {
  return (
    <div className={classNames('full-scope-card', 'dashboard__card', className)}>
      {title && <div className="dashboard__title">{title}</div>}
      <div className="dashboard__content">{children}</div>
    </div>
  );
}

export function DashboardLinkedCard({ link, target = '__self', ...props }) {
  const { myKpis, askShareForm } = mixPanelEvents.view;

  const getMixpanelEvent = link => {
    if (link === urls.KPI_VIEW) {
      return myKpis;
    }

    if (link === config.askUsAnythingForm) {
      return askShareForm;
    }

    return null;
  };

  return (
    <a
      href={link}
      target={target}
      className="dashboard__card--link"
      onClick={() => mixpanel.trackEvent(getMixpanelEvent(link))}
    >
      <DashboardCard className="dashboard__card--linked" {...props} />
    </a>
  );
}
