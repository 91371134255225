import React from 'react';

import { UiCheckCircle } from 'vyaguta-icons/ui';

import PopOver from 'components/common/popover';

import { VERIFIED } from 'constants/skills';

const VerifiedSkill = props => {
  const { count } = props;

  return (
    <PopOver
      theme="light"
      html={
        <div>
          {count.verifiedSkills} of {count.totalSkills} skills verified
        </div>
      }
    >
      <div className="verified-skill">
        <UiCheckCircle size={14} />
        {!!count && (
          <div>
            <span>{count.verifiedSkills}</span>/{count.totalSkills}
          </div>
        )}
      </div>
    </PopOver>
  );
};

export default VerifiedSkill;
