import { Formik } from 'formik';
import React, { useRef } from 'react';
import pinterpolate from 'pinterpolate';
import LFModal from 'components/common/LFModal';
import Input from 'components/common/inputWrapper/components/Input';
import { confirmEngagementStatusSchema } from 'schemas/changeEngagementStatusSchema';

import {
  ENGAGEMENT_STATUS_TRANSITION_CONFIRMATION_MESSAGE,
  ENGAGEMENT_STATUS_TRANSITION_CONFIRMATION_TITLE
} from 'constants/appConstants';

/**
 * An object containing props for ConfirmationModal.
 * @typedef {Object} ConfirmationModal~Props
 * @property {boolean} isOpen - State of the modal open or close state.
 * @property {boolean} displayMensurationLeaveField - Determine if mensuration leave field gets displayed or not.
 * @property {boolean} isSubmitting - Will disable all the inputs and display loading icon on save button.
 * @property {Number} discretionaryLeave - Submitted value will be added as initial value on input field.
 * @property {Number} sickLeave - Submitted value will be added as initial value on input field.
 * @property {Number} mensurationLeave - Submitted value will be added as initial value on input field.
 * @property {String} newEngagementStatusLabel - Engagement status label of new.
 * @property {String} currentEngagementStatusLabel - Indicates whether the Wisdom component is present.
 * @property {fn} onConfirm - Callback function for affirmative action.
 * @property {fn} onCancel - Callback function for negative action.
 */

/**
 * Confirmation Modal: Returns a confirmation modal for engagement status change
 * with discretionary and sick leave field to add leave of the user in context.
 * @param {ConfirmationModal~Props} props
 * @return {JSX.Element}
 * @constructor
 */

const ConfirmationModal = ({
  isSubmitting,
  isOpen,
  displayMensurationLeaveField,
  newEngagementStatusLabel,
  currentEngagementStatusLabel,
  discretionaryLeave,
  sickLeave,
  mensurationLeave,
  onConfirm,
  onCancel
}) => {
  const formRef = useRef(null);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <LFModal
      isOpen={isOpen}
      headerBorder={true}
      title={{
        text: ENGAGEMENT_STATUS_TRANSITION_CONFIRMATION_TITLE,
        type: 'secondary-base'
      }}
      htmlContent={
        <Formik
          innerRef={formRef}
          initialValues={{
            discretionaryLeave,
            sickLeave,
            mensurationLeave
          }}
          onSubmit={values => {
            const { discretionaryLeave, sickLeave, mensurationLeave } = values;
            return onConfirm({
              discretionaryLeave,
              sickLeave,
              mensurationLeave
            });
          }}
          validationSchema={confirmEngagementStatusSchema({
            discretionaryLeave,
            sickLeave,
            mensurationLeave,
            mensurationLeaveRequired: displayMensurationLeaveField
          })}
          render={({ values, errors, setFieldValue }) => {
            return (
              <>
                <div className="lf-modal__info padding--tb">
                  {pinterpolate(ENGAGEMENT_STATUS_TRANSITION_CONFIRMATION_MESSAGE, {
                    currentEngagementStatusLabel,
                    newEngagementStatusLabel
                  })}
                </div>
                <div className="d-flex flex-row justify-content-between gap-10">
                  <Input
                    label={`Discretionary Leave`}
                    name={`discretionaryLeave`}
                    type={`number`}
                    value={values.discretionaryLeave}
                    onChange={e => {
                      setFieldValue('discretionaryLeave', e.target.value);
                    }}
                    error={errors.discretionaryLeave}
                    min={0}
                    step={1}
                    isMandatory
                    className={`mb-0 flex-grow`}
                    disabled={isSubmitting}
                  />
                  <Input
                    label={`Sick Leave`}
                    name={`sickLeave`}
                    type={`number`}
                    value={values.sickLeave}
                    onChange={e => {
                      setFieldValue('sickLeave', e.target.value);
                    }}
                    error={errors.sickLeave}
                    min={0}
                    step={1}
                    isMandatory
                    className={`mb-0 flex-grow`}
                    disabled={isSubmitting}
                  />
                  {displayMensurationLeaveField && (
                    <Input
                      label={`Menstruation Leave`}
                      name={`mensurationLeave`}
                      type={`number`}
                      value={values.mensurationLeave}
                      onChange={e => {
                        setFieldValue('mensurationLeave', e.target.value);
                      }}
                      error={errors.mensurationLeave}
                      min={0}
                      step={1}
                      isMandatory
                      className={`mb-0 flex-grow`}
                      disabled={isSubmitting}
                    />
                  )}
                </div>
              </>
            );
          }}
        ></Formik>
      }
      renderFooter={true}
      footerBorder={true}
      buttons={[
        {
          text: 'Change & Exit',
          className: 'margin--r',
          onClick: handleSubmit,
          showLoading: true,
          isLoading: isSubmitting
        },
        {
          text: 'Exit without changing',
          type: 'outlined-grey',
          onClick: onCancel,
          disabled: isSubmitting
        }
      ]}
    />
  );
};

export default ConfirmationModal;
