import React, { useEffect, useState } from 'react';

import * as toast from 'utils/toast';
import { fetchByEmail } from 'services/user';
import { getFormattedDate } from 'utils/date';
import LFModal from 'components/common/LFModal';
import { handleError } from 'utils/errorHandler';
import ImportButton from 'components/common/button/ImportButton';

const ImportSheet = props => {
  const { setInitialValues } = props;
  const [sheetValues, setSheetValues] = useState('');

  const [newValue, setNewValue] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = e => {
    setSheetValues(e.target.value);
  };

  useEffect(() => {
    const setEmployeeInDebounce = async (email, id) => {
      try {
        if (!newValue[email]) {
          return;
        }

        const [data] = await fetchByEmail(newValue[email]);

        setInitialValues(prevState => ({
          ...prevState,
          [id]: data?.id
        }));
      } catch (err) {
        handleError(err);
      }
    };

    setInitialValues(prevState => ({
      ...prevState,
      ...newValue
    }));

    /* Set Leave Issuer */
    setEmployeeInDebounce('leaveIssuerEmail', 'leaveIssuerId');

    /* Set Team Manager */
    setEmployeeInDebounce('teamManagerEmail', 'teamManagerId');

    /* Set Appraiser */
    setEmployeeInDebounce('appraiserEmail', 'appraiserId');

    /* Set CoAppraiser */
    setEmployeeInDebounce('coAppraiserEmail', 'coAppraiserId');

    if (!newValue) {
      return;
    }

    const updatedValue = {
      ...newValue,
      designationAreaHistory:
        newValue.designation && newValue.designationId && newValue.area && newValue.areaId
          ? [
              {
                area: { id: newValue.areaId, name: newValue.area },
                designation: { id: newValue.designationId, name: newValue.designation },
                transitionDate: getFormattedDate(newValue?.joinDate)
              }
            ]
          : [],
      empStatusHistory:
        newValue.engagementStatus && newValue.engagementStatusId
          ? [
              {
                engagementStatus: { id: newValue.engagementStatusId, name: newValue.engagementStatus },
                transitionDate: getFormattedDate(newValue?.joinDate)
              }
            ]
          : [],
      roleIds: newValue?.rolesIds?.split(',').map(Number),
      joinDate: getFormattedDate(newValue?.joinDate),
      dateofBirth: newValue.dateofBirth ? getFormattedDate(newValue.dateofBirth) : ''
    };

    setInitialValues(prevState => ({
      ...prevState,
      designationAreaHistory: updatedValue.designationAreaHistory,
      empStatusHistory: updatedValue.empStatusHistory,
      roleIds: updatedValue.roleIds,
      joinDate: updatedValue.joinDate,
      dateofBirth: updatedValue.dateofBirth
    }));
  }, [newValue]);

  const handleSubmit = () => {
    try {
      const parsedJson = JSON.parse(sheetValues);

      setIsModalOpen(false);
      setNewValue(parsedJson);
      setSheetValues('');
    } catch (err) {
      toast.error({
        title: 'Error',
        message: 'Please enter correct JSON data.'
      });
    }
  };

  const handleModalTriggerClick = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSheetValues('');
  };

  const htmlForm = (
    <form>
      <textarea
        name="description"
        type="text"
        value={sheetValues}
        placeholder="Place the JSON data from the excel sheet here"
        className="form-elem"
        onChange={handleChange}
      />
    </form>
  );

  return (
    <div className="import">
      <p className="import__title">Import Leapfrogger</p>
      <p className="import__detail">Import Leapfrogger's data from excel sheet.</p>

      <LFModal
        TriggerBtn={({ onClick }) => (
          <ImportButton
            onClick={() => {
              handleModalTriggerClick();
              onClick();
            }}
          />
        )}
        title={{ text: 'Import Data From Excel Sheet' }}
        renderFooter={true}
        htmlContent={htmlForm}
        isOpen={isModalOpen}
        buttons={[
          {
            text: 'Confirm',
            className: 'mr-12',
            onClick: handleSubmit,
            showLoading: true
          },
          {
            text: 'Cancel',
            type: 'outlined-grey',
            className: 'py-8 px-12',
            onClick: handleClose,
            close: true
          }
        ]}
      />
    </div>
  );
};

export default ImportSheet;
