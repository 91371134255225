// Use a Toast Library

import { notify } from 'components/common/toast';

export function success({ title, message, className }) {
  notify({ type: 'success', data: { title, message }, className });
}

export function error({ title, message, className }) {
  notify({ type: 'danger', data: { title, message }, className });
}

export function warning({ title, message, className }) {
  notify({ type: 'warning', data: { title, message }, className });
}
