import { WORK_LOCATION } from './worklog';

export const UPDATE_QUICK_WFH_ACTION_MESSAGE = 'I am working from home today.';
export const UPDATE_QUICK_WFO_ACTION_MESSAGE = 'I am working from office today.';
export const QUICK_WFH_ACTION_ACTIVE_MESSAGE = 'You are working from home today.';
export const QUICK_WFO_ACTION_ACTIVE_MESSAGE = 'You are working from office today.';
export const QUICK_ACTION_BUTTON_DISABLED_MESSAGE = "You have already updated your today's status.";
export const QUICK_ACTION_UPDATED_WORKLOG_MESSAGE = "You have already filled today's worklog.";
export const QUICK_ACTION_UPDATE_WORKLOG_MESSAGE = "Fill today's worklog";
export const QUICK_ACTION_APPLY_LEAVE_MESSAGE = 'Apply leave for today';
export const QUICK_ACTION_APPLIED_LEAVE_MESSAGE = 'You are on leave today.';
export const QUICK_ACTION_INACTIVE_APPLY_LEAVE_MESSAGE = 'Apply leave';
export const QUICK_ACTION_FREEZE_MESSAGE = 'Apply missing attendance';

export const UPDATE_ATTENDANCE_STATUS_TOAST_MESSAGE = {
  [WORK_LOCATION.OFFICE]: 'You are all set to work from office for today.',
  [WORK_LOCATION.HOME]: 'You are all set to work from home for today.'
};

export const APPROVE_LEAVE_REQUEST_MESSAGE = 'Leave request has been approved!';
export const REJECT_LEAVE_REQUEST_MESSAGE = 'Request has been rejected!';
