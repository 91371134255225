import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as memory from 'utils/memory';
import { isDateInFuture } from 'utils/date';
import { isObjectEmpty } from 'utils/object';
import { handleError } from 'utils/errorHandler';
import { CURRENT_FISCAL_YEAR } from 'constants/memory';
import { fetch as fetchFiscalYear } from 'services/fiscalYear';

const useCurrentFiscalYear = () => {
  const fiscalYearFromLocalStorage = memory.get(CURRENT_FISCAL_YEAR, true, {});
  const [currentFiscalYear, setCurrentFiscalYear] = useState({});

  const { user } = useSelector(state => state.information.value);

  useEffect(() => {
    if (!isObjectEmpty(fiscalYearFromLocalStorage)) {
      const { endDate, country } = fiscalYearFromLocalStorage;

      // End date is future means the current fiscal year is valid.
      if (isDateInFuture(endDate) && country === user?.country) {
        if (!isEqual(currentFiscalYear, fiscalYearFromLocalStorage)) {
          setCurrentFiscalYear(fiscalYearFromLocalStorage);
        }
        return;
      }
    }

    (async () => {
      try {
        // Only fetch fiscal year with country filter
        if (!user.country) {
          return;
        }

        const fiscalYear = await fetchFiscalYear({ country: user.country });
        const newCurrentFiscalYear = fiscalYear.find(({ isCurrent }) => isCurrent) || {};

        if (!isEqual(currentFiscalYear, newCurrentFiscalYear)) {
          memory.set(CURRENT_FISCAL_YEAR, newCurrentFiscalYear);
          setCurrentFiscalYear(newCurrentFiscalYear);
        }
      } catch (e) {
        handleError(e);
      }
    })();
  }, [user.country]);

  return currentFiscalYear;
};

export default useCurrentFiscalYear;
