import isEqual from 'lodash/isEqual';
import React, { useState, useMemo } from 'react';

import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import AddCategory from './components/AddCategory';
import SortableTreeItem from './dndComponents/SortableTreeItem';
import DndContextComponent from 'components/common/sortableDnD/DndContextComponent';

import { countLinks, formattedUpdateWeightBody } from 'utils/linkLibrary';

import { useFetchQuickLinks, useUpdateLinkLibraryMutation } from 'hooks/query/useQuickLinksQuery';

const AddCategoryButton = (onClick, setIsCategoryModalOpen) => {
  const addCategory = useMemo(() => {
    return (
      <button
        type="button"
        className="f-left add-employee-button"
        onClick={() => {
          setIsCategoryModalOpen(true);
          onClick();
        }}
      >
        Add new category
      </button>
    );
  }, [onClick]);

  return addCategory;
};

const LinkLibraryList = () => {
  window.document.title = 'Link Libraries | Vyaguta';

  const [treeData, setTreeData] = useState([]);
  const [totalLinks, setTotalLinks] = useState(0);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  const { isLoading: isQuickLinksLoading } = useFetchQuickLinks({
    onSuccess: data => {
      if (!isEqual(data, treeData)) {
        setTreeData([...data]);
        setTotalLinks(countLinks(data));
      }
    }
  });
  const { mutate } = useUpdateLinkLibraryMutation();

  const closeCategoryModal = () => {
    setIsCategoryModalOpen(false);
  };

  const onDragEnd = data => {
    const bodyPayload = formattedUpdateWeightBody(data);

    setTreeData(data);
    mutate(bodyPayload);
  };

  return (
    <>
      {!isQuickLinksLoading && (
        <>
          <div className="full-scope-card rst__header">
            <div className="full-scope-card__header table-header name-wrap">
              <div>
                <div className="table-title">Link Library</div>
                <div className="table-subtitle">{totalLinks} links in total</div>
              </div>
              <div className="table-header__right">
                {
                  <AddCategory
                    isEdit={false}
                    isSubCategory={false}
                    isOpen={isCategoryModalOpen}
                    onClose={closeCategoryModal}
                    TriggerBtn={({ onClick }) => AddCategoryButton(onClick, setIsCategoryModalOpen)}
                  />
                }
              </div>
            </div>
          </div>
          <div className="rst mbpx-30">
            <DndContextComponent listData={treeData} onDragEnd={onDragEnd}>
              <ul>
                <SortableContext items={treeData.map(({ id }) => id)} strategy={verticalListSortingStrategy}>
                  {treeData.map(data => (
                    <div key={`${data.id}-${data.name}`}>
                      <SortableTreeItem id={data?.id} data={data} />
                    </div>
                  ))}
                </SortableContext>
              </ul>
            </DndContextComponent>
          </div>
        </>
      )}
    </>
  );
};

export default LinkLibraryList;
