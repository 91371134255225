import React from 'react';
import classNames from 'classnames';

// Icons
import { UiTrash, UiRepeat } from 'vyaguta-icons/ui';

// Constant
import {
  ACCEPT_TYPE_ERROR,
  PROFILE_IMAGE_DIMENSION,
  UNIT_TO_CONVERT_BYTE_IN_KILOBYTE,
  imageDetail,
  MAX_FILE_SIZE_ERROR
} from 'constants/createEmployeeForm';

const ImageUpload = props => {
  const {
    imageSelected,
    imageList,
    onImageUpload,
    onImageUpdate,
    onImageRemove,
    isButtonsVisible,
    isDragging,
    dragProps,
    errors,
    error
  } = props;

  const onImageChange = index => {
    const updateImage = () => onImageUpdate(index);

    return updateImage;
  };

  const onImageDelete = index => {
    const removeImage = () => onImageRemove(index);

    return removeImage;
  };

  if (!imageSelected) {
    return (
      <>
        <div
          className={classNames(
            'image-upload',
            { 'image-upload__dragging': isDragging },
            { 'image-upload__error': error }
          )}
          {...dragProps}
        >
          <div className="image-upload__top">
            <p className="image-upload__top__title">Drag or </p>
            <div className="image-upload__top__file-input">
              <label onClick={onImageUpload}>Click</label>
            </div>
            <p className="image-upload__top__title--last">to upload</p>
          </div>

          {imageDetail?.detail.map((item, i) => (
            <p key={i} className="image-upload__detail">
              {item}
            </p>
          ))}
        </div>
        <div>
          {errors && (
            <div className="error-block">
              {errors.acceptType && <span>{ACCEPT_TYPE_ERROR}</span>}
              {errors.maxFileSize && <span>{MAX_FILE_SIZE_ERROR}</span>}
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {imageList.map((image, index) => (
        <div key={index} className="image-selected">
          <div className="image-selected__image-container">
            <img className="image" alt="profile-img" src={image.data_url} />
            <div className="title">
              <p>{image.file.name}</p>
              <p className="title--sub-title">
                {PROFILE_IMAGE_DIMENSION} {image.file.size / UNIT_TO_CONVERT_BYTE_IN_KILOBYTE} kb
              </p>
            </div>
          </div>
          {isButtonsVisible && (
            <div className="image-selected__bottom">
              <label className="btn  btn--outlined-grey py-8 px-12 mr-12 cursor-pointer" onClick={onImageChange(index)}>
                <UiRepeat /> <div className="ml-5"> Replace </div>
              </label>
              <label className="btn btn--outlined-danger mr-12 cursor-pointer" onClick={onImageDelete(index)}>
                <UiTrash className="color-white" /> <div className="ml-5"> Delete </div>
              </label>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default ImageUpload;
