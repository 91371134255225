import { useQuery } from 'react-query';

import { LEAVE_QUERY_KEYS } from './keys';

import { fetchWellBeingLeaves } from 'services/leave';

export function useFetchWellBeingLeaveCredits(param = {}) {
  return useQuery({
    queryKey: [LEAVE_QUERY_KEYS.WELL_BEING_LEAVE_CREDITS, param.userId, param.fiscalYearId],
    queryFn: () => fetchWellBeingLeaves(param),
    enabled: !!param.userId && !!param.fiscalYearId
  });
}
