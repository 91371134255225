import classNames from 'classnames';
import { Route } from 'react-router-dom';
import { Forbidden } from '@vyaguta/vyaguta-ui';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserContext } from 'components/App';
import Loading from 'components/common/loading/Loading';

import { HOME } from 'constants/routes';

import history from 'utils/history';

import { fetchMyRole } from 'slices/myRoleSlice';

/**
 * Determines whether a user has access to view a page based on their permissions, required permissions, forbidden roles, subcontractor status, and self-allowance.
 *
 * @param {Object} permissions - The user's permissions.
 * @param {Array} requiredPermissions - The required permissions to view the page.
 * @param {Array} forbiddenRoles - The forbidden roles that restrict access to the page.
 * @param {boolean} isSubContractor - Indicates whether the user is a subcontractor.
 * @param {boolean} allowSelf - Indicates whether the user is allowed to view their own page.
 * @returns {boolean} - Returns true if the user has access to view the page, otherwise false.
 */
const getViewAccess = (permissions, requiredPermissions, forbiddenRoles, isSubContractor, allowSelf) => {
  // If forbiddenRoles are present, requiredPermissions are not present and isSubContractor then the user is not allowed to view the page
  if (!!forbiddenRoles?.length && !requiredPermissions?.length) {
    if (isSubContractor) {
      return false;
    }

    return true;
  }

  const canView = requiredPermissions?.some(permission => permissions?.[permission]) || allowSelf;

  return canView;
};

const AuthorizedRoute = ({
  component: Component,
  permission = [],
  allowSelf,
  customClassName,
  forbiddenRoles,
  ...rest
}) => {
  const user = useContext(UserContext);

  const dispatch = useDispatch();

  const {
    value: { permissions },
    loading: isPermissionLoading
  } = useSelector(state => state.information);

  const { value: userRoles = [], loading: isUserRoleLoading } = useSelector(state => state.userRoles);

  useEffect(() => {
    if (!user.id) {
      return;
    }

    dispatch(fetchMyRole(+user.id));
  }, [dispatch, user]);

  const isSubContractor = forbiddenRoles?.some(item => userRoles.includes(item));

  const isLoading = isPermissionLoading || isUserRoleLoading;

  if (isLoading) {
    return (
      <div className="container flash-card">
        <div className="full-scope-card loading__container">
          <Loading />
        </div>
      </div>
    );
  }

  const canView = getViewAccess(permissions, permission, forbiddenRoles, isSubContractor, allowSelf);

  return (
    <Route
      {...rest}
      render={props => {
        return canView ? (
          <Component {...rest} {...props} />
        ) : (
          <Forbidden
            redirectHandler={() => history.push(HOME)}
            className={classNames('flash-card', { [customClassName]: customClassName })}
          />
        );
      }}
    />
  );
};

export default AuthorizedRoute;
