import React from 'react';
import { withRouter } from 'react-router-dom';
import { FaRegCalendarTimes } from 'react-icons/fa';

import { unParseQuery } from 'utils/string';
import { getFormattedDate } from 'utils/date';

import { APPLY_LEAVE_URL } from 'constants/endpoints';

const ApplyButton = props => {
  const date = getFormattedDate();
  const search = unParseQuery({ startDate: date, endDate: date });
  const url = `${APPLY_LEAVE_URL}${search}`;

  return (
    <a href={url}>
      <div className="action-bar__btnarea d-flex align-items-center">
        <button type="button" className="btn btn-shrink btn--alternate btn--curved d-inline-flex flex-fix">
          <FaRegCalendarTimes size="16px" className="mr-5" />
          Apply Leave
        </button>
      </div>
    </a>
  );
};

export default withRouter(ApplyButton);
