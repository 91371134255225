import React from 'react';
import classNames from 'classnames';
import { UiExternalLinkAlt } from 'vyaguta-icons/ui';

import PopOver from 'components/common/popover';

import { NOTICE_BOARD_TITLE_LENGTH } from 'constants/string';

const NoticeCard = ({ notice }) => {
  const { title, description, redirectUrl } = notice;

  return (
    <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
      <div className={classNames('notice-card', { 'notice-card__clickable': redirectUrl })}>
        <div className="d-flex gap-px-8">
          <PopOver
            interactive
            html={
              <div className="tooltip-info">
                <p className="tooltip-info__title">{title}</p>
              </div>
            }
            customClass="d-grid"
            visible={title.length > NOTICE_BOARD_TITLE_LENGTH}
          >
            <div className="notice-card__title ellipsis">{title}</div>
          </PopOver>

          {redirectUrl && <UiExternalLinkAlt className="notice-card__link-icon" size={14} />}
        </div>

        <div className={classNames('notice-card__description', 'ellipsis ellipsis__two-lines')}>{description}</div>
      </div>
    </a>
  );
};

export default NoticeCard;
