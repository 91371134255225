export function useMediaQuery({ maxWidth, minWidth }) {
  if (!maxWidth && !minWidth) {
    return null;
  }

  const getMediaQueryString = (maxWidth, minWidth) => {
    const MAX_WIDTH_QUERY = `(max-width: ${maxWidth})`;
    const MIN_WIDTH_QUERY = `(min-width: ${minWidth})`;

    if (maxWidth && minWidth) {
      return `${MIN_WIDTH_QUERY}, ${MAX_WIDTH_QUERY}`;
    }
    if (maxWidth) {
      return MAX_WIDTH_QUERY;
    }
    if (minWidth) {
      return MIN_WIDTH_QUERY;
    }
  };

  const mediaQueryString = getMediaQueryString(maxWidth, minWidth);

  const mediaQuery = window.matchMedia(mediaQueryString);

  return mediaQuery.matches;
}
