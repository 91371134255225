import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import * as roleService from 'services/role';

import { success } from 'utils/toast';
import { handleError } from 'utils/errorHandler';

import PopOver from 'components/common/popover';
import Loading from 'components/common/loading/Loading';

import { ROLE_PERMISSION } from 'constants/permissions';
import { PERMISSION_GRANT_SUCCESS_MESSAGE, PERMISSION_REVOKE_SUCCESS_MESSAGE } from 'constants/appConstants';

const RolesPermissionsTable = ({ roles, permissions, rolesPermissions: rolesPermissionsFromProps }) => {
  const [rolesPermissions, setRolesPermissions] = useState(new Map());
  const [updatingRolesPermissions, setUpdatingRolesPermissions] = useState({});
  const {
    value: { permissions: currentUserPermissions }
  } = useSelector(state => state.information);

  useEffect(() => {
    setRolesPermissions(() => {
      const newRolesPermissions = new Map();

      rolesPermissionsFromProps.forEach(role => {
        newRolesPermissions.set(
          role.id,
          role.permissions.reduce(
            (permissionArray, currentPermission) => [...permissionArray, currentPermission.id],
            []
          )
        );
      });

      return newRolesPermissions;
    });
  }, [rolesPermissionsFromProps]);

  const checkRolePermission = (roleId, permissionId) => {
    const permissions = rolesPermissions.get(roleId);

    if (!permissions || !permissions.length) {
      return false;
    }

    return permissions.indexOf(permissionId) >= 0;
  };

  /**
   *  Returns boolean value for if the permission of the role is being updated or not
   * @param {Number} roleId
   * @param {Number} permissionId
   * @return {boolean}
   */
  const rolePermissionIsUpdating = (roleId, permissionId) => {
    return updatingRolesPermissions.hasOwnProperty(`${roleId}-${permissionId}`);
  };

  /*
    To change permissions text 'employees.employment.history.view'
    to 'view employees employment history'
  */
  const getPermissionsName = permissions => {
    const separatedWords = permissions.split('.');

    return [separatedWords[separatedWords.length - 1], ...separatedWords.slice(0, -1)].join(' ');
  };

  /**
   * Handles permission change event
   *
   * @param roleId
   * @param permissionId
   */
  const handlePermissionChange = async (roleId, permissionId) => {
    try {
      setUpdatingRolesPermissions(prevState => ({
        ...prevState,
        [`${roleId}-${permissionId}`]: true
      }));

      const shouldRevoke = checkRolePermission(roleId, permissionId);

      shouldRevoke
        ? await roleService.revokeRolesPermissions(roleId, permissionId)
        : await roleService.grantRolesPermissions(roleId, permissionId);

      setRolesPermissions(prevRolesPermissions => {
        const newRolesPermissions = new Map(prevRolesPermissions);

        const newPermissions = shouldRevoke
          ? newRolesPermissions.get(roleId).filter(pId => pId !== permissionId)
          : [...prevRolesPermissions.get(roleId), permissionId];

        newRolesPermissions.set(roleId, newPermissions);

        return newRolesPermissions;
      });

      success({
        title: 'Success',
        message: shouldRevoke ? PERMISSION_REVOKE_SUCCESS_MESSAGE : PERMISSION_GRANT_SUCCESS_MESSAGE
      });
    } catch (err) {
      handleError(err);
    } finally {
      setUpdatingRolesPermissions(prevState => {
        const newState = { ...prevState };
        delete newState[`${roleId}-${permissionId}`];

        return newState;
      });
    }
  };

  return (
    <div className="roles-permissions custom-scroll-bar">
      <table className="roles-permissions-table">
        <thead className="roles-permissions-table__header">
          <tr className="roles-permissions-table__row">
            <th className="roles-permissions-table__head">Actions</th>
            {roles.map(role => (
              <th className="roles-permissions-table__head" key={role.id}>
                {role.name}
                <br />
                <span className="roles-permissions-table__id">({role.id})</span>
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="roles-permissions-table__row">
          {permissions.map(permission => (
            <tr className="roles-permissions-table__row" key={permission.id}>
              <td className="roles-permissions-table__data roles-permissions-table__data--head capital-text">
                {getPermissionsName(permission.label)}{' '}
                <span className="roles-permissions-table__id">({permission.id})</span>
              </td>
              {roles.map(role => (
                <td className="roles-permissions-table__data" key={`${role.id}-${permission.id}`}>
                  {rolePermissionIsUpdating(role.id, permission.id) ? (
                    <Loading />
                  ) : (
                    <PopOver interactive html={<>{role.name}</>}>
                      <div className="roles-permissions-table__checkbox">
                        <input
                          disabled={
                            !currentUserPermissions[ROLE_PERMISSION.CREATE] &&
                            !currentUserPermissions[ROLE_PERMISSION.DELETE]
                          }
                          id={`${role.id}-${permission.id}`}
                          type="checkbox"
                          name="roles-permissions-table-checkmark"
                          className="roles-permissions-table__checkbox-btn"
                          checked={checkRolePermission(role.id, permission.id)}
                          onChange={() => handlePermissionChange(role.id, permission.id)}
                        />
                        <label htmlFor={`${role.id}-${permission.id}`} className="roles-permissions-table__label" />
                      </div>
                    </PopOver>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(RolesPermissionsTable);
