import React from 'react';
import Select from 'react-select';

const DatePickerDropdown = (options, value, onChange) => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      className="date-picker-dropdown"
      classNamePrefix="date-picker-dropdown"
      searchable={false}
      styles={{
        control: base => ({
          ...base,
          boxShadow: 'none',
          borderRadius: '2px',
          height: '38px',
          minHeight: '38px',
          borderColor: '#e6e6e6'
        }),
        indicatorSeparator: base => ({
          ...base,
          display: 'none'
        }),
        dropdownIndicator: base => ({
          ...base,
          padding: '0px 8px 0px 0px'
        }),
        valueContainer: base => ({
          ...base,
          padding: '0px 0px 0px 8px',
          position: 'static',
          fontSize: '14px',
          display: 'flex',
          justifyContent: 'start',
          color: '#000000',
          fontWeight: '500'
        }),
        indicatorsContainer: base => ({
          ...base
        }),
        menu: base => ({
          ...base,
          fontSize: '14px'
        }),
        menuList: base => ({
          ...base,
          maxHeight: '180px',
          overflowX: 'hidden',
          textAlign: 'left'
        })
      }}
    />
  );
};

export default DatePickerDropdown;
