import React from 'react';

import config from 'config';

const ReportBug = props => {
  return (
    <a href={config.bugReportUrl} target="_blank" rel="noopener noreferrer" className="footer__label">
      Report Bug
    </a>
  );
};

export default ReportBug;
