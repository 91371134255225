import React from 'react';

import { medianClockIn } from 'assets/images';

import { convertTimeToAMPMWithDots } from 'utils/time';
import TooltipPopOver from 'components/common/pill/TooltipPopOver';

export function ClockInTime(props) {
  const { medianClockInTime } = props;

  const medianTimeTooltipText = 'Your median clock-in time, visible only to you';

  return (
    <TooltipPopOver position="bottom-start" html={<p className="font-size-12">{medianTimeTooltipText}</p>}>
      <div>
        <div className="median-office-time__clock-in cursor-pointer d-flex gap-x-4 p-8">
          <img src={medianClockIn} alt="Office clock in time" />
          <div>
            <div className="font-size-18 d-flex gap-x-4 align-items-center">
              <span>WFO</span>
              <span className="median-office-time__clock-in__time font-weight-semi-bold">
                {(medianClockInTime && convertTimeToAMPMWithDots(medianClockInTime)) || '-'}
              </span>
            </div>
            <div className="median-office-time__clock-in__text font-size-12 color-grey-60">median clock-in time</div>
          </div>
        </div>
      </div>
    </TooltipPopOver>
  );
}

export default ClockInTime;
