import * as Sentry from '@sentry/browser';

import config from 'config';

/**
 * Initializing sentry.
 */
const sentry = () => {
  Sentry.init({
    dsn: config.sentryDSN,
    environment: config.env
  });
};

export default sentry;
