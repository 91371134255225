import http from '../utils/http';
import { interpolate } from '../utils/string';

import { DESIGNATION_URL, DESIGNATION_URL_ID } from '../constants/endpoints';

export async function fetchAll() {
  return await http.get(DESIGNATION_URL, { params: { size: 1000 } });
}

export async function create(designation) {
  const data = http.post(DESIGNATION_URL, { body: designation });

  return data;
}

export async function fetchById(id) {
  const data = await http.get(interpolate(DESIGNATION_URL_ID, { id }));

  return data;
}

export async function update(designation, id) {
  const data = await http.put(interpolate(DESIGNATION_URL_ID, { id }), { body: designation });

  return data;
}

export async function remove(id) {
  const data = await http.delete(interpolate(DESIGNATION_URL_ID, { id }));

  return data;
}
