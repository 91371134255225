import * as Yup from 'yup';
import MomentDateSchemaType from './momentdate';

import { getRequiredErrorLabel } from 'utils/string';

const momentSchema = new MomentDateSchemaType();

const noticeSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .max(255, 'Title cannot exceed more than 255 characters')
    .required(getRequiredErrorLabel('title')),
  description: Yup.string()
    .trim()
    .min(8, 'Description should be of at least 8 characters')
    .nullable(),
  redirectUrl: Yup.string()
    .trim()
    .test('urlValidation', 'Provide a valid link, example: https://www.example.com', function(value) {
      const validatorRegX = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

      return Boolean(value) ? validatorRegX.test(value) : true;
    })
    .nullable(),
  startDate: momentSchema.required(getRequiredErrorLabel('notice start date')),
  endDate: momentSchema
    .min(Yup.ref('startDate', 0), 'End date must be equal to or greater than start date.')
    .required(getRequiredErrorLabel('notice end date'))
});

export default noticeSchema;
