import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Forbidden } from '@vyaguta/vyaguta-ui';
import React, { useEffect, useMemo, useState } from 'react';

import useTitle from 'hooks/useTitle';
import useEmployeeDebounceOptions from 'hooks/useEmployeeDebounceOptions';

import {
  createLeaveIssuerChangeRequest,
  updateLeaveIssuerChangeRequest,
  fetchLeaveIssuerChangeRequestByUserId
} from 'services/leaveIssuerChangeRequest';
import * as userService from 'services/user';
import * as mixpanel from 'services/mixPanel';
import * as attendanceService from 'services/attendance';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { intersect } from 'utils/array';
import { getFullName } from 'utils/user';
import { isObjectEmpty } from 'utils/object';
import { handleError } from 'utils/errorHandler';
import { getFirstName, interpolate } from 'utils/string';
import { getLeaveIssueCountMessage } from 'utils/issueeCountMessage';
import { getFormattedDate, isDateInPast, isSameDate } from 'utils/date';

import PendingActionsInfo from './PendingActionsInfo';
import Loading from 'components/common/loading/Loading';
import LeaveIssuersHistory from './LeaveIssuersHistory';
import InputWrapper from 'components/common/inputWrapper';
import DatePicker from 'components/common/datepicker/DatePicker';
import Info from 'components/common/inputWrapper/components/Info';
import ProfileHeader from '../employees/components/ProfileHeader';
import Input from 'components/common/inputWrapper/components/Input';
import AvatarComponent from 'components/common/avatar/AvatarComponent';
import FormRadioGroup from 'components/common/inputWrapper/components/RadioGroup';
import DebounceFormDropdown from 'components/common/debounceFormDropdown/DebounceFormDropdown';

import { changeIssuerSchema } from 'schemas/changeIssuerSchema';

import {
  ROLE,
  ROLES_IDS,
  LIST_TYPE,
  USER_FETCH_FIELDS,
  NEW_LEAVE_ISSUER_IS_OPTIONS,
  REACT_DATEPICKER_DATE_FORMAT
} from 'constants/appConstants';
import { mixPanelEvents } from 'constants/mixPanel';
import { EMPLOYEE_PROFILE, HOME } from 'constants/routes';
import { ALL, ROLE_TYPES, TEAM } from 'constants/fetchTypes';
import { LEAVE_ISSUER_CHANGE_STATUS } from 'constants/changeLeaveIssuer';
import { DEFAULT_ISSUE_ALERT_COUNT, USER_TEAM_COUNT_TYPE } from 'constants/leave';

const newLeaveIssuerRoleOptions = [ROLE.PROJECT_MANAGER, ROLE.TEAM_LEAD, ROLE.TEAM_MANAGER, ROLE.TEAM_MEMBER];

const getNewLeaveIssuerRole = newLeaveIssuerRole => {
  if (newLeaveIssuerRoleOptions.includes(newLeaveIssuerRole)) {
    return newLeaveIssuerRole;
  }

  return ROLE.OTHER;
};

const getOtherRole = newLeaveIssuerRole => {
  if (newLeaveIssuerRoleOptions.includes(newLeaveIssuerRole)) {
    return '';
  }

  return newLeaveIssuerRole;
};

const ChangeIssuerRequest = props => {
  useTitle('Change Leave Issuer Request');

  const { user } = props;

  const id = props.match.params.id;
  const currentUserId = props.user?.id;

  const [leaveIssueCount, setleaveIssueCount] = useState(0);
  const [leaveIssuerName, setLeaveIssuerName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [havePermission, setHavePermission] = useState(true);
  const [isEmployeeLoading, setIsEmployeeLoading] = useState();
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [leaveIssuerHistory, setLeaveIssuerHistory] = useState();
  const [isIssueeCountLoading, setIsIssueeCountLoading] = useState(false);
  const [employee, setEmployee] = useState(props.location?.state?.employee);
  const [isLeaveIssuerRequestLoading, setIsLeaveIssuerRequestLoading] = useState(false);
  const [pendingRequests, setPendingRequests] = useState({ leave: 0, worklog: 0 });
  const [leaveIssuerRequest, setLeaveIssuerRequest] = useState(props.location?.state?.leaveIssuerRequest);

  const INITIAL_VALUES = {
    reason: leaveIssuerRequest?.reason || '',
    transitionDate: moment(leaveIssuerRequest?.transitionDate),
    newLeaveIssuerId: leaveIssuerRequest?.newLeaveIssuer?.id || '',
    newLeaveIssuerRole: leaveIssuerRequest?.newLeaveIssuerRole
      ? getNewLeaveIssuerRole(leaveIssuerRequest.newLeaveIssuerRole)
      : '',
    otherRole: leaveIssuerRequest?.newLeaveIssuerRole ? getOtherRole(leaveIssuerRequest.newLeaveIssuerRole) : ''
  };
  const [formValues, setFormValues] = useState(INITIAL_VALUES);

  const isEmployeeEmpty = useMemo(() => isObjectEmpty(employee), [employee]);
  const isLeaveIssuerRequestEmpty = useMemo(() => isObjectEmpty(leaveIssuerRequest), [leaveIssuerRequest]);

  const { value: userRoles = [] } = useSelector(state => state.userRoles);

  const canRequestLeaveIssuer =
    (!pendingRequests.leave && !pendingRequests.worklog) ||
    !!intersect(userRoles, [ROLE.SUPER_ADMIN, ROLE.HR_ADMIN]).length;

  const isLeaveIssuer = employee?.leaveIssuer?.id === currentUserId;

  const isTeamManager = employee?.teamManager?.id === currentUserId;

  useEffect(() => {
    fetchIssuerInfo();
  }, [formValues.newLeaveIssuerId, INITIAL_VALUES.newLeaveIssuerId]);

  const fetchIssuerInfo = async () => {
    setIsIssueeCountLoading(true);

    try {
      const userId = formValues?.newLeaveIssuerId || INITIAL_VALUES.newLeaveIssuerId;

      if (!userId) {
        return;
      }

      const issuerId = formValues.newLeaveIssuerId;

      const userName =
        (employeeDebounceOptions[1].find(option => option.value.toString() === issuerId.toString()) || {}).label ||
        null;

      const { count } = await userService.fetchIssueeCount(userId, { count: USER_TEAM_COUNT_TYPE.LEAVE_ISSUE });

      const leaveIssuerName = getFirstName(userName);
      const leaveIssueCount = count?.leaveIssuee;

      setLeaveIssuerName(leaveIssuerName);
      setleaveIssueCount(leaveIssueCount);
    } catch (e) {
      handleError(e);
    } finally {
      setIsIssueeCountLoading(false);
    }
  };

  const fetchIssuerByUserId = async () => {
    try {
      setIsLeaveIssuerRequestLoading(true);

      const { data } = await fetchLeaveIssuerChangeRequestByUserId(id);

      setHavePermission(true);
      setLeaveIssuerRequest(data);
    } catch (e) {
      setHavePermission(false);
    } finally {
      setIsLeaveIssuerRequestLoading(false);
    }
  };

  const fetchEmployeeById = async () => {
    setIsEmployeeLoading(true);

    try {
      const data = await userService.fetchById(id);

      setEmployee(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsEmployeeLoading(false);
    }
  };

  const fetchLeaveIssuerHistory = async () => {
    try {
      const data = await userService.fetchLeaveIssuerHistory(props.match.params.id);

      setLeaveIssuerHistory(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingHistory(false);
    }
  };

  const fetchAttendanceSummary = async () => {
    try {
      const params = {
        fetchType: isLeaveIssuer || isTeamManager ? TEAM : ALL,
        userId: id,
        fields: 'worklog,leave',
        roleType: isTeamManager ? ROLE_TYPES.MANAGER : undefined
      };

      const { data } = await attendanceService.fetchSummary(params);

      setPendingRequests({
        leave: data?.leave?.requested || 0,
        worklog: data?.worklog?.submitted || 0
      });
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const { form: viewChangeRequestForm } = mixPanelEvents.view.leaveIssuerChangeRequest;

    if (!employee || isEmployeeEmpty) {
      fetchEmployeeById();
    }

    if (!leaveIssuerRequest || isLeaveIssuerRequestEmpty) {
      fetchIssuerByUserId();
    }

    mixpanel.trackEvent(viewChangeRequestForm);

    fetchLeaveIssuerHistory();
    fetchAttendanceSummary();
  }, []);

  const latestTransitionDate = useMemo(() => leaveIssuerHistory?.[0]?.transitionDate, [leaveIssuerHistory]);

  const employeeDebounceOptions = useEmployeeDebounceOptions(leaveIssuerRequest?.newLeaveIssuer?.id, {
    roleIds: ROLES_IDS.ALL,
    fields: USER_FETCH_FIELDS.ROLES
  });

  const createUpdateIssuerChangeRequest = async data => {
    try {
      setIsSubmitting(true);

      const leaveIssuerChangeRequestEvent = isLeaveIssuerRequestEmpty
        ? mixPanelEvents.send.leaveIssuerChangeRequest
        : mixPanelEvents.approve.leaveIssuerChangeRequest.byRecipient;

      isLeaveIssuerRequestEmpty
        ? await createLeaveIssuerChangeRequest(id, data)
        : await updateLeaveIssuerChangeRequest(leaveIssuerRequest?.user?.id, data);

      toast.success({
        title: 'Success',
        message: `Leave issuer change request has been ${
          isLeaveIssuerRequestEmpty ? 'created' : 'approved'
        } successfully.`
      });

      mixpanel.trackEvent(leaveIssuerChangeRequestEvent);

      history.push(interpolate(EMPLOYEE_PROFILE, { id }));
    } catch (e) {
      handleError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = values => {
    const data = isLeaveIssuerRequestEmpty
      ? {
          ...values,
          transitionDate: getFormattedDate(values.transitionDate),
          newLeaveIssuerRole: values.newLeaveIssuerRole === ROLE.OTHER ? values.otherRole : values.newLeaveIssuerRole
        }
      : { status: LEAVE_ISSUER_CHANGE_STATUS.APPROVED };

    const updatedData = _.omit(data, 'otherRole');

    createUpdateIssuerChangeRequest(updatedData);
  };

  const isSelfNewIssuer = leaveIssuerRequest?.newLeaveIssuer?.id === user.id;
  const currentLeaveIssuer =
    leaveIssuerHistory?.[
      leaveIssuerHistory?.findIndex(({ transitionDate }) => isDateInPast(transitionDate) || isSameDate(transitionDate))
    ];

  const isRequestedBySelf = user.id === +id;

  if (!havePermission || isRequestedBySelf) {
    return <Forbidden className="mbpx-20" redirectHandler={() => history.push(HOME)} />;
  }

  if (isEmployeeLoading || !employee || isLeaveIssuerRequestLoading || isLoadingHistory) {
    return (
      <div className="loading-container container">
        <Loading />
      </div>
    );
  }

  return (
    <main>
      <div className="title profile-wrapper">
        <ProfileHeader employee={employee} user={user} />
      </div>
      <div className="container d-flex fd-row flex-fix name-wrap leaveissuer-page-container">
        <div className="full-scope-card momr-20 leaveissuer-form-container">
          <div className="change-issuer-request__title">
            <span>Request to Change Leave Issuer</span>
          </div>
          <>
            {!isLeaveIssuerRequestEmpty && currentLeaveIssuer && (
              <div className="d-flex gap-px-25 change-issuer-request__top">
                <div className="top-label">Current Leave Issuer</div>
                <Link
                  to={interpolate(EMPLOYEE_PROFILE, { id: currentLeaveIssuer.id })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getFullName(currentLeaveIssuer)}
                </Link>
              </div>
            )}
            <Formik
              initialValues={INITIAL_VALUES}
              validationSchema={changeIssuerSchema}
              onSubmit={values => {
                handleSubmit(values);
              }}
            >
              {props => {
                const handleChange = event => {
                  const { name, value } = event.target;
                  setFormValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                  }));

                  return props.handleChange(event);
                };

                return (
                  <Form>
                    <div className="full-scope-card__content">
                      <div className="d-flex change-issuer-request__wrap">
                        <PendingActionsInfo
                          pendingLeaves={pendingRequests.leave}
                          pendingWorklogs={pendingRequests.worklog}
                          user={getFullName(employee)}
                          isLeaveIssuer={isLeaveIssuer}
                        />
                        <div className="form-wrap__row form-wrap__row--no-margin width--full">
                          <div className="form-wrap__col fixed-height-220 width--full">
                            <div className="change-issuer-request__group">
                              <div className="form-group width--full mbpx-0">
                                <DebounceFormDropdown
                                  isMandatory
                                  name="newLeaveIssuerId"
                                  label="Change leave issuer to"
                                  type={LIST_TYPE.EMPLOYEE}
                                  debounceOptions={employeeDebounceOptions}
                                  value={props.values.newLeaveIssuerId}
                                  error={props.touched.newLeaveIssuerId && props.errors.newLeaveIssuerId}
                                  handleChange={handleChange}
                                  handleBlur={props.handleBlur}
                                  iconComponent={AvatarComponent}
                                  isDisabled={isSubmitting || !isLeaveIssuerRequestEmpty || !canRequestLeaveIssuer}
                                  className="change-issuer-request__dropdown"
                                  classNamePrefix="change-issuer-request"
                                  customSearchText="Type to search and hit enter to select"
                                />

                                {props.values.newLeaveIssuerId && isLeaveIssuerRequestEmpty && !isIssueeCountLoading && (
                                  <Info
                                    message={getLeaveIssueCountMessage({
                                      issuerName: leaveIssuerName,
                                      issueCount: leaveIssueCount,
                                      isLoggedInUser: formValues.newLeaveIssuerId === currentUserId
                                    })}
                                    shouldAlert={leaveIssueCount >= DEFAULT_ISSUE_ALERT_COUNT}
                                  />
                                )}

                                {!isLeaveIssuerRequestEmpty && currentLeaveIssuer && (
                                  <Info
                                    message={getLeaveIssueCountMessage({
                                      issuerName: getFirstName(leaveIssuerRequest.newLeaveIssuer.fullname),
                                      issueCount: leaveIssueCount,
                                      isLoggedInUser:
                                        (formValues.newLeaveIssuerId || INITIAL_VALUES.newLeaveIssuerId) ===
                                        currentUserId
                                    })}
                                    shouldAlert={leaveIssueCount >= DEFAULT_ISSUE_ALERT_COUNT}
                                  />
                                )}
                              </div>

                              <div className="form-group width--full mbpx-0">
                                <InputWrapper
                                  label="Transition date"
                                  error={props.touched.transitionDate && props.errors.transitionDate}
                                  isMandatory
                                >
                                  <DatePicker
                                    date={props.values.transitionDate}
                                    dateFormat={REACT_DATEPICKER_DATE_FORMAT}
                                    displayFormat="LL"
                                    hasError={props.touched.transitionDate && props.errors.transitionDate}
                                    isOutsideRange={date => date.isBefore(latestTransitionDate)}
                                    onDateChange={selectedDate => {
                                      props.setFieldTouched('transitionDate', true);
                                      props.setFieldValue('transitionDate', selectedDate);
                                    }}
                                    openDirection="down"
                                    placeholderText="Pick a Transition Date"
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    customClassName="change-issuer-request__date-picker"
                                    disabled={isSubmitting || !isLeaveIssuerRequestEmpty}
                                  />
                                </InputWrapper>
                              </div>
                            </div>

                            <FormRadioGroup
                              onChange={e => {
                                props.handleChange(e);
                                if (e.target.value !== ROLE.OTHER) {
                                  props.setFieldValue('otherRole', '');
                                }
                              }}
                              onBlur={props.handleBlur}
                              value={props.values.newLeaveIssuerRole}
                              error={props.touched.newLeaveIssuerRole && props.errors.newLeaveIssuerRole}
                              label="New leave issuer is"
                              name="newLeaveIssuerRole"
                              disabled={isSubmitting || !isLeaveIssuerRequestEmpty}
                              radioList={NEW_LEAVE_ISSUER_IS_OPTIONS}
                              isMandatory
                            />
                            <Input
                              name="otherRole"
                              onBlur={props.handleBlur}
                              className="wp-25"
                              value={props.values.otherRole}
                              error={props.touched.otherRole && props.errors.otherRole}
                              onChange={props.handleChange}
                              disabled={
                                props.values.newLeaveIssuerRole !== ROLE.OTHER ||
                                isSubmitting ||
                                !isLeaveIssuerRequestEmpty
                              }
                              multiline={false}
                              type="text"
                            />
                            <div className="form-group">
                              <InputWrapper
                                label="Reason for changing leave issuer"
                                error={props.touched.reason && props.errors.reason}
                                isMandatory
                              >
                                <textarea
                                  name="reason"
                                  placeholder="Type your reason..."
                                  className={classNames('form-elem font-weight-regular change-issuer-request__reason', {
                                    'form-elem--error': props.touched.reason && props.errors.reason
                                  })}
                                  value={props.values.reason}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  disabled={isSubmitting || !isLeaveIssuerRequestEmpty}
                                />
                              </InputWrapper>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex action-bar-footer ptpx-0">
                        <button
                          className={classNames(
                            'mrpx-15 btn font-weight-bold font-size-14 change-issuer-request__button',
                            {
                              'btn--primary':
                                !isSubmitting &&
                                (isLeaveIssuerRequestEmpty || isSelfNewIssuer) &&
                                canRequestLeaveIssuer,
                              'btn--disabled':
                                isSubmitting ||
                                (!isSelfNewIssuer && !isLeaveIssuerRequestEmpty) ||
                                !canRequestLeaveIssuer,
                              'cursor-not-allowed': !canRequestLeaveIssuer
                            }
                          )}
                          type="submit"
                          disabled={
                            isSubmitting || (!isSelfNewIssuer && !isLeaveIssuerRequestEmpty) || !canRequestLeaveIssuer
                          }
                        >
                          {isSubmitting && <Loading />}

                          <span className={classNames({ invisible: isSubmitting })}>
                            {isLeaveIssuerRequestEmpty
                              ? 'Send Request'
                              : isSelfNewIssuer
                              ? 'Confirm'
                              : 'Request Pending'}
                          </span>
                        </button>
                        {isLeaveIssuerRequestEmpty && (
                          <button
                            type="button"
                            className="btn btn--outlined-grey f-left card-button mr-10 change-issuer-request__button"
                            disabled={isSubmitting}
                            onClick={history.goBack}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        </div>
        <LeaveIssuersHistory leaveIssuers={leaveIssuerHistory} isLoading={isLoadingHistory} />
      </div>
    </main>
  );
};

export default ChangeIssuerRequest;
