import React from 'react';

import config from 'config';

const Feedback = props => {
  return (
    <a href={config.peopleFeedbackUrl} target="_blank" rel="noopener noreferrer" className="footer__label">
      Give Feedback
    </a>
  );
};

export default Feedback;
