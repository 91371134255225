import React from 'react';

import { ROSTER_BUTTON_TEXT } from 'constants/profile';

export function EmployeeRosterButton(props) {
  const { setIsRosterModalOpen } = props;

  return (
    <div className="d-inline">
      <div
        role="button"
        onKeyUp={() => {}}
        tabIndex={0}
        onClick={() => {
          setIsRosterModalOpen(true);
        }}
        className="d-inline employee-roster-btn cursor-pointer color-tertiary-blue-40"
      >
        {ROSTER_BUTTON_TEXT}
      </div>
    </div>
  );
}
