import { Formik } from 'formik';
import classNames from 'classnames';
import { UiInfoCircle } from 'vyaguta-icons/ui';
import React, { useState, useMemo } from 'react';

import LFModal from 'components/common/LFModal';
import DropDown from 'components/common/dropDown';
import Loading from 'components/common/loading/Loading';
import InputWrapper from 'components/common/inputWrapper';

import { mixPanelEvents } from 'constants/mixPanel';
import { CHANGE_APPRAISER_NOTE, REQUEST_FOR_CHANGE_OF_APPRAISERS } from 'constants/dashboard';

import { changeAppraiserRequestSchema } from 'schemas/changeAppraiserRequest';

import * as mixpanel from 'services/mixPanel';
import * as appraiserChangeRequestService from 'services/appraiserChangeRequest';

import { success } from 'utils/toast';
import { handleError } from 'utils/errorHandler';

const ChangeAppraiserModal = props => {
  const { isOpen, filteredAllocation, onClose, TriggerBtn, titleText } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const filteredOption = useMemo(
    () =>
      filteredAllocation.map(allocation => {
        return { label: allocation.name, value: allocation.id };
      }),
    [filteredAllocation]
  );

  const initialValues = {
    reason: '',
    allocationId: filteredOption.length === 1 ? filteredOption[0].value : null
  };

  const handleSubmit = async (body, submitProps) => {
    try {
      setIsSubmitting(true);

      const { allocationId, reason } = body;

      const data = await appraiserChangeRequestService.create(allocationId, reason);

      onClose();
      success({ title: 'Success', message: data.message });

      mixpanel.trackEvent(mixPanelEvents.select.appraiserChangeRequest.trigger.requestForChange);
    } catch (err) {
      handleError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <LFModal
      TriggerBtn={TriggerBtn}
      isOpen={isOpen}
      onClose={onClose}
      btnLabel="Change Appraiser"
      closeButton={true}
      title={{ text: titleText || REQUEST_FOR_CHANGE_OF_APPRAISERS, type: 'info' }}
      className="change-appraiser-request"
      htmlContent={
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={changeAppraiserRequestSchema}>
          {({ handleSubmit, errors, values, dirty, setFieldValue, touched, setFieldTouched }) => {
            return (
              <form onSubmit={handleSubmit} className="change-appraiser-request__form">
                <div className="change-appraiser-request__form-body">
                  <InputWrapper label="Allocation" error={touched.allocationId && errors.allocationId} isMandatory>
                    <DropDown
                      placeholder="Select an allocation"
                      hasError={touched.allocationId && errors.allocationId}
                      isDisabled={isSubmitting}
                      name="allocationId"
                      onChange={({ value }) => {
                        setFieldTouched('allocationId', true, true);
                        setFieldValue('allocationId', value);
                      }}
                      options={filteredOption}
                      value={filteredOption.find(({ value }) => value === values.allocationId)}
                    />
                  </InputWrapper>

                  <InputWrapper isMandatory label="Reason" error={touched.reason && errors.reason}>
                    <textarea
                      name="reason"
                      placeholder="Type your reason..."
                      className={classNames(
                        'form-elem font-weight-regular custom-scroll-bar change-appraiser-request__reason',
                        {
                          'form-elem--error': touched.reason && errors.reason
                        }
                      )}
                      value={values.reason}
                      onChange={e => {
                        setFieldTouched('reason', true, true);
                        setFieldValue('reason', e.target.value);
                      }}
                      disabled={isSubmitting}
                    />
                  </InputWrapper>

                  <div className="change-appraiser-request__note">
                    <UiInfoCircle size="14" />
                    <div>{CHANGE_APPRAISER_NOTE}</div>
                  </div>
                </div>

                <div className="change-appraiser-request__footer">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={classNames('btn card-button change-appraiser-request__btn', {
                      'btn--primary': !isSubmitting && dirty,
                      'btn--disabled': isSubmitting || !dirty
                    })}
                    disabled={isSubmitting || !dirty}
                  >
                    <span className={classNames({ invisible: isSubmitting })}>Request For Change </span>
                    {isSubmitting && <Loading />}
                  </button>

                  <button
                    type="button"
                    className={classNames('btn card-button ml-10 change-appraiser-request__btn', {
                      'btn--outlined-grey': !isSubmitting,
                      'btn--disabled': isSubmitting
                    })}
                    onClick={e => {
                      e.preventDefault();
                      onClose();

                      mixpanel.trackEvent(mixPanelEvents.select.appraiserChangeRequest.trigger.cancel);
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      }
    />
  );
};

export default ChangeAppraiserModal;
