import React from 'react';

function Sunrise(props) {
  const { size = 16, className, viewBox = '0 0 16 16' } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      className={className}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0023 7.96929H13.9583C14.2268 7.96929 14.4444 8.19577 14.4444 8.47516C14.4444 8.75455 14.2268 8.98103 13.9583 8.98103H13.0023C12.7338 8.98103 12.5162 8.75455 12.5162 8.47516C12.5162 8.19577 12.7338 7.96929 13.0023 7.96929ZM2.48611 8.98103H3.44212C3.7106 8.98103 3.92823 8.75455 3.92823 8.47516C3.92823 8.19577 3.7106 7.96929 3.44212 7.96929H2.48611C2.21763 7.96929 2 8.19577 2 8.47516C2 8.75455 2.21763 8.98103 2.48611 8.98103ZM8.22222 4.00662C8.4907 4.00662 8.70833 3.78014 8.70833 3.50074V2.50587C8.70833 2.22648 8.4907 2 8.22222 2C7.95374 2 7.73611 2.22648 7.73611 2.50587V3.50074C7.73611 3.78014 7.95374 4.00662 8.22222 4.00662ZM4.49844 5.31543C4.68824 5.51295 4.99607 5.513 5.18592 5.31543C5.37577 5.11786 5.37577 4.79755 5.18592 4.6L4.50994 3.89651C4.32009 3.69895 4.01228 3.69895 3.82245 3.89651C3.63263 4.09408 3.6326 4.4144 3.82245 4.61194L4.49844 5.31543ZM11.946 5.31543L12.622 4.61194C12.8119 4.41437 12.8119 4.09406 12.622 3.89651C12.4322 3.69897 12.1244 3.69895 11.9345 3.89651L11.2585 4.6C11.0687 4.79757 11.0687 5.11789 11.2585 5.31543C11.4483 5.51295 11.7562 5.513 11.946 5.31543ZM14.4444 11.4598C14.4444 11.7392 14.2268 11.9657 13.9583 11.9657H2.48611C2.21763 11.9657 2 11.7392 2 11.4598C2 11.1804 2.21763 10.9539 2.48611 10.9539H5.85734C5.24423 10.3204 4.864 9.44317 4.864 8.47516C4.864 6.54814 6.37048 4.98042 8.22222 4.98042C10.074 4.98042 11.5804 6.54814 11.5804 8.47516C11.5804 9.44317 11.2002 10.3204 10.5871 10.9539H13.9583C14.2268 10.9539 14.4444 11.1804 14.4444 11.4598ZM8.08473 10.9539H8.35974C9.61165 10.8795 10.6083 9.79624 10.6083 8.47516C10.6083 7.10602 9.53791 5.99216 8.22225 5.99216C6.90659 5.99216 5.83622 7.10602 5.83622 8.47516C5.83622 9.79624 6.83282 10.8795 8.08473 10.9539ZM12.0463 13.4327H4.39816C4.12968 13.4327 3.91205 13.6592 3.91205 13.9386C3.91205 14.218 4.12968 14.4444 4.39816 14.4444H12.0463C12.3148 14.4444 12.5324 14.218 12.5324 13.9386C12.5324 13.6592 12.3148 13.4327 12.0463 13.4327Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Sunrise;
