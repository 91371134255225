import classNames from 'classnames';
import React, { useMemo } from 'react';

import { UiBuilding, UiHomeAlt } from 'vyaguta-icons/ui';

import Table from 'components/common/table';

import { DAYS_MAPPING, DDDD, WEEK_MONTH_DAY_FORMAT } from 'constants/date';
import { AVAILABILITY_TIME_MAPPER, WORK_LOCATION_SHORT, workShiftIcon, WORK_SHIFT_LABELS } from 'constants/worklog';

import { getFormattedDate } from 'utils/date';

export function EmployeeRosterTable(props) {
  const { rosterData, isLoading } = props;

  const getTableRowsProps = (state, rowInfo) => {
    const day = getFormattedDate(rowInfo?.original?.date, DDDD);

    const styledClassname = classNames({
      'separator-row': day === DAYS_MAPPING.SUNDAY,
      'first-row': rowInfo?.index === 0
    });

    return {
      className: styledClassname.trim()
    };
  };

  const employeeRosterColumns = useMemo(() => {
    const clockinHistoryColumns = [
      {
        Header: 'Day',
        sortable: false,
        width: 140,
        resizable: false,
        accessor: 'day',
        Cell: props => {
          const { workDate } = props?.original;

          const day = getFormattedDate(workDate, WEEK_MONTH_DAY_FORMAT);

          return <span>{day}</span>;
        }
      },
      {
        Header: 'Shift',
        sortable: false,
        width: 140,
        resizable: false,
        accessor: 'shift',
        Cell: props => {
          const { workShift } = props?.original;

          if (workShift) {
            return (
              <div className="d-flex align-items-center gap-px-4">
                <span>{workShiftIcon[workShift]}</span>
                <span>{WORK_SHIFT_LABELS[workShift]}</span>
              </div>
            );
          }

          return '-';
        }
      },
      {
        Header: 'Availability Time',
        sortable: false,
        width: 200,
        resizable: false,
        accessor: 'availabilityTime',
        Cell: props => {
          const { workShift } = props?.original;

          if (workShift) {
            return <span>{AVAILABILITY_TIME_MAPPER[workShift]}</span>;
          }

          return '-';
        }
      },
      {
        Header: 'Roster',
        sortable: false,
        width: 110,
        resizable: false,
        accessor: 'roster',
        Cell: props => {
          const { location } = props?.original;

          if (location === WORK_LOCATION_SHORT.OFFICE) {
            return (
              <span className="-mlpx-1 hpx-18 wpx-18">
                <UiBuilding size={18} color="#102b7b" />
              </span>
            );
          }

          if (location === WORK_LOCATION_SHORT.HOME) {
            return (
              <span className="-mlpx-2 hpx-18 wpx-18">
                <UiHomeAlt size={18} color="#102b7b" />
              </span>
            );
          }

          return '-';
        }
      }
    ];

    return clockinHistoryColumns;
  }, []);

  return (
    <Table
      columns={employeeRosterColumns}
      showPagination={false}
      sortable={false}
      customClassName="employee-roster-table rt-cursor-default"
      getTrProps={getTableRowsProps}
      data={rosterData}
      getTbodyProps={() => {
        return { className: 'custom-scroll-bar' };
      }}
      loading={isLoading}
    />
  );
}
