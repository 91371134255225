import React from 'react';

const StepperHeader = ({ isCreateForm, employeeName, sizeOfStepper }) => {
  return (
    <div className="stepperheader">
      {isCreateForm ? (
        <p className="stepperheader__title">Add New Leapfrogger</p>
      ) : (
        <p className="stepperheader__title">Update Profile</p>
      )}
      <p className="stepperheader__detail">
        Complete the {sizeOfStepper} steps having the mandatory fields with
        <span className="stepperheader__detail--mandatory"> *</span>
        {isCreateForm ? ' to add a new leapfrogger.' : ' to update profile.'}
      </p>
    </div>
  );
};

export default StepperHeader;
