import config from '../config';
import mixpanel from 'mixpanel-browser';
import { ENV_TYPE } from 'constants/appConstants';
import { captureException } from '@sentry/browser';

const initialize = app => {
  const { mixPanelToken, mixPanelProxyDomain } = config;

  if (!mixPanelToken) {
    captureException('Cannot initialize Mixpanel: No token provided');
    return;
  }

  mixpanel.init(mixPanelToken, { api_host: mixPanelProxyDomain });

  const registerOptions = { Application: app };

  if (config.appEnv !== ENV_TYPE.PRODUCTION) {
    registerOptions.ApplicationEnvironment = config.appEnv;
  }

  mixpanel.register(registerOptions);
};

/**
 * Track Events in the application using Mixpanel
 *
 * @param {String} mixPanelEvent
 * @param {Object} mixPanelProperty
 */
const trackEvent = (mixPanelEvent, mixPanelProperty = {}) => {
  try {
    mixpanel.track(mixPanelEvent, mixPanelProperty);
  } catch (err) {
    captureException(err);
  }
};

export { initialize, trackEvent };
