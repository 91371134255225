import React from 'react';
import classNames from 'classnames';

import { Avatar } from '@vyaguta/vyaguta-ui';

import { getEmpImageUrl } from 'utils/string';

const SkillRatingSelector = props => {
  const {
    skill,
    rating,
    setRating,
    showAvatar,
    skillRatingScale,
    disabledRating,
    loggedUser,
    dispatcherCallback,
    selectedSkills,
    showDescription = true
  } = props;

  const handleRatingClick = selectedRating => {
    if (selectedSkills) {
      const isIdIncluded = selectedSkills.some(item => item.id === skill.id);

      if (isIdIncluded && selectedRating.rating === rating) {
        // If the skill is already selected and the rating is the same, reset the rating and remove the skill from selectedSkills
        setRating(null);
        dispatcherCallback(skill.id, 'remove');
      } else {
        // Set the new rating and update selectedSkills
        setRating(selectedRating.rating);
        dispatcherCallback({ id: skill.id, rating: selectedRating.rating }, 'add');
      }
    } else {
      if (selectedRating.rating === rating) {
        setRating(null);
      } else if (selectedRating.rating !== disabledRating) {
        setRating(selectedRating.rating);
      }
    }
  };

  return (
    <div className="skill-rate-selector">
      {showDescription && skill?.description && <div className="selector-description">{skill.description}</div>}
      <div className="skill-rate-selector__btn-container">
        {skillRatingScale.map(ratingScale => {
          return (
            <div
              className={classNames('rating-btn flex-1', {
                'rating-btn--selected': ratingScale.rating === rating && !showAvatar,
                'rating-btn--non-selected': ratingScale.rating !== rating && ratingScale.rating !== disabledRating,
                'rating-btn--selected-green': ratingScale.rating === rating && showAvatar,
                'rating-btn--disabled': ratingScale.rating === disabledRating
              })}
              onClick={() => handleRatingClick(ratingScale)}
            >
              <div className="rating-btn__top-container">
                <div className="scale">{ratingScale.rating}</div>
                {ratingScale.rating === rating && loggedUser && (
                  <div className="avatar-container">
                    <div className="avatar">
                      <Avatar image={getEmpImageUrl(loggedUser.empId)} size="extraSmall" />
                    </div>
                  </div>
                )}
              </div>
              <div className="description">{ratingScale.description}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SkillRatingSelector;
