import React from 'react';
import classnames from 'classnames';
import { MdAdd } from 'react-icons/md';

import { SKILLS } from 'constants/permissions';
import ScoreTab from 'components/home/CreateUpdateEmployeeForm/components/Scoretab';

const SkillNode = ({
  skills,
  selectedSkills,
  rating,
  activeSkill,
  raterState,
  setRaterState,
  skill,
  onRating,
  onSkillUncheck,
  onClick
}) => {
  const selected = selectedSkills && selectedSkills.find(selected => selected.id === skill.id);
  const isActive = rating ? Boolean(selected) : activeSkill && activeSkill.id === skill.id;

  const getSkillByName = name => skills.find(skill => skill.name === name);

  const handleRating = (skillName, rating) => {
    const effectedSkill = getSkillByName(skillName);
    onRating(effectedSkill, rating && +rating);
    setRaterState(null);
  };

  const isRaterOpen = raterState && raterState === skill.id;

  return (
    <div className="skill-node" key={'skill-node' + skill.id}>
      {isRaterOpen && (
        <div className="skill-node-rating">
          <ScoreTab
            id={skill.id}
            name={skill.name}
            scoreValue={selected && selected.rating}
            handleChange={handleRating}
          />
        </div>
      )}
      <span
        key={skill.id}
        className={classnames('skill', {
          'active-skill': isActive
        })}
        onClick={() => {
          if (rating) {
            if (isRaterOpen) {
              setRaterState(null);
            } else if (isActive) {
              onSkillUncheck(skill);
            } else {
              setRaterState(skill.id);
            }
          } else {
            onClick(skill);
          }
        }}
      >
        {skill.name}
      </span>
    </div>
  );
};

const SkillPanel = props => {
  const { activeSkill, skills, handleAddClick, category, rating = true, permissions } = props;

  return (
    <div className={`skill-panel`}>
      {skills.map(skill => (
        <SkillNode key={skill.id} skill={skill} {...props} />
      ))}
      {/* Commented for skill version 1 */}
      {/* {permissions[SKILLS.CREATE] && !rating && (
        <div className="d-inline-block skill-node">
          <button
            type="button"
            onClick={handleAddClick}
            className={classnames('icon-round m-auto skill-add', {
              'active-add':
                activeSkill &&
                !activeSkill.id &&
                activeSkill.skillCategory &&
                activeSkill.skillCategory.name === category
            })}
          >
            <MdAdd size="25" />
          </button>
        </div>
      )} */}
    </div>
  );
};

export default SkillPanel;
