import React from 'react';

const EmployeeBasicDetail = props => {
  const { fieldMap, className } = props;

  const employeeBasicDetail =
    fieldMap &&
    fieldMap.map(
      ({ LogoComponent, DataComponent, ExtraComponent }, index) =>
        DataComponent && (
          <div className="employee-contact-item d-flex align-items-center flex-fix mr-24 color-grey-70" key={index}>
            <LogoComponent size="16px" />
            &nbsp;
            <span className="pl-5">
              <DataComponent />
            </span>
            {ExtraComponent && <ExtraComponent />}
          </div>
        )
    );

  return (
    employeeBasicDetail && (
      <div className={`employee-contact flex-fix d-flex fd-row font-12 ${className}`}>{employeeBasicDetail}</div>
    )
  );
};

export default EmployeeBasicDetail;
