import { FiHome } from 'vyaguta-icons/fi';
import { FiAlertTriangle } from 'vyaguta-icons/fi';
import { FaRegCalendarTimes } from 'react-icons/fa';
import { UiBuilding, UiFileEditAlt } from 'vyaguta-icons/ui';
import {
  QUICK_ACTION_FREEZE_MESSAGE,
  QUICK_ACTION_APPLIED_LEAVE_MESSAGE,
  QUICK_ACTION_APPLY_LEAVE_MESSAGE,
  QUICK_ACTION_BUTTON_DISABLED_MESSAGE,
  QUICK_ACTION_INACTIVE_APPLY_LEAVE_MESSAGE,
  QUICK_ACTION_UPDATED_WORKLOG_MESSAGE,
  QUICK_ACTION_UPDATE_WORKLOG_MESSAGE,
  QUICK_WFH_ACTION_ACTIVE_MESSAGE,
  QUICK_WFO_ACTION_ACTIVE_MESSAGE,
  UPDATE_QUICK_WFH_ACTION_MESSAGE,
  UPDATE_QUICK_WFO_ACTION_MESSAGE
} from './messages';

export const ATTENDANCE_TYPE = {
  WFO: 'wfo',
  WFH: 'wfh',
  LEAVE: 'leave',
  FREEZE: 'freeze'
};

export const BTN_STATES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  GRAYED: 'grayed',
  DISABLED: 'disabled',
  WARNING: 'warning'
};

export const LEAVE_BUTTON_LABEL = {
  [BTN_STATES.PRIMARY]: QUICK_ACTION_APPLY_LEAVE_MESSAGE,
  [BTN_STATES.SECONDARY]: QUICK_ACTION_APPLIED_LEAVE_MESSAGE,
  [BTN_STATES.GRAYED]: QUICK_ACTION_INACTIVE_APPLY_LEAVE_MESSAGE
};

export const WFO_BUTTON_LABEL = {
  [BTN_STATES.PRIMARY]: UPDATE_QUICK_WFO_ACTION_MESSAGE,
  [BTN_STATES.SECONDARY]: QUICK_WFO_ACTION_ACTIVE_MESSAGE,
  [BTN_STATES.DISABLED]: QUICK_ACTION_BUTTON_DISABLED_MESSAGE
};

export const WFH_BUTTON_LABEL = {
  [BTN_STATES.PRIMARY]: UPDATE_QUICK_WFH_ACTION_MESSAGE,
  [BTN_STATES.SECONDARY]: QUICK_WFH_ACTION_ACTIVE_MESSAGE,
  [BTN_STATES.DISABLED]: QUICK_ACTION_BUTTON_DISABLED_MESSAGE
};

export const WORKLOG_BUTTON_LABEL = {
  [BTN_STATES.PRIMARY]: QUICK_ACTION_UPDATE_WORKLOG_MESSAGE,
  [BTN_STATES.DISABLED]: QUICK_ACTION_UPDATED_WORKLOG_MESSAGE
};

export const FREEZE_BUTTON_LABEL = {
  [BTN_STATES.PRIMARY]: QUICK_ACTION_FREEZE_MESSAGE
};

export const WFO_BUTTON = {
  icon: UiBuilding,
  label: WFO_BUTTON_LABEL[BTN_STATES.PRIMARY],
  id: ATTENDANCE_TYPE.WFO
};

export const WFH_BUTTON = {
  icon: FiHome,
  label: WFH_BUTTON_LABEL[BTN_STATES.PRIMARY],
  id: ATTENDANCE_TYPE.WFH
};

export const LEAVE_BUTTON = {
  icon: FaRegCalendarTimes,
  label: LEAVE_BUTTON_LABEL[BTN_STATES.PRIMARY],
  id: ATTENDANCE_TYPE.LEAVE
};

export const MISSING_ATTENDANCE_BUTTON = {
  icon: FiAlertTriangle,
  label: FREEZE_BUTTON_LABEL[BTN_STATES.PRIMARY],
  id: ATTENDANCE_TYPE.FREEZE,
  type: BTN_STATES.WARNING
};

export const WORKLOG_BUTTON = {
  icon: UiFileEditAlt,
  label: WORKLOG_BUTTON_LABEL[BTN_STATES.PRIMARY],
  type: 'primary',
  id: 'worklog'
};

export const BUTTON_LABEL = {
  [ATTENDANCE_TYPE.WFH]: WFH_BUTTON_LABEL,
  [ATTENDANCE_TYPE.WFO]: WFO_BUTTON_LABEL,
  [ATTENDANCE_TYPE.LEAVE]: LEAVE_BUTTON_LABEL,
  worklog: WORKLOG_BUTTON_LABEL
};

export const MAX_LENGTH = 4;
export default [WFH_BUTTON, WFO_BUTTON, LEAVE_BUTTON];
