import config from 'config';
import classNames from 'classnames';
import React, { useState, useEffect, useCallback } from 'react';

import { Avatar } from '@vyaguta/vyaguta-ui';

import * as userService from 'services/user';

import { pulseLogo } from 'assets/images';
import { isObjectEmpty } from 'utils/object';
import { handleError } from 'utils/errorHandler';
import { EMPLOYEE_PROFILE } from 'constants/routes';
import { MINIMUM_PAGE_COUNT } from 'constants/page';
import { parse, stringify } from 'utils/queryParam';
import { buildUrl, extractFullName, getEmpImageUrl, interpolate } from 'utils/string';

import useTitle from 'hooks/useTitle';
import Table from 'components/common/table';
import EmployeesFilter from './EmployeesFilter';
import Pagination from 'components/common/paginator';
import Loading from 'components/common/loading/Loading';
import { USER_SELECTED_FIELDS } from 'constants/fetchTypes';
import TableHeader from 'components/common/table/TableHeader';
import {
  EMPLOYEE_SORT_BY,
  EMPLOYEE_SORT_ORDER,
  USER_FETCH_FIELDS,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER
} from 'constants/appConstants';

const getColumns = (
  setIsFilterChanged,
  isAscendingOrdered,
  setIsAscendingOrdered,
  columnToSortBy,
  setColumnToSortBy
) => [
  {
    Header: 'SN.',
    sortable: false,
    filterable: false,
    accessor: 'sn',
    maxWidth: 80,
    Cell: props => {
      return (
        <span className="capital-text employees__table-text">{props.value < 10 ? `0${props.value}` : props.value}</span>
      );
    }
  },
  {
    Header: (
      <TableHeader
        label="Name"
        identifier={EMPLOYEE_SORT_BY.FIRST_NAME}
        columnToSortBy={columnToSortBy}
        isAscendingOrdered={isAscendingOrdered}
        onClick={() => {
          setColumnToSortBy(EMPLOYEE_SORT_BY.FIRST_NAME);
          setIsAscendingOrdered(!isAscendingOrdered);
        }}
      />
    ),
    accessor: 'firstName',
    sortable: false,
    filterable: false,
    sortMethod: (a, b) => 1,
    minWidth: 180,
    Cell: props => {
      const fullName = extractFullName(props.original, true);
      return (
        <div className="d-flex flex-row align-items-center" data-cy="employee-image-name">
          <Avatar
            className="nametag nametag--lg profile-photo leapfroggers-profile"
            image={getEmpImageUrl(props.original.empId)}
            alt={fullName}
          />
          <span className="text-bold capital-text employees__table-text">{fullName}</span>

          <a
            onClick={event => {
              event.stopPropagation();
            }}
            href={interpolate(buildUrl(config.pulseBaseUrl, config.endpoints.pulse.userFeed), {
              id: props.original?.id
            })}
            className="employees__pulse-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={pulseLogo} alt="pulse" />
          </a>
        </div>
      );
    }
  },
  {
    Header: 'Cell Number',
    sortable: false,
    filterable: false,
    accessor: 'mobilePhone',
    Cell: props => (
      <a
        href={`tel:${props.value}`}
        onClick={event => {
          event.stopPropagation();
        }}
        data-cy="employee-phone"
      >
        {props.value}
      </a>
    )
  },
  {
    Header: (
      <TableHeader
        label="Designation"
        identifier={EMPLOYEE_SORT_BY.DESIGNATION}
        columnToSortBy={columnToSortBy}
        isAscendingOrdered={isAscendingOrdered}
        onClick={() => {
          setColumnToSortBy(EMPLOYEE_SORT_BY.DESIGNATION);
          setIsAscendingOrdered(!isAscendingOrdered);
        }}
      />
    ),
    accessor: 'designation',
    sortable: false,
    filterable: false,
    minWidth: 150,
    Cell: props => (
      <span className="capital-text employees__table-text" data-cy="employee-area-designation">
        {props && props.value && props.value.id
          ? `${props.value.name}${props.value.area ? `, ${props.value.area.name}` : ''}`
          : '-'}
      </span>
    )
  }
];

const Employees = props => {
  const { history, updateFilterState } = props;

  const queryParams = parse(props.location.search, {
    decoder: function(str) {
      return decodeURIComponent(str);
    }
  });
  const [isFilterApplied, setIsFilterApplied] = useState(!isObjectEmpty(queryParams));
  const [pageData, setPageData] = useState({});
  const [pageCount, setPageCount] = useState(DEFAULT_PAGE_NUMBER);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [employeeList, setEmployeesList] = useState([]);
  const [columnToSortBy, setColumnToSortBy] = useState(EMPLOYEE_SORT_BY.FIRST_NAME);
  const [isAscendingOrdered, setIsAscendingOrdered] = useState(queryParams?.order !== EMPLOYEE_SORT_ORDER.DESCENDING);

  const setTitle = useTitle();

  useEffect(() => {
    setTitle('Leapfroggers');
  }, []);

  useEffect(() => {
    (async () => {
      await fetchEmployees(columnToSortBy);
    })();
  }, [props.location.search, isFilterChanged]);

  useEffect(() => {
    if (!employeeList.length) {
      return;
    }

    (async () => {
      updateUrl({
        ...queryParams,
        page: DEFAULT_PAGE_NUMBER,
        order: isAscendingOrdered ? EMPLOYEE_SORT_ORDER.ASCENDING : EMPLOYEE_SORT_ORDER.DESCENDING
      });
    })();
  }, [isAscendingOrdered, columnToSortBy]);

  const fetchEmployees = async (columnToSortBy = EMPLOYEE_SORT_BY.FIRST_NAME) => {
    try {
      setIsDataLoading(true);

      const params = {
        ...queryParams,
        sortBy: columnToSortBy,
        fields: USER_SELECTED_FIELDS.join(','),
        order: isAscendingOrdered ? EMPLOYEE_SORT_ORDER.ASCENDING : EMPLOYEE_SORT_ORDER.DESCENDING,
        workingShiftId: queryParams.workingShiftId?.toUpperCase().replace(/ /g, '_')
      };

      if (queryParams.roleIds) {
        params['fields'] = [USER_FETCH_FIELDS.ROLES, ...USER_SELECTED_FIELDS].join(',');
      }

      const response = await userService.fetchAll(params);
      const pageIndex = queryParams.page ? queryParams.page : DEFAULT_PAGE_NUMBER;
      const size = queryParams.size ? queryParams.size : DEFAULT_PAGE_SIZE;
      const { data } = response;
      const { total } = response.meta;
      const pgCount = Math.ceil(total / size);
      setPageData(response.meta);

      const dataWithSN = data.map((data, index) => {
        const sn = (pageIndex - 1) * size + index + 1;

        return {
          ...data,
          sn
        };
      });

      setEmployeesList(dataWithSN);
      setPageCount(pgCount);
    } catch (err) {
      handleError(err);
    } finally {
      setIsDataLoading(false);
    }
  };

  const updateUrl = useCallback(
    value => {
      const queryParam = stringify('', value, { arrayFormat: 'comma', encode: false });
      history.push({
        pathname: props.location.pathname,
        search: queryParam
      });
    },
    [props.location.pathname]
  );

  const applyFilter = filter => {
    updateUrl(filter);
  };

  const onPageChange = useCallback(
    selectedPage => {
      updateUrl({
        ...parse(props.location.search, {
          decoder: function(str) {
            return decodeURIComponent(str);
          }
        }),
        page: selectedPage
      });
    },

    [props.location.search, updateUrl]
  );

  const onPageSizeChange = useCallback(
    selectedSize => {
      setIsFilterApplied(true);
      updateUrl({
        ...parse(props.location.search, {
          decoder: function(str) {
            return decodeURIComponent(str);
          }
        }),
        page: DEFAULT_PAGE_NUMBER,
        size: selectedSize
      });
    },
    [props.location.search, updateUrl]
  );

  return (
    <main>
      <div className="container leapfroggers-table">
        <div className="full-scope-card">
          <EmployeesFilter
            {...props}
            data={pageData}
            applyFilter={applyFilter}
            updateFilterState={updateFilterState}
            isFilterApplied={isFilterApplied}
            setIsFilterApplied={setIsFilterApplied}
            isDataLoading={isDataLoading}
          />
          <div className={classNames('full-scope-card__content')}>
            <>
              <Table
                loading={isDataLoading}
                data={employeeList}
                columns={getColumns(
                  setIsFilterChanged,
                  isAscendingOrdered,
                  setIsAscendingOrdered,
                  columnToSortBy,
                  setColumnToSortBy
                )}
                showPagination={false}
                getTrProps={(state, rowInfo) => {
                  return {
                    className: 'align-items-center d-flex',
                    'data-cy': 'employee-row',
                    onClick: e => {
                      const url = interpolate(EMPLOYEE_PROFILE, { id: rowInfo.original.id });
                      if (e.ctrlKey || e.metaKey) {
                        window.open(url);
                      } else {
                        history.push(url);
                      }
                    }
                  };
                }}
              />
              <Pagination
                pageData={pageData}
                pageCount={+(pageCount || MINIMUM_PAGE_COUNT)}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
              />
            </>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Employees;
