import React from 'react';
import classNames from 'classnames';

const Loading = props => {
  return (
    <div className={classNames('spinner black', { [props.className]: props.className })}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export default Loading;
