import React from 'react';

import { UiTrash } from 'vyaguta-icons/ui';

const DeletePopup = ({ onClick }) => {
  return (
    <li
      className="overflow-menu__list-item overflow-menu__list-item--hoverable color-tertiary-red-40 d-flex"
      onClick={onClick}
    >
      <span className="icon overflow-menu__icon icon--delete">
        <UiTrash className="tertiary-red.40" size={16} />
      </span>
      Delete
    </li>
  );
};

export default DeletePopup;
