import React from 'react';
import classNames from 'classnames';

const FilterPill = props => {
  const { notification, isSmall, label, color, isActive, handleClick, customClassName } = props;

  return (
    <div
      className={classNames('filter-pill', {
        [`filter-pill--small`]: isSmall,
        [`filter-pill--active`]: isActive,
        [`filter-pill--color-${color}`]: color,
        [`${customClassName}`]: customClassName
      })}
      onClick={handleClick || null}
    >
      <span className="filter-pill__label">{label}</span>
      {notification && (
        <span className={classNames('filter-pill__bulb', { [`filter-pill__bulb--${color}`]: color })}>
          {notification}
        </span>
      )}
    </div>
  );
};

export default FilterPill;
