import React from 'react';
import { Link } from 'react-router-dom';

class DropDownMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      showMenu: false
    };
  }

  showMenu = event => {
    event.preventDefault();

    this.setState(prev => {
      return {
        ...prev,
        showMenu: !prev.showMenu
      };
    });
  };

  render() {
    const { Icon, classNames } = this.props;

    return (
      <div className="dropdown">
        <button onClick={this.showMenu} className="edit-profile edit-profile-icon order-1 momt-10">
          <Icon size="16" stroke="2" className="mr-8 pl-0" />
          {this.props.buttonName}
          <svg
            className="ml-8"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.52666 7L12.1756 13L17.8245 7L19.7075 9L12.1756 17L4.64368 9L6.52666 7Z"
              fill="currentcolor"
            />
          </svg>
        </button>

        <div
          className={`dropdown-content ${classNames ? classNames : ''} ${
            this.state.showMenu ? 'dropdown-content-active' : ''
          }`}
        >
          {this.props.links.map(link => (
            <Link to={link.to} className={link.className} key={link.to}>
              {link.label}
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default DropDownMenu;
