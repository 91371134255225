import React from 'react';
import classNames from 'classnames';

const SmallInput = ({ placeholder, type, value, error, onChange, onFocus, label, customClassName, ...rest }) => {
  return (
    <div className={classNames('input-wrapper__small-input', { [customClassName]: customClassName })}>
      <input
        className={`input ${error ? 'input--error' : ''}`}
        value={value}
        type={type}
        onChange={e => onChange(e)}
        onFocus={onFocus}
        placeholder={placeholder}
        {...rest}
      />
      <p className="name">{label}</p>
    </div>
  );
};

export default SmallInput;
