import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FiAlertCircle } from 'react-icons/fi';

import skillSchema from 'schemas/skillSchema';
import { FormGroup, FormSelect } from '../../CreateUpdateEmployeeForm/components';

import { SKILLS } from 'constants/permissions';
import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import DeleteButton from 'components/common/button/DeleteButton';

class EditSkill extends React.Component {
  constructor(props) {
    super(props);
    const { skill } = props;
    this.state = {
      selectedSkill: { ...skill }
    };
  }

  componentWillReceiveProps(props) {
    const { skill } = props;
    this.setState({
      selectedSkill: { ...skill }
    });
  }

  handleSaveClick = skill => this.props.handleSave(skill, this.state.selectedSkill.id);

  handleCancelClick = () => this.props.handleCancel();

  handleDeleteClick = skill => {
    this.props.handleDelete(skill);
  };

  render() {
    const { saving, deleting, skillCategories, permissions } = this.props;
    const { selectedSkill } = this.state;
    const skillCategorySelectOptions = skillCategories.map(category => {
      return { value: category, label: category.name };
    });

    return (
      <Formik
        enableReinitialize
        initialValues={selectedSkill}
        validationSchema={skillSchema}
        onSubmit={this.handleSaveClick}
        render={({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
          <form className="edit-skill card-form" onSubmit={handleSubmit}>
            <h3 className="edit-skill_header">Skill Details</h3>
            <FormGroup
              name="name"
              label="Skill Name"
              isMandatory={true}
              value={values.name}
              error={touched.name && errors.name}
              handleBlur={handleBlur}
              readOnly={!permissions[SKILLS.CREATE]}
              handleChange={handleChange}
              placeholder="Skill Name"
            />
            <FormGroup
              name="description"
              label="Skill Description"
              multiline={true}
              isMandatory={false}
              value={values.description}
              readOnly={!permissions[SKILLS.CREATE]}
              error={touched.description && errors.description}
              handleBlur={handleBlur}
              handleChange={handleChange}
              placeholder="Skill Description"
            />
            {permissions[SKILLS.CREATE] && (
              <FormSelect
                label="Skill Category"
                name="skillCategory"
                isMandatory={true}
                handleChange={handleChange}
                value={values.skillCategory}
                error={touched.skillCategory && errors.skillCategory && 'Please enter the skill category'}
                options={skillCategorySelectOptions}
                uniqueIdentifier="id"
              />
            )}

            <div className="mt-30 button-area">
              {(permissions[SKILLS.CREATE] || permissions[SKILLS.UPDATE]) && (
                <button
                  type="submit"
                  disabled={saving}
                  className="btn btn--primary f-left card-button mr-10 min-width-80"
                >
                  {saving ? <Loading /> : selectedSkill.id ? 'Update' : 'Create'}
                </button>
              )}
              <button
                type="button"
                onClick={this.handleCancelClick}
                className="btn btn--outlined-grey f-left card-button"
              >
                Cancel
              </button>
              {permissions[SKILLS.DELETE] && selectedSkill.id && (
                <LFModal
                  TriggerBtn={DeleteButton}
                  isLoading={deleting}
                  modalStatusIcon={<FiAlertCircle color="#F44336" size={24} />}
                  title={{ text: 'Do you want to delete this skill?', type: 'danger' }}
                  className="delete-modal"
                  message={{ text: 'This action cannot be reverted back once deleted.', type: 'delete__text' }}
                  renderFooter={true}
                  buttons={[
                    {
                      text: 'Yes, delete',
                      type: 'red',
                      className: 'mr-12 delete__btn',
                      onClick: () => this.handleDeleteClick(selectedSkill),
                      close: true,
                      showLoading: true
                    },
                    {
                      text: 'No, go back',
                      type: 'outlined-grey',
                      className: 'py-8 px-12',
                      close: true
                    }
                  ]}
                />
              )}
            </div>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({ permissions: state.information.value.permissions });

export default connect(mapStateToProps)(EditSkill);
