import { WORK_LOCATION } from './worklog';

export const REJECTED = 'REJECTED';
export const APPROVED = 'APPROVED';
export const PENDING = 'PENDING';
export const SUBMITTED = 'SUBMITTED';
export const CANCELLED = 'CANCELLED';
export const REQUESTED = 'REQUESTED';

export const WORKLOG_STATUS = {
  PENDING: PENDING,
  REJECTED: REJECTED,
  APPROVED: APPROVED,
  SUBMITTED: SUBMITTED,
  CANCELLED: CANCELLED
};

export const ATTENDANCE_LOCATION_LABEL = {
  [WORK_LOCATION.OFFICE]: { present: 'Working from office', past: 'Worked from office' },
  [WORK_LOCATION.HOME]: { present: 'Working from home', past: 'Worked from home' }
};
