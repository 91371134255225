import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { getFormattedDate } from 'utils/date';
import DatePicker from 'components/common/datepicker/DatePicker';

import InputWrapper from '../InputWrapper';

const FormDateSelect = props => {
  const { label, error, isMandatory, onBlur, value, onChange, name, customClassName, ...rest } = props;

  const handleOnChange = selectedDate => {
    return onChange({
      target: {
        name,
        value: getFormattedDate(selectedDate)
      }
    });
  };

  const handleDateClose = () => {
    if (onBlur) {
      return onBlur({
        target: {
          name
        }
      });
    }
  };

  return (
    <InputWrapper
      label={label}
      error={error}
      isMandatory={isMandatory}
      className={classNames({
        [customClassName]: customClassName
      })}
    >
      <DatePicker
        isOutsideRange={() => false}
        hasError={Boolean(error)}
        onClose={handleDateClose}
        onDateChange={handleOnChange}
        date={moment(value)._isValid ? moment(value) : null}
        {...rest}
      />
    </InputWrapper>
  );
};

export default FormDateSelect;
