import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { UiHomeAlt, UiBuilding, UiExclamationTriangle } from 'vyaguta-icons/ui';

import CalendarCross from 'assets/icon/CalendarCross';

import { COLORS } from 'constants/color';
import { WORK_LOCATION } from 'constants/worklog';
import { attendanceActionMapper } from 'constants/workStatus';

import { fetchUserWorkStatus } from 'services/user';

import { getFormattedDate } from 'utils/date';
import { handleError } from 'utils/errorHandler';

import AttendanceWifiLog from 'components/common/attendance/AttendanceWifiLog';

const attendanceIconMapper = {
  WIFI_CONNECTED: 'bg-tertiary-green-45',
  WIFI_NOT_CONNECTED: 'bg-tertiary-red-10',
  PENDING: 'bg-tertiary-orange-10',
  LEAVE: 'bg-tertiary-purple-10'
};
/**
 * get attendance Icon and label
 * 1. on leave
 * 2. wfh+wifi log
 * 3. wfh+no wifi log
 * 4. wfo+wifi log
 * 5. wfo+no wifi log
 * 6. pending + wifi-log
 * 7. pending no wifi log
 * 8. no wifi log
 */
const getIconAndClassName = workStatus => {
  if (workStatus.leave && !workStatus.holiday) {
    return {
      Icon: CalendarCross,
      className: attendanceIconMapper.LEAVE,
      label: attendanceActionMapper.LEAVE,
      color: COLORS.purple,
      hasLogo: false
    };
  }

  if (workStatus.worklog) {
    const { location } = workStatus.worklog;

    if (location === WORK_LOCATION.HOME) {
      if (workStatus.hasCheckedIn) {
        return {
          Icon: UiHomeAlt,
          className: attendanceIconMapper.WIFI_CONNECTED,
          label: attendanceActionMapper.WFH_WIFI,
          hasLogo: true
        };
      }

      return {
        Icon: UiHomeAlt,
        className: attendanceIconMapper.WIFI_CONNECTED,
        label: attendanceActionMapper.WFH,
        hasLogo: false
      };
    }

    if (workStatus.hasCheckedIn) {
      return {
        Icon: UiBuilding,
        className: attendanceIconMapper.WIFI_CONNECTED,
        label: attendanceActionMapper.WFO_WIFI,
        hasLogo: true
      };
    }

    return {
      Icon: UiBuilding,
      className: attendanceIconMapper.WIFI_NOT_CONNECTED,
      label: attendanceActionMapper.WFO_NOWIFI,
      hasLogo: true,
      logoColor: COLORS.grey20
    };
  }

  if (workStatus.hasCheckedIn && !workStatus.holiday) {
    return {
      Icon: UiExclamationTriangle,
      className: attendanceIconMapper.PENDING,
      label: attendanceActionMapper.Pending_WIFI,
      color: COLORS.orange80,
      hasLogo: true
    };
  }

  if (!workStatus.holiday) {
    return {
      Icon: UiExclamationTriangle,
      className: attendanceIconMapper.PENDING,
      label: attendanceActionMapper.PENDING_NOWIFI,
      color: COLORS.orange80,
      hasLogo: false
    };
  }

  return { Icon: null };
};

const WorkStatus = ({ id, userId, links }) => {
  const [workStatus, setWorkStatus] = useState(null);
  const [previousId, setPreviousId] = useState(null);

  const { missing: selfMissing, pending: selfPending } = useSelector(state => state.selfAttendance);

  const getWorkStatusInfo = async () => {
    try {
      const params = {
        date: getFormattedDate(),
        userIds: id,
        withWifiLog: true
      };

      const data = await fetchUserWorkStatus(params);

      setWorkStatus(data[0]);
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    const isFirstRender = !previousId;
    const isIdChanged = !!previousId && previousId !== id;
    const isAttendanceUpdated = !!(userId === id && (selfMissing || selfPending));

    if (isFirstRender || isIdChanged || isAttendanceUpdated) {
      setPreviousId(id);

      getWorkStatusInfo();
    }
  }, [id, previousId, selfMissing, selfPending, userId]);

  if (!workStatus) {
    return;
  }

  const { Icon, label, color, className, hasLogo, logoColor } = getIconAndClassName(workStatus);

  if (!Icon) {
    return;
  }

  return (
    <div
      className={classNames('status-height work-title d-flex align-items-center justify-content-end gap-px-8', {
        'status-margin ': !links.length
      })}
    >
      <AttendanceWifiLog
        Icon={Icon}
        color={color || COLORS.white}
        className={className}
        hasLogo={hasLogo}
        logoColor={logoColor}
      />
      <h3 className="font-12"> {label}</h3>
    </div>
  );
};

export default WorkStatus;
