export const CANDIDATE_PIPELINES = {
  STAFF_MANAGER_EVALUATION: 'Staff Manager Evaluation',
  PROJECT_EVALUATION: 'Project Evaluation',
  CLIENT_EVALUATION: 'Client Evaluation',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
};

export const CANDIDATE_BOOKING_STATUS = {
  ONBOARDED: 'Onboarded',
  CLIENT_REJECTED: 'Client Rejected',
  PROJECT_REJECTED: 'Project Rejected',
  REMOVED_FROM_BOOKIING: 'Removed from Booking',
  REJECTED_BY_STAFF_MANAGER: 'Rejected by Staff Manager'
};

export const PERSISTING_CANDIDATE_BOOKING_STATUSES = [
  CANDIDATE_BOOKING_STATUS.ONBOARDED,
  CANDIDATE_BOOKING_STATUS.CLIENT_REJECTED,
  CANDIDATE_BOOKING_STATUS.PROJECT_REJECTED,
  CANDIDATE_BOOKING_STATUS.REMOVED_FROM_BOOKIING,
  CANDIDATE_BOOKING_STATUS.REJECTED_BY_STAFF_MANAGER
];

export const CANDIDATE_PIPELINE_MAPPED_COLORS = {
  'Staff Manager Evaluation': 'tertiary-blue-40',
  'Project Evaluation': 'tertiary-yellow-60',
  'Client Evaluation': 'tertiary-orange-60',
  Accepted: 'primary-base',
  Rejected: 'tertiary-red-60'
};

export const ALLOCATION_TYPE_CURRENT = 'current';

export const ALLOCATION_TYPE_BOOKING = 'booking';

export const WEEK_LIMIT_COUNT = 2;

export const RADIX = 10;
