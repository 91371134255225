import React from 'react';

import AllocationAndBooking from './AllocationAndBooking';
import AllocationCardFooter from './AllocationCardFooter';

export default function AllocationCard({ userId, designation }) {
  return (
    <div className="full-scope-card dashboard__card d-flex flex-direction-column">
      <AllocationAndBooking userId={userId} designation={designation} />

      <AllocationCardFooter />
    </div>
  );
}
