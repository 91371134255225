import { LINK_LIBRARY_TYPES } from 'constants/linkLibrary';

/**
 * Define a recursive function to merge nodes
 *
 * @param {Object[]} existingNode
 * @param {Object[]} newNode
 * @param {number} updatedNodeId
 * @returns {Object[]}
 */

export const mergeNode = (existingNode, newNode, updatedNodeId) => {
  // If the new node has no children, merge it with the existing node
  if (!newNode.children || newNode.children.length === 0) {
    return {
      ...newNode,
      expanded: existingNode ? existingNode.expanded : false,
      isNewNode: !existingNode
    };
  }

  // Merge child nodes recursively
  const mergedChildren = newNode.children.map(newChild => {
    const existingChild = existingNode ? existingNode.children.find(node => node.id === newChild.id) : null;

    if (newChild?.type === 'links') {
      // Function to update links and mark them as new if necessary
      const updateLinks = (oldLinks, newLinks) => {
        const arr = newLinks.map(newObj => ({
          ...newObj,
          isNewNode: !oldLinks?.some(oldObj => oldObj.id === newObj.id)
        }));

        return arr;
      };

      const newLinks = updateLinks(existingChild?.links, newChild?.links);

      return {
        ...newChild,
        links: newLinks
      };
    }

    // Recursively merge child nodes
    const childNode = mergeNode(existingChild, newChild);

    return childNode;
  });

  // Return the merged node with updated properties
  return {
    ...newNode,
    expanded: existingNode && existingNode.id === updatedNodeId ? true : existingNode ? existingNode.expanded : false,
    isNewNode: !existingNode,
    children: mergedChildren
  };
};

export const getLinkIds = data => {
  let linkIds = [];

  data.forEach(item => {
    if (item.type === 'link') {
      linkIds.push(item.id);
    }

    if (item.children && Array.isArray(item.children)) {
      linkIds = linkIds.concat(getLinkIds(item.children));
    }
  });

  return linkIds;
};

/**
 * Returns total links when hierarchy data is provided.
 *
 * @param {Object[]} hierarchyData
 * @returns {number}
 */

export const countLinks = data => {
  const linkIds = getLinkIds(data);
  const filteredIds = linkIds.filter(value => typeof value === 'number');
  const linkCount = filteredIds.length;

  return linkCount;
};

/**
 * Return formatted request body payload for updating the link library weights.
 *
 * @param {Array} data
 * @returns {Object}
 */
export const formattedUpdateWeightBody = data => {
  const updatedData = data
    .filter(item => item.id !== undefined && item.id !== null)
    .map((item, index) => {
      return { id: item.id, weight: index + 1 };
    });
  const bodyPayload = {
    type: data?.[0]?.type,
    data: updatedData
  };

  return bodyPayload;
};

/**
 * Returns the node level of an item using its id and type.
 *
 * @param {Array} treeData
 * @param {Object} param1
 * @param {Number} currentLevel
 * @returns {Number}
 */
export const findNodeLevel = (treeData, { id, type }, currentLevel = 0) => {
  const checkCondition = node => {
    const nodeIdentifier =
      type === LINK_LIBRARY_TYPES.SUBCATEGORY ? `${node.id}-${node.type}` : `${node.categoryId}-${node.type}`;

    return nodeIdentifier === `${id}-${type}`;
  };

  for (const node of treeData) {
    if (checkCondition(node)) {
      return currentLevel;
    }

    if (node.children && node.children.length > 0) {
      const childLevel = findNodeLevel(node.children, { id, type }, currentLevel + 1);
      if (childLevel !== -1) {
        return childLevel;
      }
    }
  }

  return -1;
};
