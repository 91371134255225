import React from 'react';

import Radio from './Radio';
import InputWrapper from '../InputWrapper';

const FormRadioGroup = ({ label, error, name, radioList, isMandatory, disabled, onChange, onBlur, value }) => {
  const handleChange = e => {
    return onChange({
      target: {
        name,
        value: e.target.value
      }
    });
  };

  const handleBlur = () => {
    return onBlur({
      target: {
        name
      }
    });
  };

  return (
    <InputWrapper label={label} error={error} isMandatory={isMandatory}>
      <div className="input-wrapper__radio-group">
        {radioList.map(item => (
          <Radio
            disabled={disabled}
            label={item.label}
            key={item.value}
            name={name}
            value={item.value}
            checked={value === item.value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        ))}
      </div>
    </InputWrapper>
  );
};

export default FormRadioGroup;
