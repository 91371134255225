import React from 'react';
import classNames from 'classnames';
import { FiCalendar } from 'react-icons/fi';

import { UiExternalLinkAlt } from 'vyaguta-icons/ui';

import { DATE_DISPLAY_FORMAT } from 'constants/appConstants';

import { getFormattedDate } from 'utils/date';

const NoticeBoardCard = ({ notice }) => {
  const { title, description, startDate, endDate, redirectUrl } = notice;

  const currentDate = getFormattedDate();

  const isActiveNotice = currentDate >= startDate && currentDate <= endDate;

  return (
    <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
      <div
        className={classNames('notice-board-card', {
          'notice-board-card__active': isActiveNotice,
          'notice-board-card__clickable': redirectUrl
        })}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-px-10 align-items-base wp-80">
            <div className="notice-board-card__title">{title}</div>
            {redirectUrl && (
              <a href={redirectUrl} target="_blank" rel="noopener noreferrer" className="d-flex">
                <UiExternalLinkAlt size={14} className="notice-board-card__icon" />
              </a>
            )}
          </div>
          {isActiveNotice && (
            <div
              className={classNames('notice-board-card__date', { 'notice-board-card__date--active': isActiveNotice })}
            >
              <FiCalendar size={14} />

              {getFormattedDate(currentDate, DATE_DISPLAY_FORMAT)}
            </div>
          )}
        </div>
        <div className="notice-board-card__description">{description}</div>
        {!isActiveNotice && (
          <div className="notice-board-card__date notice-board-card__date--end">
            Published on: {getFormattedDate(startDate, DATE_DISPLAY_FORMAT)}
          </div>
        )}
      </div>
    </a>
  );
};

export default NoticeBoardCard;
