import http from '../utils/http';
import { interpolate } from 'utils/string';
import { EVENTS, EVENT_URL_ID } from '../constants/endpoints';

export async function fetchEvents(params) {
  return await http.get(EVENTS, { params: { ...params } });
}

export async function createEvent(event) {
  return await http.post(EVENTS, { body: event });
}

export async function updateEvent(id, event) {
  return await http.put(interpolate(EVENT_URL_ID, { id }), { body: event });
}

export async function fetchEventById(id) {
  return await http.get(interpolate(EVENT_URL_ID, { id }));
}

export async function removeEvent(id) {
  return await http.delete(interpolate(EVENT_URL_ID, { id }));
}
