import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import {
  WEEK_LIMIT_COUNT,
  ALLOCATION_TYPE_BOOKING,
  ALLOCATION_TYPE_CURRENT,
  PERSISTING_CANDIDATE_BOOKING_STATUSES
} from 'constants/bookingAllocation';
import { dashboardColors } from 'constants/chartColors';
import { EMPTY_ALLOCATION_CHART_MESSAGE } from 'constants/dashboard';
import { ALLOCATION_PILL_TAB_OPTIONS } from 'constants/appConstants';

import { fetchUserBookingAllocation, fetchUserCurrentAllocations } from 'services/user';

import { getRandomColor, pluralize, startCase } from 'utils/string';
import { sortByDate, isDateToday, isDateInFuture, getFormattedDate, getWeeksLaterDate } from 'utils/date';

import PillTab from 'components/common/pillTab';
import CommonChart from 'components/common/piechart/CommonChart';
import BookingAllocation from 'components/home/dashboard/components/BookingAllocation';

import { defaultAllocationHrs } from '../CurrentAllocation';

function AllocationAndBooking({ userId, designation }) {
  const [isError, setIsError] = useState(false);
  const [allocation, setAllocationType] = useState(ALLOCATION_TYPE_CURRENT);

  const [chartData, setChartData] = useState([]);
  const [colors, setColors] = useState(dashboardColors);
  const [currentAllocationLoading, setIsCurrentAllocationLoading] = useState(false);
  const [bookingAllocationLoading, setIsBookingAllocationLoading] = useState(false);
  const [projectAllocations, setProjectAllocations] = useState([]);
  const [bookedAllocation, setBookedAllocations] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setIsCurrentAllocationLoading(true);

        const data = await fetchUserCurrentAllocations(userId);

        const currentAllocations = !data
          ? []
          : sortByDate(data, 'startDate').filter(allocation =>
              allocation.endDate ? isDateToday(allocation.endDate) || isDateInFuture(allocation.endDate) : true
            );

        setProjectAllocations(currentAllocations);

        if (currentAllocations.length > colors.length) {
          let totalColorsToBeAdded = currentAllocations.length - dashboardColors.length;

          const colorsToBeAdded = Array.from({ length: totalColorsToBeAdded + 1 }, getRandomColor);

          setColors(previousColor => [...previousColor, ...colorsToBeAdded]);
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsCurrentAllocationLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsBookingAllocationLoading(true);

        const data = await fetchUserBookingAllocation(userId);

        if (!data) {
          return;
        }

        const limitDate = getWeeksLaterDate(data.bookingStatus.statusUpdatedAt, WEEK_LIMIT_COUNT);

        const includesPersistingStatus = PERSISTING_CANDIDATE_BOOKING_STATUSES.includes(data.bookingStatus.name);

        if (includesPersistingStatus && getFormattedDate() > limitDate) {
          return;
        }

        setBookedAllocations(data);
      } catch (error) {
      } finally {
        setIsBookingAllocationLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (currentAllocationLoading || bookingAllocationLoading) {
      return;
    }

    const selectedAllocationTab =
      !projectAllocations.length && bookedAllocation ? ALLOCATION_TYPE_BOOKING : ALLOCATION_TYPE_CURRENT;

    setAllocationType(selectedAllocationTab);
  }, [currentAllocationLoading, bookingAllocationLoading]);

  useEffect(() => {
    if (!projectAllocations || !projectAllocations.length) {
      return;
    }

    let chart = [];

    if (!projectAllocations.length && designation.area) {
      chart = [
        {
          key: 0,
          label: designation?.area?.name,
          value: defaultAllocationHrs,
          color: colors[0]
        }
      ];
    } else {
      chart = projectAllocations.map(({ allocation, project, allocationType, area }, index) => {
        return {
          key: index,
          label: project.name || area.name || startCase(allocationType),
          value: allocation,
          color: colors[index]
        };
      });
    }
    setChartData(chart);
  }, [projectAllocations, colors, designation?.area]);

  const onToggleChange = async newValue => {
    setAllocationType(newValue);
  };

  const isLoading = currentAllocationLoading || bookingAllocationLoading;

  return (
    <>
      <div className="dashboard__title dashboard__title--allocation">
        <div className="dashboard__title--allocation-text">
          <div>{pluralize('My Allocation', projectAllocations.length)}</div>
        </div>

        {bookedAllocation && (
          <div className="dashboard__toggle-tab">
            <PillTab
              tabs={ALLOCATION_PILL_TAB_OPTIONS}
              onTabClick={value => onToggleChange(value)}
              isActive={value => value === allocation}
            />
          </div>
        )}
      </div>
      {isLoading && (
        <>
          <SkeletonTheme baseColor="#fdfdfd" highlightColor="#f0f3f5">
            <Skeleton className="allocation-card-loader wp-100" height={196} />
          </SkeletonTheme>
        </>
      )}
      {!isLoading && allocation === ALLOCATION_TYPE_CURRENT && (
        <div className="dashboard__content flex-1 d-flex align-items-center justify-content-center">
          {isError && (
            <div className="container mt-25">
              <div className="allocation__empty">
                <p> Sorry, couldn't load the allocation list. Please try again!.</p>
              </div>
            </div>
          )}

          {!isError && (
            <div className="allocation__content">
              {chartData.length ? (
                <CommonChart
                  className="allocation__chart"
                  chartData={chartData}
                  wrapperVisible={false}
                  appendLabel={{ singular: 'hr/week', plural: 'hrs/week' }}
                  colors={colors}
                  projectAllocations={projectAllocations}
                  designation={designation}
                />
              ) : (
                <div className="allocation__empty">
                  <p>{EMPTY_ALLOCATION_CHART_MESSAGE}</p>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {!isLoading && allocation !== ALLOCATION_TYPE_CURRENT && (
        <div>
          <div className="booking-allocation custom-scroll-bar px-16 ptpx-12 pbpx-12">
            <BookingAllocation bookedAllocation={bookedAllocation} />
          </div>
        </div>
      )}
    </>
  );
}

export default AllocationAndBooking;
