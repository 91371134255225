import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { MdSearch } from 'react-icons/md';

import * as toast from 'utils/toast';
import { withOnlyAttrs } from 'utils/object';
import { handleError } from 'utils/errorHandler';

import Loading from 'components/common/loading/Loading';
import { SkillSetV1, EditSkill, EditSkillCategory } from './components';

import * as skillService from 'services/skillService';
import { SKILLS, SKILLS_CATEGORY } from 'constants/permissions';
import * as skillCategoryService from 'services/skillCategoryService';

class Skills extends React.Component {
  constructor(props) {
    super(props);
    window.document.title = 'Skills | Vyaguta';

    this.state = {
      skills: [],
      searchQuery: '',
      isLoading: true,
      isSaving: false,
      isDeleting: false,
      activeSkill: null,
      activeSkillCategory: null,
      skillCategories: [],
      showEditSkill: false,
      showEditSkillCategory: false
    };
  }

  componentDidMount = async () => {
    await this.fetchSkillCategories();
    this.fetchSkills();
  };

  fetchSkillCategories = async () => {
    try {
      this.setLoading(true);
      let { data: skillCategories } = await skillCategoryService.fetchAll({
        size: 10000
      });

      if (!skillCategories || !skillCategories.length) {
        return this.setLoading(false);
      }
      this.setState({
        skillCategories: skillCategories,
        isLoading: false
      });
    } catch (error) {
      handleError(error);
    }
  };

  fetchSkills = async () => {
    try {
      this.setLoading(true);

      const skillResponse = await skillService.fetchAllV1();

      this.setState({
        activeSkill: null,
        showEditSkill: false,
        showEditSkillCategory: false,
        skills: skillResponse.data,
        isSaving: false,
        isDeleting: false,
        isLoading: false
      });
    } catch (err) {
      handleError(err);
      this.setLoading(false);
    }
  };

  setLoading = isLoading => {
    this.setState({
      isLoading
    });
  };

  handleSkillAdd = skillCategory => {
    const { activeSkill } = this.state;
    if (activeSkill && !activeSkill.id && activeSkill.skillCategory.id === skillCategory.id) {
      return this.handleSkillCancel();
    }

    const skillObject = {
      name: '',
      description: '',
      skillCategory
    };
    this.setState({
      activeSkill: skillObject,
      showEditSkill: true
    });
  };

  handleSkillClick = activeSkill => {
    if (this.state.showEditSkillCategory) {
      this.handleSkillCategoryCancel();
    }

    const newActiveSkill = this.state.activeSkill !== activeSkill ? activeSkill : null;
    this.setState({
      activeSkill: newActiveSkill,
      showEditSkill: newActiveSkill && true
    });
  };

  handleSkillCancel = () => this.setState({ showEditSkill: false, activeSkill: null });

  handleSkillSave = async (skill, id = null) => {
    this.setState({ isSaving: true });
    try {
      const skillObject = withOnlyAttrs(skill, ['name', 'description', 'skillCategory']);
      skillObject.skillCategory = withOnlyAttrs(skillObject.skillCategory, ['id', 'name']);
      if (id) {
        await skillService.update(skillObject, id);
      } else await skillService.create(skillObject);

      this.fetchSkills();

      toast.success({
        title: 'Success',
        message: `Skill has been ${id ? 'updated' : 'created'} successfully.`
      });
    } catch (exception) {
      toast.error({
        title: 'Error',
        message: exception.response.data.error.message
      });
      this.setState({
        isSaving: false
      });
    }
  };

  handleSkillDelete = async skill => {
    this.setState({ isDeleting: true });

    try {
      await skillService.remove(skill.id);
      this.fetchSkills(this.state.activeSkillCategory);
      toast.success({
        title: 'Success',
        message: `Skill has been deleted successfully.`
      });
    } catch (error) {
      toast.error({
        title: 'Error',
        message: 'Cannot delete the selected skill.'
      });
      this.setState({
        isDeleting: false
      });
    }
  };

  handleSkillCategoryCancel = () => {
    this.setState({
      showEditSkillCategory: false,
      activeSkillCategory: null
    });
  };

  handleSkillCategorySave = async (skillCategory, id = null) => {
    this.setState({ isSaving: true });
    try {
      const skillCategoryObject = withOnlyAttrs(skillCategory, ['name', 'description']);
      if (id) {
        await skillCategoryService.update(skillCategoryObject, id);
      } else {
        await skillCategoryService.create(skillCategoryObject);
      }

      await this.fetchSkillCategories();

      toast.success({
        title: 'Success',
        message: `Skill Category has been ${id ? 'updated' : 'created'} successfully.`
      });

      this.setState({
        showEditSkillCategory: false
      });
    } catch (exception) {
      toast.error({
        title: 'Error',
        message: exception.response.data.error.message
      });
    } finally {
      this.setState({
        isSaving: false
      });
    }
  };

  handleSkillCategoryAdd = () => {
    const { activeSkillCategory } = this.state;
    if (activeSkillCategory && !activeSkillCategory.id) {
      return this.handleSkillCategoryCancel();
    }

    const skillCategoryObject = {
      name: '',
      description: ''
    };
    this.setState({
      activeSkillCategory: skillCategoryObject,
      showEditSkillCategory: true
    });
  };

  handleSkillCategoryDelete = async id => {
    this.setState({
      isDeleting: true
    });
    try {
      await skillCategoryService.remove(id);

      await this.fetchSkillCategories();

      toast.success({
        title: 'Success',
        message: `Skill Category has been deleted successfully.`
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({
        isDeleting: false
      });
    }
  };

  handleSkillCategoryAddClick = () => {
    if (this.state.showEditSkill) {
      this.handleSkillCancel();
    }

    this.setState({
      showEditSkillCategory: !this.state.showEditSkillCategory
    });
  };

  handleSkillAddClick = () => {
    const { showEditSkillCategory, showEditSkill, activeSkill } = this.state;

    if (showEditSkillCategory) {
      this.handleSkillCategoryCancel();
    }

    if (showEditSkill && !activeSkill.id) {
      return this.handleSkillCancel();
    }

    const skillObject = {
      name: '',
      description: '',
      skillCategory: null
    };
    this.setState({
      activeSkill: skillObject,
      showEditSkill: true
    });
  };

  handleSearchChange = ({ target: { value: searchQuery } }) => {
    this.setState({
      searchQuery
    });
  };

  handleSkillCategorySelect = category => {
    const { showEditSkill, activeSkillCategory, showEditSkillCategory } = this.state;
    if (showEditSkill) {
      this.handleSkillCancel();
    }
    if (showEditSkillCategory && activeSkillCategory.id === category.id) {
      this.handleSkillCategoryCancel();
    } else {
      this.setState({ activeSkillCategory: category, showEditSkillCategory: true });
    }
  };

  render() {
    const { isHr } = this.props.user;
    const {
      skills,
      isSaving,
      isLoading,
      isDeleting,
      searchQuery,
      activeSkill,
      showEditSkill,
      skillCategories,
      activeSkillCategory,
      showEditSkillCategory
    } = this.state;

    const { permissions } = this.props;

    return (
      <div className="full-scope-card">
        <div className="full-scope-card__header table-header name-wrap">
          <div>
            <div className="table-title">Skills</div>
            <div className="table-subtitle">{`Showing ${skills.length} Results`}</div>
          </div>

          <div className="d-flex flex-row">
            <div className="search-wrapper skills-search d-flex flex-row align-items-center">
              <MdSearch className="search-icon" />
              <input
                className="search-bar skills-search-input"
                value={searchQuery}
                type="text"
                onChange={this.handleSearchChange}
                placeholder="Search by keywords"
              />
            </div>

            {/* Commented out for skill version 1 */}
            {/* {permissions[SKILLS_CATEGORY.CREATE] && (
              <button
                className="btn btn--primary f-left card-button mr-10 new-category-btn"
                onClick={this.handleSkillCategoryAddClick}
              >
                Add Category
              </button>
            )}

            {this.props.permissions[SKILLS.CREATE] && (
              <button
                className="btn btn--primary f-left card-button mr-10 new-skill-btn"
                onClick={this.handleSkillAddClick}
              >
                Add Skill
              </button>
            )} */}
          </div>
        </div>
        <div className={classNames({ loading__container: isLoading })}>
          <div className="full-scope-card__content d-flex position-relative">
            {isLoading ? (
              <Loading className="mt-120" />
            ) : (
              <>
                <div className="skill-set-area">
                  <SkillSetV1
                    skillCategories={skillCategories}
                    searchQuery={searchQuery}
                    skills={skills}
                    activeSkill={activeSkill}
                    onSkillCategoryClick={this.handleSkillCategorySelect}
                    onSkillClick={this.handleSkillClick}
                    onSkillAdd={this.handleSkillAdd}
                    permissions={permissions}
                    isHr={isHr}
                  />
                </div>
                {permissions[SKILLS.UPDATE] && (
                  <div className="skill-edit-area">
                    {this.state.showEditSkillCategory && (
                      <EditSkillCategory
                        skillCategory={activeSkillCategory}
                        isHr={isHr}
                        handleSave={this.handleSkillCategorySave}
                        handleDelete={this.handleSkillCategoryDelete}
                        handleCancel={this.handleSkillCategoryCancel}
                        saving={isSaving}
                        deleting={isDeleting}
                      />
                    )}
                    {showEditSkill && !(activeSkillCategory && !activeSkillCategory.id) && (
                      <EditSkill
                        skill={activeSkill}
                        isHr={isHr}
                        skillCategories={skillCategories}
                        handleSave={this.handleSkillSave}
                        handleDelete={this.handleSkillDelete}
                        handleCancel={this.handleSkillCancel}
                        saving={isSaving}
                        deleting={isDeleting}
                      />
                    )}
                    {!showEditSkill && !showEditSkillCategory && (
                      <div className="empty-skill-set">{'Click on any of the skill to view details here'}</div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  permissions: state.information.value.permissions
});

export default connect(mapStateToProps)(Skills);
