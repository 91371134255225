export const EMPLOYEES = {
  VIEW: 'employees.view',
  CREATE: 'employees.create',
  FINDER: 'employees.finder.view',
  FILTER: 'employees.filter',
  EXPORT: 'employees.export',
  PROFILE: {
    VIEW: 'employees.profile.view',
    UPDATE: 'employees.profile.update'
  },
  VIEW_EMPLOYMENT_HISTORY: 'employees.employment.history.view',
  DESIGNATIONS: {
    VIEW_HISTORY: 'employees.designations.history.view',
    UPDATE: 'employees.designations.update'
  },
  ENGAGEMENTS: {
    VIEW_HISTORY: 'employees.engagements.history.view',
    UPDATE: 'employees.engagements.update'
  },

  PROJECTS: {
    VIEW_CURRENT: 'employees.projects.current.view',
    VIEW_HISTORY: 'employees.projects.history.view'
  },

  SKILLS: {
    UPDATE: 'employees.skills.update',
    SEARCH: 'employees.skills.search'
  },

  RESIGNATIONS: {
    VIEW: 'employees.resignations.view',
    CREATE: 'employees.resignations.create',
    UPDATE: 'employees.resignations.update',
    DELETE: 'employees.resignations.delete'
  },
  ASSETS: {
    VIEW: 'employees.assets.view'
  },

  UPDATE_LEAVE_ISSUER: 'employees.leaveIssuer.update',
  UPDATE_TEAM_MANAGER: 'employees.teamManager.update'
};

export const SKILLS = {
  VIEW: 'skills.view',
  CREATE: 'skills.create',
  UPDATE: 'skills.update',
  DELETE: 'skills.delete'
};

export const NOTES = {
  VIEW: 'notes.view',
  CREATE: 'notes.create',
  UPDATE: 'notes.update',
  DELETE: 'notes.delete'
};

export const NOTICES = {
  VIEW: 'notices.view',
  CREATE: 'notices.create',
  UPDATE: 'notices.update',
  DELETE: 'notices.update'
};

export const LEAVE_ISSUER_CHANGE_REQUESTS = {
  VIEW: 'leaveIssuer.change.requests.view'
};

export const SKILLS_CATEGORY = {
  VIEW: 'skillCategories.view',
  CREATE: 'skillCategories.create',
  UPDATE: 'skillCategories.update',
  DELETE: 'skillCategories.delete'
};

export const DESIGNATIONS = {
  VIEW: 'designations.view',
  CREATE: 'designations.create',
  UPDATE: 'designations.update',
  DELETE: 'designations.delete'
};

export const AREAS = {
  VIEW: 'areas.view',
  CREATE: 'areas.create',
  UPDATE: 'areas.update',
  DELETE: 'areas.delete'
};

export const REMINDERS = {
  VIEW: 'reminders.view',
  CREATE: 'reminders.create',
  UPDATE: 'reminders.update',
  DELETE: 'reminders.delete'
};

export const EVENTS = {
  VIEW: 'events.view',
  CREATE: 'events.create',
  UPDATE: 'events.update',
  DELETE: 'events.delete'
};

export const HOLIDAYS = {
  VIEW: 'holidays.view',
  CREATE: 'holidays.create',
  UPDATE: 'holidays.update',
  DELETE: 'holidays.delete'
};

export const QUICK_LINKS = {
  VIEW: 'quickLinks.view',
  CREATE: 'quickLinks.create',
  UPDATE: 'quickLinks.update',
  DELETE: 'quickLinks.delete'
};

export const QUICK_LINKS_CATEGORY = {
  VIEW: 'quickLinksCategory.view',
  CREATE: 'quickLinksCategory.create',
  UPDATE: 'quickLinksCategory.update',
  DELETE: 'quickLinksCategory.delete'
};

export const USER_PERMISSIONS = {
  VIEW: 'permissions.view',
  CREATE: 'permissions.create',
  UPDATE: 'permissions.update',
  DELETE: 'permissions.delete',
  CREATE_SUPER_ADMIN: 'permissions.superAdmin.create',
  CREATE_HR: 'permissions.hr.create',
  CREATE_PROJECT_ADMIN: 'permissions.projectAdmin.create',
  CREATE_JUMP_ADMIN: 'permissions.jumpAdmin.create',
  CREATE_HONOR_ADMIN: 'permissions.honorAdmin.create'
};

export const CREATE_ROLE_PERMISSION_MAP = {
  1: 'permissions.superAdmin.create',
  2: 'permissions.hr.create',
  4: 'permissions.projectAdmin.create',
  5: 'permissions.jumpAdmin.create'
};

export const DASHBOARD = {
  STATS: {
    TOP_TEN_LEAVES: 'stats.topTenLeaves.view',
    TODAY_ATTENDANCE: 'stats.attendance.today.view',
    NEW_FACES: 'stats.newFaces.view'
  },

  BIRTHDAYS: 'birthdays.view',
  WORK_ANNIVERSARIES: 'workAnniversaries.view',
  ANNOUNCEMENTS: 'announcements.view',
  COMPANY: 'company.overview.view'
};

export const ROLE_PERMISSION = {
  CREATE: 'role.permission.create',
  DELETE: 'role.permission.delete'
};

export const LEAVE = {
  CREATE: 'leave.create',

  LEAVE_BALANCE: {
    VIEW: 'leave-balance.view'
  }
};

export const LINK_LIBRARY = {
  CREATE: 'linkLibrary.create',
  VIEW: 'linkLibrary.view',
  UPDATE: 'linkLibrary.update',
  DELETE: 'linkLibrary.delete'
};

export const EXPIRED_POTENTIAL_END_DATE_HIGHLIGHT = {
  VIEW: 'expiredPotentialEndDateHighlight.view'
};

export const ATTENDANCE_PERMISSION = {
  VIEW: 'attendance.all.view.fe'
};
