import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchRoles } from 'slices/userRolesSlice';
export const useFetchRoles = () => {
  const dispatch = useDispatch();

  const { value: roles, loading: isLoadingRoles } = useSelector(state => state.roles);

  useEffect(() => {
    if (roles.length) {
      return;
    }

    dispatch(fetchRoles());
  }, [dispatch]);

  return { roles, isLoadingRoles };
};
