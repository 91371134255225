export const getLeaveIssueCountMessage = ({ issuerName, issueCount, isLoggedInUser }) => {
  if (isLoggedInUser) {
    const userAsLeaveIssuerMessageMap = {
      0: 'You do not grant leave to any other leapfroggers.',
      1: `You grant leave to ${issueCount} other leapfrogger.`,
      default: `You grant leave to ${issueCount} other leapfroggers.`
    };

    const selfMessage = userAsLeaveIssuerMessageMap[issueCount] || userAsLeaveIssuerMessageMap.default;

    return selfMessage;
  }

  const messageMap = {
    0: `${issuerName} does not grant leave to any other leapfroggers.`,
    1: `${issuerName} grants leave to ${issueCount} other leapfrogger.`,
    default: `${issuerName} grants leave to ${issueCount} other leapfroggers.`
  };

  const message = messageMap[issueCount] || messageMap.default;

  return message;
};
