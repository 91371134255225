import { useQuery } from 'react-query';

import { fillMissingDates } from 'utils/roster';

import { fetchRosterByUserId } from 'services/attendance';

import { ATTENDANCE_QUERY_KEYS } from './keys';

export function useFetchRosterData(params = {}) {
  return useQuery({
    queryKey: [ATTENDANCE_QUERY_KEYS.ROSTER, params],
    queryFn: () => fetchRosterByUserId(params),

    enabled: !!params.userIds && !!params.startDate && !!params.endDate,

    select: data => {
      return params.startDate && params.endDate ? fillMissingDates(data, params.startDate, params.endDate) : data;
    }
  });
}
