import { useQuery } from 'react-query';

import { handleError } from 'utils/errorHandler';

import { fetchAssetsById } from 'services/assets';

export const useFetchAssetsById = id => {
  const queryKey = ['assets', id];
  const query = useQuery(queryKey, () => fetchAssetsById(id), {
    onError: error => {
      handleError(error);
    }
  });

  return query;
};
