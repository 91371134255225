/**
 * Application wide configuration.
 */
const config = {
  env: process.env.NODE_ENV,
  appName: process.env.REACT_APP_NAME || 'Vyaguta Core',
  appVersion: process.env.REACT_APP_VERSION,
  appEnv: process.env.REACT_APP_ENV,
  basename: process.env.REACT_APP_BASE_NAME,
  baseURI: process.env.REACT_APP_API_BASE_URI,
  coreBaseURI: process.env.REACT_APP_API_CORE_BASE_URI,
  jumpBaseURI: process.env.REACT_APP_API_JUMP_BASE_URI,
  attendanceBaseURI: process.env.REACT_APP_API_ATTENDANCE_BASE_URI,
  authURI: process.env.REACT_APP_AUTH_BASE_URI,
  authClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  loginURL: process.env.REACT_APP_LMS_URL,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  vyagutaURI: process.env.REACT_APP_VYAGUTA_URL,
  newSkillRequestFormURI: process.env.REACT_APP_NEW_SKILLS_REQUEST_FORM,
  empImageURI: `${process.env.REACT_APP_IMAGE_URI}/employee/48/:empId.png`,
  projectLogoURI: `${process.env.REACT_APP_IMAGE_URI}/projects/logo/48/:projectId.png`,
  mixPanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
  mixPanelProxyDomain: process.env.REACT_APP_MIXPANEL_PROXY_DOMAIN,
  // OAuth vars
  fbAppId: process.env.REACT_APP_FB_APP_ID,
  fbAppVersion: process.env.REACT_APP_FB_APP_VERSION || 'v3.3',
  googleOAuthScope: process.env.REACT_APP_GOOGLE_OAUTH_SCOPE || 'profile email',
  googleOAuthConsent: process.env.REACT_APP_GOOGLE_OAUTH_CONSENT || 'select_account',
  googleOAuthClientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
  askUsAnythingForm:
    process.env.REACT_APP_ASK_US_ANYTHING_LINK ||
    'https://docs.google.com/forms/d/e/1FAIpQLSdfsJ0ZIDFg3d2_YAT4-Yp04kgs6aJ0sAVbF1s5qSjUkjQqVA/viewform',
  rolesInfoUrl: process.env.REACT_APP_ROLES_CONFLUENCE_URL,
  wellBeingLeavePolicy:
    process.env.REACT_WELL_BEING_LEAVE_POLICY_URL ||
    'https://lftechnology.atlassian.net/wiki/spaces/LEAP/pages/4321869940/Well-being+Leave+Policy+Nepal',
  teamsBaseURI: process.env.REACT_APP_TEAMS_BASE_URI,
  teamsAppURL: process.env.REACT_APP_TEAMS_APP_URL,
  isMaintenanceMode: Boolean(+process.env.REACT_APP_MAINTENANCE_MODE),

  relatedHelpDocumentsUrl: process.env.REACT_APP_RELATED_HELP_DOCUMENTS_URL,
  peopleFeedbackUrl: process.env.REACT_APP_PEOPLE_FEEDBACK_URL,
  bugReportUrl: process.env.REACT_APP_BUG_REPORT_URL,
  events: {
    startDate: process.env.REACT_APP_EVENT_START_DATE || '2023-07-07 09:00:00',
    endDate: process.env.REACT_APP_EVENT_END_DATE || '2023-07-07 18:00:00',
    link:
      process.env.REACT_APP_EVENT_VIDEO_LINK ||
      'https://drive.google.com/file/d/1AQnvquenAsff-l9htGzaiLZMRnLVFhbI/preview',
    title: process.env.REACT_APP_EVENT_TITLE || 'To Watch The Q3 All Hands Video: Click Here'
  },
  freezeAttendance: {
    timeForLock: process.env.REACT_APP_FREEZE_ATTENDENCE || '07:00 PM'
  },
  pulseBaseUrl: process.env.REACT_APP_PULSE_APP_URL,
  endpoints: {
    users: 'users',
    userProfile: 'users/:id',
    userProjectAllocations: '/allocations',
    userPermissions: 'users/:id/permissions',
    userActivate: 'users/:id/activate',
    roles: 'roles',
    rolesPermissions: 'roles/permissions',
    grantRolesPermissions: 'roles/:id/permissions',
    revokeRolesPermissions: 'roles/:id/permissions/:permissionId',
    permissions: 'permissions',
    information: 'me/info',
    userRoles: 'users/:id/roles',
    resource: 'users/resource',
    peopleFinder: 'people-finder',
    peopleFinderExport: 'people-finder/export',
    resourceExport: 'users/resource/export',
    userSkills: 'users/:id/skills',
    userTeam: 'users/:id/team',
    userImageUpload: 'users/:id/upload',
    updateUserAndNotifyLeaveIssuer: 'users/:id/leave-issuer',
    leaveIssuerChangeRequest: 'users/:userId/leave-issuer-history/change-request',
    updateUserAndNotifyTeamManager: 'users/:id/team-manager',
    updateUserEngagementStatus: 'users/:id/engagement-status',
    updateUserDesignation: 'users/:id/designation',
    userLeaveIssuerHistory: 'users/:id/leave-issuer-history',
    userTeamManagerHistory: 'users/:id/team-manager-history',
    leaveIssuerChangeRequestsList: 'leave-issuers/change-requests',
    leaveIssuerChangeRequestsListExport: 'leave-issuers/change-requests/export',
    bulkUpdateLeaveIssuerChangeRequest: 'leave-issuers/change-requests/status',
    updateLeaveIssuerChangeRequest: 'users/:userId/leave-issuer-history/change-request/status',
    departments: 'departments',
    designations: 'designations',
    designation: 'designations/:id',
    skill: 'skills/:id',
    skills: 'skills',
    skillsV2: 'skills/v2',
    skillV2: 'skills/v2/:id',
    skillV2Categories: 'skills/v2/categories',
    skillV2Category: 'skills/v2/categories/:id',
    userSkillsV2: 'users/:userId/skills/v2',
    userSkillsV2Status: 'users/:userId/skills/v2/:skillId/status',
    skillsV2globalratings: 'skills/v2/global-ratings',
    skillCategory: 'skill-categories/:id',
    skillCategories: 'skill-categories',
    areas: 'designation-areas',
    reminders: 'reminders',
    reminder: 'reminders/:id',
    remindersList: 'reminders/list',
    engagementStatus: 'engagement-status',
    leaveBalance: '/leave-balance',
    leaveApply: '/leaves/apply',
    leaveCalculate: '/leaves/calculate',
    leaveCredits: '/leave-credits',
    wellBeingLeaves: '/leave-credits/well-being',
    quickLinks: {
      quickLinks: 'quick-links',
      quickLinksCategories: '/quick-links/categories',
      quickLinksCategory: '/quick-links/categories/:id',
      quickLinksCategoryLinks: '/quick-links/categories/link',
      quickLinksCategoryLink: '/quick-links/categories/link/:id',
      weights: '/quick-links/weights'
    },

    events: 'events',
    event: 'events/:id',
    notices: 'notices',
    noticesList: 'notices/list',
    notice: 'notices/:id',
    noticeBoard: 'notices/noticeboard',
    notes: 'notes',
    note: 'notes/:id',
    occasion: 'occasions',
    holidays: 'holidays',
    holiday: 'holidays/:id',
    teamSummary: 'users/team-summary',
    empAssets: 'assets/:id',
    auth: {
      login: '/login',
      token: '/token',
      logout: '/logout'
    },
    attendance: {
      app: {
        worklogDetail: '/worklogs/:id',
        leaveDetail: '/leaves/:id',
        applyLeave: '/leaves/apply',
        selfWorklog: '/self/worklog',
        teamWorklog: '/team/worklog',
        selfMissing: '/self/missing',
        teamLeave: '/team/leave'
      },
      api: {
        worklogs: '/worklogs',
        calendar: '/calendar',
        summary: '/attendance/summary',
        leaves: '/leaves',
        attendance: '/attendance',
        medianClockInTime: '/wifi-logs/median-time/:userId',
        workStatusSummary: '/attendance/work-status-summary',
        unfreezeRequests: '/worklog-unfreeze-requests/:userId',
        roster: '/roster'
      }
    },
    jump: {
      app: {
        feedbackDetail: '/feedbacks/:id'
      },
      userFeedbacks: '/feedbacks/team/:userId',
      goalFeedbacks: '/goals/:goalId/feedbacks',
      userGoals: '/goals/employees/:userId',
      userGoalsSummary: '/goals/employees/:userId/summary'
    },
    pulse: {
      userFeed: '/:id/feed'
    },
    lms: {
      userProfile: '/leapfroggers/:id',
      userProfileList: '/leapfroggers/',
      dashboard: '/homepage'
    },
    fiscalYears: '/fiscal-years',
    teams: {
      allocations: '/allocations',
      industries: '/industries',
      appraiserChangeRequest: '/allocations/:id/appraiser-change-request',
      bookingDetails: '/candidates/:userId/booking-details',
      app: {
        project: '/projects/:id/detail',
        offboards: '/projects/:id/detail/offboard'
      }
    },
    userResignations: '/users/:userId/resignations',
    userResignation: '/users/:userId/resignations/:id'
  },
  vyaguta: {
    dashboard: '/',
    people: '/leapfroggers',
    attendance: '/attendance',
    teams: '/teams',
    timesheet: '/timesheet',
    honor: '/honor',
    jump: '/jump',
    okr: '/okr',
    pulse: '/pulse'
  }
};

export default config;
