import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import config from '../config';

import sentry from 'utils/sentry';
import * as storage from 'utils/storage';
import { getDecodedToken } from 'utils/token';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/storage';

import * as mixpanel from 'services/mixPanel';
import { redirectToLogin } from 'services/auth';

import Router from './Router';
import Footer from './home/footer';

import Toast from './common/toast';
import WorkPlanButtons from './common/work-plan-buttons';

sentry();

export const UserContext = React.createContext('user');

class App extends Component {
  constructor() {
    super();
    this.state = {
      user: {}
    };
  }

  componentDidMount() {
    mixpanel.initialize(config.appName);

    const decodedToken = this.getUserData();
    if (decodedToken && decodedToken.data) {
      const { data } = decodedToken;

      this.setState({
        user: data
      });
    } else {
      redirectToLogin();
    }
  }

  /**
   * Check Access Token and Refresh Token in Cookie
   *
   * @returns User Data Object else null
   * @memberof App
   */
  getUserData() {
    try {
      const accessToken = storage.get(ACCESS_TOKEN);
      const decodedToken = getDecodedToken(accessToken);

      if (accessToken && decodedToken) {
        return decodedToken;
      } else {
        const refreshToken = storage.get(REFRESH_TOKEN);
        const decodedRefreshToken = getDecodedToken(refreshToken);

        if (refreshToken && decodedRefreshToken) {
          return decodedRefreshToken;
        }
      }
    } catch (err) {
      return null;
    }

    return null;
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error);
  }

  render() {
    const { user } = this.state;

    return Object.entries(user).length ? (
      <UserContext.Provider value={user}>
        <Router />
        <WorkPlanButtons />
        <Footer />
        <Toast />
      </UserContext.Provider>
    ) : null;
  }
}

export default App;
