import React from 'react';

const FormWrapperHeader = ({ currentForm, sizeOfStepper, label }) => {
  return (
    <div className="form-wrapper-header">
      <p className="form-wrapper-header__detail">
        Step {currentForm} of {sizeOfStepper}
      </p>
      <p className="form-wrapper-header__title">{label}</p>
    </div>
  );
};

export default FormWrapperHeader;
