import { buildUrl } from 'utils/string';
import { ATTENDANCE_URL } from './endpoints';

export const HOME = '/';
export const SKILL_ABS = '/skills/:id';
export const ACTIVE_EMPLOYEES = '/leapfroggers/';
export const EMPLOYEE_PROFILE = '/leapfroggers/:id';
export const EMPLOYEE_PROFILE_LEAVE_CREDITS = '/leapfroggers/:id/leave-credits';
export const EMPLOYEE_ASSETS = '/leapfroggers/:id/assets';
export const EMPLOYEE_PROFILE_GOALS = '/leapfroggers/:id/goals';
export const EMPLOYEE_PROFILE_FEEDBACKS = '/leapfroggers/:id/feedbacks';
export const EMPLOYEE_PROFILE_MY_TEAM = '/leapfroggers/:id/my-team';
export const RESOURCE_FINDER = '/resource-finder/';
export const RESOURCE_FINDER_V1 = '/resource-finder/V1';
export const CREATE_EMPLOYEE_NOTE = '/leapfroggers/:userId/notes/create';
export const EMPLOYEE_NOTE = '/leapfroggers/:userId/notes';
export const UPDATE_EMPLOYEE_NOTE = '/leapfroggers/:userId/notes/:noteId/update';
export const CREATE_EMPLOYEE = '/leapfroggers/create';
export const UPDATE_EMPLOYEE = '/leapfroggers/:id/update';
export const EMPLOYEE_SKILLS = '/leapfroggers/:id/skills';
export const UPDATE_EMPLOYEE_SKILLS = '/leapfroggers/:id/skills';
export const CHANGE_DESIGNATION = '/leapfroggers/:id/designations';
export const CHANGE_ENGAGEMENT = '/leapfroggers/:id/engagements';
export const UPDATE_LEAVE_ISSUER = '/leapfroggers/:id/leave-issuers';
export const UPDATE_TEAM_MANAGER = '/leapfroggers/:id/team-managers';
export const CHANGE_ISSUER_REQUEST = '/leapfroggers/:id/change-issuer-request';
export const CHANGE_TENTATIVE_EXIT = '/leapfroggers/:id/tentative-exit';

export const NOTES = '/notes';
export const CREATE_NOTE = '/notes/create';
export const UPDATE_NOTE = '/notes/update/:id';
export const NOTE_SUMMARY = '/notes/summary';
export const DASHBOARD = '/';
export const ME = '/me';
export const KPIs = '/jump/kpis';
export const ROLES_PERMISSIONS = '/roles/permissions';
export const TEAMS_PROJECTS = '/teams/projects/:id';
export const NOTICE_BOARD = '/noticeboard';
export const LEAVE_ISSUER_CHANGE_REQUESTS = '/issuers/change-requests';

export const EVENTS_AND_NOTICES = '/events-notices';
export const REMINDERS = buildUrl(EVENTS_AND_NOTICES, 'reminders');
export const CREATE_REMINDER = buildUrl(EVENTS_AND_NOTICES, 'reminders/create');
export const UPDATE_REMINDER = buildUrl(EVENTS_AND_NOTICES, 'reminders/:id');
export const EVENTS = buildUrl(EVENTS_AND_NOTICES, 'events');
export const CREATE_EVENT = buildUrl(EVENTS_AND_NOTICES, 'events/create');
export const UPDATE_EVENT = buildUrl(EVENTS_AND_NOTICES, 'events/:id');
export const NOTICES = buildUrl(EVENTS_AND_NOTICES, 'notices');
export const CREATE_NOTICE = buildUrl(EVENTS_AND_NOTICES, 'notices/create');
export const UPDATE_NOTICE = buildUrl(EVENTS_AND_NOTICES, 'notices/:id');
export const HOLIDAYS = buildUrl(EVENTS_AND_NOTICES, 'holidays');
export const CREATE_HOLIDAY = buildUrl(EVENTS_AND_NOTICES, 'holidays/create');
export const UPDATE_HOLIDAY = buildUrl(EVENTS_AND_NOTICES, 'holidays/:id');
export const LINK_LIBRARY = buildUrl(EVENTS_AND_NOTICES, 'link-library');

export const SETTINGS = '/settings';
export const SKILLS = buildUrl(SETTINGS, 'skills/');
export const SKILLS_V1 = buildUrl(SETTINGS, 'skills/v1');
export const DESIGNATION = buildUrl(SETTINGS, 'designations');
export const CREATE_DESIGNATION = buildUrl(SETTINGS, 'designations/create');
export const UPDATE_DESIGNATION = buildUrl(SETTINGS, 'designations/:id');
export const USER_PERMISSION = buildUrl(SETTINGS, 'users/permissions');

export const USER_ATTENDANCE = buildUrl(ATTENDANCE_URL, 'user/:userId');
