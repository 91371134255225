import React from 'react';

import Loading from 'components/common/loading/Loading';

import { extractFullName } from 'utils/string';
import { getFormattedDate, isDateInFuture } from 'utils/date';

import { TWELEVE_HOUR_DATE_TIME_FORMAT } from 'constants/appConstants';

const LeaveIssuersHistory = ({ leaveIssuers, isLoading }) => {
  const findCurrentIndex = () => {
    for (let i = 0; i < leaveIssuers.length; i++) {
      if (!isDateInFuture(leaveIssuers[i].transitionDate)) {
        return i;
      }
    }
  };

  return (
    <div className="full-scope-card leaveissuer-history-area">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="font-bold leaveissuer-history-title">Leave Issuers</div>
          <div className="leaveissuer-history-container custom-scroll-bar">
            {leaveIssuers && leaveIssuers.length ? (
              leaveIssuers.map((leaveIssuer, idx) => (
                <div className="position-relative pr-20" key={idx}>
                  <div
                    className={`mb-20 ml-20 leaveissuer-history${
                      idx === leaveIssuers.length - 1
                        ? ' leaveissuer-history-last'
                        : idx === findCurrentIndex()
                        ? ' leaveissuer-history-current'
                        : ''
                    }`}
                  >
                    <div className="leaveissuer-history-name">{extractFullName(leaveIssuer)}</div>
                    <div className="leaveissuer-history-date">{`Effective from ${leaveIssuer.transitionDate}`}</div>
                    <div className="leaveissuer-history-date">
                      {`Changed on ${getFormattedDate(leaveIssuer.createdAt, TWELEVE_HOUR_DATE_TIME_FORMAT)}`}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="font-12">No leave issuers assigned yet.</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LeaveIssuersHistory;
