import http from '../utils/http';
import interpolate from 'pinterpolate';
import { NOTES, NOTES_URL_ID } from '../constants/endpoints';

export async function fetchNotes(params = {}) {
  return await http.get(NOTES, { params: { ...params } });
}

export async function createNote(note) {
  return await http.post(NOTES, { body: note });
}

export async function updateNote(id, note) {
  return await http.put(interpolate(NOTES_URL_ID, { id }), { body: note });
}

export async function fetchNoteById(id) {
  return await http.get(interpolate(NOTES_URL_ID, { id }));
}
