import React, { useState, useEffect } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Eventitem from './EventItem';
import useLazyLoading from 'hooks/useLazyLoading';
import { fetchOccasions } from 'services/occasion';

import Empty from 'components/common/empty/Empty';
import Loading from 'components/common/loading/Loading';

import { DEFAULT_PAGE_SIZE } from 'constants/appConstants';
import { EVENT_NOT_LOADED_TEXT } from 'constants/dashboard';

import { getFormattedDate } from 'utils/date';

const EventList = ({ currentFiscalYear }) => {
  const [events, setEvents] = useState([]);
  let { endDate } = currentFiscalYear;

  const filters = {
    size: DEFAULT_PAGE_SIZE,
    startDate: getFormattedDate(moment()),
    endDate: getFormattedDate(endDate)
  };

  const { loading, error, dataList, lastRef } = useLazyLoading(fetchOccasions, [filters]);

  const isError = error;
  const isLoadingAlone = loading && events.length < 1;

  useEffect(() => {
    setEvents(dataList);
  }, [dataList, currentFiscalYear]);

  if (isLoadingAlone) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="container mt-25">
        <div className="event__empty">
          <p>{EVENT_NOT_LOADED_TEXT}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('event__lists custom-scroll-bar', { 'event__empty-list': !events.length })}>
      {!events.length ? (
        <Empty message="There are no upcoming events and holidays" />
      ) : (
        events.map((event, index) => {
          return (
            <Eventitem
              key={index}
              childRef={lastRef}
              date={event.date}
              eventType={event.type}
              eventTitle={event.name}
            />
          );
        })
      )}
      {loading && (
        <div className="lazy-loading">
          <Loading />
        </div>
      )}
    </div>
  );
};

EventList.propTypes = {
  currentFiscalYear: {
    endDate: PropTypes.string,
    startDate: PropTypes.string
  }
};

export default EventList;
