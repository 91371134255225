import React from 'react';
import classNames from 'classnames';

function WorkStatusCount({ status, count, className }) {
  return (
    <div
      className={classNames(
        'd-flex flex-direction-column align-items-center median-office-time__work-status-count',
        className
      )}
    >
      <p className="font-size-18 font-weight-bold">{count}</p>
      <p className="work-status-count__title font-size-12">{status}</p>
    </div>
  );
}

export default WorkStatusCount;
