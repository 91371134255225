import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as goalService from '../services/goal';
import { handleError } from '../utils/errorHandler';
import { STATUS, CAN_FETCH } from '../constants/redux';

const initialState = { value: [], loading: false, status: STATUS.IDLE };

export const fetchUserGoalsSummary = createAsyncThunk(
  'userGoalsSummary/fetchUserGoalsSummary',
  async ({ employeeId, params }) => {
    try {
      const data = await goalService.fetchUserGoalsSummary(employeeId, params);

      return data;
    } catch (error) {
      handleError(error);
    }

    return initialState.value;
  }
);

export const userGoalSummarySlice = createSlice({
  name: 'userGoalsSummary',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserGoalsSummary.pending, state => {
        if (CAN_FETCH.includes(state.status)) {
          return { ...state, loading: true, status: STATUS.PENDING };
        }

        return state;
      })
      .addCase(fetchUserGoalsSummary.fulfilled, (state, action) => {
        return {
          ...state,
          value: action.payload,
          loading: false,
          status: STATUS.FULFILLED
        };
      })
      .addCase(fetchUserGoalsSummary.rejected, state => {
        return { ...state, loading: false, status: STATUS.REJECTED };
      });
  }
});

export default userGoalSummarySlice.reducer;
