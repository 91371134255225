import _ from 'lodash';

export const withoutAttrs = (obj, attrsToExclude) => {
  const result = {};

  Object.keys(obj).forEach(key => {
    if (!attrsToExclude.includes(key)) {
      result[key] = obj[key];
    }
  });

  return result;
};

export const withOnlyAttrs = (obj, attrsToInclude) => {
  const result = {};

  Object.keys(obj).forEach(key => {
    if (attrsToInclude.includes(key)) {
      result[key] = obj[key];
    }
  });

  return result;
};

/**
 * Splits an array into two partitions based on the given condition.
 *
 * @param {Array<Object>} array
 * @param {Function} callback
 * @param {Function} interceptor
 *
 * @returns {Array<Array<Object>>}
 */
export const splitArray = (array, callback = () => true, interceptor) => {
  return array.reduce(
    (result, element) => {
      if (interceptor) interceptor(element);
      result[callback(element) ? 0 : 1].push(element);
      return result;
    },
    [[], []]
  );
};

/**
 * Check if the given object is empty or not.
 *
 * @param {object | undefined} obj
 * @returns {bool}
 */
export function isObjectEmpty(obj) {
  if (!obj) {
    return true;
  }

  return obj.constructor === Object && Object.entries(obj).length === 0;
}

/**
 * Join all the values in objects with given separator.
 * for eg: { firstName: 'Bob', lastName: 'Marley'} returns 'Bob Marley'.
 *
 * @param {Object} obj
 * @param {String} separator
 * @returns {String}
 */
export function joinValues(obj, separator = ' ') {
  return Object.values(obj).join(separator);
}

/**
 * Deeply remove empty values from the given object.
 *
 * @param {any} obj
 * In case you want to exclude any false value like "", 0, false, etc.
 * @param {Array<string|number|undefined>} excludedValues
 * @returns
 */
export function deepFalsyTransform(obj, targetValue = null, excludedValues = []) {
  if (_.isArray(obj)) {
    return obj.map(obj => deepFalsyTransform(obj, targetValue, excludedValues));
  }

  if (!_.isObject(obj)) {
    if (excludedValues.includes(obj)) {
      return obj;
    }

    return obj || targetValue;
  }

  const data = _.mapValues(obj, data => {
    if (excludedValues.includes(data)) {
      return data;
    }

    return data || targetValue;
  });

  return data;
}

/**
 * Combines array of objects to single array.
 *
 * @param {Array<Object>} array
 * @returns {Object}
 */
export function combineArrayObjects(array) {
  return array.reduce((result, obj) => {
    return { ...result, ...obj };
  }, {});
}

/**
 * Check if key exists on an object
 *
 * @param {Object} object
 * @param {string} key
 */
export function hasKey(object, key) {
  return Object.prototype.hasOwnProperty.call(object, key);
}

/**
 * Convert snake case keys to camel case.
 *
 * @param {Object} obj
 * @returns {Object}
 */
export function snakeToCamel(obj) {
  return _.mapKeys(obj, (_value, key) => _.camelCase(key));
}

/**
 * Get name and value from key and value
 * Only use to edit form with react-hook-form
 */
export const getNameAndValue = (object, state) => {
  Object.entries(object).map(([name, value]) => state(name, value));
};
