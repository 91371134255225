import LinkVerticalEllipsis from 'components/home/linkLibrary/components/LinkVerticalEllipsis';
import CategoryVerticalEllipsis from 'components/home/linkLibrary/components/CategoryVerticalEllipsis';
import SubCategoryVerticalEllipsis from 'components/home/linkLibrary/components/SubCategoryVerticalEllipsis';

export const LINKS = 'links';
export const SECOND_LEVEL_CATEGORY = 2;

export const LINK_LIBRARY_TYPES = {
  CATEGORY: 'category',
  SUBCATEGORY: 'subCategory',
  LINK: 'link'
};

export const TYPE_ATTRIBUTES = {
  [LINK_LIBRARY_TYPES.CATEGORY]: {
    headerClass: () => 'rst__row-header',
    titleClass: 'rst__row-titleCategory',
    html: (data, handleRowClick) => {
      return <CategoryVerticalEllipsis selectedCategory={data} handleRowClick={handleRowClick} />;
    }
  },
  [LINK_LIBRARY_TYPES.SUBCATEGORY]: {
    headerClass: level => `rst__row-category--header paddingNested-${level} `,
    titleClass: 'rst__row-category',
    html: data => {
      return <SubCategoryVerticalEllipsis selectedCategory={data} />;
    }
  },
  [LINK_LIBRARY_TYPES.LINK]: {
    headerClass: () => 'rst__row-header',
    titleClass: 'rst__row-subCategory',
    html: data => {
      return <LinkVerticalEllipsis selectedLink={data} />;
    }
  }
};
