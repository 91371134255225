import React from 'react';

import SkillPanel from './SkillPanel';
import Accordion from 'components/common/accordion';

const SkillSetV1 = props => {
  const {
    isHr,
    skills,
    onSkillAdd,
    activeSkill,
    searchQuery,
    onSkillCategoryClick,
    onSkillClick,
    rating = false,
    selectedSkills = [],
    skillCategories = [],
    permissions,
    ...rest
  } = props;

  const categoryTemplate = {};

  skillCategories.forEach(cat => (categoryTemplate[cat.name] = []));

  const parseSkills = skills =>
    skills.reduce((acc, curr) => {
      const activeCategory = curr?.skillCategory?.name;

      return {
        ...acc,
        [activeCategory]: acc[activeCategory] ? [...acc[activeCategory], curr] : [curr]
      };
    }, categoryTemplate);

  const filteredSkills = parseSkills(skills);
  const filteredSelectedSkills = parseSkills(selectedSkills);

  const data = Object.keys(filteredSkills).map(category => {
    const skills = rating
      ? filteredSkills[category]
      : filteredSkills[category].filter(skill => skill.name.toLowerCase().includes(searchQuery.toLowerCase()));

    return {
      title: category,
      value: skillCategories.find(sCategory => sCategory.name === category),
      onTitleClick: onSkillCategoryClick,
      content: () => (
        <SkillPanel
          isHr={isHr}
          rating={rating}
          skills={skills}
          selectedSkills={filteredSelectedSkills[category]}
          category={category}
          onClick={onSkillClick}
          activeSkill={activeSkill}
          handleAddClick={() => {
            onSkillAdd(skillCategories.find(cat => cat.name === category));
          }}
          permissions={permissions}
          {...rest}
        />
      ),
      info: skills.length
    };
  });

  return data.length ? (
    <Accordion data={data} className="pr-6" />
  ) : (
    <div className="empty-skill-set">{'Click on Add category to create a new skill category.'}</div>
  );
};

export default SkillSetV1;
