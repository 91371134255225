import moment from 'moment';
import classNames from 'classnames';
import { MdSearch } from 'react-icons/md';
import React, { useState, useEffect } from 'react';
import { Empty, Select } from '@vyaguta/vyaguta-ui';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import useQuery from 'hooks/useQuery';
import useTitle from 'hooks/useTitle';

import NoticeBoardCard from './NoticeBoardCard';

import { handleError } from 'utils/errorHandler';
import { getDefaultYearFilter } from 'utils/notice';

import * as noticeService from 'services/notices';

import { DEFAULT_PAGE } from 'constants/page';
import { YEAR_OPTIONS } from 'constants/options';
import { DEFAULT_PAGE_SIZE } from 'constants/appConstants';
import { getFormattedDate } from 'utils/date';

const NoticeBoard = props => {
  useTitle('Noticeboard');

  const currentYear = moment().year();

  const currentDate = getFormattedDate();

  const [query, setQuery] = useQuery(props);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filters, setFilters] = useState({ ...query });
  const [noticeList, setNoticeList] = useState([]);
  const [noticeCount, setNoticeCount] = useState();
  const [searchQuery, setSearchQuery] = useState(query.q ? query.q : '');

  const [currentNoticeList, setCurrentNoticeList] = useState([]);
  const [pastNoticeList, setPastNoticeList] = useState([]);
  const [sortedNoticeList, setSortedNoticeList] = useState([]);

  const applyFilter = filter => {
    setQuery({ ...query, ...filter });
  };

  const handleYearChange = option => {
    setFilters({ ...filters, year: option.value });
    setIsFilterChanged(true);
  };

  const handleSearchChange = event => {
    event.preventDefault();

    const textValue = event.target.value.trimLeft();

    setSearchQuery(textValue);
    setFilters({ ...filters, q: textValue });
    setIsFilterChanged(true);
  };

  const handleApplyFilter = () => {
    if (!isFilterChanged) {
      return;
    }

    applyFilter(filters);
    setIsFilterChanged(false);
    setIsFilterApplied(true);
  };

  const handleReset = () => {
    if (!isFilterApplied) {
      return;
    }

    setFilters({});
    setIsFilterApplied(false);
    setSearchQuery('');
    applyFilter({ year: YEAR_OPTIONS[1].value, q: '' });
  };

  const fetchNoticeBoard = async () => {
    try {
      setIsLoading(true);

      if (!query.year) {
        setQuery(getDefaultYearFilter());
        setFilters({ ...filters, ...getDefaultYearFilter() });

        return;
      }

      if (Number(query.year) !== currentYear) {
        setIsFilterApplied(true);
      }

      if (query.q) {
        setIsFilterApplied(true);
      }

      const appliedFilters = {
        page: DEFAULT_PAGE,
        size: DEFAULT_PAGE_SIZE,
        year: query.year,
        q: query.q
      };

      const { meta } = await noticeService.fetchNoticeBoard(appliedFilters);

      const { page, size, total } = meta;
      const totalPages = Math.ceil(total / size);

      setNoticeCount(total);

      const fetchAllPromise = [];

      for (let i = page; i <= totalPages; i++) {
        fetchAllPromise.push(
          noticeService.fetchNoticeBoard({
            page: i,
            size: DEFAULT_PAGE_SIZE,
            year: query.year,
            q: query.q
          })
        );
      }

      const remainingNoticeList = await Promise.all(fetchAllPromise);

      const formattedNoticeList = remainingNoticeList.reduce((acc, { data }) => [...acc, ...data], []);

      setNoticeList(formattedNoticeList);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNoticeBoard();
  }, [query.year, query.q]);

  useEffect(() => {
    if (!noticeList.length) {
      return;
    }

    setCurrentNoticeList(noticeList.filter(notice => currentDate >= notice.startDate && currentDate <= notice.endDate));

    setPastNoticeList(noticeList.filter(notice => !(currentDate >= notice.startDate && currentDate <= notice.endDate)));
  }, [noticeList]);

  useEffect(() => {
    setSortedNoticeList([...currentNoticeList, ...pastNoticeList]);
  }, [currentNoticeList, pastNoticeList]);

  return (
    <main>
      <div className="container">
        <div className={classNames('full-scope-card', 'mbpx-0')}>
          <div className="full-scope-card__header table-header name-wrap">
            <div className="d-flex">
              <h3 className="table-title">Noticeboard</h3>
            </div>
            <div className="d-flex align-items-center notice-board-filter custom-scroll-bar">
              <div className="search-wrapper notice-search d-flex align-items-center">
                <MdSearch className="search-icon" />
                <input
                  className="search-bar notice-search__input"
                  value={searchQuery}
                  type="text"
                  placeholder="Search notice"
                  onKeyDown={event => (event.key === 'Enter' ? handleApplyFilter() : undefined)}
                  onChange={event => handleSearchChange(event)}
                />
              </div>
              <div className="hpx-45 mlpx-5">
                <Select
                  isSearchable
                  options={YEAR_OPTIONS}
                  autoResize
                  onChange={handleYearChange}
                  value={YEAR_OPTIONS.find(opt => opt.value === +filters.year) || YEAR_OPTIONS[1]}
                  placeholder="Year"
                />
              </div>
              <div className="title-bar__filter-buttons d-flex align-items-center">
                <button
                  className={classNames('button m-10 ml-24 wpx-96', {
                    'button--primary': !isFilterChanged,
                    'button--secondary': isFilterChanged
                  })}
                  onClick={handleApplyFilter}
                >
                  Apply Filter
                </button>

                <button
                  className={classNames('button__reset', {
                    'button__reset--active': isFilterApplied
                  })}
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames('mtpx-24 mbpx-10 notice-board-container', {
            'notice-board-container--empty': !noticeCount && !isLoading
          })}
        >
          {isLoading ? (
            <SkeletonTheme baseColor="#e9edf0" highlightColor="#F0F3F5">
              <Skeleton width={152} height={16} />
              <div className="full-scope-card__content mtpx-16">
                <div>
                  {Array(7)
                    .fill(0)
                    .map((item, index) => (
                      <Skeleton className="notice-board-card-loader" key={index} />
                    ))}
                </div>
              </div>
            </SkeletonTheme>
          ) : noticeCount ? (
            <>
              <span className="notice-board__count">{`Showing ${noticeCount} notices of ${query.year}`}</span>
              <div className="full-scope-card__content mtpx-16">
                {sortedNoticeList?.map(noticeItem => (
                  <NoticeBoardCard notice={noticeItem} key={noticeItem.id} />
                ))}
              </div>
            </>
          ) : (
            <Empty message="Notices not found." />
          )}
        </div>
      </div>
    </main>
  );
};

export default NoticeBoard;
