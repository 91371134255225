import React from 'react';

import { FormSelect, RadioButton, DateSelector } from '../components';
import { generateReactSelectOptionsFromObject } from 'utils/reactSelect';

class EditDesignation extends React.Component {
  constructor(props) {
    super(props);
    const { selectedDesignation } = props;
    this.state = {
      selectedDesignation: { ...selectedDesignation },
      originalDesignation: selectedDesignation,
      showErrors: false
    };
  }

  componentWillReceiveProps(props) {
    const { selectedDesignation } = props;
    this.setState({
      selectedDesignation
    });
  }

  handleSaveClick = () => {
    const { errors } = this.props;
    if (!errors) this.props.handleSave();
    else
      this.setState({
        showErrors: true
      });
  };

  handleCancelClick = () => this.props.handleCancel(this.state.originalDesignation);

  handleChange = event => {
    const { name: changingKey, value: changingValue } = event.target;
    let changeTo = null;
    switch (changingKey) {
      case 'designation':
        changeTo = this.getDesinationForId(changingValue);
        break;
      case 'area':
        changeTo = this.getDesinationAreaForId(changingValue);
        break;
      default:
        changeTo = changingValue;
        break;
    }
    this.setState(
      {
        selectedDesignation: {
          ...this.state.selectedDesignation,
          [changingKey]: changeTo
        }
      },
      () => this.props.handleChange(this.state.selectedDesignation)
    );
  };

  getDesinationForId(id) {
    const { designations } = this.props;

    return designations.find(designation => designation.id === +id);
  }

  getDesinationAreaForId(id) {
    const { designationAreas } = this.props;

    return designationAreas.find(designationArea => designationArea.id === +id);
  }

  render() {
    const { designations, designationAreas, errors, isCreateForm } = this.props;

    const { selectedDesignation } = this.state;

    const designationSelectOptions = generateReactSelectOptionsFromObject(designations);
    const areaSelectOptions = generateReactSelectOptionsFromObject(designationAreas);
    return (
      <div className="card-form">
        <FormSelect
          label="Designation"
          name="designation"
          isMandatory={true}
          isDisabled={false}
          handleChange={this.handleChange}
          value={selectedDesignation.designation && selectedDesignation.designation.id}
          error={this.state.showErrors && errors && errors.designation && 'Please enter Designation'}
          options={designationSelectOptions}
        />
        <RadioButton
          name="area"
          label="Area"
          isMandatory={true}
          isDisabled={false}
          canReset={true}
          selectedValue={selectedDesignation.area && selectedDesignation.area.id}
          selectOptions={areaSelectOptions}
          error={this.state.showErrors && errors && errors.area && 'Please enter Area'}
          handleChange={this.handleChange}
        />
        <DateSelector
          name="transitionDate"
          label="Transition Date"
          placeholderText="Pick a Transition Date"
          isMandatory={true}
          value={selectedDesignation.transitionDate}
          error={this.state.showErrors && errors && errors.transitionDate}
          handleChange={this.handleChange}
        />

        <div className="mt-30">
          <button type="submit" onClick={this.handleSaveClick} className="btn btn--secondary f-left card-button mr-10 ">
            Save
          </button>

          <button
            type="button"
            onClick={this.handleCancelClick}
            disabled={isCreateForm}
            className="btn btn--primary f-right card-button"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}
export default EditDesignation;
