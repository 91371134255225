import React from 'react';

// Components
import InputWrapperLabel from './components/InputWrapperLabel';

const InputWrapper = ({ label, error, isMandatory, children, className }) => {
  return (
    <div className={`input-wrapper ${className || ''}`}>
      <InputWrapperLabel label={label} isMandatory={isMandatory} />
      {children}

      {error && <span className="input-wrapper__error">{error}</span>}
    </div>
  );
};

export default InputWrapper;
