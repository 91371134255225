import React from 'react';

import { N_OF_MEDIAN_TIME } from 'constants/dashboard';

import { medianClockIn } from 'assets/images';
import { convertTimeToAMPMWithDots } from 'utils/time';

export function MedianClockInTime(props) {
  const { clockInTime } = props;

  const clockinText = `Last ${N_OF_MEDIAN_TIME} days median clock-in time`;

  return (
    <div className="flex gap-px-6 align-items-center median-time-container">
      <figure className="hpx-21 wpx-16">
        <img src={medianClockIn} alt="Clock-in" height={21} width={16} />
      </figure>

      <p className="font-size-12 flex align-items-center">
        {clockinText}
        <span className="bulb bulb--md bulb--grey-40 mrpx-4 mlpx-4" />
        WFO
        <span className="bulb bulb--md bulb--grey-40 mrpx-4 mlpx-4" />
        <span className="font-weight-semi-bold">{(clockInTime && convertTimeToAMPMWithDots(clockInTime)) || '-'}</span>
      </p>
    </div>
  );
}
