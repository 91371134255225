import moment from 'moment';
import React, { useEffect, useCallback, useState } from 'react';

import history from 'utils/history';
import useTitle from 'hooks/useTitle';
import HolidayForm from './HolidayForm';
import * as routes from 'constants/routes';
import { handleError } from 'utils/errorHandler';
import * as holidayService from 'services/holidays';
import Loading from 'components/common/loading/Loading';
import BackButton from 'components/common/button/BackButton';

const CreateEditHoliday = props => {
  const holidayId = props.match.params?.id;
  const title = holidayId ? 'Edit Holiday' : 'Add Holiday';
  const setTitle = useTitle();

  useEffect(() => {
    setTitle(holidayId ? 'Edit Holiday' : 'Add Holiday');
  }, []);

  const [loading, setLoading] = useState(false);
  const [holiday, setHoliday] = useState(null);

  const fetchHoliday = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await holidayService.fetchById(holidayId);
      setHoliday({
        ...data,
        date: moment(data.date)
      });
    } catch (err) {
      handleError(err);
      history.push(routes.HOLIDAYS);
    } finally {
      setLoading(false);
    }
  }, [holidayId]);

  useEffect(() => {
    if (!holidayId) {
      return;
    }

    fetchHoliday();
  }, [holidayId, fetchHoliday]);

  return (
    <div className="full-scope-card">
      <div className="full-scope-card__header table-header name-wrap">
        <div className="d-flex flex-row">
          <BackButton />
          <div className="d-flex flex-row">
            <div className="table-header">
              <h3 className="text-bold">{title}</h3>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="loading-min-height">
          <Loading />
        </div>
      ) : (
        <HolidayForm holiday={holiday} />
      )}
    </div>
  );
};

export default CreateEditHoliday;
