import { FiInfo } from 'vyaguta-icons/fi';
import { useEffect, useMemo, useState } from 'react';
import { UiExternalLinkAlt } from 'vyaguta-icons/ui';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import ProjectLogo from 'components/common/projectLogo';
import Info from 'components/common/inputWrapper/components/Info';

import useRefetchOnTabActive from 'hooks/useRefetchOnTabActive';

import { handleError } from 'utils/errorHandler';
import { getUrlWithQueryParams } from 'utils/queryParam';
import { getProjectLogoUrl, interpolate } from 'utils/string';

import * as userService from 'services/user';

import {
  CANCEL,
  OFFBOARDED,
  VIEW_PROJECT,
  YES_TERMINATE,
  OFFBOARDING_COMPLETE_MESSAGE,
  TERMINATION_CONFIRMATION_MESSAGE,
  TERMINATION_CONFIRMATION_MESSAGE_WITHOUT_OFFBOARD
} from 'constants/appConstants';
import { PROJECT_OFFBOARD_URL } from 'constants/endpoints';

function ProjectOffboardIcon({ projectLink, isUserOffboarded }) {
  if (isUserOffboarded) {
    return (
      <div className="offboard d-flex align-items-center gap-x-8">
        <span className="offboard__dot" />
        <span className="font-14 regular">{OFFBOARDED}</span>
      </div>
    );
  }

  return (
    <span
      className="cursor-pointer project-name__link regular font-12 d-flex gap-x-4"
      onClick={() => window.open(interpolate(projectLink))}
    >
      {VIEW_PROJECT}
      <div>
        <UiExternalLinkAlt size={12} className="external-link mbpx-1" />
      </div>
    </span>
  );
}

function ProjectCard({ id, name, isLocked, username }) {
  const projectUrl = interpolate(PROJECT_OFFBOARD_URL, { id: id });

  const projectLink = getUrlWithQueryParams(projectUrl, { q: `${username}` });

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center project-name gap-px-12 project-card">
        <ProjectLogo logoUrl={getProjectLogoUrl(id)} name={name} size="small" className="mr-3x" />
        {name}
      </div>
      <ProjectOffboardIcon projectLink={projectLink} isUserOffboarded={isLocked} />
    </div>
  );
}

export function UserAllocationLists({ userId, setIsOffboardingComplete, firstName, lastName }) {
  const [allocations, setAllocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllocations = async userId => {
    try {
      setIsLoading(true);

      const data = await userService.fetchUserCurrentAllocations(userId);

      setAllocations(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllocations(userId);
  }, [userId]);

  useRefetchOnTabActive(() => fetchAllocations(userId, 'visible'));

  const projectAllocations = useMemo(() => allocations.filter(item => item.project.id), [allocations]);
  const isOffboardCompleted = useMemo(
    () => Boolean(projectAllocations.length) && projectAllocations.every(item => item.isLocked),
    [projectAllocations]
  );

  const isAlreadyOffboarded = !isLoading && projectAllocations.length === 0;

  useEffect(() => {
    setIsOffboardingComplete(isOffboardCompleted || isAlreadyOffboarded);
  }, [isOffboardCompleted, setIsOffboardingComplete, isLoading, projectAllocations]);

  if (isLoading) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  if (!projectAllocations.length) {
    return (
      <>
        {interpolate(TERMINATION_CONFIRMATION_MESSAGE_WITHOUT_OFFBOARD, {
          firstName,
          lastName
        })}
      </>
    );
  }

  return (
    <div className="d-flex flex-column gap-px-12">
      <div>
        {interpolate(TERMINATION_CONFIRMATION_MESSAGE, {
          firstName,
          lastName
        })}
      </div>
      <div className="d-flex flex-column gap-px-8">
        <div className="d-flex flex-column gap-px-12">
          {projectAllocations.map(({ project, isLocked }) => (
            <ProjectCard id={project.id} name={project.name} isLocked={isLocked} username={firstName} />
          ))}
        </div>
        {isOffboardCompleted && <Info message={OFFBOARDING_COMPLETE_MESSAGE} className="mt-4 mbpx-0" />}
      </div>
    </div>
  );
}

const TerminationConfirmationModal = ({
  isOpen,
  employeeId,
  message,
  title,
  onConfirm,
  onCancel,
  firstName,
  lastName
}) => {
  const [isOffboardingComplete, setIsOffboardingComplete] = useState(false);

  return (
    <LFModal
      isOpen={isOpen}
      renderFooter={true}
      modalStatusIcon={<FiInfo size={18} color="#F44336" className="mtpx-6" />}
      title={title}
      className="delete-modal pr-30 termination-modal"
      buttons={[
        {
          text: YES_TERMINATE,
          type: 'red',
          className: isOffboardingComplete ? 'mr-12 delete__btn mtpx-8' : 'mr-12  mtpx-8 disabled__btn',
          close: true,
          showLoading: true,
          disabled: !isOffboardingComplete,
          onClick: onConfirm
        },
        {
          text: CANCEL,
          type: 'outlined-grey',
          className: 'py-8 px-12 mtpx-8',
          onClick: onCancel
        }
      ]}
      htmlContent={
        <UserAllocationLists
          firstName={firstName}
          lastName={lastName}
          userId={employeeId}
          setIsOffboardingComplete={setIsOffboardingComplete}
        />
      }
    />
  );
};

export default TerminationConfirmationModal;
