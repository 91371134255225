import React from 'react';
import classNames from 'classnames';

import { warning } from 'assets/images';

import { POTENTIAL_END_DATE_HIGHLIGHT_MESSAGE } from 'constants/profile';

import Pills from './pills';
import TooltipPopOver from './TooltipPopOver';

function ExpiredPill(props) {
  const { text, fontSize = 10, isSelf = true, className } = props;

  const tooltipText = isSelf
    ? POTENTIAL_END_DATE_HIGHLIGHT_MESSAGE.SELF
    : POTENTIAL_END_DATE_HIGHLIGHT_MESSAGE.ACTION_TAKER;

  return (
    <TooltipPopOver
      html={
        <div>
          <p className="font-size-12">{tooltipText}</p>
        </div>
      }
    >
      <Pills
        className={classNames(
          'd-flex align-items-center expired-pills--tertiary-orange-10 brpx-12 expired-pills__warning-img px-8 py-4',
          className
        )}
        textClassName="mr-4"
        fontSize={fontSize}
        color="grey.70"
        text={text}
        icon={<img className="icon" src={warning} alt="warning" />}
      />
    </TooltipPopOver>
  );
}

export default ExpiredPill;
