/**
 * Gets corresponding index for serial number from table.
 *
 * @param {Object} props : props passed to Cell in table
 * @param {Int} pageSize : current size of page
 * @param {Object} pageData : data fetched from meta for page
 *
 * @returns {String}
 */
export function getIndexOfSN(props, pageSize, pageData) {
  const index = props.index + 1 + pageSize * (pageData.page - 1);

  return index;
}
