import { getRequiredErrorLabel } from 'utils/string';
import * as Yup from 'yup';

const activateEmployeeSchema = Yup.object({
  designationId: Yup.number()
    .required(getRequiredErrorLabel('designation'))
    .typeError(getRequiredErrorLabel('designation')),
  areaId: Yup.number()
    .required(getRequiredErrorLabel('area'))
    .typeError(getRequiredErrorLabel('area')),

  engagementStatusId: Yup.number()
    .required(getRequiredErrorLabel('employment status'))
    .typeError(getRequiredErrorLabel('employment status')),

  transitionDate: Yup.date()
    .required(getRequiredErrorLabel('transition date'))
    .typeError('Invalid date'),

  departmentId: Yup.number()
    .required(getRequiredErrorLabel('department'))
    .typeError(getRequiredErrorLabel('department')),

  leaveIssuerId: Yup.number()
    .required(getRequiredErrorLabel('leave issuer'))
    .typeError(getRequiredErrorLabel('leave issuer')),

  appraiserId: Yup.number()
    .required(getRequiredErrorLabel('appraiser'))
    .typeError(getRequiredErrorLabel('appraiser')),

  coAppraiserId: Yup.number().nullable()
});

export default activateEmployeeSchema;
