import { NEPAL } from './countries';
import { EMPLOYEE_DESIGNATION } from './employeeDesignation';

export const KPI_CARD_NAME = 'kpi';
export const ASK_US_ANYTHING_CARD_NAME = 'ask';
export const KPI_TITLE = 'My KPIs';
export const ASK_US_ANYTHING_TITLE = 'Ask & Share Us Anything';
export const KPI_DESCRIPTION = 'Set your Goals and mark your achievements';
export const EVENT_NOT_LOADED_TEXT = `Sorry, couldn't load events & holidays. Please try again.`;
export const ASK_US_ANYTHING_DESCRIPTION = 'Ask us anything and get it answered in company all hands.';

export const GROUP_BY_CATEGORY = 'category';
export const CLEAR_SEARCH_CHARACTER = 'Backspace';
export const MINIMUM_SEARCH_CHARACTERS = 3;
export const EMPTY_SEARCH_RESULTS_MESSAGE = 'Sorry, we cannot find your search.';
export const QUICK_LINK_NOT_LOADED = "Sorry, quick links couldn't be fetched. Please try again!";
export const EMPTY_ALLOCATION_CHART_MESSAGE =
  'You have not been allocated to any projects/area, please talk to your project manager or your team manager.';

export const CHANGE_APPRAISER = 'Change Appraiser';
export const REQUEST_TO_CHANGE_APPRAISERS = 'Request to Change Appraisers';
export const REQUEST_FOR_CHANGE_OF_APPRAISERS = 'Request for Change of Appraisers';
export const CHANGE_LEAVE_ISSUER = 'Change Leave Issuer';
export const CHANGE_APPRAISER_NOTE =
  'Appraisee’s Project Manager, Team Manager and the current appraisers will be notified via email.';

export const projectColors = ['#2ECC71', '#f2994a', '#29B6F6', '#f5af00', '#102b7b'];

export const EVENT_TYPE = {
  1: 'Holiday',
  2: 'Event'
};

export const OCCASION_TYPE = {
  HOLIDAY: 'Holiday',
  EVENT: 'Event'
};

export const DEFAULT_ALLOCATION_HR = 40;

export const TEAM_RELATION = {
  TEAM_MEMBER: 'Team Member'
};

export const HAS_LEAVE_ISSUER_CHANGE_ACCESS = {
  'Leave Issuer': true,
  'Team Manager': true,
  'Project Manager': true,
  'Team Lead': true
};

export const WORK_STATUS = {
  WFO: 'WFO',
  WFH: 'WFH',
  LEAVE: 'Leaves'
};

export const N_OF_MEDIAN_TIME = 30;
export const MAX_CHARACTERS_MEDIAN_HISTORY_TABLE = 23;

export const INCLUDED_COUNTRIES_FOR_WORKSHIFT = [NEPAL];
export const EXCLUDED_DESIGNATION_FOR_WORKSHIFT = [EMPLOYEE_DESIGNATION.SUPPORT];

export const HOLIDAY = 'Holiday';

export const historyButtonText = `View history`;
export const historyDescriptionText = `Last ${N_OF_MEDIAN_TIME} days history of your attendance`;
