import React from 'react';
import isEqual from 'lodash/isEqual';

import { arrayMove } from '@dnd-kit/sortable';
import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';

const DndContextComponent = ({ children, ...props }) => {
  const { listData, onDragEnd } = props;

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = event => {
    if (!event.over) return;

    const { active, over } = event;

    const oldIndex = listData.findIndex(item => item.id === active.id);
    const newIndex = listData.findIndex(item => item.id === over.id);

    const updatedList = arrayMove(listData, oldIndex, newIndex);

    if (!isEqual(updatedList, listData)) {
      onDragEnd(updatedList);
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      {children}
    </DndContext>
  );
};

export default DndContextComponent;
