import { FiPlusCircle } from 'react-icons/fi';
import React, { useEffect, useMemo, useState, useCallback } from 'react';

import { getUniqueKey } from 'utils/string';
import { getMonthsLaterDate } from 'utils/date';

import AddEmployeeStatusDetails from './AddEmployeeStatusDetails';
import { PROBATION, FIXED_TERM_CONTRACT } from 'constants/employeeStatus';

export const getCheckIn = (statusDetails = []) => {
  return `Check-in ${statusDetails.filter(status => status.category.includes('Check-in')).length + 1}`;
};

const EmployeeStatusDetails = ({
  employeeStatusPayload,
  setEmployeeStatusPayload,
  handleBlur,
  touched,
  error,
  editIndex
}) => {
  const getDefaultValue = useCallback(name => {
    if (name === PROBATION) {
      return [
        {
          keyId: getUniqueKey(),
          category: 'Mid Probation',
          date: getMonthsLaterDate(employeeStatusPayload?.transitionDate, 3)
        },
        {
          keyId: getUniqueKey(),
          category: 'End Probation',
          date: getMonthsLaterDate(employeeStatusPayload?.transitionDate, 6)
        }
      ];
    }

    if (name === FIXED_TERM_CONTRACT) {
      return [
        {
          keyId: getUniqueKey(),
          category: getCheckIn()
        }
      ];
    }
  }, []);

  const initialEmpStatusDetails = useMemo(() => getDefaultValue(employeeStatusPayload.engagementStatus.name), [
    employeeStatusPayload.engagementStatus.name,
    getDefaultValue
  ]);

  const [empStatusDetails, setEmpStatusDetails] = useState(
    employeeStatusPayload?.empStatusDetails?.map(item => ({
      ...item,
      keyId: getUniqueKey()
    })) || getDefaultValue(employeeStatusPayload.engagementStatus.name)
  );

  useEffect(() => {
    if (typeof editIndex === 'number') {
      return;
    }

    setEmpStatusDetails(initialEmpStatusDetails);
  }, [editIndex, initialEmpStatusDetails]);

  useEffect(() => {
    setEmployeeStatusPayload(payload => ({
      ...payload,
      empStatusDetails: empStatusDetails.map(({ id, category, date }) => ({
        id,
        category,
        date
      }))
    }));
  }, [empStatusDetails, setEmployeeStatusPayload]);

  const onAdd = () => {
    setEmpStatusDetails(prev => [
      ...prev,
      {
        keyId: getUniqueKey(),
        category:
          employeeStatusPayload.engagementStatus.name === FIXED_TERM_CONTRACT ? getCheckIn(empStatusDetails) : ''
      }
    ]);
  };

  const handleDelete = id => {
    const filteredArray = empStatusDetails.filter(item => {
      return item.keyId !== id;
    });

    setEmpStatusDetails(filteredArray);
  };

  return (
    <div className="emp-status-details-form emp-status-details-form--add-leapfrogger custom-scroll-bar">
      <div className="emp-status-details-form__heading">Add {employeeStatusPayload.engagementStatus.name} Category</div>

      <div className="emp-status-details-form__body">
        {empStatusDetails?.map((item, index) => (
          <AddEmployeeStatusDetails
            key={item.keyId}
            item={item}
            setEmpStatusDetails={setEmpStatusDetails}
            handleDelete={() => handleDelete(item.keyId)}
            handleBlur={handleBlur}
            error={error.empStatusDetails && error.empStatusDetails[index]}
            empStatusDetails={empStatusDetails}
            employeeStatusPayload={employeeStatusPayload}
            touched={touched}
            index={index}
            type={employeeStatusPayload.engagementStatus.name}
            editIndex={editIndex}
          />
        ))}
      </div>

      <div className="emp-status-details-form__add-button" onClick={() => onAdd()}>
        <FiPlusCircle className="emp-status-details-form__add-button--plus-icon" />
        <p className="emp-status-details-form__button-label">Add Another Category</p>
      </div>
    </div>
  );
};

export default EmployeeStatusDetails;
