import { createAction } from 'redux-actions';

import * as meService from 'services/me';

export const FETCH_INFO = 'FETCH_INFO';
export const FETCH_INFO_PENDING = 'FETCH_INFO_PENDING';
export const FETCH_INFO_REJECTED = 'FETCH_INFO_REJECTED';
export const FETCH_INFO_FULFILLED = 'FETCH_INFO_FULFILLED';

export const UPDATE_NOTICES = 'UPDATE_NOTICES';
export const DELETE_NOTICES = 'DELETE_NOTICES';

export const UPDATE_REMINDERS = 'UPDATE_REMINDERS';
export const DELETE_REMINDERS = 'DELETE_REMINDERS';

export const fetchInfo = createAction(FETCH_INFO, meService.fetchInfo);

export const updateNotices = payload => {
  return { type: UPDATE_NOTICES, payload };
};

export const deleteNotices = payload => {
  return { type: DELETE_NOTICES, payload };
};

export const updateReminders = payload => {
  return { type: UPDATE_REMINDERS, payload };
};

export const deleteReminders = payload => {
  return { type: DELETE_REMINDERS, payload };
};
