import React from 'react';

import ImageUpload from './ImageUpload';
import ImageUploading from 'react-images-uploading';
import { MAX_IMAGE_FILE_SIZE, MAX_IMAGE_NUMBER } from 'constants/createEmployeeForm';

const ImageUploader = props => {
  const { onChange, onBlur, name, value, isButtonsVisible, error } = props;

  const maxNumber = MAX_IMAGE_NUMBER;
  const maxFileSize = MAX_IMAGE_FILE_SIZE;
  const handleImageChange = imageList => {
    onChange({
      target: {
        name,
        value: imageList
      }
    });
  };

  const handleImageBlur = () => onBlur({ target: name });

  return (
    <ImageUploading
      value={value}
      onChange={handleImageChange}
      onBlur={handleImageBlur}
      maxNumber={maxNumber}
      maxFileSize={maxFileSize}
      dataURLKey="data_url"
    >
      {props => {
        const { imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps, errors } = props;

        return (
          <ImageUpload
            isButtonsVisible={isButtonsVisible}
            imageSelected={imageList.length > 0}
            imageList={imageList}
            onImageUpload={onImageUpload}
            onImageUpdate={onImageUpdate}
            onImageRemove={onImageRemove}
            isDragging={isDragging}
            dragProps={dragProps}
            onChange={handleImageChange}
            errors={errors}
            error={error}
          />
        );
      }}
    </ImageUploading>
  );
};

export default ImageUploader;
