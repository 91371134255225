import React from 'react';

const CalendarCross = ({ color = '#B3B3B3', size = 24, viewBox = '0 0 24 24', bgColor = 'none' }) => {
  return (
    <svg
      id="calendar-cross"
      width={size}
      height={size}
      viewBox={viewBox}
      fill={bgColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="calendar-cross-path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3H19C19.7956 3 20.5587 3.31607 21.1213 3.87868C21.6839 4.44129 22 5.20435 22 6V20C22 20.7956 21.6839 21.5587 21.1213 22.1213C20.5587 22.6839 19.7956 23 19 23H5C4.20435 23 3.44129 22.6839 2.87868 22.1213C2.31607 21.5587 2 20.7956 2 20V6C2 5.20435 2.31607 4.44129 2.87868 3.87868C3.44129 3.31607 4.20435 3 5 3H6V2C6 1.73478 6.10536 1.48043 6.29289 1.29289C6.48043 1.10536 6.73478 1 7 1C7.26522 1 7.51957 1.10536 7.70711 1.29289C7.89464 1.48043 8 1.73478 8 2V3H16V2C16 1.73478 16.1054 1.48043 16.2929 1.29289C16.4804 1.10536 16.7348 1 17 1C17.2652 1 17.5196 1.10536 17.7071 1.29289C17.8946 1.48043 18 1.73478 18 2V3ZM19.7071 20.7071C19.8946 20.5196 20 20.2652 20 20V11H4V20C4 20.2652 4.10536 20.5196 4.29289 20.7071C4.48043 20.8946 4.73478 21 5 21H19C19.2652 21 19.5196 20.8946 19.7071 20.7071ZM4 9H20V6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5H18V6C18 6.26522 17.8946 6.51957 17.7071 6.70711C17.5196 6.89464 17.2652 7 17 7C16.7348 7 16.4804 6.89464 16.2929 6.70711C16.1054 6.51957 16 6.26522 16 6V5H8V6C8 6.26522 7.89464 6.51957 7.70711 6.70711C7.51957 6.89464 7.26522 7 7 7C6.73478 7 6.48043 6.89464 6.29289 6.70711C6.10536 6.51957 6 6.26522 6 6V5H5C4.73478 5 4.48043 5.10536 4.29289 5.29289C4.10536 5.48043 4 5.73478 4 6V9ZM14.844 19.0542C14.4336 19.4775 13.7683 19.4775 13.3579 19.0542L12 17.6532L10.642 19.0542C10.2316 19.4776 9.56629 19.4776 9.15593 19.0542C8.74557 18.6309 8.74557 17.9445 9.15593 17.5212L10.5139 16.1202L9.34817 14.9176C8.93782 14.4942 8.93782 13.8078 9.34817 13.3845C9.75853 12.9612 10.4239 12.9612 10.8342 13.3845L12 14.5872L13.1657 13.3845C13.5761 12.9612 14.2414 12.9612 14.6517 13.3845C15.0621 13.8079 15.0621 14.4942 14.6517 14.9176L13.486 16.1202L14.844 17.5211C15.2543 17.9445 15.2543 18.6308 14.844 19.0542Z"
        fill={color}
      />
    </svg>
  );
};

export default CalendarCross;
