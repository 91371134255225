import * as Yup from 'yup';

export const addRoleSchema = Yup.object({
  userId: Yup.number()
    .required('Please select user')
    .typeError('Please select user'),
  roles: Yup.array()
    .of(
      Yup.object({
        id: Yup.number().required(),
        name: Yup.string().required()
      })
    )
    .required('Please select role(s)')
    .min(1)
});
