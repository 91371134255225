import { queryClient } from 'index';
import React, { useState, useEffect } from 'react';

import isEqual from 'lodash/isEqual';

import { CSS } from '@dnd-kit/utilities';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { findNodeLevel, formattedUpdateWeightBody } from 'utils/linkLibrary';

import { useUpdateLinkLibraryMutation } from 'hooks/query/useQuickLinksQuery';

import { LINK_LIBRARY_TYPES } from 'constants/linkLibrary';

import LinkTable from './LinkTable';
import SortableHeaderRow from './SortableHeaderRow';
import DndContextComponent from 'components/common/sortableDnD/DndContextComponent';

const SortableTreeItem = ({ id, data, nodeLevel = 0 }) => {
  const [listData, setListData] = useState(data);
  const [listChildrenData, setListChildrenData] = useState([...data.children]);

  useEffect(() => {
    if (!isEqual(data, listData)) {
      setListChildrenData([...data.children]);
    }
  }, [JSON.stringify(data.children)]);

  const [treeData, setTreeData] = useState([]);
  const _treeData = queryClient.getQueryData('quickLinks');
  useEffect(() => {
    if (!isEqual(_treeData, treeData)) {
      setTreeData([..._treeData]);
    }
  }, [_treeData]);

  const { mutate } = useUpdateLinkLibraryMutation();

  const { transform, transition, attributes, setNodeRef, listeners, setActivatorNodeRef } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const { SUBCATEGORY, LINK } = LINK_LIBRARY_TYPES;

  const onDragEnd = data => {
    const bodyPayload = formattedUpdateWeightBody(data);

    setListChildrenData(data);
    mutate(bodyPayload);
  };

  return (
    <div className="direct-row" ref={setNodeRef} style={style}>
      <SortableHeaderRow
        listData={listData}
        setListData={setListData}
        attributes={attributes}
        listeners={listeners}
        setActivatorNodeRef={setActivatorNodeRef}
        nodeLevel={nodeLevel}
      />
      {listChildrenData?.length > 0 && (
        <div className={!listData?.isExpanded ? 'rst__content' : 'rst__content--active'}>
          <DndContextComponent listData={listChildrenData} onDragEnd={onDragEnd}>
            <ul>
              <SortableContext items={listChildrenData.map(({ id }) => id)} strategy={verticalListSortingStrategy}>
                {listChildrenData.map(item => {
                  if (item?.type === SUBCATEGORY) {
                    return (
                      <li key={`${item.id}-${item.name}`}>
                        <SortableTreeItem
                          id={item.id}
                          data={item}
                          nodeLevel={findNodeLevel(treeData, { id: item.id, type: item.type })}
                        />
                      </li>
                    );
                  } else if (item?.type === LINK) {
                    return (
                      <li key={item.id}>
                        <LinkTable
                          links={item.children}
                          nodeLevel={findNodeLevel(treeData, { id: item.categoryId, type: item.type })}
                          // `${item.categoryId}-${item.type}`
                        />
                      </li>
                    );
                  }
                })}
              </SortableContext>
            </ul>
          </DndContextComponent>
        </div>
      )}
    </div>
  );
};

export default SortableTreeItem;
