import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import AvatarComponent from 'components/common/avatar/AvatarComponent';
import useEmployeeDebounceOptions from 'hooks/useEmployeeDebounceOptions';
import FormDropdown from 'components/common/inputWrapper/components/Select';
import FormDateSelect from 'components/common/inputWrapper/components/DateSelect';
import DebounceFormDropdown from 'components/common/debounceFormDropdown/DebounceFormDropdown';

import * as userService from 'services/user';
import * as departmentService from 'services/department';
import * as areaServices from 'services/designationArea';
import * as designationService from 'services/designation';
import * as engagementStatusService from 'services/engagementStatus';

import { TERMINATED } from 'constants/employeeStatus';
import activateEmployeeSchema from 'schemas/activateEmployeeSchema';

import { success } from 'utils/toast';
import { getFormattedDate } from 'utils/date';
import { extractFullName } from 'utils/string';
import { handleError } from 'utils/errorHandler';

function ActivationFormModal({ employee, afterSubmit }) {
  const [isLoading, setIsLoading] = useState({});

  const leaveIssuerDebounceOptions = useEmployeeDebounceOptions();
  const appraiserDebounceOptions = useEmployeeDebounceOptions();
  const coAppraiserDebounceOptions = useEmployeeDebounceOptions();

  const initialValues = {
    leaveIssuerId: null,
    areaId: null,
    designationId: null,
    departmentId: null,
    appraiserId: null,
    coAppraiserId: null,
    engagementStatusId: null,
    transitionDate: getFormattedDate()
  };

  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [areas, setAreas] = useState([]);
  const [engagementStatus, setEngagementStatus] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading({
        ...isLoading,
        department: true,
        designation: true,
        area: true
      });

      const departmentPromise = departmentService.fetchAll().then(({ data }) => {
        setIsLoading({ ...isLoading, department: false });

        return data;
      });

      const designationPromise = designationService.fetchAll().then(({ data }) => {
        setIsLoading({ ...isLoading, designation: false });

        return data;
      });

      const areaPromise = areaServices.fetchAll().then(({ data }) => {
        setIsLoading({ ...isLoading, area: false });

        return data;
      });

      const engagementStatusPromise = engagementStatusService.fetchAll().then(({ data }) => {
        setIsLoading({ ...isLoading, engagementStatus: false });

        return data;
      });

      try {
        const [departments, designations, areas, engagementStatus] = await Promise.all([
          departmentPromise,
          designationPromise,
          areaPromise,
          engagementStatusPromise
        ]);

        setDepartments(departments);
        setDesignations(designations);
        setAreas(areas);
        setEngagementStatus(engagementStatus.filter(({ name }) => name !== TERMINATED));
      } catch (err) {
        handleError(err);
      } finally {
        setIsLoading({});
      }
    })();
  }, []);

  const designationOptions = useMemo(
    () => designations.map(designation => ({ label: designation?.name, value: designation.id })),
    [designations]
  );

  const areaOptions = useMemo(() => areas.map(area => ({ label: area.name, value: area.id })), [areas]);

  const departmentOptions = useMemo(
    () => departments.map(department => ({ label: department.name, value: department.id })),
    [departments]
  );

  const engagementStatusOptions = useMemo(
    () => engagementStatus.map(status => ({ label: status.name, value: status.id })),
    [engagementStatus]
  );

  const handleSubmit = async (values, actions) => {
    try {
      const { data } = await userService.activateUser(employee.id, values);

      success({ title: 'Success', message: 'Employee activated successfully' });

      if (afterSubmit) {
        afterSubmit(data);
      }
    } catch (err) {
      handleError(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  if (!afterSubmit) {
    return null;
  }

  return (
    <LFModal
      closeButton={true}
      interactive={true}
      htmlContent={
        <Formik
          validationSchema={activateEmployeeSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={({ values, touched, handleSubmit, handleChange, handleBlur, errors, isSubmitting }) => (
            <form>
              <div className="office-information-form__full-width">
                <div className="office-information-form__full-width__space">
                  <FormDropdown
                    value={values.designationId}
                    name="designationId"
                    label="Designation"
                    isMandatory
                    error={touched.designationId && errors.designationId}
                    options={designationOptions}
                    isLoading={isLoading.designation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="office-information-form__full-width__space">
                  <FormDropdown
                    name="areaId"
                    label="Area"
                    isMandatory
                    error={touched.areaId && errors.areaId}
                    options={areaOptions}
                    isLoading={isLoading.area}
                    value={values.areaId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="office-information-form__full-width">
                <div className="office-information-form__full-width__space">
                  <FormDropdown
                    name="engagementStatusId"
                    label="Employment Status"
                    isMandatory
                    classNamePrefix="lf-select"
                    error={touched.engagementStatusId && errors.engagementStatusId}
                    options={engagementStatusOptions}
                    value={values.engagementStatusId}
                    isLoading={isLoading.engagementStatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="office-information-form__full-width__space">
                  <FormDateSelect
                    name="transitionDate"
                    label="Transition Date"
                    isMandatory
                    error={touched.transitionDate && errors.transitionDate}
                    value={values.transitionDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <FormDropdown
                name="departmentId"
                label="Department"
                isMandatory
                isClearable
                error={touched.departmentId && errors.departmentId}
                options={departmentOptions}
                value={values.departmentId}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <DebounceFormDropdown
                name="leaveIssuerId"
                label="Leave Issuer"
                isMandatory
                isClearable
                error={touched.leaveIssuerId && errors.leaveIssuerId}
                value={values.leaveIssuerId}
                debounceOptions={leaveIssuerDebounceOptions}
                iconComponent={AvatarComponent}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <DebounceFormDropdown
                name="appraiserId"
                label="Appraiser"
                error={touched.appraiserId && errors.appraiserId}
                isMandatory
                isClearable
                debounceOptions={appraiserDebounceOptions}
                iconComponent={AvatarComponent}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <DebounceFormDropdown
                name="coAppraiserId"
                label="Co-Appraiser"
                isClearable
                error={touched.coAppraiserId && errors.coAppraiserId}
                debounceOptions={coAppraiserDebounceOptions}
                iconComponent={AvatarComponent}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <div className="d-flex">
                <button
                  type="submit"
                  className="btn btn--primary margin-left-auto p-relative"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Activate
                  {isSubmitting && <Loading />}
                </button>
              </div>
            </form>
          )}
        />
      }
      title={{ text: `Activate ${extractFullName(employee, true)}`, type: 'info' }}
      TriggerBtn={({ onClick }) => (
        <button onClick={onClick} className="btn btn--outlined-primary order-1 momt-10">
          Activate Leapfrogger
        </button>
      )}
    />
  );
}

export default ActivationFormModal;
