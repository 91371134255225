import Select from 'react-select';
import React, { useState } from 'react';

import SkillsSelectorV1 from './SkillsSelectorV1';
import { generateReactSelectOptionsFromObject } from 'utils/reactSelect';

const ResourceFilterV1 = ({
  loadingSkills,
  skills,
  loadingDesignations,
  designations,
  initialState,
  onFilter,
  onClear
}) => {
  const initialFilterState = { ...initialState };

  if (!initialFilterState.skill || !initialFilterState.skill.length) {
    initialFilterState.skill = [null];
    initialFilterState.rating = [0];
  }

  const [filterState, setFilterState] = useState(initialFilterState);
  const [error, setError] = useState('');

  const handleChange = (name, value) => {
    setFilterState({ ...filterState, [name]: value });

    setError('');
  };

  const handleSkillChange = (index, value) => {
    const newFilterState = { ...filterState };

    if (newFilterState.skill.length >= index + 1) {
      newFilterState.skill[index] = value;
    }

    setFilterState(newFilterState);
    setError('');
  };

  const handleRatingChange = (index, value) => {
    const newFilterState = { ...filterState };

    if (newFilterState.rating.length >= index + 1) {
      newFilterState.rating[index] = value || 0;
    }

    setFilterState(newFilterState);
    setError('');
  };

  const handleSkillAddition = () => {
    const newFilterState = { ...filterState };

    newFilterState.skill.push(null);
    newFilterState.rating.push(0);
    setFilterState(newFilterState);
    setError('');
  };

  const handleClear = () => {
    setFilterState({
      skill: [null],
      rating: [0],
      designationId: null
    });

    setError('');

    if (onClear) {
      onClear();
    }
  };

  const handleFilter = () => {
    let valid = true;
    const { skill, rating } = filterState;

    skill.forEach((skill, index) => {
      rating[index] = +rating[index];

      if (!skill && rating[index]) {
        setError(`Skill missing on row: ${index + 1}`);
        valid = false;
      } else if (!skill && !rating[index]) {
        rating[index] = null;
      }
    });

    if (valid) {
      let skill = filterState.skill.filter(skill => skill);
      let rating = filterState.rating.filter(rating => rating != null);

      if (!skill || !skill.length) {
        skill = null;
        rating = null;
      }

      onFilter({ ...filterState, skill, rating });
    }
  };

  const skillOptions = generateReactSelectOptionsFromObject(skills);
  const designationOptions = generateReactSelectOptionsFromObject(designations);

  return (
    <>
      <div className="table-header name-wrap d-flex flex-row filter-header">
        <span className="resource-header-title-v1">{'Filter By'}</span>
      </div>
      <div className="skills-selector">
        <SkillsSelectorV1
          loading={loadingSkills}
          skillOptions={skillOptions}
          skills={filterState.skill}
          ratings={filterState.rating}
          onSkillChange={handleSkillChange}
          onRatingChange={handleRatingChange}
          onAddSkill={handleSkillAddition}
        />
        {error && <span className="color-red">{error}</span>}
      </div>
      <div className="designation-selector">
        <span className="filter-label">{'Role'}</span>
        <Select
          value={designationOptions.find(option => option.value === +filterState.designationId) || null}
          isSearchable={true}
          isClearable={true}
          isLoading={loadingDesignations}
          options={designationOptions}
          onChange={option => {
            handleChange('designationId', option && option.value);
          }}
        />
      </div>
      <div className="filter-control d-flex flex-row">
        <button className="btn btn--primary" onClick={handleFilter}>
          {'Apply Filter'}
        </button>
        <button className="btn btn--outlined-grey ml-10" onClick={handleClear}>
          {'Clear'}
        </button>
      </div>
    </>
  );
};

export default ResourceFilterV1;
