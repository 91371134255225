import React, { useEffect } from 'react';

import { LIST_TYPE } from 'constants/appConstants';
import AvatarComponent from 'components/common/avatar/AvatarComponent';
import DebounceFormDropdown from 'components/common/debounceFormDropdown/DebounceFormDropdown';
import { COMPLETE, ERROR, APPRAISER_ALLOCATION_FORM_INPUTS } from 'constants/createEmployeeForm';

function AppraiserAllocationForm(props) {
  const { errors, values, touched, handleChange, handleBlur, currentForm, shouldForcefullyShowError } = props;

  const { appraiserDebounceOptions, coAppraiserDebounceOptions } = props;

  useEffect(() => {
    if (currentForm) {
      const isFormEmpty = APPRAISER_ALLOCATION_FORM_INPUTS.some(key => {
        if (!values[key]) {
          return true;
        }
        return false;
      });

      if (!isFormEmpty) {
        currentForm.state = COMPLETE;
        return;
      }
      currentForm.state = ERROR;
    }
  }, [values]);

  return (
    <div className="office-information-form ">
      <div className="office-information-form__full-width">
        <div className="office-information-form__full-width__space">
          <DebounceFormDropdown
            isMandatory
            name="appraiserId"
            label="Appraiser"
            type={LIST_TYPE.EMPLOYEE}
            onBlur={handleBlur}
            value={values.appraiserId}
            error={(shouldForcefullyShowError || touched.appraiserId) && errors.appraiserId}
            touched={touched}
            debounceOptions={appraiserDebounceOptions}
            handleChange={handleChange}
            handleBlur={handleBlur}
            currentForm={currentForm}
            iconComponent={AvatarComponent}
            isClearable={true}
            className="employee-form__dropdown"
          />
        </div>
        <div className="office-information-form__full-width__space">
          <DebounceFormDropdown
            name="coAppraiserId"
            label="Co Appraiser"
            type={LIST_TYPE.EMPLOYEE}
            onBlur={handleBlur}
            value={values.coAppraiserId}
            error={(shouldForcefullyShowError || touched.coAppraiserId) && errors.coAppraiserId}
            touched={touched}
            handleChange={handleChange}
            debounceOptions={coAppraiserDebounceOptions}
            handleBlur={handleBlur}
            currentForm={currentForm}
            iconComponent={AvatarComponent}
            isClearable={true}
            className="employee-form__dropdown"
          />
        </div>
      </div>
    </div>
  );
}

export default AppraiserAllocationForm;
