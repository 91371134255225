import React from 'react';
import classNames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

const Input = ({
  label,
  error,
  isMandatory,
  placeholder,
  multiline,
  type,
  value,
  onChange,
  onFocus,
  className,
  customClassName,
  ...rest
}) => {
  return (
    <InputWrapper label={label} error={error} isMandatory={isMandatory} className={className}>
      <div>
        {multiline ? (
          <textarea className="" value={value} type={type} onChange onFocus={onFocus} />
        ) : (
          <input
            className={classNames('input-wrapper__input', {
              'input-wrapper__input--error': error,
              [customClassName]: customClassName
            })}
            value={value}
            type={type}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholder}
            {...rest}
          />
        )}
      </div>
    </InputWrapper>
  );
};

export default Input;
