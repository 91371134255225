import { queryClient } from 'index';
import { MdAdd } from 'react-icons/md';
import { UiPen } from 'vyaguta-icons/ui';
import React, { useMemo, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

import LFModal from 'components/common/LFModal/LFModal';

import { deleteQuickLinksCategory } from 'services/quickLinks';

import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';

import AddCategory from './AddCategory';
import DeletePopup from './DeletePopup';

const CategoryVerticalEllipsis = props => {
  const { selectedCategory, handleRowClick } = props;

  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isCategoryEditModalOpen, setIsCategoryEditModalOpen] = useState(false);

  const closeCategoryModal = () => {
    setIsCategoryModalOpen(false);
  };

  const closeCategoryEditModal = () => {
    setIsCategoryEditModalOpen(false);
  };

  const deleteCategory = async () => {
    try {
      const data = await deleteQuickLinksCategory(selectedCategory.id);

      toast.success({
        title: 'Success',
        message: data.message
      });

      queryClient.invalidateQueries('quickLinks');
      // await setHierarchyData();
    } catch (error) {
      handleError(error);
    }
  };

  const EditCategory = useMemo(() => {
    return ({ onClick }) => (
      <li
        className="overflow-menu__list-item overflow-menu__list-item--hoverable d-flex"
        onClick={() => {
          onClick();
          setIsCategoryEditModalOpen(true);
        }}
      >
        <span className="icon overflow-menu__icon">
          <UiPen className="grey--text" size={16} />
        </span>
        <span>Edit</span>
      </li>
    );
  }, [setIsCategoryEditModalOpen]);

  const AddLinkSubCategory = useMemo(() => {
    return ({ onClick }) => (
      <li
        className="overflow-menu__list-item overflow-menu__list-item--hoverable d-flex"
        onClick={() => {
          onClick();
          setIsCategoryModalOpen(true);
        }}
      >
        <span className="icon overflow-menu__icon">
          <MdAdd className="grey--text" size={16} />
        </span>
        <span>Add new sub-category</span>
      </li>
    );
  }, [setIsCategoryModalOpen]);

  return (
    <div className="overflow-menu">
      <div className="overflow-menu__block">
        <ul className="overflow-menu__list">
          <AddCategory
            isEdit={true}
            key="editCategory"
            isOpen={isCategoryEditModalOpen}
            onClose={closeCategoryEditModal}
            isSubCategory={false}
            selectedCategory={selectedCategory}
            TriggerBtn={EditCategory}
          />
          <AddCategory
            key="addCategory"
            isEdit={false}
            isSubCategory={true}
            isOpen={isCategoryModalOpen}
            onClose={closeCategoryModal}
            selectedCategory={selectedCategory}
            TriggerBtn={AddLinkSubCategory}
            handleRowClick={handleRowClick}
          />
          <LFModal
            TriggerBtn={DeletePopup}
            modalStatusIcon={<FiAlertTriangle color="#F44336" size={24} />}
            title={{ text: `Delete category ${selectedCategory.name}`, type: 'danger' }}
            className="delete-modal"
            htmlContent={
              <div className="d-grid mbpx-4">
                <span className="delete__text">
                  This action cannot be reverted back. The category {selectedCategory.name} will be deleted with the
                  links or category under it.
                </span>
              </div>
            }
            renderFooter={true}
            buttons={[
              {
                text: 'Yes, delete',
                type: 'red',
                className: 'mr-12 delete__btn',
                onClick: deleteCategory,
                close: true,
                showLoading: true
              },
              {
                text: 'No, go back',
                type: 'outlined-grey',
                className: 'py-8 px-12',
                close: true
              }
            ]}
          />
        </ul>
      </div>
    </div>
  );
};

export default CategoryVerticalEllipsis;
