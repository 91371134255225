import { FiLoader } from 'vyaguta-icons/fi';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FloatingButtons } from '@vyaguta/vyaguta-ui';

import config from 'config';
import history from 'utils/history';
import { success } from 'utils/toast';
import { interpolate } from 'utils/string';
import { isObjectEmpty } from 'utils/object';

import FLOATING_BUTTONS, {
  BTN_STATES,
  MAX_LENGTH,
  BUTTON_LABEL,
  WORKLOG_BUTTON,
  ATTENDANCE_TYPE,
  MISSING_ATTENDANCE_BUTTON
} from 'constants/floatingButtons';
import { SELF } from 'constants/fetchTypes';
import { SELF_MISSING_URL } from 'constants/endpoints';
import { H_MM, WEEKENDS_DAY_CODE } from 'constants/date';
import { WORKLOG_STATUS, WORK_LOCATION } from 'constants/worklog';
import { UPDATE_ATTENDANCE_STATUS_TOAST_MESSAGE } from 'constants/messages';

import { stringify } from 'utils/queryParam';
import { handleError } from 'utils/errorHandler';
import { getCurrentDay, getFormattedDate, getTimeAtTimeZone, isTimePast } from 'utils/date';

import * as worklogService from 'services/worklog';
import * as attendanceService from 'services/attendance';
import { setUserSelfAttendance } from 'slices/selfAttendanceSlice';

import * as mixpanel from 'services/mixPanel';
import { mixPanelEvents } from 'constants/mixPanel';

const WorkPlanButtons = props => {
  const dispatch = useDispatch();

  const [floatingButtons, setFloatingButtons] = useState(FLOATING_BUTTONS);
  const [todaySelfAttendance, setTodaySelfAttendance] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector(state => state.information.value);

  const today = getFormattedDate();
  const { OFFICE, HOME } = WORK_LOCATION;

  const { missing: selfMissing, pending: selfPending } = useSelector(state => state.selfAttendance);

  const { workPlanButton } = mixPanelEvents.select;

  const todaySelfAttendanceFilter = {
    startDate: today,
    endDate: today,
    fetchType: SELF
  };

  const fetchTodaySelfAttendance = async () => {
    try {
      setIsLoading(true);
      const [data] = await attendanceService.fetch(todaySelfAttendanceFilter);

      const emptyTodayData = {
        date: getFormattedDate(),
        worklog: null,
        leave: null,
        holiday: null,
        isWeekend: 0
      };

      setTodaySelfAttendance(data || emptyTodayData);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMissingChange = prevData => {
    const currentDay = getCurrentDay();

    if (WEEKENDS_DAY_CODE.includes(currentDay)) {
      return prevData;
    }

    if (!!todaySelfAttendance.holiday) {
      return prevData;
    }

    if (!prevData) {
      return prevData;
    }

    return prevData - 1;
  };

  const applyTodaysWorklog = async location => {
    const worklog = {
      location,
      workDate: today
    };

    try {
      setIsLoading(true);
      await worklogService.save(worklog);

      const [data] = await attendanceService.fetch(todaySelfAttendanceFilter);

      setTodaySelfAttendance(data);

      success({ title: 'Success', message: UPDATE_ATTENDANCE_STATUS_TOAST_MESSAGE[location] });

      dispatch(setUserSelfAttendance({ missing: handleMissingChange(selfMissing), pending: selfPending + 1 }));

      mixpanel.trackEvent(workPlanButton, { Category: location });
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const applyWfh = async () => {
    applyTodaysWorklog(HOME);
  };

  const applyWfo = async () => {
    applyTodaysWorklog(OFFICE);
  };

  const applyLeave = queryParams => {
    const url = `${config.vyagutaURI}${config.vyaguta.attendance}${config.endpoints.attendance.app.applyLeave}`;

    window.open(stringify(url, queryParams));

    mixpanel.trackEvent(workPlanButton, { Category: 'Apply Leave' });
  };

  useEffect(() => {
    fetchTodaySelfAttendance();
  }, []);

  useEffect(() => {
    if (!todaySelfAttendance || isObjectEmpty(todaySelfAttendance)) {
      return;
    }

    updateFloatingButtons(todaySelfAttendance);
  }, [todaySelfAttendance, selfPending, selfMissing]);

  const updateFloatingButtons = todaysData => {
    let attendanceType;

    const handleFloatingButtonsClick = {
      [ATTENDANCE_TYPE.LEAVE]: () => {
        if (!attendanceType) {
          return applyLeave({ startDate: todaysData.date, endDate: todaysData.date });
        }

        return applyLeave();
      },
      [ATTENDANCE_TYPE.WFH]: () => applyWfh(),
      [ATTENDANCE_TYPE.WFO]: () => applyWfo(),
      [ATTENDANCE_TYPE.FREEZE]: () => {
        window.open(SELF_MISSING_URL);
      }
    };

    const isAttendanceLockedTime = isTimePast(
      config.freezeAttendance.timeForLock,
      getTimeAtTimeZone(user.timezone, H_MM)
    );

    const isAttendanceLocked =
      !todaysData.worklog &&
      !todaysData.holiday &&
      !todaysData.leave &&
      todaysData.isWeekend === 0 &&
      isAttendanceLockedTime;

    if (isAttendanceLocked) {
      const modifyData = {
        ...MISSING_ATTENDANCE_BUTTON,
        onClick: handleFloatingButtonsClick[MISSING_ATTENDANCE_BUTTON.id]
      };

      setFloatingButtons([modifyData]);

      return;
    }

    if (todaysData.leave) {
      attendanceType = ATTENDANCE_TYPE.LEAVE;
    } else if (todaysData.worklog) {
      attendanceType = todaysData.worklog.location === OFFICE ? ATTENDANCE_TYPE.WFO : ATTENDANCE_TYPE.WFH;
    }

    if (!attendanceType) {
      const floatingButtons = FLOATING_BUTTONS.map(btn => ({ ...btn, onClick: handleFloatingButtonsClick[btn.id] }));

      setFloatingButtons(floatingButtons);
      return;
    }

    const newFloatingButtons = floatingButtons.map(btn => {
      let button;

      if (btn.id === attendanceType) {
        button = { ...btn, type: BTN_STATES.SECONDARY, onClick: null };
      } else {
        button = { ...btn, type: BTN_STATES.GRAYED, isDisabled: true };
      }

      if (btn.id === ATTENDANCE_TYPE.LEAVE) {
        button = { ...button, isDisabled: false, onClick: handleFloatingButtonsClick[btn.id] };
      }

      button.label = BUTTON_LABEL[btn.id][button.isDisabled ? BTN_STATES.DISABLED : button.type];
      button.allowTooltip = button.isDisabled;

      return button;
    });

    if (attendanceType !== ATTENDANCE_TYPE.LEAVE) {
      const isWorklogPending = todaysData.worklog.status === WORKLOG_STATUS.PENDING;

      if (newFloatingButtons.length < MAX_LENGTH) {
        WORKLOG_BUTTON.onClick = () => {
          const destinationPath = interpolate(
            `${config.vyagutaURI}${config.vyaguta.attendance}${config.endpoints.attendance.app.worklogDetail}`,
            { id: todaysData.worklog.id }
          );
          if (history.location.pathname === destinationPath) return;

          window.open(destinationPath);

          mixpanel.trackEvent(workPlanButton, { Category: "Fill today's worklog" });
        };
        newFloatingButtons.unshift(WORKLOG_BUTTON);
      }
      const worklogBtn = newFloatingButtons[0];

      worklogBtn.type = isWorklogPending ? BTN_STATES.PRIMARY : BTN_STATES.GRAYED;
      worklogBtn.isDisabled = worklogBtn.allowTooltip = !isWorklogPending;
      worklogBtn.label = BUTTON_LABEL[worklogBtn.id][isWorklogPending ? worklogBtn.type : BTN_STATES.DISABLED];

      newFloatingButtons[0] = worklogBtn;
    }
    setFloatingButtons(newFloatingButtons);
  };

  return isLoading ? (
    <div className="floating-action-container">
      <div className="loading-spinner">
        <FiLoader size={24} />
      </div>
    </div>
  ) : isObjectEmpty(todaySelfAttendance) ? (
    <></>
  ) : (
    <FloatingButtons buttons={floatingButtons} />
  );
};

export default WorkPlanButtons;
