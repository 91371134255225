import moment from 'moment';
import { Formik } from 'formik';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiAlertCircle, FiCalendar } from 'react-icons/fi';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import DeleteButton from 'components/common/button/DeleteButton';
import DatePicker from 'components/common/datepicker/DatePicker';

import noticeSchema from 'schemas/notices';

import * as noticeService from 'services/notices';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';
import { getFormattedDate, isDateBetweenInclusive } from 'utils/date';

import * as routes from 'constants/routes';
import { DEFAULT_DATE_FORMAT } from 'constants/date';
import { DEFAULT_HISTORY_ENTRIES } from 'constants/appConstants';

import { deleteNotices, updateNotices } from 'slices/informationSlice';

const NoticeForm = props => {
  const dispatch = useDispatch();

  const initialValues = {
    title: '',
    description: null,
    redirectUrl: null,
    startDate: moment(),
    endDate: moment()
  };

  const { notice } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = () => {
    return history.length > DEFAULT_HISTORY_ENTRIES ? history.goBack() : history.push(routes.NOTICES);
  };

  const handleSubmit = async values => {
    setIsSubmitting(true);

    const noticeObject = {
      title: values.title?.trim(),
      description: values.description ? values.description.trim() : null,
      redirectUrl: values.redirectUrl ? values.redirectUrl.trim() : null,
      startDate: values.startDate.startOf('day').format(DEFAULT_DATE_FORMAT),
      endDate: values.endDate.endOf('day').format(DEFAULT_DATE_FORMAT)
    };

    try {
      if (notice) {
        await noticeService.update(notice.id, noticeObject);

        if (
          isDateBetweenInclusive(
            getFormattedDate(),
            getFormattedDate(noticeObject.startDate),
            getFormattedDate(noticeObject.endDate)
          )
        ) {
          dispatch(updateNotices({ id: notice.id, ...noticeObject }));
        } else {
          dispatch(deleteNotices(notice.id));
        }
      } else {
        const { data } = await noticeService.create(noticeObject);

        if (
          isDateBetweenInclusive(
            getFormattedDate(),
            getFormattedDate(noticeObject.startDate),
            getFormattedDate(noticeObject.endDate)
          )
        ) {
          dispatch(updateNotices({ id: data.id, ...noticeObject }));
        }
      }

      toast.success({
        title: 'Success',
        message: notice ? 'Notice updated successfully.' : 'Notice created successfully.'
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
      history.push(routes.NOTICES);
    }
  };

  const handleDelete = async () => {
    try {
      await noticeService.remove(notice.id);

      if (
        isDateBetweenInclusive(getFormattedDate(), getFormattedDate(notice.startDate), getFormattedDate(notice.endDate))
      ) {
        dispatch(deleteNotices(notice.id));
      }

      toast.success({
        title: 'Success',
        message: 'Notice deleted successfully.'
      });
    } catch (error) {
      handleError(error);
    } finally {
      history.push(routes.NOTICES);
    }
  };

  const buttons = [
    {
      text: 'Confirm',
      type: 'red',
      className: 'mr-12 delete__btn',
      onClick: handleDelete,
      close: true,
      showLoading: true
    },
    {
      text: 'Cancel',
      type: 'outlined-grey',
      className: 'py-8 px-12',
      close: true
    }
  ];

  return (
    <Formik
      initialValues={notice ? { ...notice } : { ...initialValues }}
      validationSchema={noticeSchema}
      onSubmit={handleSubmit}
      render={({ handleChange, handleSubmit, touched, errors, values, handleBlur, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="notice-form">
          <div className="full-scope-card__content">
            <div className="form-wrap">
              <div className="form-wrap__row form-wrap__row--no-margin clearfix">
                <div className="form-wrap__col col-sm-6 px-0">
                  <div className="form-group width-full">
                    <label className="form-label dark--text">
                      Title <span className="required ml-5">*</span>
                    </label>
                    <input
                      name="title"
                      type="text"
                      value={values.title}
                      placeholder="Provide a suitable title for the notice"
                      className={classNames('form-elem form-elem--input-sm', {
                        'form-elem--error': errors.title && touched.title
                      })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.title && touched.title && <div className="error-block">{errors.title}</div>}
                  </div>
                  <div className="form-group width-full">
                    <label className="form-label dark--text">Description</label>
                    <textarea
                      name="description"
                      type="text"
                      value={values.description}
                      placeholder="Provide a suitable description for the notice"
                      className={classNames('form-elem form-elem--input-sm', {
                        'form-elem--error': errors.description && touched.description
                      })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.description && touched.description && (
                      <div className="error-block">{errors.description}</div>
                    )}
                  </div>
                  <div className="form-group width-full">
                    <label className="form-label dark--text">Link</label>
                    <input
                      name="redirectUrl"
                      type="text"
                      value={values.redirectUrl}
                      placeholder="Provide link for the notice"
                      className={classNames('form-elem form-elem--input-sm', {
                        'form-elem--error': errors.redirectUrl && touched.redirectUrl
                      })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.redirectUrl && touched.redirectUrl && (
                      <div className="error-block">{errors.redirectUrl}</div>
                    )}
                  </div>
                  <div className="d-flex flex-direction-responsive">
                    <div className="form-group width-full momr-20">
                      <label className="form-label dark--text">
                        Start Date <span className="required ml-5">*</span>
                      </label>
                      <div className="form-elem--icon-pos">
                        <DatePicker
                          id="startDate"
                          displayFormat="LL"
                          customInputIcon={<FiCalendar size={18} className="color-grey-50" />}
                          date={moment(values.startDate)}
                          onDateChange={date => {
                            date = moment(date);
                            if (!date._isValid) {
                              return;
                            }

                            setFieldValue('startDate', date);
                          }}
                        />
                      </div>
                      {errors.startDate && touched.startDate && <div className="error-block">{errors.startDate}</div>}
                    </div>
                    <div className="form-group width-full">
                      <label className="form-label dark--text">
                        End Date<span className="required ml-5">*</span>
                      </label>

                      <div className="form-elem--icon-pos">
                        <DatePicker
                          id="endDate"
                          displayFormat="LL"
                          customInputIcon={<FiCalendar size={18} className="color-grey-50" />}
                          date={moment(values.endDate)}
                          onDateChange={date => {
                            date = moment(date);
                            if (!date._isValid) {
                              return;
                            }

                            setFieldValue('endDate', date);
                          }}
                        />
                      </div>
                      {errors.endDate && touched.endDate && <div className="error-block">{errors.endDate}</div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-bar-footer action-bar-footer--bordered-top px-16">
              <button
                type="submit"
                className={classNames('btn btn--primary btn--curved mr-10', {
                  'btn--loading': isSubmitting
                })}
                disabled={isSubmitting}
              >
                <span className={isSubmitting ? 'invisible' : ''}>Save</span>
                {isSubmitting && <Loading />}
              </button>
              <button
                type="button"
                className={classNames('btn btn--outlined-grey btn--curved mr-10')}
                disabled={isSubmitting}
                onClick={handleClick}
              >
                Cancel
              </button>

              {notice && (
                <LFModal
                  TriggerBtn={DeleteButton}
                  modalStatusIcon={<FiAlertCircle color="#F44336" size={24} />}
                  title={{ text: 'Delete this notice?', type: 'danger' }}
                  className="delete-modal"
                  message={{ text: "You won't be able to revert this!", type: 'delete__text' }}
                  renderFooter={true}
                  buttons={buttons}
                />
              )}
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default NoticeForm;
