import http from 'utils/http';
import { interpolate, unParseQuery } from 'utils/string';
import { REMINDER_LIST_URL, REMINDER_URL_ID, REMINDER_URL } from 'constants/endpoints';

export async function fetchAll(params) {
  return await http.get(REMINDER_LIST_URL, { params: { ...params } });
}

export async function remove(id) {
  return await http.delete(interpolate(REMINDER_URL_ID, { id }));
}

export async function fetchReminder(id) {
  return await http.get(interpolate(REMINDER_URL_ID, { id }));
}

export async function create(reminder) {
  return await http.post(REMINDER_URL, { body: reminder });
}

export async function updateReminder(id, reminder) {
  return await http.put(interpolate(REMINDER_URL_ID, { id }), { body: { id, ...reminder } });
}
