import React, { useState } from 'react';

function useModalState(defaultValue = false) {
  const [open, setOpen] = useState(defaultValue);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onToggle = () => {
    setOpen(prev => !prev);
  };

  return {
    open,
    setOpen,
    onOpen,
    onClose,
    onToggle
  };
}

export default useModalState;
