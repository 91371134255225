import http from 'utils/http';

import { interpolate } from 'utils/string';

import {
  DASHBOARD_QUICK_LINKS,
  QUICK_LINK,
  QUICK_LINKS,
  QUICK_LINKS_CATEGORIES,
  QUICK_LINKS_CATEGORY,
  QUICK_LINKS_WEIGHTS
} from 'constants/endpoints';

/**
 *
 * @returns {Array} quick links
 */
export async function fetchQuickLinks() {
  const data = await http.get(DASHBOARD_QUICK_LINKS);

  return data.data;
}

/**
 *
 * @param {Object} params - query string(s)
 * @returns {Array} quick links
 */
export async function addQuickLinksCategory(category) {
  const data = await http.post(QUICK_LINKS_CATEGORIES, { body: category });

  return data;
}

/**
 *
 * @param {Object} params - query string(s)
 * @returns {Array} quick links
 */
export async function updateQuickLinksCategory(id, category) {
  const url = interpolate(QUICK_LINKS_CATEGORY, { id });
  const data = await http.put(url, { body: category });

  return data;
}

/**
 *
 * @param {Object} params - id string(s)
 * @returns {Array} quick links
 */
export async function deleteQuickLinksCategory(id) {
  const url = interpolate(QUICK_LINKS_CATEGORY, { id });
  const data = await http.delete(url);

  return data;
}

/**
 *
 * @param {Object} params - query string(s)
 * @returns {Array} quick links
 */
export async function addQuickLinks(link) {
  const data = await http.post(QUICK_LINKS, { body: link });

  return data;
}

/**
 *
 * @param {Object} params - query string(s)
 * @returns {Array} quick links
 */
export async function updateQuickLinks(id, link) {
  const url = interpolate(QUICK_LINK, { id });
  const data = await http.put(url, { body: link });

  return data;
}

/**
 *
 * @param {Object} params - id string(s)
 * @returns {Array} quick links
 */
export async function deleteQuickLinks(id) {
  const url = interpolate(QUICK_LINK, { id });
  const data = await http.delete(url);

  return data;
}

export async function updateLinkLibraryWeights(payload) {
  const url = QUICK_LINKS_WEIGHTS;
  const data = await http.patch(url, { body: payload });

  return data;
}
