import React from 'react';
import classnames from 'classnames';

const Checkbox = props => {
  const { options, selectedOptions, onChange, isDisabled = false } = props;

  return (
    <>
      {options.map((item, index) => {
        const { label, value } = item;

        return (
          <div className={classnames('lf-checkbox__checkbox', { 'mt-3x': index !== 0 })} key={index}>
            <input
              type="checkbox"
              id={value}
              disabled={isDisabled}
              checked={selectedOptions[value]}
              className="lf-checkbox__checkbox-btn"
              value={value}
              onChange={onChange}
            />
            <label htmlFor={value} className="lf-checkbox__label fs-medium mb-0x">
              {label}
            </label>
            <br />
          </div>
        );
      })}
    </>
  );
};

export default Checkbox;
