import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import history from 'utils/history';
import { parse, stringify } from 'utils/queryParam';

import FilterPill from './FilterPill';

const FilterPills = props => {
  const { statusLabels, statuses, colorMap, summary, onChange } = props;

  const [activeFilter, setActiveFilter] = useState(statuses.ALL);

  const totalCount = Object.keys(summary).reduce((acc, key) => acc + summary[key], 0);

  useEffect(() => {
    const { status = statuses.ALL } = parse(props.location.search);

    setActiveFilter(status);
  }, [props.location.search]);

  const handleFilterClick = selectedFilter => {
    const queryParam = parse(props.location.search);

    delete queryParam.page;

    updateUrl({ ...queryParam, status: selectedFilter });

    onChange(selectedFilter);
  };

  const updateUrl = value => {
    const queryParam = stringify('', value);

    history.push({
      pathname: props.location.pathname,
      search: queryParam
    });
  };

  return (
    <>
      {totalCount ? (
        <FilterPill
          notification={totalCount}
          isSmall={true}
          label={statusLabels[statuses.ALL]}
          color={colorMap[statuses.ALL]}
          isActive={activeFilter === statuses.ALL}
          handleClick={() => handleFilterClick()}
        />
      ) : null}

      {summary.approved > 0 && (
        <FilterPill
          notification={summary.approved}
          isSmall={true}
          label={statusLabels[statuses.APPROVED]}
          color={colorMap[statuses.APPROVED]}
          isActive={activeFilter === statuses.APPROVED}
          handleClick={() => handleFilterClick(statuses.APPROVED)}
        />
      )}

      {summary.pending > 0 && (
        <FilterPill
          notification={summary.pending}
          isSmall={true}
          label={statusLabels[statuses.PENDING]}
          color={colorMap[statuses.PENDING]}
          isActive={activeFilter === statuses.PENDING}
          handleClick={() => handleFilterClick(statuses.PENDING)}
        />
      )}

      {summary.rejected > 0 && (
        <FilterPill
          notification={summary.rejected}
          isSmall={true}
          label={statusLabels[statuses.REJECTED]}
          color={colorMap[statuses.REJECTED]}
          isActive={activeFilter === statuses.REJECTED}
          handleClick={() => handleFilterClick(statuses.REJECTED)}
        />
      )}
    </>
  );
};

export default withRouter(FilterPills);
