import React from 'react';
import classNames from 'classnames';
import { FiLoader } from 'react-icons/fi';
import { UiMegaphone } from 'vyaguta-icons/ui';

import { NOTICE_BOARD } from 'constants/routes';

const Notices = ({ active, noticeCount, isNoticeCountLoading }) => {
  return (
    <div className="header-pills notices-pills">
      <a href={NOTICE_BOARD} rel="noopener noreferrer">
        <div className="d-flex align-items-center content">
          <div className="icon d-flex align-items-center">
            <UiMegaphone size={16} className={classNames({ 'notices-icon-active': active })} />
            <div className="d-flex justify-content-center">
              {isNoticeCountLoading ? (
                <FiLoader className="badge-count badge-count__loading" />
              ) : (
                noticeCount > 0 && <div className="badge-count">{noticeCount}</div>
              )}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Notices;
