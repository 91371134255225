import React from 'react';
import classnames from 'classnames';

const Spinner = props => {
  return (
    <div className={classnames('spinner', { [props.className]: props.className })}>
      <div className='bounce1 bg-grey'></div>
      <div className='bounce2 bg-grey'></div>
      <div className='bounce3 bg-grey'></div>
    </div>
  );
};

export default Spinner;
