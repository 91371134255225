import React from 'react';
import classnames from 'classnames';

import { scores } from 'constants/score';

const ScoreTab = props => {
  const { scoreId, scoreValue, handleChange, name, disabled, lightMode } = props;

  const handleClick = (score, isSelected) => {
    let value = score;
    if (isSelected) value = null;
    handleChange(name, value);
  };

  const options = scores.map(score => {
    const isSelected = scoreValue && scoreValue === score;

    const buttonContainerClass = classnames(`${lightMode ? 'light-' : ''}box-links__nodes`, {
      [`${lightMode ? 'light-' : ''}box-links__nodes--active`]: isSelected,
      [`${lightMode ? 'light-' : ''}box-links__nodes--disabled`]: disabled
    });

    const labelClass = classnames('score-tab__value', {
      'score-tab__value--disabled': disabled
    });

    return (
      <div className={buttonContainerClass} key={score}>
        <label htmlFor={scoreId} className={labelClass}>
          <input
            type="radio"
            id={scoreId}
            onClick={() => handleClick(score, isSelected)}
            className="score-tab__radio"
            disabled={disabled}
          />
          {score}
        </label>
      </div>
    );
  });

  return <div className="box-links">{options}</div>;
};

export default ScoreTab;
