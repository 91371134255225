import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';

import history from 'utils/history';
import * as routes from 'constants/routes';
import { DEFAULT_HISTORY_ENTRIES } from 'constants/appConstants';

export default function BackButton() {
  return (
    <button
      type="button"
      className="btn btn-shrink btn--alternate btn--curved mr-16 d-inline-flex flex-fix"
      onClick={() => (history.length > DEFAULT_HISTORY_ENTRIES ? history.goBack() : history.push(routes.HOME))}
    >
      <IoMdArrowBack size="16px" className="mr-5" />
      Back
    </button>
  );
}
