import lodash from 'lodash';
import { useCallback, useState } from 'react';

import { MIN_DEBOUNCE_TIME } from 'constants/appConstants';

export const useDebounce = () => {
  const [code, setCode] = useState('');

  const debounceFunction = useCallback(
    lodash.debounce(code => {
      setCode(code);
    }, MIN_DEBOUNCE_TIME),
    []
  );

  return [code, debounceFunction];
};
