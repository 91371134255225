import http from '../utils/http';
import { interpolate } from 'utils/string';
import { NOTICES, NOTICES_LIST, NOTICE_BOARD, NOTICE_URL_ID } from '../constants/endpoints';

export async function fetchAll(params) {
  return await http.get(NOTICES_LIST, { params: { ...params } });
}

export async function fetch(params) {
  return await http.get(NOTICES, { params: { ...params } });
}

export async function fetchNoticeBoard(params) {
  return await http.get(NOTICE_BOARD, { params: { ...params } });
}

export async function fetchById(id) {
  return await http.get(interpolate(NOTICE_URL_ID, { id }));
}

export async function create(notice) {
  return await http.post(NOTICES, { body: notice });
}

export async function update(id, notice) {
  return await http.put(interpolate(NOTICE_URL_ID, { id }), { body: notice });
}

export async function remove(id) {
  return await http.delete(interpolate(NOTICE_URL_ID, { id }));
}
