import React from 'react';
import { UiHistory } from 'vyaguta-icons/ui';

import { leaveTypeId } from 'constants/leave';

import Table from 'components/common/table';
import PopOver from 'components/common/popover';
import ApplyButton from 'components/common/layouts/apply-buttons';

import useModalState from 'hooks/useModalState';
import { useFetchWellBeingLeaveCredits } from 'hooks/query/useFetchWellBeingLeaveCredits';

import WellBeingLeaveHistory from './WellBeingLeaveHistory';

const getColumns = ({ userResignation, wellBeingLeaveCredits, isLoading, open, onOpen, onClose }) => {
  const columns = [
    {
      Header: 'Leave',
      accessor: 'leaveType',
      Cell: props =>
        props.original.leaveTypeId === leaveTypeId.WELL_BEING ? (
          <div className="d-flex gap-x-4 align-items-center">
            {props.original.leaveType}
            <PopOver
              interactive
              theme="light"
              disableOnScroll={true}
              open={open}
              onRequestClose={onClose}
              position="right-end"
              html={
                <WellBeingLeaveHistory
                  wellBeingLeaveCredits={wellBeingLeaveCredits}
                  isLoading={isLoading}
                  open={open}
                  onClose={onClose}
                />
              }
            >
              <span onMouseEnter={onOpen} className="well-being-leave__history d-flex align-items-center">
                <UiHistory size={16} />
              </span>
            </PopOver>
          </div>
        ) : (
          props.original.leaveType
        )
    },
    {
      Header: 'Credits',
      accessor: 'leaveCredits'
    },
    {
      Header: 'Taken',
      accessor: 'leavesTaken'
    },
    {
      Header: 'Available',
      accessor: 'available'
    }
  ];

  if (userResignation) {
    columns.push({
      Header: 'Eligible (Last 30 days)',
      accessor: 'eligible'
    });
  }

  return columns;
};

const LeaveCredits = ({ canApplyLeave = false, loading = false, data, employeeId, resignation, fiscalYearId }) => {
  const { open, onOpen, onClose } = useModalState();

  const { data: wellBeingLeaveCredits, isLoading } = useFetchWellBeingLeaveCredits({
    userId: employeeId,
    fiscalYearId
  });

  return (
    <main>
      <div className="container">
        <div className="full-scope-card mt-15">
          <div className="full-scope-card__header table-header name-wrap d-flex justify-content-between user-select-none">
            <div>
              <span className="table-header__title">Leave Credits</span>
            </div>

            {canApplyLeave && (
              <div className="action-bar d-flex justify-content-center">
                <ApplyButton id={employeeId} />
              </div>
            )}
          </div>

          <Table
            data={data}
            columns={getColumns({
              userResignation: resignation,
              wellBeingLeaveCredits,
              isLoading,
              open,
              onOpen,
              onClose
            })}
            loading={loading}
            showPagination={false}
            minRows={data?.length}
            customClassName="rt-cursor-default"
          />
        </div>
      </div>
    </main>
  );
};

export default LeaveCredits;
