import _ from 'lodash';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UiCheckCircle } from 'vyaguta-icons/ui';

import { SKILL_PILL_ACTION, SKILL_STATUS } from 'constants/skills';

import PopOver from 'components/common/popover';

import SkillRatingSelector from './SkillRatingSelector';

import { addSelectedSkill, removeSelectedSkill } from 'slices/selectedSkillSlice';

const SkillPill = props => {
  const {
    skill,
    onClick,
    mappedSkillRating,
    setIsOverlayOpenFor,
    isOpenFor,
    isSelf,
    isTeamManager,
    ratingOpenFor,
    setRatingOpenFor
  } = props;
  const { name } = skill;

  const globalRatingScale = useSelector(state => state.globalRatings.value);

  const [rating, setRating] = useState(null);
  const [status, setStatus] = useState(null);
  const [recommendedForDelete, setRecommendedForDelete] = useState(null);
  const [ratingScale, setRatingSkill] = useState(skill.localRating?.length ? skill.localRating : globalRatingScale);

  const selectedSkills = useSelector(state => state.selectedSkills.value);

  const dispatch = useDispatch();

  useEffect(() => {
    if (mappedSkillRating && mappedSkillRating[skill.id]) {
      setRating(mappedSkillRating[skill.id].rating);

      setStatus(mappedSkillRating[skill.id].skillStatus?.status);
      setRecommendedForDelete(mappedSkillRating[skill.id].skillStatus?.rating === 0);
    }

    if (skill.rating) {
      setRating(skill.rating);
    }
    /* eslint-disable */
  }, [skill]);

  const isFlagged = skill?.skillStatus?.status === SKILL_STATUS.FLAGGED || status === SKILL_STATUS.FLAGGED;

  const isRemovalRecommendation =
    (skill?.skillStatus?.status === SKILL_STATUS.FLAGGED && skill?.skillStatus?.rating === 0) ||
    (status === SKILL_STATUS.FLAGGED && recommendedForDelete);

  const isSkillSelected =
    isOpenFor?.id === skill.id && (skill.skillStatus?.status === SKILL_STATUS.APPROVED || !skill.skillStatus?.status);

  const isFlaggedSelected = isOpenFor?.id === skill.id && skill.skillStatus?.status === SKILL_STATUS.FLAGGED;

  const isDeleteSelected =
    isOpenFor?.id === skill.id && skill.skillStatus?.status === SKILL_STATUS.FLAGGED && skill.skillStatus?.rating === 0;

  const dispatcherCallback = (payload, type) => {
    if (type === SKILL_PILL_ACTION.ADD) {
      dispatch(addSelectedSkill(payload));
    } else if (type === SKILL_PILL_ACTION.REMOVE) {
      dispatch(removeSelectedSkill(payload));
    }
  };

  return (
    <PopOver
      disabled={!mappedSkillRating || !!status}
      visible={mappedSkillRating && !status && (!ratingOpenFor || ratingOpenFor.id === skill.id)}
      interactive
      open={ratingOpenFor?.id === skill.id}
      onRequestClose={() => setRatingOpenFor(null)}
      trigger="click"
      html={
        <SkillRatingSelector
          skillRatingScale={ratingScale}
          skill={skill}
          rating={rating}
          setRating={setRating}
          dispatcherCallback={dispatcherCallback}
          selectedSkills={selectedSkills}
        />
      }
      theme="light"
      offset="0, 20"
      arrow={true}
    >
      <div
        className={classNames('skills-list__pill-item', {
          'skills-list__pill-item--selected': rating,
          'skills-list__pill-item--view': skill.rating,
          'skills-list__pill-item--hover': !!setIsOverlayOpenFor && ((isSelf && skill.skillStatus) || isTeamManager),
          'skills-list__pill-item--flagged': isFlagged,
          'skills-list__pill-item--removal-recommendation': isRemovalRecommendation,
          'skills-list__pill-item--verified-selected': isSkillSelected,
          'skills-list__pill-item--flagged-selected': isFlaggedSelected,
          'skills-list__pill-item--delete-selected': isDeleteSelected
        })}
        onClick={e => {
          e.stopPropagation();

          onClick && onClick();
        }}
      >
        <div className="name">{name}</div>
        {rating && (
          <div
            className={classNames('rating', {
              'rating--view': skill.rating
            })}
          >
            {rating}
          </div>
        )}
        {(skill.skillStatus?.status === SKILL_STATUS.APPROVED || status === SKILL_STATUS.APPROVED) && (
          <div className="icon-container">
            <UiCheckCircle />
          </div>
        )}
      </div>
    </PopOver>
  );
};

export default SkillPill;
