import React from 'react';
import EmployeeNoteItem from './EmployeeNoteItem';

const EmployeeNoteList = props => {
  const { notes, authUserId } = props;

  return (
    <>
      {notes.map(({ note, showHeader, compact, isOpen, onClick }, idx) => (
        <EmployeeNoteItem
          index={idx + 1}
          key={idx + 1}
          note={note}
          showHeader={showHeader}
          compact={compact}
          isOpen={isOpen}
          onClick={onClick}
          authUserId={authUserId}
        />
      ))}
    </>
  );
};

export default EmployeeNoteList;
