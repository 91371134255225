import { Formik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useState } from 'react';

import { FiChevronRight, FiPlus } from 'react-icons/fi';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import InputWrapper from 'components/common/inputWrapper';
import Input from 'components/common/inputWrapper/components/Input';

import { ADD_SKILL, EDIT_SKILL, LAST_SKILL_LEVEL } from 'constants/skills';

import { addSkillSchema } from 'schemas/addSkillSchema';

import * as skillV2Service from 'services/skillV2';

import { success } from 'utils/toast';
import { handleError } from 'utils/errorHandler';

import UseGlobalRating from './UseGlobalRating';

const AddSkillModal = props => {
  const { skillData, skillParentData, addSubSkill, isOpen, onClose, callback } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const initialValues = {
    name: skillData?.name || '',
    description: skillData?.description || '',
    isGlobalRatingChecked: skillData?.localRating?.length ? false : true,
    localRating: skillData?.localRating || [
      {
        rating: 1,
        description: ''
      },
      {
        rating: 2,
        description: ''
      },
      {
        rating: 3,
        description: ''
      },
      {
        rating: 4,
        description: ''
      }
    ]
  };

  const lastParent = skillParentData.at(-1);

  const isEditMode = !!skillData?.id;

  const skillLevel = skillParentData.length;

  const lastSkillLevel = skillLevel === LAST_SKILL_LEVEL;

  const handleAddSubSkill = e => {
    e.preventDefault();

    addSubSkill([...skillParentData, skillData]);
  };

  const handleSubmit = async (body, submitProps) => {
    const refineBody = {
      name: body.name,
      description: body.description,
      ...(body.isGlobalRatingChecked ? {} : { localRating: body.localRating })
    };

    try {
      setIsSubmitting(true);

      const updatedBody = { ...refineBody, parentId: lastParent.id };

      if (!isEditMode) {
        const data = await skillV2Service.create(updatedBody);

        callback && callback(data);

        onClose();

        success({ title: 'Success', message: data.message });
      } else {
        const data = await skillV2Service.update(skillData.id, refineBody);

        callback && callback(data);

        onClose();

        success({ title: 'Success', message: data.message });
      }
    } catch (err) {
      handleError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const onDelete = async () => {
    if (isEditMode) {
      try {
        setIsDeleting(true);

        await skillV2Service.deleteSkill(skillData.id);

        success({
          title: 'Success',
          message: 'The skill has been deleted successfully.'
        });

        callback && callback();
      } catch (error) {
        handleError(error);
      } finally {
        onClose();
        setIsDeleting(false);
      }
    }
  };

  const SkillList = ({ skills }) => {
    return (
      <div className="skill-modal__skill-list">
        {skills?.map((skill, index) => {
          const lastItem = index === skills.length - 1;

          return (
            <div className="skill-modal__skill-item" key={skill.id}>
              <div className={classNames({ 'skill-modal--semi-bold': lastItem })}>{skill.name}</div>
              {!lastItem && <FiChevronRight className="p-2" size="12" />}
            </div>
          );
        })}
      </div>
    );
  };

  const TriggerBtn = () => {
    return <div className="hidden"></div>;
  };

  return (
    <LFModal
      TriggerBtn={TriggerBtn}
      isOpen={isOpen}
      onClose={onClose}
      closeButton={true}
      title={{ text: isEditMode ? EDIT_SKILL : ADD_SKILL, type: 'info' }}
      className="skill-modal"
      htmlContent={
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={addSkillSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
        >
          {({ handleSubmit, handleBlur, handleChange, errors, values, dirty, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit} className="skill-modal__form">
                <div className="skill-modal__form-body skill-modal__skill-body custom-scroll">
                  <div>
                    <div className="skill-modal__label">You are {isEditMode ? 'editing' : 'adding'} skill under:</div>
                    <SkillList skills={skillParentData} />
                  </div>

                  <Input
                    isMandatory
                    label="Skill Name"
                    name="name"
                    placeholder="Type skill name"
                    className="wp-100"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name}
                    disabled={isSubmitting}
                    multiline={false}
                    type="text"
                  />

                  <InputWrapper label="Description" error={errors.description}>
                    <textarea
                      name="description"
                      placeholder="Type skill description here..."
                      className={classNames('form-elem custom-scroll-bar skill-modal__textarea', {
                        'form-elem--error': errors.description
                      })}
                      value={values.description}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                  </InputWrapper>

                  {(!!skillData?.isEndSkill || !isEditMode) && (
                    <UseGlobalRating
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  )}

                  {isEditMode && !lastSkillLevel && (
                    <div className="skill-modal__sub-skill" onClick={handleAddSubSkill}>
                      <button className="btn skill-modal__sub-skill-btn" type="button">
                        <FiPlus size="12" fontWeight="600" />
                        Add Sub-skill
                      </button>
                      {values.name && !!skillData?.isEndSkill && (
                        <div className="skill-modal__sub-skill-note">
                          Note that, <span>{values.name} </span> will be converted into a skill group under{' '}
                          <span>{lastParent.name}</span> to have its own set of skills.
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="skill-modal__footer">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={classNames('btn skill-modal__btn', {
                      'btn--primary': !isSubmitting && dirty,
                      'btn--disabled': isSubmitting || !dirty
                    })}
                    disabled={isSubmitting || !dirty}
                  >
                    <span className={classNames({ invisible: isSubmitting })}>{isEditMode ? 'Save' : 'Add'}</span>
                    {isSubmitting && <Loading />}
                  </button>

                  <button
                    type="button"
                    className={classNames('btn ml-10 skill-modal__btn', {
                      'btn--outlined-grey': !isSubmitting,
                      'btn--disabled': isSubmitting
                    })}
                    onClick={e => {
                      e.preventDefault();
                      onClose();
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                  {isEditMode && !!skillData?.isEndSkill && (
                    <button
                      type="button"
                      className="btn btn--outlined-danger f-right card-button change-tentative-exit__btn"
                      onClick={showDeleteConfirmationModal}
                      disabled={isSubmitting}
                    >
                      Delete
                    </button>
                  )}
                </div>
                {isDeleteConfirmationModalOpen && (
                  <div className="skill-delete-modal absolute">
                    <div className="font-14">
                      <p>
                        {'Are you sure you want to delete the skill: '} <b>{values.name}</b>
                        {'?'}
                      </p>
                      <p>{'It can not be undone once deleted.'}</p>
                    </div>
                    <div className="d-flex gap-4x mt-4 f-right">
                      <button
                        type="button"
                        className={classNames('btn ml-10 skill-modal__btn', {
                          'btn--outlined-grey': !isSubmitting,
                          'btn--disabled': isSubmitting
                        })}
                        onClick={closeDeleteConfirmationModal}
                        disabled={isDeleting}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn--outlined-danger f-right card-button min-width-80"
                        onClick={onDelete}
                        disabled={isDeleting}
                      >
                        {isDeleting ? <Loading /> : 'Yes, delete'}
                      </button>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      }
    />
  );
};

AddSkillModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addSubSkill: PropTypes.func.isRequired,
  skillData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string
  }),
  skillParentData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string
    })
  ).isRequired
};

export default AddSkillModal;
