import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import InputLabel from './InputLabel';

const FormGroup = props => {
  const {
    name,
    type,
    label,
    isMandatory,
    multiline,
    error,
    value,
    placeholder,
    readOnly,
    handleChange,
    handleBlur,
    containerClass,
    disabled
  } = props;

  const inputProps = {
    className: classnames('form-elem', { 'bg-grey': readOnly, 'border-red': error }),
    name,
    readOnly,
    value: value || '',
    id: label + '-inputfield',
    disabled,
    placeholder,
    onChange: handleChange,
    onBlur: handleBlur
  };

  return (
    <div className={classnames('form-group', { [containerClass]: containerClass })} key={label + '-txtinput'}>
      {label && InputLabel(label, isMandatory)}

      {multiline ? <textarea {...inputProps} /> : <input type={type || 'text'} {...inputProps} />}
      {error && <span className="error-block">{error}</span>}
    </div>
  );
};

FormGroup.propTypes = {
  label: PropTypes.any,
  type: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  error: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  containerClass: PropTypes.string
};

export default FormGroup;
