import React from 'react';
import { Tooltip as ReactTippy } from 'react-tippy';

const Tooltip = props => {
  return (
    <>
      <ReactTippy {...props}>{props.children}</ReactTippy>
    </>
  );
};

export default Tooltip;
