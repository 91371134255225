import config from '../config';
import * as routes from 'constants/routes';

import * as permissions from './permissions';
import { HEADER, SECONDARY_HEADER } from './appConstants';

export const getHeaderNavs = currentPath => {
  return [
    {
      label: HEADER.PEOPLE,
      link: config.vyaguta.people,
      isActive: (currentPath !== '/') & (currentPath !== '/noticeboard')
    },
    {
      label: HEADER.TEAMS,
      link: config.vyaguta.teams
    },
    {
      label: HEADER.ATTENDANCE,
      link: config.vyaguta.attendance
    },
    {
      label: HEADER.JUMP,
      link: config.vyaguta.jump
    },
    {
      label: HEADER.HONOR,
      link: config.vyaguta.honor
    },
    {
      label: HEADER.OKR,
      link: config.vyaguta.okr
    },
    {
      label: HEADER.PULSE,
      link: config.vyaguta.pulse
    }
  ];
};

export const getSecondaryNav = isSubContractor => {
  const secondaryNav = [
    {
      label: SECONDARY_HEADER.LEAPFROGGERS,
      to: routes.ACTIVE_EMPLOYEES,
      requiredAuthorization: false,
      exact: false
    },
    {
      label: SECONDARY_HEADER.PEOPLE_FINDER,
      to: routes.RESOURCE_FINDER,
      allowFor: [permissions.EMPLOYEES.FINDER],
      exact: true
    },
    {
      label: SECONDARY_HEADER.SETTINGS,
      to: routes.SETTINGS,
      allowFor: [permissions.SKILLS.VIEW, permissions.DESIGNATIONS.VIEW, permissions.USER_PERMISSIONS.VIEW],
      exact: false
    },
    {
      label: SECONDARY_HEADER.EVENTS_AND_NOTICES,
      to: routes.EVENTS_AND_NOTICES,
      allowFor: [
        permissions.REMINDERS.VIEW,
        permissions.EVENTS.VIEW,
        permissions.NOTICES.VIEW,
        permissions.HOLIDAYS.VIEW
      ],
      exact: false
    },
    {
      label: SECONDARY_HEADER.NOTES_SUMMARY,
      to: routes.NOTE_SUMMARY,
      allowFor: [permissions.NOTES.VIEW],
      exact: false
    },
    {
      label: SECONDARY_HEADER.LEAVE_ISSUER_REQUESTS,
      to: routes.LEAVE_ISSUER_CHANGE_REQUESTS,
      allowFor: [permissions.LEAVE_ISSUER_CHANGE_REQUESTS.VIEW],
      exact: false
    }
  ];

  // Remove specific items if the user is a subcontractor
  if (isSubContractor) {
    return secondaryNav.filter(
      item =>
        ![
          SECONDARY_HEADER.PEOPLE_FINDER,
          SECONDARY_HEADER.SETTINGS,
          SECONDARY_HEADER.EVENTS_AND_NOTICES,
          SECONDARY_HEADER.NOTES_SUMMARY,
          SECONDARY_HEADER.LEAVE_ISSUER_REQUESTS
        ].includes(item.label)
    );
  }

  return secondaryNav;
};

export const SUB_CONTRACTOR_HEADER = [HEADER.PEOPLE, HEADER.ATTENDANCE, HEADER.PULSE];
