import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FiAlertCircle } from 'react-icons/fi';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import { SKILLS_CATEGORY } from 'constants/permissions';
import skillCategorySchema from 'schemas/skillCategorySchema';
import DeleteButton from 'components/common/button/DeleteButton';
import { FormGroup } from '../../CreateUpdateEmployeeForm/components';

class EditSkillCategory extends React.Component {
  constructor(props) {
    super(props);
    const { skillCategory } = props;
    this.state = {
      selectedSkillCategory: { ...skillCategory }
    };
  }

  componentWillReceiveProps(props) {
    const { skillCategory } = props;
    this.setState({
      selectedSkillCategory: { ...skillCategory }
    });
  }

  handleSaveClick = skillCategory => this.props.handleSave(skillCategory, this.state.selectedSkillCategory.id);

  handleDeleteClick = () => {
    this.props.handleDelete(this.state.selectedSkillCategory.id);
  };

  handleCancelClick = () => this.props.handleCancel();

  render() {
    const { saving, deleting, permissions } = this.props;
    const { selectedSkillCategory } = this.state;

    return (
      <Formik
        enableReinitialize
        initialValues={selectedSkillCategory}
        validationSchema={skillCategorySchema}
        onSubmit={this.handleSaveClick}
        render={({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
          <form className="edit-skill card-form" onSubmit={handleSubmit}>
            <h3 className="edit-skill_header">Skill Category Details</h3>
            <FormGroup
              name="name"
              label="Skill Category Name"
              isMandatory={true}
              value={values.name}
              error={errors.name}
              handleBlur={handleBlur}
              handleChange={handleChange}
              readOnly={!permissions[SKILLS_CATEGORY.CREATE]}
              placeholder="Skill Category Name"
            />
            <FormGroup
              name="description"
              label="Skill Description"
              isMandatory={false}
              multiline={true}
              value={values.description}
              readOnly={!permissions[SKILLS_CATEGORY.CREATE]}
              error={touched.description && errors.description}
              handleBlur={handleBlur}
              handleChange={handleChange}
              placeholder="Skill Category Description"
            />
            <div className="mt-30 button-area">
              {(permissions[SKILLS_CATEGORY.CREATE] || permissions[SKILLS_CATEGORY.UPDATE]) && (
                <button
                  type="submit"
                  disabled={saving}
                  className="btn btn--primary f-left card-button mr-10 min-width-80"
                >
                  {saving ? <Loading /> : selectedSkillCategory.id ? 'Update' : 'Create'}
                </button>
              )}
              <button
                type="button"
                onClick={this.handleCancelClick}
                className="btn btn--outlined-grey f-left card-button"
              >
                Cancel
              </button>
              {permissions[SKILLS_CATEGORY.DELETE] && selectedSkillCategory.id && (
                <LFModal
                  TriggerBtn={DeleteButton}
                  isLoading={deleting}
                  modalStatusIcon={<FiAlertCircle color="#F44336" size={24} />}
                  title={{ text: 'Do you want to delete this skill category?', type: 'danger' }}
                  className="delete-modal"
                  message={{ text: 'This action cannot be reverted back once deleted.', type: 'delete__text' }}
                  renderFooter={true}
                  buttons={[
                    {
                      text: 'Yes, delete',
                      type: 'red',
                      className: 'mr-12 delete__btn',
                      onClick: () => this.handleDeleteClick(selectedSkillCategory),
                      close: true,
                      showLoading: true
                    },
                    {
                      text: 'No, go back',
                      type: 'outlined-grey',
                      className: 'py-8 px-12',
                      close: true
                    }
                  ]}
                />
              )}
            </div>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({ permissions: state.information.value.permissions });

export default connect(mapStateToProps)(EditSkillCategory);
