import { getDateDifference, isDateBetweenInclusive } from './date';

/**
 * Function to check if the attendance is unfrozen.
 *
 * @param {string} workDate
 * @param {Array} unfreezeRequests
 * @returns
 */
export const checkAttendanceUnfrozen = (workDate, unfreezeRequests) => {
  if (!unfreezeRequests?.length) {
    return false;
  }

  const filteredRequests = unfreezeRequests
    .filter(request => isDateBetweenInclusive(workDate, request.unfreezeFrom, request.unfreezeTo))
    .sort((a, b) => getDateDifference(a.updatedAt, b.updatedAt));

  const latestUnfreezeRequest = filteredRequests[0];

  if (!latestUnfreezeRequest) {
    return false;
  }

  return latestUnfreezeRequest.isUnfrozen;
};
