export const MY_TEAM_FILTERS = {
  ISSUER: 'issuer',
  MANAGER: 'manager',
  APPRAISER: 'appraiser',
  COAPPRAISER: 'coappraiser'
};

export const RELATION_SORT_PRECEDENCE = {
  Appraiser: 1,
  'Leave Issuer': 2,
  'Co-Appraiser': 3,
  'Team Manager': 4,
  'Project Manager': 5,
  'Team Lead': 6,
  'Team Member': 7
};

export const MY_TEAM_SORT_BY = {
  PLANNED_LEAVES: 'plannedLeaves'
};

export const ORDER_BY = {
  ASC: 'asc',
  DESC: 'desc'
};

export const TEAM_MANAGER = 'Team Manager';

export const APPRAISER = 'Appraiser';
export const CO_APPRAISER = 'Co-Appraiser';
