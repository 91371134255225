import React from 'react';

import { FormSelect, DateSelector } from '../components';
import { generateReactSelectOptionsFromObject } from 'utils/reactSelect';

class EditEmployeeStatus extends React.Component {
  constructor(props) {
    super(props);
    const { selectedEmployeeStatus } = props;
    this.state = {
      selectedEmployeeStatus: { ...selectedEmployeeStatus },
      originalEmployeeStatus: selectedEmployeeStatus,
      showErrors: false
    };
  }

  componentWillReceiveProps(props) {
    const { selectedEmployeeStatus } = props;
    this.setState({
      selectedEmployeeStatus
    });
  }

  handleSaveClick = () => {
    const { errors } = this.props;
    if (!errors) this.props.handleSave();
    else
      this.setState({
        showErrors: true
      });
  };

  handleCancelClick = () => this.props.handleCancel(this.state.originalEmployeeStatus);

  handleChange = event => {
    const { name: changingKey, value: changingValue } = event.target;
    let changeTo = null;
    switch (changingKey) {
      case 'engagementStatus':
        changeTo = this.getEngagementStatusForId(changingValue);
        break;
      default:
        changeTo = changingValue;
        break;
    }
    this.setState(
      {
        selectedEmployeeStatus: {
          ...this.state.selectedEmployeeStatus,
          [changingKey]: changeTo
        }
      },

      () => {
        this.props.handleChange(this.state.selectedEmployeeStatus);
      }
    );
  };

  getEngagementStatusForId(id) {
    const { engagementStatus } = this.props;

    return engagementStatus.find(status => status.id === +id);
  }

  render() {
    const { engagementStatus, errors, isCreateForm } = this.props;

    const { selectedEmployeeStatus } = this.state;

    const engagementStatusSelectOptions = generateReactSelectOptionsFromObject(engagementStatus);
    return (
      <div className="card-form">
        <FormSelect
          label={'Employment Status'}
          name="engagementStatus"
          isMandatory={true}
          isDisabled={false}
          handleChange={this.handleChange}
          value={selectedEmployeeStatus.engagementStatus && selectedEmployeeStatus.engagementStatus.id}
          error={this.state.showErrors && errors && errors.engagementStatus && 'Please enter Employment Status'}
          options={engagementStatusSelectOptions}
        />
        <div>
          <div className="f-left">
            <DateSelector
              name="transitionDate"
              label="Transition Date"
              placeholderText="Pick a Transition Date"
              isMandatory={true}
              value={selectedEmployeeStatus.transitionDate}
              error={this.state.showErrors && errors && errors.transitionDate}
              handleChange={this.handleChange}
            />
          </div>

          <div className="f-right">
            <DateSelector
              name="endDate"
              label="End Date"
              placeholderText="Pick a End Date"
              isMandatory={false}
              value={selectedEmployeeStatus.endDate}
              error={this.state.showErrors && errors ? errors.endDate : ' '}
              handleChange={this.handleChange}
            />
          </div>
        </div>

        <div className="mt-30">
          <button type="submit" onClick={this.handleSaveClick} className="btn btn--primary f-left card-button mr-10 ">
            Save
          </button>
          <button
            type="button"
            disabled={isCreateForm}
            onClick={this.handleCancelClick}
            className="btn btn--secondary f-right card-button"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}
export default EditEmployeeStatus;
