import Lodash from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchAll } from 'services/role';
import { handleError } from '../utils/errorHandler';
import { STATUS, CAN_FETCH } from '../constants/redux';

const initialState = { value: [], loading: false, status: STATUS.IDLE };

export const fetchRoles = createAsyncThunk('roles/fetchRoles', async () => {
  try {
    const data = await fetchAll();

    return data;
  } catch (error) {
    handleError(error);
  }

  return initialState.value;
});

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRoles.pending, state => {
        if (CAN_FETCH.includes(state.status)) {
          return { ...state, loading: true, status: STATUS.PENDING };
        }

        return state;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        return {
          ...state,
          value: [...Lodash.sortBy(action.payload, ['name'])],
          loading: false,
          status: STATUS.FULFILLED
        };
      })
      .addCase(fetchRoles.rejected, state => {
        return { ...state, loading: false, status: STATUS.REJECTED };
      });
  }
});

export default rolesSlice.reducer;
