import lodash from 'lodash';
import classnames from 'classnames';
import { IoMdClose } from 'react-icons/io';
import { UiPlus, UiSearch } from 'vyaguta-icons/ui';
import React, { useCallback, useMemo, useState, useEffect } from 'react';

import * as roleService from 'services/role';

import { parse } from 'utils/queryParam';
import { pluralize } from 'utils/string';
import { handleError } from 'utils/errorHandler';

import { MAX_FILTER_TEXT_LENGTH } from 'constants/filters';

import DropDownSearch from 'components/common/dropdownsearch';

const UserPermissionsFilter = props => {
  const { data, applyFilter, setIsFilterApplied, isFilterApplied, handleAddUserClick } = props;
  const queryParams = parse(props.location.search);

  const [filters, setFilters] = useState({ ...queryParams });
  const [isFetchingRole, setIsFetchingRole] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [rolesOptionsFilter, setRolesOptionsFilter] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [selectedRolesList, setSelectedRolesList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setIsFetchingRole(true);
        const rolesData = await roleService.fetchAll();

        setRolesData(rolesData);
        setRolesOptionsFilter(rolesData);
        const selectedRoleIds = !filters.roleIds ? [] : filters.roleIds.split(',');
        if (selectedRoleIds.length > 0) {
          const selectedOptions = selectedRoleIds.map(selectedId => {
            const index = rolesData.findIndex(item => `${item.id}` === selectedId);
            return {
              value: selectedId,
              label: rolesData[index].name
            };
          });
          setRolesOptionsFilter(rolesData => rolesData.filter(item => !selectedRoleIds.includes(`${item.id}`)));
          setSelectedRolesList(selectedOptions);
        }
      } catch (error) {
        handleError(error);
      } finally {
        setIsFetchingRole(false);
      }
    })();
  }, []);

  const roleOptions = useMemo(() => {
    if (!rolesOptionsFilter) return [];

    const rolesList = rolesOptionsFilter.map(({ id, name }) => ({ value: id, label: name }));
    const sortedRoles = lodash.sortBy(rolesList, ['label']);

    return sortedRoles;
  }, [rolesOptionsFilter]);

  const showEllipses = value => {
    if (value && value.label.length > MAX_FILTER_TEXT_LENGTH) {
      value.label = value.label.slice(0, MAX_FILTER_TEXT_LENGTH) + '...';
    }
    return value;
  };

  const findDefaultRole = useCallback(() => {
    const result = roleOptions.find(({ value }) => +value === +filters.roleIds);

    return result ? result : { label: 'All' };
  }, [filters, roleOptions]);

  const updateFilter = newState => {
    setFilters(prevState => ({ ...prevState, ...newState }));
    setIsFilterChanged(true);
  };

  const handleApplyFilter = () => {
    applyFilter(filters);
    setIsFilterChanged(false);
    setIsFilterApplied(true);
  };

  const handleSearchClose = () => {
    const updatedFilters = { ...filters, q: undefined };
    if (!queryParams.q) {
      setFilters(updatedFilters);

      return;
    }
    setFilters(updatedFilters);
    applyFilter(updatedFilters);
  };

  const handleOnRoleChange = options => {
    setSelectedRolesList(options);
    if (options.length === 0) {
      setRolesOptionsFilter(rolesData);
      setSelectedRolesList([]);
      setFilters({ ...filters, roleIds: undefined });
      return;
    }
    const filteredList = rolesOptionsFilter.filter(role => role.id !== options['value']);

    setRolesOptionsFilter(filteredList);
  };

  useEffect(() => {
    if (!selectedRolesList.length) {
      if (!queryParams.roleIds) {
        setIsFilterChanged(false);
        setIsFilterApplied(false);
      }
      return;
    }

    updateFilter({ roleIds: selectedRolesList.map(({ value }) => value) });
  }, [selectedRolesList]);

  return (
    <div className="full-scope-card__header table-header name-wrap py-10">
      <div>
        <div className="table-title">Role Management</div>
        <div className="table-subtitle">
          Showing {data.length} {pluralize('Result', data.length)}
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="d-flex flex-row align-items-center roles__search-bar">
          <UiSearch className="roles__search-icon" />
          <input
            className="roles__search-input"
            value={filters.q ? filters.q : ''}
            type="text"
            onKeyDown={e => (e.key === 'Enter' ? (isFilterChanged ? handleApplyFilter() : undefined) : undefined)}
            onChange={event => updateFilter({ q: event.target.value.trimLeft() })}
            placeholder="Search by Name"
          />
          {filters.q && filters.q.trim().length > 0 && (
            <IoMdClose className="roles__search-icon__close" onClick={handleSearchClose} />
          )}
        </div>

        <DropDownSearch
          showSelected={true}
          title="Role"
          placeholder={'Role'}
          values={selectedRolesList}
          isPlain
          isBig
          isLoading={isFetchingRole}
          options={roleOptions}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          customClassName={classnames('lf-select', { 'lf-filter-applied': false })}
          onChange={handleOnRoleChange}
          searchLabel="All"
          className="py-0"
        />

        <div className="title-bar__filter-buttons d-flex align-items-center">
          <button
            className={classnames('button m-10 ml-24', {
              'button--primary': !isFilterChanged,
              'button--secondary': isFilterChanged
            })}
            onClick={isFilterChanged ? handleApplyFilter : undefined}
          >
            Apply Filter
          </button>

          <button
            className={classnames('button__reset', {
              'button__reset--active': isFilterApplied
            })}
            onClick={
              isFilterApplied
                ? () => {
                    setFilters({});
                    setIsFilterApplied(false);
                    setSelectedRolesList([]);
                    applyFilter({});
                  }
                : undefined
            }
          >
            Reset
          </button>

          <button className="button m-10 ml-24 button--secondary" onClick={() => handleAddUserClick(true)}>
            <span className="d-flex align-items-center white-space-nowrap">Add User</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserPermissionsFilter;
