import React, { useState } from 'react';
import { UiPen, UiTrash } from 'vyaguta-icons/ui';

import classNames from 'classnames';
import DeleteModal from './DeleteModal';
import { getFormattedDate } from 'utils/date';
import Tooltip from 'components/common/tooltip';
import { DATE_FORMAT_FOR_DISPLAY } from 'constants/appConstants';

const DesignationList = props => {
  const { title, transitionDate, openEditModal, handleDelete, employeeName, canEdit, isCurrent } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        onDelete={handleDelete}
        title="Do you want to delete the designation ?"
        deleteMessage={`You are about to delete the ${title} designation ${
          !!employeeName.trim() ? 'for ' + employeeName : ''
        }`}
        closeModal={() => setIsModalOpen(false)}
      />
      <div className="designation-list__full-width">
        <div
          className={classNames('designation-list__full-width__vertical', {
            'designation-list__full-width__vertical--glown': isCurrent
          })}
        ></div>
        <div className={classNames('designation-list__full-width__item', { 'pointer-events-none': !canEdit })}>
          <div>
            <p className="title">{title}</p>
            <p className="date">
              <span className="time">{getFormattedDate(transitionDate, DATE_FORMAT_FOR_DISPLAY)}</span>
            </p>
          </div>
          {canEdit && (
            <div className="right-icons">
              <div className="icon-container" onClick={openEditModal}>
                <Tooltip
                  html={
                    <div className="tooltip-info">
                      <p className="tooltip-info__title">Edit</p>
                    </div>
                  }
                >
                  <div className="icon-container--full">
                    <UiPen />
                  </div>
                </Tooltip>
              </div>
              <div className="icon-container icon-container--delete" onClick={() => setIsModalOpen(true)}>
                <Tooltip
                  html={
                    <div className="tooltip-info">
                      <p className="tooltip-info__title">Delete</p>
                    </div>
                  }
                >
                  <div className="icon-container--full">
                    <UiTrash />
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DesignationList;
