import React from 'react';
import { useMemo } from 'react';
import classNames from 'classnames';

import { FiX } from 'react-icons/fi';
import { UiQuestionCircle } from 'vyaguta-icons/ui';

import config from 'config';

import Table from 'components/common/table';

import { DATE_FORMAT_FOR_DISPLAY } from 'constants/appConstants';

import { pluralize } from 'utils/string';
import { getDaysCount, getFormattedDate } from 'utils/date';

const columns = [
  {
    Header: 'Leave days',
    accessor: 'leaveDays',
    sortable: false,
    resizable: false,
    maxWidth: 104
  },
  {
    Header: 'Added date',
    accessor: 'createdAt',
    sortable: false,
    resizable: false,
    Cell: props => getFormattedDate(props.original.createdAt, DATE_FORMAT_FOR_DISPLAY)
  },
  {
    Header: 'Expiry date',
    accessor: 'expiresOn',
    sortable: false,
    resizable: false,
    Cell: ({ original }) => {
      const expiryDaysLeft = getDaysCount(new Date(), original.expiresOn);

      return (
        <>
          {getFormattedDate(original.expiresOn, DATE_FORMAT_FOR_DISPLAY)}&nbsp;
          {original.isExpired && <span className="color-grey-50 fs10">(Expired)</span>}
          {!original.isExpired && !original.isUsed && (
            <span className="color-grey-50 fs10">{`(${expiryDaysLeft} ${pluralize('day', expiryDaysLeft)} left)`}</span>
          )}
        </>
      );
    }
  }
];

function WellBeingLeaveHistory({ wellBeingLeaveCredits, isLoading, onClose }) {
  const { unusedWellBeingLeaveCredits, usedWellBeingLeaveCredits } = useMemo(() => {
    if (!wellBeingLeaveCredits) {
      return { unusedWellBeingLeaveCredits: [], usedWellBeingLeaveCredits: [] };
    }

    return wellBeingLeaveCredits.reduce(
      (acc, leaveCredit) => {
        if (leaveCredit.isUsed || leaveCredit.isExpired) {
          acc.usedWellBeingLeaveCredits.push(leaveCredit);
        } else {
          acc.unusedWellBeingLeaveCredits.push(leaveCredit);
        }

        return acc;
      },
      { unusedWellBeingLeaveCredits: [], usedWellBeingLeaveCredits: [] }
    );
  }, [wellBeingLeaveCredits]);

  const checkHasBorderBottom = data =>
    data === unusedWellBeingLeaveCredits.at(-1) &&
    unusedWellBeingLeaveCredits.length &&
    usedWellBeingLeaveCredits.length;

  const getTrProps = (_state, row) => {
    return {
      className: classNames({
        'well-being-leave__table--greyed': row?.original?.isUsed || row?.original?.isExpired,
        'well-being-leave__table--bordered': checkHasBorderBottom(row?.original)
      })
    };
  };

  return (
    <div className="bg-white--base well-being-leave">
      <div className="well-being-leave__title">
        <div className="d-flex gap-x-8">
          <div className="">Well-being leave history</div>

          <a href={config.wellBeingLeavePolicy} className="well-being-leave__link" target="_blank" rel="noreferrer">
            <UiQuestionCircle size={14} />
            <span>Learn More</span>
          </a>
        </div>

        <button onClick={onClose} type="button" aria-label="Close Button" className="btn btn--sm p-0">
          <FiX size={20} />
        </button>
      </div>

      <div className="well-being-leave__table">
        <Table
          columns={columns}
          data={[...unusedWellBeingLeaveCredits.reverse(), ...usedWellBeingLeaveCredits.reverse()]}
          loading={isLoading}
          showPagination={false}
          getTrProps={getTrProps}
          noDataText="No well-being leave to show"
          noDataClassName="well-being-leave__empty"
          customClassName="rt-cursor-default"
        />
      </div>
    </div>
  );
}

export default WellBeingLeaveHistory;
