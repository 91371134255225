import moment from 'moment';
import { Formik } from 'formik';
import classnames from 'classnames';
import { calendar } from 'assets/images';
import { FiXCircle } from 'react-icons/fi';
import React, { useState, useCallback, useEffect } from 'react';

import history from 'utils/history';
import * as toast from 'utils/toast';
import * as routes from 'constants/routes';
import Badge from 'components/common/badge';
import * as noteService from 'services/note';
import noteSchema from 'schemas/addNoteSchema';
import { handleError } from 'utils/errorHandler';
import { formatNoteAuthorizedUsers } from 'utils/user';
import Loading from 'components/common/loading/Loading';
import { getEmpImageUrl, interpolate } from 'utils/string';
import DatePicker from 'components/common/datepicker/DatePicker';
import Input from 'components/common/inputWrapper/components/Input';
import AvatarComponent from 'components/common/avatar/AvatarComponent';
import useEmployeeDebounceOptions from 'hooks/useEmployeeDebounceOptions';
import DebounceFormDropdown from 'components/common/debounceFormDropdown/DebounceFormDropdown';
import FormRadioGroup from 'components/home/employees/components/employeeForm/inputWrapper/components/RadioGroup';
import {
  INITIAL_EMPLOYEE_SELECT_VALUE,
  LIST_TYPE,
  NOTE_DESCRIPTION_PLACEHOLDER,
  NOTE_FORM_FIELD_VALUES,
  NOTE_STATUS,
  REACT_DATEPICKER_DATE_FORMAT
} from 'constants/appConstants';
import { getFormattedDate } from 'utils/date';

const initialValues = {
  label: '',
  status: 'active',
  description: '',
  actionableUsers: '',
  viewers: '',
  actionDate: ''
};

const NoteForm = props => {
  const { note, location } = props;
  const [submitting, setSubmitting] = useState(false);
  const [selectedViewerList, setSelectedViewerList] = useState([]);
  const [selectedActionablePerson, setSelectedActionablePerson] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  const actionableUsersDebounceOptions = useEmployeeDebounceOptions();
  const viewersDebounceOptions = useEmployeeDebounceOptions();

  const userId = location.pathname.split('/')[2];

  const handleSubmit = async values => {
    const notePayload = {
      label: values.label?.trim(),
      description: values.description?.trim(),
      status: values.status,
      viewers: values.viewers,
      actionableUsers: values.actionableUsers,
      userId: userId,
      actionDate: values.actionDate
    };

    try {
      setSubmitting(true);

      if (note) {
        await noteService.updateNote(note.id, notePayload);
      } else {
        await noteService.createNote(notePayload);
      }

      toast.success({
        title: 'Success',
        message: note ? 'Note updated successfully.' : 'Note created successfully.'
      });

      history.push(interpolate(routes.EMPLOYEE_NOTE, { userId }));
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!note) {
      return;
    }

    setIsEditable(true);

    setSelectedViewerList(note.viewers);
    setSelectedActionablePerson(note.actionableUsers);
  }, [note]);

  const handleNoteUserSelectChange = (options, setFieldValue, setSelectedList, selectedList) => {
    const isUserSelected = selectedList.find(user => user.id === options.target.value);

    if (isUserSelected) {
      return;
    }

    const employeeOptions =
      options.target.name === NOTE_FORM_FIELD_VALUES.VIEWERS
        ? viewersDebounceOptions[1]
        : actionableUsersDebounceOptions[1];
    const formattedUser = formatNoteAuthorizedUsers(employeeOptions);

    const selectedUser = formattedUser.find(user => user.id === options.target.value);
    const newPersonList = [...selectedList, selectedUser];

    options && setSelectedList(newPersonList);
    setFieldValue(`${options.target.name}`, newPersonList);
  };

  const handleRemovePeople = (id, name, setFieldValue, values, setSelectedList) => {
    if (name === NOTE_FORM_FIELD_VALUES.VIEWERS) {
      setFieldValue(
        NOTE_FORM_FIELD_VALUES.VIEWERS,
        values.viewers?.filter(user => user.id !== id)
      );
    }

    if (name === NOTE_FORM_FIELD_VALUES.ACTIONABLE_PEOPLE) {
      setFieldValue(
        NOTE_FORM_FIELD_VALUES.ACTIONABLE_PEOPLE,
        values.actionableUsers?.filter(user => user.id !== id)
      );
    }

    setSelectedList(person => person.filter(user => user.id !== id));
  };

  const removeOptionsCallback = useCallback(
    options => {
      return options.filter(option => +option.value !== +userId);
    },
    [userId]
  );

  const handleCancelEvent = () => history.push(interpolate(routes.EMPLOYEE_NOTE, { userId }));

  return (
    <Formik
      initialValues={note ? { ...note } : { ...initialValues }}
      validationSchema={noteSchema}
      onSubmit={handleSubmit}
      render={({ handleChange, handleSubmit, touched, errors, values, handleBlur, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="full-scope-card__content">
            <div className="form-wrap">
              <div className="form-wrap__row form-wrap__row--no-margin clearfix">
                <div className="form-wrap__col col-sm-6 px-0">
                  <div className="form-group">
                    <label className="form-label dark--text">
                      Title<span className="required ml-5">*</span>
                    </label>
                    <Input
                      name="label"
                      onBlur={handleBlur}
                      value={values.label}
                      error={touched.label && errors.label}
                      onChange={handleChange}
                      placeholder="Please enter title"
                      multiline={false}
                      type="text"
                      customClassName="note-input"
                    />
                  </div>
                  {isEditable && (
                    <div className="form-group">
                      <label className="form-label dark--text">
                        Status<span className="required ml-5">*</span>
                      </label>
                      <FormRadioGroup
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.status}
                        error={touched.status && errors.status}
                        name="status"
                        radioList={NOTE_STATUS}
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <label className="form-label dark--text">
                      Viewers<span className="required ml-5">*</span>
                    </label>
                    <DebounceFormDropdown
                      name="viewers"
                      type={LIST_TYPE.EMPLOYEE}
                      debounceOptions={viewersDebounceOptions}
                      value={INITIAL_EMPLOYEE_SELECT_VALUE}
                      error={touched.viewers && errors.viewers}
                      handleChange={state =>
                        handleNoteUserSelectChange(state, setFieldValue, setSelectedViewerList, selectedViewerList)
                      }
                      iconComponent={AvatarComponent}
                      removeOptionsCallback={removeOptionsCallback}
                    />
                    <div className={classnames('badge-wrap', { 'mt-3x': selectedViewerList.length !== 0 })}>
                      {selectedViewerList?.map(user => (
                        <Badge
                          key={user?.id}
                          handleOnClick={() => {
                            const url = interpolate(routes.EMPLOYEE_PROFILE, { id: user.id });

                            window.open(url);
                          }}
                          imageUrl={getEmpImageUrl(user?.empId)}
                          closeIcon={
                            <FiXCircle
                              color="#999999"
                              size={18}
                              onClick={() =>
                                handleRemovePeople(user?.id, 'viewers', setFieldValue, values, setSelectedViewerList)
                              }
                            />
                          }
                          label={user?.fullName}
                          color={'color-grey-60'}
                          clickableLabel={'badge__clickable-label'}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label dark--text">
                      Actionable Peoples<span className="required ml-5">*</span>
                    </label>
                    <DebounceFormDropdown
                      name="actionableUsers"
                      type={LIST_TYPE.EMPLOYEE}
                      debounceOptions={actionableUsersDebounceOptions}
                      value={INITIAL_EMPLOYEE_SELECT_VALUE}
                      error={touched.actionableUsers && errors.actionableUsers}
                      handleChange={state =>
                        handleNoteUserSelectChange(
                          state,
                          setFieldValue,
                          setSelectedActionablePerson,
                          selectedActionablePerson
                        )
                      }
                      iconComponent={AvatarComponent}
                      removeOptionsCallback={removeOptionsCallback}
                    />
                    <div className={classnames('badge-wrap', { 'mt-3x': selectedActionablePerson.length !== 0 })}>
                      {selectedActionablePerson?.map(user => (
                        <Badge
                          key={user?.id}
                          handleOnClick={() => {
                            const url = interpolate(routes.EMPLOYEE_PROFILE, { id: user.id });

                            window.open(url);
                          }}
                          imageUrl={getEmpImageUrl(user?.empId)}
                          closeIcon={
                            <FiXCircle
                              color="#999999"
                              size={18}
                              onClick={() =>
                                handleRemovePeople(
                                  user?.id,
                                  'actionableUsers',
                                  setFieldValue,
                                  values,
                                  setSelectedActionablePerson
                                )
                              }
                            />
                          }
                          label={user?.fullName}
                          color={'color-grey-60'}
                          clickableLabel={'badge__clickable-label'}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="form-group notes-date-picker">
                    <label className="form-label dark--text">
                      Action Date<span className="required ml-5">*</span>
                    </label>
                    <DatePicker
                      customInputIcon={<img className="form-icon" src={calendar} alt="Calendar" />}
                      date={values.actionDate && moment(values.actionDate)}
                      dateFormat={REACT_DATEPICKER_DATE_FORMAT}
                      hasError={touched.actionDate && errors.actionDate}
                      onDateChange={selectedDate => {
                        setFieldValue('actionDate', getFormattedDate(selectedDate));
                      }}
                      openDirection="up"
                      isOutsideRange={date => date.isBefore(getFormattedDate(values?.createdAt))}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                    />
                    {errors.actionDate && touched.actionDate && <div className="error-block">{errors.actionDate}</div>}
                  </div>
                  <div className="form-group">
                    <label className="form-label dark--text">
                      Description<span className="required ml-5">*</span>
                    </label>
                    <textarea
                      name="description"
                      type="text"
                      value={values.description}
                      placeholder={NOTE_DESCRIPTION_PLACEHOLDER}
                      className={classnames('form-elem form-elem--input-sm', {
                        'form-elem--error': errors.description && touched.description
                      })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    ></textarea>
                    {errors.description && touched.description && (
                      <div className="error-block">{errors.description}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="action-bar-footer action-bar-footer--bordered-top px-16">
              <button
                type="submit"
                className={classnames('btn btn--primary btn--curved mr-16', {
                  'btn--loading': submitting
                })}
                disabled={submitting}
              >
                <span className={classnames({ invisible: submitting })}>Save</span>
                {submitting && <Loading className="light" />}
              </button>
              <button
                type="button"
                className={classnames('btn btn--outlined-grey btn--curved mr-10')}
                disabled={submitting}
                onClick={handleCancelEvent}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default NoteForm;
