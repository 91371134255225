import moment from 'moment';
import lodash from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import config from 'config';

import * as userService from 'services/user';
import * as mixpanel from 'services/mixPanel';
import * as attendanceService from 'services/attendance';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { intersect } from 'utils/array';
import { getDateDifference } from 'utils/date';
import { handleError } from 'utils/errorHandler';
import { getLeaveIssueCountMessage } from 'utils/issueeCountMessage';
import { extractFullName, getFirstName, interpolate } from 'utils/string';

import Loading from '../../common/loading/Loading';
import PendingActionsInfo from './PendingActionsInfo';
import LeaveIssuersHistory from './LeaveIssuersHistory';
import InputWrapper from 'components/common/inputWrapper';
import DatePicker from 'components/common/datepicker/DatePicker';
import ProfileHeader from '../employees/components/ProfileHeader';
import Info from 'components/common/inputWrapper/components/Info';
import { FormSelect } from '../CreateUpdateEmployeeForm/components';
import AvatarComponent from 'components/common/avatar/AvatarComponent';

import {
  MIN_DEBOUNCE_TIME,
  TYPE_USER_TEXT,
  SEARCH_LEAVE_ISSUER_TEXT,
  MIN_USER_SEARCH_LENGTH,
  NO_USERS_FOUND_TEXT,
  REACT_DATEPICKER_DATE_FORMAT,
  ROLE
} from 'constants/appConstants';
import { mixPanelEvents } from 'constants/mixPanel';
import { ALL, MAKE_SEARCH_BY } from 'constants/fetchTypes';
import { DEFAULT_ISSUE_ALERT_COUNT, USER_TEAM_COUNT_TYPE } from 'constants/leave';

const selectedFieldToState = {
  leaveIssuer: 'selectedLeaveIssuer',
  transitionDate: 'transitionDate'
};

class ChangeLeaveIssuer extends Component {
  constructor(props) {
    super(props);
    window.document.title = 'Leave issuer | Vyaguta';

    this.state = {
      employee: null,
      loadingEmployee: true,
      lodadingHistory: true,
      loadingLeaveIssuers: false,
      id: props.match.params.id,
      user: {
        name: '',
        currentLeaveIssuer: '',
        currentLeaveIssuerId: 0
      },
      leaveIssuers: [],
      leaveIssuersHistory: [],
      selectedLeaveIssuer: null,
      transitionDate: null,
      currentTransitionDate: null,
      isSubmitting: false,
      errors: {
        leaveIssuer: false,
        transitionDate: false
      },
      leaveIssuerCode: '',
      issueCount: 0,
      firstName: '',
      loadingIssueCount: false,
      userId: this.props?.user?.id,
      pendingLeaves: 0,
      pendingWorklogs: 0
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.fetchEmployeeById();
      this.fetchLeaveIssuerHistory();
      this.fetchAttendanceSummary();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { leaveIssuerCode, selectedLeaveIssuer } = this.state;
    const { leaveIssuerCode: prevLeaveIssuerCode, selectedLeaveIssuer: prevSelectedLeaveIssuer } = prevState;

    if (leaveIssuerCode !== prevLeaveIssuerCode) {
      this.fetchEmployees(leaveIssuerCode);
    }

    if (selectedLeaveIssuer !== prevSelectedLeaveIssuer) {
      this.fetchIssueCount();
    }
  }

  fetchEmployeeById = async () => {
    this.setState({ loadingEmployee: true });
    try {
      const data = await userService.fetchById(this.state.id);
      window.document.title = this.getPageTitle(data);

      this.setState({
        employee: data,
        user: {
          name: extractFullName(data),
          currentLeaveIssuer: data.leaveIssuer ? extractFullName(data.leaveIssuer) : 'None',
          currentLeaveIssuerId: data.leaveIssuer.id
        }
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({ loadingEmployee: false });
    }
  };

  fetchLeaveIssuerHistory = async () => {
    this.setState({ lodadingHistory: true });

    try {
      const data = await userService.fetchLeaveIssuerHistory(this.state.id);

      this.setState({
        leaveIssuersHistory: data
      });

      this.setState({ currentTransitionDate: data.length && data[0].transitionDate });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({ lodadingHistory: false });
    }
  };

  fetchAttendanceSummary = async () => {
    try {
      const params = {
        fetchType: ALL,
        userId: this.state.id,
        fields: 'worklog,leave'
      };

      const { data } = await attendanceService.fetchSummary(params);

      this.setState({ pendingLeaves: data?.leave?.requested || 0, pendingWorklogs: data?.worklog?.submitted || 0 });
    } catch (error) {
      handleError(error);
    }
  };

  handleChange = event => {
    const selectedValue = event && event.target && event.target.value;
    const selectedFieldName = event && event.target && event.target.name;

    this.setState({
      [`${selectedFieldToState[selectedFieldName]}`]: this.getSelectedOptionValue(selectedFieldName, selectedValue),
      errors: {
        ...this.state.errors,
        [`${selectedFieldName}`]: false
      }
    });
  };

  leaveIssuerDebounceFunction = lodash.debounce(code => {
    this.setState({
      leaveIssuerCode: code
    });
  }, MIN_DEBOUNCE_TIME);

  handleLeaveIssuerCodeChange = code => {
    if (code.trimStart().length < MIN_USER_SEARCH_LENGTH) {
      this.setState({
        loadingLeaveIssuers: false,
        leaveIssuerCode: ''
      });

      return;
    }

    this.setState({
      loadingLeaveIssuers: true
    });
    this.leaveIssuerDebounceFunction(code);
  };

  getSelectedOptionValue = (fieldName, fieldValue) => {
    if (fieldName === 'leaveIssuer') {
      return fieldValue && this.state.leaveIssuers.find(leaveIssuer => fieldValue === leaveIssuer.value);
    } else if (fieldName === 'transitionDate') {
      return fieldValue;
    } else {
      return null;
    }
  };

  fetchEmployees = async code => {
    if (!code) {
      return;
    }

    try {
      const { data } = await userService.fetchAll({ q: code, searchBy: MAKE_SEARCH_BY.join(',') });
      const selectOptions = data.map(this.getLeaveIssuerSelectOptions);

      this.setState({
        leaveIssuers: selectOptions.filter(({ value: id }) => id !== +this.state.id)
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({
        loadingLeaveIssuers: false
      });
    }
  };

  fetchIssueCount = async () => {
    this.setState({ loadingIssueCount: true });

    if (!this.state.selectedLeaveIssuer) {
      return;
    }

    try {
      const { value: issuerId, label: fullName } = this.state.selectedLeaveIssuer;
      const firstName = getFirstName(fullName);
      const { count } = await userService.fetchIssueeCount(issuerId, {
        count: USER_TEAM_COUNT_TYPE.LEAVE_ISSUE
      });

      const { leaveIssuee } = count;

      this.setState({
        issueCount: leaveIssuee,
        firstName: firstName
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({
        loadingIssueCount: false
      });
    }
  };

  getLeaveIssuerSelectOptions = leaveIssuer => ({
    value: leaveIssuer.id,
    empId: leaveIssuer.empId,
    label: extractFullName(leaveIssuer),
    email: leaveIssuer.email
  });

  handleSubmit = async () => {
    const { selectedLeaveIssuer, transitionDate } = this.state;

    const { leaveIssuer: changeLeaveIssuer } = mixPanelEvents.change;

    if (!selectedLeaveIssuer || !transitionDate) {
      return this.setState({
        errors: {
          leaveIssuer: !selectedLeaveIssuer,
          transitionDate: !transitionDate
        }
      });
    }

    try {
      this.setState({
        isSubmitting: true
      });

      const leaveIssuer = {
        id: selectedLeaveIssuer.value,
        email: selectedLeaveIssuer.email,
        transitionDate
      };

      await userService.updateLeaveIssuer(leaveIssuer, this.state.id);

      mixpanel.trackEvent(changeLeaveIssuer);

      toast.success({
        title: 'Success',
        message: 'Leave Issuer has been updated successfully.'
      });

      this.redirectToEmployeeEdit();
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({
        isSubmitting: false
      });
    }
  };

  redirectToEmployeeEdit() {
    history.goBack();
  }

  getPageTitle = employee => {
    const employeeName = employee ? extractFullName(employee) : '';
    const pageTitle = employeeName ? `${employeeName}'s leave issuer | Vyaguta` : 'Leave issuer | Vyaguta';

    return pageTitle;
  };

  render() {
    const {
      user,
      errors,
      loadingEmployee,
      loadingLeaveIssuers,
      leaveIssuersHistory,
      employee,
      isSubmitting,
      leaveIssuers,
      transitionDate,
      currentTransitionDate,
      selectedLeaveIssuer,
      leaveIssuerCode,
      firstName,
      issueCount,
      loadingIssueCount,
      userId,
      pendingLeaves,
      pendingWorklogs
    } = this.state;

    const { userRoles = [] } = this.props;

    const pendingLeaveOrWorklog = pendingLeaves || pendingWorklogs;
    const isHrAdminOrSuperAdmin = Boolean(intersect(userRoles, [ROLE.SUPER_ADMIN, ROLE.HR_ADMIN]).length);

    const canEditLeaveIssuer = !pendingLeaveOrWorklog || isHrAdminOrSuperAdmin;

    if (loadingEmployee) {
      return (
        <div className="loading-container container">
          <Loading />
        </div>
      );
    }

    return (
      <>
        <div className="title profile-wrapper">
          <ProfileHeader employee={employee} user={this.props.user} />
        </div>
        <div className="container d-flex fd-row flex-fix name-wrap leaveissuer-page-container">
          <div className="full-scope-card momr-20 leaveissuer-form-container">
            <div className="full-scope-card__header table-header name-wrap">
              <div className="d-flex flex-row">
                <h3>Change Leave Issuer</h3>
              </div>
            </div>
            <div className="leaveissuer-form">
              <label className="font-label font-14 label-margin">
                <span className="font-weight-bold dark--text"> Name: </span>
                <span className="span-padding-left">{user.name}</span>
              </label>

              <label className="font-label font-14 label-margin">
                <span className="font-weight-bold dark--text"> Current Leave Issuer: </span>
                <span className="span-padding-left">
                  <Link to={interpolate(config.endpoints.lms.userProfile, { id: user.currentLeaveIssuerId })}>
                    {user.currentLeaveIssuer}
                  </Link>
                </span>
              </label>

              <PendingActionsInfo pendingLeaves={pendingLeaves} pendingWorklogs={pendingWorklogs} user={user.name} />

              <FormSelect
                label="Leave Issuer"
                name="leaveIssuer"
                placeholder={loadingLeaveIssuers ? 'Loading' : SEARCH_LEAVE_ISSUER_TEXT}
                value={selectedLeaveIssuer && selectedLeaveIssuer.value}
                isMandatory={true}
                error={errors.leaveIssuer ? 'Please Select a Leave Issuer' : null}
                handleChange={this.handleChange}
                options={leaveIssuers}
                isLoading={loadingLeaveIssuers}
                noOptionsMessage={() => (leaveIssuerCode.length ? NO_USERS_FOUND_TEXT : TYPE_USER_TEXT)}
                handleInputChange={this.handleLeaveIssuerCodeChange}
                iconComponent={AvatarComponent}
                isClearable={true}
                disabled={!canEditLeaveIssuer}
              />

              {selectedLeaveIssuer && !loadingIssueCount && (
                <Info
                  message={getLeaveIssueCountMessage({
                    issuerName: firstName,
                    issueCount: issueCount,
                    isLoggedInUser: selectedLeaveIssuer?.value === userId
                  })}
                  shouldAlert={issueCount >= DEFAULT_ISSUE_ALERT_COUNT}
                />
              )}

              <InputWrapper
                isMandatory={true}
                label="Transition Date"
                error={errors.transitionDate ? 'Please Select a transition date' : null}
              >
                <DatePicker
                  date={transitionDate ? moment(transitionDate) : null}
                  placeholderText="Pick a Transition Date"
                  dateFormat={REACT_DATEPICKER_DATE_FORMAT}
                  displayFormat="LL"
                  hasError={errors.transitionDate ? 'Please Select a transition date' : null}
                  isOutsideRange={date => getDateDifference(date, currentTransitionDate) >= 0}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  openDirection="up"
                  onDateChange={selectedDate =>
                    this.handleChange({
                      target: {
                        name: 'transitionDate',
                        value: moment(selectedDate).format('YYYY-MM-DD')
                      }
                    })
                  }
                />
              </InputWrapper>
              <button
                type="button"
                className="btn btn--outlined-grey f-left card-button mr-10 mb-20"
                onClick={this.redirectToEmployeeEdit}
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                type="button"
                className={classNames('btn f-right card-button', {
                  'btn--primary': !isSubmitting && canEditLeaveIssuer,
                  'btn--disabled': isSubmitting || !canEditLeaveIssuer,
                  'cursor-not-allowed': !canEditLeaveIssuer
                })}
                onClick={this.handleSubmit}
                disabled={isSubmitting || !canEditLeaveIssuer}
              >
                {isSubmitting ? <Loading /> : 'Update'}
              </button>
            </div>
          </div>
          <LeaveIssuersHistory leaveIssuers={leaveIssuersHistory} />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userRoles: state.userRoles.value
  };
};

export default connect(mapStateToProps)(ChangeLeaveIssuer);
