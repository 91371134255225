/**
 * Scrolls to the first element with the given classname.
 *
 * @param {string} className classname of the element to be scrolled to
 */
export const scrollToElement = className => {
  const element = document.getElementsByClassName(className)[0];

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};
