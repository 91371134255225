import classNames from 'classnames';
import React, { useState } from 'react';

import { UiMultiply, UiCheckCircle } from 'vyaguta-icons/ui';

import { OVERLAY_TIMEOUT, SKILL_STATUS, VERIFIED } from 'constants/skills';

import FlaggedRatingSelector from 'components/home/skills/components/FlaggedRatingSelector';
import { SkillBreadCrumb } from 'components/home/skills/components/SkillBreadCrumb';

const SkillReviewOverlay = props => {
  const { isOpenFor, setIsOverlayOpenFor, globalRatingScale, employee, setRefreshUserSkills } = props;

  const [closeOverlay, setCloseOverlay] = useState(false);

  const handleOverlayClose = shouldRefetch => {
    setCloseOverlay(true);

    setTimeout(() => {
      setIsOverlayOpenFor(null);

      if (shouldRefetch) {
        setRefreshUserSkills(prev => !prev);
      }

      setCloseOverlay(false);
    }, OVERLAY_TIMEOUT);
  };

  return (
    <div
      className={classNames('skill-overlay', {
        'skill-overlay--close': closeOverlay,
        'skill-overlay--open': !!isOpenFor
      })}
    >
      <div className="skill-overlay__header">
        <div className="title">Skills Details</div>
        <div className="close" onClick={() => handleOverlayClose(false)}>
          <UiMultiply />
        </div>
      </div>
      <div className="mb-20">
        <SkillBreadCrumb skills={isOpenFor.parentData} />
      </div>
      <div className="skill-heading">
        <div className="skill-heading__container">
          <div className="title">{isOpenFor.name}</div>
          {isOpenFor.skillStatus?.status === SKILL_STATUS.APPROVED && (
            <div className="verified-skill">
              <UiCheckCircle size={14} />
              {VERIFIED}
            </div>
          )}
        </div>
        <div className="description">{isOpenFor.description}</div>
      </div>
      <div className="mt-15">
        <FlaggedRatingSelector
          skillRatingScale={isOpenFor.localRating ? isOpenFor.localRating : globalRatingScale}
          skill={isOpenFor}
          userRating={isOpenFor.rating}
          employee={employee}
          handleOverlayClose={handleOverlayClose}
        />
      </div>
    </div>
  );
};

export default SkillReviewOverlay;
