import { UiPen } from 'vyaguta-icons/ui';
import { UiTrash } from 'vyaguta-icons/ui';

import createUpdateFormSchema from 'schemas/createUpdateFormSchema';
import LeapfrogHistoryForm from 'components/home/employees/components/employeeForm/forms/leapfrogHistory';
import OfficialInformationForm from 'components/home/employees/components/employeeForm/forms/officialInformation';
import AppraiserAllocationForm from 'components/home/employees/components/employeeForm/forms/appraiserAllocation';
import PersonalInformationForm from 'components/home/employees/components/employeeForm/forms/personalInformation';

export const getStepperNavigationList = isCreateForm => [
  {
    id: 1,
    label: 'Official Information',
    detail: 'Fill the information for the official purpose',
    state: '',
    formComponent: OfficialInformationForm,
    formSchema: createUpdateFormSchema.getOfficialInformationSchema(isCreateForm)
  },
  {
    id: 2,
    label: 'Personal Information',
    detail: 'Fill the personal information of the Leapfrogger',
    state: '',
    formComponent: PersonalInformationForm,
    formSchema: createUpdateFormSchema.ProfileInfo
  },
  {
    id: 3,
    label: 'Leapfrog History',
    detail: 'Fill the history of the leapfrogger',
    state: '',
    formComponent: LeapfrogHistoryForm,
    formSchema: createUpdateFormSchema.leapfrogHistory
  },
  {
    id: 4,
    label: 'Appraisers',
    detail: 'Fill appraiser and co appraiser of leapfrogger',
    formComponent: AppraiserAllocationForm,
    formSchema: createUpdateFormSchema.appraiserAllocation
  }
];

export const COMPLETE = 'Complete';
export const ERROR = 'Error';

export const NEXT = 'next';
export const PREV = 'prev';

export const imageDetail = {
  detail: ['Supported extension .jpg .jpeg .png ', 'Size should not be more than 5MB', ' Recommended Ratio 500x500px']
};

export const MAX_IMAGE_NUMBER = 1;
export const MAX_IMAGE_FILE_SIZE = 5000000; //i.e. 5MB

export const TEMPLATE_FOR_CV = 'https://docs.google.com/document/d/1_-d32ZcPjEsoXVd7A4AnTmMem62cJdm20IReb9GgzRU/edit';

export const RIGHT_ICONS = [
  {
    label: 'Edit',
    component: UiPen
  },
  {
    label: 'Delete',
    component: UiTrash
  }
];

export const PROFILE_IMAGE_DIMENSION = '50 x 50';
export const UNIT_TO_CONVERT_BYTE_IN_KILOBYTE = 1000;
export const ACCEPT_TYPE_ERROR = 'Your selected file type is not allowed';
export const MAX_FILE_SIZE_ERROR = 'Selected file size exceed 5mb';

export const EMPLOYEE_HISTORY_TYPES = {
  ENGAGEMENT_STATUS: 'ENGAGEMENT_STATUS',
  AREA_AND_DESIGNATION: 'DESIGNATION_AREA'
};

export const OFFICIAL_INFORMATION_FORM_INPUTS = [
  'firstName',
  'lastName',
  'empId',
  'joinDate',
  'username',
  'departmentId',
  'scheduledType',
  'workingShift',
  'employeeImage'
];

export const LEAPFROG_HISTORY_FORM_INPUTS = ['designationAreaHistory', 'empStatusHistory'];

export const PERSONAL_INFORMATION_FORM_INPUTS = [
  'gender',
  'dateofBirth',
  'bloodGroup',
  'maritialStatus',
  'personalEmail',
  'mobilePhone',
  'emergencyPhone',
  'emergencyContactRelationship',
  'temporaryAddress',
  'country'
];

export const APPRAISER_ALLOCATION_FORM_INPUTS = ['appraiserId'];
