import React from 'react';
import * as moment from 'moment';
import interpolate from 'pinterpolate';

import * as userService from 'services/user';

import Empty from 'components/common/empty/Empty';
import ProfileHeader from './components/ProfileHeader';
import Loading from 'components/common/loading/Loading';
import ProfileBody from './components/profile/ProfileBody';

import { extractFullName } from 'utils/string';
import { handleError } from 'utils/errorHandler';
import { validateEmail } from 'utils/validEmail';

import { connect } from 'react-redux';

import { EMPLOYEE_PROFILE } from 'constants/routes';
import { EMPLOYEE_STATUS_STYLES } from 'constants/employeeStatus';
import { ROLE_FLAGS, ROLE_FLAG_TO_VALUE } from 'constants/employeeRoles';

class Employee extends React.Component {
  constructor(props) {
    super(props);
    window.document.title = 'Profile | Vyaguta';

    this.state = {
      loading: true,
      viewingProfilePicture: false,
      employee: null
    };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const isValidEmail = validateEmail(id);

    if (isValidEmail) {
      try {
        const [userData] = await userService.fetchByEmail(id);

        if (!userData) {
          this.setState({
            loading: false,
            employee: null
          });

          return;
        }

        this.props.history.push(interpolate(EMPLOYEE_PROFILE, { id: userData.id }));

        return;
      } catch (error) {
        handleError(error);
      }
    }

    await this.fetchEmployee(id);
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      await this.fetchEmployee(nextProps.match.params.id);
    }
  }

  updateEmployee = data => {
    this.setState({
      employee: {
        ...data,
        empStatusHistory: data.empStatusHistory && data.empStatusHistory.reverse(),
        designationAreaHistory: data.designationAreaHistory && data.designationAreaHistory.reverse()
      }
    });
  };

  fetchEmployee = async userId => {
    try {
      const data = await userService.fetchById(userId);
      window.document.title = this.getPageTitle(data);

      this.updateEmployee(data);
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  getEmploymentInfo = employee => {
    const empStatus = employee.empStatus;
    const employeeStatusClass = EMPLOYEE_STATUS_STYLES[empStatus];

    return <div className={`uppercase-text emp-info ${employeeStatusClass}`}>{empStatus}</div>;
  };

  getEmployeeRoles = employee => {
    let roles = [];

    ROLE_FLAGS.forEach(flag => {
      if (employee[flag]) {
        roles.push(ROLE_FLAG_TO_VALUE[flag]);
      }
    });

    return roles.join(', ');
  };

  getEmployedNumberOfDay = (empStatus, joinDate) => {
    if (empStatus && empStatus.toLowerCase() !== 'terminated') {
      const noOfEmployedDay = moment().diff(moment(joinDate), 'days');
      const year = parseInt(noOfEmployedDay / 365);
      const day = noOfEmployedDay % 365;

      const yearString = year > 0 && `${year} ${year > 1 ? 'years' : 'year'}`;
      const dayString = `${day} ${day > 1 ? 'days' : 'day'}`;

      return (yearString ? yearString + ' ' : '') + dayString;
    }

    return null;
  };

  getOriginalUrlFrom(avatarUrl) {
    return avatarUrl && avatarUrl.replace('avatar', 'original');
  }

  isAuthorizedUser = () => this.props.user.isHr || this.props.user.id === this.state.employee.id;

  setViewingProfile = viewing => this.setState({ viewingProfilePicture: viewing });

  getPageTitle = employee => {
    const employeeFullName = employee ? extractFullName(employee) : '';
    const pageTitle = employeeFullName ? `${employeeFullName}'s profile | Vyaguta` : 'Profile | Vyaguta';

    return pageTitle;
  };

  render() {
    const { loading, employee } = this.state;
    return (
      <div>
        {loading ? (
          <div className="container loading-container">
            <Loading />
          </div>
        ) : employee ? (
          <div>
            <div className="title profile-wrapper">
              <ProfileHeader
                employee={this.state.employee}
                user={this.props.user}
                updateEmployee={this.updateEmployee}
              />
            </div>
            <ProfileBody {...this.props} employee={this.state.employee} authUser={this.props.user} />
          </div>
        ) : (
          <main>
            <div className="container">
              <div className="full-scope-card empty-container__wrapper">
                <Empty message="Employee does not exist!" />
              </div>
            </div>
          </main>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUserPermissions: state.information.value.permissions
  };
};

export default connect(mapStateToProps)(Employee);
