import * as Yup from 'yup';
import { getRequiredErrorLabel } from 'utils/string';
const globalRatingSchema = Yup.object({
  one: Yup.string()
    .trim()
    .required(getRequiredErrorLabel('description for rating 1.')),
  two: Yup.string()
    .trim()
    .required(getRequiredErrorLabel('description for rating 2.')),
  three: Yup.string()
    .trim()
    .required(getRequiredErrorLabel('description for rating 3.')),
  four: Yup.string()
    .trim()
    .required(getRequiredErrorLabel('description for rating 4.'))
});
export default globalRatingSchema;
