import moment from 'moment';
import { Formik } from 'formik';
import classNames from 'classnames';
import pinterpolate from 'pinterpolate';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { getFormattedDate } from 'utils/date';
import { handleError } from 'utils/errorHandler';
import { extractFullName, interpolate } from 'utils/string';

import * as userService from 'services/user';

import useTitle from 'hooks/useTitle';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import InputWrapper from 'components/common/inputWrapper';
import BackButton from 'components/common/button/BackButton';
import DatePicker from 'components/common/datepicker/DatePicker';

import ProfileHeader from '../employees/components/ProfileHeader';
import EmployeeHistoryList from '../employees/components/EmploymentHistoryList';

import {
  REACT_DATEPICKER_DATE_FORMAT,
  EMPLOYEE_RESIGNATION_DELETE_CONFIRMATION_TITLE,
  EMPLOYEE_RESIGNATION_DELETE_CONFIRMATION_MESSAGE
} from 'constants/appConstants';
import * as routes from 'constants/routes';
import { EMPLOYEES } from 'constants/permissions';

import { changeTentativeExitSchema } from 'schemas/changeTentativeExitSchema';

const ChangeTentativeExit = ({ user, match: { params } }) => {
  const {
    value: { permissions }
  } = useSelector(state => state.information);
  const userId = Number(params.id);

  const [employee, setEmployee] = useState({});
  const [isLoadingEmployee, setIsLoadingEmployee] = useState(false);
  const [empResignation, setEmpResignation] = useState({});
  const [isLoadingResignation, setIsLoadingResignation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const canEditResignation = permissions[EMPLOYEES.RESIGNATIONS.UPDATE];
  const canCreateResignation = permissions[EMPLOYEES.RESIGNATIONS.CREATE];
  const canDeleteResignation = permissions[EMPLOYEES.RESIGNATIONS.DELETE];
  const isEditMode = empResignation?.id;

  const setTitle = useTitle('Employee Resignation');

  const getInitialValues = empResignation => {
    return {
      reason: empResignation?.reason || '',
      tentativeExitDate: empResignation?.tentativeExitDate || null,
      informedDate: empResignation?.informedDate || null
    };
  };

  const initialValues = getInitialValues(empResignation);

  const fetchEmployeeById = async () => {
    try {
      setIsLoadingEmployee(true);

      const employee = await userService.fetchById(userId);

      setTitle(getPageTitle(employee));
      setEmployee(employee);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingEmployee(false);
    }
  };

  const fetchEmpResignation = async () => {
    try {
      setIsLoadingResignation(true);

      const resignation = await userService.fetchResignation(userId);

      setEmpResignation(resignation.data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingResignation(false);
    }
  };

  useEffect(() => {
    if (!userId) {
      return;
    }

    fetchEmployeeById();
    fetchEmpResignation();
  }, [userId]);

  const handleSubmit = async ({ reason, tentativeExitDate, informedDate }, submitProps) => {
    try {
      setIsSubmitting(true);

      tentativeExitDate = getFormattedDate(tentativeExitDate);
      informedDate = getFormattedDate(informedDate);

      const resignation = isEditMode
        ? await userService.updateResignation({ reason, tentativeExitDate, informedDate }, userId, empResignation.id)
        : await userService.createResignation({ reason, tentativeExitDate, informedDate }, userId);

      setEmpResignation(resignation.data);

      redirectToEmployeeProfile();

      toast.success({
        title: 'Success',
        message: isEditMode
          ? 'Tentative exit date has been updated successfully.'
          : 'Tentative exit date has been added successfully'
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDelete = async () => {
    if (isEditMode) {
      try {
        setIsDeleting(true);

        await userService.deleteResignation(userId, empResignation.id);

        setEmpResignation(null);

        setIsDeleteConfirmationModalOpen(false);

        redirectToEmployeeProfile();

        toast.success({
          title: 'Success',
          message: 'Tentative exit date has been deleted successfully.'
        });
      } catch (error) {
        handleError(error);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const redirectToEmployeeProfile = () => {
    history.push(interpolate(routes.EMPLOYEE_PROFILE, { id: userId }));
  };

  const getPageTitle = employee => {
    const employeeName = employee ? extractFullName(employee) : '';
    const pageTitle = employeeName ? `${employeeName}'s resignation` : 'Employee Resignation';

    return pageTitle;
  };

  const showDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const DeleteConfirmationModal = ({ isOpen, message, title, onConfirm, onCancel, isDeleting }) => (
    <LFModal
      isOpen={isOpen}
      renderFooter={true}
      title={title}
      className="delete-modal"
      customBtnClass="hidden"
      message={message}
      buttons={[
        {
          text: 'Yes, delete',
          type: 'red',
          className: 'mr-12 delete__btn',
          close: true,
          showLoading: true,
          isLoading: isDeleting,
          onClick: onConfirm
        },
        {
          text: 'Cancel',
          type: 'outlined-grey',
          className: 'py-8 px-12',
          onClick: !isDeleting && onCancel
        }
      ]}
    />
  );

  if (isLoadingEmployee || isLoadingResignation) {
    return (
      <div className="container loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        title={{
          text: EMPLOYEE_RESIGNATION_DELETE_CONFIRMATION_TITLE,
          type: `danger`
        }}
        message={{
          text: pinterpolate(EMPLOYEE_RESIGNATION_DELETE_CONFIRMATION_MESSAGE, {
            firstName: employee.firstName,
            lastName: employee.lastName
          })
        }}
        onConfirm={onDelete}
        onCancel={closeDeleteConfirmationModal}
        isDeleting={isDeleting}
      />

      <div className="title profile-wrapper">
        <ProfileHeader employee={employee} user={user} />
      </div>
      <div className="container d-flex fd-row flex-fix name-wrap change-tentative-exit">
        <div className="full-scope-card momr-20 change-tentative-exit__form-container">
          <div className="change-tentative-exit__title name-wrap">
            <BackButton />
            <h3>{!isEditMode ? 'Add' : 'Edit'} Tentative Exit Date</h3>
          </div>

          <Formik
            key={JSON.stringify(initialValues)}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={changeTentativeExitSchema}
          >
            {({ handleSubmit, errors, values, dirty, resetForm, setFieldValue, touched, setFieldTouched }) => (
              <form onSubmit={handleSubmit} className="change-tentative-exit__form">
                <div className="change-tentative-exit__form-body">
                  <InputWrapper
                    isMandatory
                    label="Resignation email received on."
                    error={touched.informedDate && errors.informedDate}
                  >
                    <DatePicker
                      date={values.informedDate ? moment(values.informedDate) : null}
                      placeholderText="Select Date"
                      dateFormat={REACT_DATEPICKER_DATE_FORMAT}
                      displayFormat="LL"
                      hasError={touched.informedDate && errors.informedDate}
                      openDirection="up"
                      onDateChange={selectedDate => {
                        setFieldTouched('informedDate', true, true);
                        setFieldValue('informedDate', selectedDate);
                      }}
                      disabled={isSubmitting}
                    />
                  </InputWrapper>

                  <InputWrapper
                    isMandatory
                    label="Tentative exit date"
                    error={touched.tentativeExitDate && errors.tentativeExitDate}
                  >
                    <DatePicker
                      date={values.tentativeExitDate ? moment(values.tentativeExitDate) : null}
                      placeholderText="Select Date"
                      dateFormat={REACT_DATEPICKER_DATE_FORMAT}
                      displayFormat="LL"
                      hasError={touched.tentativeExitDate && errors.tentativeExitDate}
                      openDirection="up"
                      onDateChange={selectedDate => {
                        setFieldTouched('tentativeExitDate', true, true);
                        setFieldValue('tentativeExitDate', selectedDate);
                      }}
                      disabled={isSubmitting}
                    />
                  </InputWrapper>

                  <InputWrapper isMandatory label="Reason" error={touched.reason && errors.reason}>
                    <textarea
                      name="reason"
                      placeholder="Type your reason..."
                      className={classNames(
                        'form-elem font-weight-regular custom-scroll-bar change-issuer-request__reason',
                        {
                          'form-elem--error': touched.reason && errors.reason
                        }
                      )}
                      value={values.reason}
                      onChange={e => {
                        setFieldTouched('reason', true, true);
                        setFieldValue('reason', e.target.value);
                      }}
                      disabled={isSubmitting}
                    />
                  </InputWrapper>
                </div>

                <div className="change-tentative-exit__footer">
                  {isEditMode && canEditResignation && (
                    <button
                      type="submit"
                      className={classNames('btn card-button change-tentative-exit__btn', {
                        'btn--primary': !isSubmitting && dirty,
                        'btn--disabled': isSubmitting || !dirty
                      })}
                      disabled={isSubmitting || !dirty}
                    >
                      <span className={classNames({ invisible: isSubmitting })}>Save</span>
                      {isSubmitting && <Loading />}
                    </button>
                  )}

                  {!isEditMode && canCreateResignation && (
                    <button
                      type="submit"
                      className={classNames('btn btn--primary card-button change-tentative-exit__btn', {
                        'btn--primary': !isSubmitting,
                        'btn--disabled': isSubmitting
                      })}
                      disabled={isSubmitting}
                    >
                      <span className={classNames({ invisible: isSubmitting })}>Add</span>
                      {isSubmitting && <Loading />}
                    </button>
                  )}

                  <button
                    type="button"
                    className={classNames('btn card-button ml-10 change-tentative-exit__btn', {
                      'btn--outlined-grey': !isSubmitting,
                      'btn--disabled': isSubmitting
                    })}
                    onClick={() => redirectToEmployeeProfile()}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>

                  {isEditMode && canDeleteResignation && (
                    <button
                      type="button"
                      className="btn btn--outlined-danger f-right card-button change-tentative-exit__btn"
                      onClick={showDeleteConfirmationModal}
                      disabled={isSubmitting}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="full-scope-card emp-history-area emp-history-wrapper custom-scroll-bar change-tentative-exit__sidebar">
          <EmployeeHistoryList employee={employee} />
        </div>
      </div>
    </>
  );
};

export default ChangeTentativeExit;
