import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { interpolate } from 'utils/string';
import * as feedbackService from '../services/feedback';

import { urls } from 'constants/jump';
import { STATUS, CAN_FETCH } from '../constants/redux';

const initialState = { value: [], loading: false, status: STATUS.IDLE };

export const fetchUserFeedbacks = createAsyncThunk(
  'userFeedbacks/fetchUserFeedbacks',
  async ({ employeeId, params }) => {
    try {
      const data = await feedbackService.fetchUserFeedbacks(employeeId, params);

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const userFeedbacksSlice = createSlice({
  name: 'userFeedbacks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserFeedbacks.pending, state => {
        if (CAN_FETCH.includes(state.status)) {
          return { ...state, loading: true, status: STATUS.PENDING };
        }

        return state;
      })
      .addCase(fetchUserFeedbacks.fulfilled, (state, action) => {
        const mutatedState = action.payload.map(feedback => ({
          ...feedback,
          link: interpolate(urls.FEEDBACK_DETAIL_VIEW, { id: feedback.feedbackId })
        }));

        return {
          ...state,
          value: [...state.value, ...mutatedState],
          loading: false,
          status: STATUS.FULFILLED
        };
      })
      .addCase(fetchUserFeedbacks.rejected, state => {
        return { ...state, loading: false, status: STATUS.REJECTED };
      });
  }
});

export default userFeedbacksSlice.reducer;
