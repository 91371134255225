import { FiAlertTriangle } from 'react-icons/fi';
import { UiTrash, UiPen } from 'vyaguta-icons/ui';
import React, { useEffect, useState } from 'react';

import * as roleServices from 'services/role';

import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';

import UserDetail from './UserDetail';
import CreateEditRole from './CreateEditRole';
import LFModal from 'components/common/LFModal/LFModal';

const DeletePopup = ({ onClick }) => {
  return (
    <li
      className="overflow-menu__list-item overflow-menu__list-item--hoverable color-tertiary-red-40 d-flex"
      onClick={onClick}
    >
      <span className="icon overflow-menu__icon icon--delete">
        <UiTrash className="tertiary-red.40" size={16} />
      </span>
      Delete role(s)
    </li>
  );
};

const EditRoles = ({ onClick }) => {
  return (
    <li className="overflow-menu__list-item overflow-menu__list-item--hoverable d-flex" onClick={onClick}>
      <span className="icon overflow-menu__icon">
        <UiPen className="grey--text" size={16} />
      </span>
      <span>Edit role(s)</span>
    </li>
  );
};

const VerticalEllipsis = props => {
  const { selectedUser, fetchUserRoles } = props;

  const [selectedRolesList, setSelectedRolesList] = useState(selectedUser.roles || []);

  const [firstName] = selectedUser.fullName.split(' ');
  const errorText = 'Please select role(s)';
  const deleteMessage = `${selectedUser.fullName} is removed from the permission.`;
  const editMessage = ` You have successfully updated the role for ${selectedUser.fullName}.`;

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(selectedRolesList.length === 0);
  }, [selectedRolesList]);

  const upsertRoles = async (user, selectedRolesList) => {
    const roles = selectedRolesList ? selectedRolesList : [];
    const message = selectedRolesList ? editMessage : deleteMessage;

    if (isError) {
      return;
    }

    try {
      await roleServices.updateUserRoles(user.id, { roles });

      fetchUserRoles();

      toast.success({
        title: 'Success',
        message
      });
    } catch (error) {
      handleError(error);
    }
  };

  const listRoles = selectedRolesList => {
    return selectedRolesList
      .map((role, index) => {
        return index !== selectedRolesList.length - 1 ? role.name + ', ' : role.name;
      })
      .join('');
  };

  return (
    <div className="overflow-menu">
      <div className="overflow-menu__block">
        <ul className="overflow-menu__list">
          <LFModal
            TriggerBtn={EditRoles}
            title={{ text: 'Edit Roles', type: 'info' }}
            className="edit-role"
            htmlContent={
              <>
                <UserDetail detail={selectedUser} />
                <CreateEditRole
                  setSelectedRolesList={setSelectedRolesList}
                  selectedRolesList={selectedRolesList}
                  isEditable={true}
                  errors={{ roles: errorText }}
                  touched={{ roles: isError }}
                />
                {isError && <div className="error-block">{errorText}</div>}
              </>
            }
            renderFooter={true}
            buttons={[
              {
                text: 'Save & Close',
                type: 'primary',
                className: 'mr-16',
                onClick: () => upsertRoles(selectedUser, selectedRolesList),
                close: !isError,
                showLoading: true
              },
              {
                text: 'Cancel',
                type: 'outlined-grey',
                className: 'py-8 px-12',
                onClick: () => setSelectedRolesList(selectedUser.roles),
                close: true
              }
            ]}
          />

          <LFModal
            TriggerBtn={DeletePopup}
            modalStatusIcon={<FiAlertTriangle color="#F44336" size={24} />}
            title={{ text: `Delete role(s) for ${firstName}?`, type: 'danger' }}
            className="delete-modal"
            htmlContent={
              <div className="d-grid mbpx-4">
                <span className="delete__text">All assigned roles will be deleted:</span>
                <span className="delete__title">{listRoles(selectedRolesList)}</span>
                <span className="delete__text">This action would turn {selectedUser.fullName} to a common user. </span>
              </div>
            }
            renderFooter={true}
            buttons={[
              {
                text: 'Yes, delete',
                type: 'red',
                className: 'mr-12 delete__btn',
                onClick: () => upsertRoles(selectedUser),
                close: true,
                showLoading: true
              },
              {
                text: 'Nevermind',
                type: 'outlined-grey',
                className: 'py-8 px-12',
                close: true
              }
            ]}
          />
        </ul>
      </div>
    </div>
  );
};

export default VerticalEllipsis;
