import http from '../utils/http';
import { interpolate } from 'utils/string';
import { HOLIDAYS, HOLIDAY_URL_ID } from '../constants/endpoints';

export async function fetch(params) {
  return await http.get(HOLIDAYS, { params });
}

export async function create(holiday) {
  return await http.post(HOLIDAYS, { body: holiday });
}

export async function update(id, holiday) {
  return await http.put(interpolate(HOLIDAY_URL_ID, { id }), { body: holiday });
}

export async function fetchById(id) {
  return await http.get(interpolate(HOLIDAY_URL_ID, { id }));
}

export async function remove(id) {
  return await http.delete(interpolate(HOLIDAY_URL_ID, { id }));
}
