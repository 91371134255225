import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as goalService from '../services/goal';
import { handleError } from '../utils/errorHandler';
import { STATUS, CAN_FETCH } from '../constants/redux';

const initialState = { value: [], loading: false, status: STATUS.IDLE };

export const fetchUserGoals = createAsyncThunk('userGoals/fetchUserGoals', async ({ employeeId, params }) => {
  try {
    const data = await goalService.fetchUserGoals(employeeId, params);

    return data;
  } catch (error) {
    handleError(error);
  }

  return initialState.value;
});

export const userGoalSlice = createSlice({
  name: 'userGoals',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserGoals.pending, state => {
        if (CAN_FETCH.includes(state.status)) {
          return { ...state, loading: true, status: STATUS.PENDING };
        }

        return state;
      })
      .addCase(fetchUserGoals.fulfilled, (state, action) => {
        return {
          ...state,
          value: action.payload,
          loading: false,
          status: STATUS.FULFILLED
        };
      })
      .addCase(fetchUserGoals.rejected, state => {
        return { ...state, loading: false, status: STATUS.REJECTED };
      });
  }
});

export default userGoalSlice.reducer;
