import * as Yup from 'yup';

const skillSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z][\sa-z0-9A-Z\-/]{1,50}$/, 'Invalid Name')
    .required('Please enter the skill name'),
  description: Yup.string()
    .matches(/^[a-zA-Z][\sa-z0-9A-Z\-/]{1,200}$/, 'Invalid Description')
    .nullable(),
  skillCategory: Yup.object()
    .shape({
      id: Yup.number().required(),
      name: Yup.string().required()
    })
    .required('Please enter skill category')
});

export default skillSchema;
