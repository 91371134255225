import React from 'react';
import interpolate from 'pinterpolate';

import config from 'config';

import PopOver from 'components/common/popover';

import { DATE_FORMAT_FOR_DISPLAY } from 'constants/appConstants';
import { CANDIDATE_PIPELINE_MAPPED_COLORS, RADIX } from 'constants/bookingAllocation';

import { intersect } from 'utils/array';
import { getFormattedDate } from 'utils/date';
import { pluralize } from 'utils/string';

export default function BookingAllocation(props) {
  const { bookedAllocation } = props;

  const {
    role,
    duration,
    userSkills,
    allocation,
    bookedArea,
    targetDate,
    availability,
    bookingStatus,
    bookedProject,
    requestSkills,
    bookingCategory
  } = bookedAllocation;

  const userSkillsName = userSkills?.map(item => item.name) || [];

  const requestSkillsName = requestSkills?.map(item => item.name) || [];

  const commonSkillsName = intersect(userSkillsName, requestSkillsName);

  const staffingDuration = duration.toString().split('.');
  const months = parseInt(staffingDuration[0], RADIX);
  const days = Math.round(parseInt(staffingDuration[1], RADIX) * 0.3);

  const calculateSkillMatch = (skills, requiredSkills) => {
    const rating = `${skills}/${requiredSkills}`;

    if (!requiredSkills) {
      return { rating, percentage: 0 };
    }

    const percentage = Math.round((skills / requiredSkills) * 100);

    return { rating, percentage };
  };

  const getPillColor = percent => {
    switch (true) {
      case percent >= 80:
        return 'green';
      case percent >= 50:
        return 'blue';
      case percent >= 30:
        return 'orange';
      default:
        return 'red';
    }
  };

  return (
    <div>
      <div>
        {bookedArea?.name ? (
          <p className="booking-allocation__title">{bookedArea.name}</p>
        ) : (
          bookedProject?.name && (
            <p
              className="cursor-pointer project-name__link booking-allocation__title"
              onClick={() =>
                window.open(
                  interpolate(`${config.teamsAppURL}${config.endpoints.teams.app.project}`, {
                    id: bookedProject.id
                  })
                )
              }
            >
              {bookedProject.name}
            </p>
          )
        )}
      </div>
      <div className="d-flex gap-px-12">
        <div className="flex-1 booking-allocation__info-wrapper">
          <div className="booking-allocation__info-container">
            <p className="booking-allocation__info-title">Allocation</p>
            <p className="booking-allocation__info-content">
              {allocation}
              {pluralize('hr', allocation)}/week &#xb7;{' '}
              {`${months > 0 ? `${months} months` : ''} ${days > 0 ? `${days} days` : ''}`}
            </p>
          </div>
          <div className="booking-allocation__info-container">
            <p className="booking-allocation__info-title">Target date</p>
            <p className="booking-allocation__info-content">{getFormattedDate(targetDate, DATE_FORMAT_FOR_DISPLAY)}</p>
          </div>
          <div className="booking-allocation__info-container">
            <p className="booking-allocation__info-title">Role</p>
            <p className="booking-allocation__info-content">{role.name}</p>
          </div>
        </div>
        <div className="flex-1">
          <div className="booking-allocation__info-container">
            <p className="booking-allocation__info-title">Skill match</p>
            <div className="d-flex align-items-end">
              <span
                className={`skills-percentage skills-percentage--${getPillColor(
                  calculateSkillMatch(commonSkillsName?.length, requestSkillsName?.length).percentage || 0
                )}`}
              >
                {`${calculateSkillMatch(commonSkillsName?.length, requestSkillsName?.length).percentage}%` || '0'}
              </span>
              <div className="mb-5">
                <PopOver
                  html={
                    <p className="matching-skills">
                      {commonSkillsName.length ? commonSkillsName.join(', ') : 'No matching skills'}
                    </p>
                  }
                  interactive
                >
                  <span
                    className={`cursor-pointer pills pills--${getPillColor(
                      calculateSkillMatch(commonSkillsName?.length, requestSkillsName?.length).percentage || 0
                    )}`}
                  >
                    {calculateSkillMatch(commonSkillsName?.length, requestSkillsName?.length).rating}
                  </span>
                </PopOver>
              </div>
            </div>
          </div>
          <div className="booking-allocation__info-container">
            <p className="booking-allocation__info-title">Status</p>
            <div className="d-flex align-items-center gap-px-2">
              <span className={`bulb bulb--medium bulb--${CANDIDATE_PIPELINE_MAPPED_COLORS[bookingCategory.name]}`} />
              <p className="booking-allocation__info-status">{bookingStatus.name}</p>
            </div>
          </div>
          <div className="booking-allocation__info-container">
            <p className="booking-allocation__info-title">Availability</p>
            <p className="booking-allocation__info-content">
              {getFormattedDate(availability, DATE_FORMAT_FOR_DISPLAY)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
