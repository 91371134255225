import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import ExpiredPill from 'components/common/pill/ExpiredPill';

import { shouldHighlightPotentialEndDate } from 'utils/allocation';

import { PROJECT_ALLOCATION_DATE_FORMAT } from 'constants/appConstants';
import { EXPIRED_POTENTIAL_END_DATE_HIGHLIGHT } from 'constants/permissions';

import { getFormattedDate } from 'utils/date';

export default function AllocationTimePeriod(props) {
  const { startDate, endDate, potentialEndDate, isSelf = true, className, isTeamManager } = props;

  const {
    value: { permissions }
  } = useSelector(state => state.information);

  const hasViewPotentialEndDateHighlightPermission = !!permissions[EXPIRED_POTENTIAL_END_DATE_HIGHLIGHT.VIEW];
  const canViewPotentialEndDateHighlight = hasViewPotentialEndDateHighlightPermission || isSelf || isTeamManager;

  const shouldHighlight =
    canViewPotentialEndDateHighlight && shouldHighlightPotentialEndDate(endDate, potentialEndDate);

  return (
    <div className="project-timeline project-timeline--current-allocation">
      <div className={classNames(className)}>
        <span className="project-timeline__head mr-4">Start date:</span>
        <span>{getFormattedDate(startDate, PROJECT_ALLOCATION_DATE_FORMAT)}</span>
      </div>
      {endDate && (
        <div className={classNames(className)}>
          <span className="project-timeline__head mr-4">End date:</span>
          <span>{getFormattedDate(endDate, PROJECT_ALLOCATION_DATE_FORMAT)}</span>
        </div>
      )}
      {shouldHighlight && (
        <div className={classNames('d-flex align-items-center', className)}>
          <div className="project-timeline__head mr-4">Potential end date:</div>
          <ExpiredPill text={getFormattedDate(potentialEndDate, PROJECT_ALLOCATION_DATE_FORMAT)} isSelf={isSelf} />
        </div>
      )}
      {!endDate && !shouldHighlight && (
        <div className={classNames(className)}>
          <span className="project-timeline__head mr-4">Potential end date:</span>
          <span>{getFormattedDate(potentialEndDate, PROJECT_ALLOCATION_DATE_FORMAT)}</span>
        </div>
      )}
    </div>
  );
}
