/**
 * Gets default path from nav bar.
 *
 * @param {Object} navBarsWithPermissions
 *
 * @returns {Object}
 */
export const getDefaultPath = navBarsWithPermissions => {
  for (const navBar of navBarsWithPermissions) {
    if (navBar.hasPermission) {
      return navBar.path;
    }
  }
};
