/**
 * Generates react select options from given array of object.
 *
 * @param {Array} array
 * @param {Object} model Object keys from which react select's value and label is to fetched.
 * @returns {Array}
 */
export const generateReactSelectOptionsFromObject = (array, model = { value: 'id', label: 'name' }) =>
  array.map(item => ({ value: item[model.value], label: item[model.label] }));

/**
 * Generates react select options for given array of string.
 *
 * @param {Array} array
 * @returns {Array}
 */
export const generateReactSelectOptions = array => array.map(value => ({ value, label: value }));

/**
 * Generates react select group options for given array.
 *
 * @param {Array} array
 * @returns {Array}
 */
export const generateReactSelectSkillGroupOptions = (array, addCategory = false) => {
  const groupOptions = array.reduce((acc, item) => {
    const { category, id, name } = item;

    const label = category.name;

    const showCategory = { ...(addCategory ? { category } : {}) };

    if (!acc[label]) {
      acc[label] = { label, options: [] };
    }

    acc[label].options.push({ label: name, value: id, id, ...showCategory });

    return acc;
  }, {});

  return Object.values(groupOptions);
};
