import React from 'react';

const LeapfrogLogo = ({ size = 13, viewBox = '0 0 13 13', bgColor = 'none' }) => {
  return (
    <svg width={size} height={size} viewBox={viewBox} fill={bgColor} xmlns="http://www.w3.org/2000/svg">
      <rect x="0.873047" y="1.375" width="11.25" height="11.25" rx="5.625" fill="#038E43" />
      <rect
        x="0.873047"
        y="1.375"
        width="11.25"
        height="11.25"
        rx="5.625"
        className="logo-stroke"
        stroke-width="1.25"
      />
      <path
        d="M8.99582 4.5C6.4712 4.5 4 6.97116 4 9.5H5.41643C5.41643 7.74817 7.25347 5.92415 8.99582 5.92415V4.5Z"
        fill="white"
      />
    </svg>
  );
};

export default LeapfrogLogo;
