import { debounce } from 'lodash';
import { useEffect, useCallback } from 'react';

import { DEFAULT_DEBOUNCE_TIME, SCROLL_DIRECTION } from 'constants/appConstants';

const useScrollDirection = (containerSelector, direction, callback, delay = DEFAULT_DEBOUNCE_TIME) => {
  let prevScrollTop = 0;

  const container = document.querySelector(containerSelector);

  // useCallback has been used here to make sure that the function is not recreated on every render.

  // Also debounce has been used over throttle to make sure that the callback is not triggered multiple times.
  // in the case of throttle, the callback will be triggered every x seconds, but in the case of debounce,
  // the callback will be triggered only after x seconds of the last scroll event.
  const handleScroll = useCallback(
    debounce(() => {
      const { scrollTop, scrollLeft, scrollHeight, scrollWidth, clientHeight, clientWidth } = container;

      const shouldTriggerCallback =
        (direction === SCROLL_DIRECTION.BOTTOM && scrollTop + clientHeight === scrollHeight) ||
        (direction === SCROLL_DIRECTION.TOP && scrollTop === 0) ||
        (direction === SCROLL_DIRECTION.LEFT && scrollLeft === 0) ||
        (direction === SCROLL_DIRECTION.RIGHT && scrollLeft + clientWidth === scrollWidth) ||
        (direction === SCROLL_DIRECTION.DOWN && prevScrollTop < scrollTop) ||
        (direction === SCROLL_DIRECTION.UP && prevScrollTop > scrollTop);

      if (shouldTriggerCallback) {
        callback();
      }

      prevScrollTop = scrollTop;
    }, delay),
    [direction, callback]
  );

  // useEffect has been used here to make sure that the event listener is added only once.
  useEffect(() => {
    if (!container) {
      return;
    }

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [containerSelector, handleScroll]);
};

export default useScrollDirection;
