import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { handleError } from '../utils/errorHandler';
import { STATUS, CAN_FETCH } from '../constants/redux';

import * as skillV2Service from 'services/skillV2';

const initialState = { value: {}, loading: false, status: STATUS.IDLE };

export const fetchGlobalRating = createAsyncThunk('globalRating/fetchGlobalRating', async () => {
  try {
    const data = await skillV2Service.fetchGlobalRating();

    return data;
  } catch (error) {
    handleError(error);
  }

  return initialState.value;
});

export const globalRatingSlice = createSlice({
  name: 'globalRating',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGlobalRating.pending, state => {
        if (CAN_FETCH.includes(state.status)) {
          state.loading = true;
          state.status = STATUS.PENDING;
        }

        return state;
      })
      .addCase(fetchGlobalRating.fulfilled, (state, action) => {
        return {
          ...state,
          value: action.payload.data.globalRatings,
          loading: false,
          status: STATUS.FULFILLED
        };
      })
      .addCase(fetchGlobalRating.rejected, state => {
        state.loading = false;
        state.status = STATUS.REJECTED;
      });
  }
});

export default globalRatingSlice.reducer;
