import React from 'react';
import { NotFound } from '@vyaguta/vyaguta-ui';
import { Redirect, Route, Switch } from 'react-router-dom';

import Employees from './employees';
import Dashboard from './dashboard';
import history from 'utils/history';
import Settings from './settings/Settings';
import { interpolate } from 'utils/string';
import UpdateEmployee from './updateEmployee';
import ChangeTeamManager from './teammanager';
import NoteSummary from './notes/NoteSummary';
import AuthorizedRoute from './AuthorizedRoute';
import NoticeBoard from './notices/NoticeBoard';
import RolesPermissions from './rolesPermissions';
import CreateEmployeeForm from './createEmployee';
import ChangeDesignation from './changeDesignation';
import * as permissions from 'constants/permissions';
import EmployeeSkills from './employees/EmployeeSkills';
import EmployeeProfile from './employees/EmployeeProfile';
import ChangeEngagement from 'components/home/changeEnagement';
import ChangeLeaveIssuer from './leaveissuer/ChangeLeaveIssuer';
import EventsAndNotices from './eventsAndNotices/EventsAndNotices';
import { ResourceFinder, ResourceFinderV1 } from './resourceFinder';
import ChangeIssuerRequest from './leaveissuer/ChangeIssuerRequest';
import ChangeLeaveIssuerList from './leaveissuer/ChangeLeaveIssuerList';
import EmployeeAssets from './employees/components/profile/EmployeeAssets';
import ChangeTentativeExit from './changeTentativeExit/ChangeTentativeExit';

import {
  CREATE_EMPLOYEE,
  RESOURCE_FINDER,
  UPDATE_EMPLOYEE,
  EMPLOYEE_SKILLS,
  EMPLOYEE_PROFILE,
  ACTIVE_EMPLOYEES,
  CHANGE_DESIGNATION,
  UPDATE_LEAVE_ISSUER,
  UPDATE_TEAM_MANAGER,
  DASHBOARD,
  EMPLOYEE_PROFILE_LEAVE_CREDITS,
  EMPLOYEE_PROFILE_GOALS,
  EMPLOYEE_PROFILE_FEEDBACKS,
  ROLES_PERMISSIONS,
  ME,
  HOME,
  CHANGE_ENGAGEMENT,
  NOTE_SUMMARY,
  NOTICE_BOARD,
  CHANGE_ISSUER_REQUEST,
  LEAVE_ISSUER_CHANGE_REQUESTS,
  EVENTS_AND_NOTICES,
  SETTINGS,
  CHANGE_TENTATIVE_EXIT,
  RESOURCE_FINDER_V1,
  EMPLOYEE_ASSETS
} from 'constants/routes';
import { ROLE } from 'constants/appConstants';

const HomeRouter = props => {
  return (
    <Switch>
      <AuthorizedRoute
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        permission={[permissions.EMPLOYEES.CREATE]}
        path={CREATE_EMPLOYEE}
        component={CreateEmployeeForm}
      />
      <AuthorizedRoute
        exact
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        path={RESOURCE_FINDER_V1}
        permission={[permissions.EMPLOYEES.FINDER]}
        component={ResourceFinderV1}
        {...props}
      />
      <AuthorizedRoute
        exact
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        path={RESOURCE_FINDER}
        permission={[permissions.EMPLOYEES.FINDER]}
        component={ResourceFinder}
        {...props}
      />
      <AuthorizedRoute
        exact
        permission={[permissions.EMPLOYEES.SKILLS.UPDATE]}
        path={EMPLOYEE_SKILLS}
        component={EmployeeSkills}
        allowSelf={true}
        {...props}
      />
      <AuthorizedRoute
        exact
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        permission={[permissions.EMPLOYEES.UPDATE_LEAVE_ISSUER]}
        path={UPDATE_LEAVE_ISSUER}
        component={ChangeLeaveIssuer}
        {...props}
      />
      <AuthorizedRoute
        exact
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        permission={[permissions.EMPLOYEES.UPDATE_TEAM_MANAGER]}
        path={UPDATE_TEAM_MANAGER}
        component={ChangeTeamManager}
        {...props}
      />
      <AuthorizedRoute
        exact
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        permission={[permissions.EMPLOYEES.ENGAGEMENTS.UPDATE]}
        path={CHANGE_ENGAGEMENT}
        component={ChangeEngagement}
        {...props}
      />
      <AuthorizedRoute
        exact
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        permission={[permissions.EMPLOYEES.DESIGNATIONS.UPDATE]}
        path={CHANGE_DESIGNATION}
        component={ChangeDesignation}
        {...props}
      />
      <AuthorizedRoute
        permission={[permissions.EMPLOYEES.PROFILE.UPDATE]}
        path={UPDATE_EMPLOYEE}
        allowSelf={true}
        component={UpdateEmployee}
        {...props}
      />
      <AuthorizedRoute
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        permission={[permissions.EMPLOYEES.RESIGNATIONS.VIEW]}
        path={CHANGE_TENTATIVE_EXIT}
        allowSelf={false}
        component={ChangeTentativeExit}
        {...props}
      />
      <Route exact path={CHANGE_ISSUER_REQUEST} render={empProps => <ChangeIssuerRequest {...empProps} {...props} />} />
      <Route exact path={ACTIVE_EMPLOYEES} render={empProps => <Employees {...empProps} {...props} />} />
      <Route exact path={DASHBOARD} render={empProps => <Dashboard {...empProps} {...props} />} />
      <Route path={EMPLOYEE_PROFILE} render={empProps => <EmployeeProfile {...empProps} {...props} />} />
      <AuthorizedRoute
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        permission={[permissions.NOTES.VIEW]}
        path={NOTE_SUMMARY}
        component={NoteSummary}
        {...props}
      />
      <AuthorizedRoute
        permission={[permissions.EMPLOYEES.ASSETS.VIEW]}
        allowSelf={true}
        path={EMPLOYEE_ASSETS}
        render={empProps => <EmployeeAssets {...empProps} {...props} />}
      />
      <Route exact path={NOTICE_BOARD} render={props => <NoticeBoard {...props} />} />
      <AuthorizedRoute
        exact
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        permission={[permissions.LEAVE_ISSUER_CHANGE_REQUESTS.VIEW]}
        path={LEAVE_ISSUER_CHANGE_REQUESTS}
        component={ChangeLeaveIssuerList}
        {...props}
      />

      <AuthorizedRoute
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        permission={[
          permissions.NOTICES.VIEW,
          permissions.EVENTS.VIEW,
          permissions.REMINDERS.VIEW,
          permissions.HOLIDAYS.VIEW
        ]}
        path={EVENTS_AND_NOTICES}
        component={EventsAndNotices}
        {...props}
      />

      <AuthorizedRoute
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        permission={[permissions.SKILLS.VIEW, permissions.DESIGNATIONS.VIEW, permissions.USER_PERMISSIONS.VIEW]}
        path={SETTINGS}
        component={Settings}
        {...props}
      />

      <Route
        exact
        path="/employees/:id"
        render={props => <Redirect to={interpolate(EMPLOYEE_PROFILE, { id: props.match.params.id })} />}
      />
      <Route
        exact
        path="/employees/:id/goals"
        render={props => <Redirect to={interpolate(EMPLOYEE_PROFILE_GOALS, { id: props.match.params.id })} />}
      />
      <Route
        exact
        path="/employees/:id/feedbacks"
        render={props => <Redirect to={interpolate(EMPLOYEE_PROFILE_FEEDBACKS, { id: props.match.params.id })} />}
      />
      <Route
        exact
        path="/employees/:id/leave-credits"
        render={props => <Redirect to={interpolate(EMPLOYEE_PROFILE_LEAVE_CREDITS, { id: props.match.params.id })} />}
      />
      <Route
        exact
        path="/employees/:id/assets"
        render={props => <Redirect to={interpolate(EMPLOYEE_ASSETS, { id: props.match.params.id })} />}
      />
      <Route
        exact
        path="/employees/:id/update"
        render={props => <Redirect to={interpolate(UPDATE_EMPLOYEE, { id: props.match.params.id })} />}
      />
      <Route
        exact
        path="/employees/:id/skills"
        render={props => <Redirect to={interpolate(EMPLOYEE_SKILLS, { id: props.match.params.id })} />}
      />
      <Route
        exact
        path="/employees/:id/designations"
        render={props => <Redirect to={interpolate(CHANGE_DESIGNATION, { id: props.match.params.id })} />}
      />
      <Route
        exact
        path="/employees/:id/leave-issuers"
        render={props => <Redirect to={interpolate(UPDATE_LEAVE_ISSUER, { id: props.match.params.id })} />}
      />
      <Route
        exact
        path="/employees/:id/team-managers"
        render={props => <Redirect to={interpolate(UPDATE_TEAM_MANAGER, { id: props.match.params.id })} />}
      />
      <AuthorizedRoute
        forbiddenRoles={[ROLE.SUB_CONTRACTOR]}
        exact
        path={ROLES_PERMISSIONS}
        component={RolesPermissions}
        {...props}
      />
      <Redirect from={ME} to={interpolate(EMPLOYEE_PROFILE, { id: props.user.id })} />
      <Redirect from="/employees/" to={ACTIVE_EMPLOYEES} />
      <Route render={() => <NotFound redirectHandler={() => history.push(HOME)} className="flash-card" />} />
    </Switch>
  );
};

export default HomeRouter;
