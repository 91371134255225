import React from 'react';

// Icons
import { CircleCheck, InfoOutline } from 'assets/images';

// Constant
import { COMPLETE, ERROR } from 'constants/createEmployeeForm';

/**
 @state
* Complete
* Selected --
* Hovered
* Error
 **/

const StepperNavigation = props => {
  const { item, onNavigate, currentForm } = props;

  return (
    <div
      onClick={() => onNavigate(item.id)}
      className={`steppernavigation ${
        item.state === COMPLETE ? 'steppernavigation--complete' : item.state === ERROR ? 'steppernavigation--error' : ''
      } ${item.id === currentForm.id ? 'steppernavigation--selected' : ''}`}
    >
      <div className="steppernavigation__icon">
        {item.state === COMPLETE ? (
          <img src={CircleCheck} alt="CircleCheck" srcset="" />
        ) : item.state === ERROR ? (
          <img src={InfoOutline} alt="Infooutline" srcset="" />
        ) : (
          <p className="steppernavigation__icon__id">{item.id}</p>
        )}
      </div>
      <div className="steppernavigation__box">
        <p className="title">{item.label}</p>
        <p className="detail">{item.detail}</p>
      </div>
    </div>
  );
};

export default StepperNavigation;
