import { parseQuery, unParseQuery } from 'utils/string';

const getURLQuery = props => {
  return parseQuery(props.location.search);
};

const setURLQuery = (props, query) => {
  let currentQuery = parseQuery(props.location.search);
  currentQuery = { ...currentQuery, ...query };

  props.history.push({
    search: unParseQuery(currentQuery)
  });
};

const useQuery = (props, initialState = {}) => {
  const query = getURLQuery(props) || initialState;

  const setQuery = query => setURLQuery(props, query);

  return [query, setQuery];
};

export default useQuery;
