import React, { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { TitleBar, GoalListView, Select } from '@vyaguta/vyaguta-ui';

import Empty from 'components/common/empty/Empty';
import Loading from 'components/common/loading/Loading';

import useTitle from 'hooks/useTitle';
import { YEAR_OPTIONS } from 'constants/options';
import { GOAL_TYPE, periodOptions } from 'constants/jump';

import { handleError } from 'utils/errorHandler';
import { getDefaultFilters, getYearFilters } from 'utils/jump';

import { fetchUserGoals } from 'slices/userGoalSlice';
import { fetchGoalFeedbacks } from 'slices/goalFeedbackSlice';
import { fetchUserGoalsSummary } from 'slices/userGoalSummarySlice';

const DEFAULT_COUNT_VALUE = 0;

const EmployeeGoals = ({ employeeId, query, setQuery }) => {
  const dispatch = useDispatch();

  const { value: goalFeedbacks, loading: isFetchingGoalFeedbacks } = useSelector(state => state.goalFeedbacks);
  const { value: goals, loading: isFetchingUserGoals } = useSelector(state => state.userGoals);
  const { value: goalSummary } = useSelector(state => state.userGoalsSummary);

  useEffect(() => {
    if (!query.year || !query.period) {
      return setQuery(getDefaultFilters());
    }

    try {
      dispatch(
        fetchUserGoals({ employeeId, params: { type: query.type, ...getYearFilters(query.period, query.year) } })
      );
    } catch (err) {
      handleError(err);
    }
  }, [employeeId, query]);

  useEffect(() => {
    if (!query.year || !query.period) {
      return;
    }

    try {
      dispatch(fetchUserGoalsSummary({ employeeId, params: getYearFilters(query.period, query.year) }));
    } catch (err) {
      handleError(err);
    }
  }, [employeeId, query.year, query.period]);

  const handleGoalClick = useCallback(
    async event => {
      if (event.isOpen && !goalFeedbacks[event.goal.id]) {
        try {
          await dispatch(fetchGoalFeedbacks({ goalId: event.goal.id }));
        } catch (err) {
          handleError(err);
        }
      }
    },
    [goalFeedbacks, dispatch]
  );

  const handlePillClick = useCallback(
    async (event, value) => {
      setQuery({ ...query, type: value });
    },
    [query, setQuery]
  );

  const findSummaryCount = useCallback((summary, type) => {
    if (!summary) {
      return DEFAULT_COUNT_VALUE;
    }

    const summaryFound = summary.find(data => type === data.type);

    return summaryFound ? summaryFound.count : DEFAULT_COUNT_VALUE;
  }, []);

  const [totalCount, goalsCount, achievementsCount] = useMemo(() => {
    if (!goalSummary || !goalSummary.length) {
      return [DEFAULT_COUNT_VALUE, DEFAULT_COUNT_VALUE, DEFAULT_COUNT_VALUE];
    }

    return [
      goalSummary.reduce((acc, cv) => acc + +cv.count, 0),
      findSummaryCount(goalSummary, GOAL_TYPE.GOAL),
      findSummaryCount(goalSummary, GOAL_TYPE.ACHIEVEMENT)
    ];
  }, [goalSummary, findSummaryCount]);

  const filterPills = useMemo(() => {
    return [
      {
        color: 'blue',
        isSmall: false,
        isActive: !query.type,
        notificationCount: totalCount,
        label: 'All',
        value: GOAL_TYPE.ALL,
        onClick: handlePillClick,
        className: false
      },
      {
        color: 'yellow',
        isSmall: false,
        isActive: query.type === GOAL_TYPE.GOAL,
        notificationCount: goalsCount,
        label: 'Goals',
        value: GOAL_TYPE.GOAL,
        onClick: handlePillClick,
        className: false
      },
      {
        color: 'green',
        isSmall: false,
        isActive: query.type === GOAL_TYPE.ACHIEVEMENT,
        notificationCount: achievementsCount,
        label: 'Achievements',
        value: GOAL_TYPE.ACHIEVEMENT,
        onClick: handlePillClick,
        className: false
      }
    ].filter(({ notificationCount }) => Boolean(notificationCount));
  }, [query.type, totalCount, goalsCount, achievementsCount, handlePillClick]);

  const handleYearChange = option => {
    setQuery({ ...query, year: option.value });
  };

  const handlePeriodChange = option => {
    setQuery({ ...query, period: option.value });
  };

  return (
    <div className="container">
      <div className="mt-15">
        <TitleBar className="string mb-20" subTitle="" filterPills={filterPills} disabled={isFetchingUserGoals}>
          <TitleBar.Right>
            <Select
              isSearchable={true}
              options={periodOptions}
              autoResize={true}
              disabled={isFetchingUserGoals}
              onChange={handlePeriodChange}
              value={periodOptions.find(opt => opt.value === query.period) || periodOptions[0]}
              placeholder={'Period'}
            />
            <Select
              isSearchable={true}
              options={YEAR_OPTIONS}
              autoResize={true}
              disabled={isFetchingUserGoals}
              onChange={handleYearChange}
              value={YEAR_OPTIONS.find(opt => opt.value === +query.year) || YEAR_OPTIONS[0]}
              placeholder={'Year'}
            />
          </TitleBar.Right>
        </TitleBar>
        {isFetchingUserGoals ? (
          <div className="full-scope-card mt-15">
            <Loading />
          </div>
        ) : goals.length ? (
          <GoalListView
            loadingFeedback={isFetchingGoalFeedbacks}
            goals={goals.map(goal => ({ goal, onClick: handleGoalClick, feedbacks: goalFeedbacks[goal.id] }))}
          />
        ) : (
          <div className="full-scope-card mt-15 p-20 empty-container__wrapper">
            <Empty message="No goals and achievements to show." />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeGoals;
