import moment from 'moment';
import { Formik } from 'formik';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiAlertCircle } from 'react-icons/fi';
import { HORIZONTAL_ORIENTATION } from 'react-dates/constants';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';

import { calendar } from 'assets/images';

import * as routes from 'constants/routes';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import DatePicker from 'components/common/datepicker/DatePicker';
import DeleteButton from 'components/common/button/DeleteButton';

import reminderSchema from 'schemas/reminders';

import * as reminderService from 'services/reminder';

import { getFormattedDate, isDateBetweenInclusive } from 'utils/date';

import { deleteReminders, updateReminders } from 'slices/informationSlice';

const initialValues = {
  title: '',
  redirectUrl: '',
  startDate: moment(),
  endDate: moment()
};

const ReminderForm = ({ reminder }) => {
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const handleCancelClick = () => (history.length > 1 ? history.goBack() : history.push(routes.REMINDERS));

  const handleSubmit = async values => {
    setSubmitting(true);

    const reminderObj = {
      title: values.title,
      redirectUrl: values.redirectUrl,
      endDate: values.endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      startDate: values.startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss')
    };

    try {
      if (reminder) {
        await reminderService.updateReminder(reminder.id, reminderObj);

        if (
          isDateBetweenInclusive(
            getFormattedDate(),
            getFormattedDate(reminderObj.startDate),
            getFormattedDate(reminderObj.endDate)
          )
        ) {
          dispatch(updateReminders({ id: reminder.id, ...reminderObj }));
        } else {
          dispatch(deleteReminders(reminder.id));
        }
      } else {
        const { data } = await reminderService.create(reminderObj);

        const { id } = data;

        if (
          isDateBetweenInclusive(
            getFormattedDate(),
            getFormattedDate(reminderObj.startDate),
            getFormattedDate(reminderObj.endDate)
          )
        ) {
          dispatch(updateReminders({ id, ...reminderObj }));
        }
      }

      toast.success({
        title: 'Success',
        message: reminder ? 'Reminder updated successfully.' : 'Reminder created successfully.'
      });

      history.push(routes.REMINDERS);
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteClick = async () => {
    try {
      await reminderService.remove(reminder.id);

      if (
        isDateBetweenInclusive(
          getFormattedDate(),
          getFormattedDate(reminder.startDate),
          getFormattedDate(reminder.endDate)
        )
      ) {
        dispatch(deleteReminders(reminder.id));
      }

      toast.success({
        title: 'Deleted',
        message: 'Reminder has been deleted successfully.'
      });
      history.push(routes.REMINDERS);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Formik
      initialValues={reminder ? { ...reminder } : { ...initialValues }}
      validationSchema={reminderSchema}
      onSubmit={handleSubmit}
      render={props => (
        <form onSubmit={props.handleSubmit}>
          <div className="full-scope-card__content">
            <div className="form-wrap">
              <div className="form-wrap__row form-wrap__row--no-margin clearfix">
                <div className="form-wrap__col col-sm-6 px-0">
                  <div className="form-group">
                    <label className="form-label dark--text">
                      Title <span className="required ml-5">*</span>
                    </label>
                    <input
                      name="title"
                      type="text"
                      value={props.values.title}
                      placeholder="Provide a suitable tilte for the reminder"
                      className="form-elem"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                    ></input>
                    {props.errors.title && props.touched.title && (
                      <div className="error-block">{props.errors.title}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label dark--text">
                      Link <span className="required ml-5">*</span>
                    </label>
                    <input
                      name="redirectUrl"
                      type="text"
                      value={props.values.redirectUrl}
                      placeholder="Provide a redirect link"
                      className="form-elem"
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                    ></input>
                    {props.errors.redirectUrl && props.touched.redirectUrl && (
                      <div className="error-block">{props.errors.redirectUrl}</div>
                    )}
                  </div>
                  <div className="d-flex flex-direction-responsive">
                    <div className="form-group width--full momr-20">
                      <label className="form-label dark--text">
                        Start Date <span className="required ml-5">*</span>
                      </label>
                      <div className="form-elem--icon-pos">
                        <DatePicker
                          id="startDate"
                          displayFormat="LL"
                          customInputIcon={<img className="form-icon" src={calendar} alt="Calendar" />}
                          date={props.values.startDate}
                          onDateChange={date => {
                            date = moment(date);
                            if (!date._isValid) {
                              return;
                            }
                            const endDate = date.isAfter(props.values.endDate, 'day') ? date : props.values.endDate;

                            props.setFieldValue('startDate', date);
                            props.setFieldValue('endDate', endDate);
                          }}
                          numberOfMonths={1}
                          orientation={HORIZONTAL_ORIENTATION}
                          isOutsideRange={() => false}
                        />
                      </div>
                      {props.errors.startDate && props.touched.startDate && (
                        <div className="error-block">{props.errors.startDate}</div>
                      )}
                    </div>
                    <div className="form-group width--full">
                      <label className="form-label dark--text">
                        End Date <span className="required ml-5">*</span>
                      </label>
                      <div className="form-elem--icon-pos">
                        <DatePicker
                          id="endDate"
                          displayFormat="LL"
                          customInputIcon={<img className="form-icon" src={calendar} alt="Calendar" />}
                          date={props.values.endDate}
                          onDateChange={date => {
                            date = moment(date);
                            if (!date._isValid) {
                              return;
                            }
                            const startDate = date.isBefore(props.values.startDate, 'day')
                              ? date
                              : props.values.startDate;

                            props.setFieldValue('startDate', startDate);
                            props.setFieldValue('endDate', date);
                          }}
                          numberOfMonths={1}
                          orientation={HORIZONTAL_ORIENTATION}
                        />
                      </div>
                      {props.errors.endDate && props.touched.endDate && (
                        <div className="error-block">{props.errors.endDate}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-bar-footer action-bar-footer--bordered-top px-16">
              <button
                type="submit"
                className={classnames('btn btn--primary btn--curved mr-10', {
                  'btn--loading': submitting
                })}
                disabled={submitting}
              >
                <span className={submitting ? 'invisible' : ''}>Save</span>
                {submitting && <Loading />}
              </button>
              <button
                type="button"
                className={classnames('btn btn--outlined-grey btn--curved mr-10')}
                disabled={submitting}
                onClick={handleCancelClick}
              >
                Cancel
              </button>
              {reminder && (
                <LFModal
                  TriggerBtn={DeleteButton}
                  modalStatusIcon={<FiAlertCircle color="#F44336" size={24} />}
                  title={{ text: 'Delete this reminder?', type: 'danger' }}
                  className="delete-modal"
                  message={{ text: "You won't be able to revert this!", type: 'delete__text' }}
                  renderFooter={true}
                  buttons={[
                    {
                      text: 'Confirm',
                      type: 'red',
                      className: 'mr-12 delete__btn',
                      onClick: handleDeleteClick,
                      close: true,
                      showLoading: true
                    },
                    {
                      text: 'Cancel',
                      type: 'outlined-grey',
                      className: 'py-8 px-12',
                      close: true
                    }
                  ]}
                />
              )}
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default ReminderForm;
