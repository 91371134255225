import { useCallback, useEffect, useRef, useState } from 'react';

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/page';

import { handleError } from 'utils/errorHandler';

const useLazyLoading = (getDataFunction, param) => {
  const [loading, setLoading] = useState(true);
  const observer = useRef();

  const [dataList, setDataList] = useState([]);
  const [metaData, setMetaData] = useState({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE, total: 0 });

  const paramObj = param && param[0];

  const update = useCallback(async () => {
    const filters = {
      ...paramObj,
      page: metaData.page,
      size: DEFAULT_PAGE_SIZE
    };

    try {
      setLoading(true);

      const { data, meta } = await getDataFunction(filters);

      setMetaData({ ...meta, page: meta.page + 1 });
      setDataList([...dataList, ...data]);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [getDataFunction, metaData.page, param]);

  const reset = useCallback(() => {
    setDataList([]);
    setMetaData({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE, total: 0 });
  }, []);

  useEffect(() => {
    if (metaData.page === 1) {
      update();
    }
  }, [metaData.page]);

  const lastRef = useCallback(
    node => {
      if (loading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && dataList.length < metaData.total) {
          update();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, metaData, dataList]
  );

  return { loading, dataList, lastRef, update, reset };
};

export default useLazyLoading;
