import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useState, useMemo } from 'react';

import { Avatar } from '@vyaguta/vyaguta-ui';
import { UiDownloadAlt } from 'vyaguta-icons/ui';

import LFModal from 'components/common/LFModal';
import Empty from 'components/common/empty/Empty';
import { EMPLOYEES } from 'constants/permissions';
import Loading from 'components/common/loading/Loading';
import SkillCategoryChild from 'components/home/skills/components/SkillCategoryChild';

import * as routes from 'constants/routes';

import history from 'utils/history';
import { handleError } from 'utils/errorHandler';
import { parseQueryURL } from 'utils/queryParam';
import { getSearchAndRemainingSkills, getSearchTreeWithRating } from 'utils/skillV2';
import { getEmpImageUrl, interpolate, pluralize, stringToArray } from 'utils/string';

import * as skillService from 'services/skillService';

const viewAllSkills = (onClick, length) => {
  return (
    <button className="view-all font-size-14" onClick={onClick}>
      View {length} others
    </button>
  );
};

const parseSkillsFrom = (skills, skillsTree, remainingLength) =>
  skills && skills?.length ? (
    <>
      {skills.map(skill => (
        <div className="skill-pill-item d-flex align-items-center p-12" key={skill.id}>
          <span>{skill.name}</span>

          {skill.rating && (
            <div className="rating d-flex align-items-center justify-content-center mlpx-6">
              <span className="font-size-12 color-grey-60">{skill.rating}</span>
            </div>
          )}
        </div>
      ))}

      {!!remainingLength && (
        <LFModal
          closeButton={true}
          TriggerBtn={({ onClick }) => viewAllSkills(onClick, remainingLength)}
          title={{ text: 'Selected Skills', type: 'info' }}
          htmlContent={
            <div className="skill-set-area__container p-0">
              <div className="skills-list-container p-0 w-100p">
                {skillsTree.map(skill => {
                  return <SkillCategoryChild selectedCategory={skill} skillListClass="height-auto mbpx-16" />;
                })}
              </div>
            </div>
          }
          renderFooter={false}
          className="people-finder-view-all-modal container"
        />
      )}
    </>
  ) : null;

const EmployeeRow = ({ employee: { user, skills }, allSkills, skillsQuery }) => {
  const { id, empId, fullname, designation } = user;

  const [searchSkills, remainingSkills] = useMemo(() => getSearchAndRemainingSkills(skills, skillsQuery), [
    skills,
    skillsQuery
  ]);

  const skillsTree = useMemo(() => getSearchTreeWithRating(remainingSkills, allSkills), [remainingSkills, allSkills]);

  return (
    <div className="d-flex align-items-center border-bottom-grey-10 p-y-16">
      <div className="d-flex align-items-center w-60x">
        <Avatar
          className="nametag nametag--lg profile-photo people-finder-profile"
          image={getEmpImageUrl(empId)}
          alt={fullname}
        />

        <div className="namecard__info">
          <Link className="profile-link text--bold text--grey fs-13" to={interpolate(routes.EMPLOYEE_PROFILE, { id })}>
            {fullname}
          </Link>
          <div className="muted-text res-designation-name">{designation.name}</div>
        </div>
      </div>

      <div className="skills-pill-list d-flex gap-px-16 flex-wrap">
        {parseSkillsFrom(searchSkills, skillsTree, remainingSkills?.length)}
      </div>
    </div>
  );
};

const ResourceResults = props => {
  const { total, employees, exporting, onExport } = props;

  const {
    value: { permissions }
  } = useSelector(state => state.information);

  const { skill: skillsQuery } = parseQueryURL(history.location.search);

  const [allSkills, setAllSkills] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await skillService.fetchAll();

        setAllSkills(data);
      } catch (error) {
        handleError(error);
      }
    })();
  }, []);

  return (
    <>
      <div className="resource-list-header table-header name-wrap border-bottom-grey-20">
        <div>
          <p className="resource-header-title">Leapfroggers</p>

          <p className="font-size-12 color-grey-60">
            {employees.length ? `Showing ${employees?.length} Leapfroggers` : ''}
          </p>
        </div>

        <div className="d-flex flex-row ai-center">
          <span className="resource-results">{pluralize(`${total || 'No'} Result`, total)}</span>
          {permissions[EMPLOYEES.EXPORT] && !!total && (
            <button className="btn btn--outlined-grey export-button" onClick={onExport} disabled={exporting}>
              {exporting ? (
                <div className="export-button loading">
                  <Loading />
                </div>
              ) : (
                <>
                  <UiDownloadAlt className="download-icon" />
                  {'.CSV'}
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <div className="resource-result-list">
        {employees && employees.length ? (
          employees.map((employee, index) => (
            <EmployeeRow
              employee={employee}
              key={index}
              allSkills={allSkills}
              skillsQuery={stringToArray(skillsQuery, ',', true) || []}
            />
          ))
        ) : (
          <div className="empty-container__wrapper">
            <Empty message={'No leapfroggers found'} />
          </div>
        )}
      </div>
    </>
  );
};

export default ResourceResults;
