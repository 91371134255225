import * as Yup from 'yup';

import { URL } from 'constants/regex';

export const addLinkSchema = Yup.object({
  name: Yup.string()
    .required('Please provide name')
    .trim()
    .typeError('Please provide name'),
  link: Yup.string()
    .matches(URL, 'Link is not valid')
    .required('Please provide link')
    .trim()
    .typeError('Please provide link')
});
