import React from 'react';

import { extractFullName } from 'utils/string';

const TeamManagersHistory = ({ teamManagers }) => {
  return (
    <div className="full-scope-card team-manager-history-area">
      <div className="font-bold team-manager-history-title">Team Managers</div>
      <div className="team-manager-history-container custom-scroll-bar">
        {teamManagers && teamManagers.length ? (
          teamManagers.map((teamManager, idx) => (
            <div className="position-relative pr-20">
              <div
                className={`mb-20 ml-20 team-manager-history${
                  idx === teamManagers.length - 1 ? ' team-manager-history-last' : ''
                }`}
              >
                <div className="team-manager-history-name">{extractFullName(teamManager)}</div>
                <div className="team-manager-history-date">{`Effective from ${teamManager.transitionDate}`}</div>
                <div className="team-manager-history-date">{`Changed on ${teamManager.createdAt}`}</div>
              </div>
            </div>
          ))
        ) : (
          <div className="font-12">No team managers assigned yet.</div>
        )}
      </div>
    </div>
  );
};

export default TeamManagersHistory;
