import React from 'react';

import config from 'config';

const SkillRatingInfo = () => (
  <div className="custom-scroll-bar skills-faq-wrapper">
    <div className="rating-info-node">
      <p class="c4">
        <h4>1. What is the objective to gather the skills rating?</h4>
      </p>
      <ol class="c12 lst-kix_5e6v1cftzies-0 start" start="1">
        <li class="c4 c6">
          <span class="c1 c5">Collect the individual skills profile of current Leapfroggers.</span>
        </li>
        <li class="c4 c6">
          <span class="c1 c5">Create a database of leapfrog skillset.</span>
        </li>
        <li class="c4 c6">
          <span class="c1 c5">Help the resource team to quickly seek resources on particular areas/stack.</span>
        </li>
      </ol>
    </div>
    <div className="rating-info-node">
      <h4 class="c10" className="criteria-title">
        2. How to rate skills?
      </h4>
      <table class="rating-info-table c17">
        <tbody>
          <tr class="c7">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c3">
                <span class="c5">Rating</span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c3">
                <span class="c5">Description</span>
              </p>
            </td>
          </tr>
          <tr class="c7">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c3">
                <span class="c5">1</span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c3">
                <span class="c5">
                  Attended trainings
                  <br />
                  Completed tutorials
                </span>
              </p>
              <p>
                <span className="rating-info-table__meaning">Meaning: </span>You need proper guidance or need to
                re-learn.
              </p>
            </td>
          </tr>
          <tr class="c7">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c3">
                <span class="c5">2</span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c3">
                <span class="c5">
                  Created sample applications
                  <br />
                  Contributed to a project module or feature using the stack/area.
                </span>
              </p>
              <p>
                <span className="rating-info-table__meaning">Meaning: </span>You can work on it with little guidance.
              </p>
            </td>
          </tr>
          <tr class="c7">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c3">
                <span class="c5">3</span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c3">
                <span class="c5">Worked on the stack/area periodically for &gt;= 2 years</span>
              </p>
              <p class="c3">
                <span class="c5">Well acquainted with multiple areas of the stack/area.</span>
              </p>
              <p>
                <span className="rating-info-table__meaning">Meaning: </span>You are confident and can work with no
                guidance.
              </p>
            </td>
          </tr>
          <tr class="c7">
            <td class="c1" colspan="1" rowspan="1">
              <p class="c3">
                <span class="c5">4</span>
              </p>
            </td>
            <td class="c13" colspan="1" rowspan="1">
              <p class="c3">
                <span class="c5">Have an in-depth understanding of the stack/area.</span>
              </p>
              <p class="c3">
                <span class="c5">Knows ins and outs of the stack/area. &nbsp;</span>
              </p>

              <p>
                <span className="rating-info-table__meaning">Meaning: </span>You are very comfortable and have worked in
                that tech stack. You can lead a small team if needed.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="c10 criteria-footer">
        <strong>Note :</strong> The guideline mentioned above determines an outline to rate oneself in particular
        skills. This will be validated by experts in future.
      </p>
    </div>
    <div className="rating-info-node">
      <p class="c4">
        <h4>3. Is there anywhere I can list the training and tutorials I have gone through?</h4>
      </p>
      <p>There will be a section to add related training or courses of a skill in future release.</p>
    </div>
    <div className="rating-info-node">
      <p class="c4">
        <h4>4. Are my ratings going to be validated?</h4>
      </p>
      <p>
        Validations at the present are done by the individuals themselves. Their respective appraisers will validate in
        the “Final Evaluation” stage of Performance Evaluation. Appraisers can suggest or help to calibrate the ratings
        during the 1-1s.
      </p>
    </div>
    <div class="rating-info-node">
      <p class="c4">
        <h4>5. I cannot find skills that I want to rate myself. Can I add one?</h4>
      </p>
      <span class="c16">
        You can suggest adding skills through this form{' - '}
        <a class="c15" href={config.newSkillRequestFormURI} rel="noopener noreferrer" target="_blank">
          Request form to add skills/concerns
        </a>
      </span>
    </div>
  </div>
);

export default SkillRatingInfo;
