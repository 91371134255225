import jwt from 'jsonwebtoken';

/**
 * Decoded the passed token and return decoded token.
 *
 * @param {jwtToken} token
 */
export function getDecodedToken(token) {
  try {
    return jwt.decode(token);
  } catch (Error) {
    return null;
  }
}
