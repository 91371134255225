import http from '../utils/http';
import { interpolate, unParseQuery } from 'utils/string';
import { SKILL_URL, SKILL_ABSOLUTE_URL, USER_SKILLSV2_URL, SKILL_URL_V1 } from '../constants/endpoints';

export async function fetchAll(params) {
  const data = await http.get(SKILL_URL);

  return data;
}

export async function fetchAllV1(params) {
  const data = await http.get(SKILL_URL_V1 + unParseQuery(params || { size: 1000 }));

  return data;
}

export async function fetchByUserId(userId) {
  const data = await http.get(interpolate(USER_SKILLSV2_URL, { userId }));

  return data;
}

export async function fetchById(id) {
  const { data } = await http.get(interpolate(SKILL_ABSOLUTE_URL, { id }));

  return data;
}

export async function create(skill) {
  return await http.post(SKILL_URL_V1, {
    body: skill
  });
}

export async function update(skill, id) {
  return await http.put(interpolate(SKILL_ABSOLUTE_URL, { id }), {
    body: skill
  });
}

export async function remove(id) {
  return await http.delete(interpolate(SKILL_ABSOLUTE_URL, { id }));
}
