import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import history from 'utils/history';
import { capitalize } from 'utils/string';
import { isObjectEmpty } from 'utils/object';
import { getFormattedDate } from 'utils/date';
import { handleError } from 'utils/errorHandler';
import { parse, stringify } from 'utils/queryParam';

import PopOver from 'components/common/popover';
import Pagination from 'components/common/paginator';
import NoteAccordionTable from './NoteAccordionTable';
import PillStatus from 'components/common/pill/PillStatus';
import TableHeader from 'components/common/table/TableHeader';
import MaterialDropdown from 'components/common/dropDown/materialDropdown';
import EmployeeNoteItem from '../employees/components/notes/EmployeeNoteItem';
import AvatarLinkComponent from 'components/common/avatar/AvatarLinkComponent';

import {
  NOTE_ROLE_FILTER,
  NOTE_STATUS_TYPES,
  DEFAULT_PAGE_SIZE,
  NOTE_STATUS_FILTER,
  DEFAULT_SORT_ORDER,
  DEFAULT_PAGE_NUMBER,
  DATE_FORMAT_FOR_DISPLAY,
  DEFAULT_NOTE_ROLE_FILTER,
  DEFAULT_NOTE_STATUS_FILTER
} from 'constants/appConstants';
import { ORDER_BY } from 'constants/myTeam';
import { MINIMUM_PAGE_COUNT } from 'constants/page';
import { NOTE_SUMMARY_LABEL_LENGTH } from 'constants/string';

import useTitle from 'hooks/useTitle';
import * as noteService from 'services/note';

const NoteSummary = props => {
  useTitle('Notes Summary');

  const queryParams = parse(props.location.search, {
    decoder: str => decodeURIComponent(str)
  });

  const [filters, setFilters] = useState({
    status: queryParams.status || DEFAULT_NOTE_STATUS_FILTER.value,
    role: queryParams.role,
    page: queryParams.page || DEFAULT_PAGE_NUMBER,
    size: queryParams.pageSize || DEFAULT_PAGE_SIZE,
    order: queryParams.order || DEFAULT_SORT_ORDER,
    sortBy: queryParams.sortBy
  });
  const [pageData, setPageData] = useState({});
  const [pageCount, setPageCount] = useState(DEFAULT_PAGE_NUMBER);
  const [columnToSortBy, setColumnToSortBy] = useState('actionDate');
  const [isAscendingOrdered, setIsAscendingOrdered] = useState(true);
  const [notes, setNotes] = useState([]);
  const [isFetchingNotes, setIsFetchingNotes] = useState(false);

  const fetchAllNotes = async () => {
    setIsFetchingNotes(true);

    try {
      const { size, page, status, role, sortBy, order } = queryParams;

      const params = {
        page,
        size,
        status,
        role,
        sortBy,
        order
      };

      const { data, meta } = await noteService.fetchNotes(params);

      const { total } = meta;
      const pageCount = Math.ceil(total / size);
      setPageData(meta);

      const notes = data.map((datum, index) => {
        return {
          ...datum,
          sn: (page - 1) * size + (index + 1)
        };
      });

      setNotes(notes);
      setPageCount(pageCount);
    } catch (error) {
      handleError(error);
    } finally {
      setIsFetchingNotes(false);
    }
  };

  const updateUrl = useCallback(
    value => {
      const queryParam = stringify('', value, { arrayFormat: 'comma', encode: false });
      history.push({
        pathname: props.location.pathname,
        search: queryParam
      });
    },
    [props.location.pathname]
  );

  const getParams = useCallback(
    prev => ({
      ...prev,
      ...parse(props.location.search, {
        decoder: str => decodeURIComponent(str)
      })
    }),
    [props.location.search]
  );

  const onPageSizeChange = useCallback(
    selectedSize => {
      setFilters(prev => {
        const params = getParams(prev);

        params.page = DEFAULT_PAGE_NUMBER;
        params.size = selectedSize;

        return { ...params };
      });
    },
    [getParams]
  );

  const onPageChange = useCallback(
    selectedPage => {
      setFilters(prev => {
        const params = getParams(prev);

        params.page = selectedPage;

        return { ...params };
      });
    },
    [getParams]
  );

  const onStatusChange = useCallback(
    selectedStatus => {
      setFilters(prev => {
        const params = getParams(prev);

        if (!selectedStatus) {
          delete params.status;
        } else {
          params.status = selectedStatus;
        }

        return { ...params };
      });
    },

    [getParams]
  );

  const onRoleChange = useCallback(
    selectedRole => {
      setFilters(prev => {
        const params = getParams(prev);

        if (!selectedRole) {
          delete params.role;
        } else {
          params.role = selectedRole;
        }

        return { ...params };
      });
    },

    [getParams]
  );

  const onHeaderClick = useCallback(
    name => {
      const order = filters.sortBy === name && filters.order === ORDER_BY.ASC ? ORDER_BY.DESC : ORDER_BY.ASC;
      setFilters(prev => ({
        ...prev,
        sortBy: name,
        order
      }));

      setColumnToSortBy(name);
      setIsAscendingOrdered(order === ORDER_BY.ASC);
    },
    [filters]
  );

  const getColumns = (isAscendingOrdered, setIsAscendingOrdered, columnToSortBy, setColumnToSortBy) => [
    {
      Header: <TableHeader label="SN." identifier="sn" isAscendingOrdered={isAscendingOrdered} />,
      accessor: 'sn',
      maxWidth: 80,
      className: 'd-flex align-items-center',
      Cell: ({ original: note }) => <div className="text-center">{note.sn}</div>
    },
    {
      Header: (
        <TableHeader
          label="Title"
          identifier="title"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => {
            onHeaderClick('title');
          }}
        />
      ),
      accessor: 'title',
      maxWidth: 250,
      className: 'd-flex align-items-center',
      Cell: ({ original: note }) => (
        <PopOver
          visible={note.label.length > NOTE_SUMMARY_LABEL_LENGTH}
          interactive
          html={
            <div className="tooltip-info word-break">
              <p className="tooltip-info__title">{note.label}</p>
            </div>
          }
          customClass="ellipsis"
        >
          <span className="ellipsis">{note.label}</span>
        </PopOver>
      )
    },
    {
      Header: (
        <TableHeader
          label="Status"
          identifier="status"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => onHeaderClick('status')}
        />
      ),
      accessor: 'status',
      maxWidth: 150,
      className: 'd-flex align-items-center',
      Cell: ({ original: note }) => (
        <PillStatus isActive={note.status === NOTE_STATUS_TYPES.ACTIVE} label={capitalize(note.status)} />
      )
    },
    {
      Header: (
        <TableHeader
          label="Action Date"
          identifier="actionDate"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => onHeaderClick('actionDate')}
        />
      ),
      accessor: 'actionDate',
      maxWidth: 150,
      className: 'd-flex align-items-center',
      Cell: ({ original: note }) => (note.actionDate ? getFormattedDate(note.actionDate, DATE_FORMAT_FOR_DISPLAY) : '-')
    },
    {
      Header: (
        <TableHeader
          label="Created Date"
          identifier="createdDate"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => onHeaderClick('createdDate')}
        />
      ),
      accessor: 'createdDate',
      maxWidth: 150,
      className: 'd-flex align-items-center',
      Cell: ({ original: note }) => <>{getFormattedDate(note.createdAt, DATE_FORMAT_FOR_DISPLAY)}</>
    },
    {
      Header: (
        <TableHeader
          label="Notes of"
          identifier="noteOf"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => onHeaderClick('noteOf')}
        />
      ),
      accessor: 'noteOf',
      maxWidth: 300,
      className: 'd-flex align-items-center',
      Cell: ({ original: note }) => (
        <AvatarLinkComponent empId={note.user.empId} id={note.user.id} name={note.user.fullname} />
      )
    },
    {
      Header: (
        <TableHeader
          label="Creator"
          identifier="creator"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => onHeaderClick('creator')}
        />
      ),
      accessor: 'creator',
      maxWidth: 300,
      className: 'd-flex align-items-center',
      Cell: ({ original: note }) => (
        <AvatarLinkComponent empId={note.noteCreator.empId} id={note.noteCreator.id} name={note.noteCreator.fullName} />
      )
    }
  ];

  useEffect(() => {
    updateUrl({
      ...filters
    });
  }, [filters]);

  useEffect(() => {
    if (isObjectEmpty(queryParams)) {
      return;
    }

    fetchAllNotes();
  }, [props.location.search]);

  return (
    <main>
      <div className="container">
        <div className="full-scope-card">
          <div className="full-scope-card__header table-header name-wrap">
            <div className="d-flex flex-row">
              <h3 className="table-title">Notes Summary</h3>
            </div>
            <div className="d-flex gap-10">
              <MaterialDropdown
                isSearchable={false}
                options={NOTE_STATUS_FILTER}
                isPlain
                isSmall
                autoResize={true}
                value={NOTE_STATUS_FILTER.find(item => filters.status === item.value) || DEFAULT_NOTE_ROLE_FILTER}
                onChange={option => {
                  onStatusChange(option.value);
                }}
                placeholder="Status"
              />
              <div className="d-flex">
                <MaterialDropdown
                  isSearchable={false}
                  options={NOTE_ROLE_FILTER}
                  isPlain
                  isBig
                  autoResize={true}
                  value={NOTE_ROLE_FILTER.find(item => queryParams.role === item.value) || DEFAULT_NOTE_ROLE_FILTER}
                  onChange={option => {
                    onRoleChange(option.value);
                  }}
                  placeholder="Role"
                />
              </div>
            </div>
          </div>
          <div className={classNames('full-scope-card__content')}>
            <NoteAccordionTable
              columns={[...getColumns(isAscendingOrdered, setIsAscendingOrdered, columnToSortBy, setColumnToSortBy)]}
              data={notes}
              loading={isFetchingNotes}
              subComponent={({ index, original: note }) => (
                <EmployeeNoteItem key={index} note={note} showHeader={false} />
              )}
              showPagination={false}
              emptyMessage="Notes not found."
            />
            <Pagination
              pageData={pageData}
              pageCount={+(pageCount || MINIMUM_PAGE_COUNT)}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default NoteSummary;
