import { queryClient } from 'index';
import { MdAdd } from 'react-icons/md';
import React, { useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { UiPen, UiLinkAdd } from 'vyaguta-icons/ui';

import LFModal from 'components/common/LFModal/LFModal';

import { deleteQuickLinksCategory } from 'services/quickLinks';

import AddLink from './AddLink';
import AddCategory from './AddCategory';
import DeletePopup from './DeletePopup';

import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';

const SubCategoryVerticalEllipsis = props => {
  const { selectedCategory } = props;

  const [isSubCategoryModalOpen, setIsSubCategoryModalOpen] = useState(false);
  const [isCategoryEditModalOpen, setIsCategoryEditModalOpen] = useState(false);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

  const closeCategoryEditModal = () => {
    setIsCategoryEditModalOpen(false);
  };

  const closeSubCategoryModal = () => {
    setIsSubCategoryModalOpen(false);
  };

  const closeLinkModal = () => {
    setIsLinkModalOpen(false);
  };

  const EditCategoryPopUp = ({ onClick }) => {
    return (
      <li
        className="overflow-menu__list-item overflow-menu__list-item--hoverable d-flex"
        onClick={() => {
          setIsCategoryEditModalOpen(true);
          onClick();
        }}
      >
        <span className="icon overflow-menu__icon">
          <UiPen className="grey--text" size={16} />
        </span>
        <span>Edit</span>
      </li>
    );
  };

  const AddLinkPopup = ({ onClick }) => {
    return (
      <li
        className="overflow-menu__list-item overflow-menu__list-item--hoverable d-flex"
        onClick={() => {
          setIsLinkModalOpen(true);
          onClick();
        }}
      >
        <span className="icon overflow-menu__icon">
          <UiLinkAdd className="grey--text" size={16} />
        </span>
        <span>Add new link</span>
      </li>
    );
  };

  const AddLinkSubCategory = ({ onClick }) => {
    return (
      <li
        className="overflow-menu__list-item overflow-menu__list-item--hoverable d-flex"
        onClick={() => {
          setIsSubCategoryModalOpen(true);
          onClick();
        }}
      >
        <span className="icon overflow-menu__icon">
          <MdAdd className="grey--text" size={16} />
        </span>
        <span>Add new sub-category</span>
      </li>
    );
  };

  const deleteCategory = async () => {
    try {
      const data = await deleteQuickLinksCategory(selectedCategory.id);

      toast.success({
        title: 'Success',
        message: data.message
      });

      queryClient.invalidateQueries('quickLinks');
      // await setHierarchyData(selectedCategory.parentId);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="overflow-menu">
      <div className="overflow-menu__block">
        <ul className="overflow-menu__list">
          <AddCategory
            isEdit={true}
            key="editSubCategory"
            isOpen={isCategoryEditModalOpen}
            onClose={closeCategoryEditModal}
            isSubCategory={true}
            selectedCategory={selectedCategory}
            TriggerBtn={EditCategoryPopUp}
          />
          <AddCategory
            isSubCategory={true}
            key="addCategory"
            isEdit={false}
            isOpen={isSubCategoryModalOpen}
            onClose={closeSubCategoryModal}
            selectedCategory={selectedCategory}
            TriggerBtn={AddLinkSubCategory}
          />
          <AddLink
            isEdit={false}
            isOpen={isLinkModalOpen}
            onClose={closeLinkModal}
            selectedLink={selectedCategory}
            TriggerBtn={AddLinkPopup}
          />
          <LFModal
            TriggerBtn={DeletePopup}
            modalStatusIcon={<FiAlertTriangle color="#F44336" size={24} />}
            title={{ text: `Delete category ${selectedCategory.name}`, type: 'danger' }}
            className="delete-modal"
            htmlContent={
              <div className="d-grid mbpx-4">
                <span className="delete__text">
                  This action cannot be reverted back. The sub-category {selectedCategory.name} will be deleted with the
                  links or category under it.
                </span>
              </div>
            }
            renderFooter={true}
            buttons={[
              {
                text: 'Yes, delete',
                type: 'red',
                className: 'mr-12 delete__btn',
                onClick: deleteCategory,
                close: true,
                showLoading: true
              },
              {
                text: 'No, go back',
                type: 'outlined-grey',
                className: 'py-8 px-12',
                close: true
              }
            ]}
          />
        </ul>
      </div>
    </div>
  );
};

export default SubCategoryVerticalEllipsis;
