import { useState } from 'react';

function useDataState(loading = false, defaultValue = []) {
  const [isLoading, setIsLoading] = useState(loading);

  const [data, setData] = useState(defaultValue);

  return {
    data,
    setData,
    isLoading,
    setIsLoading
  };
}

export default useDataState;
