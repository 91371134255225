/* eslint-disable react-hooks/exhaustive-deps */
import { components } from 'react-select';
import React, { useState, useEffect } from 'react';

import { UiSearch } from 'vyaguta-icons/ui';

import DropDown from 'components/common/dropDown';

import { handleError } from 'utils/errorHandler';
import { generateReactSelectSkillGroupOptions } from 'utils/reactSelect';

import useDataState from 'hooks/useDataState';

import * as skillV2Service from 'services/skillV2';

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props} className="px-8">
        <UiSearch />
      </components.DropdownIndicator>
    )
  );
};

function SearchSkill(props) {
  const { setSelectedSearchSkill, selectedSearchSkill, triggerSearchSkillTree } = props;

  const [searchInput, setSearchInput] = useState('');

  const { data, setData, isLoading, setIsLoading } = useDataState();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const { data } = await skillV2Service.fetchEndLevelSkills();

        setData(data);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [triggerSearchSkillTree]);

  const skillOptions = generateReactSelectSkillGroupOptions(data, true);

  return (
    <DropDown
      // Open dropdown menu when input text length is more than or equal to 3
      menuIsOpen={!!searchInput.length}
      options={skillOptions}
      isSearchable
      isClearable
      isLoading={isLoading}
      isDisabled={isLoading}
      value={selectedSearchSkill}
      isBigSearchBox
      components={{
        DropdownIndicator
      }}
      onChange={option => {
        setSelectedSearchSkill(option);
      }}
      onInputChange={(string, { action }) => {
        setSearchInput(string);
      }}
      customStyles={{
        control: base => ({
          ...base,
          flexDirection: 'row-reverse'
        }),
        dropdownIndicator: base => ({
          ...base,
          padding: '0 8px'
        }),
        clearIndicator: base => ({
          ...base,
          padding: '0 8px',
          position: 'absolute',
          right: 0
        }),
        valueContainer: base => ({
          ...base,
          padding: '0',
          marginRight: '20px'
        }),
        indicatorsContainer: base => ({
          ...base,
          display: 'flex',
          flexDirection: 'row-reverse'
        })
      }}
    />
  );
}

export default SearchSkill;
