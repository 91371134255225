export const TRAINING_TRUNCATE_LENGTH = 68;
export const NOTE_SUMMARY_LABEL_LENGTH = 32;
export const MY_TEAM_PROJECT_NAME_LENGTH = 20;
export const MY_TEAM_FULLNAME_LENGTH = 21;
export const LEAVE_ISSUER_CHANGE_REASON_LENGTH = 20;
export const LEAVE_ISSUER_CHANGE_USER_NAME_LENGTH = 16;

export const REMINDER_TITLE_LENGTH = 64;

export const EVENT_NAME_LENGTH = 43;
export const EVENT_DESCRIPTION_LENGTH = 54;

export const NOTICE_TITLE_LENGTH = 40;
export const NOTICE_DESCRIPTION_LENGTH = 42;

export const HOLIDAY_NAME_LENGTH = 36;
export const HOLIDAY_DESCRIPTION_LENGTH = 50;

export const NOTICE_BOARD_TITLE_LENGTH = 56;

export const TOOLTIP_LIMIT_LENGTH = 58;
