import React from 'react';

import Feedback from './components/Feedback';
import ReportBug from './components/BugReport';
import ReleaseNote from './components/ReleaseNote';

const Footer = props => {
  return (
    <div className="footer">
      <div className="container d-flex footer__body">
        <div className="footer__content">
          <ReportBug />
        </div>

        <div className="footer__content ml-25">
          <Feedback />
        </div>

        <ReleaseNote />
      </div>
    </div>
  );
};

export default Footer;
