/**
 * Downloads a CSV file with the specified data and file name.
 *
 * @param {any} blob The data to download as a CSV file.
 * @param {string} fileName The name of the file to download.
 */
export function downloadCSV(blob, fileName) {
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  link.click();

  setTimeout(() => URL.revokeObjectURL(link.href), 0);
}
