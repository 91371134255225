import React from 'react';

import LFModal from 'components/common/LFModal';

import { MedianClockInTime } from './MedianClockInTime';
import { ClockinHistoryTable } from './ClockinHistoryTable';

export function ClockinHistoryModal(props) {
  const {
    TriggerBtn,
    isOpen,
    onClose,
    clockinHistoryData,
    isClockinHistoryLoading,
    medianClockInTime,
    unfreezeRequests
  } = props;

  const modalHeader = 'My clock-in time history';

  return (
    <LFModal
      TriggerBtn={TriggerBtn}
      isOpen={isOpen}
      onClose={onClose}
      closeButton
      htmlContent={
        <>
          <MedianClockInTime clockInTime={medianClockInTime} />
          <ClockinHistoryTable
            clockinHistoryData={clockinHistoryData}
            isLoading={isClockinHistoryLoading}
            unfreezeRequests={unfreezeRequests}
          />
        </>
      }
      title={{
        text: modalHeader,
        type: 'info'
      }}
      className="clockin-history-modal"
    />
  );
}
