import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';

import { en } from 'constants/lang/en';

import globalRatingSchema from 'schemas/globalRatingSchema';

import { success } from 'utils/toast';
import { interpolate } from 'utils/string';
import { getNameAndValue } from 'utils/object';
import { handleError } from 'utils/errorHandler';

import { fetchGlobalRating } from 'slices/globalRatingSlice';

import * as skillV2Service from 'services/skillV2';

import GlobalRatingList from './GlobalRatingList';
import GlobalRatingInput from './GlobalRatingInput';

const initialValues = { one: '', two: '', three: '', four: '' };

function GlobalRatingModal(props) {
  const { globalRatings, isLoadingGlobalRating, openGlobalRatingModal, onGlobalRatingModalClose } = props;

  const dispatch = useDispatch();

  const handleSubmits = async (values, actions) => {
    const refineData = {
      globalRatings: [
        {
          rating: 1,
          description: values.one
        },
        {
          rating: 2,
          description: values.two
        },
        {
          rating: 3,
          description: values.three
        },
        {
          rating: 4,
          description: values.four
        }
      ]
    };

    try {
      actions.setSubmitting(true);

      await skillV2Service.updateGlobalRating(refineData);

      success({ title: 'Success', message: interpolate(en.UPDATE_DATA, { type: en.GLOBAL_RATING }) });

      getNameAndValue(initialValues, actions.setFieldValue);

      onGlobalRatingModalClose();

      getGlobalRating();
    } catch (err) {
      handleError(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const getGlobalRating = async () => {
    try {
      await dispatch(fetchGlobalRating());
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <LFModal
      isOpen={openGlobalRatingModal}
      onClose={onGlobalRatingModalClose}
      closeButton={true}
      title={{ text: en.MODAL.EDIT_GLOBAL_RATING, type: 'info' }}
      className="global-rating-modal"
      htmlContent={
        <div>
          <div className="px-16">
            <p className="mb-8 color-grey-80 font-size-14">Current global rating standard:</p>

            {isLoadingGlobalRating && (
              <div className="global-rating-loading position-relative">
                <Loading />
              </div>
            )}

            {!isLoadingGlobalRating && globalRatings && <GlobalRatingList globalRatings={globalRatings} />}
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={globalRatingSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmits}
            render={({ values, touched, handleSubmit, handleChange, handleBlur, errors, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="global-rating-form">
                    <div className="p-16">
                      <p className="mb-8 color-grey-80 font-size-14">Set new global rating standard below.</p>

                      <GlobalRatingInput
                        rating={1}
                        name="one"
                        value={values.one}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors.one}
                        placeholder={globalRatings[0]?.description}
                      />

                      <GlobalRatingInput
                        rating={2}
                        name="two"
                        value={values.two}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors.two}
                        placeholder={globalRatings[1]?.description}
                      />

                      <GlobalRatingInput
                        rating={3}
                        name="three"
                        value={values.three}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors.three}
                        placeholder={globalRatings[2]?.description}
                      />

                      <GlobalRatingInput
                        rating={4}
                        name="four"
                        value={values.four}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors.four}
                        placeholder={globalRatings[3]?.description}
                      />

                      <p className="pt-8 color-grey-80 font-size-14">
                        For local rating, you can set them on the respective skill.
                      </p>
                    </div>

                    <div className="footer p-16">
                      <button
                        className="button button--secondary position-relative"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {en.BUTTON.SAVE}

                        {isSubmitting && <Loading />}
                      </button>

                      <button className="button button--primary" type="button" onClick={onGlobalRatingModalClose}>
                        {en.BUTTON.CANCEL}
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      }
    />
  );
}

export default GlobalRatingModal;
