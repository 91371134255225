import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { HIRED, TERMINATED } from 'constants/employeeStatus';

import EmploymentHistory from './EmploymentHistory';

import { pluralize } from 'utils/string';

function EmployeeHistoryList({ employee }) {
  const formatDate = (yearString, dayString) => {
    return (yearString ? yearString + ' ' : '') + dayString;
  };

  const getEmployedNumberOfDay = (empStatus, joinDate, empStatusHistory) => {
    const userJoinDate = moment(joinDate);
    let currentDate = moment();

    if (empStatus === TERMINATED) {
      if (!empStatusHistory) {
        return;
      }

      const latestEmpStatus = empStatusHistory[0];
      const terminationDate = moment(latestEmpStatus.transitionDate);
      // If terminated, set the current date to the termination date
      currentDate = terminationDate;
    }

    // Include end date in count by adding one day
    currentDate.add(1, 'days');

    if (empStatus === HIRED) {
      const noOfEmployedDay = currentDate.diff(userJoinDate, 'days');
      const daysToBeHired = Math.abs(noOfEmployedDay);
      return noOfEmployedDay <= 0
        ? `${daysToBeHired} ${pluralize('day', daysToBeHired)} to be hired`
        : `${daysToBeHired} ${pluralize('day', daysToBeHired)} exceeded`;
    }

    const years = currentDate.diff(userJoinDate, 'years');

    const userCurrentYearStartDate = userJoinDate.add(years, 'years');

    const days = currentDate.diff(userCurrentYearStartDate, 'days');

    const yearString = years > 0 ? pluralize(`${years} year`, years) : '';
    const dayString = pluralize(`${days} day`, days);

    return formatDate(yearString, dayString);
  };

  const totalEmploymentHistory = getEmployedNumberOfDay(
    employee.empStatus,
    employee.joinDate,
    employee.empStatusHistory
  );

  const shouldShowEmploymentHistory = employee.empStatusHistory && employee.designationAreaHistory;

  return (
    <div className="work-experience-wrapper custom-scroll-bar">
      <div
        className={classNames('emp-experience-title', {
          'mb-5': totalEmploymentHistory,
          'mb-20': !totalEmploymentHistory
        })}
      >
        Employment History
      </div>
      {totalEmploymentHistory && <div className="total-experience work-experience mb-20">{totalEmploymentHistory}</div>}
      {shouldShowEmploymentHistory ? (
        <EmploymentHistory
          empStatusHistory={employee.empStatusHistory}
          designationAreaHistory={employee.designationAreaHistory}
        />
      ) : (
        employee.designation && (
          <div className="position-relative">
            <div className="employee-status-wrapper employee-status-wrapper-last">
              <div className="employee-status">
                <div className="designation-title font-bold current-designation-title">
                  {employee.designation.name}
                  {employee.designation.area && `, ${employee.designation.area.name}`}
                </div>
                <div className="engagement-status">{employee.empStatus}</div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default EmployeeHistoryList;
