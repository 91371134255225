import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';

import useTitle from 'hooks/useTitle';

import history from 'utils/history';
import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';
import { interpolate, pluralize } from 'utils/string';

import Table from 'components/common/table';
import PopOver from 'components/common/popover';
import EditDelete from 'components/common/editDelete';
import TableHeader from 'components/common/table/TableHeader';

import * as designationService from 'services/designation';

import { DESIGNATIONS } from 'constants/permissions';
import { CREATE_DESIGNATION, UPDATE_DESIGNATION } from 'constants/routes';

const Designation = props => {
  useTitle('Designations');

  const [tableData, setTableData] = useState([]);
  const [size, setSize] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [columnToSortBy, setColumnToSortBy] = useState('sn');
  const [isAscendingOrdered, setIsAscendingOrdered] = useState(true);

  const {
    value: { permissions }
  } = useSelector(state => state.information);

  const fetchDesignations = async () => {
    try {
      setIsLoading(true);

      const response = await designationService.fetchAll();

      const { data } = response;

      const dataWithSN = data.map((data, index) => {
        const sn = index + 1;
        return {
          ...data,
          sn
        };
      });

      setTableData([...dataWithSN]);
      setIsLoading(false);
      setSize(data.length);
    } catch (err) {
      setIsLoading(false);

      handleError(err);
    }
  };

  useEffect(() => {
    fetchDesignations();
  }, []);

  const getRowProps = (state, rowInfo) => {
    return {
      className: 'align-items-center d-flex',
      onClick: e => {
        if (!permissions[DESIGNATIONS.UPDATE]) {
          return;
        }

        const url = interpolate(UPDATE_DESIGNATION, { id: rowInfo.original.id });

        if (e.ctrlKey || e.metaKey) {
          window.open(url);
        } else {
          history.push(url);
        }
      }
    };
  };

  const handleEdit = row => {
    history.push(interpolate(UPDATE_DESIGNATION, { id: row.original.id }));
  };

  const handleDelete = async row => {
    try {
      await designationService.remove(row.original.id);

      toast.success({
        title: 'Success',
        message: 'Designation deleted successfully.'
      });

      fetchDesignations();
    } catch (error) {
      handleError(error);
    }
  };

  const getDeleteCell = () => {
    const canEdit = permissions[DESIGNATIONS.UPDATE];

    const canDelete = permissions[DESIGNATIONS.DELETE];

    const canEditOrDelete = canEdit || canDelete;

    return {
      Header: '',
      width: 6,
      className: 'margin-left-auto cursor-default',
      Cell: row =>
        canEditOrDelete ? (
          <span
            className="verticle-ellipse cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <PopOver
              interactive
              trigger="click"
              theme="light"
              size="regular overflow-menu__regular"
              position="left-start"
              disableOnScroll={true}
              popperOptions={{
                modifiers: {
                  addZIndex: {
                    enabled: true,
                    order: 810,
                    fn: data => ({
                      ...data,
                      styles: {
                        ...data.styles,
                        zIndex: 10
                      }
                    })
                  }
                }
              }}
              html={
                <EditDelete
                  onDelete={() => handleDelete(row)}
                  onEdit={() => handleEdit(row)}
                  canEdit={canEdit}
                  canDelete={canDelete}
                  type="designation"
                  title={row.original.name}
                />
              }
            >
              <BsThreeDotsVertical size={20} />
            </PopOver>
          </span>
        ) : null
    };
  };

  const columns = [
    {
      Header: (
        <TableHeader
          label="SN."
          identifier="sn"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => {
            if (columnToSortBy !== 'sn') {
              setIsAscendingOrdered(true);
            } else {
              setIsAscendingOrdered(!isAscendingOrdered);
            }
            setColumnToSortBy('sn');
          }}
        />
      ),
      accessor: 'sn',
      maxWidth: 72,
      Cell: props => {
        return (
          <span className="capital-text employees__table-text">
            {props.value < 10 ? `0${props.value}` : props.value}
          </span>
        );
      }
    },
    {
      Header: (
        <TableHeader
          label="Name"
          identifier="name"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => {
            if (columnToSortBy !== 'name') {
              setIsAscendingOrdered(true);
            } else {
              setIsAscendingOrdered(!isAscendingOrdered);
            }
            setColumnToSortBy('name');
          }}
        />
      ),
      accessor: 'name',
      maxWidth: 500,
      Cell: props => {
        return <span className="text-bold capital-text">{props.original.name}</span>;
      }
    },
    {
      Header: (
        <TableHeader
          label="Description"
          identifier="description"
          columnToSortBy={columnToSortBy}
          isAscendingOrdered={isAscendingOrdered}
          onClick={() => {
            if (columnToSortBy !== 'description') {
              setIsAscendingOrdered(true);
            } else {
              setIsAscendingOrdered(!isAscendingOrdered);
            }
            setColumnToSortBy('description');
          }}
        />
      ),
      accessor: 'description',
      maxWidth: 600
    }
  ];

  const defaultSorted = [
    {
      id: 'sn',
      desc: false
    }
  ];

  return (
    <div className="full-scope-card">
      <div className="full-scope-card__header table-header name-wrap">
        <div>
          <div className="table-title">Designations</div>
          <div className="table-subtitle">
            Showing {tableData.length} {pluralize('Result', tableData.length)}
          </div>
        </div>
        {permissions[DESIGNATIONS.CREATE] && (
          <Link className="f-left add-employee-button" to={CREATE_DESIGNATION}>
            Add Designation
          </Link>
        )}
      </div>

      <div className="full-scope-card__content">
        <Table
          data={tableData}
          columns={[...columns, getDeleteCell()]}
          loading={isLoading}
          defaultSorted={defaultSorted}
          showPagination={false}
          noDataText={'Designation Not Found'}
          minRows={size}
          getTrProps={getRowProps}
        />
      </div>
    </div>
  );
};

export default Designation;
