import config from '../config';

import http from 'utils/http';

export async function fetch(params = {}) {
  const url = `${config.coreBaseURI}${config.endpoints.fiscalYears}`;
  const { data } = await http.get(url, { params });

  return data;
}
