import React from 'react';
import Select from 'react-select';
import { MdAdd } from 'react-icons/md';

import ScoreTab from 'components/home/CreateUpdateEmployeeForm/components/Scoretab';

const SkillsSelectorV1 = props => {
  const { skillOptions, loading, onAddSkill, skills, ratings, onSkillChange, onRatingChange } = props;

  return (
    <>
      <div className="skills-label">
        <div className="f-left skill-label filter-label">{'Skills'}</div>
        <div className="f-right rating-label filter-label">{'Ratings'}</div>
      </div>
      {skills.map((skill, index) => {
        return (
          <div className="skill-select-node clearfix" key={index}>
            <div className="f-left skill-select">
              <Select
                options={skillOptions}
                isLoading={loading}
                isSearchable={true}
                isClearable={true}
                value={skillOptions.find(option => option.value === +skill) || null}
                onChange={option => {
                  const value = option && option.value;
                  onSkillChange(index, value);

                  if (ratings[index] == null && value) {
                    onRatingChange(index, 0);
                  }
                }}
              />
            </div>
            <div className="f-right rating-select">
              <ScoreTab
                lightMode={true}
                id={index}
                scoreValue={+ratings[index]}
                handleChange={(name, value) => {
                  onRatingChange(index, value);
                }}
              />
            </div>
          </div>
        );
      })}
      <div className="add-more-skill-filter-v1" onClick={onAddSkill}>
        <span className="icon-round m-auto skill-add">
          <MdAdd />
        </span>
        <span className="skill-add-label-v1">{'Add Another'}</span>
      </div>
    </>
  );
};

export default SkillsSelectorV1;
