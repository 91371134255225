import { Formik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useState } from 'react';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import InputWrapper from 'components/common/inputWrapper';
import Input from 'components/common/inputWrapper/components/Input';

import { ADD_CATEGORY, EDIT_CATEGORY } from 'constants/skills';

import { addCategorySkillSchema } from 'schemas/addSkillCategorySchema';

import * as skillV2Service from 'services/skillV2';

import { success } from 'utils/toast';
import { handleError } from 'utils/errorHandler';

const AddSkillCategoryModal = props => {
  const { categoryData, isOpen, onClose, callback } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const initialValues = {
    name: categoryData?.name || '',
    description: categoryData?.description || ''
  };

  const isEditMode = !!categoryData;

  const handleSubmit = async (body, submitProps) => {
    try {
      setIsSubmitting(true);

      if (!isEditMode) {
        const data = await skillV2Service.createCategory(body);

        callback && callback(data);
        onClose();
        success({ title: 'Success', message: data.message });
      } else {
        const data = await skillV2Service.updateCategory(categoryData.id, body);

        callback && callback(data);
        onClose();
        success({ title: 'Success', message: data.message });
      }
    } catch (err) {
      handleError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const TriggerBtn = () => {
    return <div className="hidden"></div>;
  };

  const showDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const onDelete = async () => {
    if (isEditMode) {
      try {
        setIsDeleting(true);

        await skillV2Service.deleteCategory(categoryData.id);

        success({
          title: 'Success',
          message: 'The category has been deleted successfully.'
        });

        callback && callback();
      } catch (error) {
        handleError(error);
      } finally {
        onClose();
        setIsDeleting(false);
      }
    }
  };

  return (
    <LFModal
      TriggerBtn={TriggerBtn}
      isOpen={isOpen}
      onClose={onClose}
      closeButton={true}
      title={{ text: isEditMode ? EDIT_CATEGORY : ADD_CATEGORY, type: 'info' }}
      className="skill-modal"
      htmlContent={
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={addCategorySkillSchema}>
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            errors,
            values,
            dirty,
            setFieldValue,
            touched,
            setFieldTouched
          }) => {
            return (
              <form onSubmit={handleSubmit} className="skill-modal__form">
                <div className="skill-modal__form-body">
                  <Input
                    isMandatory
                    label="Category Name"
                    name="name"
                    placeholder="Type category name"
                    className="wp-100"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                    disabled={isSubmitting}
                    multiline={false}
                    type="text"
                  />

                  <InputWrapper label="Description" error={touched.description && errors.description}>
                    <textarea
                      name="description"
                      placeholder="Type category description here..."
                      className={classNames('form-elem custom-scroll-bar skill-modal__textarea', {
                        'form-elem--error': touched.description && errors.description
                      })}
                      value={values.description}
                      onChange={e => {
                        setFieldTouched('description', true, true);
                        setFieldValue('description', e.target.value);
                      }}
                      disabled={isSubmitting}
                    />
                  </InputWrapper>
                </div>

                <div className="skill-modal__footer">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={classNames('btn skill-modal__btn', {
                      'btn--primary': !isSubmitting && dirty,
                      'btn--disabled': isSubmitting || !dirty
                    })}
                    disabled={isSubmitting || !dirty}
                  >
                    <span className={classNames({ invisible: isSubmitting })}>{isEditMode ? 'Save' : 'Add'}</span>
                    {isSubmitting && <Loading />}
                  </button>

                  <button
                    type="button"
                    className={classNames('btn ml-10 skill-modal__btn', {
                      'btn--outlined-grey': !isSubmitting,
                      'btn--disabled': isSubmitting
                    })}
                    onClick={e => {
                      e.preventDefault();
                      onClose();
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                  {isEditMode && !categoryData?.subSkills?.length && (
                    <button
                      type="button"
                      className="btn btn--outlined-danger f-right card-button change-tentative-exit__btn"
                      onClick={showDeleteConfirmationModal}
                      disabled={isSubmitting}
                    >
                      Delete
                    </button>
                  )}
                </div>
                {isDeleteConfirmationModalOpen && (
                  <div className="skill-delete-modal absolute">
                    <div className="font-14">
                      <p>
                        {'Are you sure you want to delete the category: '} <b>{values.name}</b>
                        {'?'}
                      </p>
                      <p>{'It can not be undone once deleted.'}</p>
                    </div>
                    <div className="d-flex gap-4x mt-4 f-right">
                      <button
                        type="button"
                        className={classNames('btn ml-10 skill-modal__btn', {
                          'btn--outlined-grey': !isSubmitting,
                          'btn--disabled': isSubmitting
                        })}
                        onClick={closeDeleteConfirmationModal}
                        disabled={isDeleting}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn--outlined-danger f-right card-button min-width-80"
                        onClick={onDelete}
                        disabled={isDeleting}
                      >
                        {isDeleting ? <Loading /> : 'Yes, delete'}
                      </button>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      }
    />
  );
};

AddSkillCategoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  categoryData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string
  })
};

export default AddSkillCategoryModal;
