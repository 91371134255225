import moment from 'moment';

export const generateYearOptionsFrom = fromDate => {
  let toYear = moment().year() + 1;
  const fromYear = +moment(fromDate) - 2;

  const options = [];

  while (toYear >= fromYear) {
    options.push({
      label: toYear,
      value: toYear
    });
    toYear--;
  }

  return options;
};
