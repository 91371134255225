import { useSelector } from 'react-redux';
import React, { useState, useEffect, useMemo } from 'react';

import { UiInfoCircle } from 'vyaguta-icons/ui';

import Modal from 'components/common/modal';
import { SkillSet } from 'components/home/skills/components';
import SkillRatingInfo from 'components/home/employees/components/SkillRatingInfo';
import SearchSkill from 'components/home/skills/skillV2Component/SearchSkill/SearchSkill';

import { extractFullName } from 'utils/string';
import { getSearchSkillTree } from 'utils/skillV2';

const SkillPicker = props => {
  const { label, skills = [], employee, showAddSkills = true, selectedSkills } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [showSkillRatingInfo, setShowRatingInfo] = useState(false);
  const [selectedSearchSkill, setSelectedSearchSkill] = useState({});

  const {
    value: { permissions, user }
  } = useSelector(state => state.information);

  const loggedUserId = user.id;

  const employeeFullName = employee ? extractFullName(employee, false) : '';

  const getFormTitle = employeeFullName => {
    const renderText = loggedUserId === employee.id ? 'your' : `${employeeFullName}'s`;

    return `Rate ${renderText} Skills`;
  };

  const getPageTitle = employeeFullName => {
    const pageTitle = `${getFormTitle(employeeFullName)} | Vyaguta`;

    return pageTitle;
  };

  useEffect(() => {
    window.document.title = getPageTitle(employeeFullName);
  }, [employee]);

  const handleSearchChange = ({ target: { value: searchQuery } }) => {
    setSearchQuery(searchQuery);
  };

  const showSkillRateInfo = () => {
    setShowRatingInfo(true);
  };

  const searchSkillTree = useMemo(() => getSearchSkillTree(selectedSearchSkill, skills), [skills, selectedSearchSkill]);

  return (
    <>
      <div className="container">
        <div className="full-scope-card__header table-header name-wrap">
          <div className="d-flex align-items-center">
            <div className="table-title">{getFormTitle(employeeFullName)}</div>
            {/* <span className="rate-skill-info" onClick={showSkillRateInfo}>
              <UiInfoCircle size="18" />
            </span> */}
          </div>

          <SearchSkill setSelectedSearchSkill={setSelectedSearchSkill} selectedSearchSkill={selectedSearchSkill} />
        </div>
        {label ? <label className="form-label dark--text font-bold mb-10">{label}</label> : ''}
        <div className="full-scope-card emp-skills-selection d-flex position-relative primary-skills-card">
          <SkillSet
            skills={skills}
            showAddSkills={showAddSkills}
            searchSkillTree={searchSkillTree}
            selectedSkills={selectedSkills}
            setSelectedSearchSkill={setSelectedSearchSkill}
          />
        </div>
      </div>
      <Modal
        className="edit-skills-modal"
        isOpen={showSkillRatingInfo}
        closeButton={true}
        renderFooter={false}
        onClose={() => {
          setShowRatingInfo(false);
        }}
        title={{ text: 'Frequently Asked Questions' }}
        htmlContent={<SkillRatingInfo />}
      />
    </>
  );
};

export default SkillPicker;
