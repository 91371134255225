import React from 'react';

import { historyButtonText, historyDescriptionText } from 'constants/dashboard';

export function ClockinHistoryButton(props) {
  const { setIsHistoryModalOpen } = props;

  return (
    <div className="d-flex justify-content-between font-size-12 mb-8">
      <div className="color-grey-60">{historyDescriptionText}</div>
      <div
        role="button"
        onKeyUp={() => {}}
        tabIndex={0}
        onClick={() => {
          setIsHistoryModalOpen(true);
        }}
        className="median-office-time__history-text cursor-pointer color-tertiary-blue-40"
      >
        {historyButtonText}
      </div>
    </div>
  );
}
