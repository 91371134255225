export const en = {
  CREATE: 'Create',
  UPDATE: 'Update',
  PREVIOUS: '« Previous',
  NEXT: 'Next »',
  EMPLOYEE: 'Employee',
  PULSE: 'Pulse',
  GLOBAL_RATING: 'Global rating',
  CREATE_DATA: ':type has been added',
  UPDATE_DATA: ':type has been updated',
  BUTTON: {
    SAVE: 'Save',
    BACK: 'Back',
    APPLY_FILTER: 'Apply Filter',
    RESET: 'Reset',
    CANCEL: 'Cancel',
    DISCARD_CHANGES: 'Discard changes',
    GO_BACK: 'Go back',
    SEARCH: 'Search',
    CLEAR: 'Clear',
    ADD_ANOTHER_SKILL_FILTER: 'Add another skill filter',
    EDIT_GLOBAL_RATING: 'Edit Global Rating',
    ADD_CATEGORY: 'Add Category'
  },
  MODAL: {
    EDIT_GLOBAL_RATING: 'Edit Global Rating'
  }
};
