import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Avatar } from '@vyaguta/vyaguta-ui';
import { UiDownloadAlt } from 'vyaguta-icons/ui';

import Empty from 'components/common/empty/Empty';
import Loading from 'components/common/loading/Loading';

import * as routes from 'constants/routes';
import { EMPLOYEES } from 'constants/permissions';

import { extractFullName, getEmpImageUrl, interpolate, pluralize } from 'utils/string';

const parseSkillsFrom = skills =>
  skills && skills.length ? (
    <div className="d-flex fd-row f-wrap">
      {skills.map(skill => (
        <div className="selected-skill" key={skill.id}>
          <span>{skill.name}</span>
          {skill.rating && (
            <div className="skill-rating">
              <span>{skill.rating}</span>
            </div>
          )}
        </div>
      ))}
    </div>
  ) : null;

const EmployeeRow = ({ employee: { id, empId, designation, skills, ...otherInfo } }) => {
  return (
    <div className="employee-row clearfix">
      <Avatar
        className="left nametag nametag--lg profile-photo people-finder-profile"
        image={getEmpImageUrl(empId)}
        alt={extractFullName(otherInfo)}
      />
      <div className="left namecard__info">
        <Link className="profile-link text--bold text--grey fs-13" to={interpolate(routes.EMPLOYEE_PROFILE, { id })}>
          {extractFullName(otherInfo)}
        </Link>
        <div className="muted-text res-designation-name">{designation.name}</div>
        <div className="mb-20">{parseSkillsFrom(skills)}</div>
      </div>
    </div>
  );
};

const ResourceResultsV1 = props => {
  const { total, employees, exporting, onExport } = props;

  const {
    value: { permissions }
  } = useSelector(state => state.information);

  return (
    <>
      <div className="resource-list-header table-header name-wrap">
        <div className="d-flex flex-row">
          <span className="resource-header-title-v1">{'Leapfroggers'}</span>
        </div>
        <div className="d-flex flex-row ai-center">
          <span className="resource-results">{pluralize(`${total || 'No'} Result`, total)}</span>
          {permissions[EMPLOYEES.EXPORT] && !!total && (
            <button className="btn btn--outlined-grey export-button" onClick={onExport} disabled={exporting}>
              {exporting ? (
                <div className="export-button loading">
                  <Loading />
                </div>
              ) : (
                <>
                  <UiDownloadAlt className="download-icon" />
                  {'.CSV'}
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <div className="resource-result-list">
        {employees && employees.length ? (
          employees.map((employee, index) => <EmployeeRow employee={employee} key={index} />)
        ) : (
          <div className="empty-container__wrapper">
            <Empty message={'No leapfroggers found'} />
          </div>
        )}
      </div>
    </>
  );
};

export default ResourceResultsV1;
