/**
 * It removes a element from an array which evaluates to true on applying function cb and returns it.
 *
 * @param {Array<item:any>} array
 * @param {Function} cb
 * @param {Any} fallback
 *
 * @returns {Any}
 */
export const findOnArrayAndRemove = (array, cb, fallback) => {
  const index = array.findIndex(cb);

  if (index === -1) {
    return fallback;
  }

  return array.splice(index, 1)[0];
};

/**
 * Returns new array with the given index removed.
 *
 * @param {Array} array
 * @param {Number} index
 *
 * @returns {Array}
 */
export const filterFromIndex = (array, index) => {
  const result = [...array];

  result.splice(index, 1);

  return result;
};
/**
 *
 * @param {Array} array
 * @returns last item from array
 */
export const getLastItemFromArray = array => array.slice(-1)[0];

/**
 * Function to map array to object.
 * for eg array [1,2,4,5]  is mapped to object { 1:1, 2:2, 4:4, 5:5 }
 *
 * @param {Array} array
 * @returns {Object}
 */
export const getObjectFromArray = array => array.reduce((prev, current) => ({ ...prev, [current]: current }), {});

/**
 * Return common elements between two arrays.
 *
 * @param {Array} a
 * @param {Array} b
 */
export function intersect(a, b) {
  return a.filter(Set.prototype.has, new Set(b));
}

/**
 * Returns abbreviations of stringa with spaces excluding passed param
 *
 * @param {Array} array
 * @param {Array} exclude
 */
export const abbreviationsArray = (array, exclude) => {
  return array.map(str => {
    if (str.indexOf(' ') === -1 || exclude.includes(str)) {
      // Check if the string has no spaces or is in the exclude array
      return str;
    } else {
      return str
        .split(' ')
        .map(word => {
          return word.charAt(0).toUpperCase();
        })
        .join('');
    }
  });
};

/**
 * Returns unique elements in an array
 *
 * @param {Array} array
 */
export function getUniqueElements(array) {
  const uniqueElement = new Set(array);

  return [...uniqueElement];
}
