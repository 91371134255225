import React from 'react';

// Components
import StepperHeader from './components/StepperHeader';
import StepperNavigation from './components/StepperNavigation';

const Stepper = props => {
  const { stepperNavigation, onNavigate, currentForm, isCreateForm, employeeName } = props;

  const sizeOfStepper = stepperNavigation.length;

  return (
    <div className="stepper">
      <div className="stepper__nav">
        <StepperHeader isCreateForm={isCreateForm} employeeName={employeeName} sizeOfStepper={sizeOfStepper} />
        <div className="stepper__content">
          {stepperNavigation.map(item => (
            <StepperNavigation key={item.id} item={item} onNavigate={onNavigate} currentForm={currentForm} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
