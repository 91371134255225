import http from '../utils/http';
import { interpolate } from 'utils/string';

import {
  LEAVE_ISSUER_CHANGE_REQUEST,
  LEAVE_ISSUER_CHANGE_REQUESTS_LIST,
  UPDATE_LEAVE_ISSUER_CHANGE_REQUEST,
  BULK_UPDATE_LEAVE_ISSUER_CHANGE_REQUEST,
  LEAVE_ISSUER_CHANGE_REQUESTS_LIST_EXPORT
} from '../constants/endpoints';

/**
 * Fetch all leave issuer change requests.
 *
 * @param {Object} params
 * @returns {Promise}
 */
export async function fetchAll(params) {
  return await http.get(LEAVE_ISSUER_CHANGE_REQUESTS_LIST, { params: { ...params } });
}

/**
 * Gets a list of leave issuer change requests as a CSV.
 *
 * @param {Object} params An object containing the query parameters for the export request.
 * @return {Promise<any>} A promise that resolves with the exported data as a Blob.
 */
export async function exportAll(params) {
  return await http.get(
    LEAVE_ISSUER_CHANGE_REQUESTS_LIST_EXPORT,
    { params: { ...params }, responseType: 'blob' },
    true
  );
}

/**
 * Update status of individual’s leave issuer change request.
 *
 * @param {Number} userId
 * @param {Object} updatedRequest
 * @returns {Promise}
 */
export async function updateLeaveIssuerChangeRequest(userId, updatedRequest) {
  return await http.patch(interpolate(UPDATE_LEAVE_ISSUER_CHANGE_REQUEST, { userId }), {
    body: updatedRequest
  });
}

/**
 * Update status of multiple leave issuer change requests.
 *
 * @param {Object} updatedRequests
 * @returns {Promise}
 */
export async function bulkUpdateLeaveIssuerChangeRequest(body) {
  return await http.post(BULK_UPDATE_LEAVE_ISSUER_CHANGE_REQUEST, {
    body
  });
}

/**
 * Fetch leave issuer change request by user id.
 *
 * @param {number} userId
 * @returns {Promise}
 */
export async function fetchLeaveIssuerChangeRequestByUserId(userId) {
  return await http.get(interpolate(LEAVE_ISSUER_CHANGE_REQUEST, { userId }));
}

/**
 * Create leave issuer change request.
 *
 * @param {number} userId
 * @param {object} data
 * @returns {Promise}
 */
export async function createLeaveIssuerChangeRequest(userId, data) {
  return await http.post(interpolate(LEAVE_ISSUER_CHANGE_REQUEST, { userId }), { body: data });
}
