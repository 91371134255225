import { FiCopy } from 'react-icons/fi';
import React, { useEffect, useState } from 'react';

import PopOver from 'components/common/popover/popover.js';

function CopyButton(props) {
  const { value } = props;
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isCopied) {
      return;
    }

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }, [isCopied]);

  return (
    <div>
      &nbsp;
      <button
        className="copy-btn"
        onClick={() => {
          setIsCopied(true);
          navigator.clipboard.writeText(value);
        }}
      >
        <PopOver
          visible={!isCopied}
          html={
            <div className="tooltip-info word-break">
              <p className="tooltip-info__title">Copy</p>
            </div>
          }
        >
          <PopOver
            visible={isCopied}
            delay={[0, 1500]}
            html={
              <div className="tooltip-info word-break">
                <p className="tooltip-info__title">Copied!</p>
              </div>
            }
          >
            <FiCopy size={12} />
          </PopOver>
        </PopOver>
      </button>
    </div>
  );
}

export default CopyButton;
