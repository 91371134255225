/**
 * Get local storage value for the key.
 *
 * @param {String} key whose value to get.
 * @param {boolean} isObject
 * @param {String} fallback fallback value if key doesn't exist.
 */
export function get(key, isObject, fallback) {
  if (isObject) {
    return JSON.parse(localStorage.getItem(key)) || fallback;
  }

  return localStorage.getItem(key) || fallback;
}

/**
 * Set local storage value for the key.
 *
 * @param {String} key whose Value to set.
 * @param {*} value Value to set.
 */
export function set(key, value) {
  if (value instanceof Object) {
    localStorage.setItem(key, JSON.stringify(value));
    return;
  }

  localStorage.setItem(key, value);
}

/**
 * Remove the key value pair in local storage.
 *
 * @param {string} key
 */
export function remove(key) {
  localStorage.removeItem(key);
}
