import { WORKING_SHIFTS_OPTIONS } from 'constants/worklog';
import { DEFAULT_AVAILABILITY_END_TIME, DEFAULT_AVAILABILITY_START_TIME } from 'constants/appConstants';

import { getFormattedDate } from 'utils/date';

const initialFormData = {
  //Official Information
  firstName: null,
  middleName: null,
  lastName: null,
  empId: '',
  joinDate: getFormattedDate(),
  recruiteeUrl: '',
  username: '',
  departmentId: null,
  leaveIssuerId: null,
  teamManagerId: null,
  scheduledType: null,
  workingShift: WORKING_SHIFTS_OPTIONS[1].value,
  availabilityStartTime: DEFAULT_AVAILABILITY_START_TIME,
  availabilityEndTime: DEFAULT_AVAILABILITY_END_TIME,
  roleIds: [],
  pastExperienceYears: null,
  pastExperienceMonths: null,
  cvUrl: null,

  //Profile Information
  gender: null,
  dateofBirth: null,
  bloodGroup: null,
  maritialStatus: null,
  personalEmail: null,
  mobilePhone: null,
  homePhone: null,
  emergencyPhone: null,
  emergencyContactRelationship: null,
  temporaryAddress: null,
  permanentAddress: null,
  country: null,
  timezone: null,
  githubId: null,
  employeeImage: null,

  //Leapfrog History Information
  designationAreaHistory: [],
  empStatusHistory: [],

  //AppraiserForm Information
  appraiserId: null,
  coAppraiserId: null
};

export default initialFormData;
