import omit from 'lodash';
import React, { useRef } from 'react';

import classnames from 'classnames';
import Creatable from 'react-select/creatable';
import Select, { components } from 'react-select';

const MaterialDropdown = props => {
  const {
    isPlain,
    isSmall,
    isMedium,
    isBig,
    customClassName,
    defaultValue,
    isLoading,
    value,
    name,
    onDropDownChange,
    onInputChange,
    autoResize = false,
    isMulti = false,
    isCreatable = false,
    placeholder,
    customStyles,
    onMenuClose,
    disabled,
    isStatusFilter,
    closeMenuOnSelect
  } = props;

  const selectClass = classnames(
    'lf-select',
    {
      'lf-select--plain': isPlain,
      'lf-select--small': isSmall,
      'lf-select--medium': isMedium,
      'lf-select--big': isBig,
      'lf-select--status': isStatusFilter
    },
    customClassName
  );

  const { ValueContainer, Placeholder } = components;

  const handleFormatCreateLabel = inputValue => {
    if (props.formatCreateLabel) return props.formatCreateLabel;

    return inputValue;
  };

  const SelectComponent = isCreatable ? Creatable : Select;

  const CustomPlaceholderContainer = props => {
    const { children } = props;
    return (
      <>
        <Placeholder {...props}>{props.selectProps.placeholder}</Placeholder>
        <ValueContainer {...props}>
          {React.Children.map(children, child =>
            child && child.type !== Placeholder ? <div title={child.props.children}>{child}</div> : null
          )}
        </ValueContainer>
      </>
    );
  };

  const ref = useRef(null);

  return (
    <div className="custom-dropdown">
      <SelectComponent
        ref={ref}
        name={name ? name : ''}
        placeholder={placeholder || 'Select'}
        isLoading={isLoading}
        defaultValue={defaultValue}
        value={value}
        isDisabled={disabled}
        onChange={onDropDownChange}
        classNamePrefix="material-dropdown-select"
        menuPortalTarget={document.body}
        styles={{
          singleValue: styles => (autoResize ? omit(styles, ['maxWidth']) : { ...styles }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          menu: base => ({
            ...base,
            marginTop: 2,
            minWidth: '80px',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            width: '100%'
          }),
          valueContainer: styles => ({
            ...styles,
            top: '8px',
            marginLeft: '2px'
          }),
          placeholder: styles => ({
            ...styles,
            marginTop: '12px',
            top: '-20px',
            padding: '8px',
            fontWeight: 'normal',
            textAlign: 'left',
            position: 'absolute'
          }),
          option: (styles, { isSelected }) => {
            return {
              ...styles,
              color: isSelected ? '#ffffff' : '#999999'
            };
          },
          control: (styles, { isFocused }) => ({
            ...styles,
            padding: '8px 0px',
            background: isFocused && 'white'
          }),
          ...customStyles
        }}
        menuPosition={'absolute'}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPlacement={'auto'}
        className={selectClass}
        onInputChange={onInputChange}
        isMulti={isMulti}
        components={{ ValueContainer: CustomPlaceholderContainer }}
        formatCreateLabel={handleFormatCreateLabel}
        autoResize={autoResize}
        onMenuClose={() => {
          ref.current.blur();
          onMenuClose && onMenuClose();
        }}
        {...props}
      />
    </div>
  );
};

export default MaterialDropdown;
