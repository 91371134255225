/**
 * Add class name to all the children recursively.
 *
 * @param {HTMLElement} parent
 * @param {string} className
 * @param {conditional} Function
 * @returns
 */
export function addClassToChildren(parent, className, conditional) {
  if (!parent || !(parent instanceof Element)) {
    return;
  }

  if ((conditional && conditional(parent)) || !conditional) {
    if (parent.classList) {
      parent.classList.add(className);
    }
  }

  if (!parent.childElementCount) {
    return;
  }

  parent.childNodes.forEach(el => addClassToChildren(el, className, conditional));
}
