import * as Yup from 'yup';

const designationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z][\sa-zA-Z/]{1,100}$/, 'Invalid Name')
    .required('Please enter designation name'),
  description: Yup.string()
    .matches(/^[a-z0-9A-Z][\sa-zA-Z/]{1,200}$/, 'Invalid Description')
    .nullable()
});

export default designationSchema;
