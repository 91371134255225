import config from 'config';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';

import { MdArrowBack } from 'react-icons/md';

import * as toast from 'utils/toast';
import { isObjectEmpty } from 'utils/object';
import { handleError } from 'utils/errorHandler';

import * as userService from 'services/user';
import * as skillService from 'services/skillService';
import * as skillV2Service from 'services/skillV2Service';

import Modal from 'components/common/modal';
import Empty from 'components/common/empty/Empty';
import ProfileHeader from './components/ProfileHeader';
import Loading from 'components/common/loading/Loading';
import SkillRatingInfo from './components/SkillRatingInfo';
import { SkillPicker } from '../CreateUpdateEmployeeForm/components';

import { REQUEST_NEW_SKILLS } from 'constants/skills';

import { loadSkills } from 'slices/selectedSkillSlice';
import { fetchGlobalRating } from 'slices/globalRatingSlice';

const EmployeeSkills = props => {
  const [skills, setSkills] = useState([]);
  const [employee, setEmployee] = useState({});
  const [userSkills, setUserSkills] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [isSkillsLoading, setSkillsLoading] = useState(true);
  const [isEmployeeLoading, setEmployeeLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [shouldSkillUpdate, setShouldSkillUpdate] = useState(false);

  const { value } = useSelector(store => store.information);

  const selectedSkills = useSelector(state => state.selectedSkills.value);

  const dispatch = useDispatch();

  const fetchEmployee = useCallback(async () => {
    setEmployeeLoading(true);
    try {
      const { id: userId } = props.match.params;

      const employee = await userService.fetchById(userId);

      setEmployee(employee);
    } catch (error) {
      handleError(error);
    } finally {
      setEmployeeLoading(false);
    }
  }, [props.match.params]);

  const fetchSkillsV2 = useCallback(async () => {
    try {
      setSkillsLoading(true);

      const { data } = await skillService.fetchAll();

      setSkills(data);
    } catch (error) {
      handleError(error);
    } finally {
      setSkillsLoading(false);
    }
  }, []);

  const fetchUserSkillsV2 = useCallback(async () => {
    if (!employee.id) {
      return;
    }

    try {
      const { data } = await skillService.fetchByUserId(employee.id);

      dispatch(loadSkills(data));

      setUserSkills(data);
    } catch (error) {
      handleError(error);
    }
  }, [employee]);

  const fetchGlobalRatingScale = useCallback(async () => {
    try {
      setSkillsLoading(true);

      await dispatch(fetchGlobalRating());
    } catch (error) {
      handleError(error);
    } finally {
    }
  }, []);

  useEffect(() => {
    fetchSkillsV2();
  }, [fetchSkillsV2]);

  useEffect(() => {
    fetchGlobalRatingScale();
  }, [fetchGlobalRatingScale]);

  useEffect(() => {
    fetchUserSkillsV2();
  }, [employee, shouldSkillUpdate]);

  useEffect(() => {
    fetchEmployee();
  }, [fetchEmployee]);

  useEffect(() => {
    if (isObjectEmpty(employee)) {
      return;
    }
  }, [employee]);

  useEffect(() => {
    const formattedUserSkills = userSkills.map(skill => ({ id: skill.id, rating: skill.rating }));

    const mappedUserSkill = userSkills.reduce((acc, curr) => {
      acc[curr.id] = curr.rating;

      return acc;
    }, {});

    const haveSameSkills =
      formattedUserSkills.length === selectedSkills.length &&
      selectedSkills.every(skill => mappedUserSkill[skill.id] && skill.rating === mappedUserSkill[skill.id]);

    setIsUpdated(!haveSameSkills);
  }, [userSkills, selectedSkills]);

  const handleUpdate = async () => {
    setSubmitting(true);

    const filteredSkills = selectedSkills.map(skill => ({ id: skill.id, rating: skill.rating }));

    try {
      const payload = { skills: filteredSkills };

      await userService.updateSkillsV2(employee.id, payload);

      toast.success({
        title: 'Success',
        message: 'Skill set updated successfully.'
      });

      setIsUpdated(false); // make the Update button disabled
      setShouldSkillUpdate(prev => !prev);
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const [showSkillRatingInfo, setShowRatingInfo] = useState(false);

  const { history } = props;

  const handleRequestClick = () => {
    window.open(config.newSkillRequestFormURI, '_blank');
  };

  return (
    <div>
      {isSkillsLoading || isEmployeeLoading ? (
        <div className="container loading-container">
          <Loading />
        </div>
      ) : employee.id ? (
        <div>
          <div className="title profile-wrapper">
            <ProfileHeader employee={employee} user={props.user} />
          </div>
          <main className="profile-body-wrapper">
            <div className="container d-flex fd-row flex-fix name-wrap">
              <div className="full-scope-card full-scope-card__header emp-info-container skills-toolbar">
                <button onClick={handleRequestClick} className="btn btn--outlined-grey btn-label f-right">
                  {REQUEST_NEW_SKILLS}
                </button>
                <button
                  onClick={() => {
                    history.goBack();
                  }}
                  disabled={submitting}
                  className="btn btn--outlined-grey btn-label f-left p-5"
                >
                  <MdArrowBack size="18" />
                  <span className="mv-5">Back</span>
                </button>
                <h3 className="employees-title skills-title">Edit Skills</h3>
              </div>
            </div>
            <Modal
              className="edit-skills-modal"
              isOpen={showSkillRatingInfo}
              closeButton={true}
              renderFooter={false}
              onClose={() => {
                setShowRatingInfo(false);
              }}
              title={{ text: 'Frequently Asked Questions' }}
              htmlContent={<SkillRatingInfo />}
            />
            <SkillPicker
              name="skills"
              label={false}
              skills={skills}
              employee={employee}
              showAddSkills={false}
              selectedSkills={selectedSkills}
            />
            <div className="edit-skill-footer">
              <div className="container d-flex fd-row flex-fix name-wrap">
                <div className="full-scope-card full-scope-card__header emp-info-container skills-toolbar">
                  <button
                    onClick={() => {
                      history.goBack();
                    }}
                    disabled={submitting}
                    className="btn btn--outlined-grey btn-label f-left p-5"
                  >
                    <span className="mv-5">Back</span>
                  </button>
                  <button
                    onClick={handleUpdate}
                    disabled={!isUpdated || submitting}
                    className={classNames('btn btn-label btn--curved f-right', {
                      'btn--primary': isUpdated,
                      'btn--disabled': !isUpdated
                    })}
                  >
                    {submitting ? <Loading /> : 'Add Skills'}
                  </button>
                  <button
                    onClick={() => {
                      history.goBack();
                    }}
                    disabled={submitting}
                    className="btn btn--outlined-grey btn-label f-right p-5 mr-10"
                  >
                    <span className="mv-5">Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <main>
          <div className="container">
            <div className="full-scope-card empty-container__wrapper">
              <Empty message="Employee does not exist!" />
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default EmployeeSkills;
