import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FiHelpCircle } from 'react-icons/fi';

import PopOver from 'components/common/popover';
import { CustomCheckbox } from 'components/common/checkbox';

import { handleError } from 'utils/errorHandler';

import { fetchGlobalRating } from 'slices/globalRatingSlice';

import GlobalRatingList from '../skillV2Component/GlobalRatingList';
import GlobalRatingInput from '../skillV2Component/GlobalRatingInput';

function UseGlobalRating({ values, handleBlur, handleChange, errors, setFieldValue }) {
  const dispatch = useDispatch();

  const { value: globalRatings, loading: isLoadingGlobalRating } = useSelector(state => state.globalRatings);

  useEffect(() => {
    if (globalRatings?.length > 0) {
      return;
    }

    getGlobalRating();
  }, [globalRatings]);

  const getGlobalRating = async () => {
    try {
      await dispatch(fetchGlobalRating());
    } catch (error) {
      handleError(error);
    }
  };

  const handleOnChange = () => {
    setFieldValue('isGlobalRatingChecked', !values.isGlobalRatingChecked);
  };

  return (
    <div className="mbpx-16 bg-grey-5 p-1rem">
      <div className="d-flex align-items-start h-24">
        <CustomCheckbox
          onChange={handleOnChange}
          checked={values.isGlobalRatingChecked}
          label="Use global rating for this skill"
          className="plpx-24 color-grey-80 font-size-14"
        />

        <PopOver
          interactive
          trigger="click"
          theme="light"
          className="hello"
          position="right"
          html={
            <div className="w-61x py-8 px-4">
              <p className="font-size-12 text-align-left color-grey-80 mbpx-12">
                By using a global rating system, all the skills will have the same standard for rating as below:
              </p>

              <GlobalRatingList globalRatings={globalRatings} classes={{ label: 'font-size-12' }} />

              <p className="font-size-12 text-align-left color-grey-80 mtpx-12">
                If you wish to have a specific rating standard for this skill, uncheck the option to add local rating.
              </p>
            </div>
          }
        >
          <div className="ml-2x h-24 d-flex align-items-center">
            <FiHelpCircle size={16} color="#2196F3" className="cursor-pointer" />
          </div>
        </PopOver>
      </div>

      {!values.isGlobalRatingChecked && (
        <>
          <p className="color-grey-80 font-size-14 mb-8 mtpx-12">Please set local rating standard for this skill.</p>

          {values.localRating.map(({ rating, description }, index) => {
            return (
              <GlobalRatingInput
                key={index}
                rating={rating}
                name={`localRating[${index}].description`}
                value={description}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.localRating && errors.localRating[index]?.description}
                placeholder={globalRatings[index]?.description || 'Loading...'}
              />
            );
          })}
        </>
      )}
    </div>
  );
}

export default UseGlobalRating;
