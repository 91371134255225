import 'react-tippy/dist/tippy.css';
import classNames from 'classnames';
import { Tooltip } from 'react-tippy';
import React, { useEffect, useRef } from 'react';

const PopOver = props => {
  const ref = useRef();

  const { disableOnScroll, customClass, visible = true } = props;

  const removeTooltipOnScroll = () => window.addEventListener('scroll', () => ref.current?.hideTooltip());

  useEffect(() => {
    if (!disableOnScroll) {
      removeTooltipOnScroll();
    }
    return () => window.removeEventListener('scroll', removeTooltipOnScroll);
  }, [disableOnScroll]);

  return (
    <>
      {visible ? (
        <Tooltip ref={ref} {...props} className={classNames({ [customClass]: customClass })}>
          {props.children}
        </Tooltip>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};

export default PopOver;
