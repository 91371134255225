import React from 'react';
import classNames from 'classnames';
import Loading from '../loading/Loading';

export default function DeleteButton({ onClick, isLoading }) {
  return (
    <button type="button" className="btn btn--danger btn--curved f-right" onClick={onClick}>
      {isLoading && <Loading />}
      <span className={classNames({ invisible: isLoading })}>Delete</span>
    </button>
  );
}
