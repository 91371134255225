import React from 'react';

function Sunset(props) {
  const { size = 16, viewBox = '0 0 18 18', className } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 15H2" stroke="currentColor" strokeWidth="1.27273" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.7266 12.5977H15.9993"
        stroke="currentColor"
        strokeWidth="1.27273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12.5977H3.27273"
        stroke="currentColor"
        strokeWidth="1.27273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.182 12.5977C12.182 11.802 11.8468 11.0389 11.2501 10.4763C10.6534 9.91373 9.84405 9.59766 9.00018 9.59766C8.15631 9.59766 7.347 9.91373 6.75029 10.4763C6.15359 11.0389 5.81836 11.802 5.81836 12.5977"
        stroke="currentColor"
        strokeWidth="1.27273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0469 8.78559L13.9505 7.93359"
        stroke="currentColor"
        strokeWidth="1.27273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.04883 7.93359L4.95246 8.78559"
        stroke="currentColor"
        strokeWidth="1.27273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 7.2V3" stroke="currentColor" strokeWidth="1.27273" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.546 4.80469L9.00053 7.20469L6.45508 4.80469"
        stroke="currentColor"
        strokeWidth="1.27273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Sunset;
