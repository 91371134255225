import React from 'react';
import { UiBuilding, UiHomeAlt } from 'vyaguta-icons/ui';

import Tooltip from 'components/common/tooltip';

import { WORK_LOCATION } from 'constants/worklog';
import { worklogStatusColor } from 'constants/worklog';
import { ATTENDANCE_LOCATION_LABEL } from 'constants/attendanceStatus';

const AttendanceLocationIcon = props => {
  const { status, isPast, location } = props;

  const label = ATTENDANCE_LOCATION_LABEL[location][isPast ? 'past' : 'present'];
  const statusText = `Worklog ${status.toLowerCase()}`;

  const Icon = WORK_LOCATION.OFFICE === location ? UiBuilding : UiHomeAlt;

  return (
    <div className="category-center">
      <span data-for="worklog" className="c-grey-light" data-cy="worklog-location-icon">
        <Tooltip
          html={
            <div className="font-12">
              {label} - {statusText}
            </div>
          }
          className="category-center"
          data-cy="worklog-location-icon-tooltip"
        >
          <Icon size={20} className={worklogStatusColor[status]} data-cy="worklog-location-icon-svg" />
        </Tooltip>
      </span>
    </div>
  );
};

export default AttendanceLocationIcon;
