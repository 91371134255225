import React from 'react';
import classnames from 'classnames';

const PillTab = props => {
  const { tabs, className, isActive, onTabClick } = props;

  let [activeTabIndex, defaultTabIndex] = tabs.reduce((acc, tab, tabIndex) => {
    if (isActive(tab.value)) {
      acc[0] = tabIndex;
    }

    if (tab.isDefaultActive) {
      acc[1] = tabIndex;
    }

    return acc;
  }, []);

  if (activeTabIndex === undefined) {
    activeTabIndex = defaultTabIndex ?? 0;
  }

  return (
    <div className="pill-tabs">
      {tabs.map((tab, index) => {
        return (
          <button
            key={index}
            className={classnames('pill-tabs__btn', {
              [className]: className,
              'pill-tabs__btn--active': index === activeTabIndex
            })}
            onClick={() => onTabClick(tab.value)}
          >
            {tab.label}
          </button>
        );
      })}
    </div>
  );
};

export default PillTab;
