import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from '../../common/Spinner';
import Select, { components } from 'react-select';
import { RiCloseCircleLine } from 'react-icons/ri';
import { MdExpandMore, MdSearch } from 'react-icons/md';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <MdSearch className="height-16 width-16" />
    </components.DropdownIndicator>
  );
};

const ShowSelectedNumber = props => {
  const { isMultiple, values, searchLabel } = props;

  if (!Boolean(values.length)) {
    return (
      <div className="dropdown-search__select-text dropdown-search__select-none text-align-right">{searchLabel}</div>
    );
  }

  if (!isMultiple) {
    return null;
  }

  return (
    <span className="dropdown-search__select-text">
      {values.length}
      {' Selected'}
    </span>
  );
};

class DropDownSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSelectOpen: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  setIsSelectOpen = isSelectOpen => this.setState({ isSelectOpen: isSelectOpen });

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setIsSelectOpen(false);
    }
  };

  handleSelectClick = () => {
    const { isSelectOpen } = this.state;

    this.setIsSelectOpen(!isSelectOpen);
  };

  handleRemove = () => {
    if (this.props.onRemove) {
      this.props.onRemove();
    }
  };

  handleChange = selectedOptions => {
    if (this.props.onChange) {
      this.props.onChange(selectedOptions || []);
    }
  };

  render() {
    const { isSelectOpen } = this.state;

    const {
      title,
      components = { DropdownIndicator },
      values,
      options,
      isMultiple,
      isRemovable,
      showSelected,
      searchLabel,
      noOptionsMessage,
      handleInputChange,
      isLoading,
      className = '',
      iconComponent,
      isDebounceLoading
    } = this.props;

    return (
      <div
        className={classNames('dropdown-search', { 'dropdown-search--active': isSelectOpen })}
        ref={this.setWrapperRef}
      >
        <button
          className={`dropdown-search__control dropdown-search__btn-max d-flex justify-content-between ${className}`}
          onClick={this.handleSelectClick}
        >
          <div className="d-flex flex-column">
            {showSelected && <div className="text-align-left dropdown-search__title">{title}</div>}
            <div className="dropdown-search__control--label d-flex text-align-left">
              {!showSelected && title}
              {showSelected && (
                <ShowSelectedNumber
                  isMultiple={isMultiple}
                  values={values}
                  searchLabel={isLoading ? <Spinner className="dropdown-search__loading" /> : searchLabel}
                />
              )}
              <div
                className={classNames({
                  'dropdown-search__control__active-icon': isSelectOpen,
                  'dropdown-search__control__icon': !isSelectOpen
                })}
              >
                <MdExpandMore alt="Options" />
              </div>
            </div>
          </div>
        </button>

        {isRemovable && (
          <div className="dropdown-search__remove" title="Remove" onClick={this.handleRemove}>
            <RiCloseCircleLine alt="Remove dropdown" />
          </div>
        )}

        {isSelectOpen && (
          <div className="dropdown-search__select">
            <Select
              autoFocus
              isLoading={isDebounceLoading}
              menuIsOpen
              isClearable
              isSearchable
              value={values}
              onInputChange={handleInputChange}
              noOptionsMessage={noOptionsMessage}
              isMulti={isMultiple}
              closeMenuOnSelect={false}
              onChange={this.handleChange}
              components={components}
              placeholder={`Search ${title}...`}
              classNamePrefix="dropdown-search__select"
              className="dropdown-search__select__container"
              options={options}
              formatOptionLabel={iconComponent}
            ></Select>
          </div>
        )}
      </div>
    );
  }
}

DropDownSearch.defaultProps = {
  isMultiple: true,
  isRemovable: false,
  showSelected: false
};

DropDownSearch.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  handleInputChange: PropTypes.any,
  noOptionsMessage: PropTypes.any,
  isMultiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string
    })
  ),
  isRemovable: PropTypes.bool,
  showSelected: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.any)
};

export default DropDownSearch;
