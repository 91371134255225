import React from 'react';

import classNames from 'classnames';

import { RiDraggable } from 'react-icons/ri';
import { MdExpandLess } from 'react-icons/md';
import { FiMoreVertical } from 'react-icons/fi';

import PopOver from 'components/common/popover';

import { TYPE_ATTRIBUTES } from 'constants/linkLibrary';

const SortableHeaderRow = props => {
  const { listData, setListData, attributes, listeners, setActivatorNodeRef, nodeLevel } = props;

  const handleRowClick = () => {
    setListData(prev => {
      return {
        ...prev,
        isExpanded: !prev?.isExpanded
      };
    });
  };

  return (
    <div className="rst__row" onClick={handleRowClick}>
      <div className="rst__row-wrapper">
        <div className={TYPE_ATTRIBUTES[listData.type].headerClass(nodeLevel)}>
          <div ref={setActivatorNodeRef} {...attributes} {...listeners} className="rst__row-draggableIcon">
            <RiDraggable />
          </div>
          <span className={TYPE_ATTRIBUTES[listData.type].titleClass}>{listData.name}</span>
          <div
            className={classNames('rst__dropIcon', {
              'rst__dropIcon--active': !listData?.isExpanded
            })}
          >
            <MdExpandLess alt="Options" />
          </div>
        </div>
        <span
          className="vertical-ellipsis"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <PopOver
            interactive
            theme="light"
            trigger="click"
            size="small"
            position="left-start"
            distance={5}
            disableOnScroll={true}
            popperOptions={{
              modifiers: {
                addZIndex: {
                  enabled: true,
                  order: 810,
                  fn: data => ({
                    ...data,
                    styles: {
                      ...data.styles,
                      zIndex: 10
                    }
                  })
                }
              }
            }}
            html={TYPE_ATTRIBUTES[listData.type].html(listData, handleRowClick)}
            className="vertical-ellipsis__menu dots-menu"
          >
            <FiMoreVertical className="grey--text" size={24} />
          </PopOver>
        </span>
      </div>
    </div>
  );
};

export default SortableHeaderRow;
