import { DATE_FORMAT_DEFAULT } from 'constants/appConstants';

import { getDateRange, getFormattedDate, isWeekend } from './date';

/**
 * Fills in missing dates within a given date range, ensuring that weekend days (Saturday and Sunday)
 * are only included if data exists for them. Weekends with no data are excluded from the result.
 *
 * @param {Array} rosterData - An array of roster data objects, each containing a `workDate` field.
 * @param {string} startDate - The start date of the range (in ISO format or any valid date format).
 * @param {string} endDate - The end date of the range (in ISO format or any valid date format).
 * @returns {Array} - A list of roster data objects, with missing dates filled in as needed.
 *                    The data for each date will be included, with default values for missing data.
 *
 * @example
 * const rosterData = [
 *   { workDate: '2024-11-01', userId: 1, location: 'Office', workShift: 'Morning' },
 *   { workDate: '2024-11-02', userId: 2, location: 'Home', workShift: 'Evening' }
 * ];
 * const startDate = '2024-11-01';
 * const endDate = '2024-11-07';
 * const result = fillMissingDates(rosterData, startDate, endDate);
 * // Returns an array with data for each day from Nov 1 to Nov 7,
 * // excluding weekends if no data exists for those days.
 *
 */
export const fillMissingDates = (rosterData, startDate, endDate) => {
  const dateRange = getDateRange(startDate, endDate);

  const rosterDataMap = new Map(rosterData.map(item => [getFormattedDate(item.workDate, DATE_FORMAT_DEFAULT), item]));

  return dateRange.flatMap(date => {
    const formattedDate = getFormattedDate(date, DATE_FORMAT_DEFAULT);

    // If it's a weekend and no data exists, return an empty array to exclude the date
    if (isWeekend(date) && !rosterDataMap.has(formattedDate)) {
      return [];
    }

    // Otherwise, return the existing data or a default object for the date
    return [
      rosterDataMap.get(formattedDate) || {
        userId: null,
        workDate: formattedDate,
        location: null,
        workShift: null
      }
    ];
  });
};
