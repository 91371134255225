import { Formik } from 'formik';
import classNames from 'classnames';
import { queryClient } from 'index';
import React, { useMemo, useState } from 'react';

import LFModal from 'components/common/LFModal';
import Loading from 'components/common/loading/Loading';
import Input from 'components/common/inputWrapper/components/Input';

import * as toast from 'utils/toast';
import { handleError } from 'utils/errorHandler';

import { addLinkCategorySchema } from 'schemas/addLinkCategorySchema';

import { addQuickLinksCategory, updateQuickLinksCategory } from 'services/quickLinks';

const AddCategory = props => {
  const { selectedCategory, isOpen, onClose, TriggerBtn, isEdit, isSubCategory, handleRowClick } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    name: isEdit ? selectedCategory?.name : ''
  };

  const handleCategory = async values => {
    setIsSubmitting(true);

    const parentId = isSubCategory
      ? isEdit
        ? selectedCategory?.parentId
        : selectedCategory?.id
      : selectedCategory?.parentId;

    const categoryData = {
      name: values.name,
      parentId: parentId
    };

    let data;

    try {
      if (isEdit) {
        data = await updateQuickLinksCategory(selectedCategory.id, categoryData);
      } else {
        data = await addQuickLinksCategory(categoryData);

        if (handleRowClick) {
          handleRowClick();
        }
      }

      toast.success({
        title: 'Success',
        message: data.message
      });

      queryClient.invalidateQueries('quickLinks');
    } catch (error) {
      handleError(error);
    } finally {
      onClose();
      setIsSubmitting(false);
    }
  };

  const getTitle = useMemo(() => {
    if (isEdit) {
      return isSubCategory ? 'Edit sub-category' : 'Edit category';
    } else {
      return isSubCategory ? 'Add new sub-category' : 'Add new category';
    }
  }, [isEdit, isSubCategory]);

  return (
    <LFModal
      TriggerBtn={TriggerBtn}
      isOpen={isOpen}
      onClose={onClose}
      closeButton={true}
      title={{
        text: getTitle,
        type: 'info'
      }}
      className="skill-modal"
      htmlContent={
        <Formik initialValues={initialValues} onSubmit={handleCategory} validationSchema={addLinkCategorySchema}>
          {({ handleSubmit, handleBlur, handleChange, errors, values, dirty, touched }) => {
            return (
              <form onSubmit={handleSubmit} className="skill-modal__form">
                <div className="skill-modal__form-body">
                  <Input
                    isMandatory
                    label={isSubCategory ? 'Sub-category Name' : 'Category Name'}
                    name="name"
                    placeholder={
                      isSubCategory ? 'Write the name of the sub-category' : 'Write the name of the category'
                    }
                    className="wp-100"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                    disabled={isSubmitting}
                    multiline={false}
                    type="text"
                  />
                </div>

                <div className="skill-modal__footer">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={classNames('btn skill-modal__btn', {
                      'btn--primary': !isSubmitting && dirty,
                      'btn--disabled': isSubmitting || !dirty
                    })}
                    disabled={isSubmitting || !dirty}
                  >
                    <span className={classNames({ invisible: isSubmitting })}>
                      {isEdit ? 'Edit & close' : 'Add & close'}
                    </span>
                    {isSubmitting && <Loading />}
                  </button>

                  <button
                    type="button"
                    className={classNames('btn ml-10 skill-modal__btn', {
                      'btn--outlined-grey': !isSubmitting,
                      'btn--disabled': isSubmitting
                    })}
                    onClick={e => {
                      e.preventDefault();
                      onClose();
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      }
    />
  );
};

export default AddCategory;
