import { useEffect, useRef } from 'react';

function useNonInitialEffect(callback, dependencies) {
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      callback();
    }
  }, dependencies);
}

export default useNonInitialEffect;
