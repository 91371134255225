import React from 'react';
import { useSelector } from 'react-redux';
import { Empty } from '@vyaguta/vyaguta-ui';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import NoticeCard from './NoticeCard';

import { lilypad } from 'assets/images';

const NoticeBoard = () => {
  const {
    value: { notices },
    loading: isNoticesLoading
  } = useSelector(state => state.information);

  return (
    <div className="notice-board__list custom-scroll-bar">
      <div className="mtpx-18">
        {isNoticesLoading ? (
          <SkeletonTheme baseColor="#ffffff33" highlightColor="#ffffff66">
            {Array(3)
              .fill(0)
              .map((item, index) => (
                <Skeleton key={index} className="notice-card-loader" />
              ))}
          </SkeletonTheme>
        ) : !notices.length ? (
          <Empty image={lilypad} className="notice-board__empty-list mtpx-40" />
        ) : (
          <>
            {notices.map((noticeItem, index) => (
              <NoticeCard notice={noticeItem} key={index} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default NoticeBoard;
