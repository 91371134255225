import classNames from 'classnames';
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

import { FiChevronRight } from 'react-icons/fi';

import Empty from 'components/common/empty/Empty';

import { SELECT_CATEGORY_ADD, SELECT_CATEGORY_VIEW } from 'constants/skills';

import { getFilterSkills } from 'utils/skillV2';

import AddSkillModal from './AddSkillModal';
import SkillCategoryChild from './SkillCategoryChild';
import AddSkillCategoryModal from './AddSkillCategory';

const SkillSetV2 = props => {
  const {
    skills,
    showAddSkills = true,
    refechSkills,
    selectedSkills,
    searchSkillTree,
    setSelectedSearchSkill,
    isCategoryModalOpen,
    setIsCategoryModalOpen
  } = props;

  const selectedCategoryRef = useRef();

  const [searchTreeState, setSearchTreeState] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [selectedCategoryModalData, setSelectedCategoryModalData] = useState(null);

  const [isSkillModalOpen, setIsSkillModalOpen] = useState(false);
  const [skillData, setSkillData] = useState(null);
  const [skillParentData, setSkillParentData] = useState(null);

  useEffect(() => {
    if (selectedCategory) {
      skills.forEach(skill => {
        if (skill.id === selectedCategory.id) {
          return setSelectedCategory(skill);
        }
      });
    }
  }, [skills]);

  useEffect(() => {
    // If search bar clear search it should unselect category
    if (!searchSkillTree?.length) {
      if (!searchTreeState) {
        return;
      }

      setSelectedCategory(null);
      setSearchTreeState(null);
    }

    const [searchSkill] = searchSkillTree;

    setSelectedCategory(searchSkill);
    setSearchTreeState(searchSkill);
  }, [searchSkillTree]);

  useEffect(() => {
    if (selectedCategoryRef.current) {
      selectedCategoryRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [selectedCategoryRef, selectedCategory]);

  const openCategoryModal = useCallback(() => {
    setIsCategoryModalOpen(true);
  }, [setIsCategoryModalOpen]);

  const closeCategoryModal = () => {
    setIsCategoryModalOpen(false);
    setSelectedCategoryModalData(null);
  };

  const openEditCategoryModal = categoryData => {
    setSelectedCategoryModalData(categoryData);
    openCategoryModal();
  };

  const openSkillModal = () => {
    setIsSkillModalOpen(true);
  };

  const closeSkillModal = () => {
    setIsSkillModalOpen(false);
    setSkillData(null);
  };

  const openAddSkillModal = parentData => {
    setSkillParentData(parentData);
    openSkillModal();
  };

  const openEditSkillModal = (skillData, parentData) => {
    setSkillData(skillData);
    setSkillParentData(parentData);
    openSkillModal();
  };

  const addSubSkill = newParentData => {
    setSkillData(null);
    setSkillParentData(newParentData);
  };

  const handleCategoryClick = category => {
    if (selectedCategory?.id !== category.id) {
      setSelectedCategory(category);
      setSelectedSearchSkill({});
      setSearchTreeState(null);
    }
  };

  const filterSkills = useMemo(() => getFilterSkills(skills, searchSkillTree), [searchSkillTree, skills]);

  return (
    <div className="skill-set-area">
      {isCategoryModalOpen && (
        <AddSkillCategoryModal
          isOpen={isCategoryModalOpen}
          onClose={closeCategoryModal}
          categoryData={selectedCategoryModalData}
          callback={refechSkills}
        />
      )}

      {isSkillModalOpen && (
        <AddSkillModal
          isOpen={isSkillModalOpen}
          skillData={skillData}
          skillParentData={skillParentData}
          onClose={closeSkillModal}
          addSubSkill={addSubSkill}
          callback={refechSkills}
        />
      )}

      <div className="skill-set-area__container">
        <div className="area-list-container">
          {filterSkills?.map(item => {
            const selected = item.id === selectedCategory?.id;

            return (
              <button
                key={item.id}
                onClick={() => handleCategoryClick(item)}
                className={classNames('area-list-container__item', {
                  'area-list-container__item--active': selected
                })}
                ref={selected ? selectedCategoryRef : null}
              >
                <div className="d-flex align-items-center gap-px-8">
                  <div className="name">{item.name}</div>
                  <div className="count">{item.totalSkills}</div>
                </div>
                <div className="mt-5">
                  <FiChevronRight />
                </div>
              </button>
            );
          })}
        </div>

        <div className="skills-list-container">
          {!selectedCategory?.id && (
            <div className="skills-list-container__empty">
              <Empty message={selectedSkills ? SELECT_CATEGORY_ADD : SELECT_CATEGORY_VIEW} />
            </div>
          )}

          {selectedCategory && (
            <SkillCategoryChild
              showAddSkills={showAddSkills}
              openEditCategoryModal={openEditCategoryModal}
              openEditSkillModal={openEditSkillModal}
              openAddSkillModal={openAddSkillModal}
              selectedCategory={selectedCategory}
              selectedSkills={selectedSkills}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SkillSetV2;
