import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';

import history from 'utils/history';
import * as toast from 'utils/toast';
import * as userService from 'services/user';
import { extractFullName } from 'utils/string';
import { handleError } from 'utils/errorHandler';

import useTitle from 'hooks/useTitle';
import Loading from '../../common/loading/Loading';
import TeamManagersHistory from './TeamManagersHistory';
import InputWrapper from 'components/common/inputWrapper';
import DatePicker from 'components/common/datepicker/DatePicker';
import ProfileHeader from '../employees/components/ProfileHeader';
import AvatarComponent from 'components/common/avatar/AvatarComponent';
import useEmployeeDebounceOptions from 'hooks/useEmployeeDebounceOptions';
import DebounceFormDropdown from 'components/common/debounceFormDropdown/DebounceFormDropdown';
import {
  LIST_TYPE,
  NO_USERS_FOUND_TEXT,
  REACT_DATEPICKER_DATE_FORMAT,
  ROLES_IDS,
  TYPE_USER_TEXT,
  USER_FETCH_FIELDS
} from 'constants/appConstants';

const ChangeTeamManager = props => {
  const [employee, setEmployee] = useState({});
  const [loadingEmployee, setLoadingEmployee] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loadingTeamManagers, setLoadingTeamManagers] = useState(false);
  const [id, setId] = useState(props.match.params.id);
  const [teamManagersHistory, setTeamManagersHistory] = useState([]);
  const [teamManagerCode, setTeamManagerCode] = useState('');
  const [selectedTeamManager, setSelectedTeamManager] = useState(null);
  const [transitionDate, setTransitionDate] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const setTitle = useTitle();

  const employeeDebounceOptions = useEmployeeDebounceOptions(null, {
    roleIds: ROLES_IDS.TEAM_MANAGER,
    fields: USER_FETCH_FIELDS.ROLES
  });

  useEffect(() => {
    setTitle('Team Manager');
  }, []);

  const fetchEmployeeById = async () => {
    setLoadingEmployee(true);
    try {
      const data = await userService.fetchById(id);
      window.document.title = getPageTitle(data);

      setEmployee({
        ...data,
        name: extractFullName(data),
        currentTeamManager: data.teamManager ? extractFullName(data.teamManager) : 'None'
      });
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingEmployee(false);
    }
  };

  const fetchTeamManagerHistory = async () => {
    try {
      setLoadingHistory(true);

      const data = await userService.fetchTeamManagerHistory(id);

      setTeamManagersHistory(data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingHistory(false);
    }
  };

  const handleChange = event => {
    const selectedValue = event && event.target && event.target.value;
    const selectedFieldName = event && event.target && event.target.name;

    if (selectedFieldName === 'teamManager') {
      setSelectedTeamManager(selectedValue);
    } else if (selectedFieldName === 'transitionDate') {
      setTransitionDate(selectedValue);
    }

    setErrors({
      ...errors,
      [`${selectedFieldName}`]: false
    });
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchEmployeeById();
    fetchTeamManagerHistory();
  }, [id]);

  const handleSubmit = async () => {
    if (!selectedTeamManager || !transitionDate) {
      return setErrors({
        teamManager: !selectedTeamManager,
        transitionDate: !transitionDate
      });
    }

    try {
      setIsSubmitting(true);

      const teamManager = {
        id: selectedTeamManager,
        transitionDate
      };

      await userService.updateTeamManager(teamManager, id);

      toast.success({
        title: 'Success',
        message: 'Team Manager has been updated successfully.'
      });

      redirectToEmployeeEdit();
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const redirectToEmployeeEdit = () => {
    history.goBack();
  };

  const getPageTitle = employee => {
    const employeeName = employee ? extractFullName(employee) : '';
    const pageTitle = employeeName ? `${employeeName}'s team manager` : 'Team Manager';

    return pageTitle;
  };

  const handleNoOptionsMessage = () => (teamManagerCode.length ? NO_USERS_FOUND_TEXT : TYPE_USER_TEXT);

  const removeOptionsCallback = useCallback(
    options => {
      return options.filter(option => +option.value !== +id);
    },
    [id]
  );

  if (loadingEmployee) {
    return (
      <div className="container loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="title profile-wrapper">
        <ProfileHeader employee={employee} user={props.user} />
      </div>
      <div className="container d-flex fd-row flex-fix name-wrap team-manager-page-container">
        <div className="full-scope-card momr-20 team-manager-form-container">
          <div className="full-scope-card__header table-header name-wrap">
            <div className="d-flex flex-row">
              <h3>Change Team Manager</h3>
            </div>
          </div>
          <div className="team-manager-form">
            <label className="font-label font-14 label-margin">
              <span className="font-weight-bold dark--text"> Name: </span>
              <span className="span-padding-left">{employee.name}</span>
            </label>

            <label className="font-label font-14 label-margin">
              <span className="font-weight-bold dark--text"> Current Team Manager: </span>
              <span className="span-padding-left">{employee.currentTeamManager}</span>
            </label>

            <DebounceFormDropdown
              isMandatory
              name="teamManager"
              label="Team Manager"
              type={LIST_TYPE.EMPLOYEE}
              debounceOptions={employeeDebounceOptions}
              value={selectedTeamManager && selectedTeamManager.value}
              error={errors.teamManager ? 'Please Select a Team Manager' : null}
              handleChange={handleChange}
              handleNoOptionsMessage={handleNoOptionsMessage}
              listCode={teamManagerCode}
              setListCode={setTeamManagerCode}
              loadingList={loadingTeamManagers}
              setLoadingList={setLoadingTeamManagers}
              iconComponent={AvatarComponent}
              isClearable={true}
              removeOptionsCallback={removeOptionsCallback}
            />
            <InputWrapper
              isMandatory={true}
              label="Transition Date"
              error={errors.transitionDate ? 'Please Select a transition date' : null}
            >
              <DatePicker
                date={transitionDate ? moment(transitionDate) : null}
                placeholderText="Pick a Transition Date"
                dateFormat={REACT_DATEPICKER_DATE_FORMAT}
                displayFormat="LL"
                hasError={errors.transitionDate ? 'Please Select a transition date' : null}
                openDirection="up"
                onDateChange={selectedDate =>
                  handleChange({
                    target: {
                      name: 'transitionDate',
                      value: moment(selectedDate).format('YYYY-MM-DD')
                    }
                  })
                }
              />
            </InputWrapper>

            <button
              type="button"
              className="btn btn--outlined-grey f-left card-button mr-10 mb-20"
              onClick={redirectToEmployeeEdit}
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn--primary f-right card-button"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? <Loading /> : 'Update'}
            </button>
          </div>
        </div>
        <TeamManagersHistory teamManagers={teamManagersHistory} />
      </div>
    </>
  );
};

export default ChangeTeamManager;
