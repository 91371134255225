import config from 'config';
import interpolate from 'pinterpolate';

import http from 'utils/http';
import { buildUrl } from 'utils/string';

/**
 * Request api to create the appraiser change request.
 *
 * @param {number} allocationId
 * @param {String} reason
 *
 * @returns {Object}
 */
export async function create(allocationId, reason) {
  const url = interpolate(buildUrl(config.teamsBaseURI, config.endpoints.teams.appraiserChangeRequest), {
    id: allocationId
  });

  const data = await http.post(url, {
    body: { reason }
  });

  return data;
}
