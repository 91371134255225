import React from 'react';

import { FiAlertCircle } from 'react-icons/fi';

const InfoBox = props => {
  const { message } = props;

  return (
    <div className="info-box">
      <div className="info-box__icon-container">
        <FiAlertCircle className="icon" size={16} />
      </div>
      <div>{message}</div>
    </div>
  );
};

export default InfoBox;
