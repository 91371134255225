import React from 'react';
import classNames from 'classnames';

import Loading from 'components/common/loading/Loading';

const MyTeamFilters = ({ label, loading, count, active, onClick, isVisible = true }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={classNames('my-team-list__filters__item', {
        'my-team-list__filters__item--active': active,
        'wp-25': loading
      })}
      onClick={() => {
        !loading && onClick();
      }}
    >
      <div className="my-team-list__filters__item__label">{label}</div>

      <div className="my-team-list__filters__item__count">
        {loading ? (
          <div className="p-relative hpx-20">
            <Loading />
          </div>
        ) : (
          <>{`${count}`}</>
        )}
      </div>
    </div>
  );
};

export default MyTeamFilters;
