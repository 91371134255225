import classnames from 'classnames';
import React, { useState } from 'react';
import { FiCalendar } from 'vyaguta-icons/fi';
import { UiArrowLeft, UiArrowRight } from 'vyaguta-icons/ui';

import { SingleDatePicker } from 'react-dates';
import { ICON_AFTER_POSITION } from 'react-dates/constants';

import { MONTH_MAPPING } from 'constants/date';

import { getCalendarMonthsOption, getCalendarYearsOption, getWeek } from 'utils/date';

import DatePickerDropdown from './DatePickerDropdown';

const SmallDatePicker = props => {
  const { fromEnhancedFilter, isDark, pastYearsCount, futureYearsCount, ...rest } = props;
  const [focused, setFocused] = useState(false);

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    const handleMonthSelect = (month, onMonthSelect, event) => {
      return onMonthSelect(month, event.value);
    };

    const handleYearSelect = (month, onYearSelect, event) => {
      return onYearSelect(month, event.value);
    };

    const monthValue = { label: MONTH_MAPPING[month.month()], value: month.month() };

    const yearValue = { label: month.year(), value: month.year() };

    const onMonthChange = event => handleMonthSelect(month, onMonthSelect, event);

    const onYearChange = event => handleYearSelect(month, onYearSelect, event);

    return (
      <div>
        <div className="d-flex justify-content-center align-items-start gap-px-8">
          {DatePickerDropdown(getCalendarMonthsOption(), monthValue, onMonthChange)}

          {DatePickerDropdown(getCalendarYearsOption(), yearValue, onYearChange)}
        </div>

        <div className="d-flex date-picker-week-header">
          {getWeek().map(item => (
            <p className="title">{item}</p>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classnames('small-date__wrapper', {
        'enhanced-filter__date-picker': fromEnhancedFilter,
        'enhanced-filter__date-picker--dark': isDark
      })}
    >
      <div className="small-date__label">{props.label}</div>
      <SingleDatePicker
        id="date"
        isOutsideRange={() => false}
        placeholder="Select Date"
        focused={focused}
        displayFormat="LL"
        numberOfMonths={1}
        customInputIcon={<FiCalendar size="18px" color="#DADADA" />}
        navPrev={<UiArrowLeft size={20} className="color-grey-80" />}
        navNext={<UiArrowRight size={20} className="color-grey-80" />}
        onFocusChange={({ focused }) => setFocused(focused)}
        inputIconPosition={ICON_AFTER_POSITION}
        hideKeyboardShortcutsPanel={true}
        renderMonthElement={renderMonthElement}
        {...rest}
      />
    </div>
  );
};

export default SmallDatePicker;
