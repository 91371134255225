import * as Yup from 'yup';

export const addCategorySkillSchema = Yup.object({
  name: Yup.string()
    .required('Please provide name')
    .trim()
    .typeError('Please provide name'),

  description: Yup.string()
    .trim()
    .typeError('Please provide description')
});
