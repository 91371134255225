import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

import { handleError } from 'utils/errorHandler';
import { STATUS, CAN_FETCH } from '../constants/redux';

import * as skillService from 'services/skillService';

const initialState = { value: [], loading: false, status: STATUS.IDLE };

const formatDataToUpdate = userSkills => {
  return userSkills.map(skill => ({ id: skill.id, rating: skill.rating, skillStatus: skill.skillStatus }));
};

export const fetchSelectedSkills = createAsyncThunk('selectedSkills/fetchSelectedSkills', async employeeId => {
  try {
    const data = await skillService.fetchByUserId(employeeId);

    return formatDataToUpdate(data.data);
  } catch (error) {
    handleError(error);
  }

  return initialState.value;
});

export const selectedSkillSlice = createSlice({
  name: 'selectedSkills',
  initialState,
  reducers: {
    loadSkills: (state, action) => {
      state.value = action.payload;
    },
    addSelectedSkill: (state, action) => {
      // remove skill if already selected
      const updatedSkills = current(state).value.filter(item => item.id !== action.payload.id);

      // Add the skill with the new rating
      state.value = [...updatedSkills, { id: action.payload.id, rating: action.payload.rating }];
    },
    removeSelectedSkill: (state, action) => {
      state.value = current(state).value.filter(item => item.id !== action.payload);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSelectedSkills.pending, state => {
        if (CAN_FETCH.includes(state.status)) {
          state.loading = true;
          state.status = STATUS.PENDING;
        }

        return state;
      })
      .addCase(fetchSelectedSkills.fulfilled, (state, action) => {
        return {
          ...state,
          value: action.payload,
          loading: false,
          status: STATUS.FULFILLED
        };
      })
      .addCase(fetchSelectedSkills.rejected, state => {
        state.loading = false;
        state.status = STATUS.REJECTED;
      });
  }
});

export default selectedSkillSlice.reducer;
export const { addSelectedSkill, removeSelectedSkill, loadSkills } = selectedSkillSlice.actions;
