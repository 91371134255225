import { UiMoon, UiSun } from 'vyaguta-icons/ui';

import Sunset from 'components/common/sunset';
import Sunrise from 'components/common/sunrise';

export const REJECTED = 'REJECTED';
export const APPROVED = 'APPROVED';
export const PENDING = 'PENDING';
export const SUBMITTED = 'SUBMITTED';
export const CANCELLED = 'CANCELLED';

export const WORKLOG_STATUS = {
  PENDING: PENDING,
  REJECTED: REJECTED,
  APPROVED: APPROVED,
  SUBMITTED: SUBMITTED,
  CANCELLED: CANCELLED
};

export const WORK_LOCATION = {
  OFFICE: 'OFFICE',
  HOME: 'HOME'
};

export const WORK_LOCATION_SHORT = {
  OFFICE: 'WFO',
  HOME: 'WFH'
};

export const worklogStatusColor = {
  APPROVED: 'c-green',
  PENDING: 'c-yellow',
  REJECTED: 'c-red',
  SUBMITTED: 'c-brand-secondary',
  CANCELLED: 'c-grey',
  MISSING: 'c-red'
};

export const WORKING_SHIFTS_FILTER_OPTION = [
  {
    label: 'Morning',
    value: 'Morning Shift'
  },
  {
    label: 'Day',
    value: 'Day Shift'
  },
  {
    label: 'Evening',
    value: 'Evening Shift'
  },
  {
    label: 'Late Evening',
    value: 'Late Evening Shift'
  }
];

export const WORKING_SHIFTS_OPTIONS = [
  {
    label: 'Morning',
    value: 'MORNING_SHIFT'
  },
  {
    label: 'Day',
    value: 'DAY_SHIFT'
  },
  {
    label: 'Evening',
    value: 'EVENING_SHIFT'
  },
  {
    label: 'Late Evening',
    value: 'LATE_EVENING_SHIFT'
  }
];

export const WORKING_SHIFTS = {
  MORNING_SHIFT: 'MORNING_SHIFT',
  DAY_SHIFT: 'DAY_SHIFT',
  EVENING_SHIFT: 'EVENING_SHIFT',
  LATE_EVENING_SHIFT: 'LATE_EVENING_SHIFT'
};

export const workShiftIcon = {
  [WORKING_SHIFTS.MORNING_SHIFT]: <Sunrise size={16} className="color-tertiary-yellow-80" />,
  [WORKING_SHIFTS.DAY_SHIFT]: <UiSun size={16} className="color-tertiary-blue-60" />,
  [WORKING_SHIFTS.EVENING_SHIFT]: <Sunset size={16} className="color-tertiary-orange-80" />,
  [WORKING_SHIFTS.LATE_EVENING_SHIFT]: <UiMoon size={16} className="color-tertiary-purple-80" />
};

export const WORK_SHIFT_LABELS = {
  [WORKING_SHIFTS.MORNING_SHIFT]: 'Morning',
  [WORKING_SHIFTS.DAY_SHIFT]: 'Day',
  [WORKING_SHIFTS.EVENING_SHIFT]: 'Evening',
  [WORKING_SHIFTS.LATE_EVENING_SHIFT]: 'Late Evening'
};

export const AVAILABILITY_TIME_MAPPER = {
  [WORKING_SHIFTS.MORNING_SHIFT]: '05:00 AM - 2:00 PM',
  [WORKING_SHIFTS.DAY_SHIFT]: '9:00 AM - 6:00 PM',
  [WORKING_SHIFTS.EVENING_SHIFT]: '1:00 PM - 10:00 PM',
  [WORKING_SHIFTS.LATE_EVENING_SHIFT]: '3:00 PM - 12:00 AM'
};
