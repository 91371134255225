import React from 'react';

import { underMaintenance } from 'assets/images';
import {
  UNDER_MAINTENANCE_TITLE,
  UNDER_MAINTENANCE_MESSAGE1,
  UNDER_MAINTENANCE_MESSAGE2
} from 'constants/appConstants';

const Maintenance = () => {
  return (
    <div className="fixed-view-height container">
      <div className="card card--elevated mt-3rem flex-column">
        <img src={underMaintenance} height={300} />
        <div className="d-flex flex-column mt-3rem align-center">
          <p className="title">{UNDER_MAINTENANCE_TITLE}</p>
          <p className="message mt-1rem">{UNDER_MAINTENANCE_MESSAGE1}</p>
          <p className="message">{UNDER_MAINTENANCE_MESSAGE2}</p>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
