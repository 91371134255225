import { configureStore } from '@reduxjs/toolkit';

import myRolesSlice from './slices/myRoleSlice';
import rolesReducer from './slices/userRolesSlice';
import userGoalsReducer from './slices/userGoalSlice';
import informationReducer from './slices/informationSlice';
import globalRatingReducer from './slices/globalRatingSlice';
import selectedSkillReducer from './slices/selectedSkillSlice';
import userFeedbacksReducer from './slices/userFeedbackSlice';
import goalFeedbacksReducer from './slices/goalFeedbackSlice';
import selfAttendanceReducer from './slices/selfAttendanceSlice';
import userGoalsSummaryReducer from './slices/userGoalSummarySlice';

const store = configureStore({
  reducer: {
    userFeedbacks: userFeedbacksReducer,
    goalFeedbacks: goalFeedbacksReducer,
    userGoals: userGoalsReducer,
    userGoalsSummary: userGoalsSummaryReducer,
    roles: rolesReducer,
    selfAttendance: selfAttendanceReducer,
    information: informationReducer,
    globalRatings: globalRatingReducer,
    selectedSkills: selectedSkillReducer,
    userRoles: myRolesSlice
  }
});

export default store;
