import React from 'react';

function GlobalRatingInput({ rating, name, value, onBlur, onChange, errors, placeholder }) {
  return (
    <>
      <div className="global-rating-input d-flex">
        <p className="rating">{rating}</p>

        <div className="input-content">
          <input name={name} type="text" value={value} placeholder={placeholder} onBlur={onBlur} onChange={onChange} />
        </div>
      </div>

      {errors && <div className="error-block mb-6 text--right">{errors}</div>}
    </>
  );
}

export default GlobalRatingInput;
