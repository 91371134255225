import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useMemo } from 'react';

import { Avatar } from '@vyaguta/vyaguta-ui';

import config from 'config';
import { pulseLogo } from 'assets/images';
import { FiMoreVertical, FiAlertTriangle } from 'react-icons/fi';
import { UiBuilding, UiExclamationTriangle, UiHomeAlt } from 'vyaguta-icons/ui';

import Table from 'components/common/table';
import Tooltip from 'components/common/tooltip';
import PopOver from 'components/common/popover';
import { CalendarCross } from 'components/common/icons';
import PillStatus from 'components/common/pill/PillStatus';
import TableHeader from 'components/common/table/TableHeader';
import AttendanceLocationIcon from 'components/common/attendance/AttendanceLocationIcon';

import { handleError } from 'utils/errorHandler';
import { getUniqueElements } from 'utils/array';
import { getFormattedDate, isDateInPast, sortByAscendingDate } from 'utils/date';
import { buildUrl, getEmpImageUrl, getFirstAndLastName, interpolate, pluralize } from 'utils/string';

import useScrollDirection from 'hooks/useScrollDirection';

import * as mixpanel from 'services/mixPanel';
import * as teamSummaryService from 'services/teamSummary';

import {
  CHANGE_APPRAISER,
  CHANGE_LEAVE_ISSUER,
  DEFAULT_ALLOCATION_HR,
  HAS_LEAVE_ISSUER_CHANGE_ACCESS
} from 'constants/dashboard';
import { mixPanelEvents } from 'constants/mixPanel';
import { REQUESTED } from 'constants/attendanceStatus';
import { leaveStatus, LEAVE_ISSUER } from 'constants/leave';
import * as hoverStatusTitle from 'constants/attendanceActionStatus';
import { CHANGE_ISSUER_REQUEST, EMPLOYEE_PROFILE } from 'constants/routes';
import { LEAVE_DETAIL_URL, PROJECT_DETAILS_URL } from 'constants/endpoints';
import { APPROVED, PENDING, SUBMITTED, WORK_LOCATION } from 'constants/worklog';
import {
  APPRAISER,
  CO_APPRAISER,
  MY_TEAM_SORT_BY,
  ORDER_BY,
  RELATION_SORT_PRECEDENCE,
  TEAM_MANAGER
} from 'constants/myTeam';
import { ABBREVIATED_DATE_FORMAT, ddd_MMM_DD, SCROLL_DIRECTION } from 'constants/appConstants';

import ChangeAppraiserModal from './ChangeAppraiserModal';
import { AttendanceSummaryItem } from './AttendanceSummaryItem';

const attendanceIcons = {
  wfh: UiHomeAlt,
  wfo: UiBuilding,
  leave: CalendarCross,
  missing: UiExclamationTriangle
};

const excludeSummaryTypes = {
  worklog: ['leave', 'missing'],
  leave: ['wfo', 'wfh', 'missing'],
  missing: ['wfo', 'wfh', 'leave']
};

const iconColorMap = {
  missing: '#f5af00',
  wfo: '#737373',
  wfh: '#737373',
  leave: '#737373'
};

const PlannedLeavesInfo = ({ plannedLeaves }) => {
  const sortedDate = sortByAscendingDate(plannedLeaves, 'leaveStartDate');

  return (
    <div className="planned-leaves-info">
      {sortedDate?.map(date => {
        let formattedDate = '';

        if (date.leaveStartDate === date.leaveEndDate) {
          formattedDate = getFormattedDate(date.leaveStartDate, ABBREVIATED_DATE_FORMAT);
        } else {
          formattedDate =
            getFormattedDate(date.leaveStartDate, ABBREVIATED_DATE_FORMAT) +
            ' - ' +
            getFormattedDate(date.leaveEndDate, ABBREVIATED_DATE_FORMAT);
        }

        const isLeavePending = date.leaveStatus === 'REQUESTED';
        const calendarCrossColor = isLeavePending ? '#ab22ff' : '#B3B3B3';

        const mixPanelEvent = isLeavePending
          ? mixPanelEvents.select.requestedPlannedLeaves
          : mixPanelEvents.select.approvedPlannedLeaves;

        return (
          <div key={date?.leaveDate} className="planned-leaves-info__items">
            <a
              href={interpolate(LEAVE_DETAIL_URL, { id: date.leaveId })}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => mixpanel.trackEvent(mixPanelEvent)}
            >
              <div
                className={classNames('attendance__planned-leaves d-flex align-items-center', {
                  'attendance__pending-color-theme': isLeavePending,
                  'attendance__approved-color-theme': !isLeavePending
                })}
              >
                <span data-cy="attendance-leave-status" className="attendance__leave-status d-flex pr-4">
                  <CalendarCross size={16} color={calendarCrossColor} />
                </span>
                <span className="attendance__data-count">{formattedDate}</span>
              </div>
            </a>
          </div>
        );
      })}
    </div>
  );
};

const TeamsRelationsInfo = ({ relations }) => {
  return (
    <div className="team-relations-info">
      <div className="team-relations-info__title">I am their</div>
      {relations?.map(teamRelation => {
        if (!teamRelation?.project?.name) {
          return null;
        }

        return (
          <div key={teamRelation?.relation} className="team-relations-info__items">
            <div className="team-relations-info">
              <div className="team-relations-info__items--relation">{teamRelation?.relation}</div>
              <a
                href={interpolate(PROJECT_DETAILS_URL, { id: teamRelation?.project?.id })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="team-relations-info__items--project">{teamRelation?.project?.name}</div>
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TeamsAllocationInfo = ({ currentAllocations }) => {
  return (
    <div className="team-allocations-info">
      <div className="team-allocations-info__title">Allocations</div>
      {currentAllocations.map(teamAllocation => {
        return (
          <div key={teamAllocation?.relation} className="team-allocations-info__items">
            {teamAllocation.type === 'project' ? (
              <a
                href={interpolate(PROJECT_DETAILS_URL, { id: teamAllocation?.projectId })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="team-allocations-info__items--name team-allocations-info__items--link">
                  {teamAllocation?.name}
                </div>
              </a>
            ) : (
              <div className="team-allocations-info__items--name">{teamAllocation?.name}</div>
            )}
            <div className="team-allocations-info__items--hour">
              <span className="team-allocations-info__items--pill">
                <PillStatus isActive={true} />
              </span>
              <span className="team-allocations-info__items--hour-count">{teamAllocation?.allocation}h</span>
              <span className="team-allocations-info__items--dot">{` . `}</span>
              <span className="team-allocations-info__items--billing-status">{teamAllocation.billingStatus}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

function categorizeAttendanceList(attendanceList) {
  const categorizedAttendanceList = attendanceList?.reduce(
    (result, attendance) => {
      if (!attendance.worklog && !attendance.leave && !attendance.leaveTypeId && !attendance.location) {
        result.missing.push(attendance);

        return result;
      }

      if (attendance.worklog || attendance.location) {
        const location = attendance.worklog?.location || attendance.location;

        if (location === WORK_LOCATION.OFFICE) {
          result.wfo.push(attendance);

          return result;
        }

        if (location === WORK_LOCATION.HOME) {
          result.wfh.push(attendance);

          return result;
        }
      }

      if (attendance.leave || attendance.leaveTypeId) {
        result.leave.push(attendance);
      }

      return result;
    },
    { missing: [], wfh: [], wfo: [], leave: [] }
  );

  return categorizedAttendanceList;
}

function sortAttendanceList(attendanceList) {
  const attendanceMap = {
    missing: [],
    leaveRequested: [],
    leaveApproved: [],
    wfhPending: [],
    wfhSubmitted: [],
    wfhApproved: [],
    wfoPending: [],
    wfoSubmitted: [],
    wfoApproved: []
  };

  for (const { attendance: todaysAttendance } of attendanceList) {
    const { today: attendance } = todaysAttendance;

    if (!attendance.worklog && !attendance.leave && !attendance.leaveTypeId && !attendance.location) {
      attendanceMap.missing.push({ ...attendance, type: 'missing', icon: attendanceIcons.missing });

      continue;
    }

    // attendance.leave is for the response of calendar service
    // attendance.leaveTypeId is for the response of worklog service
    if (attendance.worklog || attendance.location) {
      const location = attendance.worklog?.location || attendance.location;
      const status = attendance.worklog?.status || attendance.status;

      if (location === WORK_LOCATION.OFFICE) {
        if (status === PENDING) {
          attendanceMap.wfoPending.push({ ...attendance, type: 'wfoPending', icon: attendanceIcons.wfo });

          continue;
        }

        if (status === SUBMITTED) {
          attendanceMap.wfoSubmitted.push({ ...attendance, type: 'wfoSubmitted', icon: attendanceIcons.wfo });

          continue;
        }

        if (status === APPROVED) {
          attendanceMap.wfoApproved.push({ ...attendance, type: 'wfoApproved', icon: attendanceIcons.wfo });

          continue;
        }
      }

      if (location === WORK_LOCATION.HOME) {
        if (status === PENDING) {
          attendanceMap.wfhPending.push({ ...attendance, type: 'wfhPending', icon: attendanceIcons.wfh });

          continue;
        }

        if (status === SUBMITTED) {
          attendanceMap.wfhSubmitted.push({ ...attendance, type: 'wfhSubmitted', icon: attendanceIcons.wfh });

          continue;
        }

        if (status === APPROVED) {
          attendanceMap.wfhApproved.push({ ...attendance, type: 'wfhApproved', icon: attendanceIcons.wfh });

          continue;
        }
      }
    }

    // attendance.leave is for the response of calendar service
    // attendance.leaveTypeId is for the response of leave service
    if (attendance.leave || attendance.leaveTypeId) {
      const status = attendance.leave?.status || attendance.status;

      if (status === REQUESTED) {
        attendanceMap.leaveRequested.push({ ...attendance, type: 'leaveRequested', icon: attendanceIcons.leave });

        continue;
      }

      if (status === APPROVED) {
        attendanceMap.leaveApproved.push({ ...attendance, type: 'leaveApproved', icon: attendanceIcons.leave });

        continue;
      }
    }
  }

  return Object.values(attendanceMap).flat();
}

const updateTeamSummaryData = (teamData, userId) => {
  let showAction = false;

  const updatedTeamSummaryData =
    teamData.map(userAllocationData => {
      const filteredAllocation =
        userAllocationData?.allocation?.currentAllocations?.allocations?.filter(allocation => {
          return allocation.appraiserId === userId;
        }) || [];

      const hasChangeAppraiserAccess = filteredAllocation.length > 0;
      const hasChangeLeaveIssuerAccess = userAllocationData.allocation?.relations?.some(
        ({ relation }) => HAS_LEAVE_ISSUER_CHANGE_ACCESS[relation]
      );

      if (hasChangeAppraiserAccess || hasChangeLeaveIssuerAccess) {
        showAction = true;
      }

      return { ...userAllocationData, filteredAllocation, hasChangeAppraiserAccess, hasChangeLeaveIssuerAccess };
    }) || [];

  return { updatedTeamSummaryData, showAction };
};

const MyTeam = props => {
  const { user } = props;

  const [teamSummaryData, setTeamSummaryData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [teamMemberRelation, setTeamMemberRelation] = useState('');
  const [categorizedTeamData, setCategorizedTeamData] = useState({});

  const [sortBy, setSortBy] = useState(null);
  const [orderBy, setOrderBy] = useState(ORDER_BY.ASC);

  const history = useHistory();

  const handleScrollToBottom = () => mixpanel.trackEvent(mixPanelEvents.scroll.myTeamDashboard.toScrollBottom);
  const handleScrollToDown = () => mixpanel.trackEvent(mixPanelEvents.scroll.myTeamDashboard.toScrollDown);
  const handleScrollUp = () => mixpanel.trackEvent(mixPanelEvents.scroll.myTeamDashboard.toScrollUp);

  useScrollDirection('.my-team__table-container .rt-tbody', SCROLL_DIRECTION.BOTTOM, handleScrollToBottom);
  useScrollDirection('.my-team__table-container .rt-tbody', SCROLL_DIRECTION.DOWN, handleScrollToDown);
  useScrollDirection('.my-team__table-container .rt-tbody', SCROLL_DIRECTION.UP, handleScrollUp);

  const [, , path] = useMemo(() => history.location.pathname.split('/'), [history.location.pathname]);

  const currentDayMonthDate = getFormattedDate(getFormattedDate(), ddd_MMM_DD);

  const sortedTeamData = useMemo(() => {
    switch (sortBy) {
      case MY_TEAM_SORT_BY.PLANNED_LEAVES:
        return teamSummaryData.sort((rowA, rowB) => {
          const aPlannedLeavesCount =
            rowA.attendance?.plannedFutureLeaves?.reduce((total, leave) => total + leave.leaveDays, 0) || 0;

          const bPlannedLeavesCount =
            rowB.attendance?.plannedFutureLeaves?.reduce((total, leave) => total + leave.leaveDays, 0) || 0;

          if (orderBy === ORDER_BY.ASC) {
            return aPlannedLeavesCount - bPlannedLeavesCount;
          } else {
            return bPlannedLeavesCount - aPlannedLeavesCount;
          }
        });

      default:
        return teamSummaryData
          .map(user => {
            user.allocation.relations.sort((a, b) => {
              return RELATION_SORT_PRECEDENCE[a?.relation] - RELATION_SORT_PRECEDENCE[b?.relation];
            });
            return user;
          })
          .sort((a, b) => {
            const aPrecedence = a.allocation.relations
              .map(({ relation }) => RELATION_SORT_PRECEDENCE[relation])
              .sort()[0];
            const bPrecedence = b.allocation.relations
              .map(({ relation }) => RELATION_SORT_PRECEDENCE[relation])
              .sort()[0];

            return aPrecedence - bPrecedence;
          });
    }
  }, [orderBy, sortBy, teamSummaryData]);

  useEffect(() => {
    (async () => {
      try {
        setIsDataLoading(true);

        const payload = {
          includeFutureLeaves: true
        };

        const data = await teamSummaryService.fetchTeamSummary(payload);

        const { updatedTeamSummaryData, showAction } = updateTeamSummaryData(data, user.id);

        const formattedTeamData = sortAttendanceList(updatedTeamSummaryData);

        const categorizedTeamData = categorizeAttendanceList(formattedTeamData);

        setTeamSummaryData(updatedTeamSummaryData);
        setShowAction(showAction);
        setCategorizedTeamData(categorizedTeamData);
      } catch (error) {
        handleError(error);
      } finally {
        setIsDataLoading(false);
      }
    })();
  }, [user.id]);

  const getColumns = () => [
    {
      Header: <TableHeader label="Name" identifier="name" />,
      accessor: 'name',
      width: 250,
      sortable: false,
      Cell: props => {
        return (
          <div className="d-flex align-items-center team-member">
            <div>
              <Avatar
                className="mr-8"
                image={getEmpImageUrl(props.original.user.empId)}
                alt={props.original.user.fullname}
                size="small"
              />
            </div>
            <div className="team-member__info">
              <a
                href={interpolate(EMPLOYEE_PROFILE, { id: props.original.user.id })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="team-member__name">{getFirstAndLastName(props.original.user.fullname)}</div>
              </a>
              <div className="my-team__table-text designation">{props.original.user.designation?.name}</div>
            </div>
            <a
              className="pulse-icon-container"
              href={interpolate(buildUrl(config.pulseBaseUrl, config.endpoints.pulse.userFeed), {
                id: props.original.user.id
              })}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => mixpanel.trackEvent(mixPanelEvents.select.pulse)}
            >
              <img src={pulseLogo} alt="pulse" />
            </a>
          </div>
        );
      }
    },
    {
      Header: <TableHeader label="Attendance" identifier="attendance" />,
      accessor: 'Attendance',
      width: 110,
      sortable: false,
      Cell: props => {
        const { holiday, isWeekend, date, worklog, leave } = props.original?.attendance?.today;

        const isPast = isDateInPast(date);
        const leaveTooltipTitle = `${hoverStatusTitle.ON_LEAVE} - ${leaveStatus[leave?.status]}`;

        if (!!worklog) {
          const { location, status } = worklog;

          return <AttendanceLocationIcon status={status} location={location} isPast={isPast} />;
        }

        if (holiday) {
          return <span data-cy="attendance-holiday"></span>;
        }

        if (isWeekend) {
          return <span data-cy="attendance-weekend"></span>;
        }

        if (!!leave) {
          const isAnyLeavePending = leave.status === REQUESTED;
          const calendarCrossColor = isAnyLeavePending ? '#ab22ff' : '#B3B3B3';

          return (
            <div className="category-center c-red">
              <span data-for="leave" className="c-orange" data-cy="attendance-leave-status">
                <Tooltip
                  id="leave"
                  html={<div className="font-12">{leaveTooltipTitle}</div>}
                  data-cy="attendance-leave-status-tooltip"
                >
                  <CalendarCross size={20} color={calendarCrossColor} />
                </Tooltip>
              </span>
            </div>
          );
        }

        return (
          <div className="category-center">
            <span data-for="missing" className="c-yellow" data-cy="attendance-missing-status">
              <Tooltip
                id="missing"
                html={<div className="font-12">{hoverStatusTitle.ATTENDANCE_PENDING}</div>}
                data-cy="attendance-missing-status-tooltip"
              >
                <FiAlertTriangle size={20} data-cy="attendance-missing-status-icon" />
              </Tooltip>
            </span>
          </div>
        );
      },
      maxWidth: 120,
      minWidth: 120
    },
    {
      Header: <TableHeader label="I am their" identifier="relation" />,
      accessor: 'relation',
      width: 220,
      sortable: false,
      Cell: props => {
        const { relations } = props.original.allocation;
        const relationsData = _.uniq(relations?.map(({ relation }) => relation));

        const isEveryProjectNull = relations.every(({ project }) => !project);

        return (
          <PopOver
            interactive
            trigger="click"
            visible={!isEveryProjectNull}
            theme="light"
            html={<TeamsRelationsInfo relations={relations} />}
          >
            <div className="relations my-team__table-text cursor-pointer">{relationsData.join(', ')}</div>
          </PopOver>
        );
      }
    },
    {
      Header: <TableHeader label="Allocations" identifier="allocation" />,
      accessor: 'allocations',
      width: 295,
      sortable: false,
      Cell: props => {
        const allocationData = props.original.allocation;

        const allocations = allocationData.currentAllocations.allocations?.map(allocation => {
          return allocation?.name;
        });

        return (
          <PopOver
            interactive
            trigger="click"
            theme="light"
            html={
              <TeamsAllocationInfo
                currentAllocations={_.uniqBy(
                  allocationData.currentAllocations?.allocations,
                  allocation => allocation.name + allocation.type
                )}
              />
            }
          >
            <div className="my-team__table-text cursor-pointer">
              {allocations?.length ? (
                <div className="allocations">
                  <div
                    className={classNames(`allocations__duration`, {
                      'c-red':
                        props.original.allocation.currentAllocations.totalAllocationHours > DEFAULT_ALLOCATION_HR,
                      'c-blue':
                        props.original.allocation.currentAllocations.totalAllocationHours < DEFAULT_ALLOCATION_HR
                    })}
                  >
                    {props.original.allocation.currentAllocations.totalAllocationHours}h
                  </div>
                  <div className="allocations__project-area"> {getUniqueElements(allocations)?.join(', ')}</div>
                </div>
              ) : (
                '-'
              )}
            </div>
          </PopOver>
        );
      }
    },
    {
      Header: (
        <TableHeader
          label="Planned Leave"
          identifier="plannedLeaves"
          columnToSortBy={sortBy}
          isAscendingOrdered={orderBy === ORDER_BY.ASC}
          onClick={() => {
            if (sortBy === MY_TEAM_SORT_BY.PLANNED_LEAVES) {
              setOrderBy(orderBy === ORDER_BY.ASC ? ORDER_BY.DESC : ORDER_BY.ASC);
            } else {
              setOrderBy(ORDER_BY.DESC);
            }
            setSortBy(MY_TEAM_SORT_BY.PLANNED_LEAVES);
          }}
        />
      ),
      accessor: MY_TEAM_SORT_BY.PLANNED_LEAVES,
      width: 145,
      sortable: false,
      Cell: props => {
        const plannedLeavesCount = props.original.attendance?.plannedFutureLeaves?.reduce(
          (total, leave) => total + leave.leaveDays,
          0
        );

        const isAnyLeavePending = props.original.attendance.plannedFutureLeaves?.some(
          leave => leave.leaveStatus === 'REQUESTED'
        );

        const calendarCrossColor = isAnyLeavePending ? '#ab22ff' : '#B3B3B3';

        return (
          <div className="my-team__table-text">
            <PopOver
              interactive
              trigger="click"
              theme="light"
              html={<PlannedLeavesInfo plannedLeaves={props.original.attendance.plannedFutureLeaves} />}
            >
              {plannedLeavesCount ? (
                <div
                  className={classNames('attendance__planned-leaves d-flex align-items-center', {
                    'attendance__pending-color-theme': isAnyLeavePending,
                    'attendance__approved-color-theme': !isAnyLeavePending
                  })}
                  onClick={() => mixpanel.trackEvent(mixPanelEvents.view.plannedLeaves)}
                >
                  <span className="attendance__data-count">{plannedLeavesCount}</span>
                  <span className="attendance__leave-status d-flex" data-cy="attendance-leave-status">
                    <CalendarCross size={12} color={calendarCrossColor} />
                  </span>
                </div>
              ) : (
                ''
              )}
            </PopOver>
          </div>
        );
      }
    },
    {
      Header: <TableHeader label="Pending/Missing" identifier="missing" />,
      accessor: 'totalMissingCount',
      width: 150,
      sortable: false,
      Cell: props => {
        const missingOrPendingCount = props.original.attendance.totalMissingCount;

        return (
          <div className="my-team__table-text">
            <span className="attendance__data-count">{missingOrPendingCount || '-'}</span>
          </div>
        );
      }
    },
    ...(showAction
      ? [
          {
            Header: <TableHeader label="" identifier="action" />,
            width: 80,
            sortable: false,
            filterable: false,
            className: 'cursor-default',
            Cell: props => {
              const userAllocationData = props.original;

              const { filteredAllocation, hasChangeAppraiserAccess, hasChangeLeaveIssuerAccess } = userAllocationData;

              const [isModalOpen, setIsModalOpen] = useState(false);

              const hiddenBtn = () => {
                return <div style={{ display: 'hidden' }}></div>;
              };

              if (!hasChangeAppraiserAccess && !hasChangeLeaveIssuerAccess) {
                return '';
              }

              return (
                <div className="mr-10">
                  <ChangeAppraiserModal
                    TriggerBtn={hiddenBtn}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    filteredAllocation={filteredAllocation}
                  />

                  <PopOver
                    interactive
                    hideOnClick
                    position="left"
                    trigger="click"
                    theme="custom-light"
                    color="white"
                    popperOptions={{
                      modifiers: {
                        addZIndex: {
                          enabled: true,
                          order: 810,
                          fn: data => ({
                            ...data,
                            styles: {
                              ...data.styles,
                              zIndex: 10
                            }
                          })
                        }
                      }
                    }}
                    customClass="my-team-action"
                    html={
                      <div className="d-flex flex-column my-team-menu">
                        {hasChangeLeaveIssuerAccess && (
                          <a
                            className="my-team-menu__item"
                            href={interpolate(CHANGE_ISSUER_REQUEST, {
                              id: props.original.user.id
                            })}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                              mixpanel.trackEvent(
                                mixPanelEvents.select.leaveIssuerChangeRequest.linkFromMyTeamDashboard
                              );
                            }}
                          >
                            <span>{CHANGE_LEAVE_ISSUER}</span>
                          </a>
                        )}
                        {hasChangeAppraiserAccess && (
                          <button
                            className="my-team-menu__item"
                            aria-hidden="true"
                            onClick={() => {
                              setIsModalOpen(true);
                              mixpanel.trackEvent(mixPanelEvents.select.appraiserChangeRequest.linkFromMyTeamDashboard);
                            }}
                          >
                            {CHANGE_APPRAISER}
                          </button>
                        )}
                      </div>
                    }
                  >
                    <button type="button" className="btn action-btn">
                      <FiMoreVertical />
                    </button>
                  </PopOver>
                </div>
              );
            }
          }
        ]
      : [])
  ];

  /**
   * Function to map and serialize the relation of team members in text.
   *
   * @example [{allocation: {relations:[relation: 'Team Manager']}}]
   * sets into the relationCount object text.
   */
  const getAndSerializeRelationsOfTeamMembers = () => {
    let relationCount = {
      LI: 0,
      TM: 0,
      Appraiser: 0,
      'Co-Appraiser': 0
    };

    const uniqueAppraiserRelations = new Set();
    const uniqueCoAppraiserRelations = new Set();

    teamSummaryData.forEach(team => {
      team.allocation.relations.forEach(relation => {
        if (relation.relation === LEAVE_ISSUER) {
          relationCount = {
            ...relationCount,
            LI: relationCount.LI + 1
          };
        }

        if (relation.relation === TEAM_MANAGER) {
          relationCount = {
            ...relationCount,
            TM: relationCount.TM + 1
          };
        }

        if (relation.relation === APPRAISER) {
          uniqueAppraiserRelations.add(team.user.id);
        }

        if (relation.relation === CO_APPRAISER) {
          uniqueCoAppraiserRelations.add(team.user.id);
        }
      });

      relationCount = {
        ...relationCount,
        Appraiser: uniqueAppraiserRelations.size,
        'Co-Appraiser': uniqueCoAppraiserRelations.size
      };

      const filteredEntries = Object.entries(relationCount)
        .filter(([_, value]) => value !== 0)
        .map(([key, value]) => `${key}: ${value}`);

      const result = filteredEntries.join(', ');

      setTeamMemberRelation(result);
    });
  };

  useEffect(() => {
    getAndSerializeRelationsOfTeamMembers();
  }, [teamSummaryData]);

  /**
   * Renders the team relation and popover in my team.
   *
   * @returns {ReactElement}
   */
  const renderTeamRelationAndPopOver = () => {
    /**
     * Function to take relation of a team member and serialize a string for popover.
     *
     * @param {string} relation
     * @returns {string}
     */
    const handlePopOverText = relation => {
      const [key, value] = relation.split(':');

      const popoverTextMap = {
        LI: `${LEAVE_ISSUER} of ${value} ${pluralize('Member', value)}`,
        TM: `${TEAM_MANAGER} of ${value} ${pluralize('Member', value)}`,
        [APPRAISER]: `${APPRAISER} of ${value} ${pluralize('Member', value)}`,
        [CO_APPRAISER]: `${CO_APPRAISER} of ${value} ${pluralize('Member', value)}`
      };

      return popoverTextMap[key] || '';
    };

    return (
      <>
        {'('}
        {teamMemberRelation.split(', ').map((relation, index, array) => (
          <PopOver
            visible={handlePopOverText(relation)}
            interactive
            html={
              <div className="tooltip-info">
                <p className="tooltip-info__title">{handlePopOverText(relation)}</p>
              </div>
            }
          >
            <span>
              {relation}
              {index !== array.length - 1 && ', '}
            </span>
          </PopOver>
        ))}
        {')'}
      </>
    );
  };

  return (
    <div className="my-team__table-container custom-scroll-bar">
      <div className="full-scope-card__header table-header name-wrap">
        <div className="my-team__header d-flex align-items-center justify-content-between f-wrap gap-y-8">
          <div className="table-title-left d-flex">
            <div className="table-title">My Team</div>
            <div className="table-sub-title">
              {teamSummaryData.length} {pluralize('Member', teamSummaryData.length)}{' '}
              {teamMemberRelation && renderTeamRelationAndPopOver()}
            </div>
          </div>
          <div className="table-title-right r-right">
            <div className="my-team__sub-header d-flex align-items-center">
              <div className="badge__label mrpx-12">
                <span className="today">Today . </span>
                <span>{currentDayMonthDate}</span>
              </div>

              <div className="attendance-summary mrpx-8">
                {Object.keys(categorizedTeamData).map((item, index) => (
                  <PopOver
                    key={index}
                    interactive
                    html={
                      <div className="tooltip-info">
                        <p className="tooltip-info__title">
                          {['wfh', 'wfo'].includes(item) ? _.toUpper(item) : _.capitalize(item)}
                        </p>
                      </div>
                    }
                  >
                    <AttendanceSummaryItem
                      key={index}
                      icon={attendanceIcons[item]}
                      iconColor={iconColorMap[item]}
                      size={16}
                      isVisible={!excludeSummaryTypes?.[path]?.includes(item)}
                      count={categorizedTeamData[item].length}
                    />
                  </PopOver>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Table
        loading={isDataLoading}
        columns={getColumns()}
        data={sortedTeamData}
        showPagination={false}
        customClassName="rt-cursor-default"
      />
    </div>
  );
};

MyTeam.propTypes = {
  user: PropTypes.object.isRequired
};

export default MyTeam;
