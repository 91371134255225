import React, { useEffect, useState } from 'react';

import * as rolesService from 'services/role';
import * as permissionsService from 'services/permission';

import { handleError } from 'utils/errorHandler';
import { RolesPermissionsTable } from './components';
import Loading from 'components/common/loading/Loading';

const RolesPermissions = () => {
  window.document.title = 'Roles Permissions | Vyaguta';

  const [isLoading, setIsLoading] = useState({
    roles: false,
    permissions: false,
    rolesPermissions: false
  });
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [rolesPermissions, setRolesPermissions] = useState([]);

  const fetchRoles = async () => {
    try {
      setIsLoading(previousState => ({
        ...previousState,
        roles: true
      }));
      const roles = await rolesService.fetchAll();

      setRoles(roles);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(previousState => ({
        ...previousState,
        roles: false
      }));
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchPermisssions = async () => {
    try {
      setIsLoading(previousState => ({
        ...previousState,
        permissions: true
      }));
      const { data: permissions } = await permissionsService.fetchAll();

      setPermissions(permissions);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(previousState => ({
        ...previousState,
        permissions: false
      }));
    }
  };

  useEffect(() => {
    fetchPermisssions();
  }, []);

  const fetchRolesPermissions = async () => {
    try {
      setIsLoading(previousState => ({
        ...previousState,
        rolesPermissions: true
      }));
      const { data: rolesPermissions } = await rolesService.fetchRolesPermissions();

      setRolesPermissions(rolesPermissions);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(previousState => ({
        ...previousState,
        rolesPermissions: false
      }));
    }
  };

  useEffect(() => {
    fetchRolesPermissions();
  }, []);

  const {
    roles: isRolesLoading,
    permissions: isPermissionsLoading,
    rolesPermissions: isRolesPermissionsLoading
  } = isLoading;

  return (
    <main>
      <div className="container">
        <div className="full-scope-card">
          <div className="full-scope-card__header table-header name-wrap">
            <div className="d-flex flex-row">
              <h3 className="table-title">Roles Permissions</h3>
            </div>
          </div>

          <div className="full-scope-card__content">
            <div className="roles-permissions-wrapper">
              {isPermissionsLoading || isRolesLoading || isRolesPermissionsLoading ? (
                <Loading className="mt-25" />
              ) : (
                <RolesPermissionsTable roles={roles} permissions={permissions} rolesPermissions={rolesPermissions} />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RolesPermissions;
