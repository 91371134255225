import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router as BrowserRouter, Switch } from 'react-router-dom';

import config from 'config';

import history from 'utils/history';

import { fetchMyRole } from 'slices/myRoleSlice';
import { fetchUserInfo } from 'slices/informationSlice';

import Home from './home';
import { UserContext } from './App';

import { HOME } from '../constants/routes';

// Top level application router.
const Router = () => {
  const dispatch = useDispatch();
  const { value, loading } = useSelector(state => state.userRoles);

  const { isMaintenanceMode } = config;

  const user = useContext(UserContext);

  useEffect(() => {
    if (isMaintenanceMode) {
      return;
    }

    dispatch(fetchUserInfo());
  }, [dispatch, isMaintenanceMode]);

  useEffect(() => {
    if (!user.id) {
      return;
    }

    dispatch(fetchMyRole(+user.id));
  }, [dispatch, user]);

  return (
    <BrowserRouter history={history}>
      <Switch>
        <Route path={HOME} render={props => <Home userRoles={value} isUserRoleLoading={loading} />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
