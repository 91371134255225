import React, { useCallback, useEffect, useState } from 'react';

import useTitle from 'hooks/useTitle';
import DesignationForm from './DesignationForm';
import Loading from 'components/common/loading/Loading';
import BackButton from 'components/common/button/BackButton';

import * as designationService from 'services/designation';

import { DESIGNATION } from 'constants/routes';

import history from 'utils/history';
import { handleError } from 'utils/errorHandler';

const AddEditDesignation = props => {
  const designationId = props.match.params?.id;

  const title = designationId ? 'Edit Designation' : 'Add Designation';

  const setTitle = useTitle();

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  const [isLoading, setIsLoading] = useState(false);
  const [designation, setDesignation] = useState(null);

  const getPageTitle = designation => {
    if (!designation.name) {
      return 'Edit Designation';
    }

    return `Edit Designation - ${designation.name}`;
  };

  const fetchDesignation = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await designationService.fetchById(designationId);

      const pageTitle = getPageTitle(data);

      setTitle(pageTitle);

      const { id, name, description, status } = data;

      setDesignation({ id, name, description, status });
    } catch (error) {
      handleError(error);
      history.push(DESIGNATION);
    } finally {
      setIsLoading(false);
    }
  }, [designationId]);

  useEffect(() => {
    if (!designationId) {
      return;
    }

    fetchDesignation();
  }, [designationId, fetchDesignation]);

  return (
    <div className="full-scope-card">
      <div className="full-scope-card__header table-header name-wrap">
        <div className="d-flex flex-row">
          <BackButton />
          <div className="d-flex flex-row">
            <div className="table-header">
              <h3 className="text-bold">{title}</h3>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="loading-min-height">
          <Loading />
        </div>
      ) : (
        <DesignationForm designation={designation} {...props} />
      )}
    </div>
  );
};

export default AddEditDesignation;
