import config from 'config';
import interpolate from 'pinterpolate';

import http from 'utils/http';
import { buildUrl } from 'utils/string';

import { GLOBAL_RATINGS, SKILL_URL, SKILL_URL_ID, SKILL_V2_CATEGORY_URL } from '../constants/endpoints';

/**
 * Request api to create the skill category.
 *
 * @param {Object} body
 *
 * @returns {Object}
 */
export async function createCategory(body) {
  const url = interpolate(buildUrl(config.coreBaseURI, config.endpoints.skillV2Categories));

  const data = await http.post(url, {
    body
  });

  return data;
}

/**
 * Request api to update the skill category.
 *
 * @param {number} categoryId
 * @param {Object} body
 *
 * @returns {Object}
 */
export async function updateCategory(categoryId, body) {
  const url = interpolate(buildUrl(config.coreBaseURI, config.endpoints.skillV2Category), {
    id: categoryId
  });

  const data = await http.put(url, {
    body
  });

  return data;
}

/**
 * Request api to create the skill.
 *
 * @param {Object} body
 *
 * @returns {Object}
 */
export async function create(body) {
  const url = interpolate(buildUrl(config.coreBaseURI, config.endpoints.skillsV2));

  const data = await http.post(url, {
    body
  });

  return data;
}

/**
 * Request api to update the skill by id.
 *
 * @param {number} skillId
 * @param {Object} body
 *
 * @returns {Object}
 */
export async function update(skillId, body) {
  const url = interpolate(buildUrl(config.coreBaseURI, config.endpoints.skillV2), {
    id: skillId
  });

  const data = await http.put(url, {
    body
  });

  return data;
}

export async function fetchGlobalRating() {
  const data = await http.get(GLOBAL_RATINGS);

  return data;
}

export async function updateGlobalRating(skill) {
  return await http.put(GLOBAL_RATINGS, {
    body: skill
  });
}

export async function fetchEndLevelSkills() {
  const data = await http.get(SKILL_URL, { params: { type: 'endSkills' } });

  return data;
}

export async function deleteSkill(id) {
  const data = await http.delete(interpolate(SKILL_URL_ID, { id }));

  return data;
}

export async function deleteCategory(id) {
  const data = await http.delete(interpolate(SKILL_V2_CATEGORY_URL, { id }));

  return data;
}
