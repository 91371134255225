import * as toast from 'utils/toast';
import { NO_INTERNET_CONNECTION_ERROR_MESSAGE } from '../constants/appConstants';

/**
 * Checks internet connection and return error message if user is offline.
 *
 * @param {function} callback
 *
 * @returns {function}
 */
export const handleUserOfflineMode = (callback = () => true) => {
  const isOnline = window.navigator.onLine;

  if (!isOnline) {
    return toast.error({
      title: 'Error',
      message: NO_INTERNET_CONNECTION_ERROR_MESSAGE
    });
  }

  return callback();
};
