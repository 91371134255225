import React, { useEffect, useState } from 'react';

import NoteForm from './NoteForm';
import history from 'utils/history';
import useTitle from 'hooks/useTitle';
import * as noteService from 'services/note';
import { handleError } from 'utils/errorHandler';
import Loading from 'components/common/loading/Loading';
import BackButton from 'components/common/button/BackButton';

const CreateEditNote = props => {
  const [isLoading, setIsLoading] = useState(false);
  const noteId = props.match.params && props.match.params.noteId;
  const title = noteId ? 'Edit Note' : 'Add Note';
  const [note, setNote] = useState(null);

  useTitle(title);

  const fetchNote = async () => {
    try {
      setIsLoading(true);

      const { data } = await noteService.fetchNoteById(noteId);

      setNote(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!noteId) {
      return;
    }

    fetchNote();
  }, [noteId]);

  return (
    <main>
      <div className="container">
        <div className="full-scope-card">
          <div className="full-scope-card__header table-header name-wrap">
            <div className="d-flex flex-row">
              <BackButton />
              <div className="d-flex flex-row">
                <div className="table-header">
                  <span className="table-header__title">{title}</span>
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="loading-min-height">
              <Loading />
            </div>
          ) : (
            <NoteForm location={props.location} note={note} />
          )}
        </div>
      </div>
    </main>
  );
};

export default CreateEditNote;
