import React, { useEffect, useMemo, useState } from 'react';

import Select from 'react-select';

import { formatTime, getTime } from 'utils/string';
import { generateReactSelectOptions } from 'utils/reactSelect';

const TIME_OPTIONS = ['AM', 'PM'];

function TimeInput(props) {
  const { placeholder, type, time, error, onChange, onBlur, label, name, ...rest } = props;

  const [timeType, setTimeType] = useState(time.type);
  const [timeValue, setTimeValue] = useState(time.value);
  const [inputValue, setInputValue] = useState(formatTime(time.value, time.type));

  const formattedTime = useMemo(() => formatTime(time.value, timeType), [time.value, timeType]);

  const handleTimeUpdate = formattedTime => {
    const time = getTime(formattedTime);
    setTimeType(time.type);
    setTimeValue(time.value);
    setInputValue(formattedTime);
    onChange({ target: { name, value: { value: time.value, type: time.type } } });
  };

  const handleTimeChange = () => {
    const formattedTime = formatTime(inputValue, timeType);
    handleTimeUpdate(formattedTime);
  };

  useEffect(() => {
    const formattedTime = formatTime(time.value, time.type);
    handleTimeUpdate(formattedTime);
  }, [time, timeType]);

  useEffect(() => {
    handleTimeChange();
  }, [timeType]);

  const handleBlur = e => {
    onBlur(e);
    handleTimeChange();
  };

  return (
    <div className="time-input">
      <input
        className="time-input__text"
        type="text"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onBlur={handleBlur}
        {...rest}
      />
      <Select
        isDisabled={rest.disabled}
        classNamePrefix="time-select"
        options={generateReactSelectOptions(TIME_OPTIONS)}
        onChange={options => {
          setTimeType(options.value);
        }}
        onBlur={handleBlur}
      />
    </div>
  );
}

export default TimeInput;
