import React from 'react';
import classNames from 'classnames';

import PopOver from 'components/common/popover';

import { FiFlag, FiTrash2 } from 'react-icons/fi';

const FlaggedSkill = props => {
  const { count, type } = props;

  const isFlag = type === 'flag';
  const isDelete = type === 'delete';

  return (
    <PopOver
      theme="light"
      html={
        <div>
          {isFlag && 'Flagged'}
          {isDelete && 'Recommended for removal'}
        </div>
      }
    >
      <div className={classNames({ 'flagged-skill': type === 'flag', 'delete-skill': type === 'delete' })}>
        {isFlag && <FiFlag size={14} />}
        {isDelete && <FiTrash2 size={14} />}

        {!!count && (
          <div>
            <span>{count}</span>
          </div>
        )}
      </div>
    </PopOver>
  );
};

export default FlaggedSkill;
