import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchInfo } from '../services/me';
import { handleError } from '../utils/errorHandler';
import { getObjectFromArray } from '../utils/array';
import { STATUS, CAN_FETCH } from '../constants/redux';

const initialState = {
  value: {
    permissions: {},
    user: {},
    reminders: [],
    notices: []
  },
  loading: false,
  status: STATUS.IDLE
};

export const fetchUserInfo = createAsyncThunk('information/fetchUserInfo', async () => {
  try {
    const data = await fetchInfo();

    return data;
  } catch (error) {
    handleError(error);
  }

  return initialState.value;
});

export const informationSlice = createSlice({
  name: 'information',
  initialState,
  reducers: {
    updateNotices: (state, action) => {
      state.value.notices = state.value.notices
        .filter(notice => notice.id !== action.payload.id)
        .concat(action.payload);
    },
    deleteNotices: (state, action) => {
      state.value.notices = state.value.notices.filter(notice => notice.id !== action.payload) || [];
    },
    updateReminders: (state, action) => {
      state.value.reminders = state.value.reminders
        .filter(reminder => reminder.id !== action.payload.id)
        .concat(action.payload);
    },
    deleteReminders: (state, action) => {
      state.value.reminders = state.value.reminders.filter(reminder => reminder.id !== action.payload) || [];
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUserInfo.pending, state => {
        if (CAN_FETCH.includes(state.status)) {
          return { ...state, loading: true, status: STATUS.PENDING };
        }

        return state;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        const {
          permissions: { permissions },
          user,
          notices,
          reminders
        } = action.payload;

        return {
          ...state,
          value: {
            ...state.value,
            permissions: permissions ? getObjectFromArray(permissions) : {},
            user,
            notices,
            reminders
          },
          loading: false,
          status: STATUS.FULFILLED
        };
      })
      .addCase(fetchUserInfo.rejected, state => {
        return { ...state, loading: false, status: STATUS.REJECTED };
      });
  }
});

export default informationSlice.reducer;
export const { updateNotices, deleteNotices, updateReminders, deleteReminders } = informationSlice.actions;
