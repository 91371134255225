import { useQuery } from 'react-query';

import { FISCAL_YEAR_QUERY_KEYS } from './keys';

import * as fiscalYearService from 'services/fiscalYear';

export function useFetchFiscalYearByCountry(country, shouldFetch = true) {
  return useQuery({
    queryKey: [FISCAL_YEAR_QUERY_KEYS.BY_COUNTRY, country],
    queryFn: () => fiscalYearService.fetch({ country }),
    enabled: !!country && shouldFetch
  });
}
