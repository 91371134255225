import lodash from 'lodash';
import React, { useMemo, useEffect } from 'react';

// Icons
import { FiPlusCircle } from 'vyaguta-icons/fi';
import { FiInfo } from 'react-icons/fi';

// Components
import DesignationList from './components/DesignationList';
import EngagementStatusList from './components/EngagementStatusList';
import { EMPLOYEE_HISTORY_TYPES, ERROR, COMPLETE, LEAPFROG_HISTORY_FORM_INPUTS } from 'constants/createEmployeeForm';

import { getUniqueKey } from 'utils/string';
import { isDateInFuture } from 'utils/date';
import { filterFromIndex } from 'utils/array';

const LeapfrogHistoryForm = props => {
  // UI

  const {
    isCreateForm,
    values,
    handleChange,
    openDesignationModal,
    openEmployeeStatusModal,
    canEdit,
    currentForm
  } = props;

  const { designationAreaHistory, empStatusHistory } = values;

  const isHistoriesEmpty = designationAreaHistory.length === 0 || empStatusHistory.length === 0;

  const currentDesignationIndex = useMemo(() => {
    const index = designationAreaHistory.findIndex(({ transitionDate }) => !isDateInFuture(transitionDate));

    return index === -1 ? 0 : index;
  }, [designationAreaHistory]);

  const currentStatusIndex = useMemo(() => {
    const index = empStatusHistory.findIndex(({ transitionDate }) => !isDateInFuture(transitionDate));

    return index === -1 ? 0 : index;
  }, [empStatusHistory]);

  const handleDelete = (deleteIndex, historyType) => {
    if (!historyType || isNaN(deleteIndex)) {
      return null;
    }

    let name, value;
    if (historyType === EMPLOYEE_HISTORY_TYPES.AREA_AND_DESIGNATION) {
      name = 'designationAreaHistory';
      value = filterFromIndex(values.designationAreaHistory, deleteIndex);
    } else if (historyType === EMPLOYEE_HISTORY_TYPES.ENGAGEMENT_STATUS) {
      name = 'empStatusHistory';
      value = filterFromIndex(values.empStatusHistory, deleteIndex);
    }

    return handleChange({
      target: {
        name,
        value
      }
    });
  };

  useEffect(() => {
    if (currentForm) {
      const isFormEmpty = LEAPFROG_HISTORY_FORM_INPUTS.some(key => {
        if (lodash.isEmpty(values[key])) {
          return true;
        }
        return false;
      });

      if (!isFormEmpty) {
        currentForm.state = COMPLETE;
        return;
      }
      currentForm.state = ERROR;
    }
  }, [values]);

  return (
    <>
      <div className="leapfrog-history-form">
        <div className="leapfrog-history-form__top">
          <div className="leapfrog-history-form__items">
            {canEdit('designationAreaHistory') ? (
              <div className="add-button" onClick={() => openDesignationModal('Add')}>
                <FiPlusCircle className="plus-icon" />
                <p className="button-label">Add New Designation</p>
              </div>
            ) : (
              <p className="emp-experience-title mb-5">Designation History</p>
            )}
            <div className="leapfrog-history-form__designation-list">
              <div className="designation-list">
                {designationAreaHistory.map((item, index) => (
                  <DesignationList
                    canEdit={canEdit('designationAreaHistory')}
                    handleDelete={() => handleDelete(index, EMPLOYEE_HISTORY_TYPES.AREA_AND_DESIGNATION)}
                    key={getUniqueKey()}
                    isCurrent={currentDesignationIndex === index}
                    title={`${item.designation.name}, ${item.area?.name ? ` ${item.area.name}` : ''}`}
                    transitionDate={item.transitionDate}
                    openEditModal={() => openDesignationModal('Edit', index)}
                    employeeName={`${values.firstName || ''}  ${values.lastName || ''}`}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="leapfrog-history-form__items">
            {canEdit('empStatusHistory') ? (
              <div className="add-button" onClick={() => openEmployeeStatusModal('Add')}>
                <FiPlusCircle className="plus-icon" />
                <p className="button-label">Add New Employment Status</p>
              </div>
            ) : (
              <p className="emp-experience-title mb-5">Engagement History</p>
            )}
            <div className="leapfrog-history-form__designation-list">
              <div className="designation-list">
                {empStatusHistory.map((item, index) => (
                  <EngagementStatusList
                    canEdit={canEdit('empStatusHistory')}
                    key={getUniqueKey()}
                    isCurrent={currentStatusIndex === index}
                    title={item.engagementStatus.name}
                    transitionDate={item.transitionDate}
                    endDate={item.endDate}
                    empStatusDetails={item.empStatusDetails}
                    openEditModal={() => openEmployeeStatusModal('Edit', index)}
                    editIndex={index}
                    handleDelete={() => handleDelete(index, EMPLOYEE_HISTORY_TYPES.ENGAGEMENT_STATUS)}
                    employeeName={`${values.firstName || ''}  ${values.lastName || ''}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {isCreateForm && isHistoriesEmpty && (
          <div className="leapfrog-history-form__info">
            <FiInfo className="info-icon" />
            <p className="label">Add at least one designation &amp; employment status</p>
          </div>
        )}
      </div>
    </>
  );
};

export default LeapfrogHistoryForm;
