import React, { useMemo } from 'react';

import { getCurrentWeekRange, getFormattedDate } from 'utils/date';

import LFModal from 'components/common/LFModal';
import Info from 'components/common/inputWrapper/components/Info';

import { useFetchRosterData } from 'hooks/query/useFetchRosterData';

import { EmployeeRosterTable } from './EmployeeRosterTable';

export function EmployeeRosterModal(props) {
  const { TriggerBtn, isOpen, onClose, employee } = props;

  const [weekStartDate, weekEndDate] = getCurrentWeekRange(getFormattedDate());

  const { isLoading: isRosterDataLoading, data: rosterData } = useFetchRosterData({
    userIds: employee?.id,
    startDate: weekStartDate,
    endDate: weekEndDate
  });

  const modalHeader = useMemo(() => `${employee?.firstName}'s Roster`, [employee?.firstName]);

  return (
    <LFModal
      TriggerBtn={TriggerBtn}
      isOpen={isOpen}
      onClose={onClose}
      closeButton
      htmlContent={
        <div className="roster-alert">
          <Info
            shouldAlert={false}
            message={`This is ${employee?.firstName}'s expected roster for the week, not actual attendance.`}
            className="roster-alert__wrapper"
          />

          <EmployeeRosterTable isLoading={isRosterDataLoading} rosterData={rosterData} />
        </div>
      }
      title={{
        text: modalHeader,
        type: 'info'
      }}
      className="employee-roster-modal"
    />
  );
}
