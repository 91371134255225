import axios from 'axios';
import HttpStatus from 'http-status';

import config from '../config';

import * as authService from '../services/auth';
import * as tokenService from '../services/token';

import { REFRESH_TOKEN_URL } from '../constants/endpoints';

const instance = axios.create({
  baseURL: config.baseURI,
  responseType: 'json'
});

let unauthorizedRequestQueue = [];
let isRefreshingAccessToken = false;

/**
 * Initialize the unauthorized response interceptors.
 */
instance.interceptors.response.use(
  response => response,
  /**
   * This interceptor checks if the response had a 401 status code, which means
   * that the access token used for the request has expired. It then refreshes
   * the access token and resends the original request.
   */
  unauthorizedResponseHandlerInterceptor
);

export async function unauthorizedResponseHandlerInterceptor(err) {
  const originalRequest = err.config;
  const code = err.response && err.response.status;
  const path = originalRequest.url;

  const refreshToken = tokenService.getRefreshToken();
  if (!refreshToken || (code === HttpStatus.UNAUTHORIZED && originalRequest.url === REFRESH_TOKEN_URL)) {
    authService.redirectToLogin();

    return Promise.reject(err);
  }

  if (code === HttpStatus.UNAUTHORIZED && path !== REFRESH_TOKEN_URL) {
    if (isRefreshingAccessToken) {
      return subscribeToAccessTokenRefresh(originalRequest);
    }

    try {
      isRefreshingAccessToken = true;

      const refreshedAccessToken = await authService.refresh(refreshToken);
      tokenService.setAccessToken(refreshedAccessToken);

      const newRequest = updateAccessToken(originalRequest, refreshedAccessToken);

      callRequestsFromUnauthorizedQueue(refreshedAccessToken);
      clearUnauthorizedRequestQueue();

      isRefreshingAccessToken = false;

      return instance.request(newRequest);
    } catch (error) {
      return authService.redirectToLogin();
    }
  }

  throw err;
}

/**
 * Changes access token of the provided request.
 *
 * @param {Object} originalRequest
 * @param {Object} newToken
 */
function updateAccessToken(originalRequest, newToken) {
  return {
    ...originalRequest,
    headers: {
      ...originalRequest.headers,
      Authorization: `Bearer ${newToken}`
    }
  };
}

/**
 * Subscribe retry request to access token refresh.
 * Add request to unauthorized request queue.
 *
 * @param {Object} originalRequest
 */
function subscribeToAccessTokenRefresh(originalRequest) {
  return new Promise(resolve => {
    unauthorizedRequestQueue.push(function(refreshedAccessToken) {
      const newRequest = updateAccessToken(originalRequest, refreshedAccessToken);

      resolve(instance.request(newRequest));
    });
  });
}

/**
 * Clear unauthorized request queue.
 */
function clearUnauthorizedRequestQueue() {
  unauthorizedRequestQueue = [];
}

/**
 * Call pending requests from unauthorized request queue.
 *
 * @param {String} refreshedAccessToken
 */
function callRequestsFromUnauthorizedQueue(refreshedAccessToken) {
  unauthorizedRequestQueue.map(cb => cb(refreshedAccessToken));
}

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} [config]
 * @param {Object} [config.params] An object of queries that will be added to
 * the url.
 * @param {Boolean} [config.accessToken] Whether or not to include the
 * access-token header.
 * @returns {Promise}
 */
function get(url, { params = {}, accessToken = true, responseType = 'json', headers = {} } = {}, raw = false) {
  const authHeaders = {};

  if (accessToken) {
    authHeaders['Authorization'] = `Bearer ${tokenService.getAccessToken()}`;
  }

  return instance({
    url,
    params,
    responseType,
    method: 'get',
    headers: { ...authHeaders, ...headers }
  }).then(response => (raw ? response : response.data));
}

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} [config]
 * @param {Object} [config.params] An object of queries that will be added to
 * the url.
 * @param {Object} [config.body] An object that will be sent in the request
 * body.
 * @param {Boolean} [config.accessToken] Whether or not to include the
 * access-token header.
 * @returns {Promise}
 */
function post(url, { params = {}, body = {}, accessToken = true, headers = {} } = {}) {
  const authHeaders = {};

  if (accessToken) {
    authHeaders['Authorization'] = `Bearer ${tokenService.getAccessToken()}`;
  }

  return instance({
    url,
    params,
    data: body,
    method: 'post',
    headers: { ...authHeaders, ...headers }
  }).then(response => response.data);
}

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} [config]
 * @param {Object} [config.params] An object of queries that will be added to
 * the url.
 * @param {Object} [config.body] An object that will be sent in the request
 * body.
 * @param {Boolean} [config.accessToken] Whether or not to include the
 * access-token header.
 * @returns {Promise}
 */
function put(url, { params = {}, body = {}, accessToken = true, headers = {} } = {}) {
  const authHeaders = {};

  if (accessToken) {
    authHeaders['Authorization'] = `Bearer ${tokenService.getAccessToken()}`;
  }

  return instance({
    url,
    params,
    data: body,
    method: 'put',
    headers: { ...authHeaders, ...headers }
  }).then(response => response.data);
}

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} [config]
 * @param {Object} [config.params] An object of queries that will be added to
 * the url.
 * @param {Object} [config.body] An object that will be sent in the request
 * body.
 * @param {Boolean} [config.accessToken] Whether or not to include the
 * access-token header.
 * @returns {Promise}
 */
function patch(url, { params = {}, body = {}, accessToken = true, headers = {} } = {}) {
  const authHeaders = {};

  if (accessToken) {
    authHeaders['Authorization'] = `Bearer ${tokenService.getAccessToken()}`;
  }

  return instance({
    url,
    params,
    data: body,
    method: 'patch',
    headers: { ...authHeaders, ...headers }
  }).then(response => response.data);
}

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} [config]
 * @param {Object} [config.params] An object of queries that will be added to
 * the url.
 * @param {Object} [config.body] An object that will be sent in the request
 * @param {Boolean} [config.accessToken] Whether or not to include the
 * access-token header.
 * @returns {Promise}
 */
function remove(url, { params = {}, body = {}, accessToken = true, headers = {} } = {}) {
  const authHeaders = {};

  if (accessToken) {
    authHeaders['Authorization'] = `Bearer ${tokenService.getAccessToken()}`;
  }

  return instance({
    url,
    params,
    data: body,
    method: 'delete',
    headers: { ...authHeaders, ...headers }
  }).then(response => response.data);
}

export default {
  get,
  put,
  post,
  delete: remove,
  patch
};
