import React from 'react';

import { pluralize } from 'utils/string';
import { ATTENDANCE_URL } from 'constants/endpoints';
import Info from 'components/common/inputWrapper/components/Info';

const PendingActionsInfo = ({ pendingLeaves, pendingWorklogs, user, isLeaveIssuer }) => {
  // Return null if there are no pending actions
  if (!pendingLeaves && !pendingWorklogs) {
    return null;
  }

  // Construct the pending requests message
  const getPendingRequestsMessage = () => {
    if (pendingLeaves && pendingWorklogs) {
      return (
        <>
          <strong>
            {pendingLeaves} {pluralize('leave request', pendingLeaves)}
          </strong>
          &nbsp;and&nbsp;
          <strong>
            {pendingWorklogs} {pluralize('worklog', pendingWorklogs)}
          </strong>
        </>
      );
    }

    if (pendingLeaves) {
      return (
        <strong>
          {pendingLeaves} {pluralize('leave request', pendingLeaves)}
        </strong>
      );
    }

    if (pendingWorklogs) {
      return (
        <strong>
          {pendingWorklogs} {pluralize('worklog', pendingWorklogs)}
        </strong>
      );
    }

    return null;
  };

  const pendingRequestsMessage = getPendingRequestsMessage();

  // Construct the full message
  const getPendingActionsInfo = () => {
    if (pendingRequestsMessage) {
      return (
        <>
          <p>
            You cannot change the leave issuer until necessary action is taken.&nbsp;
            {user} has {pendingRequestsMessage} pending approval.
          </p>
          {isLeaveIssuer && (
            <p className="pt-8">
              <a href={ATTENDANCE_URL}>View Attendance</a>
            </p>
          )}
        </>
      );
    }

    return null;
  };

  return <Info message={getPendingActionsInfo()} shouldAlert={true} className="mt-4" />;
};

export default PendingActionsInfo;
