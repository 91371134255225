import { useQuery } from 'react-query';

import { LEAVE_QUERY_KEYS } from './keys';

import * as userService from 'services/user';

export function useFetchLeaveBalance(param = {}, shouldFetch = true) {
  return useQuery({
    queryKey: [LEAVE_QUERY_KEYS.LEAVE_BALANCE, param.userId, param.fiscalYearId],
    queryFn: async () => {
      const { data } = await userService.fetchLeaves({ ...param, onlyActive: true });

      return data;
    },
    enabled: !!param.userId && !!param.fiscalYearId && shouldFetch,
    select: data => Object.keys(data).map(values => data[values])
  });
}
