import config from 'config';
import http from 'utils/http';

/**
 * Request api to save the worklog.
 *
 * @param {Object} worklog
 * @param {String} worklog.location
 * @param {String} worklog.date
 * @param {Array} worklog.worklog
 *
 * @returns {Object}
 */
export async function save(worklog) {
  const url = `${config.attendanceBaseURI}${config.endpoints.attendance.api.worklogs}`;

  const { data } = await http.post(url, {
    body: worklog
  });

  return data;
}
