import React, { useState, useCallback, useEffect } from 'react';

import { MINIMUM_PAGE_COUNT } from 'constants/page';

import * as userService from 'services/user';
import * as skillV2Service from 'services/skillV2';
import * as designationService from 'services/designation';
import * as designationAreaService from 'services/designationArea';

import useTitle from 'hooks/useTitle';

import Pagination from 'components/common/paginator';
import Loading from 'components/common/loading/Loading';

import { useQuery } from 'utils/queryParam';
import { handleError } from 'utils/errorHandler';

import { ResourceFilter, ResourceResults } from './components';

const PAGE_COUNT = 10;

const ResourceFinder = props => {
  const [query, setQuery] = useQuery(props);
  const [loading, setLoading] = useState({
    employees: false,
    skills: false,
    designations: false,
    export: false,
    areas: false
  });
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [skills, setSkills] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [areas, setAreas] = useState([]);
  const [pageSize, setPageSize] = useState(PAGE_COUNT);

  const setTitle = useTitle();

  useEffect(() => {
    setTitle('People Finder');
  }, []);

  const { page, ...filterQuery } = query;

  const fetchResources = useCallback(async () => {
    try {
      setLoading(prevLoading => ({ ...prevLoading, employees: true }));

      const response = await userService.fetchPeopleFinder(query);

      const { data } = response;
      const { total } = response.meta;

      const pageCount = Math.ceil(total / pageSize);

      setTotal(total);
      setEmployees(data);
      setPageCount(pageCount);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, employees: false }));
    }
  }, [props.location.search]);

  const fetchSkills = useCallback(async () => {
    try {
      setLoading(prev => {
        return {
          ...prev,
          skills: true
        };
      });

      const { data } = await skillV2Service.fetchEndLevelSkills();

      setSkills(data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(prev => {
        return {
          ...prev,
          skills: false
        };
      });
    }
  }, []);

  useEffect(() => {
    fetchSkills();
  }, [fetchSkills]);

  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        setLoading(prevLoading => ({ ...prevLoading, designations: true }));

        const response = await designationService.fetchAll();

        const { data } = response;
        setDesignations(data);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(prevLoading => ({ ...prevLoading, designations: false }));
      }
    };

    fetchDesignations();
  }, []);

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        setLoading(prevLoading => ({ ...prevLoading, areas: true }));

        const response = await designationAreaService.fetchAll();

        const { data } = response;
        setAreas(data);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(prevLoading => ({ ...prevLoading, areas: false }));
      }
    };

    fetchAreas();
  }, []);

  useEffect(() => {
    if (!page) {
      setQuery({ ...query, page: 1 });

      return;
    }
    fetchResources();
  }, [fetchResources]);

  const handlePageChange = page => {
    setQuery({ ...query, page });
  };

  const handleFilterClick = newFilterState => {
    setQuery({ ...query, ...newFilterState, page: 1 });
  };

  const handleExportClick = async () => {
    try {
      setLoading(prevLoading => ({ ...prevLoading, export: true }));

      const { data: blob } = await userService.exportPeopleFinder(query);

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'leapfroggers skills.csv';
      link.click();

      setTimeout(() => URL.revokeObjectURL(link.href), 0);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, export: false }));
    }
  };

  const handlePageSizeChange = pageSize => {
    setPageSize(pageSize);

    setQuery({ ...query, size: pageSize, page: 1 });
  };

  const handleClearClick = () => {
    setQuery({ page: 1 });
  };

  return (
    <main>
      <div className="container">
        <div className="full-scope-card">
          <div className="full-scope-card__header table-header name-wrap">
            <div className="d-flex flex-row">
              <h3 className="table-title">People Finder</h3>
            </div>
          </div>
          <div className="full-scope-card__content d-flex position-relative">
            <div className="resource-filter-area">
              <ResourceFilter
                skills={skills}
                initialState={filterQuery}
                designations={designations}
                areas={areas}
                onFilter={handleFilterClick}
                onClear={handleClearClick}
                loadingSkills={loading.skills}
                loadingDesignations={loading.designations}
                loadingAreas={loading.areas}
              />
            </div>
            <div className="resource-results-area">
              {loading.employees ? (
                <Loading />
              ) : (
                <>
                  <ResourceResults
                    employees={employees}
                    total={total}
                    exporting={loading.export}
                    onExport={handleExportClick}
                  />
                  <Pagination
                    pageData={{ page: +page }}
                    onPageChange={handlePageChange}
                    pageCount={+(pageCount || MINIMUM_PAGE_COUNT)}
                    onPageSizeChange={handlePageSizeChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResourceFinder;
