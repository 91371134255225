import classNames from 'classnames';
import interpolate from 'pinterpolate';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UiMegaphone } from 'vyaguta-icons/ui';
import { usePageVisibility } from 'react-page-visibility';

import MyTeam from './components/MyTeam';
import EventList from './components/EventList';
import NoticeBoard from './components/NoticeBoard';
import DashboardCard from './components/DashboardCard';
import AllocationCard from './components/AllocationCard/AllocationCard';

import * as mixpanel from 'services/mixPanel';

import history from 'utils/history';

import { NOTICE_BOARD } from 'constants/routes';
import { mixPanelEvents } from 'constants/mixPanel';
import { EXCLUDED_DESIGNATION_FOR_WORKSHIFT, INCLUDED_COUNTRIES_FOR_WORKSHIFT } from 'constants/dashboard';

import useCurrentFiscalYear from 'hooks/useCurrentFiscalYear';

export default function Dashboard(props) {
  window.document.title = 'Vyaguta';

  const isVisible = usePageVisibility();
  const currentFiscalYear = useCurrentFiscalYear();

  const {
    value: { user }
  } = useSelector(state => state.information);

  const hasUserInfo = Boolean(user?.id);

  const shouldShowWorkshift =
    INCLUDED_COUNTRIES_FOR_WORKSHIFT.includes(user?.country) &&
    !EXCLUDED_DESIGNATION_FOR_WORKSHIFT.includes(user?.designation?.name);

  useEffect(() => {
    const event = isVisible ? mixPanelEvents.view.dashboard : mixPanelEvents.switch.from.dashboard;

    mixpanel.trackEvent(event);
  }, [isVisible]);

  const handleNoticeBoardClick = e => {
    e.preventDefault();

    history.push(interpolate(NOTICE_BOARD));
  };

  const gridClassname = !hasUserInfo || shouldShowWorkshift ? 'grid-rows-with-work-shift' : 'grid-rows';

  return (
    <div className="container dashboard">
      <div className={classNames(`dashboard__contents`, gridClassname)}>
        <AllocationCard userId={props.user.id} designation={props?.user?.designation} />

        {/* <DashboardCard className="actions" title="Attendance Information">
          <PendingActions userId={props.user.id} designation={props?.user?.designation} />
        </DashboardCard> */}

        {/* <DashboardCard className="full-scope-card dashboard__card leave-plan-card" title="Team's Leave Plan">
          <LeavePlanCard userId={props.user.id} />
        </DashboardCard> */}

        <div className="full-scope-card dashboard__card event__card d-flex flex-direction-column">
          <div className="dashboard__title event__card--title">Upcoming Events & Holidays</div>
          {currentFiscalYear.endDate && <EventList currentFiscalYear={currentFiscalYear} />}
        </div>

        <DashboardCard className="full-scope-card dashboard__card notice-board d-flex flex-direction-column">
          <div className="dashboard__title d-flex justify-between notice-board__heading">
            <div className="d-flex align-items-center gap-px-12">
              <UiMegaphone size={20} />
              <span>Notice Board</span>
            </div>

            <a
              href={NOTICE_BOARD}
              rel="noopener noreferrer"
              className="d-flex gap-px-5 align-items-center notice-board__link"
            >
              <span>View All</span>
            </a>
          </div>
          <NoticeBoard />
        </DashboardCard>

        <div className="full-scope-card dashboard__card my-team">
          <MyTeam user={props?.user} />
        </div>

        {/* <div className="full-scope-card dashboard__card quick-links">
          <div className="accordion__card">
            <QuickLinksContainer />
          </div>
        </div> */}
      </div>
    </div>
  );
}
