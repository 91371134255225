import * as Yup from 'yup';

import MomentDateSchemaType from './momentdate';
import { getRequiredErrorLabel } from 'utils/string';

const momentSchema = new MomentDateSchemaType();

const holidaySchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(255, 'Name cannot exceed more than 255 characters')
    .required(getRequiredErrorLabel('title')),
  date: momentSchema.required(getRequiredErrorLabel('event date')),
  description: Yup.string()
    .trim()
    .min(8, 'Description should be of at least 8 characters')
    .max(255, 'Description cannot exceed more than 255 characters')
    .required(getRequiredErrorLabel('description')),
  countries: Yup.array()
    .min(1, 'Please select at least one country')
    .nullable()
});

export default holidaySchema;
