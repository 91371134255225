export const SELF = 'self';

export const TEAM = 'team';

export const ALL = 'all';

export const ROLE_TYPES = {
  ALL: 'all',
  MANAGER: 'manager',
  ISSUER: 'issuer'
};

export const USER_SELECTED_FIELDS = ['avatarUrl', 'mobilePhone', 'department', 'designation', 'leaveIssuer'];

export const MAKE_SEARCH_BY = ['firstName', 'lastName', 'middleName'];
